import Tippy from "@tippyjs/react";
import React, { useEffect, useState } from "react";

export function HeaderFTE({
  peoples,
  skills,
  roles,
  dRoles,
  domains,
  links,
  circles,
  projects,
}) {
  const [fte, setFte] = useState({
    peoplesAvailability: 0,
    plan: 0,
    workload: 0,
  });


  useEffect(() => {
    let defaultRoleFTE = 0;
    if (peoples && peoples.length > 0) {
      let sum = 0;
      peoples.forEach((data) => {
        if(undefined === data.availability)return;
        sum = sum + data.availability || 0;
      });
      setFte((prev) => ({ ...prev, peoplesAvailability: parseFloat(sum.toFixed(2)) }));
    }

    let planTotalCount = 0;
    let planSum = 0;

    if (skills && skills.length > 0) {
      skills.forEach(({ planTotal, workload }) => {
        planTotalCount = planTotalCount + planTotal || 0;
        if (workload && workload.length > 0) {
          workload.forEach((sw) => {
            if (sw && sw.plan) {
              planSum = planSum + sw.plan || 0;
            }
          });
        }
      });
    }

    if (roles && roles.length > 0) {
      roles.forEach(({ planTotal, workload }) => {

        planTotalCount = planTotalCount + planTotal || 0;
        if (workload && workload.length > 0) {

          workload.forEach((sw) => {
            if (sw && sw.plan) {
              // defaultRoleFTE += sw.plan;
              planSum = planSum + sw.plan || 0;
            }
          });
        }
      });

      dRoles && dRoles.forEach(({ planTotal, workload, _id }) => {
        planTotalCount = planTotalCount + planTotal || 0;
        if (workload && workload.length > 0) {
          workload.forEach((sw) => {
            if (sw && sw.plan) {
              // defaultRoleFTE += sw.plan;
              planSum = planSum + sw.plan || 0;
            }
          });
        }
      });
    }

    if (domains && domains.length > 0) {
      domains.forEach(({ planTotal, workload }) => {
        planTotalCount = planTotalCount + planTotal || 0;
        if (workload && workload.length > 0) {
          workload.forEach((sw) => {
            if (sw && sw.plan) {
              planSum = planSum + sw.plan || 0;
            }
          });
        }
      });
    }
    if (links && links.length > 0) {
      links.forEach(({ planTotal, workload }) => {
        planTotalCount = planTotalCount + planTotal || 0;
        if (workload && workload.length > 0) {
          workload.forEach((sw) => {
            if (sw && sw.plan) {
              // defaultRoleFTE += sw.plan;
              planSum = planSum + sw.plan || 0;
            }
          });
        }
      });
    }
    // console.log(defaultRoleFTE)
    // console.log("correct plan",planTotalCount)
    // const totalDefalut = planTotalCount + defaultRoleFTE;
    setFte((prev) => ({
      ...prev,
      // plan: parseFloat(totalDefalut.toFixed(2)),
      plan: parseFloat(planTotalCount.toFixed(2)),
      workload: parseFloat(planSum.toFixed(2)),
    }));
  }, [peoples, skills, roles, domains, links, circles, projects]);
// console.log(fte)
  return (
    <>
      <div className="dropdown total-fte">
        <Tippy content={<span>Total FTE</span>}>
          <div className="btn btn-mytpt btn-mytpt-modes">
            {`${fte.peoplesAvailability || 0}/${fte.plan || 0}/${fte.workload || 0}`}
          </div>
        </Tippy>
      </div>
    </>
  );
}
