import {
    USER_FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORDT_FAIL, SUCCESS_MESSAGE_CLEAR, ERROR_CLEAR,
    LOGIN_FAIL, USER_LOGIN_SUCCESS, USER_RESET_PASSWORD_SUCCESS, RESET_PASSWORDT_FAIL,
    USER_UPDATE_SUCCESS, USER_UPDATE_FAIL, SIGN_OUT, REGISTER_FAIL, REGISTER_SUCCESS, USER_VERIFIY_SUCCESS,
    USER_VERIFIY_FAIL, PAYMENT_VERIFIY_SUCCESS, PAYMENT_VERIFIY_MSG_CLEAR, PAYMENT_VERIFIY_FAIL, CUR_USER_DATA_GET_SUCCESS,
    CUR_USER_DATA_GET_FAIL, CUR_USER_LHDATA_GET_SUCCESS, CUR_USER_LHDATA_GET_FAIL, SOCIAL_LOGIN_TOKEN_DELETE, BULK_ADD_SUCCESS, BULK_ADD_FAIL,
    DIRECT_ADD_CO_ADMIN_VERIFIY_FAIL,
    DIRECT_ADD_CO_ADMIN_VERIFIY_SUCCESS
} from '../types/authType'
import deCodeToken from 'jwt-decode'

const authState = {
    loading: true,
    stripeUrl: '',
    authenticate: false,
    error: '',
    successMessage: '',
    paySuccessMessage: '',
    payErrorMessage: '', /* ADDED - 22/7/24 */
    directAddSuccessMessage:"",
    directAddErrorMessage:"",
    myInfo: {},
    curUserData: {},
    loginHistory: [],
    socialLoginToken: null,
}

const tokenDecode = (token) => {
    const tokenDecoded = deCodeToken(token);
    const expTime = new Date(tokenDecoded.exp * 1000);
    if (new Date() > expTime) {
        return null
    }
    return tokenDecoded;
}

const getToken = localStorage.getItem('authToken');
if (getToken) {
    const getInfo = tokenDecode(getToken)
    // console.log(authState.myInfo,authState.curUserData)
    if (getInfo) {
        authState.myInfo = getInfo;
        authState.authenticate = true;
        authState.loading = false
    }
}


export const authReducer = (state = authState, action) => {
    const { payload, type } = action;
    if (type === FORGOT_PASSWORDT_FAIL || type === LOGIN_FAIL || type === RESET_PASSWORDT_FAIL || type === USER_UPDATE_FAIL) {
        return {
            ...state,
            error: payload.error,
            authenticate: false,
            loading: true,
            myInfo: ''
        }
    }
    if (type === USER_LOGIN_SUCCESS) {
        // console.log(payload)
        const myInfo = tokenDecode(payload.token);
        // console.log(myInfo)
        return {
            ...state,
            myInfo: myInfo,
            successMessage: payload.successMessage,
            error: '',
            authenticate: true,
            loading: false
        }
    }
    if (type === REGISTER_SUCCESS) {
        return {
            ...state,
            successMessage: payload.successMessage,
            socialLoginToken: payload.socialLoginToken
        }
    }
    if (type === USER_FORGOT_PASSWORD_SUCCESS || type === USER_RESET_PASSWORD_SUCCESS) {
        // console.log(payload)
        // const myInfo = tokenDecode(payload.token);

        return {
            ...state,
            successMessage: payload.successMessage,
            error: '',
            loading: false
        }
    }
    if (type === USER_VERIFIY_SUCCESS) {
        return {
            ...state,
            stripeUrl: payload.stripeUrl
        }
    }
    if (type === PAYMENT_VERIFIY_SUCCESS) {
        return {
            ...state,
            paySuccessMessage: payload.paySuccessMessage
        }
    }
    /* ADDED - 22/7/24 */
    if (type === PAYMENT_VERIFIY_FAIL) {
        return {
            ...state,
            payErrorMessage: payload.paySuccessMessage
        }
    }

    if (type === DIRECT_ADD_CO_ADMIN_VERIFIY_SUCCESS) {
        return {
            ...state,
            directAddSuccessMessage: payload.paySuccessMessage
        }
    }
    if (type === DIRECT_ADD_CO_ADMIN_VERIFIY_FAIL) {
        return {
            ...state,
            directAddErrorMessage: payload.paySuccessMessage
        }
    }

    if (type === PAYMENT_VERIFIY_MSG_CLEAR) {
        return {
            ...state,
            payErrorMessage: "",
            paySuccessMessage: "",
            directAddSuccessMessage: "",
            directAddErrorMessage: "",
        }
    }
    if (type === USER_UPDATE_SUCCESS) {
        return {
            ...state,
            successMessage: payload.successMessage,
            error: '',
            loading: false
        }
    }
    if (type === SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            successMessage: ''
        }
    }
    if (type === REGISTER_FAIL) {
        return {
            ...state,
            error: payload.error
        }
    }
    if (type === ERROR_CLEAR) {
        // console.log("here to clear error message")
        return {
            ...state,
            error: ''
        }
    }
    if (type === CUR_USER_DATA_GET_SUCCESS) {
        return {
            ...state,
            curUserData: payload.curUserData
        }
    }
    if (type === CUR_USER_DATA_GET_FAIL) {
        return {
            ...state,
            error: payload.error
        }
    }
    if (type === CUR_USER_LHDATA_GET_SUCCESS) {
        return {
            ...state,
            loginHistory: payload.loginHistory
        }
    }
    if (type === SIGN_OUT) {
        return {
            ...state,
            loading: true,
            authenticate: false,
            error: '',
            successMessage: '',
            myInfo: ''
        }
    }
    if (type === SOCIAL_LOGIN_TOKEN_DELETE) {
        return {
            ...state,
            socialLoginToken: null
        }
    }

    if (type === BULK_ADD_SUCCESS) {
        return {
            ...state,
            successMessage: payload.successMessage
        }
    }

    if (type === BULK_ADD_FAIL) {
        return {
            ...state,
            error: payload.error
        }
    }
    return state;
}