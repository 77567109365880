import React, { useState, useEffect, useRef } from "react";
import Tags from "../Tags";
import Selectize from "../Selectize";
import SingleSelet from "../SingleSelet";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
// import { getTeam } from '../../../store/actions/teamAction';
import {
  createPeople,
  updatePeople,
  getPeople,
  deletePeople,
} from "../../../../store/actions/peopleAction";
import { reportGetAdmin } from "../../../../store/actions/reportAction";
import { useAlert } from "react-alert";
import {
  PEOPLE_SUCCESS_MESSAGE_CLEAR,
  PEOPLE_ERROR_CLEAR,
  PEOPLE_ID_CLEAR,
} from "../../../../store/types/peopleType";
import { TAGS_SUCCESS_MESSAGE_CLEAR } from "../../../../store/types/tagsType";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

import { SERVER_URI } from "../../../../config/keys";
import { getTags, tagCreate } from "../../../../store/actions/tagsAction";
import { updateState, getState } from "../../../../store/actions/stateAction";
import {
  historyCreate,
  historyGet,
} from "../../../../store/actions/historyAction";
import {
  HISTORY_MESSAGE_CLEAR,
  HISTORY_DATAS_CLEAR,
} from "../../../../store/types/historyType";
import TextareaAutosize from "react-textarea-autosize";
import WorkLoadPeople from "./WorkLoadPeople";
import { aggrigatedPersonFTE } from "../../../../HelperFunctions/FTE_cal";
import Documents from "./Documents";

export default function PeopleModal({
  data,
  openPeople,
  closePeople,
  setPepoles,
  CloseState,
  stateCloseState,
  isNToP,
  focusMode,
  setFocused,
  focused,
}) {
  // let alert = useAlert();
  let dispatch = useDispatch();
  const animatedComponents = makeAnimated();

  const { loading, successMessage, error, authenticate, myInfo } = useSelector(
    (state) => state.auth
  );
  const { peoples, cuPeopleId, peopleMessagee, peopleError } = useSelector(
    (state) => state.peoples
  );
  const { states, cuStateId, statesMessagee, statesError, statesMessage } =
    useSelector((state) => state.states);
  const { historys, historyMessagee, historyError, historyMessage } =
    useSelector((state) => state.historys);
  /* indra :  */
  const { skills } = useSelector((state) => state.skills);
  const { roles } = useSelector((state) => state.roles);
  const { domains } = useSelector((state) => state.domains);
  const { links } = useSelector((state) => state.links);

  let adminId = myInfo.userId;
  let teamId = localStorage.getItem("teamId");
  let url = `${SERVER_URI}/user/person/create`;
  let urls = `${SERVER_URI}/user/person/update`;

  // PEOPLE  Create and Update Data set

  let arr = {
    _id: data && data._id ? data._id : null,
    fname: data && data.fname ? data.fname : "",
    lname: data && data.lname ? data.lname : "",
    mobile: data && data.mobile ? data.mobile : "",
    email: data && data.email ? data.email : "",
    workRole: data && data.workRole ? data.workRole : "Internal",
    mentor: data && data.mentor ? data.mentor : null,
    mentees: data && data.mentees ? data.mentees : [],
    tags: data && data.tags ? data.tags : [],
    newTag: [],
    adminId: adminId ? adminId : null,
    url: urls ? urls : "",
    teamId: teamId ? teamId : null,
    availability: data && data.availability ? data.availability : 0,
  };

  // Stor people data in peope modal useSate and also change this state

  const [people, setPeople] = useState(arr);

  const handleChange = (e) => {
    if (e.target.name === "availability") {
      setPeople({
        ...people,
        [e.target.name]:
          e.target.value < 0 ? 0 : e.target.value > 1 ? 1 : e.target.value,
      });
    } else {
      setPeople({
        ...people,
        [e.target.name]: e.target.value,
      });
    }
  };

  //added by ranjit
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const centeredPlaceholderStyle = {
    lineHeight: "3rem",
    height: "2rem",
    padding: "0",
  };

  // people data structure in value and lable format for mentor and mentees options

  var Data = [];
  //options
  peoples.map((e) => {
    Data.push({ value: `${e._id}`, label: `${e.fname} ${e.lname}` });
  });

  var MenteesData = [];
  //options
  peoples.map((e) => {
    if (e && Object.keys(e).length > 0 && (!e.mentor || e.mentor === null)) {
      MenteesData.push({ value: `${e._id}`, label: `${e.fname} ${e.lname}` });
    }
  });

  // This is mentor options for people
  const [optionsMentor, setOptionsMentor] = useState([]);

  useEffect(() => {
    // if (Object.keys(data).length > 0) {
    var optionsMentor = [...Data];
    if (people._id && people._id.length > 0) {
      optionsMentor = optionsMentor.filter((e) => {
        return e.value !== people._id.toString();
      });
    }
    if (people.mentees && people.mentees.length > 0) {
      for (let i = 0; i < people.mentees.length; i++) {
        optionsMentor = optionsMentor.filter((e) => {
          return e.value !== people.mentees[i].toString();
        });
      }
    }
    setOptionsMentor(optionsMentor);
    // }
  }, [people, setPeople]);

  // This is mentees options for people

  const [optionsMentees, setOptionsMentees] = useState([]);

  useEffect(() => {
    // if (Object.keys(data).length > 0) {
    let optionsMentee = [...MenteesData];
    if (people._id && people._id.length > 0) {
      optionsMentee = optionsMentee.filter((e) => {
        return e.value !== people._id.toString();
      });
    }
    if (people && Object.keys(people).length > 0) {
      optionsMentee = optionsMentee.filter((e) => {
        if (people.mentor && people.mentor.length > 0) {
          return e.value !== people.mentor.toString();
        } else {
          return e;
        }
      });
    }
    setOptionsMentees(optionsMentee);
    // }
  }, [people, setPeople]);

  // For update data: set current mentor data
  var cumentor = {};

  // current option
  if (arr && arr.mentor) {
    for (let i = 0; i < peoples.length; i++) {
      if (peoples[i]._id === arr.mentor) {
        cumentor = {
          value: `${peoples[i]._id}`,
          label: `${peoples[i].fname} ${peoples[i].lname}`,
        };
        break;
      }
    }
  }

  // store mentor  information in useState and update people state

  // const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState(cumentor);

  useEffect(() => {
    if (value) {
      setPeople({
        ...people,
        ["mentor"]: value.value,
      });
    } else {
      setPeople({
        ...people,
        ["mentor"]: null,
      });
    }
  }, [value, setValue]);

  // For update data: set current mentees data

  var cumentors = [];

  if (data && data.mentees && data.mentees.length > 0) {
    for (let i = 0; i < peoples.length; i++) {
      let inc = data.mentees.includes(peoples[i]._id);
      if (inc) {
        cumentors.push({
          value: `${peoples[i]._id}`,
          label: `${peoples[i].fname} ${peoples[i].lname}`,
        });
      }
    }
  }

  // store mentees  information in useState and update people state

  const [values, setValues] = React.useState(cumentors);

  const handelSelect = (value) => {
    if (value) {
      let mDatas = [];
      if (value && value.length > 0 && Data && Data.length > 0) {
        for (let i = 0; i < value.length; i++) {
          for (let j = 0; j < Data.length; j++) {
            if (value[i].value === Data[j].value) {
              mDatas.push(Data[j]);
              break;
            }
          }
        }
      }
      setValues(mDatas);
    }
  };

  useEffect(() => {
    if (values) {
      let mentorData = [];
      for (let i = 0; i < values.length; i++) {
        if (values[i] && values[i].value) {
          mentorData.push(values[i].value);
        }
      }
      setPeople({
        ...people,
        ["mentees"]: mentorData,
      });
    }
  }, [values, setValues]);

  // If user input any new tags : set new tags in people state
  let curTag = [];
  if (people.newTag && people.newTag.length > 0) {
    for (let i = 0; i < people.newTag.length; i++) {
      curTag.push({ userId: myInfo.userId, tags: [people.newTag[i]] });
    }
  }

  // get current time in particular format
  const currentTime = () => {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    var d = new Date();
    var day = days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }
    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();
    let curTime =
      month + " " + date + ", " + year + " " + hr + ":" + min + " " + ampm;
    return curTime;
  };

  // people data update: if user changes any fields then this history stor in historyState

  let historyState = [];

  if (data && Object.keys(data).length > 0) {
    if (data.fname || people.fname) {
      if (data.fname !== people.fname) {
        if (data.fname.length > 0 && people.fname.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "First Name",
            prev: data.fname,
            next: people.fname,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.fname || people.fname) {
      if (data.lname !== people.lname) {
        if (data.lname === "" && people.lname && people.lname.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Last Name",
            prev: data.lname,
            next: people.lname,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (
          data.lname &&
          data.lname.length > 0 &&
          people.lname &&
          people.lname.length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Last Name",
            prev: data.lname,
            next: people.lname,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (data.lname && data.lname.length > 0 && people.lname === "") {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Last Name",
            prev: data.lname,
            next: people.lname,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.mobile || people.mobile) {
      if (data.mobile !== people.mobile) {
        if (data.mobile === "" && people.mobile && people.mobile.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Phone Number",
            prev: data.mobile,
            next: people.mobile,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (
          data.mobile &&
          data.mobile.length > 0 &&
          people.mobile &&
          people.mobile.length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Phone Number",
            prev: data.mobile,
            next: people.mobile,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (
          data.mobile &&
          data.mobile.length > 0 &&
          people.mobile === ""
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Phone Number",
            prev: data.mobile,
            next: people.mobile,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.email || people.email) {
      if (data.email !== people.email) {
        if (data.email === "" && people.email.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Email",
            prev: data.email,
            next: people.email,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (data.email.length > 0 && people.email.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Email",
            prev: data.email,
            next: people.email,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (data.email.length > 0 && people.email === "") {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Email",
            prev: data.email,
            next: people.email,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.workRole || people.workRole) {
      if (data.workRole !== people.workRole) {
        if (
          data.workRole === "" &&
          people.workRole &&
          people.workRole.length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Work Role",
            prev: data.workRole,
            next: people.workRole,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (
          data.workRole &&
          data.workRole.length > 0 &&
          people.workRole &&
          people.workRole.length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Work Role",
            prev: data.workRole,
            next: people.workRole,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (
          data.workRole &&
          data.workRole.length > 0 &&
          people.workRole === ""
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Work Role",
            prev: data.workRole,
            next: people.workRole,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.mentor || people.mentor) {
      let oldMentor = "";
      let newMentor = "";
      if (peoples && peoples.length > 0) {
        for (let i = 0; i < peoples.length; i++) {
          if (peoples[i].mentor === data.mentor) {
            oldMentor = `${peoples[i].fname} ${peoples[i].lname}`;
          }
          if (peoples[i].mentor === people.mentor) {
            newMentor = `${peoples[i].fname} ${peoples[i].lname}`;
          }
        }
      }
      if (oldMentor !== newMentor) {
        if (oldMentor === "" && newMentor.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Mentor",
            prev: oldMentor,
            next: newMentor,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (oldMentor.length > 0 && newMentor.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Mentor",
            prev: oldMentor,
            next: newMentor,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (oldMentor.length > 0 && newMentor === "") {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Mentor",
            prev: oldMentor,
            next: newMentor,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (
      (data.mentees && data.mentees.length > 0) ||
      (people.mentees && people.mentees.length > 0)
    ) {
      let oldMentees = "";
      let newMentees = "";
      if (data.mentees && peoples && peoples.length > 0) {
        for (var i = 0; i < peoples.length; i++) {
          for (var j = 0; j < data.mentees.length; j++) {
            if (peoples[i]._id === data.mentees[j]) {
              if (j === 0) {
                oldMentees = `${peoples[i].fname} ${peoples[i].lname}`;
              } else {
                oldMentees =
                  oldMentees + "," + `${peoples[i].fname} ${peoples[i].lname}`;
              }
            }
          }
          for (var k = 0; k < people.mentees.length; k++) {
            if (peoples[i]._id === people.mentees[k]) {
              if (k === 0) {
                newMentees = `${peoples[i].fname} ${peoples[i].lname}`;
              } else {
                newMentees =
                  newMentees + "," + `${peoples[i].fname} ${peoples[i].lname}`;
              }
            }
          }
        }
      }
      if (oldMentees !== newMentees) {
        if (oldMentees === "" && newMentees.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Mentees",
            prev: oldMentees,
            next: newMentees,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (oldMentees.length > 0 && newMentees.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Mentees",
            prev: oldMentees,
            next: newMentees,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (oldMentees.length > 0 && newMentees === "") {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Mentees",
            prev: oldMentees,
            next: newMentees,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (
      (data.tags && data.tags.length > 0) ||
      (people.tags && people.tags.length > 0)
    ) {
      if (data.tags && people.tags && data.tags.join() !== people.tags.join()) {
        if (
          data.tags &&
          data.tags.length === 0 &&
          people.tags &&
          people.tags.length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: people.tags.join(),
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (data.tags.length > 0 && people.tags.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: people.tags.join(),
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (
          data.tags &&
          data.tags.length > 0 &&
          people.tags &&
          people.tags.length === 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: people.tags.join(),
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
  }

  const [npUpdate, setNPUpdate] = useState(false);

  const handelUpdate = async () => {
    if (people._id) {
      setNPUpdate(true);
      let peopleSort = localStorage.getItem("peopleSort");
      dispatch(updatePeople({ ...people, peopleSort })).then(() => {
        dispatch(historyCreate(historyState));
      });
    }
    dispatch({ type: HISTORY_DATAS_CLEAR });
    return true;
  };

  // For  update : next and previous botton for people

  const handlePreviosNext = async (data) => {
    dispatch({ type: HISTORY_DATAS_CLEAR });
    let curNPData = [];
    if (peoples && peoples.length > 0 && isNToP && isNToP.length > 0) {
      for (let i = 0; i < isNToP.length; i++) {
        for (let j = 0; j < peoples.length; j++) {
          if (isNToP[i]._id === peoples[j]._id) {
            curNPData.push(peoples[j]);
            break;
          }
        }
      }
    }

    if (data === "prev") {
      if (curNPData && curNPData.length > 0) {
        for (let i = 0; i < curNPData.length; i++) {
          if (curNPData[i]._id === people._id && i >= 0) {
            localStorage.setItem("npState", "People");
            let index = i === 0 ? curNPData.length - 1 : i - 1;
            await handelUpdate().then(() => {
              setPepoles(curNPData[index], curNPData, 1, "People");
            });
          }
        }
      }
    } else if (data === "next") {
      if (curNPData && curNPData.length > 0) {
        for (let i = 0; i < curNPData.length; i++) {
          if (curNPData[i]._id === people._id && i <= curNPData.length - 1) {
            localStorage.setItem("npState", "People");
            let index = i < curNPData.length - 1 ? i + 1 : 0;
            await handelUpdate().then(() => {
              setPepoles(curNPData[index], curNPData, 1, "People");
            });
          }
        }
      }
    }
  };

  // Copy people card information

  const peopleCopy = (e) => {
    setPeople({
      ...people,
      _id: "",
      lname: people.lname + " (Copy)",
    });
    dispatch({ type: HISTORY_DATAS_CLEAR });
  };

  //Create and update people information
  const [document, setDocument] = useState();

  const peopleSubmit = async (e) => {
    // console.log("----Submit Call only for clicking inside modal-----");
    e.preventDefault();
    //update
    localStorage.setItem("npState", "");
    let peopleSort = localStorage.getItem("peopleSort");
    people.documents = document;
    if (people._id) {
      // people.deleteMetor = cumentor.value
      dispatch(updatePeople({ ...people, peopleSort })).then(() => {
        dispatch(historyCreate(historyState));
      });
      // console.log(people)
    } else {
      //create
      dispatch(createPeople({ ...people, peopleSort }));
    }
    dispatch({ type: HISTORY_DATAS_CLEAR });
  };

  useEffect(() => {
    if (CloseState && CloseState === "People") {
      /* console.log(
        "--- close modal pe trigger hota h only for outside click ---",
        { CloseState, stateCloseState, people }
      ); */
      stateCloseState("");
      //update
      let peopleSort = localStorage.getItem("peopleSort");
      if (people._id) {
        people.documents = document;
        dispatch(updatePeople({ ...people, peopleSort })).then(() => {
          dispatch(historyCreate(historyState));
        });
      } else {
        //create
        people.documents = document;
        dispatch(createPeople({ ...people, peopleSort }));
      }
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  }, [CloseState, stateCloseState]);

  // get people reports in new tab
  const getPeopleReports = (data) => {
    dispatch(reportGetAdmin({ peopleId: data })).then(() => {
      dispatch({ type: HISTORY_DATAS_CLEAR });
      closePeople();
    });
  };

  // FOR DELETE: delete people data
  const peopleDelete = (peopleId) => {
    // e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You can still restore this from trash!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.peopleSort = localStorage.getItem("peopleSort");
        obj.adminId = people.adminId;
        obj.url = `${SERVER_URI}/user/person/delete`;
        obj.personId = peopleId;
        obj.softdelete = true;

        dispatch(deletePeople(obj)).then(() => {
          dispatch(getState({ userId: myInfo.userId, teamId: obj.teamId }));
          dispatch({ type: PEOPLE_ID_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
          closePeople();
        });
        // closePeople()
      }
    });
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;

    if (peopleMessagee && peopleMessagee.length > 0) {
      dispatch({ type: PEOPLE_ID_CLEAR });
      dispatch(getState(obj));
      /* let cuPeople = localStorage.getItem("peopleSort");
      if (curTag.length > 0) {
        dispatch(
          tagCreate({
            adminId: myInfo.userId,
            teamId: obj.teamId,
            url: `${SERVER_URI}/user/tags/create`,
            tags: curTag,
          })
        ).then(() => {
          dispatch({ type: TAGS_SUCCESS_MESSAGE_CLEAR });
          dispatch(getTags(obj));
        });
      } */

      dispatch({ type: PEOPLE_SUCCESS_MESSAGE_CLEAR });
      // dispatch(getPeople({ ...obj, curPeople: cuPeople }));
      let curState = localStorage.getItem("npState");
      if (curState !== "People") {
        closePeople();
      }
      // closePeople();
      dispatch({ type: PEOPLE_SUCCESS_MESSAGE_CLEAR });
    }

    if (peopleError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: peopleError,
      });
      dispatch({ type: PEOPLE_ERROR_CLEAR });
    }
  }, [peopleMessagee, peopleError, cuPeopleId, npUpdate]);

  // get current people history
  const historysGet = (data) => {
    if (historys && historys.length === 0) {
      dispatch(historyGet({ cardId: data }));
    } else {
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  };

  useEffect(() => {
    if (historyMessage) {
      dispatch({ type: HISTORY_MESSAGE_CLEAR });
    }
  }, [historyMessage && historys]);

  // set Peope history message

  let historysMessage = [];

  if (historys && historys.length > 0) {
    for (let i = 0; i < historys.length; i++) {
      if (historys[i].cardStatus === "created") {
        let str = `<i>${historys[i].hcTime}</i> : Created`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "added") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} added ` +
          " (" +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "changed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} changed from ` +
          `<b>${historys[i].prev}</b>` +
          " to " +
          `<b>${historys[i].next}</b>`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "removed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} removed ` +
          " (previously: " +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      }
    }
  }

  const peopleMFocus = (id) => {
    let fData = {
      card: "People",
      index: Number(localStorage.getItem("FIndex")),
      id: id,
    };

    if (!fData.index) {
      let curPeople = localStorage.getItem("peopleSort");
      curPeople = curPeople.split(",");
      if (curPeople && curPeople.length > 0) {
        for (let i = 0; i < curPeople.length; i++) {
          if (curPeople[i] === people._id) {
            fData = { ...fData, ["index"]: i, ["id"]: people._id };
            break;
          }
        }
        setFocused(fData);
        closePeople();
        dispatch({ type: HISTORY_DATAS_CLEAR });
      }
    } else {
      setFocused(fData);
      closePeople();
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  };

  const ClosePeopleModal = () => {
    closePeople();
    dispatch({ type: HISTORY_DATAS_CLEAR });
  };

  const [curIsFocused, setCurIsFocused] = useState(false);

  useEffect(() => {
    let isNToPObj = isNToP && isNToP.find((i) => i._id === focused.id);
    if (focused && Object.keys(focused).length > 0) {
      if (
        focused.card === "People" &&
        people &&
        people._id &&
        people._id.length > 0 &&
        isNToP &&
        isNToP.length > 0
      ) {
        // if (isNToP[Number(focused.index)]._id === people._id) {
        if (isNToPObj && isNToPObj._id === people._id) {
          setCurIsFocused(true);
        }
      }
    }
  }, [focused]);

  const refFname = useRef(null);
  const refLname = useRef(null);

  const handleClickFname = () => {
    refFname.current.focus();
  };

  const handleClickLname = () => {
    refLname.current.focus();
  };

  const CustomOption = ({ innerProps, label, value }) => {
    let flag = false;
    if (peoples && peoples.length > 0) {
      for (let i = 0; i < peoples.length; i++) {
        if (peoples[i]._id === value && peoples[i].workRole === "External") {
          flag = true;
          break;
        }
      }
    }
    let data = ``;
    if (flag) {
      data += `<span class="custom-badge blue">EXT</span>`;
    }
    data += label;
    return (
      <div {...innerProps} className="tdListItem">
        <span dangerouslySetInnerHTML={{ __html: data }} />
      </div>
    );
  };

  const isExtPerson = (Id) => {
    if (peoples && peoples.length > 0) {
      let flag = false;
      for (let i = 0; i < peoples.length; i++) {
        if (peoples[i]._id === Id && peoples[i].workRole === "External") {
          flag = true;
          break;
        }
      }
      return flag;
    }
  };

  return (
    <div id="peopleModal" role="dialog">
      <div className="modal-dialog modal-lg modal-dialog-scrollable ">
        <form className="form" onSubmit={(e) => peopleSubmit(e)}>
          <div className="modal-content ">
            <div className="modal-header header-yellow">
              <div className="row">
                <div className="col-xs-8 text-left">
                  <h4 className="modal-title">
                    <img
                      src="images/icon-people.png"
                      style={{
                        width: "17px",
                        marginTop: "-3px",
                        marginRight: "5px",
                      }}
                    />{" "}
                    PERSON
                  </h4>
                </div>

                {focusMode && focusMode.length > 0 ? null : (
                  <div className="col-xs-4 text-right">
                    <Tippy content={<span>Previous Person</span>}>
                      <button
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        type="button"
                        className="btn btn-mytpt-gray show-prev"
                        onClick={() => handlePreviosNext("prev")}
                      >
                        <i className="fa fa-angle-left"></i>
                      </button>
                    </Tippy>
                    <Tippy content={<span>Next Person</span>}>
                      <button
                        style={{ cursor: "pointer" }}
                        type="button"
                        className="btn btn-mytpt-gray show-next"
                        onClick={() => handlePreviosNext("next")}
                      >
                        <i className="fa fa-angle-right"></i>
                      </button>
                    </Tippy>
                  </div>
                )}
              </div>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <TextareaAutosize
                      type="text"
                      readOnly={false}
                      ref={refFname}
                      name="fname"
                      value={people.fname}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      className="form-control tool-name logit"
                      placeholder="+ Add first name"
                      style={centeredPlaceholderStyle}
                    />

                    <TextareaAutosize
                      type="text"
                      name="lname"
                      readOnly={false}
                      value={people.lname}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      className="form-control tool-name text-size logit"
                      ref={refLname}
                      placeholder="+ Add last name"
                      style={centeredPlaceholderStyle}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="tel"
                      name="mobile"
                      readOnly={false}
                      value={people.mobile}
                      onChange={handleChange}
                      className="form-control logit"
                      placeholder="+ Add phone number"
                      autoComplete="off"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      readOnly={false}
                      value={people.email}
                      onChange={handleChange}
                      className="form-control logit"
                      placeholder="+ Add email address"
                      autoComplete="off"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="radio"
                      id="internal"
                      readOnly={false}
                      name="workRole"
                      className="logit"
                      value="Internal"
                      onChange={handleChange}
                      defaultChecked={
                        arr.workRole === "Internal" ? true : false
                      }
                    />
                    <label htmlFor="internal" className="inline-label">
                      Internal
                    </label>

                    <input
                      type="radio"
                      id="external"
                      readOnly={false}
                      onChange={handleChange}
                      name="workRole"
                      className="logit"
                      value="External"
                      defaultChecked={
                        arr.workRole === "External" ? true : false
                      }
                    />
                    <label htmlFor="external" className="inline-label">
                      External
                    </label>
                  </div>

                  {people.workRole === "External" ? (
                    <div
                      className="form-group flag"
                      style={{ display: "block" }}
                    >
                      <label>
                        People marked external will be flagged:{" "}
                        <span className="custom-badge blue">EXT</span>
                      </label>
                    </div>
                  ) : null}

                  <WorkLoadPeople
                    handleChange={handleChange}
                    availability={people.availability}
                    plannedValue={aggrigatedPersonFTE(
                      skills,
                      roles,
                      domains,
                      links,
                      people._id
                    )}
                  />
                </div>

                <div className="col-md-6">
                  <div className="form-group mentor ss">
                    <h4>Mentor</h4>
                    <Select
                      closeMenuOnSelect={true}
                      classNamePrefix="select"
                      className="single-select-container"
                      value={
                        value && value.value && value.value.length > 0
                          ? [
                              {
                                value: value.value,
                                label: (
                                  <div style={{ fontSize: "14px" }}>
                                    {/* {value.value && isExtPerson(value.value) ? (
                                      <span className="custom-badge blue">
                                        EXT
                                      </span>
                                    ) : null}
                                    {value.label} */}

                                    {value.value && isExtPerson(value.value) ? (
                                      <>
                                        <span
                                          className="custom-badge blue"
                                          style={{ display: "inline-block" }}
                                        >
                                          EXT
                                        </span>{" "}
                                        {value.label}
                                      </>
                                    ) : (
                                      value.label
                                    )}
                                  </div>
                                ),
                              },
                            ]
                          : []
                      }
                      onChange={(newValue) => setValue(newValue)}
                      isClearable
                      options={optionsMentor}
                      isDisabled={false}
                      placeholder="+ Add mentor"
                      components={{ Option: CustomOption }}
                      // menuIsOpen={true}
                    />
                  </div>

                  <div className="form-group mentees ms">
                    <h4>Mentees</h4>
                    <Select
                      closeMenuOnSelect={false}
                      classNamePrefix="select"
                      className="multiple-select-container"
                      onChange={(newValue) => {
                        handelSelect(newValue);
                      }}
                      defaultValue={
                        cumentors && cumentors.length > 0
                          ? cumentors.map((cumentor, cmindex) => {
                              // console.log(cumentor);
                              return {
                                label: (
                                  <div style={{ fontSize: "14px" }}>
                                    {/* {cumentor.value &&
                                    isExtPerson(cumentor.value) ? (
                                      <span className="custom-badge blue">
                                        EXT
                                      </span>
                                    ) : null}
                                    {cumentor.label} */}

                                    {cumentor.value &&
                                    isExtPerson(cumentor.value) ? (
                                      <>
                                        <span
                                          className="custom-badge blue"
                                          style={{ display: "inline-block" }}
                                        >
                                          EXT
                                        </span>{" "}
                                        {cumentor.label}
                                      </>
                                    ) : (
                                      cumentor.label
                                    )}
                                  </div>
                                ),
                                value: cumentor.value,
                              };
                            })
                          : null
                      }
                      isMulti
                      options={optionsMentees}
                      isDisabled={false}
                      // placeholder="+ Add a mentee"
                      components={{ Option: CustomOption }}
                      // menuIsOpen={true}
                    />
                  </div>
                  <Documents data={data} domain={people} setDocumentData={setDocument}/>
                  <div readOnly={true} className="form-group tags">
                    <h4>Tags</h4>
                    <Tags
                      data={arr}
                      onChange={handleChange}
                      people={people}
                      name="tags"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer ">
              <div className="row">
                <div className="col-xs-4 text-left">
                  <Tippy content={<span>Enter Focus Mode</span>}>
                    <button
                      type="button"
                      onClick={() => peopleMFocus(people._id)}
                      className="btn btn-mytpt-gray focusit"
                      data-id="peopleModal"
                      data-dismiss="modal"
                    >
                      {/* <i className="far fa-dot-circle"></i> */}
                      <img alt="" src="./images/newicons/focus.svg" />
                    </button>
                  </Tippy>
                  { localStorage.getItem("mode") !== "View" && people._id && !curIsFocused ? (
                    <Tippy content={<span>Delete This Person</span>}>
                      <button
                        type="button"
                        onClick={() => peopleDelete(people._id)}
                        className="btn btn-mytpt-gray delete"
                        data-id="peopleModal"
                        data-post_type="person"
                      >
                        <img alt="" src="./images/newicons/delete.svg" />
                      </button>
                    </Tippy>
                  ) : null}

                  {people._id ? (
                    <Tippy content={<span>View Report</span>}>
                      <button
                        type="button"
                        onClick={() => getPeopleReports(people._id)}
                        className="btn btn-mytpt-gray report"
                        data-id="peopleModal"
                        data-dismiss="modal"
                      >
                        {/* <i className="fas fa-clipboard"></i> */}
                        <img alt="" src="./images/newicons/report.svg" />
                      </button>
                    </Tippy>
                  ) : null}
                  {localStorage.getItem("mode") !== "View" && (
                  <Tippy content={<span>Copy This Person</span>}>
                    <button
                      type="button"
                      onClick={(e) => peopleCopy(e)}
                      className="btn btn-mytpt-gray clone"
                      data-id="peopleModal"
                    >
                      <img alt="" src="./images/newicons/copy.svg" />
                    </button>
                  </Tippy>
                  )}
                  <Tippy content={<span>View History</span>}>
                    <button
                      onClick={() => historysGet(people._id)}
                      style={
                        historysMessage.length > 0
                          ? { backgroundColor: "#F3D068" }
                          : null
                      }
                      type="button"
                      className="btn btn-mytpt-gray history"
                    >
                      <img alt="" src="./images/newicons/history.svg" />
                    </button>
                  </Tippy>
                </div>
                <div className="col-xs-8 text-right">
                  <Tippy content={<span>Close Tool</span>}>
                    <button
                      type="button"
                      onClick={ClosePeopleModal}
                      className="btn btn-mytpt-red"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </Tippy>
                  {localStorage.getItem("mode") !== "View" && (
                  <Tippy content={<span>Save And Close</span>}>
                    <button
                      type="submit"
                      className="btn btn-mytpt save-close"
                      data-modal_id="peopleModal"
                    >
                      <i className="fa fa-check"></i>
                    </button>
                  </Tippy>                    
                  )}
                </div>
              </div>

              {historysMessage.length > 0 ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>History</h4>
                    {historysMessage.reverse().map((e, index) => {
                      return (
                        <p
                          key={index}
                          dangerouslySetInnerHTML={{ __html: e }}
                        ></p>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
