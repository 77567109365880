import axios from 'axios'
import {
    USER_FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORDT_FAIL, USER_LOGIN_SUCCESS, LOGIN_FAIL,
    USER_RESET_PASSWORD_SUCCESS, RESET_PASSWORDT_FAIL, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL
    , REGISTER_FAIL, REGISTER_SUCCESS, USER_VERIFIY_SUCCESS, USER_VERIFIY_FAIL, PAYMENT_VERIFIY_SUCCESS,
    PAYMENT_VERIFIY_FAIL, CUR_USER_DATA_GET_SUCCESS, CUR_USER_DATA_GET_FAIL, CUR_USER_LHDATA_GET_SUCCESS, BULK_ADD_SUCCESS, BULK_ADD_FAIL,
    DIRECT_ADD_CO_ADMIN_VERIFIY_SUCCESS,
    DIRECT_ADD_CO_ADMIN_VERIFIY_FAIL
} from '../types/authType'

import { SERVER_URI } from '../../config/keys'
import { IpInfo } from '../../HelperFunctions/IpInfo'
import { CLIENT_URL } from '../../config/keys'

import {loggedInAxios,userAuth} from "../AxiosInstance"
import { unfreezeWindow } from '../../HelperFunctions/Overlay'

// get-login-user-data

// const token = localStorage.getItem("authToken");

// export const userAuth = (token) => {
//     loggedInAxios.interceptors.request.use(
//         config => {
//             config.headers.authorization = `Bearer ${token}`;
//             return config;
//         },
//         error => {
//             return Promise.reject(error)
//         }
//     )
// }

/* Used for user registration from frontend */
export const userRegister = (data) => {
    return async (dispatch) => {
        try {
            const response = await loggedInAxios.post(`${SERVER_URI}/admin/register`, {...data,url:CLIENT_URL})

            dispatch({
                type: REGISTER_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    // token : response.data.token,
                    socialLoginToken: response.data.socialLoginToken
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: REGISTER_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: REGISTER_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

/* Used to create the default team after registration is success */
export const userdefaultTeam = (data) => {
    return async (dispatch) => {
        try {
            const response = await loggedInAxios.post(`${SERVER_URI}/admin/create-default-team`, data)
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: REGISTER_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: REGISTER_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

// GOOGLE SIGN IN
export const googleSignIn = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        try {
            // console.log(datas)

            let getuserHistory = await IpInfo();

            datas.userHistory = getuserHistory.data

            const response = await loggedInAxios.post(`${SERVER_URI}/admin/googleLogin`, datas)
            // console.log(response.data.data)
            localStorage.setItem('authToken', response.data.data.token)
            localStorage.setItem('teamId', response.data.data.curTeam)

            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    token: response.data.data.token
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: LOGIN_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: LOGIN_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

//GET LOGIN USER DATAS 
export const getLoginUserData = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        const config = {
            headers: {
                'Content-Type': 'application/josn',
                'adminId': datas.userId,
                'url': `${SERVER_URI}/user/circle/get`
            }
        }
        // console.log(config)
        try {
            const token = localStorage.getItem("authToken");

            userAuth(token)
            const response = await loggedInAxios.get(`${SERVER_URI}/admin/get-login-user-data`, config);

            dispatch({
                type: CUR_USER_DATA_GET_SUCCESS,
                payload: {
                    curUserData: response.data.data,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: CUR_USER_DATA_GET_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: CUR_USER_DATA_GET_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

/* Used for user email verification from frontend */
export const userVerify = (datas) => {
    // console.log(datas)
    return async (dispatch) => {
        try {
            const response = await loggedInAxios.put(`${SERVER_URI}/admin/register`, datas)
            // console.log(response)
            dispatch({
                type: USER_VERIFIY_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    stripeUrl: response.data.data
                }
            })
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: USER_VERIFIY_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: USER_VERIFIY_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

/* Used for payment verification from frontend, after coming back from Stripe payment page */
export const paymentVerify = (datas) => {
    return async (dispatch) => {
        try {
            const response = await loggedInAxios.put(`${SERVER_URI}/admin/payment-verify`, {...datas, url: CLIENT_URL})

            dispatch({
                type: PAYMENT_VERIFIY_SUCCESS,
                payload: {
                    paySuccessMessage: response.data.message,
                }
            })
        } catch (error) {
            unfreezeWindow()
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: PAYMENT_VERIFIY_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: PAYMENT_VERIFIY_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

export const AddOnVerify = (datas) => {
    return async (dispatch) => {
        try {
            const response = await loggedInAxios.put(`${SERVER_URI}/admin/add-on-verify`, {...datas,url: CLIENT_URL});
            // console.log(response);
            dispatch({
                type: PAYMENT_VERIFIY_SUCCESS,
                payload: {
                    paySuccessMessage: response.data.message,
                }
            })
        } catch (error) {
            // console.log(error)
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: PAYMENT_VERIFIY_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: PAYMENT_VERIFIY_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

export const DirectAddCoAdmin = (datas) => {
    return async (dispatch) => {
        try {
            const response = await loggedInAxios.put(`${SERVER_URI}/admin/direct-add-on-coadmin`, datas);
            dispatch({
                type: DIRECT_ADD_CO_ADMIN_VERIFIY_SUCCESS,
                payload: {
                    paySuccessMessage: response.data.message,
                }
            })
        } catch (error) {
            // console.log(error)
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: DIRECT_ADD_CO_ADMIN_VERIFIY_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: DIRECT_ADD_CO_ADMIN_VERIFIY_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

/* Used for user login from frontend */
export const userLogin = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        try {
            // console.log(datas)
            let getuserHistory = await IpInfo();

            datas.userHistory = getuserHistory && getuserHistory.data ? getuserHistory.data : {};

            const response = await loggedInAxios.post(`${SERVER_URI}/admin/login`, datas)
            // console.log(response.data.data)
            localStorage.setItem('authToken', response.data.data.token)
            localStorage.setItem('teamId', response.data.data.curTeam)

            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    token: response.data.data.token
                }
            })

        } catch (error) {
            // console.log(440, error)
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: LOGIN_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: LOGIN_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }

        }
    }
}

/* indranil */
export const Autologin = (datas) => {
    // console.log(462,datas);
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        try {
            // console.log(datas)
            let getuserHistory = await IpInfo();
            datas.userHistory = getuserHistory.data

            const response = await loggedInAxios.post(`${SERVER_URI}/admin/auto-login`, datas);

            localStorage.setItem('authToken', response.data.data.token)
            localStorage.setItem('teamId', response.data.data.curTeam)

            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    token: response.data.data.token
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: LOGIN_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: LOGIN_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }

        }
    }
}

export const userReLogin = (datas) => {
    return async (dispatch) => {
        try {

            const response = await loggedInAxios.post(`${SERVER_URI}/admin/relogin`, datas)
            localStorage.setItem('authToken', response.data.data.token)
            localStorage.setItem('teamId', response.data.data.curTeam)
        } catch (error) {
            // console.log(error)
        }
    }
}

export const userUpdate = (datas) => {
    return async (dispatch) => {
        try {
            const response = await loggedInAxios.put(`${SERVER_URI}/admin/update`, datas);
            // console.log(localStorage.setItem("modeSwitchPopUp", response.data.modeSwitchPopUp))

            dispatch({
                type: USER_UPDATE_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: USER_UPDATE_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: USER_UPDATE_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

export const userForgot = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        try {
            // console.log(datas)
            const response = await loggedInAxios.post(`${SERVER_URI}/admin/forgot-password`, {...datas,url:CLIENT_URL})
            // console.log(response.data)
            // localStorage.setItem('authToken', response.data.data.token)

            dispatch({
                type: USER_FORGOT_PASSWORD_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    // token: response.data.token
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: FORGOT_PASSWORDT_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: FORGOT_PASSWORDT_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

export const userReset = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        try {
            // console.log(datas)
            userAuth(datas.token)
            const response = await loggedInAxios.post(`${SERVER_URI}/admin/reset-password`, datas)
            // console.log(response.data)
            // localStorage.setItem('authToken', response.data.data.token)

            dispatch({
                type: USER_RESET_PASSWORD_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    // token: response.data.data.token
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: RESET_PASSWORDT_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: RESET_PASSWORDT_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

export const userSessionResetEmail = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        try {
            // console.log(datas)
            // userAuth(datas.token)
            const response = await loggedInAxios.post(`${SERVER_URI}/admin/send-email-reset-session-token`, {...datas,url: CLIENT_URL})

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: RESET_PASSWORDT_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: RESET_PASSWORDT_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

export const userSessionReset = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        try {
            // console.log(datas)
            // userAuth(datas.token)
            const response = await loggedInAxios.post(`${SERVER_URI}/admin/reset-session-token`, datas)

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: RESET_PASSWORDT_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: RESET_PASSWORDT_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

export const userLoginHistory = (datas) => {
    return async (dispatch) => {
        // const url = 'http://localhost:8080'
        try {
            // console.log(datas)
            userAuth(datas.token)
            const response = await loggedInAxios.get(`${SERVER_URI}/admin/login-history`, datas)
            // console.log(response.data.message)
            // localStorage.setItem('authToken', response.data.data.token)

            dispatch({
                type: CUR_USER_LHDATA_GET_SUCCESS,
                payload: {
                    loginHistory: response.data.data
                }
            })

        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.message) {
                let data = error.response.data.message
                dispatch({
                    type: RESET_PASSWORDT_FAIL,
                    payload: {
                        error: data
                    }
                })
            } else if (error.message) {
                dispatch({
                    type: RESET_PASSWORDT_FAIL,
                    payload: {
                        error: error.message
                    }
                })
            }
        }
    }
}

export const CreateMany = (datas) => {
    return async (dispatch) => {
        try {
            // console.log(datas)
            userAuth(datas.token)

            let url = '';
            if (datas.type === 'people') {
                url = `${SERVER_URI}/user/person/create-many`
            } else if (datas.type === 'skills') {
                url = `${SERVER_URI}/user/skill/create-many`
            } else if (datas.type === 'roles') {
                url = `${SERVER_URI}/user/role/create-many`
            } else if (datas.type === 'domains') {
                url = `${SERVER_URI}/user/domain/create-many`
            } else if (datas.type === 'links') {
                url = `${SERVER_URI}/user/link/create-many`
            } else if (datas.type === 'circles') {
                url = `${SERVER_URI}/user/circle/create-many`
            } else if (datas.type === 'projects') {
                url = `${SERVER_URI}/user/project/create-many`
            }

            const response = await loggedInAxios.post(url, datas)

            dispatch({
                type: BULK_ADD_SUCCESS,
                payload: {
                    successMessage: response.data.message
                }
            })
        } catch (error) {
            let data = '';
            if (error && error.response && error.response.data) {
                data = error.response.data;
            } else {
                data = { message: 'Something went wrong. Please refresh and try again.' };
            }

            dispatch({
                type: BULK_ADD_FAIL,
                payload: {
                    error: data.message
                }
            })
        }
    }
}