import React, { useState, useEffect, useRef } from "react";
import Tags from "../Tags";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch, useSelector } from "react-redux";
import { TagsInput } from "react-tag-input-component";
import TextareaAutosize from "react-textarea-autosize";
// import { getTeam } from '../../../store/actions/teamAction';
import {
  createDomain,
  updateDomain,
  getDomain,
  deleteDomain,
} from "../../../../store/actions/domainAction";
import { useAlert } from "react-alert";
import {
  DOMAIN_SUCCESS_MESSAGE_CLEAR,
  DOMAIN_ERROR_CLEAR,
  DOMAIN_ID_CLEAR,
} from "../../../../store/types/domainType";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { SERVER_URI } from "../../../../config/keys";
import { TAGS_SUCCESS_MESSAGE_CLEAR } from "../../../../store/types/tagsType";
import { getTags, tagCreate } from "../../../../store/actions/tagsAction";
import {
  getMember,
  updateMember,
} from "../../../../store/actions/memberAction";
import { updateState, getState } from "../../../../store/actions/stateAction";
import {
  historyGet,
  historyCreate,
} from "../../../../store/actions/historyAction";
import {
  HISTORY_MESSAGE_CLEAR,
  HISTORY_DATAS_CLEAR,
} from "../../../../store/types/historyType";
import {
  createRole,
  updateRole,
  getRole,
  deleteRole,
} from "../../../../store/actions/roleAction";
import { createSkills, getSkills } from "../../../../store/actions/skillAction";
import Objectives from "./Objectives";
import Tasks from "./Tasks";
import WorkLoadSRDL from "./WorkLoadSRDL";
import { sumFTE } from "../../../../HelperFunctions/FTE_cal";
import { numberInputOnWheelPreventChange } from "../../../../HelperFunctions/StopInputScroll";
import Documents from "./Documents";

export default function DomainModel({
  data,
  closeDomain,
  CloseState,
  stateCloseState,
  setDomains,
  isNToP,
  focusMode,
  compareMode
}) {
  const animatedComponents = makeAnimated();
  let dispatch = useDispatch();
// console.log("domain data",data);
  const { loading, successMessage, error, authenticate, myInfo } = useSelector(
    (state) => state.auth
  );
  const { peoples, peopleMessagee, peopleError } = useSelector(
    (state) => state.peoples
  );
  const { domains, cuDomainId, domainMessage, domainMessagee, domainError } =
    useSelector((state) => state.domains);
  const { skills, skillMessagee, killError } = useSelector(
    (state) => state.skills
  );
  const { roles, roleMessagee, roleError } = useSelector(
    (state) => state.roles
  );
  const { members, memberMessagee, memberError, memberMessage } = useSelector(
    (state) => state.members
  );
  const { circles, circleMessage, circleError } = useSelector(
    (state) => state.circles
  );
  const { projects, projectMessagee, projectError } = useSelector(
    (state) => state.projects
  );
  const { states, cuStateId, statesMessagee, statesError, statesMessage } =
    useSelector((state) => state.states);
  const { historys, historyMessagee, historyError, historyMessage } =
    useSelector((state) => state.historys);

  let adminId = myInfo.userId;
  let teamId = localStorage.getItem("teamId");
  let url = `${SERVER_URI}/user/person/create`;
  let urls = `${SERVER_URI}/user/person/update`;
  // console.log(domains[2])

  // set all members datas for current domains modal

  let memberIds = [];
  if (data && members.length > 0) {
    let Members = [...members];
    Members.map((e, index) => {
      let inc = e.coreMembers.Domains.includes(data._id);
      let incs = e.extendedMembers.Domains.includes(data._id);
      if (inc) {
        memberIds.push({ memberId: e.memberType, modelId: data._id, XTD: "" });
      }
      if (incs) {
        memberIds.push({
          memberId: e.memberType,
          modelId: data._id,
          XTD: "XTD",
        });
      }
    });
  }

  if (data && memberIds.length > 0) {
    for (let i = 0; i < memberIds.length; i++) {
      for (let j = 0; j < circles.length; j++) {
        if (memberIds[i].memberId === circles[j]._id) {
          memberIds[i].memberName = circles[j].circleName + " " + "(circle)";
        }
      }
      for (let k = 0; k < projects.length; k++) {
        if (memberIds[i].memberId === projects[k]._id) {
          memberIds[i].memberName = projects[k].projectName + " " + "(project)";
        }
      }
    }
  }

  // current doamin modal member action

  const memberHandel = (elements) => {
    let popoverId = {};
    if (elements && elements.memberId.length > 0) {
      for (let i = 0; i < members.length; i++) {
        if (members[i].memberType === elements.memberId) {
          let arr = {
            teamId: members[i].teamId,
            memberType: members[i].memberType,
            coreMembers: {
              People: members[i].coreMembers.People,
              Skills: members[i].coreMembers.Skills,
              Roles: members[i].coreMembers.Roles,
              Links: members[i].coreMembers.Links,
              Domains: members[i].coreMembers.Domains,
              Circles: members[i].coreMembers.Circles,
              Projects: members[i].coreMembers.Projects,
            },
            extendedMembers: {
              People: members[i].extendedMembers.People,
              Skills: members[i].extendedMembers.Skills,
              Roles: members[i].extendedMembers.Roles,
              Links: members[i].extendedMembers.Links,
              Domains: members[i].extendedMembers.Domains,
              Circles: members[i].extendedMembers.Circles,
            },
          };
          if (arr && arr.teamId.length > 0) {
            // setPopoverId(arr)
            popoverId = arr;
          }
          break;
        }
      }
    }
    if (
      popoverId &&
      popoverId.memberType &&
      popoverId.memberType.length > 0 &&
      elements.XTD === "XTD"
    ) {
      cFskillSub(domain, popoverId, "extend");
    }
    if (
      popoverId &&
      popoverId.memberType &&
      popoverId.memberType.length > 0 &&
      elements.XTD !== "XTD"
    ) {
      cFskillSub(domain, popoverId, "core");
    }
  };

  const cFskillSub = (data, popoverId, type) => {
    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.Domains
    ) {
      let inc = popoverId.coreMembers.Domains.includes(data._id);
      if (inc) {
        popoverId.coreMembers.Domains = popoverId.coreMembers.Domains.filter(
          (e) => {
            return e !== data._id;
          }
        );
      }
    } else if (
      type === "extend" &&
      popoverId &&
      popoverId.extendedMembers &&
      popoverId.extendedMembers.Domains
    ) {
      let incs = popoverId.extendedMembers.Domains.includes(data._id);
      if (incs) {
        popoverId.extendedMembers.Domains =
          popoverId.extendedMembers.Domains.filter((e) => {
            return e !== data._id;
          });
      }
    }
    if (popoverId) {
      popoverId.adminId = myInfo.userId;
      popoverId.url = `${SERVER_URI}/user/member/update`;
      // console.log(popoverId);
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverId.teamId;
      dispatch(updateMember(popoverId)).then(() => {
        // dispatch(getMember(obj));
      });
    }
  };

  // for create and update doamin data set data in arr object

  let arr = {
    _id: data && data._id ? data._id : null,
    teamId: data && data.teamId ? data.teamId : null,
    domainName: data && data.domainName ? data.domainName : "",
    defaultDomain: data.defaultDomain ? data.defaultDomain : false,
    purpose: data && data.purpose ? data.purpose : "",
    tasks: data && data.tasks ? data.tasks : [],
    objectives: data && data.objectives ? data.objectives : [],
    owners: data.owners ? data.owners : {},
    standIn: data.standIn ? data.standIn : "",
    tags: data.tags ? data.tags : [],
    newTag: [],
    adminId: adminId ? adminId : null,
    url: urls ? urls : "",
    teamId: teamId ? teamId : null,
    planTotal: data && data.planTotal ? data.planTotal : "",
    workload: data && data.workload ? data.workload : [],
  };

  // console.log(data, arr)

  // set arr object in doamin useState for create and update doamin and also change any field according to the current user
  const [domain, setDomain] = useState(arr);
  // console.log(domain)

  const handleChange = (e) => {
    // if (e.target.name  === "planTotal") {
    //   setDomain({
    //     ...domain,
    //     [e.target.name]: e.target.value === "" ? 0 : e.target.value < 0 ? 0 : e.target.value,
    //   });
    // }else{
      setDomain({
        ...domain,
        [e.target.name]: e.target.value,
      });      
    // }
  };
  // added by ranjit
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default Enter behavior
    }
  };
  const centeredPlaceholderStyle = {
    lineHeight: "3rem",
    height: "2rem",
    padding: "0",
  };
  //

  // console.log(233, domain)

  // set of group style

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  //options skills
  var roleData = [];
  roles.map((e) => {
    roleData.push({ value: `${e._id}`, label: `${e.roleName}` });
  });

  //options skills
  var skillsData = [];
  skills.map((e) => {
    skillsData.push({ value: `${e._id}`, label: `${e.skillName}` });
  });

  //options skills
  var Data = [];
  peoples.map((e) => {
    Data.push({ value: `${e._id}`, label: `${e.fname} ${e.lname}` });
  });

  // All current Domain filed options

  var options = Data;

  const colourOptions = skillsData;

  const flavourOptions = roleData;

  const groupedOptions = [
    {
      label: "Person",
      options: [{ value: "63d3c91d40c4cf67373922e2", label: "Person" }],
    },
    {
      label: "Skills",
      options: colourOptions,
    },
    {
      label: "Roles",
      options: flavourOptions,
    },
  ];

  // set format group label

  const formatGroupLabel = (GroupedOption) => (
    <div style={groupStyles}>
      <span>{GroupedOption.label}</span>
      <span style={groupBadgeStyles}>{GroupedOption.options.length}</span>
    </div>
  );

  // set stand in for update current domain

  let cuStandIn = {};
  if (arr.standIn) {
    for (let i = 0; i < peoples.length; i++) {
      let inc = [data.standIn].includes(peoples[i]._id);
      if (inc) {
        cuStandIn = {
          value: `${peoples[i]._id}`,
          label: `${peoples[i].fname} ${peoples[i].lname}`,
        };
      }
    }
  }

  // set ownerType for update current domain

  let cuOwnerType = [];
  if (arr.owners) {
    if (arr.owners.length > 0 && arr.owners.type) {
      if (cuOwnerType) {
        for (let i = 0; i < skills.length; i++) { }
      }
    }
  }

  // set and change stand id according user

  const [values, setValues] = React.useState(cuStandIn);

  // console.log(111, cuStandIn, values, owner);

  if (values && values.value && values.value.length > 0) {
    domain.standIn = values.value;
  }

  // Current Domain tasks

  let taskData = [];
  if (domain.tasks.length > 0) {
    domain.tasks.map((e) => {
      taskData.push({ tasks: e });
    });
    taskData.push({ tasks: "" });
  }

  if (taskData.length === 0) {
    taskData.push({ tasks: "" });
  }

  const [formValues, setFormValues] = useState(taskData);

  let handleForm = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    let len = formValues.length;
    if (len > 0) {
      if (formValues[len - 1].tasks.length > 0) {
        setFormValues([...formValues, { tasks: "" }]);
      }
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const [isClicking, setIsCliking] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addFormFields(e);
      setIsCliking(true);
    }
    // e.preventDefault()
  };

  useEffect(() => {
    const textarea = document.getElementById(`task${formValues.length - 1}`);
    if (textarea && isClicking) {
      textarea.focus();
      setIsCliking(false);
    }
  }, [formValues, setFormValues]);

  let newTasks = [];
  if (formValues.length > 0) {
    formValues.map((e) => {
      if (e.tasks.length > 0) {
        newTasks.push(e.tasks);
      }
    });
  }
  domain.tasks = newTasks;

  //Current Owner ownerType and Owners sets
  var ownerTypes = {};
  var Owners = {};
  if (domain && domain.owners) {
    if (domain.owners.type && domain.owners.type.length > 0) {
      for (let i = 0; i < skills.length; i++) {
        let inc = [domain.owners.type].includes(skills[i]._id);
        if (inc) {
          ownerTypes = { value: skills[i]._id, label: skills[i].skillName };
          if (domain.owners.owner) {
            let incs = skills[i].owners.includes(domain.owners.owner);
            if (incs) {
              for (let j = 0; j < peoples.length; j++) {
                let incp = [domain.owners.owner].includes(peoples[j]._id);
                if (incp) {
                  Owners = {
                    value: peoples[j]._id,
                    label: `${peoples[j].fname} ${peoples[j].lname}`,
                  };
                  break;
                }
              }
            }
            break;
          }
        }
      }
      if (Object.keys(ownerTypes).length === 0) {
        for (let j = 0; j < roles.length; j++) {
          let inc = [domain.owners.type].includes(roles[j]._id);
          if (inc) {
            ownerTypes = { value: roles[j]._id, label: roles[j].roleName };
            if (domain.owners.owner) {
              let incs = roles[j].owners.includes(domain.owners.owner);
              if (incs) {
                for (let k = 0; k < peoples.length; k++) {
                  let incp = [domain.owners.owner].includes(peoples[k]._id);
                  if (incp) {
                    Owners = {
                      value: peoples[k]._id,
                      label: `${peoples[k].fname} ${peoples[k].lname}`,
                    };
                    break;
                  }
                }
              }
              break;
            }
          }
        }
      }
      if (Object.keys(ownerTypes).length === 0) {
        if (domain.owners.type == "63d3c91d40c4cf67373922e2") {
          ownerTypes = { value: "63d3c91d40c4cf67373922e2", label: "Person" };
          if (domain.owners.owner) {
            for (let i = 0; i < peoples.length; i++) {
              let inc = [domain.owners.owner].includes(peoples[i]._id);
              if (inc) {
                Owners = {
                  value: peoples[i]._id,
                  label: `${peoples[i].fname} ${peoples[i].lname}`,
                };
              }
            }
          }
        }
      }
    }
  }

  const [ownerType, setOwnerType] = useState(ownerTypes);

  var newOwners = [];
  if (newOwners && ownerType) {
    for (let i = 0; i < skills.length; i++) {
      let inc = [ownerType.value].includes(skills[i]._id);
      if (inc) {
        newOwners = skills[i].owners;
        break;
      }
    }
  }

  if (newOwners && ownerType) {
    for (let i = 0; i < roles.length; i++) {
      let inc = [ownerType.value].includes(roles[i]._id);
      if (inc) {
        newOwners = roles[i].owners;
        break;
      }
    }
  }

  if (newOwners && ownerType) {
    if (ownerType.value === "63d3c91d40c4cf67373922e2") {
      newOwners = options;
    }
  }

  let curOwners = [];
  if (
    newOwners &&
    ownerType &&
    ownerType.value !== "63d3c91d40c4cf67373922e2"
  ) {
    if (newOwners.length > 0) {
      for (let i = 0; i < peoples.length; i++) {
        let inc = newOwners.includes(peoples[i]._id);
        if (inc) {
          curOwners.push({
            value: peoples[i]._id,
            label: `${peoples[i].fname} ${peoples[i].lname}`,
          });
        }
      }
    }
  }

  if (
    curOwners.length === 0 &&
    ownerType &&
    ownerType.value === "63d3c91d40c4cf67373922e2"
  ) {
    curOwners = newOwners;
  }

  const [owner, setOwner] = useState(Owners);

  // console.log(562, owner)
  useEffect(() => {
    if (owner || ownerType) {
      let obj = {};
      obj.type = ownerType && ownerType.value ? ownerType.value : null;
      obj.owner = owner && owner.value ? owner.value : null;
      setDomain({
        ...domain,
        ["owners"]: obj,
      });
    }
  }, [owner, ownerType]);

  const typeHandelChange = (typeData) => {
    if (typeData && Object.keys(typeData).length > 0) {
      setOwnerType(typeData);
    } else {
      setOwner({});
      setOwnerType({});
      setValues({});
    }
  };

  const handleDomainOwnerChange = (newvalue) => {
    // console.log(613,newvalue);
    if (newvalue && Object.keys(newvalue).length > 0) {
      setOwner(newvalue);
      setWorkLoadState([]);
    } else if (domain.standIn) {
      Swal.fire({
        title: "Note",
        text: "If you delete the owner the standin will also be deleted!",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          setOwner({});
          setWorkLoadState([]);
          setValues({});
        }
      });
    } else {
      setOwner({});
      setWorkLoadState([]);
      setValues({});
    }
  };

  const handleDomainStandInChange = (newValue) => {
    // console.log(newValue, owner)
    if (newValue && newValue.value === owner.value) {
      Swal.fire({
        title: "Error",
        text: "Domain owner and stand-in cannot be the same person!",
        // icon: "warning",
        // showCancelButton: true,
        confirmButtonColor: "#3085d6",
        // cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    } else {
      setValues(newValue);
    }
  };

  // Current Owner Tags

  let curTag = [];
  if (domain.newTag.length > 0) {
    for (let i = 0; i < domain.newTag.length; i++) {
      curTag.push({ userId: myInfo.userId, tags: [domain.newTag[i]] });
    }
  }

  // Get Current Owner Time
  const currentTime = () => {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date();
    var day = days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }
    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();
    let curTime =
      month + " " + date + ", " + year + " " + hr + ":" + min + " " + ampm;
    return curTime;
  };

  // Set Current Owner Historys
  let historyState = [];

  if (data && Object.keys(data).length > 0) {
    if (data.domainName || domain.domainName) {
      if (data.domainName !== domain.domainName) {
        if (data.domainName.length > 0 && domain.domainName.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Domain Name",
            prev: data.domainName,
            next: domain.domainName,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.purpose || domain.purpose) {
      if (data.purpose !== domain.purpose) {
        if (
          (data.purpose === "" || data.purpose === undefined) &&
          domain.purpose.length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: domain.purpose,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (
          (data.purpose.length > 0 || data.purpose !== undefined) &&
          domain.purpose.length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: domain.purpose,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (data.purpose.length > 0 && domain.purpose === "") {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: domain.purpose,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.tasks || domain.tasks) {
      if (
        data.tasks &&
        domain.tasks &&
        data.tasks.join() !== domain.tasks.join()
      ) {
        if (
          data.tasks &&
          domain.tasks &&
          data.tasks.join() === "" &&
          domain.tasks.length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: data.tasks.join(),
            next: domain.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (
          data.tasks &&
          data.tasks.join().length > 0 &&
          domain.tasks &&
          domain.tasks.join().length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: data.tasks.join(),
            next: domain.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (
          data.tasks &&
          data.tasks.join().length > 0 &&
          domain.tasks &&
          domain.tasks.join() === ""
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: data.tasks.join(),
            next: domain.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    // if (data.owner || link.owner) {
    //   let oldOwners = ''
    //   let newOwners = ''
    //   if (peoples && peoples.length > 0) {
    //     for (let i = 0; i < peoples.length; i++) {
    //       if (peoples[i]._id === data.owner) {
    //         oldOwners = `${peoples[i].fname} ${peoples[i].lname}`
    //       }
    //       if (peoples[i]._id === link.owner) {
    //         newOwners = `${peoples[i].fname} ${peoples[i].lname}`
    //       }
    //     }
    //   }
    //   if (oldOwners !== newOwners) {
    //     if (oldOwners === '' && newOwners.length > 0) {
    //       let obj = {
    //         cardId: data._id,
    //         teamId: teamId,
    //         field: 'Owner',
    //         prev: oldOwners,
    //         next: newOwners,
    //         hcTime: currentTime(),
    //         cardStatus: 'added'
    //       }
    //       historyState.push(obj)
    //     } else if (oldOwners.length > 0 && newOwners.length > 0) {
    //       let obj = {
    //         cardId: data._id,
    //         teamId: teamId,
    //         field: 'Owner',
    //         prev: oldOwners,
    //         next: newOwners,
    //         hcTime: currentTime(),
    //         cardStatus: 'changed'
    //       }
    //       historyState.push(obj)
    //     } else if (oldOwners.length > 0 && newOwners === '') {
    //       let obj = {
    //         cardId: data._id,
    //         teamId: teamId,
    //         field: 'Owner',
    //         prev: oldOwners,
    //         next: newOwners,
    //         hcTime: currentTime(),
    //         cardStatus: 'removed'
    //       }
    //       historyState.push(obj)
    //     }
    //   }
    // }
    if (data.standIn || domain.standIn) {
      let oldStandIn = "";
      let newStandIn = "";
      if (peoples && peoples.length > 0) {
        for (let i = 0; i < peoples.length; i++) {
          if (peoples[i]._id === data.standIn) {
            oldStandIn = `${peoples[i].fname} ${peoples[i].lname}`;
          }
          if (peoples[i]._id === domain.standIn) {
            newStandIn = `${peoples[i].fname} ${peoples[i].lname}`;
          }
        }
      }
      if (oldStandIn !== newStandIn) {
        if (oldStandIn === "" && newStandIn.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Owner",
            prev: oldStandIn,
            next: newStandIn,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (oldStandIn.length > 0 && newStandIn.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Owner",
            prev: oldStandIn,
            next: newStandIn,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (oldStandIn.length > 0 && newStandIn === "") {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Owner",
            prev: oldStandIn,
            next: newStandIn,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (
      (data.tags && data.tags.length > 0) ||
      (domain.tags && domain.tags.length > 0)
    ) {
      if (data.tags && domain.tags && data.tags.join() !== domain.tags.join()) {
        if (data.tags.join() === "" && domain.tags.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: domain.tags.join(),
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (data.tags.length > 0 && domain.tags.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: domain.tags.join(),
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (data.tags.length > 0 && domain.tags.length === 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: domain.tags.join(),
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
  }

  const handelUpdate = async () => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    let cuSkills = localStorage.getItem("skillSort");
    let currole = localStorage.getItem("roleSort");

    domain.standIn = domain.owners.owner && values ? values.value : null;

    //update
    let domainSort = localStorage.getItem("domainSort");
    if (domain._id) {
      dispatch(updateDomain({ ...domain, domainSort })).then(() => {
        dispatch(getSkills({ ...obj, curskill: cuSkills }));
        dispatch(getRole({ ...obj, currole: currole }));
        dispatch(historyCreate(historyState));
      });
      // console.log(domain)
    }
    dispatch({ type: HISTORY_DATAS_CLEAR });
    return true;
  };

  // Current Owner Next And Previous
  const handlePreviosNext = async (data) => {
    dispatch({ type: HISTORY_DATAS_CLEAR });
    let curUpdateData = [];
    if (domains && domains.length > 0 && isNToP && isNToP.length > 0) {
      for (let i = 0; i < isNToP.length; i++) {
        for (let j = 0; j < domains.length; j++) {
          if (domains[j]._id === isNToP[i]._id) {
            curUpdateData.push(domains[j]);
            break;
          }
        }
      }
    }
    if (data === "prev") {
      if (curUpdateData && curUpdateData.length > 0) {
        for (let i = 0; i < curUpdateData.length; i++) {
          if (curUpdateData[i]._id === domain._id && i >= 0) {
            localStorage.setItem("npState", "Domain");
            let index = i === 0 ? curUpdateData.length - 1 : i - 1;
            await handelUpdate().then(() => {
              setDomains(curUpdateData[index], curUpdateData, 0, "Domain");
            });
          }
        }
      }
    } else if (data === "next") {
      if (curUpdateData && curUpdateData.length > 0) {
        for (let i = 0; i < curUpdateData.length; i++) {
          if (
            curUpdateData[i]._id === domain._id &&
            i <= curUpdateData.length - 1
          ) {
            localStorage.setItem("npState", "Domain");
            let index = i < curUpdateData.length - 1 ? i + 1 : 0;
            await handelUpdate().then(() => {
              setDomains(curUpdateData[index], curUpdateData, 0, "Domain");
            });
          }
        }
      }
    }
  };

  // Copy Current Domain
  const domainCopy = (e) => {
    e.preventDefault();
    dispatch({ type: HISTORY_DATAS_CLEAR });
    setDomain({
      ...domain,
      _id: "",
      domainName: domain.domainName + " (Copy)",
    });
    // dispatch(createDomain(datas))
  };

  // Create And update Action
  const [documentData, setDocument] = useState();
  const domainSubmit = (e) => {
    e.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    let skillSort = localStorage.getItem("skillSort");
    let roleSort = localStorage.getItem("roleSort");
    let domainSort = localStorage.getItem("domainSort");

    domain.standIn = domain.owners.owner && values ? values.value : null;
    localStorage.setItem("npState", "");
    //update
    domain.documents = documentData
    
    if (domain._id) {
      dispatch(
        updateDomain({ ...domain, domainSort, skillSort, roleSort })
      ).then(() => {
        dispatch(historyCreate(historyState));
      });
    } else {
      //create
      dispatch(
        createDomain({ ...domain, domainSort, skillSort, roleSort })
      ).then(() => {
        // dispatch(getSkills({ ...obj, curskill: cuSkills }));
        // dispatch(getRole({ ...obj, currole: currole }));
      });
      // console.log("949",domain)
    }
    dispatch({ type: HISTORY_DATAS_CLEAR });
  };

  useEffect(() => {
    if (CloseState && CloseState === "Domains") {
      // console.log("outside click");
      stateCloseState("");
      //update
      let obj = {};
      obj.teamId = localStorage.getItem("teamId");
      obj.userId = myInfo.userId;
      let skillSort = localStorage.getItem("skillSort");
      let roleSort = localStorage.getItem("roleSort");
      let domainSort = localStorage.getItem("domainSort");

      domain.standIn = domain.owners.owner && values ? values.value : null;
      localStorage.setItem("npState", "");
      //update
      if (domain._id) {
        domain.documents = documentData
        dispatch(
          updateDomain({ ...domain, domainSort, skillSort, roleSort })
        ).then(() => {
          dispatch(historyCreate(historyState));
        });
      } else {
        //create
        domain.documents = documentData
        dispatch(
          createDomain({ ...domain, domainSort, skillSort, roleSort })
        ).then(() => { });
      }
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  }, [CloseState, stateCloseState]);

  // Current Domain Delete Action

  const domainDelete = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure?",
      text: "You can still restore this from trash!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.adminId = domain.adminId;
        obj.url = `${SERVER_URI}/user/person/delete`;
        obj.domainId = domain._id;
        obj.softdelete = true;
        dispatch(deleteDomain(obj)).then(() => {
          dispatch(getState({ userId: myInfo.userId, teamId: obj.teamId }));
          dispatch({ type: DOMAIN_ID_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
          closeDomain();
        });
        // closeDomain()
      }
    });
  };

  // console.log(domainMessagee);
  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (domainMessagee) {
      dispatch(getState(obj));
      dispatch({ type: DOMAIN_ID_CLEAR });
      dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
      let curState = localStorage.getItem("npState");

      if (curState !== "Domain") {
        closeDomain();
      }

      dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
    }

    if (domainError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: domainError,
      });
      // alert.error(peopleError)
      dispatch({ type: DOMAIN_ERROR_CLEAR });
      // dispatch(getDomain(obj));
      // closeDomain();
    }
    dispatch(getTags({ userId: myInfo.userId }));
  }, [domainMessagee, domainError, cuDomainId]);

  // Current Domain History get and set

  const historysGet = (data) => {
    if (historys && historys.length === 0) {
      dispatch(historyGet({ cardId: data }));
    } else {
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  };

  useEffect(() => {
    if (historyMessage) {
      dispatch({ type: HISTORY_MESSAGE_CLEAR });
    }
  }, [historyMessage && historys]);

  // Set message current domain according to fields
  let historysMessage = [];

  if (historys && historys.length > 0) {
    for (let i = 0; i < historys.length; i++) {
      if (historys[i].cardStatus === "created") {
        let str = `<i>${historys[i].hcTime}</i> : Created`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "added") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} added ` +
          " (" +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "changed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} changed from ` +
          `<b>${historys[i].prev}</b>` +
          " to " +
          `<b>${historys[i].next}</b>`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "removed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} removed ` +
          " (previously: " +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      }
    }
  }

  const CloseDomainModal = () => {
    dispatch({ type: HISTORY_DATAS_CLEAR });
    closeDomain();
  };

  const ref = useRef(null);

  const handleClick = () => {
    ref.current.focus();
  };

  //added by ranjit
  const onDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const onDragOver = (e, index) => {
    e.preventDefault();
  };

  const onDrop = (e, index) => {
    const draggedIndex = e.dataTransfer.getData("index");
    const items = [...formValues];
    const draggedItem = items[draggedIndex];
    items.splice(draggedIndex, 1);
    items.splice(index, 0, draggedItem);
    setFormValues(items);
  };

  const CustomOption = ({ innerProps, label, value }) => {
    let flag = false;
    if (peoples && peoples.length > 0) {
      for (let i = 0; i < peoples.length; i++) {
        if (peoples[i]._id === value && peoples[i].workRole === "External") {
          flag = true;
          break;
        }
      }
    }
    let data = ``;
    if (flag) {
      data += `<span class="custom-badge blue">EXT</span>`;
    }
    data += label;
    return (
      <div {...innerProps} className="tdListItem">
        <span dangerouslySetInnerHTML={{ __html: data }} />
      </div>
    );
  };

  const isExtPerson = (Id) => {
    if (peoples && peoples.length > 0) {
      let flag = false;
      for (let i = 0; i < peoples.length; i++) {
        if (peoples[i]._id === Id && peoples[i].workRole === "External") {
          flag = true;
          break;
        }
      }
      return flag;
    }
  };

  const sSIsExtrnal = (e) => {
    if (e.ownerType === "Single owner") {
      for (let i = 0; i < peoples.length; i++) {
        if (e.owners[0] === peoples[i]._id) {
          if (peoples[i].workRole === "External") {
            return true;
          }
        }
      }
    } else if (e.ownerType === "Multiple owners") {
      let count = 0;
      for (let i = 0; i < peoples.length; i++) {
        let inc = e.owners.includes(peoples[i]._id);
        if (inc) {
          if (peoples[i].workRole === "External") {
            count++;
          }
        }
      }
      if (count === e.owners.length && count !== 0) {
        return true;
      }
    }
    return false;
  };

  const sRIsExtrnal = (e) => {
    if (e.owners && e.owners.length > 0) {
      if (e.ownerRole === "Single owner") {
        for (let i = 0; i < peoples.length; i++) {
          if (e.owners[0] === peoples[i]._id) {
            if (peoples[i].workRole === "External") {
              return true;
            }
          }
        }
      } else if (e.ownerRole === "Multiple owners") {
        let count = 0;
        for (let i = 0; i < peoples.length; i++) {
          let inc = e.owners.includes(peoples[i]._id);
          if (inc) {
            if (peoples[i].workRole === "External") {
              count++;
            }
          }
        }
        if (count === e.owners.length) {
          return true;
        }
      }
    }
    return false;
  };

  const isExtType = (Id) => {
    if (Id && Id.length > 0) {
      let curData = false;
      let flag = false;
      if (Id === "63d3c91d40c4cf67373922e2") {
        flag = true;
      } else {
        if (skills && skills.length > 0 && !flag) {
          for (let i = 0; i < skills.length; i++) {
            if (skills[i]._id === Id) {
              flag = true;
              curData = sSIsExtrnal(skills[i]);
              break;
            }
          }
        }
        if (roles && roles.length > 0 && !flag) {
          for (let i = 0; i < roles.length; i++) {
            if (roles[i]._id === Id) {
              flag = true;
              curData = sRIsExtrnal(roles[i]);
              break;
            }
          }
        }
      }
      return curData;
    }
  };

  const outerBoxClick = () => {
    if (
      formValues &&
      formValues.length > 0 &&
      formValues[formValues.length - 1].tasks &&
      formValues[formValues.length - 1].tasks.length > 0
    ) {
      addFormFields();
      setIsCliking(true);
    }
  };

  const [workloadState, setWorkLoadState] = useState(domain.workload || []);

  const handleWorkloadChange = (sv, inputVal) => {
    if(inputVal > 1){
      inputVal = 1;
    }else if(inputVal < 0){
      inputVal = 0;
    }
    // Find the index of the item in workloadState that matches the ownerId (sv.value)
    const existingIndex = workloadState.findIndex(
      (item) => item && item.ownerId === sv.value
    );
    // Create a new copy of the workloadState array
    const updatedWorkloadState = [...workloadState];

    if (existingIndex !== -1) {
      // If the item already exists, update its plan
      updatedWorkloadState[existingIndex] = {
        ...updatedWorkloadState[existingIndex],
        plan: inputVal,
      };
    } else {
      // If the item does not exist, add a new item
      updatedWorkloadState.push({
        ownerId: sv.value,
        plan: inputVal,
      });
    }

    setDomain({ ...domain, workload: updatedWorkloadState });
    // Update the state with the new workloadState array
    setWorkLoadState(updatedWorkloadState);
  };

  return (
    <div
      id="domainModal"
      onClick={() => outerBoxClick()}
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <form className="form" onSubmit={(e) => domainSubmit(e)}>
          {/* Modal content*/}

          <div className="modal-content">
            <div className="modal-header header-blue">
              <div className="row ">
                <div className="col-xs-8 text-left">
                  <h4 className="modal-title">
                    <img
                      src="images/icon-domain.png"
                      style={{
                        width: "17px",
                        marginTop: "-3px",
                        marginRight: "5px",
                      }}
                    />{" "}
                    DOMAIN
                  </h4>
                </div>
                {compareMode.active || (focusMode && focusMode.length > 0) ? null : (
                  <div className="col-xs-4 text-right">
                    <Tippy content={<span>Previous Domain</span>}>
                      <button
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        type="button"
                        className="btn btn-mytpt-gray show-prev"
                        onClick={() => handlePreviosNext("prev")}
                      // disabled
                      >
                        <i className="fa fa-angle-left"></i>
                      </button>
                    </Tippy>
                    <Tippy content={<span>Next Domain</span>}>
                      <button
                        style={{ cursor: "pointer" }}
                        type="button"
                        className="btn btn-mytpt-gray show-next"
                        onClick={() => handlePreviosNext("next")}
                      // disabled
                      >
                        <i className="fa fa-angle-right"></i>
                      </button>
                    </Tippy>
                  </div>
                )}
              </div>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextareaAutosize
                      type="text"
                      ref={ref}
                      name="domainName"
                      value={domain.domainName}
                      onKeyDown={handleKeyDown}
                      readOnly={domain.defaultDomain}
                      onChange={handleChange}
                      className="form-control tool-name logit"
                      placeholder="+ Add domain name"
                      style={centeredPlaceholderStyle}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <h4>Purpose</h4>
                    <TextareaAutosize
                      cacheMeasurements
                      type="text"
                      value={domain.purpose}
                      onChange={handleChange}
                      className="form-control purpose logit"
                      name="purpose"
                      placeholder="+ Add purpose"
                    />
                  </div>

                  <div className="form-group tasks">
                    <Tasks
                      formValues={formValues}
                      onDragStart={onDragStart}
                      onDragOver={onDragOver}
                      onDrop={onDrop}
                      handleForm={handleForm}
                      handleKeyPress={handleKeyPress}
                      removeFormFields={removeFormFields}
                    />
                  </div>

                  <div className="form-group objectives">
                    <Objectives data={domain} domain={domain} />
                  </div>

                  <WorkLoadSRDL
                    handleChange={handleChange}
                    planTotal={parseFloat(domain.planTotal)}
                    status={sumFTE(domain.workload, [owner])}
                  />

                </div>

                <div className="col-md-6">
                  <div className="form-group ss">
                    <h4>Owner</h4>
                    <div className="row">
                      <div className="col-xs-5" style={{ paddingRight: 0 }}>
                        <div className="flag">
                          <label>Type:</label>
                        </div>
                        <Select
                          closeMenuOnSelect={true}
                          classNamePrefix="select"
                          className="single-select-container"
                          isClearable
                          isSearchable
                          formatGroupLabel={formatGroupLabel}
                          onChange={(newValue) => typeHandelChange(newValue)}
                          // value={ownerType && ownerType.value && ownerType.value.length > 0 ? [ownerType] : []}
                          value={
                            ownerType &&
                              ownerType.value &&
                              ownerType.value.length > 0
                              ? [
                                {
                                  value: ownerType.value,
                                  label: (
                                    <div style={{ fontSize: "14px" }}>
                                      {/* {ownerType.value &&
                                        isExtType(ownerType.value) ? (
                                        <span className="custom-badge blue">
                                          EXT
                                        </span>
                                      ) : null}
                                      {ownerType.label} */}

                                      {ownerType.value &&
                                        isExtType(ownerType.value) ? (
                                        <>
                                          <span className="custom-badge blue" style={{ display: 'inline-block' }}>
                                            EXT
                                          </span> {ownerType.label}
                                        </>
                                      ) : ownerType.label}
                                    </div>
                                  ),
                                },
                              ]
                              : []
                          }
                          options={groupedOptions}
                          placeholder="+ Add owner type"
                        // menuIsOpen={true}
                        />
                      </div>
                      <div className="col-xs-5" style={{ paddingRight: 0 }}>
                        <div className="flag">
                          <label>Owner:</label>
                        </div>
                        <Select
                          closeMenuOnSelect={true}
                          classNamePrefix="select"
                          className="single-select-container"
                          // components={animatedComponents}
                          isClearable
                          isSearchable
                          onChange={(newValue) =>
                            handleDomainOwnerChange(newValue)
                          }
                          // value={owner && owner.value && owner.value.length > 0 ? [owner] : []}
                          value={
                            owner && owner.value && owner.value.length > 0
                              ? [
                                {
                                  value: owner.value,
                                  label: (
                                    <div style={{ fontSize: "14px" }}>
                                      {/* {owner.value &&
                                        isExtPerson(owner.value) ? (
                                          <span className="custom-badge blue">
                                            EXT
                                          </span>
                                        ) : null}
                                        {owner.label} */}

                                      {owner.value &&
                                        isExtPerson(owner.value) ? (
                                        <>
                                          <span className="custom-badge blue" style={{ display: 'inline-block' }}>
                                            EXT
                                          </span> {owner.label}
                                        </>
                                      ) : owner.label}
                                    </div>
                                  ),
                                },
                              ]
                              : []
                          }
                          options={[...curOwners]}
                          placeholder="+ Add owner"
                          components={{ Option: CustomOption }}
                        />
                      </div>
                      <div className="col-xs-2">
                        <div className="flag">
                          <label>FTE</label>
                        </div>
                        {/* {console.log(1645,owner,owner && Object.keys(owner).length  && owner.value? false : true)} */}
                        <input
                          type="number"
                          // id={`plan-${index}`}
                          className="form-control fte"
                          // name={`plan-${index}`}
                          placeholder="Max: 1"
                          value={workloadState && workloadState.length > 0 && workloadState[0] ? workloadState[0].plan : ""} // Bind the input value to planValue
                          step="any"
                          onChange={(e) =>
                            handleWorkloadChange(owner, e.target.value)
                          }
                          disabled = {owner && Object.keys(owner) && owner.value? false : true}
                          onWheel={numberInputOnWheelPreventChange}
                        />
                      </div>
                    </div>
                  </div>

                  {owner && owner.value && owner.value.length > 0 ? null : (
                    <div
                      className="form-group flag"
                      style={{ display: "block" }}
                    >
                      <label>
                        Domains without owners will be flagged:{" "}
                        <span className="custom-badge red">NO</span>
                      </label>
                    </div>
                  )}

                  <div className="form-group domain-stand-in ss">
                    <h4>Stand In</h4>
                    <Select
                      closeMenuOnSelect={true}
                      classNamePrefix="select"
                      className="single-select-container"
                      // components={animatedComponents}
                      isClearable
                      isSearchable
                      onChange={(newValue) => {
                        handleDomainStandInChange(newValue);
                      }}
                      // Added by Indranil on 11-12-2023
                      value={
                        owner &&
                          values &&
                          values.value &&
                          values.value.length > 0
                          ? [
                            {
                              value: values.value,
                              label: (
                                <div style={{ fontSize: "14px" }}>
                                  {values.value &&
                                    isExtPerson(values.value) ? (
                                    <span className="custom-badge blue">
                                      EXT
                                    </span>
                                  ) : null}
                                  {values.label}
                                </div>
                              ),
                            },
                          ]
                          : []
                      }
                      isDisabled={
                        !owner || !owner.value || owner.value.length === 0
                          ? true
                          : false
                      }
                      options={options}
                      placeholder="+ Add stand-in"
                      components={{ Option: CustomOption }}
                    />
                  </div>

                  <div className="form-group member-in">
                    <h4>Member In</h4>
                    <div className="flag">
                      <label>This domain is currently member in:</label>
                    </div>
                    <div
                      className="row"
                      style={{ marginRight: 0, marginLeft: 0 }}
                    >
                      {memberIds && memberIds.length > 0
                        ? memberIds.map((e, index) => {
                          if (
                            !e.memberName ||
                            e.memberName === "" ||
                            e.memberName === null
                          )
                            return;

                          return (
                            <div key={index} className="col-xs-12">
                              {e.memberName}
                              {e.XTD.length > 0 && e.XTD === "XTD" ? (
                                <span
                                  style={{
                                    display: "inline-block",
                                    marginBottom: "0",
                                  }}
                                  className="custom-badge lightgray person-pill text-red"
                                >
                                  <span className="text-red">XTD</span>
                                </span>
                              ) : null}
                              <a
                                className="remove"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "60%",
                                }}
                              >
                                <span
                                  onClick={() => memberHandel(e)}
                                  className="fa fa-times"
                                ></span>
                              </a>
                            </div>
                          );
                        })
                        : null}
                    </div>
                  </div>
                  <Documents data={data} domain={domain} setDocumentData={setDocument} />
                  
                  <div className="form-group tags">
                    <h4>Tags</h4>
                    <Tags
                      data={arr}
                      onChange={handleChange}
                      domain={domain}
                      name="tags"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="row ">
                <div className="col-xs-4 text-left">
                  {localStorage.getItem("mode") !== "View" && domain._id && !domain.defaultDomain ? (
                    <Tippy content={<span>Copy This Domain</span>}>
                      <button
                        type="button"
                        onClick={(e) => domainCopy(e)}
                        className="btn btn-mytpt-gray clone"
                        data-id="domainModal"
                      >
                        <img alt="" src="./images/newicons/copy.svg" />
                      </button>
                    </Tippy>
                  ) : null}

                  {localStorage.getItem("mode") !== "View" && domain._id && !domain.defaultDomain ? (
                    <Tippy content={<span>Delete This Domain</span>}>
                      <button
                        type="button"
                        onClick={domainDelete}
                        className="btn btn-mytpt-gray delete"
                        data-id="domainModal"
                        data-post_type="domain"
                      >
                        <img alt="" src="./images/newicons/delete.svg" />
                      </button>
                    </Tippy>
                  ) : null}

                  <Tippy content={<span>View History</span>}>
                    <button
                      type="button"
                      onClick={() => historysGet(domain._id)}
                      style={
                        historysMessage.length > 0
                          ? { backgroundColor: "#F3D068" }
                          : null
                      }
                      className="btn btn-mytpt-gray history"
                    >
                      <img alt="" src="./images/newicons/history.svg" />
                    </button>
                  </Tippy>
                </div>
                <div className="col-xs-8 text-right">
                  <Tippy content={<span>Close Tool</span>}>
                    <button
                      type="button"
                      onClick={CloseDomainModal}
                      className="btn btn-mytpt-red"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </Tippy>
                  {localStorage.getItem("mode") !== "View" && (                    
                  <Tippy content={<span>Save And Close</span>}>
                    <button
                      type="submit"
                      className="btn btn-mytpt save-close"
                      data-modal_id="domainModal"
                    >
                      <i className="fa fa-check"></i>
                    </button>
                  </Tippy>
                  )}
                </div>
              </div>
              {historysMessage.length > 0 ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>History</h4>
                    {historysMessage.reverse().map((e, index) => {
                      return (
                        <p
                          key={index}
                          dangerouslySetInnerHTML={{ __html: e }}
                        ></p>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
