import React, { useEffect, useState } from "react";
import Select from "react-select";
// import JSON from 'json'
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";

import { SERVER_URI } from "../../../../config/keys";
import { createAndUpdateMember, getPFMember } from "../../../../store/actions/pfmemberAction";
import { updateMember, getMember } from "../../../../store/actions/memberAction";
import { PCTOOL_CREATE_SUCCESS, PCTOOL_CREATE_FAIL, PCTOOL_GET_SUCCESS, PCTOOL_GET_FAIL, SIGN_OUT } from "../../../../store/types/toolsType";

export default function CircleModelTool({ closeCircleModeTool, focused, setFocused, temporarilyMovedItem, setTemporarilyMovedItem }) {
  let dispatch = useDispatch();

  const { loading, successMessage, error, authenticate, myInfo } = useSelector((state) => state.auth);
  const { peoples, peopleMessagee, errors } = useSelector((state) => state.peoples);
  const { circleTools, toolSuccessMessage, toolErrors } = useSelector((state) => state.tools);

  let adminId = myInfo.userId;
  let teamId = localStorage.getItem("teamId");

  const [tools, setTools] = useState([]);
  const [cmtype, SetCmType] = useState("Core");
  const [allp, setAllp] = useState(false);
  const [singleOwnerType, setSingleOwnerType] = useState(false);
  const [headName, setHeadName] = useState({
    adding: "",
    to: ""
  })

  let dragCards = localStorage.getItem("dragCard");
  let cardData = JSON.parse(localStorage.getItem("dragData"));
  let fcDatas = JSON.parse(localStorage.getItem("fcDatas"));
  let TypeLoc = localStorage.getItem("TypeLoc")

  useEffect(() => {
    if (headName && headName.adding.length === 0) {
      if (dragCards && dragCards === "Skills") {
        if (cardData && Object.keys(cardData).length > 0 && cardData.skillName) {
          setHeadName({
            ...headName,
            ["adding"]: `Adding: ${cardData.skillName} (Skill)`
          })
        }
      } else if (dragCards && dragCards === "Roles") {
        if (cardData && Object.keys(cardData).length > 0 && cardData.roleName) {
          setHeadName({
            ...headName,
            ["adding"]: `Adding: ${cardData.roleName} (Role)`
          })
        }
      }
    }
  }, [dragCards, cardData])

  useEffect(() => {
    if (headName && headName.to.length === 0) {
      if (focused && focused.card && focused.card === "Circles") {
        if (fcDatas && Object.keys(fcDatas).length > 0 && fcDatas.circleName) {
          setHeadName({
            ...headName,
            ["to"]: `To: ${fcDatas.circleName} (Circle)`
          })
        }
      } else if (focused && focused.card && focused.card === "Projects") {
        if (fcDatas && Object.keys(fcDatas).length > 0 && fcDatas.projectName) {
          setHeadName({
            ...headName,
            ["to"]: `To: ${fcDatas.projectName} (Project)`
          })
        }
      }
    }
  }, [fcDatas, focused])

  useEffect(() => {
    if (TypeLoc && TypeLoc.length > 0) {
      SetCmType(TypeLoc)
    }
  }, [TypeLoc])

  useEffect(() => {
    let inTools = [];
    if (cardData && Object.keys(cardData).length > 0 && tools.length === 0) {
      var userRdata = [];
      if (cardData.owners && cardData.owners.length > 0 && peoples && peoples.length > 0) {
        for (let i = 0; i < peoples.length; i++) {
          for (let j = 0; j < cardData.owners.length; j++) {
            if (peoples[i]._id === cardData.owners[j] && !inTools.includes(peoples[i]._id)) {
              inTools.push(peoples[i]._id);

              userRdata.push({
                name: `${peoples[i].fname ? peoples[i].fname : ""} ${peoples[i].lname ? peoples[i].lname : ""}`,
                CardId: cardData._id,
                peopleIds: peoples[i]._id,
                isSelect: false,
              });
            }
          }
        }
      }
      if (userRdata && userRdata.length > 0) {
        setTools(userRdata);
      }
    }
  }, [cardData]);

  const pHandelChange = () => {
    if (allp === false) {
      if (tools && tools.length > 0) {
        let pdata = [...tools];
        for (let i = 0; i < pdata.length; i++) {
          pdata[i].isSelect = true;
        }
      }
      setAllp(!allp);
    } else if (allp === true) {
      if (tools && tools.length > 0) {
        let pdata = [...tools];
        for (let i = 0; i < pdata.length; i++) {
          pdata[i].isSelect = false;
        }
      }
      setAllp(!allp);
    }
  }

  const handelChange = (isChecked, value) => {
    if (tools && tools.length > 0) {
      let pdata = [...tools];
      for (let i = 0; i < pdata.length; i++) {
        if (pdata[i].peopleIds === value.peopleIds) {
          pdata[i].isSelect = isChecked;
          break;
        }
      }
      if (pdata && pdata.length > 0) {
        let count = 0
        for (let i = 0; i < pdata.length; i++) {
          if (pdata[i].isSelect === true) {
            count++;
          }
        }
        if (count === pdata.length) {
          // setAllp(true)
        } else {
          setAllp(false)
        }
      }
      setTools(pdata);
    }
  };

  const cFActionspf = (pfpopoverId) => {
    if (pfpopoverId) {
      pfpopoverId.adminId = myInfo.userId;
      pfpopoverId.url = `${SERVER_URI}/user/pfmember/create`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = pfpopoverId.teamId;
      dispatch(createAndUpdateMember(pfpopoverId)).then(() => {
        dispatch(getPFMember(obj));
        localStorage.removeItem("TypeLoc");
      });
    }
  };

  const cFActions = (popoverId, pfpopoverId) => {
    if (popoverId) {
      popoverId.adminId = myInfo.userId;
      popoverId.url = `${SERVER_URI}/user/member/create`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverId.teamId;

      dispatch(updateMember(popoverId)).then(() => {
        // dispatch(getMember(obj));
        if (pfpopoverId && Object.keys(pfpopoverId).length > 0) {
          cFActionspf(pfpopoverId)
        }
        localStorage.removeItem("dragCard");
        localStorage.removeItem("dragData");
        localStorage.removeItem("dragType");
      });
    }
  };

  const pFActionspf = (pfpopoverIds) => {
    if (pfpopoverIds) {
      pfpopoverIds.adminId = myInfo.userId;
      pfpopoverIds.url = `${SERVER_URI}/user/pfmember/create`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = pfpopoverIds.teamId;
      dispatch(createAndUpdateMember(pfpopoverIds)).then(() => {
        dispatch(getPFMember(obj));
        localStorage.removeItem("TypeLoc");
      });
    }
  };

  const pFActions = (popoverIds, pfpopoverIds) => {
    // console.log(210, pfpopoverIds)
    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        // dispatch(getMember(obj));
        if (pfpopoverIds && Object.keys(pfpopoverIds).length > 0) {
          pFActionspf(pfpopoverIds)
        }
        localStorage.removeItem("dragCard");
        localStorage.removeItem("dragData");
        localStorage.removeItem("dragType");
      });
    }
  };

  let card_type = localStorage.getItem("dragCard");
  let card_data = JSON.parse(localStorage.getItem("dragData"));
  /* // console.log(card_type, card_data)
  if (card_type === 'Skills' && cardData.ownerType === 'Single owner') {
    setSingleOwnerType(true);
    setAllp(true);
  } else if (localStorage.getItem("dragCard") === 'Roles' && cardData.ownerRole === 'Single owner') {
    setSingleOwnerType(true);
    setAllp(true);
  }

  console.log(singleOwnerType) */
  // membercData
  const pFCirclePopover = () => {
    var popoverIds = JSON.parse(localStorage.getItem("popoverIds"));
    var popoverId = JSON.parse(localStorage.getItem("popoverId"));

    var pfpopoverIds = JSON.parse(localStorage.getItem("pfmemberIds"));
    var pfpopoverId = JSON.parse(localStorage.getItem("pfmemberId"));

    let cardType = localStorage.getItem("dragCard");
    let cardData = JSON.parse(localStorage.getItem("dragData"));

    let type = cmtype;
    let isCheckIdentifier = tools.findIndex((st) => st.isSelect === true);

    const checkFlag = allp || (cardType === 'Skills' && cardData.ownerType === 'Single owner') || (cardType === 'Roles' && cardData.ownerRole === 'Single owner')
    // console.log(checkFlag);

    if (!allp && isCheckIdentifier == -1 && !checkFlag) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: "Please select one box",
      });
      return isCheckIdentifier;
    }

    let allPeopleData = [];
    // console.log(tools)
    if (tools && tools.length > 0) {
      if (allp === true || checkFlag) {
        for (let i = 0; i < tools.length; i++) {
          allPeopleData.push({
            CardId: tools[i].CardId,
            peopleIds: tools[i].peopleIds,
          });
        }
      } else {
        for (let i = 0; i < tools.length; i++) {
          if (tools[i].isSelect) {
            allPeopleData.push({
              CardId: tools[i].CardId,
              peopleIds: tools[i].peopleIds,
            });
          }
        }
      }
    }

    if (focused.card === "Circles") {
      if (cardType === "Skills" && cardData) {
        if (type === "Core") {
          if (pfpopoverId && Object.keys(pfpopoverId).length > 0 && allPeopleData && allPeopleData.length > 0) {
            const existingSet = new Set(pfpopoverId.coreMembers.Skills.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverId.coreMembers.Skills = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverId && Object.keys(popoverId).length > 0) {
            popoverId.coreMembers.Skills = [...new Set([...popoverId.coreMembers.Skills, cardData._id])];
          }
        } else if (type === "Extended") {
          if (pfpopoverId && Object.keys(pfpopoverId).length > 0 && allPeopleData && allPeopleData.length > 0) {
            const existingSet = new Set(pfpopoverId.extendedMembers.Skills.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverId.extendedMembers.Skills = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverId && Object.keys(popoverId).length > 0) {
            popoverId.extendedMembers.Skills = [...new Set([...popoverId.extendedMembers.Skills, cardData._id])];
          }
        }
      } else if (cardType === "Roles" && cardData) {
        if (type === "Core") {
          if (pfpopoverId && Object.keys(pfpopoverId).length > 0 && allPeopleData && allPeopleData.length > 0) {
            const existingSet = new Set(pfpopoverId.coreMembers.Roles.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverId.coreMembers.Roles = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverId && Object.keys(popoverId).length > 0) {
            popoverId.coreMembers.Roles = [...new Set([...popoverId.coreMembers.Roles, cardData._id])];
          }
        } else if (type === "Extended") {
          if (pfpopoverId && Object.keys(pfpopoverId).length > 0 && allPeopleData && allPeopleData.length > 0) {
            const existingSet = new Set(pfpopoverId.extendedMembers.Roles.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverId.extendedMembers.Roles = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverId && Object.keys(popoverId).length > 0) {
            popoverId.extendedMembers.Roles = [...new Set([...popoverId.extendedMembers.Roles, cardData._id])];
          }
        }
      } else if (cardType === "Domains" && cardData) {
        if (type === "Core") {
          if (pfpopoverId && Object.keys(pfpopoverId).length > 0 && allPeopleData && allPeopleData.length > 0) {
            const existingSet = new Set(pfpopoverId.coreMembers.Domains.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverId.coreMembers.Domains = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverId && Object.keys(popoverId).length > 0) {
            popoverId.coreMembers.Domains = [...new Set([...popoverId.coreMembers.Domains, cardData._id])];
          }
        } else if (type === "Extended") {
          if (pfpopoverId && Object.keys(pfpopoverId).length > 0 && allPeopleData && allPeopleData.length > 0) {
            const existingSet = new Set(pfpopoverId.extendedMembers.Domains.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverId.extendedMembers.Domains = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverId && Object.keys(popoverId).length > 0) {
            popoverId.extendedMembers.Domains = [...new Set([...popoverId.extendedMembers.Domains, cardData._id])];
          }
        }
      } else if (cardType === "Links" && cardData) {
        if (type === "Core") {
          if (pfpopoverId && Object.keys(pfpopoverId).length > 0 && allPeopleData && allPeopleData.length > 0) {
            const existingSet = new Set(pfpopoverId.coreMembers.Links.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverId.coreMembers.Links = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverId && Object.keys(popoverId).length > 0) {
            popoverId.coreMembers.Links = [...new Set([...popoverId.coreMembers.Links, cardData._id])];
          }
        } else if (type === "Extended") {
          if (pfpopoverId && Object.keys(pfpopoverId).length > 0 && allPeopleData && allPeopleData.length > 0) {
            const existingSet = new Set(pfpopoverId.extendedMembers.Links.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverId.extendedMembers.Links = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverId && Object.keys(popoverId).length > 0) {
            popoverId.extendedMembers.Links = [...new Set([...popoverId.extendedMembers.Links, cardData._id])];
          }
        }
      }

      if (popoverId && Object.keys(popoverId).length > 0) {
        cFActions(popoverId, pfpopoverId)
      }
    } else if (focused.card === "Projects") {
      if (cardType === "Skills" && cardData) {
        if (type === "Core") {
          if (pfpopoverIds && Object.keys(pfpopoverIds).length > 0 && allPeopleData && allPeopleData.length > 0) {
            const existingSet = new Set(pfpopoverIds.coreMembers.Skills.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverIds.coreMembers.Skills = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverIds && Object.keys(popoverIds).length > 0) {
            popoverIds.coreMembers.Skills = [...new Set([...popoverIds.coreMembers.Skills, cardData._id])];
          }
        } else if (type === "Extended") {
          if (pfpopoverIds && Object.keys(pfpopoverIds).length > 0) {
            const existingSet = new Set(pfpopoverIds.extendedMembers.Skills.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverIds.extendedMembers.Skills = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverIds && Object.keys(popoverIds).length > 0) {
            popoverIds.extendedMembers.Skills = [...new Set([...popoverIds.extendedMembers.Skills, cardData._id])];
          }
        }
      } else if (cardType === "Roles" && cardData) {
        if (type === "Core") {
          if (pfpopoverIds && Object.keys(pfpopoverIds).length > 0 && allPeopleData && allPeopleData.length > 0) {
            const existingSet = new Set(pfpopoverIds.coreMembers.Roles.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverIds.coreMembers.Roles = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverIds && Object.keys(popoverIds).length > 0) {
            popoverIds.coreMembers.Roles = [...new Set([...popoverIds.coreMembers.Roles, cardData._id])];
          }
        } else if (type === "Extended") {
          if (pfpopoverIds && Object.keys(pfpopoverIds).length > 0 && allPeopleData && allPeopleData.length > 0) {
            const existingSet = new Set(pfpopoverIds.extendedMembers.Roles.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverIds.extendedMembers.Roles = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverIds && Object.keys(popoverIds).length > 0) {
            popoverIds.extendedMembers.Roles = [...new Set([...popoverIds.extendedMembers.Roles, cardData._id])];
          }
        }
      } else if (cardType === "Domains" && cardData) {
        if (type === "Core") {
          if (pfpopoverIds && Object.keys(pfpopoverIds).length > 0 && allPeopleData && allPeopleData.length > 0) {
            const existingSet = new Set(pfpopoverIds.coreMembers.Domains.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverIds.coreMembers.Domains = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverIds && Object.keys(popoverIds).length > 0) {
            popoverIds.coreMembers.Domains = [...new Set([...popoverIds.coreMembers.Domains, cardData._id])];
          }
        } else if (type === "Extended") {
          if (pfpopoverIds && Object.keys(pfpopoverIds).length > 0 && allPeopleData && allPeopleData.length > 0) {
            pfpopoverIds.coreMembers.Domains = [...new Set([...pfpopoverIds.coreMembers.Domains, ...allPeopleData])];
          }
          if (popoverIds && Object.keys(popoverIds).length > 0) {
            popoverIds.extendedMembers.Domains = [...new Set([...popoverIds.extendedMembers.Domains, cardData._id])];
          }
        }
      } else if (cardType === "Links" && cardData) {
        if (type === "Core") {
          if (pfpopoverIds && Object.keys(pfpopoverIds).length > 0 && allPeopleData && allPeopleData.length > 0) {
            // pfpopoverIds.coreMembers.Links = [...new Set([...pfpopoverIds.coreMembers.Links, ...allPeopleData])];

            const existingSet = new Set(pfpopoverIds.coreMembers.Links.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverIds.coreMembers.Links = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverIds && Object.keys(popoverIds).length > 0) {
            popoverIds.coreMembers.Links = [...new Set([...popoverIds.coreMembers.Links, cardData._id])];
          }
        } else if (type === "Extended") {
          if (pfpopoverIds && Object.keys(pfpopoverIds).length > 0 && allPeopleData && allPeopleData.length > 0) {
            // pfpopoverIds.coreMembers.Links = [...new Set([...pfpopoverIds.coreMembers.Links, ...allPeopleData])];

            const existingSet = new Set(pfpopoverIds.extendedMembers.Links.map(item => `${item.CardId}-${item.peopleIds}`));
            const newSet = new Set(allPeopleData.map(item => `${item.CardId}-${item.peopleIds}`));

            // Merge sets and convert back to an array
            pfpopoverIds.extendedMembers.Links = Array.from(new Set([...existingSet, ...newSet]))
              .map(itemString => {
                const [CardId, peopleIds] = itemString.split('-');
                return { CardId, peopleIds };
              });
          }
          if (popoverIds && Object.keys(popoverIds).length > 0) {
            popoverIds.extendedMembers.Links = [...new Set([...popoverIds.extendedMembers.Links, cardData._id])];
          }
        }
      }

      if (popoverIds && Object.keys(popoverIds).length > 0) {
        pFActions(popoverIds, pfpopoverIds)
      }
    }

    // Update allOwnersFlag array of pfpopoverId for all card types
    if (focused.card === "Circles") {
      if (type === "Core" && cardData) {
        if ((allp === true || checkFlag) && pfpopoverId && pfpopoverId.allOwnersFlag && pfpopoverId.allOwnersFlag.core && !pfpopoverId.allOwnersFlag.core.some(item => item && cardData && item.toString() === cardData._id.toString())) {
          pfpopoverId.allOwnersFlag.core.push(cardData._id);
          // console.log(550, pfpopoverId);
        }
      }

      if (type === "Extended" && cardData) {
        if ((allp === true || checkFlag) && pfpopoverId && pfpopoverId.allOwnersFlag && pfpopoverId.allOwnersFlag.extended && !pfpopoverId.allOwnersFlag.extended.some(item => item && cardData && item.toString() === cardData._id.toString())) {
          pfpopoverId.allOwnersFlag.extended.push(cardData._id);
          // console.log(556, pfpopoverId);
        }
      }
      // localStorage.setItem("pfmemberId",JSON.stringify(pfpopoverId));
    } else if (focused.card === "Projects") {
      if (type === "Core" && cardData) {
        // console.log(564, pfpopoverIds);
        if ((allp === true || checkFlag) && pfpopoverIds && pfpopoverIds.allOwnersFlag && pfpopoverIds.allOwnersFlag.core && !pfpopoverIds.allOwnersFlag.core.some(item => item && cardData && item.toString() === cardData._id.toString())) {
          pfpopoverIds.allOwnersFlag.core.push(cardData._id);
          // console.log(564, pfpopoverIds);
        }
      }

      if (type === "Extended" && cardData) {
        if ((allp === true || checkFlag) && pfpopoverIds && pfpopoverIds.allOwnersFlag && pfpopoverIds.allOwnersFlag.extended && !pfpopoverIds.allOwnersFlag.extended.some(item => item && cardData && item.toString() === cardData._id.toString())) {
          pfpopoverIds.allOwnersFlag.extended.push(cardData._id);
          // console.log(571, pfpopoverIds);
        }
      }

    }
  };

  const saveTools = () => {
    pFCirclePopover() !== -1 && closeCircleModeTool();
  };

  return (
    <div id="" role="dialog" data-backdrop="static" data-keyboard="false">
      <div
        className="modal-dialog modal-sm modal-dialog-scrollable "
      >
        {/* Modal content*/}
        <div className="modal-content ">
          <div className="modal-header">
            {headName && Object.keys(headName).length > 0 && headName.adding ?
              <h5 className="modal-title">{headName.adding}</h5>
              : null}
            {headName && Object.keys(headName).length > 0 && headName.to ?
              <h5 className="modal-title">{headName.to}</h5>
              : null}
          </div>

          <div className="modal-body">
            <form className="form">
              <div className="row">
                <div className="col-xs-12">
                  <div className="form-group mtype" style={{ marginBottom: '0px' }}>
                    <input type="radio" id="mtype_core" className="logit" onChange={(e) => SetCmType("Core")} name="mtype" checked={cmtype === "Core"} />
                    <label htmlFor="mtype_core" className="inline-label">
                      Core
                    </label>
                    <input type="radio" id="mtype_xtd" className="logit" onChange={(e) => SetCmType("Extended")} name="mtype" checked={cmtype === "Extended"} />
                    <label htmlFor="mtype_xtd" className="inline-label">
                      Extended
                    </label>
                  </div>

                  {(card_type === 'Skills' && cardData.ownerType === 'Single owner') || (localStorage.getItem("dragCard") === 'Roles' && cardData.ownerRole === 'Single owner') ? (
                    null
                  ) : (
                    <>
                      <p style={{ marginBottom: '5px' }}>Who do you want to add?</p>
                      <div style={{ borderRadius: "10px", marginBottom: "10px" }}>
                        <div className="card-on-popup">
                          <label>
                            <input
                              style={{ marginRight: "2%", position: 'unset' }}
                              checked={allp}
                              onChange={(e) => pHandelChange()}
                              type="checkbox"
                            />
                            {`All owners`}
                          </label>
                        </div>
                      </div>

                      <div>
                        {/* {tools && tools.Skill && tools.Skill.length > 0 ? */}
                        <Accordion
                          sx={{ borderRadius: "10px", marginBottom: "10px" }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                          >
                            <Typography sx={{ fontSize: 14 }}>
                              Select owners
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormGroup>
                              {tools && tools.length > 0
                                ? tools.map((value, index) => {
                                  return (
                                    <div key={index}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={
                                              value.isSelect
                                                ? value.isSelect
                                                : false
                                            }
                                            onChange={(e) =>
                                              handelChange(
                                                e.target.checked,
                                                value
                                              )
                                            }
                                          />
                                        }
                                        label={
                                          <Typography
                                            component="div"
                                            fontSize={14}
                                          >
                                            {value.name}
                                          </Typography>
                                        }
                                      />
                                    </div>
                                  );
                                })
                                : null}
                            </FormGroup>
                          </AccordionDetails>
                        </Accordion>
                        {/* : null} */}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer" style={{ 'borderRadius': ' 6px' }}>
            <div className="row">
              <div className="col-md-12 text-right">
                <Tippy content={<span>Close Tool</span>}>
                  <button
                    type="button"
                    onClick={() => closeCircleModeTool()}
                    className="btn btn-mytpt btn-mytpt-red"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </Tippy>
                <input
                  type="button"
                  id="mytpt-delete-tags"
                  onClick={() => saveTools()}
                  className="btn btn-mytpt"
                  value="Save"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
