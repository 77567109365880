import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { userLogin, Autologin, userForgot, userRegister, userdefaultTeam } from "../../../store/actions/authAction";
import { SOCIAL_LOGIN_TOKEN_DELETE } from "../../../store/types/authType";
import { useAlert } from "react-alert";
import { SUCCESS_MESSAGE_CLEAR, ERROR_CLEAR, REGISTER_FAIL } from "../../../store/types/authType";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getpricing } from "../../../store/actions/pricingAction";
import { userLogin, Autologin, userForgot, userRegister, userdefaultTeam, userVerify, paymentVerify } from "../../../store/actions/authAction";
import deCodeToken from "jwt-decode";
import ReCAPTCHA from "react-google-recaptcha";
import Navbar from "./Navbar";
import Footer from '../../Component/Footer/Footer';
import { GoogleLogin } from 'react-google-login';
import { CLIENT_URL, SERVER_URI, RC_SITE_KEY, GOOGLE_CLIENT_ID } from "../../../config/keys"
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';



export default function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { token } = useParams();
  const [searchParams] = useSearchParams();
  let paymentId = searchParams.get("session_id");
  const { loading, stripeUrl, successMessage, paySuccessMessage, error, authenticate, myInfo, socialLoginToken } = useSelector((state) => state.auth);
  const { pricings, Prices, cuPricingId, pricingMessagee, pricingMessage, pricingError } = useSelector((state) => state.pricings);

  const [regis, setRegis] = useState({
    fname: "",
    lname: "",
    companyName: "",
    role: "User",
    email: "",
    plan: localStorage.getItem("Rnavigate") ? localStorage.getItem("Rnavigate") : "FREE",
    prices: localStorage.getItem("subPrices"),
    teamName: "",
    userUrl: "http://",
    password: "",
    isChecked: false,
    googleSign: false,
    invited: false,
    isLocked:true,
  });

  const registerHandel = (e) => {
    if (e.target.name !== "isChecked") {
      setRegis({
        ...regis,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "isChecked") {
      setRegis({
        ...regis,
        [e.target.name]: !regis.isChecked,
      });
    }
  };



  const [loadings, setLoadings] = useState(false);

  const [shows, setShows] = useState(false);
  const [show, setShow] = useState("Info");

  const handelShows = () => setShows(!shows);
  const recaptchaRef = React.useRef();

  const registerUser = async () => {
    // console.log(72, regis);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (regis.plan && regis.plan.length > 0 && regis.isChecked) {
      setLoadings(true);
      const token = await recaptchaRef.current.executeAsync();
      regis.rc_token = token;
      dispatch(userRegister(regis)).then(() => {
        setLoadings(false);
      })
    } else if (!emailRegex.test(regis.email)) {
      dispatch({
        type: REGISTER_FAIL,
        payload: { error: "Please enter a valid email address." },
      });
      setLoadings(false);
    } else if (!regis.isChecked) {
      dispatch({
        type: REGISTER_FAIL,
        payload: { error: "Please accept the terms of service." },
      });
      setLoadings(false);
    }
  };

  useEffect(() => {
    dispatch(getpricing());
  }, []);

  const tokenDecode = (token) => {
    const tokenDecoded = deCodeToken(token);
    const expTime = new Date(tokenDecoded.exp * 1000);
    if (new Date() > expTime) {
      return null;
    }
    return tokenDecoded;
  };


  useEffect(() => {
    if (token && token.length > 0) {
      let tokenData = tokenDecode(token);
      if (tokenData) {
        let obj = {
          fname: tokenData.fname ? tokenData.fname : "",
          lname: tokenData.lname ? tokenData.lname : "",
          companyName: tokenData.companyName ? tokenData.companyName : "",
          role: tokenData.role ? tokenData.role : "",
          email: tokenData.email ? tokenData.email : "",
          plan: localStorage.getItem("Rnavigate"),
          prices: localStorage.getItem("subPrices"),
          teamName: tokenData.teamName ? tokenData.teamName : "",
          userUrl: "http://",
          password: "",
          isChecked: false,
        }
        setRegis(obj)
      }
      // console.log(119, tokenData)
      if (tokenData.plan === "FREE") {
        dispatch(userVerify({ userId: tokenData.userId, plan: tokenData.plan, prices: tokenData.prices, email: tokenData.email })).then(() => {
          setShow("Success");
        });
      } else if (tokenData.plan === "GO" || tokenData.plan === "LIFETIME") {
        dispatch(userVerify({ userId: tokenData.userId, plan: tokenData.plan, prices: tokenData.prices, email: tokenData.email })
        ).then(() => {
          setShow("Payment");
        });
      }
    }
  }, [token]);


  // console.log(show)
  useEffect(() => {
    if (paymentId && paymentId.length > 0) {
      dispatch(paymentVerify({ session_id: paymentId }));
    }
  }, [paymentId]);

  useEffect(() => {
    if (authenticate) {
      dispatch({ type: SOCIAL_LOGIN_TOKEN_DELETE });

      // navigate("/"); // Also used for autologin from registration page after confirmation
      // window.location.reload()

      navigate("/onboarding-wizard"); // Added as a part of v4.5.1
    }

    if (paySuccessMessage && paySuccessMessage.length > 0) {
      setShow("Success");
    }
    if (successMessage) {
      if (successMessage === "Email verification link sent successfully!") {
        if (regis.googleSign === true) {
          if (regis.plan === "FREE") {
            setShow("Success");
          } else if (regis.plan === "GO" /* || regis.plan === "LIFETIME" */) {
            setShow("Payment");
          }
        } else if (regis.googleSign === false) {
          setShow("Confirm");
        }
        dispatch(userdefaultTeam(regis))
      }

      dispatch({ type: SUCCESS_MESSAGE_CLEAR });
    }
    // alert.success(successMessage)

    if (error) {
      // alert.error(error)
      setShow("Info")
      setLoadings(false);
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: error,
      });
      dispatch({ type: ERROR_CLEAR });
    }
  }, [successMessage, error, paySuccessMessage]);

  document.body.style = "";
  document.body.classList = "no-app-pages body2 menu2";
  document.body.style = `background-image: url(${CLIENT_URL}/images/inner-bg-2-1440w.png)`

  const responseSuccess = (res) => {
    let userData = tokenDecode(res.tokenId);

    /* verify email is already exist or not */
    //axios handler to for email existance check
    /* Added by Indranil 13-12-2023 */
    axios.post(`${SERVER_URI}/admin/email-existance-check`, { email: userData.email }).then((res) => {
      // console.log(res.data.data);
      if (res.data.data === true) {
        Swal.fire({
          // icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: "This email is already registered!",
        });
      } else {
        let obj = {
          fname: userData.given_name,
          lname: userData.family_name,
          companyName: "",
          role: "User",
          email: userData.email,
          plan: localStorage.getItem("Rnavigate"),
          prices: localStorage.getItem("subPrices"),
          teamName: "",
          userUrl: "http://",
          password: "",
          isChecked: false,
          googleSign: true
        }
        setRegis(obj)
      }
    }).catch((error) => {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: "Network error!",
      });
    });

  }

  const responseFailure = (res) => {
    // console.log('Login fail, response: ', res)
    if (res.error === "popup_closed_by_user") return false;

    Swal.fire({
      // icon: "error",
      title: "Error",
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
      text: "Please try using another email!",
    });
  }

  const DirectLogin = async (req, res) => {

    const maintoken = socialLoginToken ? socialLoginToken : token;

    /* direct login usuing token */
    let tokenData = tokenDecode(maintoken);
    const rc_token = await recaptchaRef.current.executeAsync();

    const state = {
      email: tokenData.email,
      userUniqId: tokenData.userId,
      rc_token: rc_token
    }

    setLoadings(true);

    dispatch(Autologin(state))
  }

  return (
    <div>
      <Navbar />
      <div className="container vertical-center">
        <div className="row">
          <div className="col-lg-1 col-sm-12 col-12"></div>
          <div className="col-lg-10 col-sm-12 col-12">
            <div className="alert alert-error" style={{ display: "none" }} ></div>
            <form action="#" method="post" id="registration" className="infomation-section" >
              <nav>
                <div className="nav nav-pills nav-fill" id="nav-tab" role="tablist" >
                  <a className={show === "Info" ? "nav-link active" : "nav-link"} id="step1-tab" >
                    <span>1</span> Info&nbsp;&nbsp;
                    <img alt="" src={`${CLIENT_URL}/images/arrow2a.png`} />
                  </a>
                  <a className={show === "Confirm" ? "nav-link active" : "nav-link"} id="step2-tab" >
                    <span>2</span> Confirm&nbsp;&nbsp;
                    <img alt="" src={`${CLIENT_URL}/images/arrow2a.png`} />
                  </a>
                  {regis.plan === "FREE" ? (
                    <a className={show === "Success" ? "nav-link active" : "nav-link"} id="step2-tab" >
                      <span>3</span> Success
                    </a>
                  ) : (
                    <div className="nav nav-pills nav-fill">
                      <a className={show === "Payment" ? "nav-link active" : "nav-link"} id="step2-tab" >
                        <span>3</span> Payment&nbsp;&nbsp;
                        <img alt="" src={`${CLIENT_URL}/images/arrow2a.png`} />
                      </a>
                      <a className={show === "Success" ? "nav-link active" : "nav-link"} id="step2-tab" >
                        <span>4</span> Success
                      </a>
                    </div>
                  )}
                </div>
              </nav>
              <div className="tab-content">
                <div className={show === "Info" ? "tab-pane fade in active" : "tab-pane fade"} id="step1">
                  <h4>Information</h4>
                  <span className="text-default">
                    <span style={{ color: '#b90000' }}>*</span> indicates required fields
                  </span>
                  <div className="from-st">
                    <div className="row">
                      {!regis.googleSign ?
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <input
                              type="hidden"
                              id="session_id"
                              name="session_id"
                              className="form-control"
                              value="free_trial"
                              required=""
                              wfd-id="id4"
                            />
                            <label htmlFor="email">
                              Email address <span>*</span>
                            </label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              onChange={(e) => registerHandel(e)}
                              className="form-control"
                              value={regis.email}
                              disabled={regis.googleSign ? true : false}
                              placeholder="Your Email"
                              required=""
                              wfd-id="id5"
                            />
                            <p></p>
                          </div>
                        </div> : null}

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="company">
                            Company name <span>*</span>
                          </label>
                          <input
                            type="text"
                            id="company"
                            name="companyName"
                            onChange={(e) => registerHandel(e)}
                            className="form-control"
                            placeholder="Your company’s name"
                            required=""
                            value={regis.companyName}
                            wfd-id="id9"
                          />
                        </div>
                      </div>

                      {!regis.googleSign ?
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="first_name">
                              First name <span>*</span>
                            </label>
                            <input
                              type="text"
                              id="first_name"
                              name="fname"
                              onChange={(e) => registerHandel(e)}
                              disabled={regis.googleSign ? true : false}
                              className="form-control"
                              placeholder="Your first name"
                              value={regis.fname}
                              required=""
                              wfd-id="id7"
                            />
                          </div>
                        </div> : null}

                      {!regis.googleSign ?
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="last_name">
                              Last name <span>*</span>
                            </label>
                            <input
                              type="text"
                              id="last_name"
                              name="lname"
                              onChange={(e) => registerHandel(e)}
                              disabled={regis.googleSign ? true : false}
                              className="form-control"
                              placeholder="Your last name"
                              value={regis.lname}
                              required=""
                              wfd-id="id8"
                            />
                          </div>
                        </div> : null}

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="field2">
                            Website <span>*</span>
                          </label>
                          <input
                            type="website"
                            id="website"
                            name="userUrl"
                            className="form-control"
                            onChange={(e) => registerHandel(e)}
                            placeholder="Start with http://"
                            autoComplete="false"
                            value={regis.userUrl}
                            required=""
                            wfd-id="id10"
                          />
                          <span className="text-default">
                            needs to start with http
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="mb-3">
                          <label htmlFor="first_team">
                            Name of your first team for teamdecoder <span>*</span>
                          </label>
                          <input
                            type="text"
                            id="first_team"
                            name="teamName"
                            onChange={(e) => registerHandel(e)}
                            className="form-control"
                            placeholder="Name of your first team for teamdecoder"
                            required=""
                            value={regis.teamName}
                            wfd-id="id11"
                          />
                        </div>
                      </div>

                      {!regis.googleSign ?
                        <div className="col-lg-12">
                          <div
                            className="mb-3"
                            style={{
                              display: "block",
                              position: "relative",
                              width: "100%",
                            }}
                          >
                            <label htmlFor="password">
                              Password <span>*</span>
                            </label>
                            <input
                              type={shows ? "text" : "password"}
                              id="password"
                              name="password"
                              onChange={(e) => registerHandel(e)}
                              className="form-control"
                              placeholder="Your password"
                              required=""
                              wfd-id="id6"
                              value={regis.password}
                              data-gtm-form-interact-field-id="1"
                            />
                            <span className="text-default">
                              8-15 characters, must include one upper case, lower case, number and a special character.
                            </span>
                            <div
                              onClick={() => handelShows()}
                              id="togglePassword"
                            // style={{ marginTop: "20px" }}
                            >
                              <svg
                                style={
                                  shows
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-eye-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
                              </svg>

                              <svg
                                style={
                                  shows
                                    ? { display: "none" }
                                    : { display: "block" }
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-eye-slash-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"></path>
                                <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"></path>
                              </svg>
                            </div>
                          </div>
                        </div> : null}

                      <div className="col-lg-12">
                        <div
                          className="mb-3 form-check"
                          style={{ "paddingTop": "20px" }}
                        >
                          <input
                            type="checkbox"
                            id="toc_pp"
                            name="isChecked"
                            className="form-check-input"
                            onChange={(e) => registerHandel(e)}
                            required=""
                            wfd-id="id12"
                            checked={regis.isChecked}
                          />
                          <label style={{ marginLeft: "10px" }} className="form-check-label" htmlFor="first_team">
                            {" I agree to the "}
                            <a
                              href="https://legal.teamdecoder.com/terms-of-service"
                              target="_blank"
                              rel="noopener nofollow noreferrer"
                            >
                              terms of service
                            </a>
                            . <span>*</span>
                          </label>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <button
                          type="button"
                          onClick={() => registerUser()}
                          className="btn btn-primary submit"
                          id="step1-continue"
                        >
                          {loadings ? (
                            <i className="fa fa-spinner fa-spin"></i>
                          ) : "Continue"}

                        </button>
                      </div>
                      {!regis.googleSign ?
                        <div className="col-lg-12">
                          <div style={{ "textAlign": "center", marginTop: "10px" }}>OR</div>
                          <GoogleLogin
                            clientId={GOOGLE_CLIENT_ID}
                            render={renderProps => (
                              <p onClick={renderProps.onClick} disabled={renderProps.disabled} style={{ "textAlign": "center", marginTop: "10px" }}>
                                <a href=''><img alt="" src={`${CLIENT_URL}/images/btn_google_signup_dark_normal_web.png`} /></a>
                              </p>
                            )}
                            buttonText="Register"
                            onSuccess={responseSuccess}
                            onFailure={responseFailure}
                            cookiePolicy={'single_host_origin'}
                            isSignedIn={false}

                          />
                        </div> : null}
                      <div className="col-lg-12">
                        <p style={{ marginTop: '15px' }}>
                          In order to be able to fulfill your teamdecoder
                          license, we will process your data. You can find more
                          information about how we process your data{" "}
                          <a
                            href="https://legal.teamdecoder.com/privacy-policy"
                            target="_blank"
                            rel="noopener nofollow noreferrer"
                          >
                            here
                          </a>
                          .
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
                <div className={show === "Confirm" ? "tab-pane show active" : "tab-pane fade"} id="step2" >
                  <h4>Confirm</h4>
                  <div className="onboarding-y">
                    <h2 id="title" style={{ marginBottom: "15px", color: "#7FC2BF" }}>Yeah :) Your account has been created!</h2>
                    <p id="message">
                      We have sent an email to{" "}
                      <span className="email">{regis.email}</span>. <strong>Please click the link in that email </strong>
                      to verify your email address and you will be taken back
                      here to login in no time.
                      <br />
                      <small style={{ fontSize: "18px", display: "block", marginTop: "15px", lineHeight: "1.5" }} >
                        Don't forget to check your spam folder and add us to
                        your safe sender list to ensure you don't miss any
                        future communications.
                      </small>
                    </p>
                  </div>
                </div>
                <div className={show === "Payment" ? "tab-pane show active" : "tab-pane fade"} id="step2" >
                  <h4>Payment</h4>
                  <div className="onboarding-y">
                    <h2 id="title" style={{ marginBottom: "15px", color: "#7FC2BF" }}>{regis.googleSign ? 'Process to payment' : 'Your email has been verified. Thanks!'}</h2>
                    <p id="message" style={{ width: '100%' }}>
                      Please complete the signup process by clicking the <strong>Pay Now</strong> button. You will be taken to a secure payment page managed by Stripe.
                      {/* <br />
                      <small style={{ fontSize: "18px", display: "block", marginTop: "15px", lineHeight: "1.5" }} >
                        You will taken to secure payment page managed by Stripe.
                      </small> */}
                    </p>
                  </div>
                  <div className="buttan-858" style={{ display: "block" }}>
                    <a href={stripeUrl ? stripeUrl.url : "#"} className="btn btn-primary submit" style={{ width: "40%", "marginTop": "15px" }}>
                      Pay Now
                    </a>
                  </div>
                </div>

                <div
                  className={
                    show === "Success"
                      ? "tab-pane show active"
                      : "tab-pane fade"
                  }
                  id="step2"
                >
                  <h4>Success</h4>
                  <div className="onboarding-y">
                    {regis.plan === "FREE" ?
                      <h2 id="title" style={{ marginBottom: "15px", color: "#7FC2BF" }}>Your email has been verified. Thanks!</h2> :
                      <h2 id="title">{regis.plan === "FREE" ? 'Your email has been verified. Thanks!' : 'Your payment was successful!'}</h2>}
                    <p id="message">
                      You can now start to work with <b>teamdecoder</b>. Enjoy!
                    </p>
                  </div>
                  <div className="buttan-858" style={{ display: "block" }}>
                    <ul>
                      <li>
                        <a target="_blank" rel="noopener nofollow noreferrer" href="https://www.teamdecoder.com/app-tour">
                          <img alt="" src={`${CLIENT_URL}/images/our-qu.png`} />
                        </a>
                        .
                      </li>
                      <li>
                        <a target="_blank" rel="noopener nofollow noreferrer" href="https://teamdecoder.kb.help/getting-started/">
                          <img alt="" src={`${CLIENT_URL}/images/our-qu2.png`} />
                        </a>
                      </li>
                    </ul>

                  </div>

                  <a
                    className="btn btn-primary submit"
                    onClick={() => DirectLogin()}
                    style={{ width: "40%", "marginTop": "15px" }}
                  >
                    {loadings ?
                      <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                      : 'Login'}
                  </a>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-1 col-sm-12 col-12"></div>
        </div>
      </div>
      <Footer />
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={RC_SITE_KEY}
      />
    </div>
  );
}

// sapavef736@ratedane.com
