import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
    DirectAddCoAdmin,
    getLoginUserData,
    userRegister,
} from "../../../../store/actions/authAction";
import {
    CancelAddOnSubcription,
    getAccessManagemenet,
    updateAccessManagemenet,
} from "../../../../store/actions/accessManagementAction";
import {
    ACCESS_MANAGEMENT_ERROR_MESSAGE_CLEAR,
    ACCESS_MANAGEMENT_SUCCESS_MESSAGE_CLEAR,
} from "../../../../store/types/accessManagementTypes";
import Tippy from "@tippyjs/react";
import { Button, Input, Menu } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { generateStripeUpgradeUrl } from "../../../../store/actions/pricingAction";
import {
    ERROR_CLEAR,
    PAYMENT_VERIFIY_MSG_CLEAR,
    SUCCESS_MESSAGE_CLEAR,
} from "../../../../store/types/authType";
import { CLIENT_URL } from "../../../../config/keys";
import { freezeWindow, unfreezeWindow } from "../../../../HelperFunctions/Overlay";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 100,
            boxShadow:"none",
            border:"1px solid #eee",
            borderRadius:"4px",
            backgroundColor:"#fff",          
        },
    },
};

const AccessManagement = ({ closeAccessManagement }) => {
    const dispatch = useDispatch();
    const { myInfo, successMessage, error, curUserData, directAddSuccessMessage, directAddErrorMessage } = useSelector(
        (state) => state.auth
    );
    const { teams, template, sMessage, errors } = useSelector(
        (state) => state.teams
    );
    const {
        accessManagementData,
        accessManagementSuccessMessage,
        accessManagementErrorMessage,
    } = useSelector((state) => state.accessManagement);

    const [rows, setRows] = useState([]);
    const [teamOptions, setTeamOptions] = useState([]);
    const [stripeRow, setStripeRow] = useState([]);
    const [AddOnQuantity, setAddOnQuantity] = useState(1);

    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenuId, setOpenMenuId] = useState(null);

    const handleClick = (event, id) => {
        // console.log('handleClick called with id:', id);
        setAnchorEl(event.currentTarget);
        setOpenMenuId(id);
    };

    const handleClose = () => {
        // console.log('handleClose called');
        setAnchorEl(null);
        setOpenMenuId(null);
    };


    useEffect(() => {
        dispatch(getAccessManagemenet({ userId: curUserData._id }));
    }, []);

    useEffect(() => {
        if (Object.keys(curUserData).length > 0) {
            let AddOnDetails = curUserData.addOn.filter(
                (sao) => sao.plan === "Co-Admin"
            );
            setStripeRow(AddOnDetails);
        }
    }, [curUserData]);

    useEffect(() => {
        let mod_serverData = accessManagementData.map((sam, i) => ({
            ...sam,
            id: i + 1,
            revoke: false,
            updateTeam: false,
            editButton_Loader: false,
            stripeButton_Loader: false,
            invition_update_Button_Loader: false,
        }));

        // console.log(mod_serverData);

        let totalRow = stripeRow.reduce((acc, row) => acc + row.quantity, 0);

        let currentIndex = 0;
        // if(mod_serverData.length > 0) {

        stripeRow.forEach((stripe) => {
            // console.log(">>>>>>>",{stripe});
            for (let i = 0; i < stripe.quantity; i++) {
                // console.log({sq:stripe.quantity,i});
                if (currentIndex < mod_serverData.length) {
                    // Update existing rows with the same sessionId and subscriptionId
                    // console.log(mod_serverData[currentIndex])
                    // console.log(mod_serverData[currentIndex].sessionId);
                    // console.log(stripe.sessionId);
                    if (
                        mod_serverData[currentIndex].sessionId == stripe.sessionId &&
                        mod_serverData[currentIndex].subscriptionId == stripe.subscriptionId
                    ) {
                        currentIndex++;
                    } else {
                        // console.log("else condition---????", { currentIndex, msdl: mod_serverData.length })
                        mod_serverData.splice(currentIndex, 0, {
                            id: mod_serverData.length + 1,
                            _id: null,
                            name: "",
                            teamIds: [],
                            email: "",
                            invitedUserId: null,
                            acceptInvition: false,
                            revoke: true,
                            updateTeam: true,
                            sessionId: stripe.sessionId,
                            subscriptionId: stripe.subscriptionId,
                        });
                        currentIndex++;
                    }
                } else {
                    // console.log("else condition----???", { sq: stripe.quantity, i, currentIndex, sdl: mod_serverData.length });
                    // Add new rows if we have exhausted the existing rows
                    mod_serverData.push({
                        id: mod_serverData.length + 1,
                        _id: null,
                        name: "",
                        teamIds: [],
                        email: "",
                        invitedUserId: null,
                        acceptInvition: false,
                        revoke: true,
                        updateTeam: true,
                        sessionId: stripe.sessionId,
                        subscriptionId: stripe.subscriptionId,
                    });
                }
            }
        });

        // console.log({ sdl: mod_serverData.length, totalRow })

        // Ensure that the final length of mod_serverData matches totalRow
        while (mod_serverData.length < totalRow) {
            // console.log("|||||||||in while loop||||||")
            mod_serverData.push({
                id: mod_serverData.length + 1,
                _id: null,
                name: "",
                teamIds: [],
                email: "",
                invitedUserId: null,
                acceptInvition: false,
                revoke: true,
                updateTeam: true,
                sessionId: null,
                subscriptionId: null,
            });
        }

        // }

        // console.log(mod_serverData);
        /* sort according to id -- before setting to row */
        mod_serverData = mod_serverData.sort((a, b) => a.id - b.id);
        setRows(mod_serverData);
    }, [accessManagementData, stripeRow]);

    useEffect(() => {
        if (teams && teams.length > 0) {
            // console.log(teams);
            let filteredTeams  =  teams.filter(t => t.archived === false);
            // console.log(filteredTeams);
            let optionArray = filteredTeams.map((st) => {
                return { value: st._id, label: st.team_name };
            });
            setTeamOptions(optionArray);
        }
    }, [teams]);

    const stopLoading = (id) => {
        let new_mod_row = rows;
        new_mod_row.map((row) =>
            row.id === id ? { ...row, invition_update_Button_Loader: true } : row
        )
        setRows(new_mod_row);
    };

    const handleChange = (id, field, value) => {
        if (field === "teamIds") {
            const row = rows.find((row) => row.id === id);
            if (value[value.length - 1] === "all") {
                let selectedTeamIds = row.teamIds;
                let arr = selectedTeamIds.length === teamOptions.length ? [] : teamOptions.map((t) => t.value).filter(Boolean);
                setRows(
                    rows.map((row) => (row.id === id ? { ...row, [field]: arr } : row))
                );
            } else {
                let arr = typeof value === 'string' ? value.split(',') : value;
                setRows(
                    rows.map((row) => (row.id === id ? { ...row, [field]: arr } : row))
                );
            }
        } else {
            // console.log({ id, field, value })
            setRows(
                rows.map((row) => (row.id === id ? { ...row, [field]: value } : row))
            );
        }
    };

    const handleSend = (id) => {
        const row = rows.find((row) => row.id === id);
        setRows(
            rows.map((row) =>
                row.id === id ? { ...row, invition_update_Button_Loader: true } : row
            )
        );
        if (row.invitedUserId && row.invitedUserId !== "") {
            const trimmedName = row.name.trim();
            const nameParts = trimmedName.split(" ");
            const fname = nameParts.length > 0 ? nameParts[0] : trimmedName;
            const lname = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
            if (!fname || !lname || row.teamIds.length == 0 || !row.email) {
                Swal.fire({
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: "Full name, Email and Team(s) are required",
                });
                stopLoading(id);
                return;
            }

            const data = {
                _id: row._id,
                adminId: myInfo.userId,
                teamIds: row.teamIds,
                name: row.name,
                email: row.email,
                status: false,
                role: "Co-Admin",
                fname: fname,
                lname: lname,
                invited: true,
                companyName: myInfo.companyName,
                userUrl: "https://example.com",
                password: "Abcd@1234",
                revoke: row.revoke,
                invitedUserId: row.invitedUserId,
                sessionId: row.sessionId,
                subscriptionId: row.subscriptionId,
            };
            function validateEmail(email) {
                const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                return emailPattern.test(email);
            }
            // console.log("email verified:", validateEmail(data.email));
            if (!validateEmail(data.email)) {
                Swal.fire({
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: "Please provide a valid email address.",
                });
                return;
            }
            // Handle sending the data -- email invition
            if (!data.email || !data.name || data.teamIds.length === 0) {
                Swal.fire({
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: "Full name, Email and Team(s) are required",
                });
                return;
            }
            /* need checker if it is for only {teamIds update} or {revoke and update} */
            let coresponding_dbData = accessManagementData.find((samd) => samd._id === data._id);
            if (data.email === coresponding_dbData.email) {
                // console.log("Send Data: for team update", data);
                freezeWindow(false, true);
                dispatch(updateAccessManagemenet(data)).then(() => {
                    let new_mod_row = rows;
                    new_mod_row.map((row) =>
                        row.id === id ? { ...row, invition_update_Button_Loader: true } : row
                    )
                    setRows(new_mod_row);
                });
            } else {
                Swal.fire({
                    // icon: "info",
                    title: "Are you sure?",
                    showCancelButton: true,
                    text: `This will revoke team(s) access from ${coresponding_dbData.email}.`,
                    confirmButtonText: "Yes",
                    cancelButtonText: `No`,
                    cancelButtonColor: "#EE7364",
                }).then((result) => {
                    if (result.isConfirmed) {
                        // console.log("api call for revoking");
                        handleChange(id, "revoke", true);
                        data.revoke = true;
                        // console.log("Send Data: for revoke and update-- new email", data);
                        freezeWindow(false, true);
                        dispatch(updateAccessManagemenet(data)).then(() => {
                            let new_mod_row = rows;
                            new_mod_row.map((row) =>
                                row.id === id ? { ...row, invition_update_Button_Loader: true } : row
                            )
                            setRows(new_mod_row);
                        });
                        // const data = {
                        //     _id: row._id,
                        //     adminId: myInfo.userId,
                        //     teamIds: row.teamIds,
                        //     name: null,
                        //     email: null,
                        //     status: false,
                        //     role: "Co-Admin",
                        //     fname: null,
                        //     lname: null,
                        //     invited: true,
                        //     companyName: myInfo.companyName,
                        //     userUrl: "https://example.com",
                        //     password: "Abcd@1234",
                        //     revoke: true,
                        //     invited: true,
                        // };
                        // dispatch(updateAccessManagemenet(data));
                    } else {
                        return;
                    }
                });
            }
        } else {
            const trimmedName = row.name.trim();
            const nameParts = trimmedName.split(" ");
            const fname = nameParts.length > 0 ? nameParts[0] : trimmedName;
            const lname = nameParts.length > 1 ? nameParts.slice(1).join(" ") : null;
            /* if (!fname || !lname) {
                Swal.fire({
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: "First name and Last name both are required",
                });
                stopLoading(id);
                return;
            } else if (row.teamIds.length == 0) {
                Swal.fire({
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: "Please select a team",
                });
                stopLoading(id);
                return;
            } else if (!row.email) {
                Swal.fire({
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: "email is required",
                });
                stopLoading(id);
                return;
            } */
            if (!fname || !lname || row.teamIds.length == 0 || !row.email) {
                Swal.fire({
                    title: "Error",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp",
                    },
                    text: "Full name, Email and Team(s) are required",
                });
                stopLoading(id);
                return;
            }
            /* update call */
            const data = {
                adminId: myInfo.userId,
                teamIds: row.teamIds,
                name: row.name,
                email: row.email,
                status: false,
                role: "Co-Admin",
                fname: fname,
                lname: lname,
                invited: true,
                companyName: myInfo.companyName,
                userUrl: "https://example.com",
                password: "Abcd@1234",
                invitedUserId: row.invitedUserId,
                revoke: row.revoke,
                sessionId: row.sessionId,
                subscriptionId: row.subscriptionId,
                userPlan: curUserData.plan
            };
            // console.log("for new user reg", data);
            freezeWindow(false, true);
            dispatch(userRegister(data)).then(() => {
                let new_mod_row = rows;
                new_mod_row.map((row) =>
                    row.id === id ? { ...row, invition_update_Button_Loader: true } : row
                )
                setRows(new_mod_row);
            })
        }
    };

    const handleReSend = (id) => {
        const row = rows.find((row) => row.id === id);
        setRows(
            rows.map((row) =>
                row.id === id ? { ...row, invition_update_Button_Loader: true } : row
            )
        );
        const trimmedName = row.name.trim();
        const nameParts = trimmedName.split(" ");
        const fname = nameParts.length > 0 ? nameParts[0] : trimmedName;
        const lname = nameParts.length > 1 ? nameParts.slice(1).join(" ") : null;
        /* update call */
        const data = {
            adminId: myInfo.userId,
            teamIds: row.teamIds,
            name: row.name,
            email: row.email,
            status: false,
            role: "Co-Admin",
            fname: fname,
            lname: lname,
            invited: true,
            companyName: myInfo.companyName,
            userUrl: "https://example.com",
            password: "Abcd@1234",
            invitedUserId: row.invitedUserId,
            revoke: row.revoke,
            sessionId: row.sessionId,
            subscriptionId: row.subscriptionId,
            reSendInvite: true,
        };
        freezeWindow(false, true);
        dispatch(userRegister(data)).then(() => {
            let new_mod_row = rows;
            new_mod_row.map((row) =>
                row.id === id ? { ...row, invition_update_Button_Loader: true } : row
            )
            setRows(new_mod_row);
        });
    };

    const handleChangeTeamFlag = (id) => {
        setRows(
            rows.map((row) =>
                row.id === id ? { ...row, updateTeam: true } : row
            )
        );
    };

    const handleRevoke = (id) => {
        const row = rows.find((row) => row.id === id);
        if (row.invitedUserId) {
            Swal.fire({
                // icon: "info",
                title: "Are you sure?",
                showCancelButton: true,
                text: `This will revoke team(s) access from ${row.email}.`,
                confirmButtonText: "Yes",
                cancelButtonText: `No`,
                cancelButtonColor: "#EE7364",
            }).then((result) => {
                if (result.isConfirmed) {
                    setRows(
                        rows.map((row) =>
                            row.id === id ? { ...row, revoke: true, name: "", email: "", teamIds: [], updateTeam: true } : row
                        )
                    );
                    /* handleChange(id, "revoke", true);
                    handleChange(id, "name", "");
                    handleChange(id, "email", "");
                    handleChange(id, "teamIds", []);
                    handleChange(id, "updateTeam", true); */
                    // const data = {
                    //     _id: row._id,
                    //     adminId: myInfo.userId,
                    //     teamIds: row.teamIds,
                    //     name: null,
                    //     email: null,
                    //     status: false,
                    //     role: "Co-Admin",
                    //     fname: null,
                    //     lname: null,
                    //     invited: true,
                    //     companyName: myInfo.companyName,
                    //     userUrl: "https://example.com",
                    //     password: "Abcd@1234",
                    //     revoke: true,
                    //     invited: true,
                    // };
                    // dispatch(updateAccessManagemenet(data));
                } else {
                    return;
                }
            });
        } else {
            /* update call */
            // console.log("updated data: ", row);
        }
    };

    const HandleAddOn = (type) => {
        if (curUserData.plan === "Consulting") {
            dispatch(DirectAddCoAdmin({
                userId: myInfo.userId,
                userPlan: curUserData.plan,
                plan: "Co-Admin",
                quantity: AddOnQuantity
            }));
        } else {
            dispatch(
                generateStripeUpgradeUrl({
                    userId: myInfo.userId,
                    email: myInfo.email,
                    plan: type,
                    interval: "month",
                    quantity: AddOnQuantity ? (AddOnQuantity > 0 ? AddOnQuantity : 1) : 1,
                })
            );
        }
    };

    const handleAddOnQuantity = (value) => {
        // Remove non-numeric characters
        const sanitizedValue = value.replace(/[^0-9]/g, "");
        if (sanitizedValue === "" || isNaN(Number(sanitizedValue))) {
            setAddOnQuantity("");
        } else {
            setAddOnQuantity(Number(sanitizedValue));
        }
    };

    const handleCancelAddOnSubscription = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "This will cancel your Co-Admin license.",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.isConfirmed) {
                const row = rows.find((row) => row.id === id);
                freezeWindow(false, true);
                dispatch(CancelAddOnSubcription({ ...row, adminId: myInfo.userId }));
            }
        });
    };

    useEffect(() => {
        handleClose();
        if (accessManagementSuccessMessage !== null) {
            unfreezeWindow();
            dispatch({ type: ACCESS_MANAGEMENT_SUCCESS_MESSAGE_CLEAR });
            dispatch(getAccessManagemenet({ userId: curUserData._id }));
            dispatch(getLoginUserData({ userId: myInfo.userId }));
        } else if (accessManagementErrorMessage) {
            unfreezeWindow();
            dispatch(getAccessManagemenet({ userId: curUserData._id }));
            dispatch(getLoginUserData({ userId: myInfo.userId }));
            Swal.fire({
                title: "Error",
                showClass: {
                    popup: "animate_animated animate_fadeInDown",
                },
                hideClass: {
                    popup: "animate_animated animate_fadeOutUp",
                },
                text: accessManagementErrorMessage,
            });
            dispatch({ type: ACCESS_MANAGEMENT_ERROR_MESSAGE_CLEAR });
        }
        if (successMessage) {
            unfreezeWindow();
            dispatch({ type: SUCCESS_MESSAGE_CLEAR });
            dispatch(getAccessManagemenet({ userId: curUserData._id }));
            dispatch(getLoginUserData({ userId: myInfo.userId }));
        }
        if (error) {
            unfreezeWindow();
            Swal.fire({
                // icon: "error",
                title: "Error",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutUp",
                },
                text: error,
            });
            dispatch({ type: ERROR_CLEAR });
        }
    }, [
        accessManagementSuccessMessage,
        accessManagementErrorMessage,
        successMessage,
        error,
    ]);

    const options = [];
    for (let i = 1; i <= 99; i++) {
        options.push({ label: i > 1 ? `${i} Seat(s)` : `${i} Seat`, value: i });
    }

    useEffect(() => {
        if (directAddSuccessMessage && directAddSuccessMessage !== "") {
            dispatch({ type: PAYMENT_VERIFIY_MSG_CLEAR });
            window.location.href = `${CLIENT_URL}/?am=true`;
        } else if (directAddErrorMessage && directAddErrorMessage !== "") {
            dispatch({ type: PAYMENT_VERIFIY_MSG_CLEAR });
        }
    }, [directAddSuccessMessage, directAddErrorMessage]);


    return (
        <div
            id="access-management"
            className="modal-dialog modal-lg modal-dialog-scrollable"
            style={{ backgroundColor: "white" }}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">
                        {/* <i class="far fa-compass"></i> */} ACCESS MANAGEMENT
                    </h5>
                </div>
                <div className="modal-body">
                    <h4>Admin</h4>
                    <div className="content">
                        <p>Every account has 1 admin only, who is established at account creation and cannot be changed.
                            The admin can manage profile, tags and access settings for all teams, can access, read and edit all contents in all teams, can change the subscription and buy co-admin seats.</p>

                        <div className="gt-admin-p">
                            <p style={{ color: "#000" }}>Admin E-mail</p>
                            <p>{myInfo.email}</p>
                            <input type="hidden" className="form-control" value={myInfo.email} disabled />
                        </div>
                    </div>

                    <h4>Co-Admins</h4>
                    <div className="content">
                        <ul>
                            <li>have the same rights as the admin, except the subscription and co-admin management</li>
                            <li>can either access all teams (as stand-in or support to the main admin) or one or multiple specific teams (e.g. individual team managers).</li>
                            <li>per team only 1 person can edit the content at a time, other users will have view only rights, until the user has left the team.</li>
                        </ul>
                    </div>

                    <h4>Add Co-Admin Seats</h4>
                    <div className="content admin-seats">
                        <p style={{ color: "#000" }}>Seats:</p>
                        <select
                            value={AddOnQuantity}
                            onChange={(e) => handleAddOnQuantity(e.target.value)}
                            className="form-control"
                        >
                            {options.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        <button className="btn btn-mytpt" type="button" onClick={() => HandleAddOn("Co-Admin")}>
                            {curUserData.plan === "Consulting" ? "Add Seats" : "Buy Seats"}
                        </button>
                        {curUserData.plan !== "Consulting" && curUserData.plan !== "FREE" ?
                            <p style={{ color: "#666" }}>{`(EUR 29/month/co-admin)`}</p>
                            : null}
                    </div>

                    <h4>Your account currently includes{" "} <u>{stripeRow.reduce((acc, row) => acc + row.quantity, 0)}</u> co-admins</h4>
                    <div className="content">
                        {rows.map((row, index) => (
                            <div className="invition-sec row" key={row.id}>
                                <div className="col-md-3">
                                    <input
                                        type="text"
                                        value={row.name}
                                        // onChange={
                                        //     !row.revoke
                                        //         ? () => { }
                                        //         : (e) => handleChange(row.id, "name", e.target.value)
                                        // }
                                        onChange={(e) => handleChange(row.id, "name", e.target.value)}
                                        placeholder="Full Name"
                                        width={"100%"}
                                        required
                                        disabled={!row.revoke}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-3">
                                    <input
                                        type="email"
                                        value={row.email}
                                        // onChange={
                                        //     !row.revoke
                                        //         ? () => { }
                                        //         : (e) => handleChange(row.id, "email", e.target.value)
                                        // }
                                        onChange={(e) => handleChange(row.id, "email", e.target.value)}
                                        placeholder="E-mail address"
                                        width={"100%"}
                                        required
                                        disabled={!row.revoke}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-md-3">
                                    <FormControl className="form-control team-select">
                                        {row.teamIds && row.teamIds.length === 0 ? (
                                            <InputLabel id={`team-select-label-${row.id}`}>
                                                Choose Teams
                                            </InputLabel>
                                        ) : null}
                                        <Select
                                            labelId={`team-select-label-${row.id}`}
                                            id={`team-select-${row.id}`}
                                            multiple
                                            value={row.teamIds}
                                            onChange={(event) =>
                                                handleChange(row.id, "teamIds", event.target.value)
                                            }
                                            // input={<Input label="Select Teams" />}
                                            renderValue={(selected) =>
                                                selected
                                                    .map((value) => {
                                                        const selectedOption = teamOptions.find(
                                                            (option) => option.value === value
                                                        );
                                                        return selectedOption ? selectedOption.label : "";
                                                    })
                                                    .join(", ")
                                            }
                                            MenuProps={MenuProps}
                                            className="form-control team-select-menu"
                                            disabled={!row.updateTeam}
                                            
                                        >
                                            <MenuItem value="all" className="team-select-menu-item">
                                                <Checkbox
                                                    checked={
                                                        row.teamIds.length === teamOptions.length
                                                    }
                                                    indeterminate={
                                                        row.teamIds.length > 0 &&
                                                        row.teamIds.length < teamOptions.length
                                                    }
                                                />
                                                <ListItemText primary="Select All" />
                                            </MenuItem>

                                            {teamOptions.map((team) => (
                                                <MenuItem key={team.value} value={team.value} className="team-select-menu-item">
                                                    <Checkbox
                                                        checked={row.teamIds.indexOf(team.value) > -1}
                                                    />
                                                    <ListItemText primary={team.label} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-md-2">
                                    {row.invitedUserId ? (
                                        <>
                                            {row.revoke && row.updateTeam && (<button className="btn btn-mytpt" type="button"  onClick={()=>handleSend(row.id)}>
                                                Send Invite
                                            </button>)}
                                            {!row.revoke && row.updateTeam && (<button className="btn btn-mytpt" type="button"  onClick={()=>handleSend(row.id)}>
                                                Update
                                            </button>)}
                                            {!row.revoke && !row.updateTeam && (
                                                <p style={{ color: "#666", paddingTop: "7px", paddingLeft: "13px" }}>Invite Sent</p>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {row.invition_update_Button_Loader ? (
                                                <button
                                                    type="button"
                                                    // className="btn btn-mytpt"
                                                    onClick={() => handleSend(row.id)}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faSpinner}
                                                        spin
                                                        style={{ color: "#000000" }}
                                                    /></button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="btn btn-mytpt"
                                                    onClick={() => handleSend(row.id)}
                                                >Send Invite</button>
                                            )}
                                        </>
                                    )}
                                </div>

                                <div className="col-md-1">
                                    {row.invitedUserId ? (
                                        <div className="invite-cl">
                                            <button
                                                id={`demo-positioned-menu-${row.id}`}
                                                aria-controls={
                                                    openMenuId ? `demo-positioned-menu-${row.id}` : undefined
                                                }
                                                aria-haspopup="true"
                                                aria-expanded={openMenuId ? "true" : undefined}
                                                onClick={(e) => handleClick(e, row.id)}
                                                className="ellipsis"
                                            >
                                                <i className="fas fa-ellipsis-h"></i>
                                            </button>
                                            <Menu
                                                className="pp-box-one"
                                                anchorEl={anchorEl}
                                                open={openMenuId === row.id}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "right",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                            >
                                                <MenuItem className="menuItem" onClick={() => { handleClose(); handleChangeTeamFlag(row.id); }}>Re-assign Teams</MenuItem>
                                                <MenuItem className="menuItem" onClick={() => { handleClose(); /* handleSend(row.id) */ handleRevoke(row.id); }}>Change Co-admin</MenuItem>
                                                <MenuItem className="menuItem" onClick={() => { handleClose(); handleReSend(row.id); }}>Re-send Invite</MenuItem>
                                                <MenuItem className="menuItem remove-seat" onClick={() => { handleClose(); handleCancelAddOnSubscription(row.id); }}>Remove Seat</MenuItem>
                                            </Menu>

                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row ">
                        <div className="col-xs-4 text-left"></div>
                        <div className="col-xs-8 text-right">
                            <Tippy content={<span>Close Tool</span>}>
                                <button
                                    type="button"
                                    onClick={closeAccessManagement}
                                    className="btn btn-mytpt-red"
                                    data-dismiss="modal"
                                >
                                    <i className="fa fa-times"></i>
                                </button>
                            </Tippy>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccessManagement;
