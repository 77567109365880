import React, {
  useState,
  useEffect,
  fetchDataFromDatabase,
  useRef,
  useLayoutEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useAlert } from "react-alert";
import { Navigate, useNavigate } from "react-router-dom";
import { createPeople, getPeople } from "../../../store/actions/peopleAction";
import {
  createSkills,
  getSkills,
  updateSkills,
  dropUpdateSkills,
} from "../../../store/actions/skillAction";
import {
  createRole,
  getRole,
  updateRole,
  dropUpdateRole,
} from "../../../store/actions/roleAction";
import {
  createLink,
  getLink,
  updateLink,
  dropUpdateLink,
} from "../../../store/actions/linkAction";
import {
  createDomain,
  getDomain,
  updateDomain,
  dropUpdateDomain,
} from "../../../store/actions/domainAction";
import {
  createProject,
  getProject,
} from "../../../store/actions/projectAction";
import { createCircle, getCircle } from "../../../store/actions/circleAction";
import {
  createState,
  updateState,
  getState,
} from "../../../store/actions/stateAction";
// import {  } from "../../../store/actions/skillAction";
import { updateMember, getMember } from "../../../store/actions/memberAction";
import {
  PEOPLE_SUCCESS_MESSAGE_CLEAR,
  PEOPLE_ERROR_CLEAR,
  PEOPLE_ID_CLEAR,
} from "../../../store/types/peopleType";
import {
  SKILL_SUCCESS_MESSAGE_CLEAR,
  SKILL_ERROR_CLEAR,
  SKILL_ID_CLEAR,
  SKILLS_MODIFICATIONS,
} from "../../../store/types/skillType";
import {
  ROLE_SUCCESS_MESSAGE_CLEAR,
  ROLE_ERROR_CLEAR,
  ROLE_ID_CLEAR,
  ROLES_MODIFICATIONS,
} from "../../../store/types/roleType";
import {
  LINK_SUCCESS_MESSAGE_CLEAR,
  LINK_ERROR_CLEAR,
  LINK_ID_CLEAR,
  LINKS_MODIFICATIONS,
} from "../../../store/types/linkType";
import {
  DOMAIN_SUCCESS_MESSAGE_CLEAR,
  DOMAIN_ERROR_CLEAR,
  DOMAIN_ID_CLEAR,
  DOMAINS_MODIFICATIONS,
} from "../../../store/types/domainType";
import {
  PROJECT_SUCCESS_MESSAGE_CLEAR,
  PROJECT_ERROR_CLEAR,
  PROJECT_ID_CLEAR,
} from "../../../store/types/projectType";
import {
  CIRCLE_SUCCESS_MESSAGE_CLEAR,
  CIRCLE_ERROR_CLEAR,
  CIRCLE_ID_CLEAR,
} from "../../../store/types/circleType";
import {
  STATE_SUCCESS_MESSAGE_CLEAR,
  STATE_ERROR_CLEAR,
  STATES_MODIFICATIONS,
} from "../../../store/types/stateType";
import { noteCreate, getNote } from "../../../store/actions/noteAction";
import {
  MEMBER_UPDATE_SUCCESS,
  MEMBER_MODIFICATIONS,
} from "../../../store/types/memberType";
import { SERVER_URI, CARD_LIMIT, CLIENT_URL } from "../../../config/keys";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {
  createAndUpdateMember,
  getPFMember,
} from "../../../store/actions/pfmemberAction";
import { PCTOOL_STATE_CLAER } from "../../../store/types/toolsType";
import {
  historyCreate,
  historyGet,
} from "../../../store/actions/historyAction";
import Swal from "sweetalert2";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { createMember } from "../../../store/actions/memberAction";
import TextareaAutosize from "react-textarea-autosize";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  freezeWindow,
  unfreezeWindow,
} from "../../../../src/HelperFunctions/Overlay";
import { isCardExist } from "../../../../src/HelperFunctions/CardExist";

import { generateStripeUpgradeUrl } from "../../../store/actions/pricingAction";
// import { userVerify, paymentVerify } from "../../../store/actions/authAction";

import dragula from "dragula";
import { clearLocalStorage } from "../../../HelperFunctions/ClearLocalStorage";
import PillComponent from "./PillComponent";
import {
  aggrigatedPersonFTE,
  GetPersonAvailability,
  GetTotalWorkLoad,
  sumFTEBody,
  sumFTEBody_Focus,
} from "../../../HelperFunctions/FTE_cal";
import {
  Get_ShowHideColumn_Project_Circle_Mode,
  Get_ShowHideColumn_Project_Circle_Mode_AddedAs_people,
  GetTagFilteredSortedData,
  TagWiseSorting,
} from "../../../HelperFunctions/TagWiseSorting";
import Permission_ModePopUp from "../Permission_ModePopUp/Permission_ModePopUp";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "white" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  // padding: grid,
  // width: 250,
});

function Body({
  workloadToggle,
  openPeople,
  closePeople,
  openSkill,
  closeSkill,
  openRole,
  closeRole,
  openLink,
  closeLink,
  openCircle,
  closeCircle,
  openProject,
  closeProject,
  openDomain,
  closeDomain,
  indexSet,
  index,
  list,
  focusMode,
  compareMode,
  setCompareMode,
  naveSort,
  allSearch,
  isModeSwitched,
  clickedOnList,
  setClickedOnList,
  setIsEXT,
  isEXT,
  isNO,
  setIsNO,
  isTag,
  setIsTag,
  isTagData,
  setIsTagData,
  tagFilteredObj,
  setTagFilteredObj,
  extNoFilteredObj,
  setExtNoFilteredObj,
  specialModeDataObj,
  setSpecialModeDataObj,
  listHide,
  setListHide,
  focused,
  setFocused,
  setPepole,
  setPepoles,
  setSkill,
  setSkills,
  setRole,
  setRoles,
  setDomain,
  setDomains,
  setLink,
  setLinks,
  setCircle,
  setCircles,
  setProject,
  setProjects,
  openInfo,
  closeInfo,
  isInfos,
  isActive,
  xlsData,
  focusList,
  setFList,
  fList,
  setXls,
  setXlsName,
  setXlsCM,
  note,
  setNote,
  noteOnchange,
  saveNote,
  setSaveNote,
  openPeopleModeTool,
  openCircleModeTool,
  setFocusedMode,
  temporarilyMovedItem,
  setTemporarilyMovedItem,
}) {
  const { loading, successMessage, error, authenticate, myInfo, curUserData } =
    useSelector((state) => state.auth);
  const { peoples, cuPeopleId, peopleMessagee, peopleError } = useSelector(
    (state) => state.peoples
  );
  const {
    skills,
    cuSkillId,
    nDaoamins,
    skillsMessagee,
    skillError,
    skillsMessage,
  } = useSelector((state) => state.skills);
  const { roles, cuRoleId, dRoles, roleMessagee, roleError } = useSelector(
    (state) => state.roles
  );
  const {
    domains,
    cuDomainId,
    dDomains,
    domainMessage,
    domainMessagee,
    domainError,
  } = useSelector((state) => state.domains);
  const { links, cuLinkId, linkMessagee, linkError } = useSelector(
    (state) => state.links
  );
  const { circles, dCircles, cuCircleId, circleMessagee, circleError } =
    useSelector((state) => state.circles);
  const { projects, cuProjectId, projectMessagee, projectError } = useSelector(
    (state) => state.projects
  );
  const { members, memberMessagee, memberError, memberMessage } = useSelector(
    (state) => state.members
  );
  const { pfmembers, pfmemberMessagee, pfmemberError, pfmemberMessage } =
    useSelector((state) => state.pfmembers);
  const {
    meetings,
    meetingsMessage,
    meetingId,
    meetingsMessagee,
    meetingsError,
  } = useSelector((state) => state.meetings);
  const { noteData, noteMessage, noteMessagee, noteError } = useSelector(
    (state) => state.notes
  );
  const { circleTools, toolSuccessMessage, toolErrors } = useSelector(
    (state) => state.tools
  );
  const {
    states,
    statesLM,
    cuStateId,
    statesMessagee,
    statesError,
    statesMessage,
  } = useSelector((state) => state.states);
  const { stripeUrl } = useSelector((state) => state.pricings);

  const Allstate = useSelector((state) => state);

  const parentPeopleRef = useRef(null);
  const parentSkillRef = useRef(null);
  const parentRoleRef = useRef(null);
  const parentCircleRef = useRef(null);
  const parentProjectRef = useRef(null);
  const focusListRef = useRef(null);
  const setCombinedRefs = (providedRef, customRef) => (element) => {
    providedRef(element);
    customRef.current = element;
  };

  const [focusedRef, setFocusedRef] = useState(0);

  /* Added Overlay on state change to prevent duplicate actions. Credit: Indranil */
  useEffect(() => {
    //This will add a transparent layer over the app window so that nothing else can be clicked afret one action. Thsi unfreeze automatically hapens after 500 ms of freezing.
    // freezeWindow();
    // scrollToDiv();
  }, [Allstate]);

  useLayoutEffect(() => {
    if (focusedRef && focusListRef && focusListRef.current) {
      // console.log({ focusedRef });
      focusListRef.current.scrollTop = focusedRef;
      // console.log('after asigning scrolltop', focusListRef.current.scrollTop)
    }
    // unfreezeWindow()
  });

  let adminId = myInfo.userId;
  let teamId = localStorage.getItem("teamId");
  let url = `${SERVER_URI}/user/person/create`;

  let dispatch = useDispatch();

  /* Redirect the user to the login page if the session expiry message is captured. */
  useEffect(() => {
    let error =
      peopleError ||
      skillError ||
      roleError ||
      domainError ||
      linkError ||
      circleError ||
      projectError;
    if (
      error.trim() === "Your session has expired. Please re-login to continue."
    ) {
      // localStorage.clear();
      clearLocalStorage();
      window.location.href = "/login";
    }
  }, [
    peopleError,
    skillError,
    roleError,
    domainError,
    linkError,
    circleError,
    projectError,
  ]);

  //...............................................All Model............................................................

  // get current time in particular format
  const currentTime = () => {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    var d = new Date();
    var day = days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }
    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();
    let curTime =
      month + " " + date + ", " + year + " " + hr + ":" + min + " " + ampm;
    return curTime;
  };

  //List From for field name
  const [lFromState, setLFromsState] = useState({
    Peoples: false,
    Skills: false,
    Roles: false,
    Domains: false,
    Links: false,
    Circles: false,
    Projects: false,
  });

  // People Modal
  const [fName, setFName] = useState("");
  const handleNameChange = (event) => {
    setFName(event.target.value);
  };

  const peopleSubmit = (event) => {
    event.preventDefault();
    // Added by Debashis on 13-12-2023 (updated 18-12-2023)
    let lastname = "";
    let firstname = "";
    if (fName.trim().indexOf(" ") >= 0) {
      let nameArray = fName.split(" ");
      lastname = nameArray.pop();
      firstname = nameArray.join(" ");
    } else {
      lastname = "";
      firstname = fName;
    }

    let data = {
      fname: firstname,
      lname: lastname,
      adminId: adminId,
      url: url,
      teamId: teamId,
    };
    if (data) {
      let peopleSort = localStorage.getItem("peopleSort");
      dispatch(createPeople({ ...data, peopleSort })).then(() => {
        lFromState.Peoples = false;
      });
    }
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (peopleMessagee && peopleMessagee.length > 0) {
      dispatch(getState(obj)).then(() => {
        localStorage.setItem("latest-created", cuPeopleId);
        dispatch({ type: PEOPLE_ID_CLEAR });
        dispatch({ type: PEOPLE_SUCCESS_MESSAGE_CLEAR });
        setFName("");
        setLFromsState({
          ...lFromState,
          ["Peoples"]: false,
        });
      });
      dispatch({ type: PEOPLE_SUCCESS_MESSAGE_CLEAR });
    }

    if (peopleError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: peopleError,
      });
      dispatch({ type: PEOPLE_ERROR_CLEAR });
    }
  }, [peopleMessagee, peopleError, cuPeopleId]);

  // Skill Modal
  const [skill, setSkillName] = useState("");
  const handleSkillChange = (event) => {
    setSkillName(event.target.value);
  };

  const skillSubmit = (event) => {
    event.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    let data = { skillName: skill, adminId: adminId, url: url, teamId: teamId };
    if (data) {
      let skillSort = localStorage.getItem("skillSort");
      dispatch(createSkills({ ...data, skillSort })).then((res) => {
        // dispatch({ type: SKILL_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getSkills(obj));
        lFromState.Skills = false;
      });
    }
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (skillsMessagee && skillsMessagee.length > 0) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuSkillId);
      dispatch({ type: SKILL_ID_CLEAR });
      dispatch({ type: SKILL_SUCCESS_MESSAGE_CLEAR });
      setSkillName("");
      setLFromsState({
        ...lFromState,
        ["Skills"]: false,
      });
    }

    if (skillError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: skillError,
      });
      // alert.error(peopleError)
      dispatch({ type: SKILL_ERROR_CLEAR });
      // dispatch(getSkills(obj));
      // closeSkill();
    }
  }, [skillsMessagee, skillError, cuSkillId]);

  // Role Modal
  const [role, setRoleName] = useState("");
  const handleRoleChange = (event) => {
    setRoleName(event.target.value);
  };

  const roleSubmit = (event) => {
    event.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    let roleSort = localStorage.getItem("roleSort");
    obj.userId = myInfo.userId;
    let data = { roleName: role, adminId: adminId, url: url, teamId: teamId };
    if (data) {
      dispatch(createRole({ ...data, roleSort })).then((res) => {
        // dispatch({ type: ROLE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getRole(obj));
        lFromState.Roles = false;
      });
    }
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (roleMessagee && roleMessagee.length > 0) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuRoleId);
      dispatch({ type: ROLE_ID_CLEAR });
      dispatch({ type: ROLE_SUCCESS_MESSAGE_CLEAR });
      setRoleName("");
      setLFromsState({
        ...lFromState,
        ["Roles"]: false,
      });
    }

    if (roleError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: roleError,
      });
      // alert.error(peopleError)
      dispatch({ type: ROLE_ERROR_CLEAR });
      // dispatch(getRole(obj));
      // closeRole();
    }
  }, [roleMessagee, roleError, cuRoleId]);

  // Link Modal
  const [link, setLinkName] = useState("");
  const handleLinkChange = (event) => {
    setLinkName(event.target.value);
  };

  const linkSubmit = (event) => {
    event.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    let data = { linkName: link, adminId: adminId, url: url, teamId: teamId };
    let linkSort = localStorage.getItem("linkSort");
    if (data) {
      dispatch(createLink({ ...data, linkSort })).then((res) => {
        dispatch({ type: LINK_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getLink(obj));
        lFromState.Links = false;
      });
    }
  };

  // console.log("linkmsg in body", linkMessagee);

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;

    if (linkMessagee) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuLinkId);
      dispatch({ type: LINK_ID_CLEAR });
      dispatch({ type: LINK_SUCCESS_MESSAGE_CLEAR });
      setLinkName("");
      setLFromsState({
        ...lFromState,
        ["Links"]: false,
      });
      dispatch({ type: LINK_SUCCESS_MESSAGE_CLEAR });
    }

    if (linkError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: linkError,
      });
      // alert.error(peopleError)
      dispatch({ type: LINK_ERROR_CLEAR });
      // dispatch(getDomain(obj));
      // closeLink();
    }
  }, [linkMessagee, linkError, cuLinkId]);

  // Domain Modal
  const [domain, setDomainName] = useState("");
  const handleDomainChange = (event) => {
    setDomainName(event.target.value);
  };

  const domainSubmit = (event) => {
    event.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    let data = {
      domainName: domain,
      adminId: adminId,
      url: url,
      teamId: teamId,
    };
    let domainSort = localStorage.getItem("domainSort");
    let skillSort = localStorage.getItem("skillSort");
    let roleSort = localStorage.getItem("roleSort");
    if (data) {
      dispatch(createDomain({ ...data, domainSort, skillSort, roleSort })).then(
        (res) => {
          dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
          // dispatch(getDomain({...obj, domainSort}));
          lFromState.Domains = false;
        }
      );
    }
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (domainMessagee) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuDomainId);
      dispatch({ type: DOMAIN_ID_CLEAR });
      dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
      setDomainName("");
      setLFromsState({
        ...lFromState,
        ["Domains"]: false,
      });
      dispatch({ type: DOMAIN_SUCCESS_MESSAGE_CLEAR });
    }

    if (domainError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: domainError,
      });
      dispatch({ type: DOMAIN_ERROR_CLEAR });
    }
  }, [domainMessagee, domainError, cuDomainId]);

  // Circle Modal
  const [circle, setCircleName] = useState("");
  const handleCircleChange = (event) => {
    setCircleName(event.target.value);
  };

  const circleSubmit = (event) => {
    event.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    let circleSort = localStorage.getItem("circleSort");
    let data = {
      circleName: circle,
      adminId: adminId,
      url: url,
      teamId: teamId,
    };
    if (data) {
      dispatch(createCircle({ ...data, circleSort })).then((res) => {
        lFromState.Circles = false;
        setCircleName("");
        dispatch({ type: CIRCLE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getCircle(obj));
      });
    }
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (circleMessagee && circleMessagee.length > 0) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuCircleId);
      dispatch({ type: CIRCLE_ID_CLEAR });
      dispatch({ type: CIRCLE_SUCCESS_MESSAGE_CLEAR });

      setCircleName("");
      setLFromsState({
        ...lFromState,
        ["Circles"]: false,
      });
      dispatch({ type: CIRCLE_SUCCESS_MESSAGE_CLEAR });
    }

    if (circleError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: circleError,
      });
      dispatch({ type: CIRCLE_ERROR_CLEAR });
    }
  }, [circleMessagee, circleError, cuCircleId]);

  // Project Modal
  const [project, setProjectName] = useState("");
  const handleProjectChange = (event) => {
    setProjectName(event.target.value);
  };

  const projectSubmit = (event) => {
    event.preventDefault();
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    let data = {
      projectName: project,
      adminId: adminId,
      url: url,
      teamId: teamId,
    };
    if (data) {
      let projectSort = localStorage.getItem("projectSort");
      dispatch(createProject({ ...data, projectSort })).then((res) => {
        lFromState.Projects = false;
        setProjectName("");
        dispatch({ type: PROJECT_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getProject(obj));
      });
    }
  };

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (projectMessagee && projectMessagee.length > 0) {
      dispatch(getState(obj));
      localStorage.setItem("latest-created", cuProjectId);
      dispatch({ type: PROJECT_ID_CLEAR });
      dispatch({ type: PROJECT_SUCCESS_MESSAGE_CLEAR });
      setProjectName("");
      setLFromsState({
        ...lFromState,
        ["Projects"]: false,
      });
      dispatch({ type: PROJECT_SUCCESS_MESSAGE_CLEAR });
    }

    if (projectError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: projectError,
      });
      dispatch({ type: PROJECT_ERROR_CLEAR });
      // closeProject();
    }
  }, [projectMessagee, projectError, cuProjectId]);

  //.......................................................All Curd State.......................................................//

  //................................................ Sorting , Drag and Drop..................................................

  // Peoples
  const peopleDragItem = useRef();
  const peopleDragOverItem = useRef();
  const [peopleSorted, setpeopleSorted] = useState([]);

  useEffect(() => {
    if (
      peoples &&
      peoples.length >= 0 &&
      JSON.stringify(peoples) !== JSON.stringify(peopleSorted)
    ) {
      // console.log(peoples, peopleSorted);
      setpeopleSorted(peoples);
    }
  }, [peoples]);

  const peopleStateUpdate = (curpeople) => {
    // console.log('peopleStateUpdate: ', curpeople);
    if (curpeople.length > 0) {
      localStorage.setItem("peopleSort", curpeople);
    }

    // console.log('States: ', states);
    // let updated_states = {
    //   ...states,
    //   Person: curpeople
    // };
    // console.log('Updated states: ', updated_states);
    // dispatch({ type: STATES_MODIFICATIONS, payload: updated_states })
    freezeWindow(false);
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        Person: curpeople,
      })
    ).then(() => {
      dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
      dispatch(
        getState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          Person: curpeople,
        })
      );
      unfreezeWindow();
      // console.log('false')
    });
  };

  // states
  const PeopleSort = (data) => {
    let peoplest = [...peopleSorted];
    if (data === "NewToOld") {
      peoplest.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    if (data === "OldToNew") {
      peoplest
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .reverse();
    }
    if (data === "AtoZ") {
      peoplest.sort((a, b) => {
        const nameA = `${a.fname.toUpperCase()} ${a.lname.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.fname.toUpperCase()} ${b.lname.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "ZtoA") {
      peoplest.sort((a, b) => {
        const nameA = `${a.fname.toUpperCase()} ${a.lname.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.fname.toUpperCase()} ${b.lname.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "Parsonal") {
      let curPData = [];
      if (
        states &&
        states.Person &&
        states.Person.length > 0 &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        for (let i = 0; i < states.Person.length; i++) {
          for (let j = 0; j < peopleSorted.length; j++) {
            if (states.Person[i] === peopleSorted[j]._id) {
              curPData.push(peopleSorted[j]);
            }
          }
        }
      }
      if (curPData && curPData.length > 0) {
        peoplest = curPData;
      }
    }
    setpeopleSorted(peoplest);

    // console.log('PeopleSort: ', peoplest);
  };

  // Skills
  const skillsDragItem = useRef();
  const skillsDragOverItem = useRef();
  const [skillsSorted, setskillsSorted] = useState([]);

  useEffect(() => {
    if (
      skills &&
      skills.length >= 0 &&
      JSON.stringify(skills) !== JSON.stringify(skillsSorted)
    ) {
      setskillsSorted(skills);
    }
  }, [skills]);

  const skillStateUpdate = (curskill) => {
    if (curskill.length > 0) {
      localStorage.setItem("skillSort", curskill);
    }

    freezeWindow(false);
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        url: `${SERVER_URI}/user/states/update`,
        Skills: curskill,
      })
    ).then(() => {
      dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });

      unfreezeWindow();
    });
  };

  //Skills sorting
  const SkillsSort = (data) => {
    let skillsData = [...skillsSorted];
    if (data === "NewToOld") {
      skillsData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    if (data === "OldToNew") {
      skillsData
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .reverse();
    }
    if (data === "AtoZ") {
      skillsData.sort((a, b) => {
        const nameA = `${a.skillName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.skillName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "ZtoA") {
      skillsData.sort((a, b) => {
        const nameA = `${a.skillName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.skillName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "Parsonal") {
      let curPData = [];
      if (
        states &&
        states.Skills &&
        states.Skills.length > 0 &&
        skillsSorted &&
        skillsSorted.length > 0
      ) {
        for (let i = 0; i < states.Skills.length; i++) {
          for (let j = 0; j < skillsSorted.length; j++) {
            if (states.Skills[i] === skillsSorted[j]._id) {
              curPData.push(skillsSorted[j]);
            }
          }
        }
      }
      if (curPData && curPData.length > 0) {
        skillsData = curPData;
      }
    }
    setskillsSorted(skillsData);
  };

  // Roles
  const rolesDragItem = useRef();
  const rolesDragOverItem = useRef();
  const [rolesSorted, setrolesSorted] = useState([]);

  const rolesDragStart = (e, position) => {
    rolesDragItem.current = position;
  };

  const rolesDragEnter = (e, position) => {
    rolesDragOverItem.current = position;
  };

  useEffect(() => {
    if (
      roles &&
      roles.length >= 0 &&
      JSON.stringify(roles) !== JSON.stringify(rolesSorted)
    ) {
      setrolesSorted(roles);
    }
  }, [roles]);

  const roleStateUpdate = (currole) => {
    // console.log('roleStateUpdate: ', currole)
    if (currole.length > 0) {
      localStorage.setItem("roleSort", currole);
    }

    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        url: `${SERVER_URI}/user/states/create`,
        Roles: currole,
      })
    ).then(() => {
      dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });

      unfreezeWindow();
    });
  };

  const RolesSort = (data) => {
    var rolesData = [...rolesSorted];
    if (data === "NewToOld") {
      rolesData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    if (data === "OldToNew") {
      rolesData
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .reverse();
    }
    if (data === "AtoZ") {
      rolesData.sort((a, b) => {
        const nameA = `${a.roleName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.roleName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "ZtoA") {
      rolesData.sort((a, b) => {
        const nameA = `${a.roleName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.roleName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "Parsonal") {
      let curPData = [];
      if (
        states &&
        states.Roles &&
        states.Roles.length > 0 &&
        rolesSorted &&
        rolesSorted.length > 0
      ) {
        for (let i = 0; i < states.Roles.length; i++) {
          for (let j = 0; j < rolesSorted.length; j++) {
            if (states.Roles[i] === rolesSorted[j]._id) {
              curPData.push(rolesSorted[j]);
            }
          }
        }
      }
      if (curPData && curPData.length > 0) {
        rolesData = curPData;
      }
    }
    setrolesSorted(rolesData);
  };

  //Domains
  const domainsDragItem = useRef();
  const domainsDragOverItem = useRef();
  const [domainsSorted, setdomainsSorted] = useState([]);

  if (domainsSorted.length === 0) {
    setTimeout(() => {
      setdomainsSorted(domains);
    }, 1);
  } else if (domainsSorted.length !== domains.length) {
    setdomainsSorted(domains);
  }

  useEffect(() => {
    if (
      domains &&
      domains.length >= 0 &&
      JSON.stringify(domains) !== JSON.stringify(domainsSorted)
    ) {
      setdomainsSorted(domains);
    }
  }, [domains]);

  const domainStateUpdate = (curdomain) => {
    // console.log('domainStateUpdate: ', curdomain);
    if (curdomain.length > 0) {
      localStorage.setItem("domainSort", curdomain);
    }

    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        url: `${SERVER_URI}/user/states/create`,
        Domains: curdomain,
      })
    ).then(() => {
      dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
      unfreezeWindow();
    });
  };

  const DomainsSort = (data) => {
    var domainsData = [...domainsSorted];
    if (data === "NewToOld") {
      domainsData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    if (data === "OldToNew") {
      domainsData
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .reverse();
    }
    if (data === "AtoZ") {
      domainsData.sort((a, b) => {
        const nameA = `${a.domainName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.domainName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "ZtoA") {
      domainsData.sort((a, b) => {
        const nameA = `${a.domainName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.domainName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "Parsonal") {
      let curPData = [];
      if (
        states &&
        states.Domains &&
        states.Domains.length > 0 &&
        domainsSorted &&
        domainsSorted.length > 0
      ) {
        for (let i = 0; i < states.Domains.length; i++) {
          for (let j = 0; j < domainsSorted.length; j++) {
            if (states.Domains[i] === domainsSorted[j]._id) {
              curPData.push(domainsSorted[j]);
            }
          }
        }
      }
      if (curPData && curPData.length > 0) {
        domainsData = curPData;
      }
    }
    // console.log(domainsData)
    setdomainsSorted(domainsData);
  };

  // Links
  const linksDragItem = useRef();
  const linksDragOverItem = useRef();
  const [linksSorted, setlinksSorted] = useState([]);

  useEffect(() => {
    if (
      links &&
      links.length >= 0 &&
      JSON.stringify(links) !== JSON.stringify(linksSorted)
    ) {
      setlinksSorted(links);
    }
  }, [links]);

  const linkStateUpdate = (curlink) => {
    // console.log('linkStateUpdate: ', curlink)
    if (curlink.length > 0) {
      localStorage.setItem("linkSort", curlink);
    }

    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        url: `${SERVER_URI}/user/states/create`,
        Links: curlink,
      })
    ).then(() => {
      dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });

      unfreezeWindow();
    });
  };

  //Links sorting
  const LinksSort = (data) => {
    var linksData = [...linksSorted];
    if (data === "NewToOld") {
      linksData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    if (data === "OldToNew") {
      linksData
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .reverse();
    }
    if (data === "AtoZ") {
      linksData.sort((a, b) => {
        const nameA = `${a.linkName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.linkName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "ZtoA") {
      linksData.sort((a, b) => {
        const nameA = `${a.linkName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.linkName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "Parsonal") {
      let curPData = [];
      if (
        states &&
        states.Links &&
        states.Links.length > 0 &&
        linksSorted &&
        linksSorted.length > 0
      ) {
        for (let i = 0; i < states.Links.length; i++) {
          for (let j = 0; j < linksSorted.length; j++) {
            if (states.Links[i] === linksSorted[j]._id) {
              curPData.push(linksSorted[j]);
            }
          }
        }
      }
      if (curPData && curPData.length > 0) {
        linksData = curPData;
      }
    }
    setlinksSorted(linksData);
  };

  // Circle
  const circlesDragItem = useRef();
  const circlesDragOverItem = useRef();
  const [circlesSorted, setCirclesSorted] = useState([]);

  useEffect(() => {
    if (
      circles &&
      circles.length >= 0 &&
      JSON.stringify(circles) !== JSON.stringify(circlesSorted)
    ) {
      setCirclesSorted(circles);
    }
  }, [circles]);

  const circleStateUpdate = (curcircle) => {
    // console.log('circleStateUpdate: ', curcircle);
    if (curcircle.length > 0) {
      localStorage.setItem("circleSort", curcircle);
    }

    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        url: `${SERVER_URI}/user/states/create`,
        Circles: curcircle,
      })
    ).then(() => {
      dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });

      unfreezeWindow();
    });
  };

  const CirclesSort = (data) => {
    var circlesData = [...circlesSorted];
    if (data === "NewToOld") {
      circlesData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    if (data === "OldToNew") {
      circlesData
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .reverse();
    }
    if (data === "AtoZ") {
      circlesData.sort((a, b) => {
        const nameA = `${a.circleName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.circleName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "ZtoA") {
      circlesData.sort((a, b) => {
        const nameA = `${a.circleName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.circleName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "Parsonal") {
      let curPData = [];
      if (
        states &&
        states.Circles &&
        states.Circles.length > 0 &&
        circlesSorted &&
        circlesSorted.length > 0
      ) {
        for (let i = 0; i < states.Circles.length; i++) {
          for (let j = 0; j < circlesSorted.length; j++) {
            if (states.Circles[i] === circlesSorted[j]._id) {
              curPData.push(circlesSorted[j]);
            }
          }
        }
      }
      if (curPData && curPData.length > 0) {
        circlesData = curPData;
      }
    }
    // console.log(circlesData)
    setCirclesSorted(circlesData);
  };

  // Projects
  const projectsDragItem = useRef();
  const projectsDragOverItem = useRef();
  const [projectsSorted, setprojectsSorted] = useState([]);

  useEffect(() => {
    if (
      projects &&
      projects.length >= 0 &&
      JSON.stringify(projects) !== JSON.stringify(projectsSorted)
    ) {
      setprojectsSorted(projects);
    }
  }, [projects]);

  const projectStateUpdate = (curproject) => {
    // console.log('projectStateUpdate: ', curproject)
    if (curproject.length > 0) {
      localStorage.setItem("projectSort", curproject);
    }

    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        url: `${SERVER_URI}/user/states/create`,
        Projects: curproject,
      })
    ).then(() => {
      dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });

      unfreezeWindow();
    });
  };

  //Projects sorting
  const ProjectsSort = (data) => {
    var projectsData = [...projectsSorted];
    if (data === "NewToOld") {
      projectsData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }
    if (data === "OldToNew") {
      projectsData
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .reverse();
    }
    if (data === "AtoZ") {
      projectsData.sort((a, b) => {
        const nameA = `${a.projectName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.projectName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return 1;
        }
        if (nameA < nameB) {
          return -1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "ZtoA") {
      projectsData.sort((a, b) => {
        const nameA = `${a.projectName.toUpperCase()}`; // ignore upper and lowercase
        const nameB = `${b.projectName.toUpperCase()}`; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      });
    }
    if (data === "Parsonal") {
      let curPData = [];
      if (
        states &&
        states.Projects &&
        states.Projects.length > 0 &&
        projectsSorted &&
        projectsSorted.length > 0
      ) {
        for (let i = 0; i < states.Projects.length; i++) {
          for (let j = 0; j < projectsSorted.length; j++) {
            if (states.Projects[i] === projectsSorted[j]._id) {
              curPData.push(projectsSorted[j]);
            }
          }
        }
      }
      if (curPData && curPData.length > 0) {
        projectsData = curPData;
      }
    }
    // console.log(projectsData)
    setprojectsSorted(projectsData);
  };

  useEffect(() => {
    let datas = localStorage.getItem("sortType");
    if (datas && datas === "ACSORT" && naveSort.length > 0) {
      localStorage.removeItem("sortType");
      PeopleSort(naveSort);
      SkillsSort(naveSort);
      RolesSort(naveSort);
      LinksSort(naveSort);
      DomainsSort(naveSort);
      CirclesSort(naveSort);
      ProjectsSort(naveSort);
    }
  }, [naveSort]);

  const handeSorts = (modes, list) => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (
      list === "People" &&
      states &&
      states.cListMode &&
      Object.keys(states.cListMode).length > 0
    ) {
      let allListMode = {
        ...states.cListMode,
        People: modes,
      };
      if (modes === "NewToOld") {
        PeopleSort("NewToOld");
      } else if (modes === "OldToNew") {
        PeopleSort("OldToNew");
      } else if (modes === "AtoZ") {
        PeopleSort("AtoZ");
      } else if (modes === "ZtoA") {
        PeopleSort("ZtoA");
      }
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getState(obj));
        // localStorage.setItem("sortType", "ACSORT")
      });
    }

    if (
      list === "Skills" &&
      states &&
      states.cListMode &&
      Object.keys(states.cListMode).length > 0
    ) {
      let allListMode = {
        ...states.cListMode,
        Skills: modes,
      };
      if (modes === "NewToOld") {
        SkillsSort("NewToOld");
      } else if (modes === "OldToNew") {
        SkillsSort("OldToNew");
      } else if (modes === "AtoZ") {
        SkillsSort("AtoZ");
      } else if (modes === "ZtoA") {
        SkillsSort("ZtoA");
      }
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getState(obj));
        // localStorage.setItem("sortType", "ACSORT")
      });
    }

    if (
      list === "Roles" &&
      states &&
      states.cListMode &&
      Object.keys(states.cListMode).length > 0
    ) {
      let allListMode = {
        ...states.cListMode,
        Roles: modes,
      };
      if (modes === "NewToOld") {
        RolesSort("NewToOld");
      } else if (modes === "OldToNew") {
        RolesSort("OldToNew");
      } else if (modes === "AtoZ") {
        RolesSort("AtoZ");
      } else if (modes === "ZtoA") {
        RolesSort("ZtoA");
      }
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getState(obj));
        // RolesSort(modes)
        // localStorage.setItem("sortType", "ACSORT")
      });
    }

    if (
      list === "Domains" &&
      states &&
      states.cListMode &&
      Object.keys(states.cListMode).length > 0
    ) {
      let allListMode = {
        ...states.cListMode,
        Domains: modes,
      };
      if (modes === "NewToOld") {
        DomainsSort("NewToOld");
      } else if (modes === "OldToNew") {
        DomainsSort("OldToNew");
      } else if (modes === "AtoZ") {
        DomainsSort("AtoZ");
      } else if (modes === "ZtoA") {
        DomainsSort("ZtoA");
      }
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getState(obj));
        // DomainsSort(modes)
        // localStorage.setItem("sortType", "ACSORT")
      });
    }

    if (
      list === "Links" &&
      states &&
      states.cListMode &&
      Object.keys(states.cListMode).length > 0
    ) {
      let allListMode = {
        ...states.cListMode,
        Links: modes,
      };
      if (modes === "NewToOld") {
        LinksSort("NewToOld");
      } else if (modes === "OldToNew") {
        LinksSort("OldToNew");
      } else if (modes === "AtoZ") {
        LinksSort("AtoZ");
      } else if (modes === "ZtoA") {
        LinksSort("ZtoA");
      }
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getState(obj));
        // LinksSort(modes)
        // localStorage.setItem("sortType", "ACSORT")
      });
    }

    if (
      list === "Circles" &&
      states &&
      states.cListMode &&
      Object.keys(states.cListMode).length > 0
    ) {
      let allListMode = {
        ...states.cListMode,
        Circles: modes,
      };
      if (modes === "NewToOld") {
        CirclesSort("NewToOld");
      } else if (modes === "OldToNew") {
        CirclesSort("OldToNew");
      } else if (modes === "AtoZ") {
        CirclesSort("AtoZ");
      } else if (modes === "ZtoA") {
        CirclesSort("ZtoA");
      }
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getState(obj));
        // CirclesSort(modes)
        // localStorage.setItem("sortType", "ACSORT")
      });
    }

    if (
      list === "Projects" &&
      states &&
      states.cListMode &&
      Object.keys(states.cListMode).length > 0
    ) {
      let allListMode = {
        ...states.cListMode,
        Projects: modes,
      };
      if (modes === "NewToOld") {
        ProjectsSort("NewToOld");
      } else if (modes === "OldToNew") {
        ProjectsSort("OldToNew");
      } else if (modes === "AtoZ") {
        ProjectsSort("AtoZ");
      } else if (modes === "ZtoA") {
        ProjectsSort("ZtoA");
      }
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        // dispatch(getState(obj));
        // ProjectsSort(modes)
        // localStorage.setItem("sortType", "ACSORT")
      });
    }
  };

  //................................ fOCUSED MODELS .........................................
  let Fcard = localStorage.getItem("fCard");
  let Findex = localStorage.getItem("fIndex");
  let FCardId = localStorage.getItem("fCardId");

  let fData = {
    card: Fcard ? Fcard : "",
    index: Findex ? Number(Findex) : "",
    id: FCardId ? FCardId : "",
  };

  // const [focused, setFocused] = useState({ card: '', index: null })

  if (focused.card.length === 0 && fData.card.length > 0) {
    setFocused(fData);
  }

  useEffect(() => {
    if (focused.card.length > 0 && focused.index > -1) {
      localStorage.setItem("fCard", focused.card);
      localStorage.setItem("fIndex", focused.index);
      localStorage.setItem("fCardId", focused.id);
      // scrollToDiv();
    }
  }, [focused, setFocused]);

  const scrollToDiv = (id) => {
    setTimeout(() => {
      /* const elements = document.getElementsByClassName("focused-card");
      if (elements.length > 0) {
        // elements[0].scrollIntoView({ behavior: 'smooth' });
        elements[0].scrollIntoView();
      } */

      if (focusedRef && focusListRef && focusListRef.current) {
        // console.log({ focusedRef });
        focusListRef.current.scrollTop = focusedRef;
        // console.log('after asigning scrolltop', focusListRef.current.scrollTop)
      }
    }, 100);
  };

  /* if (parentPeopleRef.current) {
    const scrollTop = parentPeopleRef.current.scrollTop;
    // console.log("Scroll position:", scrollTop);
    setFocusedRef(scrollTop);
  } */

  const handelFocused = (data) => {
    if (data.card === "People") {
      if (parentPeopleRef.current) {
        const scrollTop = parentPeopleRef.current.scrollTop;
        // console.log("Scroll position:", scrollTop);
        setFocusedRef(scrollTop);
      }
      setFocused(data);
      scrollToDiv();
    } else if (data.card === "Circles") {
      if (parentCircleRef.current) {
        const scrollTop = parentCircleRef.current.scrollTop;
        // console.log("Scroll position:", scrollTop);
        setFocusedRef(scrollTop);
      }
      setFocused(data);
      scrollToDiv();
    } else if (data.card === "Projects") {
      if (parentProjectRef.current) {
        const scrollTop = parentProjectRef.current.scrollTop;
        // console.log("Scroll position:", scrollTop);
        setFocusedRef(scrollTop);
      }
      setFocused(data);
      scrollToDiv();
    } else if (data.card === "CPeople") {
      localStorage.setItem("fCard", "");
      localStorage.setItem("fIndex", null);
      localStorage.setItem("fCardId", null);
      setFocused({ card: "", index: null, id: null });
    } else if (data.card === "CCircles") {
      localStorage.setItem("fCard", "");
      localStorage.setItem("fIndex", null);
      localStorage.setItem("fCardId", null);
      setFocused({ card: "", index: null, id: null });
    } else if (data.card === "CProjects") {
      localStorage.setItem("fCard", "");
      localStorage.setItem("fIndex", null);
      localStorage.setItem("fCardId", null);
      setFocused({ card: "", index: null, id: null });
    }

    localStorage.removeItem("fCard");
    localStorage.removeItem("fIndex");
    localStorage.removeItem("FIndex");
    localStorage.removeItem("fCardId");
    localStorage.removeItem("popoverId");
    localStorage.removeItem("popoverIds");
    localStorage.removeItem("pfmemberId");
    localStorage.removeItem("pfmemberIds");
    localStorage.removeItem("fcData");
    localStorage.removeItem("fcDatas");
    localStorage.removeItem("dragPepleCard");
  };

  /* ............................. People focused conditions ............................. */
  //Skill
  let PfoSkills = [];
  if (
    focused &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    focused.index > -1 &&
    peopleSorted.length > 0
  ) {
    let foPeople = focused.id;
    for (let i = 0; i < skills.length; i++) {
      let data = skills[i].owners;
      if (data && data.length > 0) {
        let inc = skills[i].owners.includes(foPeople);
        if (inc) {
          PfoSkills.push(skills[i]);
        }
      }
    }
  }

  let PfouSkills = [...skills];
  if (PfoSkills && PfoSkills.length > 0) {
    for (let i = 0; i < PfoSkills.length; i++) {
      PfouSkills = PfouSkills.filter((e) => {
        return e._id !== PfoSkills[i]._id;
        // console.log(e._id === PfoSkills[i]._id)
      });
    }
  }

  //Roles
  let PfoRoles = [];
  if (
    focused &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    focused.index > -1 &&
    peopleSorted.length > 0
  ) {
    let foPeople = focused.id;
    for (let i = 0; i < roles.length; i++) {
      let data = roles[i].owners;
      if (data && data.length > 0) {
        let inc = roles[i].owners.includes(foPeople);
        if (inc) {
          PfoRoles.push(roles[i]);
        }
      }
    }
  }

  let PfouRoles = [...roles];
  if (PfoRoles && PfoRoles.length > 0) {
    for (let i = 0; i < PfoRoles.length; i++) {
      PfouRoles = PfouRoles.filter((e) => {
        return e._id !== PfoRoles[i]._id;
      });
    }
  }

  //Links
  let PfoLinks = [];
  if (
    focused &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    focused.index > -1 &&
    peopleSorted.length > 0
  ) {
    let foPeople = focused.id;
    for (let i = 0; i < links.length; i++) {
      let data = links[i].owner;
      if (data && data.length > 0) {
        let inc = [data].includes(foPeople);
        if (inc) {
          PfoLinks.push(links[i]);
        }
      }
    }
  }

  let PfouLinks = [...links];
  if (PfoLinks && PfoLinks.length > 0) {
    for (let i = 0; i < PfoLinks.length; i++) {
      PfouLinks = PfouLinks.filter((e) => {
        return e._id !== PfoLinks[i]._id;
        // console.log(e._id === PfoSkills[i]._id)
      });
    }
  }

  //Domains
  let PfoDomains = [];
  if (
    focused &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    focused.index > -1 &&
    peopleSorted.length > 0
  ) {
    let foPeople = focused.id;
    for (let i = 0; i < domains.length; i++) {
      if (domains[i].owners) {
        let data = domains[i].owners.owner;
        if (data && data.length > 0) {
          let inc = [data].includes(foPeople);
          if (inc) {
            PfoDomains.push(domains[i]);
          }
        }
      }

      if (
        domains[i].standIn &&
        domains[i].standIn.toString() === foPeople.toString()
      ) {
        PfoDomains.push(domains[i]);
      }
    }
  }

  // let PfouDomains = [...domains];
  let PfouDomains = [];
  if (Array.isArray(domains)) {
    PfouDomains = [...domains];
  }

  if (PfoDomains && PfoDomains.length > 0) {
    for (let i = 0; i < PfoDomains.length; i++) {
      PfouDomains = PfouDomains.filter((e) => {
        return e._id !== PfoDomains[i]._id;
        // console.log(e._id === PfoSkills[i]._id)
      });
    }
  }

  //Circles
  let PfoCircles = [];
  if (
    focused &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    focused.index > -1 &&
    peopleSorted.length > 0
  ) {
    let foPeople = focused.id;
    if (circles && circles.length > 0) {
      for (let i = 0; i < circles.length; i++) {
        if (
          circles[i].lead &&
          circles[i].lead.length > 0 &&
          circles[i].lead === foPeople
        ) {
          PfoCircles.push(circles[i]);
        }
      }

      if (roles && roles.length > 0) {
        for (let i = 0; i < circles.length; i++) {
          for (let j = 0; j < roles.length; j++) {
            if (
              circles[i].lead &&
              circles[i].lead.length > 0 &&
              circles[i].lead === roles[j]._id
            ) {
              let incs = roles[j].owners.includes(foPeople);
              if (incs) {
                PfoCircles.push(circles[i]);
                break;
              }
            }
          }
        }
      }

      if (skills && skills.length > 0) {
        for (let i = 0; i < circles.length; i++) {
          for (let j = 0; j < skills.length; j++) {
            if (
              circles[i].lead &&
              circles[i].lead.length > 0 &&
              circles[i].lead === skills[j]._id
            ) {
              let incs = skills[j].owners.includes(foPeople);
              if (incs) {
                PfoCircles.push(circles[i]);
                break;
              }
            }
          }
        }
      }
    }
  }

  //Project
  let PfoProjects = [];
  if (
    focused &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    focused.index > -1 &&
    peopleSorted.length > 0
  ) {
    let foPeople = focused.id;
    if (projectsSorted && projectsSorted.length > 0) {
      for (let i = 0; i < projectsSorted.length; i++) {
        if (
          projectsSorted[i] &&
          projectsSorted[i].lead &&
          projectsSorted[i].lead.length > 0 &&
          projectsSorted[i].lead === foPeople
        ) {
          PfoProjects.push(projectsSorted[i]);
        }
      }
      if (roles && roles.length > 0) {
        for (let i = 0; i < projectsSorted.length; i++) {
          for (let j = 0; j < roles.length; j++) {
            if (
              projectsSorted[i] &&
              projectsSorted[i].lead &&
              projectsSorted[i].lead.length > 0 &&
              projectsSorted[i].lead === roles[j]._id
            ) {
              let incs = roles[j].owners.includes(foPeople);
              if (incs) {
                PfoProjects.push(projectsSorted[i]);
                break;
              }
            }
          }
        }
      }
      if (skills && skills.length > 0) {
        for (let i = 0; i < projectsSorted.length; i++) {
          for (let j = 0; j < skills.length; j++) {
            if (
              projectsSorted[i] &&
              projectsSorted[i].lead &&
              projectsSorted[i].lead.length > 0 &&
              projectsSorted[i].lead === skills[j]._id
            ) {
              let incs = skills[j].owners.includes(foPeople);
              if (incs) {
                PfoProjects.push(projectsSorted[i]);
                break;
              }
            }
          }
        }
      }
    }
  }

  /* ............................. People focused action............................. */

  //Skills
  //Add focused owner in curent skills
  const PSkillAdd = (data) => {
    freezeWindow();
    let urls = `${SERVER_URI}/user/skill/update`;
    let skillSort = localStorage.getItem("skillSort");
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        skillName: data.skillName,
        purpose: data.purpose,
        tasks: data.tasks,
        ownerType: data.ownerType ? data.ownerType : "Single owner",
        owners: data.owners ? data.owners : [],
        domains: data.domains,
        memberIn: data.memberIn,
        tags: data.tags,
        newTag: [],
        adminId: adminId,
        url: urls,
      };
      if (
        arr.ownerType === "Single owner" &&
        focused &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        arr.owners = [focused.id];
      }
      if (
        arr.ownerType === "Multiple owners" &&
        focused &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        arr.owners = [...arr.owners, focused.id];
      }

      /* Hot Change in redux store */
      let updatedSkills = [...skills];
      let target_index = updatedSkills.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedSkills.splice(target_index, 1, arr);
      }
      dispatch({ type: SKILLS_MODIFICATIONS, payload: updatedSkills });
      /* Hot Change in redux store */

      let domainSort = localStorage.getItem("domainSort");
      let circleSort = localStorage.getItem("circleSort");
      let projectSort = localStorage.getItem("projectSort");
      dispatch(
        dropUpdateSkills({
          ...arr,
          skillSort,
          domainSort,
          circleSort,
          projectSort,
        })
      ).then(() => {
        // setskillsSorted(skills);
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(()=>{
          unfreezeWindow();
        });
      });
    }
  };

  const PSkillSub = (data) => {
    freezeWindow();
    // console.log(2242, 'PSkillSub', data, focused);
    let urls = `${SERVER_URI}/user/skill/update`;
    let skillSort = localStorage.getItem("skillSort");
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        skillName: data.skillName,
        purpose: data.purpose,
        tasks: data.tasks,
        ownerType: data.ownerType ? data.ownerType : "Single owner",
        owners: data.owners ? data.owners : [],
        domains: data.domains,
        memberIn: data.memberIn,
        tags: data.tags,
        newTag: [],
        adminId: adminId,
        url: urls,
      };

      if (arr.ownerType === "Single owner") {
        arr.owners = [];
      }

      if (
        arr.ownerType === "Multiple owners" &&
        focused &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        arr.owners = arr.owners.filter((e) => {
          return e !== focused.id;
        });
      }

      let updatedArr = { ...arr };
      if (arr.domains && arr.domains.length > 0) {
        // Iterate through domains and update owner to null if personId is present in domain's owner
        updatedArr = {
          ...updatedArr,
          owners: arr.owners.filter((e) => e !== focused.id),
          domains: arr.domains.map((item) => ({
            ...item,
            owner: item.owner === focused.id ? null : item.owner,
          })),
        };
      }

      /* Hot Change in redux store */
      let updatedSkills = [...skills];
      let target_index = updatedSkills.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedSkills.splice(target_index, 1, updatedArr);
      }
      dispatch({ type: SKILLS_MODIFICATIONS, payload: updatedSkills });
      /* Hot Change in redux store */

      let domainSort = localStorage.getItem("domainSort");
      let circleSort = localStorage.getItem("circleSort");
      let projectSort = localStorage.getItem("projectSort");
      dispatch(
        dropUpdateSkills({
          ...updatedArr,
          skillSort,
          domainSort,
          circleSort,
          projectSort,
        })
      ).then(() => {
        // setskillsSorted(skills);
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(()=>{
          unfreezeWindow();
        });
      });
    }
  };

  // Drag and drop
  const foSkillDrop = (e, action) => {
    // console.log(e, action);
  };

  //Roles
  //Add focused owner in curent skills
  const PRolesAdd = (data) => {
    freezeWindow();
    let urls = `${SERVER_URI}/user/role/update`;
    let roleSort = localStorage.getItem("roleSort");
    let domainSort = localStorage.getItem("domainSort");
    let circleSort = localStorage.getItem("circleSort");
    let projectSort = localStorage.getItem("projectSort");

    /* let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    obj.currole = currole; */
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        roleName: data.roleName,
        purpose: data.purpose,
        tasks: data.tasks,
        ownerRole: data.ownerRole,
        owners: data.owners,
        domains: data.domains,
        memberIn: data.memberIn,
        tags: data.tags,
        newTag: [],
        adminId: adminId,
        url: urls,
      };
      if (
        arr.ownerRole === "Single owner" &&
        focused &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        arr.owners = [focused.id];
      }
      if (
        arr.ownerRole === "Multiple owners" &&
        focused &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        arr.owners = [...arr.owners, focused.id];
      }
      // console.log(arr)

      /* Hot Change in redux store */
      let updatedRoles = [...roles];
      let target_index = updatedRoles.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedRoles.splice(target_index, 1, arr);
      }
      dispatch({ type: ROLES_MODIFICATIONS, payload: updatedRoles });
      /* Hot Change in redux store */

      dispatch(
        dropUpdateRole({
          ...arr,
          roleSort,
          domainSort,
          circleSort,
          projectSort,
        })
      ).then(() => {
        // dispatch(getRole({ ...obj, currole }));
        // dispatch(getDomain({ ...obj, domainSort }));
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(()=>{
          unfreezeWindow();
        });
      });
    }
  };

  const PRolesSub = (data) => {
    freezeWindow();
    let urls = `${SERVER_URI}/user/role/update`;
    let roleSort = localStorage.getItem("roleSort");
    let domainSort = localStorage.getItem("domainSort");
    let circleSort = localStorage.getItem("circleSort");
    let projectSort = localStorage.getItem("projectSort");
    /* currole = currole.split(",");
    // console.log(currole) */
    /* let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    obj.currole = currole; */
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        roleName: data.roleName,
        purpose: data.purpose,
        tasks: data.tasks,
        ownerRole: data.ownerRole,
        owners: data.owners,
        domains: data.domains,
        memberIn: data.memberIn,
        tags: data.tags,
        newTag: [],
        adminId: adminId,
        url: urls,
      };

      if (arr.ownerRole === "Single owner") {
        arr.owners = [];
      }

      if (
        arr.ownerRole === "Multiple owners" &&
        focused &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        arr.owners = arr.owners.filter((e) => {
          return e !== focused.id;
        });
      }

      let updatedArr = { ...arr };
      if (arr.domains && arr.domains.length > 0) {
        // Iterate through domains and update owner to null if personId is present in domain's owner
        updatedArr = {
          ...updatedArr,
          owners: arr.owners.filter((e) => e !== focused.id),
          domains: arr.domains.map((item) => ({
            ...item,
            owner: item.owner === focused.id ? null : item.owner,
          })),
        };
      }

      /* Hot Change in redux store */
      let updatedRoles = [...roles];
      let target_index = updatedRoles.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedRoles.splice(target_index, 1, updatedArr);
      }
      dispatch({ type: ROLES_MODIFICATIONS, payload: updatedRoles });
      /* Hot Change in redux store */

      dispatch(
        dropUpdateRole({
          ...updatedArr,
          roleSort,
          domainSort,
          circleSort,
          projectSort,
        })
      ).then(() => {
        // dispatch(getRole({ ...obj, currole }));
        // dispatch(getDomain({ ...obj, domainSort }));
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(()=>{
          unfreezeWindow();
        });
      });
    }
  };

  //Domains
  const PDomainsAdd = (data) => {
    freezeWindow();
    let urls = `${SERVER_URI}/user/domain/update`;
    let curdomain = localStorage.getItem("domainSort");
    curdomain = curdomain.split(",");
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    obj.curdomain = curdomain;
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        domainName: data.domainName,
        purpose: data.purpose,
        tasks: data.tasks,
        owners: data.owners ? data.owners : {},
        standIn: data.standIn ? data.standIn : null,
        memberIn: data.memberIn,
        tags: data.tags ? data.tags : [],
        newTag: [],
        adminId: adminId,
        url: urls,
        teamId: teamId,
      };
      if (arr && focused && peopleSorted && peopleSorted.length > 0) {
        arr.owners = {
          type: "63d3c91d40c4cf67373922e2",
          owner: focused.id,
        };
      }
      // console.log(arr);

      /* Hot Change in redux store */
      let updatedDomains = [...domains];
      let target_index = updatedDomains.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedDomains.splice(target_index, 1, arr);
      }
      dispatch({ type: DOMAINS_MODIFICATIONS, payload: updatedDomains });
      /* Hot Change in redux store */

      let domainSort = localStorage.getItem("domainSort");
      dispatch(dropUpdateDomain({ ...arr, domainSort })).then(() => {
        // dispatch(getDomain(obj));
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(()=>{
          unfreezeWindow();
        });
      });
    }
  };

  const PDomainsSub = (data) => {
    freezeWindow();
    let urls = `${SERVER_URI}/user/domain/update`;
    let curdomain = localStorage.getItem("domainSort");
    curdomain = curdomain.split(",");
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    obj.curdomain = curdomain;
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        domainName: data.domainName,
        purpose: data.purpose,
        tasks: data.tasks,
        owners: data.owners ? data.owners : {},
        standIn: data.standIn ? data.standIn : null,
        tags: data.tags ? data.tags : [],
        newTag: [],
        adminId: adminId,
        url: urls,
        teamId: teamId,
      };
      if (arr) {
        arr.owners = {
          type: data.owners && data.owners.type ? data.owners.type : null,
          owner: null,
        };
      }

      /* Hot Change in redux store */
      let updatedDomains = [...domains];
      let target_index = updatedDomains.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedDomains.splice(target_index, 1, arr);
      }
      dispatch({ type: DOMAINS_MODIFICATIONS, payload: updatedDomains });
      /* Hot Change in redux store */

      let domainSort = localStorage.getItem("domainSort");
      dispatch(dropUpdateDomain({ ...arr, domainSort })).then(() => {
        // dispatch(getDomain(obj));
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(()=>{
          unfreezeWindow();
        });
      });
    }
  };

  //Links
  const PLinksAdd = (data) => {
    freezeWindow();
    let urls = `${SERVER_URI}/user/link/update`;
    let curlink = localStorage.getItem("linkSort");
    curlink = curlink.split(",");
    // console.log(curlink)
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    obj.curlink = curlink;
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        linkName: data.linkName,
        purpose: data.purpose,
        tasks: data.tasks,
        owner: data.owner,
        standIn: data.standIn,
        memberIn: data.memberIn,
        tags: data.tags,
        newTag: [],
        adminId: adminId,
        url: urls,
        teamId: teamId,
      };
      if (arr && focused && peopleSorted && peopleSorted.length > 0) {
        arr.owner = focused.id;
      }
      // console.log(arr);

      /* Hot Change in redux store */
      let updatedLinks = [...links];
      let target_index = updatedLinks.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedLinks.splice(target_index, 1, arr);
      }
      dispatch({ type: LINKS_MODIFICATIONS, payload: updatedLinks });
      /* Hot Change in redux store */

      let linkSort = localStorage.getItem("linkSort");
      dispatch(dropUpdateLink({ ...arr, linkSort })).then(() => {
        // dispatch(getLink(obj));
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(()=>{
          unfreezeWindow();
        });
      });
    }
  };

  const PLinksSub = (data) => {
    freezeWindow();
    let urls = `${SERVER_URI}/user/link/update`;
    let curlink = localStorage.getItem("linkSort");
    curlink = curlink.split(",");
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    obj.curlink = curlink;
    if (data) {
      let arr = {
        _id: data._id,
        teamId: data.teamId,
        linkName: data.linkName,
        purpose: data.purpose,
        tasks: data.tasks,
        owner: data.owner,
        standIn: data.standIn,
        memberIn: data.memberIn,
        tags: data.tags,
        newTag: [],
        adminId: adminId,
        url: urls,
        teamId: teamId,
      };
      if (arr) {
        arr.owner = null;
      }

      /* Hot Change in redux store */
      let updatedLinks = [...links];
      let target_index = updatedLinks.findIndex(
        (item) => item._id === data._id
      );
      if (target_index !== -1) {
        updatedLinks.splice(target_index, 1, arr);
      }
      dispatch({ type: LINKS_MODIFICATIONS, payload: updatedLinks });
      /* Hot Change in redux store */

      let linkSort = localStorage.getItem("linkSort");
      dispatch(dropUpdateLink({ ...arr, linkSort })).then(() => {
        // dispatch(getLink(obj));
        let obj = {};
        obj.teamId = localStorage.getItem("teamId");
        obj.userId = myInfo.userId;
        dispatch(getState(obj)).then(()=>{
          unfreezeWindow();
        });
      });
    }
  };

  /* ............................. Circle focused conditions ............................. */

  let curCoreMembers = null;
  let curExtendedMembers = null;
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    circlesSorted.length > 0
  ) {
    // console.log(2694, members)
    let cId = focused.id;
    for (let i = 0; i < members.length; i++) {
      if (members[i].memberType === cId) {
        curCoreMembers = members[i].coreMembers;
        curExtendedMembers = members[i].extendedMembers;
        break;
      }
    }
  }

  // console.log(curCoreMembers);

  //People
  let CcorfoPeople = [];
  // console.log(2847, curCoreMembers);
  if (
    focused.card &&
    peopleSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curCoreMembers
  ) {
    let isLead = false;

    for (let i = 0; i < peopleSorted.length; i++) {
      // Check if added as a person
      let inc = curCoreMembers.People.includes(peopleSorted[i]._id);
      if (inc) {
        CcorfoPeople.push(peopleSorted[i]);
      }

      // Check if the lead is a person
      let focusedCircle = circlesSorted.find((c) => c._id === focused.id);
      if (circlesSorted && circlesSorted.length > 0 && focusedCircle.lead) {
        let leadOfCicleRedInHtml = focusedCircle.lead;
        let flag = true;
        if (CcorfoPeople && CcorfoPeople.length > 0) {
          for (let j = 0; j < CcorfoPeople.length; j++) {
            if (CcorfoPeople[j]._id === leadOfCicleRedInHtml) {
              flag = false;
              isLead = true;
              break;
            }
          }
        }

        if (flag && !isLead) {
          if (leadOfCicleRedInHtml === peopleSorted[i]._id) {
            CcorfoPeople.push(peopleSorted[i]);
            isLead = true;
          }
        }
      }

      if (
        circlesSorted &&
        circlesSorted.length > 0 &&
        focusedCircle.standIn &&
        focusedCircle.lead !== focusedCircle.standIn
      ) {
        let leadOfCicleGrayInHtml = focusedCircle.standIn;
        let flag = true;
        if (CcorfoPeople && CcorfoPeople.length > 0) {
          for (let j = 0; j < CcorfoPeople.length; j++) {
            if (CcorfoPeople[j]._id === leadOfCicleGrayInHtml) {
              flag = false;
              break;
            }
          }
        }
        if (flag) {
          if (leadOfCicleGrayInHtml === peopleSorted[i]._id) {
            CcorfoPeople.push(peopleSorted[i]);
          }
        }
      }

      let currentFocusedCircleMembers = members.filter(
        (item) => item.memberType === focused.id
      );
      let currentFocusedCirclePFMembers = pfmembers.filter(
        (item) => item.memberType === focused.id
      );
      // console.log(currentFocusedCirclePFMembers);
      if (
        currentFocusedCircleMembers.length > 0 &&
        currentFocusedCirclePFMembers.length > 0
      ) {
        let skillMembers = currentFocusedCircleMembers[0].coreMembers.Skills;
        if (skillMembers.length > 0) {
          skillsSorted.forEach((item) => {
            let isInPfmembersCore =
              currentFocusedCirclePFMembers[0].coreMembers.Skills.find(
                (skill) =>
                  skill.CardId === item._id &&
                  skill.peopleIds === peopleSorted[i]._id
              ) !== undefined;
            if (
              skillMembers.includes(item._id) &&
              item.owners.includes(peopleSorted[i]._id) &&
              isInPfmembersCore
            ) {
              CcorfoPeople.push(peopleSorted[i]);
            }
          });
        }

        let roleMembers = currentFocusedCircleMembers[0].coreMembers.Roles;
        if (roleMembers.length > 0) {
          rolesSorted.forEach((item) => {
            let isInPfmembersCore =
              currentFocusedCirclePFMembers[0].coreMembers.Roles.find(
                (role) =>
                  role.CardId === item._id &&
                  role.peopleIds === peopleSorted[i]._id
              ) !== undefined;
            if (
              roleMembers.includes(item._id) &&
              item.owners.includes(peopleSorted[i]._id) &&
              isInPfmembersCore
            ) {
              CcorfoPeople.push(peopleSorted[i]);
            }
          });
        }
      }
    }

    let focusedCircle = circlesSorted.find((c) => c._id === focused.id);
    if (
      !isLead &&
      circlesSorted &&
      circlesSorted.length > 0 &&
      focusedCircle.lead
    ) {
      let leadOfCicleRedInHtml = focusedCircle.lead;
      let curPLead = "";
      if (skillsSorted && skillsSorted.length > 0 && leadOfCicleRedInHtml) {
        for (let j = 0; j < skillsSorted.length; j++) {
          if (
            skillsSorted[j].ownerType === "Single owner" &&
            skillsSorted[j].owners &&
            skillsSorted[j].owners.length > 0
          ) {
            if (skillsSorted[j]._id === leadOfCicleRedInHtml) {
              curPLead = skillsSorted[j].owners[0];
              break;
            }
          }
        }
      }

      if (curPLead.length === 0) {
        if (rolesSorted && rolesSorted.length > 0 && leadOfCicleRedInHtml) {
          for (let j = 0; j < rolesSorted.length; j++) {
            if (
              rolesSorted[j].ownerRole === "Single owner" &&
              rolesSorted[j].owners &&
              rolesSorted[j].owners.length > 0
            ) {
              if (rolesSorted[j]._id === leadOfCicleRedInHtml) {
                curPLead = rolesSorted[j].owners[0];
                break;
              }
            }
          }
        }
      }

      if (CcorfoPeople && CcorfoPeople.length > 0) {
        for (let j = 0; j < CcorfoPeople.length; j++) {
          if (CcorfoPeople[j]._id === curPLead) {
            isLead = true;
            break;
          }
        }
      }

      if (
        !isLead &&
        curPLead &&
        curPLead.length > 0 &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        for (let j = 0; j < peopleSorted.length; j++) {
          if (peopleSorted[j]._id === curPLead) {
            CcorfoPeople.push(peopleSorted[j]);
            isLead = true;
            break;
          }
        }
      }
    }
  }

  let CextfoPeople = [];
  if (
    focused.card &&
    peopleSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers
  ) {
    for (let i = 0; i < peopleSorted.length; i++) {
      let inc = curExtendedMembers.People.includes(peopleSorted[i]._id);
      if (inc) {
        CextfoPeople.push(peopleSorted[i]);
      }
    }
  }

  let CedeffoPeople = [...peopleSorted];

  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers &&
    curExtendedMembers.People &&
    curExtendedMembers.People.length >= 0 &&
    curCoreMembers.People &&
    curCoreMembers.People.length >= 0
  ) {
    let cusPeople = [...curCoreMembers.People, ...curExtendedMembers.People];
    if (cusPeople.length > 0) {
      for (let i = 0; i < cusPeople.length; i++) {
        CedeffoPeople = CedeffoPeople.filter((e) => {
          return e._id !== cusPeople[i];
        });
      }
    }
  }

  //Skills
  let CcorfoSkill = [];
  if (
    focused.card &&
    skillsSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles"
  ) {
    for (let i = 0; i < skillsSorted.length; i++) {
      if (
        curCoreMembers &&
        curCoreMembers.Skills &&
        curCoreMembers.Skills.length > 0
      ) {
        let inc = curCoreMembers.Skills.includes(skillsSorted[i]._id);
        if (inc) {
          CcorfoSkill.push(skillsSorted[i]);
        }
      }

      let focusedCircle = circlesSorted.find((c) => c._id === focused.id);
      if (
        focusedCircle &&
        circlesSorted &&
        circlesSorted.length > 0 &&
        focusedCircle.lead
      ) {
        let leadOfCicleRedInHtml = focusedCircle.lead;
        let flag = true;
        if (CcorfoSkill && CcorfoSkill.length > 0) {
          for (let j = 0; j < CcorfoSkill.length; j++) {
            if (CcorfoSkill[j]._id === leadOfCicleRedInHtml) {
              flag = false;
              break;
            }
          }
        }
        if (flag) {
          if (leadOfCicleRedInHtml === skillsSorted[i]._id) {
            CcorfoSkill.push(skillsSorted[i]);
          }
        }
      }
    }
  }

  let CextfoSkill = [];
  if (
    focused.card &&
    skillsSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers &&
    curExtendedMembers.Skills &&
    curExtendedMembers.Skills.length > 0
  ) {
    for (let i = 0; i < skillsSorted.length; i++) {
      let inc = curExtendedMembers.Skills.includes(skillsSorted[i]._id);
      if (inc) {
        CextfoSkill.push(skillsSorted[i]);
      }
    }
  }

  let CedeffoSkill = [...skillsSorted];

  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    (CcorfoSkill.length > 0 || CextfoSkill.length >= 0)
  ) {
    let cusSkills = [...CcorfoSkill, ...CextfoSkill];
    if (cusSkills.length > 0) {
      CedeffoSkill = CedeffoSkill.filter((e) => {
        for (let i = 0; i < cusSkills.length; i++) {
          return e._id !== cusSkills[i]._id;
        }
      });
    }
  }

  //Roles
  let CcorfoRoles = [];
  if (
    focused.card &&
    rolesSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles"
  ) {
    for (let i = 0; i < rolesSorted.length; i++) {
      if (
        curCoreMembers &&
        curCoreMembers.Roles &&
        curCoreMembers.Roles.length > 0
      ) {
        let inc = curCoreMembers.Roles.includes(rolesSorted[i]._id);
        if (inc) {
          CcorfoRoles.push(rolesSorted[i]);
        }
      }

      let focusedCircle = circlesSorted.find((c) => c._id === focused.id);
      if (
        focusedCircle &&
        circlesSorted &&
        circlesSorted.length > 0 &&
        focusedCircle.lead
      ) {
        let leadOfCicleRedInHtml = focusedCircle.lead;
        let flag = true;
        if (CcorfoRoles && CcorfoRoles.length > 0) {
          for (let j = 0; j < CcorfoRoles.length; j++) {
            if (CcorfoRoles[j]._id === leadOfCicleRedInHtml) {
              flag = false;
              break;
            }
          }
        }
        if (flag) {
          if (leadOfCicleRedInHtml === rolesSorted[i]._id) {
            CcorfoRoles.push(rolesSorted[i]);
          }
        }
      }
    }
  }

  let CextfoRoles = [];
  if (
    focused.card &&
    rolesSorted.length &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers &&
    curExtendedMembers.Roles &&
    curExtendedMembers.Roles.length > 0
  ) {
    for (let i = 0; i < rolesSorted.length; i++) {
      let inc = curExtendedMembers.Roles.includes(rolesSorted[i]._id);
      if (inc) {
        CextfoRoles.push(rolesSorted[i]);
      }
    }
  }

  let CedeffoRoles = [...rolesSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    (CcorfoRoles.length > 0 || CextfoRoles.length > 0)
  ) {
    let cusRoles = [...CcorfoRoles, ...CextfoRoles];
    if (cusRoles.length > 0) {
      CedeffoRoles = CedeffoRoles.filter((e) => {
        for (let i = 0; i < cusRoles.length; i++) {
          return e._id !== cusRoles[i]._id;
        }
      });
    }
  }

  //Links
  let CcorfoLinks = [];
  if (
    focused.card &&
    linksSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curCoreMembers &&
    curCoreMembers.Links &&
    curCoreMembers.Links.length > 0
  ) {
    for (let i = 0; i < linksSorted.length; i++) {
      let inc = curCoreMembers.Links.includes(linksSorted[i]._id);
      if (inc) {
        CcorfoLinks.push(linksSorted[i]);
      }
    }
  }

  let CextfoLinks = [];
  if (
    focused.card &&
    linksSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers &&
    curExtendedMembers.Links &&
    curExtendedMembers.Links.length > 0
  ) {
    for (let i = 0; i < linksSorted.length; i++) {
      let inc = curExtendedMembers.Links.includes(linksSorted[i]._id);
      if (inc) {
        CextfoLinks.push(linksSorted[i]);
      }
    }
  }

  let CedeffoLinks = [...linksSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers &&
    curExtendedMembers.Roles &&
    curExtendedMembers.Roles.length >= 0 &&
    curCoreMembers.Roles &&
    curCoreMembers.Roles.length >= 0
  ) {
    let cusLinks = [...curCoreMembers.Links, ...curExtendedMembers.Links];
    if (cusLinks.length > 0) {
      for (let i = 0; i < cusLinks.length; i++) {
        CedeffoLinks = CedeffoLinks.filter((e) => {
          return e._id !== cusLinks[i];
        });
      }
    }
  }

  //Domains
  let CcorfoDomains = [];
  if (
    focused.card &&
    domainsSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curCoreMembers &&
    curCoreMembers.Domains &&
    curCoreMembers.Domains.length > 0
  ) {
    for (let i = 0; i < domainsSorted.length; i++) {
      let inc = curCoreMembers.Domains.includes(domainsSorted[i]._id);
      if (inc) {
        CcorfoDomains.push(domainsSorted[i]);
      }
    }
  }

  let CextfoDomains = [];
  if (
    focused.card &&
    domainsSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers &&
    curExtendedMembers.Domains &&
    curExtendedMembers.Domains.length > 0
  ) {
    for (let i = 0; i < domainsSorted.length; i++) {
      let inc = curExtendedMembers.Domains.includes(domainsSorted[i]._id);
      if (inc) {
        CextfoDomains.push(domainsSorted[i]);
      }
    }
  }

  let CedeffoDomains = [...domainsSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curExtendedMembers &&
    curExtendedMembers.Domains &&
    curExtendedMembers.Domains.length >= 0 &&
    curCoreMembers.Domains &&
    curCoreMembers.Domains.length >= 0
  ) {
    let cusDomains = [...curCoreMembers.Domains, ...curExtendedMembers.Domains];
    if (cusDomains.length > 0) {
      for (let i = 0; i < cusDomains.length; i++) {
        CedeffoDomains = CedeffoDomains.filter((e) => {
          return e._id !== cusDomains[i];
        });
      }
    }
  }

  //Projects
  let CcorfoProjects = [];
  if (
    focused.card &&
    projectsSorted.length > 0 &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curCoreMembers &&
    curCoreMembers.Projects &&
    curCoreMembers.Projects.length > 0
  ) {
    for (let i = 0; i < projectsSorted.length; i++) {
      let inc = curCoreMembers.Projects.includes(projectsSorted[i]._id);
      if (inc) {
        CcorfoProjects.push(projectsSorted[i]);
      }
    }
  }
  // console.log(CextfoPeople)

  let CedeffoProjects = [...projectsSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Circles" &&
    curCoreMembers &&
    curCoreMembers.Projects &&
    curCoreMembers.Projects.length >= 0
  ) {
    let cusProjects = [...curCoreMembers.Projects];
    if (cusProjects.length > 0) {
      for (let i = 0; i < cusProjects.length; i++) {
        CedeffoProjects = CedeffoProjects.filter((e) => {
          return e._id !== cusProjects[i];
        });
      }
    }
  }

  //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Projects focused cunditions.............................

  let curPCoreMembers = {};
  let curPExtendedMembers = {};
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    projectsSorted.length > 0
  ) {
    let cId = focused.id;
    for (let i = 0; i < members.length; i++) {
      if (members[i].memberType === cId) {
        curPCoreMembers = members[i].coreMembers;
        curPExtendedMembers = members[i].extendedMembers;
        break;
      }
    }
  }

  //People
  let PrcorfoPeople = [];
  if (focused.card && focused.card.length > 0 && focused.card === "Projects") {
    let isLead = false;
    for (let i = 0; i < peopleSorted.length; i++) {
      if (
        curPCoreMembers &&
        curPCoreMembers.People &&
        curPCoreMembers.People.length > 0
      ) {
        let inc = curPCoreMembers.People.includes(peopleSorted[i]._id);
        if (inc) {
          PrcorfoPeople.push(peopleSorted[i]);
        }
      }

      let focusedProject = projectsSorted.find((p) => p._id === focused.id);
      if (
        projectsSorted &&
        projectsSorted.length > 0 &&
        focusedProject &&
        focusedProject.lead
      ) {
        let leadOfCicleRedInHtml = focusedProject.lead;
        let flag = true;
        if (PrcorfoPeople && PrcorfoPeople.length > 0) {
          for (let j = 0; j < PrcorfoPeople.length; j++) {
            if (PrcorfoPeople[j]._id === leadOfCicleRedInHtml) {
              flag = false;
              isLead = true;
              break;
            }
          }
        }
        if (flag) {
          if (leadOfCicleRedInHtml === peopleSorted[i]._id) {
            PrcorfoPeople.push(peopleSorted[i]);
            isLead = true;
          }
        }
      }

      if (
        projectsSorted &&
        projectsSorted.length > 0 &&
        focusedProject &&
        focusedProject.standIn &&
        focusedProject.lead !== focusedProject.standIn
      ) {
        let leadOfCicleGrayInHtml = focusedProject.standIn;
        let flag = true;
        if (PrcorfoPeople && PrcorfoPeople.length > 0) {
          for (let j = 0; j < PrcorfoPeople.length; j++) {
            if (PrcorfoPeople[j]._id === leadOfCicleGrayInHtml) {
              flag = false;
              break;
            }
          }
        }
        if (flag) {
          if (leadOfCicleGrayInHtml === peopleSorted[i]._id) {
            PrcorfoPeople.push(peopleSorted[i]);
          }
        }
      }
    }

    let focusedProject = projectsSorted.find((p) => p._id === focused.id);
    if (
      !isLead &&
      projectsSorted &&
      projectsSorted.length > 0 &&
      focusedProject &&
      focusedProject.lead
    ) {
      let leadOfCicleRedInHtml = focusedProject.lead;
      let curPLead = "";
      if (skillsSorted && skillsSorted.length > 0 && leadOfCicleRedInHtml) {
        for (let j = 0; j < skillsSorted.length; j++) {
          if (
            skillsSorted[j].ownerType === "Single owner" &&
            skillsSorted[j].owners &&
            skillsSorted[j].owners.length > 0
          ) {
            if (skillsSorted[j]._id === leadOfCicleRedInHtml) {
              curPLead = skillsSorted[j].owners[0];
              break;
            }
          }
        }
      }
      if (!curPLead) {
        if (rolesSorted && rolesSorted.length > 0 && leadOfCicleRedInHtml) {
          for (let j = 0; j < rolesSorted.length; j++) {
            if (
              rolesSorted[j].ownerRole === "Single owner" &&
              rolesSorted[j].owners &&
              rolesSorted[j].owners.length > 0
            ) {
              if (rolesSorted[j]._id === leadOfCicleRedInHtml) {
                curPLead = rolesSorted[j].owners[0];
                break;
              }
            }
          }
        }
      }
      if (PrcorfoPeople && PrcorfoPeople.length > 0) {
        for (let j = 0; j < PrcorfoPeople.length; j++) {
          if (PrcorfoPeople[j]._id === curPLead) {
            isLead = true;
            break;
          }
        }
      }
      if (
        !isLead &&
        curPLead &&
        curPLead.length > 0 &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        for (let j = 0; j < peopleSorted.length; j++) {
          if (peopleSorted[j]._id === curPLead) {
            PrcorfoPeople.push(peopleSorted[j]);
            isLead = true;
            break;
          }
        }
      }
    }
  }

  let PrextfoPeople = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.People &&
    curPExtendedMembers.People.length > 0
  ) {
    for (let i = 0; i < peopleSorted.length; i++) {
      let inc = curPExtendedMembers.People.includes(peopleSorted[i]._id);
      if (inc) {
        PrextfoPeople.push(peopleSorted[i]);
      }
    }
  }

  let PredeffoPeople = [...peopleSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.People &&
    curPExtendedMembers.People.length >= 0 &&
    curPCoreMembers &&
    curPCoreMembers.People &&
    curPCoreMembers.People.length >= 0
  ) {
    let cusPeople = [...curPCoreMembers.People, ...curPExtendedMembers.People];
    if (cusPeople.length > 0) {
      for (let i = 0; i < cusPeople.length; i++) {
        PredeffoPeople = PredeffoPeople.filter((e) => {
          return e._id !== cusPeople[i];
        });
      }
    }
  }

  //Skills
  let PrcorfoSkill = [];
  if (focused.card && focused.card.length > 0 && focused.card === "Projects") {
    for (let i = 0; i < skillsSorted.length; i++) {
      if (
        curPCoreMembers &&
        curPCoreMembers.Skills &&
        curPCoreMembers.Skills.length > 0
      ) {
        let inc = curPCoreMembers.Skills.includes(skillsSorted[i]._id);
        if (inc) {
          PrcorfoSkill.push(skillsSorted[i]);
        }
      }
      // console.log(3325, projectsSorted[focused.index])

      let focusedProject = projectsSorted.find((p) => p._id === focused.id);
      if (
        projectsSorted &&
        projectsSorted.length > 0 &&
        focusedProject &&
        focusedProject.lead
      ) {
        let leadOfCicleRedInHtml = focusedProject.lead;
        let flag = true;
        if (PrcorfoSkill && PrcorfoSkill.length > 0) {
          for (let j = 0; j < PrcorfoSkill.length; j++) {
            if (PrcorfoSkill[j]._id === leadOfCicleRedInHtml) {
              flag = false;
              break;
            }
          }
        }
        if (flag) {
          if (leadOfCicleRedInHtml === skillsSorted[i]._id) {
            PrcorfoSkill.push(skillsSorted[i]);
          }
        }
      }
    }
  }

  let PrextfoSkill = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.Skills &&
    curPExtendedMembers.Skills.length > 0
  ) {
    for (let i = 0; i < skillsSorted.length; i++) {
      let inc = curPExtendedMembers.Skills.includes(skillsSorted[i]._id);
      if (inc) {
        PrextfoSkill.push(skillsSorted[i]);
      }
    }
  }

  let PredeffoSkill = [...skillsSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    (PrcorfoSkill.length > 0 || PrextfoSkill.length > 0)
  ) {
    let cusSkills = [...PrcorfoSkill, ...PrextfoSkill];
    if (cusSkills.length > 0) {
      PredeffoSkill = PredeffoSkill.filter((e) => {
        for (let i = 0; i < cusSkills.length; i++) {
          return e._id !== cusSkills[i]._id;
        }
      });
    }
  }

  //Roles
  let PrcorfoRoles = [];
  if (focused.card && focused.card.length > 0 && focused.card === "Projects") {
    for (let i = 0; i < rolesSorted.length; i++) {
      if (
        curPCoreMembers &&
        curPCoreMembers.Roles &&
        curPCoreMembers.Roles.length > 0
      ) {
        let inc = curPCoreMembers.Roles.includes(rolesSorted[i]._id);
        if (inc) {
          PrcorfoRoles.push(rolesSorted[i]);
        }
      }

      let focusedProject = projectsSorted.find((p) => p._id === focused.id);
      if (
        projectsSorted &&
        projectsSorted.length > 0 &&
        focusedProject &&
        focusedProject.lead
      ) {
        let leadOfCicleRedInHtml = focusedProject.lead;
        let flag = true;
        if (PrcorfoRoles && PrcorfoRoles.length > 0) {
          for (let j = 0; j < PrcorfoRoles.length; j++) {
            if (PrcorfoRoles[j]._id === leadOfCicleRedInHtml) {
              flag = false;
              break;
            }
          }
        }
        if (flag) {
          if (leadOfCicleRedInHtml === rolesSorted[i]._id) {
            PrcorfoRoles.push(rolesSorted[i]);
          }
        }
      }
    }
  }

  let PrextfoRoles = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.Roles &&
    curPExtendedMembers.Roles.length > 0
  ) {
    for (let i = 0; i < rolesSorted.length; i++) {
      let inc = curPExtendedMembers.Roles.includes(rolesSorted[i]._id);
      if (inc) {
        PrextfoRoles.push(rolesSorted[i]);
      }
    }
  }

  let PredeffoRoles = [...rolesSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    (PrcorfoRoles.length > 0 || PrextfoRoles.length > 0)
  ) {
    let cusRoles = [...PrcorfoRoles, ...PrextfoRoles];
    if (cusRoles.length > 0) {
      PredeffoRoles = PredeffoRoles.filter((e) => {
        for (let i = 0; i < cusRoles.length; i++) {
          return e._id !== cusRoles[i]._id;
        }
      });
    }
  }

  //Links
  let PrcorfoLinks = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPCoreMembers &&
    curPCoreMembers.Links &&
    curPCoreMembers.Links.length > 0
  ) {
    for (let i = 0; i < linksSorted.length; i++) {
      let inc = curPCoreMembers.Links.includes(linksSorted[i]._id);
      if (inc) {
        PrcorfoLinks.push(linksSorted[i]);
      }
    }
  }

  let PrextfoLinks = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.Links &&
    curPExtendedMembers.Links.length > 0
  ) {
    for (let i = 0; i < linksSorted.length; i++) {
      let inc = curPExtendedMembers.Links.includes(linksSorted[i]._id);
      if (inc) {
        PrextfoLinks.push(linksSorted[i]);
      }
    }
  }

  let PredeffoLinks = [...linksSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.Roles &&
    curPExtendedMembers.Roles.length >= 0 &&
    curPCoreMembers &&
    curPCoreMembers.Roles &&
    curPCoreMembers.Roles.length >= 0
  ) {
    let cusLinks = [...curPCoreMembers.Links, ...curPExtendedMembers.Links];
    if (cusLinks.length > 0) {
      for (let i = 0; i < cusLinks.length; i++) {
        PredeffoLinks = PredeffoLinks.filter((e) => {
          return e._id !== cusLinks[i];
        });
      }
    }
  }

  //Domains
  let PrcorfoDomains = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPCoreMembers &&
    curPCoreMembers.Domains &&
    curPCoreMembers.Domains.length > 0
  ) {
    for (let i = 0; i < domainsSorted.length; i++) {
      let inc = curPCoreMembers.Domains.includes(domainsSorted[i]._id);
      if (inc) {
        PrcorfoDomains.push(domainsSorted[i]);
      }
    }
  }

  let PrextfoDomains = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.Domains &&
    curPExtendedMembers.Domains.length > 0
  ) {
    for (let i = 0; i < domainsSorted.length; i++) {
      let inc = curPExtendedMembers.Domains.includes(domainsSorted[i]._id);
      if (inc) {
        PrextfoDomains.push(domainsSorted[i]);
      }
    }
  }

  let PredeffoDomains = [...domainsSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPExtendedMembers &&
    curPExtendedMembers.Domains &&
    curPExtendedMembers.Domains.length >= 0 &&
    curPCoreMembers &&
    curPCoreMembers.Domains &&
    curPCoreMembers.Domains.length >= 0
  ) {
    let cusDomains = [
      ...curPCoreMembers.Domains,
      ...curPExtendedMembers.Domains,
    ];
    if (cusDomains.length > 0) {
      for (let i = 0; i < cusDomains.length; i++) {
        PredeffoDomains = PredeffoDomains.filter((e) => {
          return e._id !== cusDomains[i];
        });
      }
    }
  }

  //Circles
  let PrcorfoCircles = [];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPCoreMembers &&
    curPCoreMembers.Circles &&
    curPCoreMembers.Circles.length > 0
  ) {
    for (let i = 0; i < circlesSorted.length; i++) {
      let inc = curPCoreMembers.Circles.includes(circlesSorted[i]._id);
      if (inc) {
        PrcorfoCircles.push(circlesSorted[i]);
      }
    }
  }

  let PredeffoCircles = [...circlesSorted];
  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    curPCoreMembers &&
    curPCoreMembers.Projects &&
    curPCoreMembers.Projects.length >= 0
  ) {
    let cusCircles = [...curPCoreMembers.Circles];
    if (cusCircles.length > 0) {
      for (let i = 0; i < cusCircles.length; i++) {
        PredeffoCircles = PredeffoCircles.filter((e) => {
          return e._id !== cusCircles[i];
        });
      }
    }
  }
  //>>>>>>>>>>>People focused cunditions drag and drop.............................

  //skills
  const skillsDrops = (e, type) => {
    if (type === "defSkill") {
      PSkillAdd(e);
    }
    if (type === "corSkill") {
      PSkillSub(e);
    }
  };

  //Roles
  const rolesDrops = (e, type) => {
    if (type === "defRoles") {
      PRolesAdd(e);
    }
    if (type === "corRoles") {
      PRolesSub(e);
    }
  };

  //Links
  const linksDrops = (e, type) => {
    if (type === "defLinks") {
      PLinksAdd(e);
    }
    if (type === "corLinks") {
      PLinksSub(e);
    }
  };

  //Domains
  const domainsDrops = (e, type) => {
    if (type === "defDomains") {
      PDomainsAdd(e);
    }
    if (type === "corDomains") {
      PDomainsSub(e);
    }
  };

  //>>>>>>>>>>> Circle focused cunditions drag and drop.............................

  //............................(allSearch)/..................................................

  var ListData = [];

  // ....................................EXTA And LEAD..........................................................

  const dWhoIsOwnerType = (e) => {
    // console.log(3831, e.owners.type, '63d3c91d40c4cf67373922e2');

    if (e.owners && e.owners.type && e.owners.type.length > 0) {
      for (let i = 0; i < skillsSorted.length; i++) {
        if (e.owners.type === skillsSorted[i]._id) {
          // console.log(e.domainName, skillsSorted[i]._id, skillsSorted[i].skillName);
          return {
            cardId: skillsSorted[i]._id,
            name: skillsSorted[i].skillName + ` (Skill)`,
            type: "Skill",
          };
        }
      }

      for (let i = 0; i < rolesSorted.length; i++) {
        if (e.owners.type === rolesSorted[i]._id) {
          // console.log(e.domainName, rolesSorted[i]._id, rolesSorted[i].roleName);
          return {
            cardId: rolesSorted[i]._id,
            name: rolesSorted[i].roleName + ` (Role)`,
            type: "Role",
          };
        }
      }
    }

    return {};
  };

  // Check if skill / role/ link/ domain owner is external type
  const IsOwnerExternal = (e, cardType) => {
    if (!e) return false;

    let allExternalPeopleIds = peopleSorted
      .filter((ps) => ps.workRole === "External")
      .map((ps) => ps._id);

    switch (cardType) {
      case "Skill":
      case "Role":
        if (e.owners) {
          let exists = e.owners.filter((value) =>
            allExternalPeopleIds.includes(value)
          );
          return exists.length > 0 ? true : false;
        } else {
          return false;
        }
        break;

      case "Domain":
        if (e.owners && e.owners.owner && e.owners.owner.length > 0) {
          let exists = allExternalPeopleIds.includes(e.owners.owner);
          return exists ? true : false;
        }
        break;

      case "Link":
        if (e.owner && e.owner.length > 0) {
          let exists = allExternalPeopleIds.includes(e.owner);
          return exists ? true : false;
        }
        break;

      default:
        return false;
    }
  };

  const OwnerExist = (owners) => {
    let exist = false;
    for (let i = 0; i < owners.length; i++) {
      if (
        peopleSorted.find((ps) => ps._id.toString() === owners[i].toString())
      ) {
        exist = true;
        break;
      }
    }
    return exist;
  };

  const dIsDirectMember = (e) => {
    // console.log(e.owners.type);
    if (e.owners.type === "63d3c91d40c4cf67373922e2") {
      return true;
    } else {
      return false;
    }
  };

  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "Projects" &&
    focused.index > -1 &&
    projectsSorted &&
    projectsSorted.length > 0
  ) {
    let focusedProject = projectsSorted.find((p) => p._id === focused.id);

    if (
      (focusedProject &&
        focusedProject.lead &&
        focusedProject.lead.length > 0) ||
      (focusedProject &&
        focusedProject.standIn &&
        focusedProject.standIn.length > 0)
    ) {
      let leadOfProjectRed = focusedProject.lead;
    }
    // console.log(PrcorfoDomains);
  }

  if (
    focused.card &&
    focused.card.length > 0 &&
    circlesSorted.length > 0 &&
    focused.card === "Circles" &&
    focused.index > -1
  ) {
    let focusedCircle = circlesSorted.find((c) => c._id === focused.id);
    if (
      (focusedCircle && focusedCircle.lead && focusedCircle.lead.length > 0) ||
      (focusedCircle &&
        focusedCircle.standIn &&
        focusedCircle.standIn.length > 0)
    ) {
      let leadOfCircleRed = focusedCircle.lead;
    }
  }

  //............................................. IsExternal or not ......................................
  const pCardHideOnchange = (data) => {
    if (data === "People") {
      setListHide({
        ...listHide,
        People: true,
      });
    }
    if (data === "Skills") {
      setListHide({
        ...listHide,
        Skills: true,
      });
    }
    if (data === "Roles") {
      setListHide({
        ...listHide,
        Roles: true,
      });
    }
    if (data === "Domains") {
      setListHide({
        ...listHide,
        Domains: true,
      });
    }
    if (data === "Links") {
      setListHide({
        ...listHide,
        Links: true,
      });
    }
    if (data === "Circles") {
      setListHide({
        ...listHide,
        Circles: true,
      });
    }
    if (data === "Projects") {
      setListHide({
        ...listHide,
        Projects: true,
      });
    }
  };

  //  ........................................................Note Action..............................................
  // .................................................................................................................

  const [noteSaveData, setNoteSaveData] = useState({
    People: "",
    Skills: "",
    Roles: "",
    Domains: "",
    Links: "",
    Circles: "",
    Projects: "",
  });

  useEffect(() => {
    let obj = {
      People: "",
      Skills: "",
      Roles: "",
      Domains: "",
      Links: "",
      Circles: "",
      Projects: "",
    };

    if (noteData && noteData.length > 0) {
      let new_obj = { ...obj }; // Create a new object to store the updates
      for (let i = 0; i < noteData.length; i++) {
        new_obj[noteData[i].noteCard] = noteData[i].noteMessage; // Update the property
      }
      setNoteSaveData(new_obj); // Set the updated object in NoteSaveData
    }
  }, [noteData]);

  const handleOnKeyUpSave = (data) => {
    // console.log(data, 'as');
    if (data === "People" && noteSaveData.People.length > 0) {
      // dispatch(etItem('teamId'),
      //   noteCard: data,
      //   noteMessage: noteSaveData.People
      // }))
    }
  };

  const handleSave = (e) => {
    setNoteSaveData({
      ...noteSaveData,
      [e.target.name]: e.target.value,
    });
    saveNotes(e);
  };

  const saveNotes = (e) => {
    dispatch(
      noteCreate({
        teamId: localStorage.getItem("teamId"),
        noteCard: e.target.name,
        noteMessage: e.target.value,
      })
    );
  };

  useEffect(() => {
    if (stripeUrl && Object.keys(stripeUrl).length > 0 && stripeUrl.url) {
      window.location.href = `${stripeUrl.url}`;
    }
  }, [stripeUrl]);

  //=========================================PEOPLE Standard algorithm===================================================
  // People
  const peopleFrom = () => {
    if (lFromState.Peoples === false) {
      if (
        curUserData &&
        Object.keys(curUserData).length > 0 &&
        curUserData.plan === "FREE"
      ) {
        if (peopleSorted.length >= CARD_LIMIT) {
          Swal.fire({
            // icon: "info",
            title: "Maximize Your Team's Potential!",
            showCancelButton: true,
            text: "You've hit the capacity limit for the free version of our software. Ready to grow further? Upgrade to a GO account for the freedom to add unlimited teams and team members!",
            confirmButtonText: "Upgrade now",
            cancelButtonText: `Close`,
            cancelButtonColor: "#EE7364",
          }).then((result) => {
            if (result.isConfirmed) {
              freezeWindow();
              dispatch(
                generateStripeUpgradeUrl({
                  userId: curUserData._id,
                  plan: "GO",
                  interval: "month",
                  email: curUserData.email,
                })
              );
              // window.location.href = `${CLIENT_URL}/pricing`
            }
          });
        } else if (peopleSorted.length < CARD_LIMIT) {
          setLFromsState({
            Peoples: !lFromState.Peoples,
            Skills: false,
            Roles: false,
            Domains: false,
            Links: false,
            Circles: false,
            Projects: false,
          });
        }
      } else {
        setLFromsState({
          ...lFromState,
          ["Peoples"]: !lFromState.Peoples,
        });
      }
    } else {
      setLFromsState({
        ...lFromState,
        ["Peoples"]: !lFromState.Peoples,
      });
    }
    // console.log(peoples)
  };

  const pref = useRef(null);
  useEffect(() => {
    if (lFromState.Peoples === true) {
      pref.current.focus();
    }
  }, [lFromState, fName]);

  const pfNameHandel = (e) => {
    if (e.key === "Enter") {
      peopleSubmit(e);
      // peopleFrom()
    }
  };

  const addNewPeoples = () => {
    if (
      curUserData &&
      Object.keys(curUserData).length > 0 &&
      !curUserData.session_id
    ) {
      if (peopleSorted.length >= CARD_LIMIT) {
        Swal.fire({
          // icon: "info",
          title: "Maximize Your Team's Potential!",
          showCancelButton: true,
          text: "You've hit the capacity limit for the free version of our software. Ready to grow further? Upgrade to a GO account for the freedom to add unlimited teams and team members!",
          confirmButtonText: "Upgrade now",
          cancelButtonText: `Close`,
          cancelButtonColor: "#EE7364",
        }).then((result) => {
          if (result.isConfirmed) {
            freezeWindow();
            dispatch(
              generateStripeUpgradeUrl({
                userId: curUserData._id,
                plan: "GO",
                interval: "month",
                email: curUserData.email,
              })
            );
            // window.location.href = "https://app.teamdecoder.com/pricing"
          }
        });
      } else if (peopleSorted.length < CARD_LIMIT) {
        setPepoles();
      }
    } else {
      setPepoles();
    }
  };

  const [peopleSearch, setPeopleSearch] = useState("");
  const spref = useRef(null);
  useEffect(() => {
    if (peopleSearch.length > 0) {
      spref.current.focus();
    }
  }, [peopleSearch]);

  var filteredPData = [];

  if (
    (peopleSearch.length > 0 || allSearch.length > 0) &&
    peopleSorted.length > 0
  ) {
    filteredPData = peopleSorted.filter((value) => {
      const searchStr = peopleSearch.toLowerCase() || allSearch.toLowerCase();
      // const isTaskPresent = value.tasks.join(' ').includes(searchStr);
      const nameMatches = `${value.fname} ${value.lname}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
      // return nameMatches || isTaskPresent;
    });
    if (allSearch && allSearch.length > 0 && filteredPData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("People");
    }
  }

  if (
    filteredPData.length === 0 &&
    peopleSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPData = [...peopleSorted];
  }

  let nonFiltePDta = [];
  if (peopleSearch.length > 0 && filteredPData.length !== peopleSorted.length) {
    nonFiltePDta = [...peopleSorted];
    for (let i = 0; i < filteredPData.length; i++) {
      nonFiltePDta = nonFiltePDta.filter((value, key) => {
        return value._id !== filteredPData[i]._id;
      });
    }
    if (filteredPData.length === 0) {
      nonFiltePDta = [...peopleSorted];
    }
  }

  let pnoteRef = useRef(null);
  var pnotePeopleData = noteSaveData.People;
  useEffect(() => {
    // console.log(pnoteRef , pnoteRef.current)
    if (
      pnoteRef &&
      pnoteRef.current &&
      noteSaveData.People &&
      noteSaveData.People.length > 0 &&
      !focusMode
    ) {
      pnoteRef.current.focus();
    }
  }, [note.People, noteSaveData.People]);

  // Skills
  const skillsFrom = () => {
    if (lFromState.Skills === false) {
      setLFromsState({
        Peoples: false,
        Skills: !lFromState.Skills,
        Roles: false,
        Domains: false,
        Links: false,
        Circles: false,
        Projects: false,
      });
    } else {
      setLFromsState({
        ...lFromState,
        ["Skills"]: !lFromState.Skills,
      });
    }
  };

  const sref = useRef(null);
  useEffect(() => {
    if (lFromState.Skills) {
      sref.current.focus();
    }
  }, [lFromState, skill]);

  const [skillSearch, setSkillSearch] = useState("");

  const sSref = useRef(null);
  useEffect(() => {
    if (skillSearch.length > 0) {
      sSref.current.focus();
    }
  }, [skillSearch]);

  var filteredSData = [];

  if (
    (skillSearch.length > 0 || allSearch.length > 0) &&
    skillsSorted.length > 0
  ) {
    filteredSData = skillsSorted.filter((value) => {
      const searchStr = skillSearch.toLowerCase() || allSearch.toLowerCase();
      const isTaskPresent = value.tasks.join(" ").includes(searchStr);
      const nameMatches = `${value.skillName}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches || isTaskPresent;
    });
    if (allSearch && allSearch.length > 0 && filteredSData.length > 0) {
      // list = list.filter((e)=>{e !== 'Skills'})
      ListData.push("Skills");
    }
  }

  if (
    filteredSData.length === 0 &&
    skillSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredSData = [...skillsSorted];
  }

  let nonFilteSData = [];
  if (skillSearch.length > 0 && filteredSData.length !== skillsSorted.length) {
    nonFilteSData = [...skillsSorted];
    for (let i = 0; i < filteredSData.length; i++) {
      nonFilteSData = nonFilteSData.filter((value, key) => {
        return value._id !== filteredSData[i]._id;
      });
    }
    if (filteredSData.length === 0) {
      nonFilteSData = [...skillsSorted];
    }
  }

  // Roles
  const rolesFrom = () => {
    if (lFromState.Roles === false) {
      setLFromsState({
        Peoples: false,
        Skills: false,
        Roles: !lFromState.Roles,
        Domains: false,
        Links: false,
        Circles: false,
        Projects: false,
      });
    } else {
      setLFromsState({
        ...lFromState,
        ["Roles"]: !lFromState.Roles,
      });
    }
  };

  const rref = useRef(null);
  useEffect(() => {
    if (lFromState.Roles) {
      rref.current.focus();
    }
  }, [lFromState, role]);

  const [roleSearch, setRoleSearch] = useState("");
  const sRref = useRef(null);
  useEffect(() => {
    if (roleSearch) {
      sRref.current.focus();
    }
  }, [roleSearch]);

  var filteredRData = [];
  if (
    (roleSearch.length > 0 || allSearch.length > 0) &&
    rolesSorted.length > 0
  ) {
    filteredRData = rolesSorted.filter((value) => {
      const searchStr = roleSearch.toLowerCase() || allSearch.toLowerCase();

      const nameMatches = `${value.roleName}`.toLowerCase().includes(searchStr);
      const isTaskPresent = value.tasks.join(" ").includes(searchStr);

      return nameMatches || isTaskPresent;
    });
    if (allSearch && allSearch.length > 0 && filteredRData.length > 0) {
      ListData.push("Roles");
    }
  }

  if (
    filteredRData.length === 0 &&
    roleSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredRData = [...rolesSorted];
  }

  let nonFilteRData = [];
  if (roleSearch.length > 0 && filteredRData.length !== rolesSorted.length) {
    nonFilteRData = [...rolesSorted];
    for (let i = 0; i < filteredRData.length; i++) {
      nonFilteRData = nonFilteRData.filter((value, key) => {
        return value._id !== filteredRData[i]._id;
      });
    }
    if (filteredRData.length === 0) {
      nonFilteRData = [...rolesSorted];
    }
  }

  // Domains
  const domainsFrom = () => {
    if (lFromState.Domains === false) {
      setLFromsState({
        Peoples: false,
        Skills: false,
        Roles: false,
        Domains: !lFromState.Domains,
        Links: false,
        Circles: false,
        Projects: false,
      });
    } else {
      setLFromsState({
        ...lFromState,
        ["Domains"]: !lFromState.Domains,
      });
    }
  };

  const dref = useRef(null);
  useEffect(() => {
    if (lFromState.Domains) {
      dref.current.focus();
    }
  }, [lFromState, domain]);

  const [domainSearch, setDomainSearch] = useState("");

  const sDref = useRef(null);
  useEffect(() => {
    if (domainSearch.length > 0) {
      sDref.current.focus();
    }
  }, [domainSearch]);

  var filteredDData = [];

  if (
    (domainSearch.length > 0 || allSearch.length > 0) &&
    domainsSorted.length > 0
  ) {
    filteredDData = domainsSorted.filter((value) => {
      const searchStr = domainSearch.toLowerCase() || allSearch.toLowerCase();
      const isTaskPresent = value.tasks.join(" ").includes(searchStr);
      const nameMatches = `${value.domainName}`
        .toLowerCase()
        .includes(searchStr);

      // console.log(value.tasks , isTaskPresent , nameMatches , nameMatches || isTaskPresent)
      return nameMatches || isTaskPresent;
    });

    if (allSearch && allSearch.length > 0 && filteredDData.length > 0) {
      ListData.push("Domains");
    }
  }

  if (
    filteredDData.length === 0 &&
    domainSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredDData = [...domainsSorted];
  }

  let nonFilteDData = [];
  if (
    domainSearch.length > 0 &&
    filteredDData.length !== domainsSorted.length
  ) {
    nonFilteDData = [...domainsSorted];
    for (let i = 0; i < filteredDData.length; i++) {
      nonFilteDData = nonFilteDData.filter((value, key) => {
        return value._id !== filteredDData[i]._id;
      });
    }
    if (filteredDData.length === 0) {
      nonFilteDData = [...domainsSorted];
    }
  }

  const openSkillAndRoleByDPill = (data) => {
    if (data && data.type === "Skill") {
      skillsSorted.filter((value, key) => {
        if (value._id === data.Id) {
          indexSet(value);
          openSkill();
        }
      });
    } else if (data && data.type === "Role") {
      rolesSorted.filter((value, key) => {
        if (value._id === data.Id) {
          indexSet(value);
          openRole();
        }
      });
    }
  };

  // Links
  const linksFrom = () => {
    if (lFromState.Links === false) {
      setLFromsState({
        Peoples: false,
        Skills: false,
        Roles: false,
        Domains: false,
        Links: !lFromState.Links,
        Circles: false,
        Projects: false,
      });
    } else {
      setLFromsState({
        ...lFromState,
        ["Links"]: !lFromState.Links,
      });
    }
  };

  const lref = useRef(null);
  useEffect(() => {
    if (lFromState.Links) {
      lref.current.focus();
    }
  }, [lFromState, link]);

  const [linkSearch, setLinkSearch] = useState("");

  const sLref = useRef(null);
  useEffect(() => {
    if (linkSearch.length > 0) {
      sLref.current.focus();
    }
  }, [linkSearch]);

  var filteredDLata = [];

  if (
    (linkSearch.length > 0 || allSearch.length > 0) &&
    linksSorted.length > 0
  ) {
    filteredDLata = linksSorted.filter((value) => {
      const searchStr = linkSearch.toLowerCase() || allSearch.toLowerCase();
      const isTaskPresent = value.tasks.join(" ").includes(searchStr);
      const nameMatches = `${value.linkName}`.toLowerCase().includes(searchStr);
      return nameMatches || isTaskPresent;
    });
    if (allSearch && allSearch.length > 0 && filteredDLata.length > 0) {
      // list = list.filter((e)=>{e !== 'Links'})
      ListData.push("Links");
    }
  }

  if (
    filteredDLata.length === 0 &&
    linkSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredDLata = [...linksSorted];
  }

  let nonFilteLData = [];
  if (linkSearch.length > 0 && filteredDLata.length !== linksSorted.length) {
    nonFilteLData = [...linksSorted];
    for (let i = 0; i < filteredDLata.length; i++) {
      nonFilteLData = nonFilteLData.filter((value, key) => {
        return value._id !== filteredDLata[i]._id;
      });
    }
    if (filteredDLata.length === 0) {
      nonFilteLData = [...linksSorted];
    }
  }

  // Circles
  const circlesFrom = () => {
    if (lFromState.Circles === false) {
      setLFromsState({
        Peoples: false,
        Skills: false,
        Roles: false,
        Domains: false,
        Links: false,
        Circles: !lFromState.Circles,
        Projects: false,
      });
    } else {
      setLFromsState({
        ...lFromState,
        ["Circles"]: !lFromState.Circles,
      });
    }
  };

  const cref = useRef(null);
  useEffect(() => {
    if (lFromState.Circles) {
      cref.current.focus();
    }
  }, [lFromState, circle]);

  const [circleSearch, setCircleSearch] = useState("");

  const sCref = useRef(null);
  useEffect(() => {
    if (circleSearch.length > 0) {
      sCref.current.focus();
    }
  }, [circleSearch]);

  var filteredCData = [];

  if (
    (circleSearch.length > 0 || allSearch.length > 0) &&
    circlesSorted.length > 0
  ) {
    filteredCData = circlesSorted.filter((value) => {
      const searchStr = circleSearch.toLowerCase() || allSearch.toLowerCase();
      const isTaskPresent = value.tasks.join(" ").includes(searchStr);
      const nameMatches = `${value.circleName}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches || isTaskPresent;
    });
    if (allSearch && allSearch.length > 0 && filteredCData.length > 0) {
      // list = list.filter((e)=>{e !== 'Circles'})
      ListData.push("Circles");
    }
  }

  if (
    filteredCData.length === 0 &&
    circleSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCData = [...circlesSorted];
  }

  let nonFilteCData = [];
  if (
    circleSearch.length > 0 &&
    filteredCData.length !== circlesSorted.length
  ) {
    nonFilteCData = [...circlesSorted];
    for (let i = 0; i < filteredCData.length; i++) {
      nonFilteCData = nonFilteCData.filter((value, key) => {
        return value._id !== filteredCData[i]._id;
      });
    }
    if (filteredCData.length === 0) {
      nonFilteCData = [...circlesSorted];
    }
  }

  // Projects
  const projectsFrom = () => {
    if (lFromState.Projects === false) {
      setLFromsState({
        Peoples: false,
        Skills: false,
        Roles: false,
        Domains: false,
        Links: false,
        Circles: false,
        Projects: !lFromState.Projects,
      });
    } else {
      setLFromsState({
        ...lFromState,
        ["Projects"]: !lFromState.Projects,
      });
    }
  };

  const prref = useRef(null);
  useEffect(() => {
    if (lFromState.Projects) {
      prref.current.focus();
    }
  }, [lFromState, project]);

  const [projectSearch, setProjectSearch] = useState("");

  const sPrref = useRef(null);
  useEffect(() => {
    if (projectSearch.length > 0) {
      sPrref.current.focus();
    }
  }, [projectSearch]);

  var filteredPrData = [];

  if (
    (projectSearch.length > 0 || allSearch.length > 0) &&
    projectsSorted.length > 0
  ) {
    filteredPrData = projectsSorted.filter((value) => {
      const searchStr = projectSearch.toLowerCase() || allSearch.toLowerCase();
      const isTaskPresent = value.tasks.join(" ").includes(searchStr);
      const nameMatches = `${value.projectName}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches || isTaskPresent;
    });
    if (allSearch && allSearch.length > 0 && filteredPrData.length > 0) {
      // list = list.filter((e)=>{e !== 'Projects'})
      ListData.push("Projects");
    }
  }

  if (
    filteredPrData.length === 0 &&
    projectSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPrData = [...projectsSorted];
  }

  let nonFiltePrData = [];
  if (
    projectSearch.length > 0 &&
    filteredPrData.length !== projectsSorted.length
  ) {
    nonFiltePrData = [...projectsSorted];
    for (let i = 0; i < filteredPrData.length; i++) {
      nonFiltePrData = nonFiltePrData.filter((value, key) => {
        return value._id !== filteredPrData[i]._id;
      });
    }
    if (filteredPrData.length === 0) {
      nonFiltePrData = [...projectsSorted];
    }
  }
  // const [specialModeDataObj,setSpecialModeDataObj] = useState([]);
  const toggleEXTnNOCards = (
    type,
    pillType,
    reset = false,
    clickedOn = "list",
    specialMode = null,
    specialModeId = null
  ) => {
    /* console.log(4747, {
      type,
      pillType,
      reset,
      clickedOn,
      specialMode,
      specialModeId
    }) */

    /* reset Special Mode */
    const resetSpecialmode_DataSet = (idToRemove) => {
      if (idToRemove) {
        let isPresent = specialModeDataObj.some(
          (item) => item.id === idToRemove
        );
        if (isPresent) {
          if (specialModeDataObj.length === 1) {
            setIsEXT(false);
            setIsNO(false);
          }
          // Remove the item from the setSpecialModeDataObj array
          setSpecialModeDataObj((prevState) =>
            prevState.filter((item) => item.id !== idToRemove)
          );
        }
      }
    };

    /* reset */
    const resetDataSet = (array, type) => {
      if (array) {
        if (clickedOnList.listName.includes(type)) {
          if (clickedOnList.listName.length === 1) {
            setIsEXT(false);
            setIsNO(false);
          }
          // Remove the item from the listName array
          setClickedOnList((prev) => ({
            ...prev,
            isClicked: clickedOnList.listName.length === 1 ? false : true, //when last then remove and make it false
            listName: prev.listName.filter((item) => item !== type),
          }));
        }

        let dataSet = [];

        if (type === "people") {
          dataSet = array.map((sd) => sd._id);
        }
        if (["skill", "role"].includes(type)) {
          dataSet = array.map((item) => item._id);
        }
        if (type === "domain") {
          dataSet = array.map((item) => item._id);
        }
        if (type === "link") {
          dataSet = array.map((item) => item._id);
        }

        setExtNoFilteredObj((prev) => ({
          ...prev,
          [type]: dataSet,
        }));
      }
    };

    /* data set creation */
    const createDataSet = (array, pill, type) => {
      if (pill === "External") {
        const ExtPeople = peopleSorted
          .filter((sd) => sd.workRole === "External")
          .map((sd) => sd._id);
        let dataSet;
        if (type === "people") {
          dataSet = array
            .filter((sd) => sd.workRole === pill)
            .map((sd) => sd._id);
        }
        if (["skill", "role"].includes(type)) {
          dataSet = array
            .filter((item) =>
              item.owners.some((owner) => ExtPeople.includes(owner))
            )
            .map((item) => item._id);
        }
        if (type === "domain") {
          dataSet = array
            .filter((item) => ExtPeople.includes(item.owners.owner))
            .map((item) => item._id);
        }
        if (type === "link") {
          dataSet = array
            .filter((item) => ExtPeople.includes(item.owner))
            .map((item) => item._id);
        }
        if (type === "circle") {
          dataSet = [];
        }
        if (type === "project") {
          dataSet = [];
        }
        // console.log({ array, pill, type, dataSet });
        /* state update */
        if (dataSet) {
          setExtNoFilteredObj((prev) => ({
            ...prev,
            [type]: dataSet,
          }));
        }
      }
      if (pill === "No") {
        let dataSet = [];
        if (type === "people") {
          dataSet = [];
        }
        if (["skill", "role"].includes(type)) {
          dataSet = array
            .filter((item) => item.owners.length === 0)
            .map((item) => item._id);
        }
        if (type === "domain") {
          dataSet = array
            .filter((item) => {
              // Check if item.owners exists and then check if item.owners.owner is null or has a length of 0
              // console.log(item.owners.owner);
              if (item.owners.owner) {
                return false;
              }
              return true;
            })
            .map((item) => item._id);
        }
        if (type === "link") {
          dataSet = array
            .filter((item) => {
              // Check if item.owners exists and then check if item.owners.owner is null or has a length of 0
              // console.log(item.owner);
              if (item.owner) {
                return false;
              }
              return true;
            })
            .map((item) => item._id);
        }
        if (type === "circle") {
          dataSet = [];
        }
        if (type === "project") {
          dataSet = [];
        }
        // console.log("=======>>>>>", { pill, type, dataSet })
        if (dataSet) {
          setExtNoFilteredObj((prev) => ({
            ...prev,
            [type]: dataSet,
          }));
        }
      }
    };
    /* data set creation */
    const createDataSet_specialMode = (array, pill, type, specialModeId) => {
      if (pill === "External") {
        const ExtPeople = peopleSorted
          .filter((sd) => sd.workRole === "External")
          .map((sd) => sd._id);
        let dataSet;
        if (type === "people") {
          dataSet = array
            .filter((sd) => sd.workRole === pill)
            .map((sd) => sd._id);
        }
        if (["skill", "role"].includes(type)) {
          dataSet = array
            .filter((item) =>
              item.owners.some((owner) => ExtPeople.includes(owner))
            )
            .map((item) => item._id);
        }
        if (type === "domain") {
          dataSet = array
            .filter((item) => ExtPeople.includes(item.owners.owner))
            .map((item) => item._id);
        }
        if (type === "link") {
          dataSet = array
            .filter((item) => ExtPeople.includes(item.owner))
            .map((item) => item._id);
        }
        if (type === "circle") {
          dataSet = [];
        }
        if (type === "project") {
          dataSet = [];
        }
        // console.log({ array, pill, type, dataSet });
        /* state update */
        setSpecialModeDataObj((prev) => {
          // Find if there's already an entry with the same specialModeId
          const existingEntry = prev.find((item) => item.id === specialModeId);
          if (existingEntry) {
            // If it exists, update the data for the corresponding type
            return prev.map((item) =>
              item.id === specialModeId
                ? { ...item, data: { ...item.data, [type]: dataSet } }
                : item
            );
          } else {
            // If it doesn't exist, add a new entry
            return [
              ...prev,
              {
                id: specialModeId,
                data: { [type]: dataSet },
              },
            ];
          }
        });
      }
      if (pill === "No") {
        let dataSet = [];
        if (type === "people") {
          dataSet = [];
        }
        if (["skill", "role"].includes(type)) {
          dataSet = array
            .filter((item) => item.owners.length === 0)
            .map((item) => item._id);
        }
        if (type === "domain") {
          dataSet = array
            .filter((item) => {
              // Check if item.owners exists and then check if item.owners.owner is null or has a length of 0
              // console.log(item.owners.owner);
              if (item.owners.owner) {
                return false;
              }
              return true;
            })
            .map((item) => item._id);
        }
        if (type === "link") {
          dataSet = array
            .filter((item) => {
              // Check if item.owners exists and then check if item.owners.owner is null or has a length of 0
              // console.log(item.owner);
              if (item.owner) {
                return false;
              }
              return true;
            })
            .map((item) => item._id);
        }
        if (type === "circle") {
          dataSet = [];
        }
        if (type === "project") {
          dataSet = [];
        }
        /* state update */
        setSpecialModeDataObj((prev) => {
          // Find if there's already an entry with the same specialModeId
          const existingEntry = prev.find((item) => item.id === specialModeId);
          if (existingEntry) {
            // If it exists, update the data for the corresponding type
            return prev.map((item) =>
              item.id === specialModeId
                ? { ...item, data: { ...item.data, [type]: dataSet } }
                : item
            );
          } else {
            // If it doesn't exist, add a new entry
            return [
              ...prev,
              {
                id: specialModeId,
                data: { [type]: dataSet },
              },
            ];
          }
        });
      }
    };
    /*--------------- if clicked on list --------------- */
    if (clickedOn === "list") {
      /* {special mode} */
      if (specialMode) {
        if (reset) {
          resetSpecialmode_DataSet(specialModeId);
          // resetSpecialmode_DataSet(skillsSorted, "skill");
          // resetSpecialmode_DataSet(rolesSorted, "role");
          // resetSpecialmode_DataSet(domainsSorted, "domain");
          // resetSpecialmode_DataSet(linksSorted, "link");
        } else {
          if (specialModeId) {
            // setSpecialModeListClick((prev) => ({
            //       ...prev,
            //       isClicked: true,
            //       cpIds: [...prev.cpIds, specialModeId],
            // }));
            /* pillType EXT*/
            if (pillType === "EXT") {
              // setClickedOnList({isClicked : true, listName: type});
              setIsEXT(true);
              setIsNO(false);
              createDataSet_specialMode(
                peopleSorted,
                "External",
                "people",
                specialModeId
              );
              createDataSet_specialMode(
                skillsSorted,
                "External",
                "skill",
                specialModeId
              );
              createDataSet_specialMode(
                rolesSorted,
                "External",
                "role",
                specialModeId
              );
              createDataSet_specialMode(
                domainsSorted,
                "External",
                "domain",
                specialModeId
              );
              createDataSet_specialMode(
                linksSorted,
                "External",
                "link",
                specialModeId
              );
            }
            /* pillType NO*/
            if (pillType === "NO") {
              // setClickedOnList({isClicked : true, listName: type});
              setIsNO(true);
              setIsEXT(false);
              createDataSet_specialMode(
                peopleSorted,
                "No",
                "people",
                specialModeId
              );
              createDataSet_specialMode(
                skillsSorted,
                "No",
                "skill",
                specialModeId
              );
              createDataSet_specialMode(
                rolesSorted,
                "No",
                "role",
                specialModeId
              );
              createDataSet_specialMode(
                domainsSorted,
                "No",
                "domain",
                specialModeId
              );
              createDataSet_specialMode(
                linksSorted,
                "No",
                "link",
                specialModeId
              );
            }
          }
        }
      } else {
        /* {normal list click not special mode} */
        let setName = null;
        if (type === "people") {
          setName = "people";
        }
        if (type === "skills") {
          setName = "skill";
        }
        if (type === "roles") {
          setName = "role";
        }
        if (type === "domains") {
          setName = "domain";
        }
        if (type === "links") {
          setName = "link";
        }
        if (setName) {
          setClickedOnList((prev) => ({
            ...prev,
            isClicked: true,
            listName: [...prev.listName, setName],
          }));
        }
        // console.log({ reset })
        /* reset section */
        if (reset) {
          if (type === "people") {
            resetDataSet(peopleSorted, "people");
          }
          if (type === "skills") {
            resetDataSet(skillsSorted, "skill");
          }
          if (type === "roles") {
            resetDataSet(rolesSorted, "role");
          }
          if (type === "domains") {
            resetDataSet(domainsSorted, "domain");
          }
          if (type === "links") {
            resetDataSet(linksSorted, "link");
          }
        }
        /* pillType EXT */
        if (pillType === "EXT") {
          // setClickedOnList({isClicked : false, listName: type});
          setIsEXT(true);
          setIsNO(false);
          if (type === "people") {
            createDataSet(peopleSorted, "External", "people");
          }
          if (type === "skills") {
            createDataSet(skillsSorted, "External", "skill");
          }
          if (type === "roles") {
            createDataSet(rolesSorted, "External", "role");
          }
          if (type === "domains") {
            createDataSet(domainsSorted, "External", "domain");
          }
          if (type === "links") {
            createDataSet(linksSorted, "External", "link");
          }
        }
        /* pillType NO*/
        if (pillType === "NO") {
          // setClickedOnList({isClicked : false, listName: type});
          setIsNO(true);
          setIsEXT(false);
          if (type === "people") {
            createDataSet(peopleSorted, "No", "people");
          }
          if (type === "skills") {
            createDataSet(skillsSorted, "No", "skill");
          }
          if (type === "roles") {
            createDataSet(rolesSorted, "No", "role");
          }
          if (type === "domains") {
            createDataSet(domainsSorted, "No", "domain");
          }
          if (type === "links") {
            createDataSet(linksSorted, "No", "link");
          }
        }
      }
    }
    /*--------------- if clicked on pill --------------- */
    if (clickedOn === "pill") {
      if (specialModeDataObj.length > 0) {
        setSpecialModeDataObj([]);
        setIsEXT(false);
        setIsNO(false);
        setIsTag("");
        setExtNoFilteredObj({});
        setTagFilteredObj({});
        setTagFilteredMegedData({});
      }
      if (clickedOnList.isClicked) {
        setClickedOnList({ isClicked: false, listName: [] });
        setIsEXT(false);
        setIsNO(false);
        setIsTag("");
        setExtNoFilteredObj({});
        setTagFilteredObj({});
        setTagFilteredMegedData({});
      }
      if (pillType === "EXT") {
        setIsEXT(true);
        setIsNO(false);
        createDataSet(peopleSorted, "External", "people");
        createDataSet(skillsSorted, "External", "skill");
        createDataSet(rolesSorted, "External", "role");
        createDataSet(domainsSorted, "External", "domain");
        createDataSet(linksSorted, "External", "link");
        createDataSet(circlesSorted, "External", "circle");
        createDataSet(projectsSorted, "External", "project");
      }
      if (pillType === "NO") {
        setIsNO(true);
        setIsEXT(false);
        createDataSet(peopleSorted, "No", "people");
        createDataSet(skillsSorted, "No", "skill");
        createDataSet(rolesSorted, "No", "role");
        createDataSet(domainsSorted, "No", "domain");
        createDataSet(linksSorted, "No", "link");
        createDataSet(circlesSorted, "No", "circle");
        createDataSet(projectsSorted, "No", "project");
      }
      /* make all ext or all no visible */
      /* people */
    }
  };

  //...................................................Standard Mode............................................
  const toggleTagCards = (tag) => {
    if (specialModeDataObj.length > 0) {
      setSpecialModeDataObj([]);
      setIsEXT(false);
      setIsNO(false);
      setIsTag("");
      setExtNoFilteredObj({});
      setTagFilteredObj({});
      setTagFilteredMegedData({});
    }
    if (clickedOnList.isClicked) {
      setClickedOnList({ isClicked: false, listName: [] });
      setIsEXT(false);
      setIsNO(false);
      setIsTag("");
      setExtNoFilteredObj({});
      setTagFilteredObj({});
      setTagFilteredMegedData({});
    }
    setIsTag(tag);

    const createDataSet = (array, tag, type) => {
      let dataSet = array
        .filter((sd) => sd.tags.includes(tag))
        .map((sd) => sd._id);

      setTagFilteredObj((prev) => ({
        ...prev,
        [type]: dataSet,
      }));
    };

    createDataSet(peopleSorted, tag, "people");
    createDataSet(skillsSorted, tag, "skill");
    createDataSet(domainsSorted, tag, "domain");
    createDataSet(rolesSorted, tag, "role");
    createDataSet(linksSorted, tag, "link");
    createDataSet(projectsSorted, tag, "project");
    createDataSet(circlesSorted, tag, "circle");
  };

  const getSortIcon = (cardType) => {
    if (!statesLM || !statesLM[cardType]) return "";

    return statesLM[cardType] === "AtoZ" ? (
      <img alt="" src="images/sort-az.png" />
    ) : statesLM[cardType] === "ZtoA" ? (
      <img alt="" src="images/sort-za.png" />
    ) : statesLM[cardType] === "NewToOld" ? (
      <img alt="" src="images/sort-dd.png" />
    ) : statesLM[cardType] === "OldToNew" ? (
      <img alt="" src="images/sort-da.png" />
    ) : statesLM[cardType] === "Parsonal" ? (
      <img alt="" src="images/drag.png" />
    ) : null;
  };

  const handleDragStart = () => {
    // freezeWindow(false);
  };

  const handleDragEnd = (result) => {
    // console.log(result);

    if (!result.draggableId || !result.source || !result.destination) {
      unfreezeWindow(false);
      return false;
    }

    if (
      result.source.droppableId &&
      result.destination.droppableId &&
      result.source.droppableId !== result.destination.droppableId
    ) {
      unfreezeWindow(false);
      return false;
    }

    // console.log(`Dropped in ${result.destination.droppableId}: `);

    /* result.draggableId // - ID of the dragged item
    result.source.index // - source index of the dragged item
    result.destination.index // - destination index of the dragged item */
    // console.log(4326, states, states.cListMode)

    if (states && states.cListMode) {
      // Perform your state update logic
      if (
        result.source.droppableId === "people" &&
        peopleSorted.length > 0 &&
        states.cListMode.People === "Parsonal"
      ) {
        const copyListItems = [...peopleSorted];
        const draggedItem = copyListItems.find(
          (item) => item._id === result.draggableId
        );
        copyListItems.splice(result.source.index, 1);
        copyListItems.splice(result.destination.index, 0, draggedItem);
        setpeopleSorted(copyListItems);
        // console.log(copyListItems)
        let curpeople = copyListItems.map((item) => item._id);
        if (curpeople && curpeople.length > 0) {
          peopleStateUpdate(curpeople);
        }
      }

      if (
        result.source.droppableId === "skills" &&
        skillsSorted.length > 0 &&
        states.cListMode.Skills === "Parsonal"
      ) {
        const copyListItems = [...skillsSorted];
        const draggedItem = copyListItems.find(
          (item) => item._id === result.draggableId
        );
        copyListItems.splice(result.source.index, 1);
        copyListItems.splice(result.destination.index, 0, draggedItem);
        setskillsSorted(copyListItems);

        let curskill = copyListItems.map((item) => item._id);
        if (curskill && curskill.length > 0) {
          skillStateUpdate(curskill);
        }
      }

      if (
        result.source.droppableId === "roles" &&
        rolesSorted.length > 0 &&
        states.cListMode.Roles === "Parsonal"
      ) {
        const copyListItems = [...rolesSorted];
        const draggedItem = copyListItems.find(
          (item) => item._id === result.draggableId
        );
        copyListItems.splice(result.source.index, 1);
        copyListItems.splice(result.destination.index, 0, draggedItem);
        setrolesSorted(copyListItems);

        let currole = copyListItems.map((item) => item._id);
        if (currole && currole.length > 0) {
          roleStateUpdate(currole);
        }
      }

      if (
        result.source.droppableId === "domains" &&
        domainsSorted.length > 0 &&
        states.cListMode.Domains === "Parsonal"
      ) {
        const copyListItems = [...domainsSorted];
        const draggedItem = copyListItems.find(
          (item) => item._id === result.draggableId
        );
        copyListItems.splice(result.source.index, 1);
        copyListItems.splice(result.destination.index, 0, draggedItem);
        setdomainsSorted(copyListItems);

        let curdomain = copyListItems.map((item) => item._id);
        if (curdomain && curdomain.length > 0) {
          domainStateUpdate(curdomain);
        }
      }

      if (
        result.source.droppableId === "links" &&
        linksSorted.length > 0 &&
        states.cListMode.Links === "Parsonal"
      ) {
        const copyListItems = [...linksSorted];
        const draggedItem = copyListItems.find(
          (item) => item._id === result.draggableId
        );
        copyListItems.splice(result.source.index, 1);
        copyListItems.splice(result.destination.index, 0, draggedItem);
        setlinksSorted(copyListItems);

        let curlink = copyListItems.map((item) => item._id);
        if (curlink && curlink.length > 0) {
          linkStateUpdate(curlink);
        }
      }

      if (
        result.source.droppableId === "circles" &&
        circlesSorted.length > 0 &&
        states.cListMode.Circles === "Parsonal"
      ) {
        const copyListItems = [...circlesSorted];
        const draggedItem = copyListItems.find(
          (item) => item._id === result.draggableId
        );
        copyListItems.splice(result.source.index, 1);
        copyListItems.splice(result.destination.index, 0, draggedItem);
        setCirclesSorted(copyListItems);

        let curcircle = copyListItems.map((item) => item._id);
        if (curcircle && curcircle.length > 0) {
          circleStateUpdate(curcircle);
        }
      }

      if (
        result.source.droppableId === "projects" &&
        projectsSorted.length > 0 &&
        states.cListMode.Projects === "Parsonal"
      ) {
        const copyListItems = [...projectsSorted];
        const draggedItem = copyListItems.find(
          (item) => item._id === result.draggableId
        );
        copyListItems.splice(result.source.index, 1);
        copyListItems.splice(result.destination.index, 0, draggedItem);
        setprojectsSorted(copyListItems);

        let curproject = copyListItems.map((item) => item._id);
        if (curproject && curproject.length > 0) {
          projectStateUpdate(curproject);
        }
      }
    }
  };

  const [tagFilteredMegedData, setTagFilteredMegedData] = useState([]);

  useEffect(() => {
    let filter_dataset = GetTagFilteredSortedData(
      isTag,
      isEXT,
      isNO,
      tagFilteredObj,
      extNoFilteredObj
    );
    setTagFilteredMegedData(filter_dataset);
  }, [isTag, isEXT, isNO, tagFilteredObj, extNoFilteredObj]);

  useEffect(() => {
    if (isEXT || isNO) {
      toggleEXTnNOCards(
        "people",
        (isEXT && "EXT") || (isNO && "NO"),
        false,
        "pill"
      );
      toggleEXTnNOCards(
        "skill",
        (isEXT && "EXT") || (isNO && "NO"),
        false,
        "pill"
      );
      toggleEXTnNOCards(
        "role",
        (isEXT && "EXT") || (isNO && "NO"),
        false,
        "pill"
      );
      toggleEXTnNOCards(
        "domain",
        (isEXT && "EXT") || (isNO && "NO"),
        false,
        "pill"
      );
    }
    if (isTag) {
      toggleTagCards(isTag);
    }
  }, [
    peopleSorted,
    skillsSorted,
    rolesSorted,
    domainsSorted,
    linksSorted,
    circlesSorted,
    projectsSorted,
  ]);

  const [scrollPos, setScrollPos] = useState({
    people: null,
    skills: null,
    roles: null,
  });

  /* handle checkbox selection in compare mode */
  const handleCheckboxSelection = (id, type) => {
    // Get the scroll position of the parent container
    let modComareBetweenData = [];
    const dataSet = { id: id, type: type };
    if (type === "people") {
      if (parentPeopleRef.current) {
        const scrollTop = parentPeopleRef.current.scrollTop;
        // console.log("Scroll position:", scrollTop);
        setScrollPos((prev) => ({
          ...prev,
          people: scrollTop /* skills: null, roles: null */,
        }));
      }
      /* take only people type */
      modComareBetweenData = compareMode.compareBetween.filter(
        (data) => data.type !== "skill" && data.type !== "role"
      );
      /* check and push or remove*/
      const isPresent = modComareBetweenData.some(
        (data) => data.id === dataSet.id
      );
      if (!isPresent) {
        modComareBetweenData = [...modComareBetweenData, dataSet];
      } else {
        modComareBetweenData = compareMode.compareBetween.filter(
          (data) => data.id !== dataSet.id
        );
      }
    } else if (type === "skill" || type === "role") {
      if (type === "skill" && parentSkillRef.current) {
        const scrollTop = parentSkillRef.current.scrollTop;
        // console.log("Scroll position:", scrollTop);
        setScrollPos((prev) => ({
          ...prev,
          /* people: null, */ skills: scrollTop /* roles: null */,
        }));
      }
      if (type === "role" && parentRoleRef.current) {
        const scrollTop = parentRoleRef.current.scrollTop;
        // console.log("Scroll position:", scrollTop);
        setScrollPos((prev) => ({
          ...prev,
          /* people: null, skills: null, */ roles: scrollTop,
        }));
      }
      /* remove only people type */
      modComareBetweenData = compareMode.compareBetween.filter(
        (data) => data.type !== "people"
      );

      /* check and push or remove*/
      const isPresent = modComareBetweenData.some(
        (data) => data.id === dataSet.id
      );
      if (!isPresent) {
        modComareBetweenData = [...modComareBetweenData, dataSet];
      } else {
        modComareBetweenData = compareMode.compareBetween.filter(
          (data) => data.id !== dataSet.id
        );
      }
    }
    setCompareMode((prev) => ({
      ...prev,
      compareBetween: modComareBetweenData,
    }));
  };

  useEffect(() => {
    if (parentPeopleRef && scrollPos && scrollPos.people) {
      parentPeopleRef.current.scrollTop = scrollPos.people;
    }
    if (parentSkillRef && scrollPos && scrollPos.skills) {
      parentSkillRef.current.scrollTop = scrollPos.skills;
    }
    if (parentRoleRef && scrollPos && scrollPos.roles) {
      parentRoleRef.current.scrollTop = scrollPos.roles;
    }
  }, [compareMode]);

  const IsChecked = (id) => {
    let checkStatus = compareMode.compareBetween.some((data) => data.id === id);
    return checkStatus;
  };
  const showCheckBox = (type) => {
    if (type === "people") {
      let checkStatusForSkill = compareMode.compareBetween.some(
        (data) => data.type === "skill"
      );
      let checkStatusForRole = compareMode.compareBetween.some(
        (data) => data.type === "role"
      );
      return !(checkStatusForSkill || checkStatusForRole);
    } else if (type === "skill" || type === "role") {
      let checkStatusForPeople = compareMode.compareBetween.some(
        (data) => data.type === "people"
      );
      return !checkStatusForPeople;
    }
  };

  const People = () => {
    return (
      <div
        className={
          Object.keys(tagFilteredMegedData).length > 0 &&
          tagFilteredMegedData.people &&
          tagFilteredMegedData.people.length === 0
            ? "board-col hide"
            : "board-col"
        }
        data-board_id="people"
      >
        <div className="list list-bg-yellow" data-list_id="people">
          <div className="dropdown" style={{ position: "static" }}>
            <h4 className="list-title">
              <img alt="" src="images/icon-people.png" />
              People (<span>{peopleSorted.length}</span>)
              {/* listId, pillType, reset = false, clickedOn = "list" */}
              <a
                className={
                  clickedOnList.isClicked &&
                  clickedOnList.listName.includes("people")
                    ? "list-menu refresh-list"
                    : "list-menu refresh-list hide"
                }
                href="#"
                onClick={() => {
                  toggleEXTnNOCards("people", "", true, "list");
                }}
              >
                <i className="fas fa-sync-alt"></i>
              </a>
              <a
                className={
                  clickedOnList.isClicked &&
                  clickedOnList.listName.includes("people")
                    ? "list-menu dropdown-toggle hide"
                    : "list-menu dropdown-toggle"
                }
                // className="list-menu dropdown-toggle"
                data-toggle="dropdown"
                href="#"
              >
                {getSortIcon("People")}
                <i className="fas fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-right" role="menu">
                <li role="presentation">
                  <a
                    role="menuitem"
                    onClick={() => isInfos("People")}
                    className="mytpt-list-info"
                  >
                    Info
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    type="button"
                    onClick={() => addNewPeoples()}
                  >
                    Add new person
                  </a>
                </li>

                <li role="presentation">
                  <a
                    role="menuitem"
                    onClick={() => pCardHideOnchange("People")}
                    className="hide_list"
                  >
                    Hide list
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" style={{ paddingRight: "5px" }}>
                    Show Notes
                    <label className="switch">
                      <input
                        name="People"
                        onChange={(e) => noteOnchange(e)}
                        checked={note.People}
                        className="dd-switch"
                        type="checkbox"
                      />
                      <span className="slider"></span>
                    </label>
                  </a>
                </li>
                <li role="presentation" className="divider"></li>
                <li className="dropdown-header">Sort by:</li>
                <li
                  role="presentation"
                  onClick={() => handeSorts("NewToOld", "People")}
                >
                  <a role="menuitem" className="sortcards" data-sort_order="n">
                    New to Old
                  </a>
                </li>
                <li
                  role="presentation"
                  onClick={() => handeSorts("OldToNew", "People")}
                >
                  <a role="menuitem" className="sortcards" data-sort_order="nd">
                    Old to New
                  </a>
                </li>
                <li
                  role="presentation"
                  onClick={() => handeSorts("AtoZ", "People")}
                >
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="asc"
                  >
                    A-Z
                  </a>
                </li>
                <li
                  role="presentation"
                  onClick={() => handeSorts("ZtoA", "People")}
                >
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="desc"
                  >
                    Z-A
                  </a>
                </li>

                <li role="presentation" className="divider"></li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="show-external"
                    onClick={() => {
                      toggleEXTnNOCards("people", "EXT", false);
                    }}
                  >
                    Show external
                  </a>
                </li>
              </ul>
            </h4>
          </div>
          <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <Droppable droppableId="people" direction="vertical">
              {(provided) => (
                <div
                  // ref={provided.innerRef}
                  ref={setCombinedRefs(provided.innerRef, parentPeopleRef)}
                  {...provided.droppableProps}
                  className="card-list sortable-card-list scrollbar"
                >
                  <div
                    className="form-group notes"
                    style={
                      note.People ? { display: "block" } : { display: "none" }
                    }
                  >
                    <textarea
                      className="notes"
                      ref={pnoteRef}
                      name="People"
                      // defaultValue=''
                      // value={noteSaveData.People}
                      onBlur={(e) => handleSave(e)}
                      // onBlur={(e) => saveNotes(e)}
                      placeholder="Notes"
                      maxLength="100"
                      autoFocus={null}
                      defaultValue={noteSaveData.People}
                    ></textarea>
                  </div>

                  <span className="clearable">
                    <input
                      type="text"
                      style={{ color: "black" }}
                      ref={spref}
                      value={peopleSearch}
                      onChange={(e) => setPeopleSearch(e.target.value)}
                      // onChange={(e) => toggleSearchCards('people', e.target.value)}
                      className="form-control search_list transparent"
                      name="search_list"
                      placeholder="Start typing to search..."
                      autoComplete="off"
                    />
                    {peopleSearch.length > 0 ? (
                      <i
                        className="clearable__clear"
                        onClick={() => setPeopleSearch("")}
                        style={{ display: "inline", color: "black" }}
                      >
                        ×
                      </i>
                    ) : null}
                  </span>

                  {filteredPData && filteredPData.length > 0
                    ? filteredPData.map((e, index) => {
                        if (isTag || isEXT || isNO) {
                          let Proceed = TagWiseSorting(
                            e,
                            isTag,
                            isEXT,
                            isNO,
                            tagFilteredObj,
                            extNoFilteredObj,
                            "people"
                          );
                          if (!Proceed) return;
                        }

                        return (
                          <Draggable
                            key={index}
                            draggableId={`${e._id}`}
                            index={index}
                            isDragDisabled={
                              statesLM.People !== "Parsonal" ? true : false
                            }
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                // onMouseDown={(e) => peopleDragStart(e, index)}
                                // onDragEnter={(e) => peopleDragEnter(e, index)}
                                // onDragEnd={peopleDrop}
                                key={e._id}
                                className={`card ${
                                  localStorage.getItem("latest-created") ===
                                  e._id
                                    ? "latest-created"
                                    : ""
                                }`}
                                onClick={(event) => {
                                  if (
                                    event.target.classList.contains("card") ||
                                    event.target.classList.contains("card-edit")
                                  ) {
                                    setPepole(e, filteredPData, index);

                                    if (parentPeopleRef.current) {
                                      const scrollTop =
                                        parentPeopleRef.current.scrollTop;
                                      // console.log("Scroll position:", scrollTop);
                                      setFocusedRef(scrollTop);
                                    }
                                  }
                                }}
                              >
                                {
                                  /* (e.availability > 0) && */
                                  workloadToggle === true && (
                                    <PillComponent
                                      occupied={aggrigatedPersonFTE(
                                        skills,
                                        [...roles, ...dRoles],
                                        domains,
                                        links,
                                        e._id
                                      )}
                                      total={e.availability}
                                    />
                                  )
                                }
                                {e.workRole === "External" ? (
                                  <span
                                    onClick={(e) => {
                                      toggleEXTnNOCards(
                                        "people",
                                        "EXT",
                                        false,
                                        "pill",
                                        e
                                      );
                                    }}
                                    className="custom-badge blue ext-owner-pill"
                                  >
                                    EXT
                                  </span>
                                ) : null}
                                <div className="static-relations">
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          key={indexs}
                                          onClick={() => toggleTagCards(r)}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>
                                <a
                                  /* onClick={() => setPepole(e, filteredPData, index)} */ className="card-edit"
                                >{`${e.fname} ${e.lname}`}</a>
                                {compareMode.active &&
                                compareMode.compareTypes /*&& showCheckBox("people")*/ ? (
                                  <input
                                    type="checkbox"
                                    id={e._id}
                                    name={`${e.fname} ${e.lname}`}
                                    onChange={() =>
                                      handleCheckboxSelection(e._id, "people")
                                    }
                                    checked={IsChecked(e._id)}
                                  />
                                ) : (
                                  <a
                                    className="card-focus"
                                    onClick={() =>
                                      handelFocused({
                                        card: "People",
                                        index: index,
                                        id: e._id,
                                      })
                                    }
                                  >
                                    <img alt="" src="./images/newicons/focus.svg" />
                                  </a>
                                )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}

                  {provided.placeholder}

                  <div
                    className="card2"
                    style={
                      lFromState.Peoples
                        ? { display: "block" }
                        : { display: "none" }
                    }
                    draggable={false}
                  >
                    <form
                      className="form-new-card"
                      role="form"
                      onSubmit={peopleSubmit}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          ref={pref}
                          name="card_name"
                          placeholder="Add first name"
                          data-mode="Standard mode"
                          className="form-control"
                          value={fName}
                          onChange={handleNameChange}
                          onKeyPress={pfNameHandel}
                          autoComplete="off"
                          required
                        />
                      </div>
                      <div className="form-group" style={{ marginTop: "10px" }}>
                        <button
                          type="submit"
                          className="btn btn-mytpt add-quick close-add-card"
                        >
                          Save
                        </button>
                        <a
                          href="#"
                          type="button"
                          onClick={() => {
                            // Added by Debashis on 13-12-2023 (updated 18-12-2023)
                            let lastname = "";
                            let firstname = "";
                            if (fName.trim().indexOf(" ") >= 0) {
                              let nameArray = fName.split(" ");
                              lastname = nameArray.pop();
                              firstname = nameArray.join(" ");
                            } else {
                              lastname = "";
                              firstname = fName;
                            }
                            setPepoles({ fname: firstname, lname: lastname });
                            setFName("");
                            lFromState.Peoples = false;
                          }}
                          className="btn btn-link underline open-tool"
                        >
                          Edit details
                        </a>
                        <a
                          href="#"
                          onClick={() => peopleFrom()}
                          className="btn btn-link underline close-add-card"
                        >
                          Close
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <a
            className="btn-list add-card"
            onClick={() => peopleFrom()}
            data-list_id="people"
            style={
              !lFromState.Peoples ? { display: "block" } : { display: "none" }
            }
          >
            + Add new person
          </a>
        </div>
      </div>
    );
  };

  const Skills = () => {
    return (
      <div
        className={
          Object.keys(tagFilteredMegedData).length > 0 &&
          tagFilteredMegedData.skill &&
          tagFilteredMegedData.skill.length === 0
            ? "board-col hide"
            : "board-col"
        }
        data-board_id="skills"
      >
        <div className="list list-bg-blue" data-list_id="skills">
          <div className="dropdown" style={{ position: "static" }}>
            <h4 className="list-title">
              <img alt="" src="images/icon-skill.png" />
              Skills (<span>{filteredSData.length}</span>)
              <a
                className={
                  clickedOnList.isClicked &&
                  clickedOnList.listName.includes("skill")
                    ? "list-menu refresh-list"
                    : "list-menu refresh-list hide"
                }
                // className="list-menu refresh-list hide"
                href="#"
                onClick={() => {
                  toggleEXTnNOCards("skills", "", true, "list");
                }}
              >
                <i className="fas fa-sync-alt"></i>
              </a>
              <a
                className={
                  clickedOnList.isClicked &&
                  clickedOnList.listName.includes("skill")
                    ? "list-menu dropdown-toggle hide"
                    : "list-menu dropdown-toggle"
                }
                // className="list-menu dropdown-toggle"
                data-toggle="dropdown"
                href="#"
              >
                {getSortIcon("Skills")}
                <i className="fas fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-right" role="menu">
                <li role="presentation">
                  <a
                    onClick={() => isInfos("Skills")}
                    role="menuitem"
                    className="mytpt-list-info"
                  >
                    Info
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" onClick={() => setSkills()}>
                    Add new skill
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    onClick={() => pCardHideOnchange("Skills")}
                    className="hide_list"
                  >
                    Hide list
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" style={{ paddingRight: "5px" }}>
                    Show Notes
                    <label className="switch">
                      <input
                        name="Skills"
                        onChange={(e) => noteOnchange(e)}
                        checked={note.Skills}
                        className="dd-switch"
                        type="checkbox"
                      />
                      <span className="slider"></span>
                    </label>
                  </a>
                </li>
                <li role="presentation" className="divider"></li>
                <li className="dropdown-header">Sort by:</li>

                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="n"
                    onClick={() => handeSorts("NewToOld", "Skills")}
                  >
                    New to Old
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="nd"
                    onClick={() => handeSorts("OldToNew", "Skills")}
                  >
                    Old to New
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="asc"
                    onClick={() => handeSorts("AtoZ", "Skills")}
                  >
                    A-Z
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="desc"
                    onClick={() => handeSorts("ZtoA", "Skills")}
                  >
                    Z-A
                  </a>
                </li>
                <li role="presentation" className="divider"></li>

                <li role="presentation">
                  <a
                    role="menuitem"
                    className="show-external"
                    onClick={() => {
                      toggleEXTnNOCards("skills", "EXT", false);
                    }}
                  >
                    Show items owned by external
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="show-noowners"
                    onClick={() => {
                      toggleEXTnNOCards("skills", "NO", false);
                    }}
                  >
                    Show items without owners
                  </a>
                </li>
              </ul>
            </h4>
          </div>
          <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <Droppable droppableId="skills" direction="vertical">
              {(skillsProvided) => (
                <div
                  // ref={skillsProvided.innerRef}
                  // {...skillsProvided.droppableProps}
                  ref={setCombinedRefs(skillsProvided.innerRef, parentSkillRef)}
                  {...skillsProvided.droppableProps}
                  className="card-list sortable-card-list scrollbar"
                >
                  <div
                    className="form-group notes"
                    style={
                      note.Skills ? { display: "block" } : { display: "none" }
                    }
                  >
                    <textarea
                      className="notes"
                      name="Skills"
                      // defaultValue=''
                      //  value={noteSaveData.Skills}
                      // onChange={(e) => handleSave(e)}
                      onBlur={(e) => handleSave(e)}
                      placeholder="Notes"
                      maxLength="100"
                      defaultValue={noteSaveData.Skills}
                    ></textarea>
                  </div>
                  <span className="clearable">
                    <input
                      type="text"
                      style={{ color: "black" }}
                      ref={sSref}
                      value={skillSearch}
                      onChange={(e) => setSkillSearch(e.target.value)}
                      data-list_name="skills"
                      className="form-control search_list transparent"
                      name="search_list"
                      placeholder="Start typing to search..."
                      autoComplete="off"
                    />
                    {skillSearch.length > 0 ? (
                      <i
                        className="clearable__clear"
                        onClick={() => setSkillSearch("")}
                        style={{ display: "inline", color: "black" }}
                      >
                        ×
                      </i>
                    ) : null}
                  </span>

                  {filteredSData.length > 0
                    ? filteredSData.map((e, index) => {
                        if (isTag || isEXT || isNO) {
                          let Proceed = TagWiseSorting(
                            e,
                            isTag,
                            isEXT,
                            isNO,
                            tagFilteredObj,
                            extNoFilteredObj,
                            "skill"
                          );
                          if (!Proceed) return;
                        }

                        let isExternal = IsOwnerExternal(e, "Skill");
                        return (
                          <Draggable
                            key={index}
                            draggableId={`${e._id}`}
                            index={index}
                            isDragDisabled={
                              statesLM.Skills !== "Parsonal" ? true : false
                            }
                          >
                            {(skillsProvided) => (
                              <div
                                ref={skillsProvided.innerRef}
                                {...skillsProvided.draggableProps}
                                {...skillsProvided.dragHandleProps}
                                // onDragStart={(e) => skillsDragStart(e, index)}
                                // onDragEnter={(e) => skillsDragEnter(e, index)}
                                // onDragEnd={skillsDrop}
                                key={index}
                                className={`card ${
                                  localStorage.getItem("latest-created") ===
                                  e._id
                                    ? "latest-created"
                                    : ""
                                }`}
                                onClick={(event) => {
                                  if (
                                    event.target.classList.contains("card") ||
                                    event.target.classList.contains("card-edit")
                                  ) {
                                    setSkill(e, filteredSData, index);
                                  }
                                }}
                              >
                                <div className="static-relations">
                                  {workloadToggle === true && (
                                    <PillComponent
                                      occupied={sumFTEBody(
                                        e.workload,
                                        e.owners
                                      )}
                                      total={e.planTotal}
                                    />
                                  )}
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          "skill",
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {e.owners.length === 0 ||
                                  !OwnerExist(e.owners) ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          "skill",
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  ) : null}
                                  {e.ownerType.length > 0 &&
                                  e.ownerType === "Single owner" ? (
                                    <span className="custom-badge blue-o single-owner-pill single-owner-pill">
                                      1
                                    </span>
                                  ) : null}
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={indexs}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>
                                <a
                                  /* onClick={() => setSkill(e, filteredSData, index)} */ className="card-edit"
                                >
                                  {e.skillName}
                                </a>
                                {compareMode.active &&
                                  compareMode.compareTypes /*&&  showCheckBox("skill")*/ && (
                                    <input
                                      type="checkbox"
                                      id={e._id}
                                      name={e.skillName}
                                      onChange={() =>
                                        handleCheckboxSelection(e._id, "skill")
                                      }
                                      checked={IsChecked(e._id)}
                                    />
                                  )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}

                  {skillsProvided.placeholder}

                  <div
                    className="card2"
                    style={
                      lFromState.Skills
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <form
                      className="form-new-card"
                      role="form"
                      onSubmit={skillSubmit}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          ref={sref}
                          name="card_name"
                          required
                          placeholder="Add skill name"
                          className="form-control"
                          value={skill}
                          onChange={handleSkillChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group" style={{ marginTop: "10px" }}>
                        <button
                          type="submit"
                          className="btn btn-mytpt add-quick close-add-card"
                        >
                          Save
                        </button>
                        <a
                          href="#"
                          type="button"
                          onClick={() => {
                            setSkills({ skillName: skill });
                            setSkillName("");
                            lFromState.Skills = false;
                          }}
                          className="btn btn-link underline open-tool"
                        >
                          Edit details
                        </a>
                        <a
                          href="#"
                          onClick={() => skillsFrom()}
                          className="btn btn-link underline close-add-card"
                        >
                          Close
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <a
            className="btn-list add-card"
            onClick={() => skillsFrom()}
            data-list_id="skills"
            style={
              !lFromState.Skills ? { display: "block" } : { display: "none" }
            }
          >
            + Add new skill
          </a>
        </div>
      </div>
    );
  };

  const Roles = () => {
    return (
      <div
        className={
          Object.keys(tagFilteredMegedData).length > 0 &&
          tagFilteredMegedData.role &&
          tagFilteredMegedData.role.length === 0
            ? "board-col hide"
            : "board-col"
        }
        data-board_id="roles"
      >
        <div className="list list-bg-blue" data-list_id="roles">
          <div className="dropdown">
            <h4 className="list-title">
              <img alt="" src="images/icon-role.png" />
              Roles (<span>{filteredRData.length}</span>)
              <a
                className={
                  clickedOnList.isClicked &&
                  clickedOnList.listName.includes("role")
                    ? "list-menu refresh-list"
                    : "list-menu refresh-list hide"
                }
                // className="list-menu refresh-list hide"
                href="#"
                onClick={() => {
                  toggleEXTnNOCards("roles", "", true, "list");
                }}
              >
                <i className="fas fa-sync-alt"></i>
              </a>
              <a
                className={
                  clickedOnList.isClicked &&
                  clickedOnList.listName.includes("role")
                    ? "list-menu dropdown-toggle hide"
                    : "list-menu dropdown-toggle"
                }
                // className="list-menu dropdown-toggle"
                data-toggle="dropdown"
                href="#"
              >
                {getSortIcon("Roles")}
                <i className="fas fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-right" role="menu">
                <li role="presentation">
                  <a
                    onClick={() => isInfos("Roles")}
                    role="menuitem"
                    className="mytpt-list-info"
                  >
                    Info
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" type="button" onClick={setRoles}>
                    Add new role
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    onClick={() => pCardHideOnchange("Roles")}
                    className="hide_list"
                  >
                    Hide list
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" style={{ paddingRight: "5px" }}>
                    Show Notes
                    <label className="switch">
                      <input
                        name="Roles"
                        onChange={(e) => noteOnchange(e)}
                        checked={note.Roles}
                        className="dd-switch"
                        type="checkbox"
                      />
                      <span className="slider"></span>
                    </label>
                  </a>
                </li>
                <li role="presentation" className="divider"></li>
                <li className="dropdown-header">Sort by:</li>

                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="n"
                    onClick={() => handeSorts("NewToOld", "Roles")}
                  >
                    New to Old
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="nd"
                    onClick={() => handeSorts("OldToNew", "Roles")}
                  >
                    Old to New
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="asc"
                    onClick={() => handeSorts("AtoZ", "Roles")}
                  >
                    A-Z
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="desc"
                    onClick={() => handeSorts("ZtoA", "Roles")}
                  >
                    Z-A
                  </a>
                </li>
                <li role="presentation" className="divider"></li>

                <li role="presentation" className="divider"></li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="show-external"
                    onClick={() => {
                      toggleEXTnNOCards("roles", "EXT", false);
                    }}
                  >
                    Show items owned by external
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="show-noowners"
                    onClick={() => {
                      toggleEXTnNOCards("roles", "NO", false);
                    }}
                  >
                    Show items without owners
                  </a>
                </li>
              </ul>
            </h4>
          </div>
          <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <Droppable droppableId="roles" direction="vertical">
              {(rolesProvided) => (
                <div
                  // ref={rolesProvided.innerRef}
                  // {...rolesProvided.droppableProps}
                  ref={setCombinedRefs(rolesProvided.innerRef, parentRoleRef)}
                  {...rolesProvided.droppableProps}
                  className="card-list sortable-card-list scrollbar"
                >
                  <div
                    className="form-group notes"
                    style={
                      note.Roles ? { display: "block" } : { display: "none" }
                    }
                  >
                    <textarea
                      className="notes"
                      name="Roles"
                      // defaultValue=''
                      // value={noteSaveData.Roles}
                      // onChange={(e) => handleSave(e)}
                      onBlur={(e) => handleSave(e)}
                      placeholder="Notes"
                      maxLength="100"
                      defaultValue={noteSaveData.Roles}
                    ></textarea>
                  </div>
                  <span className="clearable">
                    <input
                      type="text"
                      style={{ color: "black" }}
                      ref={sRref}
                      value={roleSearch}
                      onChange={(e) => setRoleSearch(e.target.value)}
                      className="form-control search_list transparent"
                      name="search_list"
                      placeholder="Start typing to search..."
                      autoComplete="off"
                    />
                    {roleSearch.length > 0 ? (
                      <i
                        className="clearable__clear"
                        onClick={() => setRoleSearch("")}
                        style={{ display: "inline", color: "black" }}
                      >
                        ×
                      </i>
                    ) : null}
                  </span>

                  {filteredRData && filteredRData.length > 0
                    ? filteredRData.map((e, index) => {
                        if (isTag || isEXT || isNO) {
                          let Proceed = TagWiseSorting(
                            e,
                            isTag,
                            isEXT,
                            isNO,
                            tagFilteredObj,
                            extNoFilteredObj,
                            "role"
                          );
                          if (!Proceed) return;
                        }
                        let isExternal = IsOwnerExternal(e, "Role");
                        if (e.defaultRole) return;

                        return (
                          <Draggable
                            key={index}
                            draggableId={`${e._id}`}
                            index={index}
                            isDragDisabled={
                              statesLM.Roles !== "Parsonal" ? true : false
                            }
                          >
                            {(rolesProvided) => (
                              <div
                                ref={rolesProvided.innerRef}
                                {...rolesProvided.draggableProps}
                                {...rolesProvided.dragHandleProps}
                                // onDragStart={(e) => rolesDragStart(e, index)}
                                // onDragEnter={(e) => rolesDragEnter(e, index)}
                                // onDragEnd={rolesDrop}

                                key={index}
                                className={`card ${
                                  localStorage.getItem("latest-created") ===
                                  e._id
                                    ? "latest-created"
                                    : ""
                                }`}
                                onClick={(event) => {
                                  if (
                                    event.target.classList.contains("card") ||
                                    event.target.classList.contains("card-edit")
                                  ) {
                                    setRole(e, filteredRData, index);
                                  }
                                }}
                              >
                                <div className="static-relations">
                                  {workloadToggle === true && (
                                    <PillComponent
                                      occupied={sumFTEBody(
                                        e.workload,
                                        e.owners
                                      )}
                                      total={e.planTotal}
                                    />
                                  )}
                                  {isExternal === true ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {e.owners.length === 0 ||
                                  !OwnerExist(e.owners) ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  ) : null}
                                  {e.ownerRole.length > 0 &&
                                  e.ownerRole === "Single owner" ? (
                                    <span className="custom-badge blue-o single-owner-pill">
                                      1
                                    </span>
                                  ) : null}
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={indexs}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>
                                <a
                                  // onClick={() => setRole(e, filteredRData, index)}
                                  className="card-edit"
                                >
                                  {e.roleName}
                                </a>
                                {compareMode.active &&
                                  compareMode.compareTypes /*&&  showCheckBox("role")*/ && (
                                    <input
                                      type="checkbox"
                                      id={e._id}
                                      name={e.roleName}
                                      onChange={() =>
                                        handleCheckboxSelection(e._id, "role")
                                      }
                                      checked={IsChecked(e._id)}
                                    />
                                  )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}

                  {dRoles && dRoles.length > 0
                    ? dRoles.map((d, index) => {
                        if (isTag || isEXT || isNO) {
                          let Proceed = TagWiseSorting(
                            d,
                            isTag,
                            isEXT,
                            isNO,
                            tagFilteredObj,
                            extNoFilteredObj,
                            "role"
                          );
                          if (!Proceed) return;
                        }
                        return (
                          <div
                            key={index}
                            className="card default-role"
                            data-card_name="Circle Lead"
                            data-default="true"
                            data-html2canvas-ignore="true"
                          >
                            <div className="static-relations">
                              {workloadToggle === true && (
                                <PillComponent
                                  occupied={sumFTEBody(d.workload, d.owners)}
                                  total={d.planTotal}
                                />
                              )}

                              {d.tags && d.tags.length > 0
                                ? d.tags.map((r, indexs) => (
                                    <span
                                      onClick={() => toggleTagCards(r)}
                                      key={indexs}
                                      className="custom-badge green tags"
                                    >
                                      {r}
                                    </span>
                                  ))
                                : null}
                            </div>
                            <a
                              onClick={() => setRole(d, dRoles)}
                              className="card-edit"
                            >
                              {d.roleName}
                            </a>
                          </div>
                        );
                      })
                    : null}

                  {rolesProvided.placeholder}

                  <div
                    className="card2"
                    style={
                      lFromState.Roles
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <form
                      className="form-new-card"
                      role="form"
                      onSubmit={roleSubmit}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          ref={rref}
                          name="card_name"
                          value={role}
                          onChange={handleRoleChange}
                          required
                          placeholder="Add role name"
                          className="form-control"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group" style={{ marginTop: "10px" }}>
                        <button
                          type="submit"
                          className="btn btn-mytpt add-quick close-add-card"
                        >
                          Save
                        </button>
                        <a
                          href="#"
                          type="button"
                          onClick={() => {
                            setRoles({ roleName: role });
                            setRoleName("");
                            lFromState.Roles = false;
                          }}
                          className="btn btn-link underline open-tool"
                        >
                          Edit details
                        </a>
                        <a
                          href="#"
                          onClick={() => rolesFrom()}
                          className="btn btn-link underline close-add-card"
                        >
                          Close
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <a
            className="btn-list add-card"
            onClick={() => rolesFrom()}
            data-list_id="roles"
            style={
              !lFromState.Roles ? { display: "block" } : { display: "none" }
            }
          >
            + Add new role
          </a>
        </div>
      </div>
    );
  };

  const Domains = () =>
    !compareMode.active && (
      <div
        className={
          Object.keys(tagFilteredMegedData).length > 0 &&
          tagFilteredMegedData.domain &&
          tagFilteredMegedData.domain.length === 0
            ? "board-col hide"
            : "board-col"
        }
        data-board_id="domains"
      >
        <div className="list list-bg-blue" data-list_id="domains">
          <div className="dropdown">
            <h4 className="list-title">
              <img alt="" src="images/icon-domain.png" />
              Domains (<span>{filteredDData.length}</span>){" "}
              <a
                className={
                  clickedOnList.isClicked &&
                  clickedOnList.listName.includes("domain")
                    ? "list-menu refresh-list"
                    : "list-menu refresh-list hide"
                }
                // className="list-menu refresh-list hide"
                href="#"
                onClick={() => {
                  toggleEXTnNOCards("domains", "", true, "list");
                }}
              >
                <i className="fas fa-sync-alt"></i>
              </a>{" "}
              <a
                className={
                  clickedOnList.isClicked &&
                  clickedOnList.listName.includes("domain")
                    ? "list-menu dropdown-toggle hide"
                    : "list-menu dropdown-toggle"
                }
                // className="list-menu dropdown-toggle"
                data-toggle="dropdown"
                href="#"
              >
                {getSortIcon("Domains")}
                <i className="fas fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-right" role="menu">
                <li role="presentation">
                  <a
                    onClick={() => isInfos("Domains")}
                    role="menuitem"
                    className="mytpt-list-info"
                  >
                    Info
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" onClick={() => setDomains()}>
                    Add new domain
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    onClick={() => pCardHideOnchange("Domains")}
                    className="hide_list"
                  >
                    Hide list
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" style={{ paddingRight: "5px" }}>
                    Show Notes
                    <label className="switch">
                      <input
                        name="Domains"
                        onChange={(e) => noteOnchange(e)}
                        checked={note.Domains}
                        className="dd-switch"
                        type="checkbox"
                      />
                      <span className="slider"></span>
                    </label>
                  </a>
                </li>
                <li role="presentation" className="divider"></li>
                <li className="dropdown-header">Sort by:</li>

                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="n"
                    onClick={() => handeSorts("NewToOld", "Domains")}
                  >
                    New to Old
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="nd"
                    onClick={() => handeSorts("OldToNew", "Domains")}
                  >
                    Old to New
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="asc"
                    onClick={() => handeSorts("AtoZ", "Domains")}
                  >
                    A-Z
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="desc"
                    onClick={() => handeSorts("ZtoA", "Domains")}
                  >
                    Z-A
                  </a>
                </li>

                <li role="presentation" className="divider"></li>
                <li role="presentation" className="divider"></li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="show-external"
                    onClick={() => {
                      toggleEXTnNOCards("domains", "EXT", false, "list");
                    }}
                  >
                    Show items owned by external
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="show-noowners"
                    onClick={() => {
                      toggleEXTnNOCards("domains", "NO", false, "list");
                    }}
                  >
                    Show items without owners
                  </a>
                </li>
              </ul>
            </h4>
          </div>

          <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <Droppable droppableId="domains" direction="vertical">
              {(domainsProvided) => (
                <div
                  ref={domainsProvided.innerRef}
                  {...domainsProvided.droppableProps}
                  className="card-list sortable-card-list scrollbar"
                >
                  <div
                    className="form-group notes"
                    style={
                      note.Domains ? { display: "block" } : { display: "none" }
                    }
                  >
                    <textarea
                      className="notes"
                      name="Domains"
                      onBlur={(e) => handleSave(e)}
                      placeholder="Notes"
                      maxLength="100"
                      defaultValue={noteSaveData.Domains}
                    ></textarea>
                  </div>
                  <span className="clearable">
                    <input
                      type="text"
                      style={{ color: "black" }}
                      ref={sDref}
                      onChange={(e) => setDomainSearch(e.target.value)}
                      value={domainSearch}
                      className="form-control search_list transparent"
                      name="search_list"
                      placeholder="Start typing to search..."
                      autoComplete="off"
                    />
                    {domainSearch.length > 0 ? (
                      <i
                        className="clearable__clear"
                        onClick={() => setDomainSearch("")}
                        style={{ display: "inline", color: "black" }}
                      >
                        ×
                      </i>
                    ) : null}
                  </span>

                  {filteredDData && filteredDData.length > 0
                    ? filteredDData.map((e, index) => {
                        if (isTag || isEXT || isNO) {
                          let Proceed = TagWiseSorting(
                            e,
                            isTag,
                            isEXT,
                            isNO,
                            tagFilteredObj,
                            extNoFilteredObj,
                            "domain"
                          );
                          if (!Proceed) return;
                        }

                        let isExternal = IsOwnerExternal(e, "Domain");
                        let whoIsOwnerType = dWhoIsOwnerType(e);
                        if (e.defaultDomain) return;

                        return (
                          <Draggable
                            key={index}
                            draggableId={`${e._id}`}
                            index={index}
                            isDragDisabled={
                              statesLM.Domains !== "Parsonal" ? true : false
                            }
                          >
                            {(domainsProvided) => (
                              <div
                                ref={domainsProvided.innerRef}
                                {...domainsProvided.draggableProps}
                                {...domainsProvided.dragHandleProps}
                                // onDragStart={(e) => domainsDragStart(e, index)}
                                // onDragEnter={(e) => domainsDragEnter(e, index)}
                                // onDragEnd={domainsDrop}

                                key={index}
                                className={`card ${
                                  localStorage.getItem("latest-created") ===
                                  e._id
                                    ? "latest-created"
                                    : ""
                                }`}
                                data-list_id="domains"
                                onClick={(event) => {
                                  if (
                                    event.target.classList.contains("card") ||
                                    event.target.classList.contains("card-edit")
                                  ) {
                                    setDomain(e, filteredDData, index);
                                  }
                                }}
                              >
                                <div className="static-relations">
                                  {workloadToggle === true &&
                                    e &&
                                    e.owners &&
                                    e.owners.owner && (
                                      <PillComponent
                                        occupied={sumFTEBody(e.workload, [
                                          e.owners.owner,
                                        ])}
                                        total={e.planTotal}
                                      />
                                    )}

                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}

                                  {whoIsOwnerType &&
                                  Object.keys(whoIsOwnerType).length > 0 ? (
                                    <span
                                      onClick={() =>
                                        cardOpensCfDomain(whoIsOwnerType)
                                      }
                                      className="custom-badge lightgray"
                                    >
                                      {whoIsOwnerType.name}
                                    </span>
                                  ) : null}

                                  {e.owners &&
                                  e.owners.owner &&
                                  e.owners.owner.length > 0 ? null : (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  )}

                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={indexs}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>
                                <a
                                  /* onClick={() => setDomain(e, filteredDData, index)} */ className="card-edit"
                                >
                                  {e.domainName}
                                </a>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}

                  {dDomains && dDomains.length > 0
                    ? dDomains.map((d, index) => {
                        return (
                          <div
                            key={index}
                            className="card default-domain"
                            data-default="true"
                            data-html2canvas-ignore="true"
                          >
                            <div className="static-relations">
                              {workloadToggle === true &&
                                d &&
                                d.owners &&
                                d.owners.owner && (
                                  <PillComponent
                                    occupied={sumFTEBody(d.workload, [
                                      d.owners.owner,
                                    ])}
                                    total={d.planTotal}
                                  />
                                )}
                              {d.tags && d.tags.length > 0
                                ? d.tags.map((r, indexs) => (
                                    <span
                                      onClick={() => toggleTagCards(r)}
                                      key={indexs}
                                      className="custom-badge green tags"
                                    >
                                      {r}
                                    </span>
                                  ))
                                : null}
                            </div>
                            <a
                              onClick={() => setDomain(d, dDomains)}
                              className="card-edit"
                            >
                              {d.domainName}
                            </a>
                          </div>
                        );
                      })
                    : null}

                  {domainsProvided.placeholder}

                  <div
                    className="card2"
                    style={
                      lFromState.Domains
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <form
                      className="form-new-card"
                      role="form"
                      onSubmit={domainSubmit}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          ref={dref}
                          name="card_name"
                          required
                          value={domain}
                          onChange={handleDomainChange}
                          placeholder="Add domain name"
                          className="form-control"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group" style={{ marginTop: "10px" }}>
                        <button
                          type="submit"
                          className="btn btn-mytpt add-quick close-add-card"
                        >
                          Save
                        </button>
                        <a
                          href="#"
                          type="button"
                          onClick={() => {
                            setDomains({ domainName: domain });
                            setDomainName("");
                            lFromState.Domains = false;
                          }}
                          className="btn btn-link underline open-tool"
                        >
                          Edit details
                        </a>
                        <a
                          href="#"
                          onClick={() => domainsFrom()}
                          className="btn btn-link underline close-add-card"
                        >
                          Close
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <a
            className="btn-list add-card"
            onClick={() => domainsFrom()}
            data-list_id="domain"
            style={
              !lFromState.Domains ? { display: "block" } : { display: "none" }
            }
          >
            + Add new domain
          </a>
        </div>
      </div>
    );

  const Links = () =>
    !compareMode.active && (
      <div
        className={
          Object.keys(tagFilteredMegedData).length > 0 &&
          tagFilteredMegedData.link &&
          tagFilteredMegedData.link.length === 0
            ? "board-col hide"
            : "board-col"
        }
        data-board_id="links"
      >
        <div className="list list-bg-blue" data-list_id="links">
          <div className="dropdown">
            <h4 className="list-title">
              <img alt="" src="images/icon-link.png" />
              Links (<span>{links.length}</span>)
              <a
                className={
                  clickedOnList.isClicked &&
                  clickedOnList.listName.includes("link")
                    ? "list-menu refresh-list"
                    : "list-menu refresh-list hide"
                }
                // className="list-menu refresh-list hide"
                href="#"
                onClick={() => {
                  toggleEXTnNOCards("links", "", true, "list");
                }}
              >
                <i className="fas fa-sync-alt"></i>
              </a>
              <a
                className={
                  clickedOnList.isClicked &&
                  clickedOnList.listName.includes("link")
                    ? "list-menu dropdown-toggle hide"
                    : "list-menu dropdown-toggle"
                }
                // className="list-menu dropdown-toggle"
                data-toggle="dropdown"
                href="#"
              >
                {getSortIcon("Links")}
                <i className="fas fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-right" role="menu">
                <li role="presentation">
                  <a
                    onClick={() => isInfos("Links")}
                    role="menuitem"
                    className="mytpt-list-info"
                  >
                    Info
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" onClick={() => setLinks()}>
                    Add new link
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    onClick={() => pCardHideOnchange("Links")}
                    className="hide_list"
                  >
                    Hide list
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" style={{ paddingRight: "5px" }}>
                    Show Notes
                    <label className="switch">
                      <input
                        name="Links"
                        onChange={(e) => noteOnchange(e)}
                        checked={note.Links}
                        className="dd-switch"
                        type="checkbox"
                      />
                      <span className="slider"></span>
                    </label>
                  </a>
                </li>
                <li role="presentation" className="divider"></li>
                <li className="dropdown-header">Sort by:</li>

                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="n"
                    onClick={() => handeSorts("NewToOld", "Links")}
                  >
                    New to Old
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="nd"
                    onClick={() => handeSorts("OldToNew", "Links")}
                  >
                    Old to New
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="asc"
                    onClick={() => handeSorts("AtoZ", "Links")}
                  >
                    A-Z
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="desc"
                    onClick={() => handeSorts("ZtoA", "Links")}
                  >
                    Z-A
                  </a>
                </li>

                <li role="presentation" className="divider"></li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="show-external"
                    onClick={() => {
                      toggleEXTnNOCards("links", "EXT", false);
                    }}
                  >
                    Show items owned by external
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="show-noowners"
                    onClick={() => {
                      toggleEXTnNOCards("links", "NO", false);
                    }}
                  >
                    Show items without owners
                  </a>
                </li>
              </ul>
            </h4>
          </div>
          <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <Droppable droppableId="links" direction="vertical">
              {(linksProvided) => (
                <div
                  ref={linksProvided.innerRef}
                  {...linksProvided.droppableProps}
                  className="card-list sortable-card-list scrollbar"
                >
                  <div
                    className="form-group notes"
                    style={
                      note.Links ? { display: "block" } : { display: "none" }
                    }
                  >
                    <textarea
                      className="notes"
                      name="Links"
                      // defaultValue=''
                      // value={noteSaveData.Links}
                      //   onChange={(e) => handleSave(e)}
                      onBlur={(e) => handleSave(e)}
                      placeholder="Notes"
                      maxLength="100"
                      defaultValue={noteSaveData.Links}
                    ></textarea>
                  </div>
                  <span className="clearable">
                    <input
                      type="text"
                      ref={sLref}
                      style={{ color: "black" }}
                      onChange={(e) => setLinkSearch(e.target.value)}
                      value={linkSearch}
                      className="form-control search_list transparent"
                      name="search_list"
                      placeholder="Start typing to search..."
                      autoComplete="off"
                    />
                    {linkSearch.length > 0 ? (
                      <i
                        className="clearable__clear"
                        onClick={() => setLinkSearch("")}
                        style={{ display: "inline", color: "black" }}
                      >
                        ×
                      </i>
                    ) : null}
                  </span>

                  {filteredDLata && filteredDLata.length > 0
                    ? filteredDLata.map((e, index) => {
                        if (isTag || isEXT || isNO) {
                          let Proceed = TagWiseSorting(
                            e,
                            isTag,
                            isEXT,
                            isNO,
                            tagFilteredObj,
                            extNoFilteredObj,
                            "link"
                          );
                          // console.log("Link", Proceed);
                          if (!Proceed) return;
                        }
                        let isExternal = IsOwnerExternal(e, "Link");
                        return (
                          <Draggable
                            key={index}
                            draggableId={`${e._id}`}
                            index={index}
                            isDragDisabled={
                              statesLM.Links !== "Parsonal" ? true : false
                            }
                          >
                            {(linksProvided) => (
                              <div
                                ref={linksProvided.innerRef}
                                {...linksProvided.draggableProps}
                                {...linksProvided.dragHandleProps}
                                // onDragStart={(e) => linksDragStart(e, index)}
                                // onDragEnter={(e) => linksDragEnter(e, index)}
                                // onDragEnd={linksDrop}

                                key={index}
                                className={`card ${
                                  localStorage.getItem("latest-created") ===
                                  e._id
                                    ? "latest-created"
                                    : ""
                                }`}
                                data-list_id="links"
                                onClick={(event) => {
                                  if (
                                    event.target.classList.contains("card") ||
                                    event.target.classList.contains("card-edit")
                                  ) {
                                    setLink(e, filteredDLata, index);
                                  }
                                }}
                              >
                                <div className="static-relations">
                                  {workloadToggle === true && (
                                    <PillComponent
                                      occupied={sumFTEBody(e.workload, [
                                        e.owner,
                                      ])}
                                      total={e.planTotal}
                                    />
                                  )}
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {e.owner && e.owner.length > 0 ? null : (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  )}
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          key={indexs}
                                          onClick={() => toggleTagCards(r)}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>
                                <a
                                  // onClick={() => setLink(e, filteredDLata, index)}
                                  className="card-edit"
                                >
                                  {e.linkName}
                                </a>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}

                  {linksProvided.placeholder}

                  <div
                    className="card2"
                    style={
                      lFromState.Links
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <form
                      className="form-new-card"
                      role="form"
                      onSubmit={linkSubmit}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          ref={lref}
                          name="card_name"
                          value={link}
                          onChange={handleLinkChange}
                          required
                          placeholder="Add link name"
                          className="form-control"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group" style={{ marginTop: "10px" }}>
                        <button
                          type="submit"
                          className="btn btn-mytpt add-quick close-add-card"
                        >
                          Save
                        </button>
                        <a
                          href="#"
                          type="button"
                          onClick={() => {
                            setLinks({ linkName: link });
                            setLinkName("");
                            lFromState.Links = false;
                          }}
                          className="btn btn-link underline open-tool"
                        >
                          Edit details
                        </a>
                        <a
                          href="#"
                          onClick={() => linksFrom()}
                          className="btn btn-link underline close-add-card"
                        >
                          Close
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <a
            className="btn-list add-card"
            onClick={() => linksFrom()}
            data-list_id="links"
            style={
              !lFromState.Links ? { display: "block" } : { display: "none" }
            }
          >
            + Add new link
          </a>
        </div>
      </div>
    );

  const Circles = () =>
    !compareMode.active && (
      <div
        className={
          Object.keys(tagFilteredMegedData).length > 0 &&
          tagFilteredMegedData.circle &&
          tagFilteredMegedData.circle.length === 0
            ? "board-col hide"
            : "board-col"
        }
        data-board_id="circles"
      >
        <div className="list list-bg-red" data-list_id="circles">
          <div className="dropdown">
            <h4 className="list-title">
              <img alt="" src="images/icon-circle.png" />
              Circles (<span>{filteredCData.length}</span>){" "}
              <a className="list-menu refresh-list hide" href="#">
                <i className="fas fa-sync-alt"></i>
              </a>{" "}
              <a
                className="list-menu dropdown-toggle"
                data-toggle="dropdown"
                href="#"
              >
                {getSortIcon("Circles")}
                <i className="fas fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-right" role="menu">
                <li role="presentation">
                  <a
                    onClick={() => isInfos("Circles")}
                    role="menuitem"
                    className="mytpt-list-info"
                  >
                    Info
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" onClick={() => setCircles()}>
                    Add new circle
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    onClick={() => pCardHideOnchange("Circles")}
                    className="hide_list"
                  >
                    Hide list
                  </a>
                </li>
                <li
                  onClick={() => setFocusedMode("Circle")}
                  role="presentation"
                >
                  <a role="menuitem" className="circle-mode">
                    Enter circle mode
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" style={{ paddingRight: "5px" }}>
                    Show Notes
                    <label className="switch">
                      <input
                        name="Circles"
                        onChange={(e) => noteOnchange(e)}
                        checked={note.Circles}
                        className="dd-switch"
                        type="checkbox"
                      />
                      <span className="slider"></span>
                    </label>
                  </a>
                </li>

                <li role="presentation" className="divider"></li>
                <li className="dropdown-header">Sort by:</li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    onClick={() => handeSorts("NewToOld", "Circles")}
                  >
                    New to Old
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    onClick={() => handeSorts("OldToNew", "Circles")}
                  >
                    Old to New
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="asc"
                    onClick={() => handeSorts("AtoZ", "Circles")}
                  >
                    A-Z
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="desc"
                    onClick={() => handeSorts("ZtoA", "Circles")}
                  >
                    Z-A
                  </a>
                </li>
              </ul>
            </h4>
          </div>
          <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <Droppable droppableId="circles" direction="vertical">
              {(circlesProvided) => (
                <div
                  // ref={circlesProvided.innerRef}
                  ref={setCombinedRefs(
                    circlesProvided.innerRef,
                    parentCircleRef
                  )}
                  {...circlesProvided.droppableProps}
                  className="card-list sortable-card-list scrollbar"
                >
                  <div
                    className="form-group notes"
                    style={
                      note.Circles ? { display: "block" } : { display: "none" }
                    }
                  >
                    <textarea
                      className="notes"
                      name="Circles"
                      // defaultValue=''
                      // value={noteSaveData.Circles}
                      //   onChange={(e) => handleSave(e)}
                      onBlur={(e) => handleSave(e)}
                      placeholder="Notes"
                      maxLength="100"
                      defaultValue={noteSaveData.Circles}
                    ></textarea>
                  </div>
                  <span className="clearable">
                    <input
                      type="text"
                      ref={sCref}
                      style={{ color: "black" }}
                      value={circleSearch}
                      onChange={(e) => setCircleSearch(e.target.value)}
                      className="form-control search_list transparent"
                      name="search_list"
                      placeholder="Start typing to search..."
                      autoComplete="off"
                    />
                    {circleSearch.length > 0 ? (
                      <i
                        className="clearable__clear"
                        onClick={() => setCircleSearch("")}
                        style={{ display: "inline", color: "black" }}
                      >
                        ×
                      </i>
                    ) : null}
                  </span>

                  {filteredCData && filteredCData.length > 0
                    ? filteredCData.map((e, index) => {
                        if (
                          isTag &&
                          Object.keys(tagFilteredObj).length > 0 &&
                          tagFilteredObj.circle &&
                          tagFilteredObj.circle.length > 0 &&
                          !tagFilteredObj.circle.includes(e._id)
                        ) {
                          return null;
                        }
                        return (
                          <Draggable
                            key={index}
                            draggableId={`${e._id}`}
                            index={index}
                            isDragDisabled={
                              statesLM.Circles !== "Parsonal" ? true : false
                            }
                          >
                            {(circlesProvided) => (
                              <div
                                ref={circlesProvided.innerRef}
                                {...circlesProvided.draggableProps}
                                {...circlesProvided.dragHandleProps}
                                key={index}
                                // onDragStart={(e) => circlesDragStart(e, index)}
                                // onDragEnter={(e) => circlesDragEnter(e, index)}
                                // onDragEnd={circlesDrop}
                                className={`card ${
                                  localStorage.getItem("latest-created") ===
                                  e._id
                                    ? "latest-created"
                                    : ""
                                }`}
                                data-list_id="circles"
                                onClick={(event) => {
                                  if (
                                    event.target.classList.contains("card") ||
                                    event.target.classList.contains("card-edit")
                                  ) {
                                    setCircle(e, filteredCData, index);
                                  }
                                }}
                              >
                                <div className="static-relations">
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={indexs}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>
                                <a
                                  // onClick={() => setCircle(e, filteredCData, index)}
                                  className="card-edit"
                                >
                                  {e.circleName}
                                </a>
                                <a className="card-focus">
                                  <img
                                    onClick={() =>
                                      handelFocused({
                                        card: "Circles",
                                        index: index,
                                        id: e._id,
                                      })
                                    }
                                    src="./images/newicons/focus.svg"
                                  />
                                </a>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}

                  {dCircles && dCircles.length > 0
                    ? dCircles.map((d, index) => {
                        return (
                          <div
                            key={index}
                            className="card default-circle"
                            data-card_name="Circle Lead"
                            data-default="true"
                            data-html2canvas-ignore="true"
                          >
                            <span className="static-relations">
                              {d.tags && d.tags.length > 0
                                ? d.tags.map((r, indexs) => (
                                    <span
                                      onClick={() => toggleTagCards(r)}
                                      key={indexs}
                                      className="custom-badge green tags"
                                    >
                                      {r}
                                    </span>
                                  ))
                                : null}
                            </span>
                            <a
                              onClick={() => setCircle(d, dCircles, index)}
                              className="card-edit"
                            >
                              {d.circleName}
                            </a>
                          </div>
                        );
                      })
                    : null}

                  {circlesProvided.placeholder}

                  <div
                    className="card2"
                    style={
                      lFromState.Circles
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <form
                      className="form-new-card"
                      role="form"
                      onSubmit={circleSubmit}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          ref={cref}
                          name="card_name"
                          value={circle}
                          onChange={handleCircleChange}
                          required
                          placeholder="Add circle name"
                          className="form-control"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group" style={{ marginTop: "10px" }}>
                        <button
                          type="submit"
                          className="btn btn-mytpt add-quick close-add-card"
                        >
                          Save
                        </button>
                        <a
                          href="#"
                          type="button"
                          onClick={() => {
                            setCircles({ circleName: circle });
                            setCircleName("");
                            lFromState.Circles = false;
                          }}
                          className="btn btn-link underline open-tool"
                        >
                          Edit details
                        </a>
                        <a
                          href="#"
                          onClick={() => circlesFrom()}
                          className="btn btn-link underline close-add-card"
                        >
                          Close
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <a
            className="btn-list add-card"
            onClick={() => circlesFrom()}
            data-list_id="circles"
            style={
              !lFromState.Circles ? { display: "block" } : { display: "none" }
            }
          >
            + Add new circle
          </a>
        </div>
      </div>
    );

  const Projects = () =>
    !compareMode.active && (
      <div
        className={
          Object.keys(tagFilteredMegedData).length > 0 &&
          tagFilteredMegedData.project &&
          tagFilteredMegedData.project.length === 0
            ? "board-col hide"
            : "board-col"
        }
        data-board_id="projects"
      >
        <div className="list list-bg-red" data-list_id="projects">
          <div className="dropdown">
            <h4 className="list-title">
              <img alt="" src="images/icon-project.png" />
              Projects (<span>{filteredPrData.length}</span>){" "}
              <a className="list-menu refresh-list hide" href="#">
                <i className="fas fa-sync-alt"></i>
              </a>
              <a
                className="list-menu dropdown-toggle"
                data-toggle="dropdown"
                href="#"
              >
                {getSortIcon("Projects")}
                <i className="fas fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-right" role="menu">
                <li role="presentation">
                  <a
                    onClick={() => isInfos("Projects")}
                    role="menuitem"
                    className="mytpt-list-info"
                  >
                    Info
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" onClick={() => setProjects()}>
                    Add new project
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    onClick={() => pCardHideOnchange("Projects")}
                    className="hide_list"
                  >
                    Hide list
                  </a>
                </li>
                <li role="presentation">
                  <a
                    onClick={() => setFocusedMode("Project")}
                    role="menuitem"
                    className="project-mode"
                  >
                    Enter project mode
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" style={{ paddingRight: "5px" }}>
                    Show Notes
                    <label className="switch">
                      <input
                        name="Projects"
                        onChange={(e) => noteOnchange(e)}
                        checked={note.Projects}
                        className="dd-switch"
                        type="checkbox"
                      />
                      <span className="slider"></span>
                    </label>
                  </a>
                </li>
                <li role="presentation" className="divider"></li>
                <li className="dropdown-header">Sort by:</li>

                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="n"
                    onClick={() => handeSorts("NewToOld", "Projects")}
                  >
                    New to Old
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="nd"
                    onClick={() => handeSorts("OldToNew", "Projects")}
                  >
                    Old to New
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="asc"
                    onClick={() => handeSorts("AtoZ", "Projects")}
                  >
                    A-Z
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    className="sortcards"
                    data-sort_order="desc"
                    onClick={() => handeSorts("ZtoA", "Projects")}
                  >
                    Z-A
                  </a>
                </li>
              </ul>
            </h4>
          </div>

          <DragDropContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <Droppable droppableId="projects" direction="vertical">
              {(projectsProvided) => (
                <div
                  // ref={projectsProvided.innerRef}
                  ref={setCombinedRefs(
                    projectsProvided.innerRef,
                    parentProjectRef
                  )}
                  {...projectsProvided.droppableProps}
                  className="card-list sortable-card-list scrollbar"
                >
                  <div
                    className="form-group notes"
                    style={
                      note.Projects ? { display: "block" } : { display: "none" }
                    }
                  >
                    <textarea
                      className="notes"
                      name="Projects"
                      // defaultValue=''
                      // value={noteSaveData.Projects}
                      //   onChange={(e) => handleSave(e)}
                      onBlur={(e) => handleSave(e)}
                      placeholder="Notes"
                      maxLength="100"
                      defaultValue={noteSaveData.Projects}
                    ></textarea>
                  </div>
                  <span className="clearable">
                    <input
                      type="text"
                      ref={sPrref}
                      style={{ color: "black" }}
                      value={projectSearch}
                      onChange={(e) => setProjectSearch(e.target.value)}
                      className="form-control search_list transparent"
                      name="search_list"
                      placeholder="Start typing to search..."
                      autoComplete="off"
                    />
                    {projectSearch.length > 0 ? (
                      <i
                        className="clearable__clear"
                        onClick={() => setProjectSearch("")}
                        style={{ display: "inline", color: "black" }}
                      >
                        ×
                      </i>
                    ) : null}
                  </span>

                  {filteredPrData && filteredPrData.length > 0
                    ? filteredPrData.map((e, index) => {
                        if (
                          isTag &&
                          Object.keys(tagFilteredObj).length > 0 &&
                          tagFilteredObj.project &&
                          tagFilteredObj.project.length > 0 &&
                          !tagFilteredObj.project.includes(e._id)
                        ) {
                          return null;
                        }
                        return (
                          <Draggable
                            key={index}
                            draggableId={`${e._id}`}
                            index={index}
                            isDragDisabled={
                              statesLM.Projects !== "Parsonal" ? true : false
                            }
                          >
                            {(projectsProvided) => (
                              <div
                                ref={projectsProvided.innerRef}
                                {...projectsProvided.draggableProps}
                                {...projectsProvided.dragHandleProps}
                                // onDragStart={(e) => projectsDragStart(e, index)}
                                // onDragEnter={(e) => projectsDragEnter(e, index)}
                                // onDragEnd={projectsDrop}
                                key={index}
                                className={`card ${
                                  localStorage.getItem("latest-created") ===
                                  e._id
                                    ? "latest-created"
                                    : ""
                                }`}
                                data-list_id="projects"
                                onClick={(event) => {
                                  if (
                                    event.target.classList.contains("card") ||
                                    event.target.classList.contains("card-edit")
                                  ) {
                                    setProject(e, filteredPrData, index);
                                  }
                                }}
                              >
                                <div className="static-relations">
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={indexs}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>
                                <a
                                  /* onClick={() => setProject(e, filteredPrData, index)} */ className="card-edit"
                                >
                                  {e.projectName}
                                </a>
                                <a className="card-focus">
                                  <img
                                    onClick={() =>
                                      handelFocused({
                                        card: "Projects",
                                        index: index,
                                        id: e._id,
                                      })
                                    }
                                    src="./images/newicons/focus.svg"
                                  />
                                </a>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}

                  {projectsProvided.placeholder}

                  <div
                    className="card2"
                    style={
                      lFromState.Projects
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <form
                      className="form-new-card"
                      role="form"
                      onSubmit={projectSubmit}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          ref={prref}
                          name="card_name"
                          value={project}
                          onChange={handleProjectChange}
                          required
                          placeholder="Add project name"
                          className="form-control"
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group" style={{ marginTop: "10px" }}>
                        <button
                          type="submit"
                          className="btn btn-mytpt add-quick close-add-card"
                        >
                          Save
                        </button>
                        <a
                          href="#"
                          type="button"
                          onClick={() => {
                            setProjects({ projectName: project });
                            setProjectName("");
                            lFromState.Projects = false;
                          }}
                          className="btn btn-link underline open-tool"
                        >
                          Edit details
                        </a>
                        <a
                          href="#"
                          onClick={() => projectsFrom()}
                          className="btn btn-link underline close-add-card"
                        >
                          Close
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <a
            className="btn-list add-card"
            onClick={() => projectsFrom()}
            data-list_id="projects"
            style={
              !lFromState.Projects ? { display: "block" } : { display: "none" }
            }
          >
            + Add new project
          </a>
        </div>
      </div>
    );

  //==================================================POPOVER FOR PEOPLE==================================================//
  var cuPfCirclePlus = [];

  useEffect(() => {
    if (focused.card && focused.card.length > 0 && peopleSorted.length > 0) {
      if (focused.card === "People") {
        let focusedPerson = peopleSorted.find((p) => p._id === focused.id);
        if (focusedPerson) {
          cuPfCirclePlus.push({
            name: `${focusedPerson.fname} ${focusedPerson.lname} (Person)`,
            Id: focused.id,
            type: "People",
          });
        }
        if (PfoSkills && PfoSkills.length > 0) {
          for (var i = 0; i < PfoSkills.length; i++) {
            cuPfCirclePlus.push({
              name: PfoSkills[i].skillName + " (Skill)",
              Id: PfoSkills[i]._id,
              type: "Skill",
            });
          }
        }
        if (PfoRoles && PfoRoles.length > 0) {
          for (var i = 0; i < PfoRoles.length; i++) {
            cuPfCirclePlus.push({
              name: PfoRoles[i].roleName + " (Role)",
              Id: PfoRoles[i]._id,
              type: "Role",
            });
          }
        }
        if (PfoDomains && PfoDomains.length > 0) {
          for (var i = 0; i < PfoDomains.length; i++) {
            cuPfCirclePlus.push({
              name: PfoDomains[i].domainName + " (Domain)",
              Id: PfoDomains[i]._id,
              type: "Domain",
            });
          }
        }
        if (PfoLinks && PfoLinks.length > 0) {
          for (var i = 0; i < PfoLinks.length; i++) {
            cuPfCirclePlus.push({
              name: PfoLinks[i].linkName + " (Link)",
              Id: PfoLinks[i]._id,
              type: "Link",
            });
          }
        }
      } else if (focused.card === "Circles") {
        // cuPfCirclePlus.push({ name: `${peopleSorted[focused.index].fname} ${peopleSorted[focused.index].lname} (Person)`, Id: focused.id })
      } else if (focused.card === "Projects") {
        // cuPfCirclePlus.push({ name: `${peopleSorted[focused.index].fname} ${peopleSorted[focused.index].lname} (Person)`, Id: focused.id })
      }
    }
  }, [
    focused,
    peopleSorted,
    skillsSorted,
    rolesSorted,
    domainsSorted,
    linksSorted,
  ]);

  // People focuesd to circle or project focused
  const PfToCAndP = (type, data) => {
    // console.log("here7777");
    if (type === "People") {
      if (
        data &&
        Object.keys(data).length > 0 &&
        peopleSorted &&
        peopleSorted.length > 0
      ) {
        for (let i = 0; i < peopleSorted.length; i++) {
          if (peopleSorted[i]._id === data._id) {
            handelFocused({
              card: "People",
              index: i,
              id: peopleSorted[i]._id,
            });
            break;
          }
        }
      }
    } else if (type === "Circles") {
      if (
        data &&
        Object.keys(data).length > 0 &&
        circlesSorted &&
        circlesSorted.length > 0
      ) {
        for (let i = 0; i < circlesSorted.length; i++) {
          if (circlesSorted[i]._id === data._id) {
            handelFocused({
              card: "Circles",
              index: i,
              id: circlesSorted[i]._id,
            });
            break;
          }
        }
      }
    } else if (type === "Projects") {
      if (
        data &&
        Object.keys(data).length > 0 &&
        projectsSorted &&
        projectsSorted.length > 0
      ) {
        for (let i = 0; i < projectsSorted.length; i++) {
          if (projectsSorted[i]._id === data._id) {
            handelFocused({
              card: "Projects",
              index: i,
              id: projectsSorted[i]._id,
            });
            break;
          }
        }
      }
    }
    // console.log("here7777 --- end");
  };

  const [PfSCirclePlus, setPfSCirclePlus] = useState("");
  var cuFPfCirclePlus = [];

  if (PfSCirclePlus && PfSCirclePlus.length > 0) {
    cuFPfCirclePlus = cuPfCirclePlus.filter((value) => {
      const searchStr = PfSCirclePlus.toLowerCase();
      const nameMatches = `${value.name}`.toLowerCase().includes(searchStr);
      return nameMatches;
    });
  }

  if (cuFPfCirclePlus.length === 0) {
    cuFPfCirclePlus = [...cuPfCirclePlus];
  }

  useEffect(() => {
    if (cuPfCirclePlus && cuPfCirclePlus.length > 0) {
      localStorage.setItem(
        "pfcAddData",
        JSON.stringify({ data: cuPfCirclePlus })
      );
    }
  }, [cuPfCirclePlus]);

  let workRole = "core";

  const HandelCAndP = (e, type) => {
    localStorage.setItem("memberType", type);
    if (type === "Circles") {
      if (focused && focused.card === "People" && members.length > 0) {
        for (let i = 0; i < members.length; i++) {
          if (members[i].memberType === e._id) {
            let arr = {
              teamId: members[i].teamId,
              memberType: members[i].memberType,
              coreMembers: {
                People: members[i].coreMembers.People,
                Skills: members[i].coreMembers.Skills,
                Roles: members[i].coreMembers.Roles,
                Links: members[i].coreMembers.Links,
                Domains: members[i].coreMembers.Domains,
                Circles: members[i].coreMembers.Circles,
                Projects: members[i].coreMembers.Projects,
              },
              extendedMembers: {
                People: members[i].extendedMembers.People,
                Skills: members[i].extendedMembers.Skills,
                Roles: members[i].extendedMembers.Roles,
                Links: members[i].extendedMembers.Links,
                Domains: members[i].extendedMembers.Domains,
                Circles: members[i].extendedMembers.Circles,
              },
            };
            if (arr && arr.teamId.length > 0) {
              localStorage.setItem("membercData", JSON.stringify(arr));
            }
            break;
          }
        }
      }
      if (
        focused &&
        focused.card === "People" &&
        pfmembers &&
        pfmembers.length > 0
      ) {
        let count = 0;
        for (let i = 0; i < pfmembers.length; i++) {
          if (pfmembers[i].memberType === e._id) {
            let arr = {
              teamId: pfmembers[i].teamId,
              memberType: pfmembers[i].memberType,
              coreMembers: {
                Skills: pfmembers[i].coreMembers.Skills,
                Roles: pfmembers[i].coreMembers.Roles,
                Links: pfmembers[i].coreMembers.Links,
                Domains: pfmembers[i].coreMembers.Domains,
              },
              extendedMembers: {
                Skills: pfmembers[i].extendedMembers.Skills,
                Roles: pfmembers[i].extendedMembers.Roles,
                Links: pfmembers[i].extendedMembers.Links,
                Domains: pfmembers[i].extendedMembers.Domains,
              },
            };
            if (arr && arr.teamId.length > 0) {
              localStorage.setItem("pfmembercData", JSON.stringify(arr));
            }
            break;
          }
          count++;
        }
        if (
          count === pfmembers.length &&
          !JSON.parse(localStorage.getItem("pfmembercData"))
        ) {
          let arr = {
            teamId: localStorage.getItem("teamId"),
            memberType: e._id.toString(),
            coreMembers: {
              Skills: [],
              Roles: [],
              Links: [],
              Domains: [],
            },
            extendedMembers: {
              Skills: [],
              Roles: [],
              Links: [],
              Domains: [],
            },
          };
          if (arr && arr.teamId.length > 0) {
            localStorage.setItem("pfmembercData", JSON.stringify(arr));
          }
        }
      } else {
        let arr = {
          teamId: localStorage.getItem("teamId"),
          memberType: e._id.toString(),
          coreMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          extendedMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
        };
        if (arr && arr.teamId.length > 0) {
          localStorage.setItem("pfmembercData", JSON.stringify(arr));
        }
      }
    } else if (type === "Projects") {
      if (focused && focused.card === "People" && members.length > 0) {
        for (let i = 0; i < members.length; i++) {
          if (members[i].memberType === e._id) {
            let arr = {
              teamId: members[i].teamId,
              memberType: members[i].memberType,
              coreMembers: {
                People: members[i].coreMembers.People,
                Skills: members[i].coreMembers.Skills,
                Roles: members[i].coreMembers.Roles,
                Links: members[i].coreMembers.Links,
                Domains: members[i].coreMembers.Domains,
                Circles: members[i].coreMembers.Circles,
                Projects: members[i].coreMembers.Projects,
              },
              extendedMembers: {
                People: members[i].extendedMembers.People,
                Skills: members[i].extendedMembers.Skills,
                Roles: members[i].extendedMembers.Roles,
                Links: members[i].extendedMembers.Links,
                Domains: members[i].extendedMembers.Domains,
                Circles: members[i].extendedMembers.Circles,
              },
            };
            if (arr && arr.teamId.length > 0) {
              localStorage.setItem("memberPData", JSON.stringify(arr));
            }
            break;
          }
        }
        if (
          focused &&
          focused.card === "People" &&
          pfmembers &&
          pfmembers.length > 0
        ) {
          let counts = 0;
          for (let i = 0; i < pfmembers.length; i++) {
            if (pfmembers[i].memberType === e._id) {
              let arr = {
                teamId: pfmembers[i].teamId,
                memberType: pfmembers[i].memberType,
                coreMembers: {
                  Skills: pfmembers[i].coreMembers.Skills,
                  Roles: pfmembers[i].coreMembers.Roles,
                  Links: pfmembers[i].coreMembers.Links,
                  Domains: pfmembers[i].coreMembers.Domains,
                },
                extendedMembers: {
                  Skills: pfmembers[i].extendedMembers.Skills,
                  Roles: pfmembers[i].extendedMembers.Roles,
                  Links: pfmembers[i].extendedMembers.Links,
                  Domains: pfmembers[i].extendedMembers.Domains,
                },
              };
              if (arr && arr.teamId.length > 0) {
                localStorage.setItem("pfmemberPData", JSON.stringify(arr));
              }
              break;
            }
            counts++;
          }
          if (
            counts === pfmembers.length &&
            !JSON.parse(localStorage.getItem("pfmemberPData"))
          ) {
            let arr = {
              teamId: localStorage.getItem("teamId"),
              memberType: e._id.toString(),
              coreMembers: {
                Skills: [],
                Roles: [],
                Links: [],
                Domains: [],
              },
              extendedMembers: {
                Skills: [],
                Roles: [],
                Links: [],
                Domains: [],
              },
            };
            if (arr && arr.teamId.length > 0) {
              localStorage.setItem("pfmemberPData", JSON.stringify(arr));
            }
          }
        } else {
          let arr = {
            teamId: localStorage.getItem("teamId"),
            memberType: e._id.toString(),
            coreMembers: {
              Skills: [],
              Roles: [],
              Links: [],
              Domains: [],
            },
            extendedMembers: {
              Skills: [],
              Roles: [],
              Links: [],
              Domains: [],
            },
          };
          if (arr && arr.teamId.length > 0) {
            localStorage.setItem("pfmemberPData", JSON.stringify(arr));
          }
        }
      }
    }
  };

  //=========================================PEOPLE FOCUSED algorithm===================================================

  //People
  let PFSotedData = [...peopleSorted];

  // Skills
  const [skillfSearch, setSkillfSearch] = useState("");

  const sPSref = useRef(null);
  useEffect(() => {
    if (skillfSearch.length > 0) {
      sPSref.current.focus();
    }
  }, [skillfSearch]);

  var filteredSFData = [];

  if (
    (skillfSearch.length > 0 || allSearch.length > 0) &&
    PfouSkills.length > 0
  ) {
    filteredSFData = PfouSkills.filter((value) => {
      const searchStr = skillfSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.skillName}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredSFData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Skills");
    }
  }

  if (
    filteredSFData.length === 0 &&
    skillfSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredSFData = [...PfouSkills];
  }

  let nonFilteSFData = [];
  if (skillfSearch.length > 0 && filteredSFData.length !== PfouSkills.length) {
    nonFilteSFData = [...PfouSkills];
    for (let i = 0; i < filteredSFData.length; i++) {
      nonFilteSFData = nonFilteSFData.filter((value, key) => {
        return value._id !== filteredSFData[i]._id;
      });
    }
    if (filteredSFData.length === 0) {
      nonFilteSFData = [...PfouSkills];
    }
  }

  // Roles
  const [rolefSearch, setRolefSearch] = useState("");

  const fpRref = useRef(null);
  useEffect(() => {
    if (rolefSearch.length > 0) {
      fpRref.current.focus();
    }
  }, [rolefSearch]);

  var filteredRFData = [];

  if (
    (rolefSearch.length > 0 || allSearch.length > 0) &&
    PfouRoles.length > 0
  ) {
    filteredRFData = PfouRoles.filter((value) => {
      const searchStr = rolefSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.roleName}`.toLowerCase().includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredRFData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Roles");
    }
  }

  if (
    filteredRFData.length === 0 &&
    rolefSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredRFData = [...PfouRoles];
  }

  let nonFilteRFData = [];
  if (rolefSearch.length > 0 && filteredRFData.length !== PfouRoles.length) {
    nonFilteRFData = [...PfouRoles];
    for (let i = 0; i < filteredRFData.length; i++) {
      nonFilteRFData = nonFilteRFData.filter((value, key) => {
        return value._id !== filteredRFData[i]._id;
      });
    }
    if (filteredRFData.length === 0) {
      nonFilteRFData = [...PfouRoles];
    }
  }

  let dMentor = [];
  let dLinkOwner = [];
  let dDomainOwner = [];
  let dProjectLead = [];
  let dCircleLead = [];

  var curPefCircless = [...PfoCircles];
  var curPefProjectss = [...PfoProjects];

  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    peopleSorted &&
    peopleSorted.length > 0
  ) {
    let focusedPeople = focused.id;

    for (let i = 0; i < peopleSorted.length; i++) {
      if (peopleSorted[i].mentor === focusedPeople) {
        dMentor = [...new Set([...dMentor, peopleSorted[i]._id])];
      }
    }

    for (let i = 0; i < PfoLinks.length; i++) {
      if (PfoLinks[i].owner === focusedPeople) {
        dLinkOwner = [...new Set([...dLinkOwner, PfoLinks[i]._id])];
      }
    }

    for (let i = 0; i < PfoDomains.length; i++) {
      if (PfoDomains[i].owners.owner === focusedPeople) {
        dDomainOwner = [...new Set([...dDomainOwner, PfoDomains[i]._id])];
      }
    }
    // console.log(curPefCircless)
    // console.log(dCircles)
    if (curPefCircless && curPefCircless.length > 0) {
      for (let i = 0; i < curPefCircless.length; i++) {
        if (curPefCircless[i].lead === focusedPeople) {
          dCircleLead = [...new Set([...dCircleLead, curPefCircless[i]._id])];
        }
      }
    }

    if (dCircles && dCircles.length > 0) {
      for (let i = 0; i < dCircles.length; i++) {
        if (dCircles[i].lead === focusedPeople) {
          dCircleLead = [...new Set([...dCircleLead, dCircles[i]._id])];
        }
      }
    }

    if (curPefProjectss && curPefProjectss.length > 0) {
      for (let i = 0; i < curPefProjectss.length; i++) {
        if (curPefProjectss[i].lead === focusedPeople) {
          dProjectLead = [
            ...new Set([...dProjectLead, curPefProjectss[i]._id]),
          ];
        }
      }
    }
  }

  let DPFRole = [];
  let SPFRole = [];

  if (
    focused.card &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    dRoles &&
    dRoles.length > 0 &&
    peopleSorted.length > 0
  ) {
    for (let i = 0; i < dRoles.length; i++) {
      if (dRoles[i].roleName === "Mentor" && dMentor.length > 0) {
        SPFRole.push(dRoles[i]);
      } else if (dRoles[i].roleName === "Mentor" && dMentor.length === 0) {
        DPFRole.push(dRoles[i]);
      }

      if (dRoles[i].roleName === "Link Owner" && dLinkOwner.length > 0) {
        SPFRole.push(dRoles[i]);
      } else if (
        dRoles[i].roleName === "Link Owner" &&
        dLinkOwner.length === 0
      ) {
        DPFRole.push(dRoles[i]);
      }

      if (dRoles[i].roleName === "Domain Owner" && dDomainOwner.length > 0) {
        SPFRole.push(dRoles[i]);
      } else if (
        dRoles[i].roleName === "Domain Owner" &&
        dDomainOwner.length === 0
      ) {
        DPFRole.push(dRoles[i]);
      }

      if (dRoles[i].roleName === "Circle Lead" && dCircleLead.length > 0) {
        SPFRole.push(dRoles[i]);
      } else if (
        dRoles[i].roleName === "Circle Lead" &&
        dCircleLead.length === 0
      ) {
        DPFRole.push(dRoles[i]);
      }

      if (dRoles[i].roleName === "Project Lead" && dProjectLead.length > 0) {
        SPFRole.push(dRoles[i]);
      } else if (
        dRoles[i].roleName === "Project Lead" &&
        dProjectLead.length === 0
      ) {
        DPFRole.push(dRoles[i]);
      }

      if (dRoles[i].roleName === "Governance moderator" && focused.id) {
        let count = 0;
        if (dRoles[i].owners && dRoles[i].owners.length > 0) {
          for (let j = 0; j < dRoles[i].owners.length; j++) {
            if (dRoles[i].owners[j].toString() === focused.id.toString()) {
              SPFRole.push(dRoles[i]);
            } else {
              count++;
            }
          }
          if (count === dRoles[i].owners.length) {
            DPFRole.push(dRoles[i]);
          }
        } else if (dRoles[i].owners && dRoles[i].owners.length === 0) {
          DPFRole.push(dRoles[i]);
        }
      }
    }
  }

  // Domains
  const [domainfSearch, setDomainfSearch] = useState("");

  const fpDref = useRef(null);
  useEffect(() => {
    if (domainfSearch.length > 0) {
      fpDref.current.focus();
    }
  }, [domainfSearch]);

  var filteredDFData = [];
  let nonFilteDFData = [];

  if (
    (domainfSearch.length > 0 || allSearch.length > 0) &&
    PfouDomains.length > 0
  ) {
    filteredDFData = PfouDomains.filter((value) => {
      const searchStr = domainfSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.domainName}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredDFData.length > 0) {
      ListData.push("Domains");
    }
  }

  if (
    filteredDFData.length === 0 &&
    domainfSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredDFData = [...PfouDomains];
  }

  if (
    domainfSearch.length > 0 &&
    filteredDFData.length !== PfouDomains.length
  ) {
    nonFilteDFData = [...PfouDomains];
    for (let i = 0; i < filteredDFData.length; i++) {
      nonFilteDFData = nonFilteDFData.filter((value, key) => {
        return value._id !== filteredDFData[i]._id;
      });
    }
    if (filteredDFData.length === 0) {
      nonFilteDFData = [...PfouDomains];
    }
  }

  /// Links
  const [linkfSearch, setLinkfSearch] = useState("");

  const fpLref = useRef(null);
  useEffect(() => {
    if (linkfSearch.length > 0) {
      fpLref.current.focus();
    }
  }, [linkfSearch]);

  var filteredLFData = [];

  if (
    (linkfSearch.length > 0 || allSearch.length > 0) &&
    PfouLinks.length > 0
  ) {
    filteredLFData = PfouLinks.filter((value) => {
      const searchStr = linkfSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.linkName}`.toLowerCase().includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredLFData.length > 0) {
      ListData.push("Links");
    }
  }

  if (
    filteredLFData.length === 0 &&
    linkfSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredLFData = [...PfouLinks];
  }

  let nonFilteLFData = [];
  if (linkfSearch.length > 0 && filteredLFData.length !== PfouLinks.length) {
    nonFilteLFData = [...PfouLinks];
    for (let i = 0; i < filteredLFData.length; i++) {
      nonFilteLFData = nonFilteLFData.filter((value, key) => {
        return value._id !== filteredLFData[i]._id;
      });
    }
    if (filteredLFData.length === 0) {
      nonFilteLFData = [...PfouLinks];
    }
  }

  /// Circles
  var curPefCircles = [...PfoCircles]; /// [...PfoCircles]
  // console.log(6498, curPefCircles)
  var memberInDatas = [];

  if (
    focused &&
    focused.card.length > 0 &&
    focused.card === "People" &&
    peopleSorted &&
    peopleSorted.length > 0
  ) {
    let foPeople = peopleSorted.find((p) => p._id === focused.id); //peopleSorted[focused.index];
    if (foPeople && Object.keys(foPeople).length > 0) {
      members.map((e, index) => {
        let inc = e.coreMembers.People.includes(foPeople._id);
        let incs = e.extendedMembers.People.includes(foPeople._id);
        if (inc) {
          memberInDatas.push({
            memberId: e.memberType,
            XTD: "",
            // name: `${foPeople.fname} ${foPeople.lname}` + " " + "(Person)",
            name: `Added as person`,
            cardType: "People",
            Id: foPeople._id,
          });
        }
        if (incs) {
          memberInDatas.push({
            memberId: e.memberType,
            XTD: "XTD",
            // name: `${foPeople.fname} ${foPeople.lname}` + " " + "(Person)",
            name: `Added as person`,
            cardType: "People",
            Id: foPeople._id,
          });
        }
      });
    }

    if (PfoSkills && PfoSkills.length > 0) {
      for (let i = 0; i < PfoSkills.length; i++) {
        members.map((e, index) => {
          let pfmemberdata = pfmembers.filter(
            (pfe, index) => pfe.memberType === e.memberType
          );
          // console.log('Current pfmemberdata: ', pfmemberdata);

          let isInPfmembersCore = false;
          if (
            pfmemberdata &&
            pfmemberdata.length > 0 &&
            pfmemberdata[0].coreMembers &&
            pfmemberdata[0].coreMembers.Skills
          ) {
            isInPfmembersCore =
              pfmemberdata[0].coreMembers.Skills.find(
                (skill) =>
                  skill.CardId === PfoSkills[i]._id &&
                  skill.peopleIds === focused.id
              ) !== undefined;
          }

          let inc = e.coreMembers.Skills.includes(PfoSkills[i]._id);
          if (inc && isInPfmembersCore) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "",
              name: PfoSkills[i].skillName + " " + "(Skill)",
              cardType: "Skills",
              Id: PfoSkills[i]._id,
            });
          }

          let incs = e.extendedMembers.Skills.includes(PfoSkills[i]._id);

          let isInPfmembersExt = false;
          if (
            pfmemberdata &&
            pfmemberdata.length > 0 &&
            pfmemberdata[0].extendedMembers &&
            pfmemberdata[0].extendedMembers.Skills
          ) {
            isInPfmembersExt =
              pfmemberdata[0].extendedMembers.Skills.find(
                (skill) =>
                  skill.CardId === PfoSkills[i]._id &&
                  skill.peopleIds === focused.id
              ) !== undefined;
          }

          if (incs && isInPfmembersExt) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "XTD",
              name: PfoSkills[i].skillName + " " + "(Skill)",
              cardType: "Skills",
              Id: PfoSkills[i]._id,
            });
          }
        });
      }
    }

    if (PfoRoles && PfoRoles.length > 0) {
      for (let i = 0; i < PfoRoles.length; i++) {
        members.map((e, index) => {
          let pfmemberdata = pfmembers.filter(
            (pfe, index) => pfe.memberType === e.memberType
          );
          // console.log('Current pfmemberdata: ', pfmemberdata);

          let inc = e.coreMembers.Roles.includes(PfoRoles[i]._id);

          let isInPfmembersCore = false;
          if (
            pfmemberdata &&
            pfmemberdata.length > 0 &&
            pfmemberdata[0].coreMembers &&
            pfmemberdata[0].coreMembers.Roles
          ) {
            isInPfmembersCore =
              pfmemberdata[0].coreMembers.Roles.find(
                (role) =>
                  role.CardId === PfoRoles[i]._id &&
                  role.peopleIds === focused.id
              ) !== undefined;
          }

          if (inc && isInPfmembersCore) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "",
              name: PfoRoles[i].roleName + " " + "(Role)",
              cardType: "Roles",
              Id: PfoRoles[i]._id,
            });
          }

          let incs = e.extendedMembers.Roles.includes(PfoRoles[i]._id);

          let isInPfmembersExt = false;
          if (
            pfmemberdata &&
            pfmemberdata.length > 0 &&
            pfmemberdata[0].extendedMembers &&
            pfmemberdata[0].extendedMembers.Roles
          ) {
            isInPfmembersExt =
              pfmemberdata[0].extendedMembers.Roles.find(
                (role) =>
                  role.CardId === PfoRoles[i]._id &&
                  role.peopleIds === focused.id
              ) !== undefined;
          }
          if (incs && isInPfmembersExt) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "XTD",
              name: PfoRoles[i].roleName + " " + "(Role)",
              cardType: "Roles",
              Id: PfoRoles[i]._id,
            });
          }
        });
      }
    }

    if (PfoDomains && PfoDomains.length > 0) {
      for (let i = 0; i < PfoDomains.length; i++) {
        members.map((e, index) => {
          let inc = e.coreMembers.Domains.includes(PfoDomains[i]._id);
          let incs = e.extendedMembers.Domains.includes(PfoDomains[i]._id);
          if (inc) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "",
              name: PfoDomains[i].domainName + " " + "(Domain)",
              cardType: "Domains",
              Id: PfoDomains[i]._id,
            });
          }
          if (incs) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "XTD",
              name: PfoDomains[i].domainName + " " + "(Domain)",
              cardType: "Domains",
              Id: PfoDomains[i]._id,
            });
          }
        });
      }
    }

    if (PfoLinks && PfoLinks.length > 0) {
      for (let i = 0; i < PfoLinks.length; i++) {
        members.map((e, index) => {
          let inc = e.coreMembers.Links.includes(PfoLinks[i]._id);
          let incs = e.extendedMembers.Links.includes(PfoLinks[i]._id);
          if (inc) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "",
              name: PfoLinks[i].linkName + " " + "(Link)",
              cardType: "Links",
              Id: PfoLinks[i]._id,
            });
          }
          if (incs) {
            memberInDatas.push({
              memberId: e.memberType,
              XTD: "XTD",
              name: PfoLinks[i].linkName + " " + "(Link)",
              cardType: "Links",
              Id: PfoLinks[i]._id,
            });
          }
        });
      }
    }
  }

  var membersInDatas = [];

  if (
    focused &&
    focused.card.length > 0 &&
    memberInDatas &&
    memberInDatas.length > 0
  ) {
    memberInDatas.map((e, index) => {
      let inc = membersInDatas.includes(e.memberId);
      if (!inc) {
        membersInDatas.push(e.memberId);
      }
    });
  }

  if (
    focused &&
    focused.card.length > 0 &&
    membersInDatas &&
    membersInDatas.length > 0
  ) {
    // console.log("Current membersInDatas: ", membersInDatas);
    circlesSorted.map((e, index) => {
      if (!e) return;
      // console.log("Checking circle: ", e);
      let inc = membersInDatas.includes(e._id);
      let duplicate = false;
      if (inc) {
        // If the e._id is in memberdatas
        // If the array is empty: Insert e into array
        if (curPefCircles.length === 0) {
          // console.log("Inserting circle: ", e);
          curPefCircles.push(e);
        } else if (curPefCircles.length > 0) {
          for (let i = 0; i < curPefCircles.length; i++) {
            if (curPefCircles[i]._id === e._id) {
              // console.log("Duplicate found: ", curPefCircles[i]._id, e._id, curPefCircles[i]._id === e._id);
              duplicate = true; // Raise a flag to skip this circle
              break; // break the loop
            }
          }

          if (!duplicate) {
            // If e is in membersInDatas array && duplicate is false: Insert e into array
            // console.log("Inserting circle: ", e);
            curPefCircles.push(e);
          }
        }
      }
    });
  }

  let PfouCircles = [...circlesSorted];
  if (curPefCircles && curPefCircles.length > 0) {
    for (let i = 0; i < curPefCircles.length; i++) {
      PfouCircles = PfouCircles.filter((e) => {
        if (e._id !== curPefCircles[i]._id) {
          return e;
        }
      });
    }
  }

  const [circlefSearch, setCirclefSearch] = useState("");

  const fpCref = useRef(null);
  useEffect(() => {
    if (circlefSearch.length > 0) {
      fpCref.current.focus();
    }
  }, [circlefSearch]);

  var filteredCFData = [];

  if (
    (circlefSearch.length > 0 || allSearch.length > 0) &&
    PfouCircles.length > 0
  ) {
    filteredCFData = PfouCircles.filter((value) => {
      const searchStr = circlefSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.circleName}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredCFData.length > 0) {
      ListData.push("Circles");
    }
  }

  if (
    filteredCFData.length === 0 &&
    circlefSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCFData = [...PfouCircles];
    if (
      focused &&
      focused.card === "People" &&
      peopleSorted &&
      peopleSorted.length > 0
    ) {
      let foPeople = peopleSorted.find((p) => p._id === focused.id); //peopleSorted[focused.index];
      let leadeData = [];
      filteredCFData = filteredCFData.filter((value) => {
        if (value.lead && foPeople) {
          if (value.lead !== foPeople._id) {
            return value;
          } else if (value.lead === foPeople._id) {
            leadeData.push(value);
          }
        } else if (!value.lead) {
          return value;
        }
      });
      if (leadeData && leadeData.length > 0) {
        curPefCircles = [...leadeData, ...filteredCFData];
      }
    }
  }

  let nonFilteCFData = [];
  if (
    circlefSearch.length > 0 &&
    filteredCFData.length !== PfouCircles.length
  ) {
    nonFilteCFData = [...PfouCircles];
    for (let i = 0; i < filteredCFData.length; i++) {
      nonFilteCFData = nonFilteCFData.filter((value, key) => {
        return value._id !== filteredCFData[i]._id;
      });
    }
    if (filteredCFData.length === 0) {
      nonFilteCFData = [...PfouCircles];
    }
  }

  const cardOpens = (datas) => {
    if (datas.cardType === "People") {
      peopleSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openPeople();
        }
      });
    } else if (datas.cardType === "Skills") {
      skillsSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openSkill();
        }
      });
    } else if (datas.cardType === "Roles") {
      rolesSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openRole();
        }
      });
    } else if (datas.cardType === "Links") {
      linksSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openLink();
        }
      });
    } else if (datas.cardType === "Domains") {
      domainsSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openDomain();
        }
      });
    }
  };

  // Edit detailsModel for people focused circle and project both
  const pfcircleOrProjectModes = (e, fCard) => {
    HandelCAndP(e, fCard);
    openPeopleModeTool();

    if (focused && focused.card.length > 0 && focused.card === "People") {
      let focusedPeople = focused.id;
      localStorage.setItem("fpData", focusedPeople);
      localStorage.setItem("pfCAndPrCData", JSON.stringify(e));
    }
  };

  const pFCcARDSub = (elements, popoverId, type) => {
    // console.log('pFCcARDSub');
    if (type === "core" && popoverId && popoverId.coreMembers) {
      if (
        elements.cardType === "People" &&
        popoverId.coreMembers.People &&
        popoverId.coreMembers.People.length > 0
      ) {
        popoverId.coreMembers.People = popoverId.coreMembers.People.filter(
          (e) => {
            return e !== elements.Id;
          }
        );
      } else if (
        elements.cardType === "Skills" &&
        popoverId.coreMembers.Skills &&
        popoverId.coreMembers.Skills.length > 0
      ) {
        popoverId.coreMembers.Skills = popoverId.coreMembers.Skills.filter(
          (e) => {
            return e !== elements.Id;
          }
        );
      } else if (
        elements.cardType === "Roles" &&
        popoverId.coreMembers.Roles &&
        popoverId.coreMembers.Roles.length > 0
      ) {
        popoverId.coreMembers.Roles = popoverId.coreMembers.Roles.filter(
          (e) => {
            return e !== elements.Id;
          }
        );
      } else if (
        elements.cardType === "Domains" &&
        popoverId.coreMembers.Domains &&
        popoverId.coreMembers.Domains.length > 0
      ) {
        popoverId.coreMembers.Domains = popoverId.coreMembers.Domains.filter(
          (e) => {
            return e !== elements.Id;
          }
        );
      } else if (
        elements.cardType === "Links" &&
        popoverId.coreMembers.Links &&
        popoverId.coreMembers.Links.length > 0
      ) {
        popoverId.coreMembers.Links = popoverId.coreMembers.Links.filter(
          (e) => {
            return e !== elements.Id;
          }
        );
      } else if (
        elements.cardType === "Circles" &&
        popoverId.coreMembers.Circles &&
        popoverId.coreMembers.Circles.length > 0
      ) {
        popoverId.coreMembers.Circles = popoverId.coreMembers.Circles.filter(
          (e) => {
            return e !== elements.Id;
          }
        );
      } else if (
        elements.cardType === "Projects" &&
        popoverId.coreMembers.Projects &&
        popoverId.coreMembers.Projects.length > 0
      ) {
        popoverId.coreMembers.Projects = popoverId.coreMembers.Projects.filter(
          (e) => {
            return e !== elements.Id;
          }
        );
      }
    } else if (type === "extend" && popoverId && popoverId.extendedMembers) {
      if (
        elements.cardType === "People" &&
        popoverId.extendedMembers.People &&
        popoverId.extendedMembers.People.length > 0
      ) {
        popoverId.extendedMembers.People =
          popoverId.extendedMembers.People.filter((e) => {
            return e !== elements.Id;
          });
      } else if (
        elements.cardType === "Skills" &&
        popoverId.extendedMembers.Skills &&
        popoverId.extendedMembers.Skills.length > 0
      ) {
        popoverId.extendedMembers.Skills =
          popoverId.extendedMembers.Skills.filter((e) => {
            return e !== elements.Id;
          });
      } else if (
        elements.cardType === "Roles" &&
        popoverId.extendedMembers.Roles &&
        popoverId.extendedMembers.Roles.length > 0
      ) {
        popoverId.extendedMembers.Roles =
          popoverId.extendedMembers.Roles.filter((e) => {
            return e !== elements.Id;
          });
      } else if (
        elements.cardType === "Domains" &&
        popoverId.extendedMembers.Domains &&
        popoverId.extendedMembers.Domains.length > 0
      ) {
        popoverId.extendedMembers.Domains =
          popoverId.extendedMembers.Domains.filter((e) => {
            return e !== elements.Id;
          });
      } else if (
        elements.cardType === "Links" &&
        popoverId.extendedMembers.Links &&
        popoverId.extendedMembers.Links.length > 0
      ) {
        popoverId.extendedMembers.Links =
          popoverId.extendedMembers.Links.filter((e) => {
            return e !== elements.Id;
          });
      } else if (
        elements.cardType === "Circles" &&
        popoverId.extendedMembers.Circles &&
        popoverId.extendedMembers.Circles.length > 0
      ) {
        popoverId.extendedMembers.Circles =
          popoverId.extendedMembers.Circles.filter((e) => {
            return e !== elements.Id;
          });
      } else if (
        elements.cardType === "Projects" &&
        popoverId.extendedMembers.Projects &&
        popoverId.extendedMembers.Projects.length > 0
      ) {
        popoverId.extendedMembers.Projects =
          popoverId.extendedMembers.Projects.filter((e) => {
            return e !== elements.Id;
          });
      }
    }
    if (popoverId) {
      let updated_members = [...members];
      let updated_popoverId = { ...popoverId };

      // Find the index of the object to replace in the array
      let indexToReplace = updated_members.findIndex(
        (member) =>
          member.teamId === updated_popoverId.teamId &&
          member.memberType === updated_popoverId.memberType
      );

      // Replace the object in the array
      if (indexToReplace !== -1) {
        updated_members[indexToReplace] = updated_popoverId;
      }

      dispatch({ type: MEMBER_MODIFICATIONS, payload: updated_members });
      setTemporarilyMovedItem(null);

      popoverId.adminId = myInfo.userId;
      popoverId.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverId.teamId;
      dispatch(updateMember(popoverId)).then(() => {
        dispatch(getMember(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  const removeCircleFCoreOrExt = (elements, type) => {
    let popoverId = {};
    if (elements && elements.memberId.length > 0) {
      for (let i = 0; i < members.length; i++) {
        if (members[i].memberType === elements.memberId) {
          let arr = {
            teamId: members[i].teamId,
            memberType: members[i].memberType,
            coreMembers: {
              People: members[i].coreMembers.People,
              Skills: members[i].coreMembers.Skills,
              Roles: members[i].coreMembers.Roles,
              Links: members[i].coreMembers.Links,
              Domains: members[i].coreMembers.Domains,
              Circles: members[i].coreMembers.Circles,
              Projects: members[i].coreMembers.Projects,
            },
            extendedMembers: {
              People: members[i].extendedMembers.People,
              Skills: members[i].extendedMembers.Skills,
              Roles: members[i].extendedMembers.Roles,
              Links: members[i].extendedMembers.Links,
              Domains: members[i].extendedMembers.Domains,
              Circles: members[i].extendedMembers.Circles,
            },
          };
          if (arr && arr.teamId.length > 0) {
            popoverId = arr;
          }
          break;
        }
      }
    }
    if (
      popoverId &&
      popoverId.memberType &&
      popoverId.memberType.length > 0 &&
      elements.XTD === "XTD"
    ) {
      pFCcARDSub(elements, popoverId, "extend");
    }
    if (
      popoverId &&
      popoverId.memberType &&
      popoverId.memberType.length > 0 &&
      elements.XTD !== "XTD"
    ) {
      pFCcARDSub(elements, popoverId, "core");
    }
  };

  const pfCicleLead = (data) => {
    if (focused && focused.card.length > 0 && focused.card === "People") {
      let flag = false;
      if (data.lead && data.lead.length > 0) {
        let objectId = peopleSorted[focused.index] ? focused.id : null;
        if (objectId === data.lead) {
          // flag = true;
          flag = "direct";
        } else {
          if (skillsSorted && skillsSorted.length > 0 && !flag) {
            for (let i = 0; i < skillsSorted.length; i++) {
              if (
                skillsSorted[i]._id === data.lead &&
                skillsSorted[i].owners &&
                skillsSorted[i].owners.length > 0 &&
                skillsSorted[i].owners.includes(objectId)
              ) {
                // flag = true;
                flag = "indirect";
                break;
              }
            }
          }
          if (rolesSorted && rolesSorted.length > 0 && !flag) {
            for (let i = 0; i < rolesSorted.length; i++) {
              if (
                rolesSorted[i]._id === data.lead &&
                rolesSorted[i].owners &&
                rolesSorted[i].owners.length > 0 &&
                rolesSorted[i].owners.includes(objectId)
              ) {
                // flag = true;
                flag = "indirect";
                break;
              }
            }
          }
        }
      }
      return flag;
    }
  };

  /// Projects
  var curPefProjects = [...PfoProjects]; // [...PfoProjects]

  if (
    focused &&
    focused.card.length > 0 &&
    membersInDatas &&
    membersInDatas.length > 0
  ) {
    // console.log("Current membersInDatas: ", membersInDatas);
    projectsSorted.map((e, index) => {
      if (!e) return;
      // console.log("Checking project: ", e);
      let inc = membersInDatas.includes(e._id);
      let duplicate = false;
      if (inc) {
        // If the e._id is in memberdatas
        // If the array is empty: Insert e into array
        if (curPefProjects.length === 0) {
          // console.log("Inserting project: ", e);
          curPefProjects.push(e);
        } else if (curPefProjects.length > 0) {
          for (let i = 0; i < curPefProjects.length; i++) {
            if (curPefProjects[i]._id === e._id) {
              // console.log("Duplicate found: ", curPefProjects[i]._id, e._id, curPefProjects[i]._id === e._id);
              duplicate = true; // Raise a flag to skip this project
              break; // break the loop
            }
          }

          if (!duplicate) {
            // If e is in membersInDatas array && duplicate is false: Insert e into array
            // console.log("Inserting project: ", e);
            curPefProjects.push(e);
          }
        }
      }
    });
  }

  let PfouProjects = [...projectsSorted];
  if (curPefProjects && curPefProjects.length > 0) {
    PfouProjects = PfouProjects.filter((e) => {
      if (!e) return;

      let flag = true;
      for (let i = 0; i < curPefProjects.length; i++) {
        if (e._id === curPefProjects[i]._id) {
          flag = false;
        }
      }
      if (flag) {
        return e;
      }
    });
  }

  const [projectsfSearch, setProjectsfSearch] = useState("");

  const fpPrref = useRef(null);
  useEffect(() => {
    if (projectsfSearch.length > 0) {
      fpPrref.current.focus();
    }
  }, [projectsfSearch]);

  var filteredPrFData = [];

  if (
    (projectsfSearch.length > 0 || allSearch.length > 0) &&
    PfouProjects.length > 0
  ) {
    filteredPrFData = PfouProjects.filter((value) => {
      const searchStr =
        projectsfSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.projectName}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredPrFData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Projects");
    }
  }

  if (
    filteredPrFData.length === 0 &&
    projectsfSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPrFData = [...PfouProjects];
    if (
      focused &&
      focused.card === "People" &&
      peopleSorted &&
      peopleSorted.length > 0
    ) {
      let foPeople = peopleSorted.find((p) => p._id === focused.id); //peopleSorted[focused.index];
      let leadeData = [];
      filteredPrFData = filteredPrFData.filter((value) => {
        if (value.lead && foPeople) {
          if (value.lead !== foPeople._id) {
            return value;
          } else if (value.lead === foPeople._id) {
            leadeData.push(value);
          }
        } else {
          return value;
        }
      });
      if (leadeData && leadeData.length > 0) {
        curPefProjects = [...leadeData, ...filteredPrFData];
      }
    }
  }

  let nonFiltePrFData = [];
  if (
    projectsfSearch.length > 0 &&
    filteredPrFData.length !== PfouProjects.length
  ) {
    nonFiltePrFData = [...projectsSorted];
    nonFiltePrFData = nonFiltePrFData.filter((value, key) => {
      let flag = true;
      for (let i = 0; i < filteredPrFData.length; i++) {
        if (value._id === filteredPrFData[i]._id) {
          flag = false;
          break;
        }
      }
      if (flag) {
        return value;
      }
    });
    if (filteredPrFData.length === 0) {
      nonFiltePrFData = [...projectsSorted];
    }
  }

  const pfProjectLead = (data) => {
    if (focused && focused.card.length > 0 && focused.card === "People") {
      let flag = false;
      if (data.lead && data.lead.length > 0) {
        let objectId = focused.id;
        if (objectId === data.lead) {
          // flag = true;
          flag = "direct";
        } else {
          if (skillsSorted && skillsSorted.length > 0 && !flag) {
            for (let i = 0; i < skillsSorted.length; i++) {
              if (
                skillsSorted[i]._id === data.lead &&
                skillsSorted[i].owners &&
                skillsSorted[i].owners.length > 0 &&
                skillsSorted[i].owners.includes(objectId)
              ) {
                // flag = true;
                flag = "indirect";
                break;
              }
            }
          }
          if (rolesSorted && rolesSorted.length > 0 && !flag) {
            for (let i = 0; i < rolesSorted.length; i++) {
              if (
                rolesSorted[i]._id === data.lead &&
                rolesSorted[i].owners &&
                rolesSorted[i].owners.length > 0 &&
                rolesSorted[i].owners.includes(objectId)
              ) {
                // flag = true;
                flag = "indirect";
                break;
              }
            }
          }
        }
      }
      return flag;
    }
  };

  const isSkillRoleLead = (data, cardType) => {
    if (focused && focused.card.length > 0 && focused.card === "People") {
      let focusedPerson = focused.id;
      let allCircles = dCircles
        .map((dc) => {
          return dc;
        })
        .concat(
          curPefCircles.map((c) => {
            return c;
          })
        );
      // console.log(allCircles);
      let flag = false;
      if (allCircles && allCircles.length > 0) {
        allCircles.map((thisCircle) => {
          if (
            thisCircle.lead &&
            thisCircle.lead === data._id &&
            thisCircle.lead === data._id &&
            data.owners.includes(focusedPerson)
          ) {
            flag = true;
            // console.log(thisCircle.circleName, thisCircle.lead, flag)
          }
        });
      }

      return flag;
    }
  };

  const getAdminTasks = (data) => {
    let adminTasks = [];
    if (focused && focused.card.length > 0 && focused.card === "People") {
      let focusedPerson = peopleSorted[focused.index] ? focused.id : null;
      adminTasks = data.administration.filter(
        (admin) => admin.owner === focusedPerson
      );
    }
    return adminTasks;
  };

  //======================================PEOPLE FOCUS MODE======================================================

  const handleDragEndPF = (result) => {
    if (!result.draggableId || !result.source || !result.destination) {
      unfreezeWindow(false);
      return false;
    }

    if (
      result.source.droppableId &&
      result.destination.droppableId &&
      result.source.droppableId === result.destination.droppableId
    ) {
      unfreezeWindow(false);
      return false;
    }

    // console.log(result);
    // console.log(`Dropped in ${result.destination.droppableId}: `);

    /* result.draggableId // - ID of the dragged item
    result.source.index // - source index of the dragged item
    result.destination.index // - destination index of the dragged item */

    if (result.source.droppableId.includes("skills")) {
      let skillDropped = skillsSorted.find(
        (item) => item._id === result.draggableId
      );
      if (result.destination.droppableId === "skills-core") {
        // console.log(skillDropped);
        PSkillAdd(skillDropped);
      } else if (result.destination.droppableId === "skills-notmatched") {
        // console.log(skillDropped);
        PSkillSub(skillDropped);
      }
    }

    if (result.source.droppableId.includes("roles")) {
      let roleDropped = rolesSorted.find(
        (item) => item._id === result.draggableId
      );
      if (result.destination.droppableId === "roles-core") {
        // console.log(roleDropped);
        PRolesAdd(roleDropped);
      } else if (result.destination.droppableId === "roles-notmatched") {
        // console.log(roleDropped);
        PRolesSub(roleDropped);
      }
    }

    if (result.source.droppableId.includes("domains")) {
      let domainDropped = domainsSorted.find(
        (item) => item._id === result.draggableId
      );
      if (result.destination.droppableId === "domains-core") {
        // console.log(domainDropped);
        PDomainsAdd(domainDropped);
      } else if (result.destination.droppableId === "domains-notmatched") {
        // console.log(domainDropped);
        PDomainsSub(domainDropped);
      }
    }

    if (result.source.droppableId.includes("links")) {
      let linkDropped = linksSorted.find(
        (item) => item._id === result.draggableId
      );
      if (result.destination.droppableId === "links-core") {
        // console.log(linkDropped);
        PLinksAdd(linkDropped);
      } else if (result.destination.droppableId === "links-notmatched") {
        // console.log(linkDropped);
        PLinksSub(linkDropped);
      }
    }

    if (result.source.droppableId.includes("circles")) {
      let circleDropped = circlesSorted.find(
        (item) => item._id === result.draggableId
      );
      if (result.destination.droppableId === "circles-core") {
        // console.log(circleDropped);
        setTemporarilyMovedItem({ cardType: "Circle", card: circleDropped });
        pfcircleOrProjectModes(circleDropped, "Circles");
      } else if (result.destination.droppableId === "circles-notmatched") {
        // console.log(circleDropped);

        let MemData = [];
        for (let i = 0; i < memberInDatas.length; i++) {
          if (memberInDatas[i].memberId === circleDropped._id) {
            MemData.push(memberInDatas[i]);
          }
        }
        let flag = false;
        if (focused && focused.card.length > 0 && focused.card === "People") {
          flag = pfCicleLead(circleDropped);
        }
        let isAddAsP = false;
        if (MemData && MemData.length === 1) {
          if (MemData[0].cardType === "People") {
            isAddAsP = true;
          }
        }
        let curpOIndex = null;
        if (MemData && MemData.length > 0) {
          for (let i = 0; i < MemData.length; i++) {
            if (MemData[i].cardType == "People") {
              curpOIndex = i;
              break;
            }
          }
        }

        if (
          (MemData.length === 1 || isAddAsP || curpOIndex !== null) &&
          (!flag || isAddAsP)
        ) {
          removeCircleFCoreOrExt(MemData[curpOIndex !== null ? curpOIndex : 0]);
        }
      }
    }

    if (result.source.droppableId.includes("projects")) {
      let projectDropped = projectsSorted.find(
        (item) => item._id === result.draggableId
      );
      if (result.destination.droppableId === "projects-core") {
        // console.log(projectDropped);
        setTemporarilyMovedItem({ cardType: "Project", card: projectDropped });
        pfcircleOrProjectModes(projectDropped, "Projects");
      } else if (result.destination.droppableId === "projects-notmatched") {
        // console.log(projectDropped);

        let MemData = [];
        for (let i = 0; i < memberInDatas.length; i++) {
          if (memberInDatas[i].memberId === projectDropped._id) {
            MemData.push(memberInDatas[i]);
          }
        }
        let flag = false;
        if (focused && focused.card.length > 0 && focused.card === "People") {
          flag = pfProjectLead(projectDropped);
        }
        let isAddAsP = false;
        if (MemData && MemData.length === 1) {
          if (MemData[0].cardType === "People") {
            isAddAsP = true;
          }
        }
        let curpOIndex = null;
        if (MemData && MemData.length > 0) {
          for (let i = 0; i < MemData.length; i++) {
            if (MemData[i].cardType == "People") {
              curpOIndex = i;
              break;
            }
          }
        }

        if (
          (MemData.length === 1 || isAddAsP || curpOIndex !== null) &&
          (!flag || isAddAsP)
        ) {
          removeCircleFCoreOrExt(MemData[curpOIndex !== null ? curpOIndex : 0]);
        }
      }
    }
  };

  const FPPeople = () => {
    return (
      <div className="board-col" data-board_id="people">
        <div className="list list-bg-yellow" data-list_id="people">
          <div className="dropdown" style={{ position: "static" }}>
            <h4 className="list-title">
              <img alt="" src="images/icon-people.png" /> People ({peopleSorted.length}
              ){" "}
              <a
                className={
                  clickedOnList.isClicked &&
                  clickedOnList.listName.includes("people")
                    ? "list-menu refresh-list"
                    : "list-menu refresh-list hide"
                }
                href="#"
                onClick={() => {
                  toggleEXTnNOCards("people", "", true, "list");
                }}
              >
                <i className="fas fa-sync-alt"></i>
              </a>
              <a
                className={
                  clickedOnList.isClicked &&
                  clickedOnList.listName.includes("people")
                    ? "list-menu dropdown-toggle hide"
                    : "list-menu dropdown-toggle"
                }
                data-toggle="dropdown"
                href="#"
              >
                {getSortIcon("People")}
                <i className="fas fa-ellipsis-v"></i>
              </a>
              <ul className="dropdown-menu dropdown-menu-right" role="menu">
                <li role="presentation">
                  <a
                    onClick={() => isInfos("People")}
                    role="menuitem"
                    className="mytpt-list-info"
                  >
                    Info
                  </a>
                </li>
                <li role="presentation">
                  <a
                    role="menuitem"
                    data-toggle="modal"
                    data-target="#peopleModal"
                  >
                    Add new person
                  </a>
                </li>

                <li role="presentation">
                  <a
                    role="menuitem"
                    onClick={() => pCardHideOnchange("People")}
                    className="hide_list"
                  >
                    Hide list
                  </a>
                </li>
                <li role="presentation">
                  <a role="menuitem" style={{ paddingRight: "5px" }}>
                    Show Notes
                    <label className="switch">
                      <input
                        name="show_notes_people"
                        className="dd-switch"
                        type="checkbox"
                      />
                      <span className="slider"></span>
                    </label>
                  </a>
                </li>
                <li role="presentation" className="divider"></li>
                <li
                  role="presentation"
                  onClick={() => {
                    toggleEXTnNOCards("people", "EXT", false);
                  }}
                >
                  <a role="menuitem" className="show-external">
                    Show external people
                  </a>
                </li>
              </ul>
            </h4>
          </div>

          <div className="card-list scrollbar" ref={focusListRef}>
            <div
              className="form-group notes"
              style={note.People ? { display: "block" } : { display: "none" }}
            >
              <textarea
                className="notes"
                ref={pnoteRef}
                name="People"
                onBlur={(e) => handleSave(e)}
                placeholder="Notes"
                maxLength="100"
                autoFocus={null}
                defaultValue={noteSaveData.People}
              ></textarea>
            </div>

            {PFSotedData && PFSotedData.length > 0
              ? PFSotedData.map((e, index) => {
                  if ((isTag || isEXT || isNO) && e._id !== focused.id) {
                    let Proceed = TagWiseSorting(
                      e,
                      isTag,
                      isEXT,
                      isNO,
                      tagFilteredObj,
                      extNoFilteredObj,
                      "people"
                    );
                    if (!Proceed) return;
                  }

                  return (
                    <div
                      // onDragStart={(e) => peopleDragStart(e, index)}
                      // onDragEnter={(e) => peopleDragEnter(e, index)}
                      // onDragEnd={peopleDrop}
                      key={e._id}
                      // draggable={focused && focused.card && focused.card !== "People" ? true : false}
                      // className={`card ${focused.card === "People" && focused.index === index ? 'focused-card' : localStorage.getItem('latest-created') === e._id ? ' latest-created ' : ''}`}
                      className={`card ${
                        focused.card === "People" && focused.id === e._id
                          ? "focused-card"
                          : localStorage.getItem("latest-created") === e._id
                          ? " latest-created "
                          : ""
                      }`}
                      data-list_id="people"
                      onClick={(event) => {
                        if (
                          event.target.classList.contains("card") ||
                          event.target.classList.contains("card-edit")
                        ) {
                          setPepole(e, PFSotedData, index);
                        }

                        if (parentPeopleRef.current) {
                          const scrollTop = parentPeopleRef.current.scrollTop;
                          // console.log("Scroll position:", scrollTop);
                          setFocusedRef(scrollTop);
                        }
                      }}
                    >
                      <div className="static-relations">
                        {
                          /* (e.availability > 0) && */ workloadToggle ===
                            true && (
                            <PillComponent
                              occupied={aggrigatedPersonFTE(
                                skills,
                                [...roles, ...dRoles],
                                domains,
                                links,
                                e._id
                              )}
                              total={e.availability}
                            />
                          )
                        }
                        {e.workRole === "External" ? (
                          <span
                            onClick={() => {
                              toggleEXTnNOCards(e._id, "EXT", false, "pill");
                            }}
                            className="custom-badge blue ext-owner-pill"
                          >
                            EXT
                          </span>
                        ) : null}
                        {e.tags && e.tags.length > 0
                          ? e.tags.map((r, indexs) => (
                              <span
                                onClick={() => toggleTagCards(r)}
                                key={indexs}
                                className="custom-badge green tags"
                              >
                                {r}
                              </span>
                            ))
                          : null}
                      </div>

                      <a
                        /* onClick={() => setPepole(e, PFSotedData, index)} */ className="card-edit"
                      >{`${e.fname} ${e.lname}`}</a>
                      {focused.card === "People" && focused.id === e._id ? (
                        <a className="card-unlink" style={{ display: "block" }}>
                          <i
                            onClick={() =>
                              handelFocused({
                                card: "CPeople",
                                index: null,
                                id: null,
                              })
                            }
                            className="icon far fa-times-circle fas"
                          ></i>
                        </a>
                      ) : focused.card === "People" && focused.id !== e._id ? (
                        <a className="card-focus">
                          <img
                            onClick={() =>
                              handelFocused({
                                card: "People",
                                index: index,
                                id: e._id,
                              })
                            }
                            src="./images/newicons/focus.svg"
                          />
                        </a>
                      ) : null}
                    </div>
                  );
                })
              : null}

            <div
              className="card2"
              style={
                lFromState.Peoples ? { display: "block" } : { display: "none" }
              }
            >
              <form
                className="form-new-card"
                role="form"
                onSubmit={peopleSubmit}
              >
                <div className="form-group">
                  <input
                    type="text"
                    ref={pref}
                    name="card_name"
                    placeholder="Add first name"
                    data-mode="People focus mode"
                    className="form-control"
                    value={fName}
                    onChange={handleNameChange}
                    onKeyPress={pfNameHandel}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="form-group" style={{ marginTop: "10px" }}>
                  <button type="submit" className="btn btn-mytpt add-quick">
                    Save
                  </button>
                  <a
                    href="#"
                    type="button"
                    // onClick={() => setPepoles({ fname: fName })}
                    onClick={() => {
                      // Added by Debashis on 13-12-2023 (updated 18-12-2023)
                      let lastname = "";
                      let firstname = "";
                      if (fName.trim().indexOf(" ") >= 0) {
                        let nameArray = fName.split(" ");
                        lastname = nameArray.pop();
                        firstname = nameArray.join(" ");
                      } else {
                        lastname = "";
                        firstname = fName;
                      }
                      setFName("");
                      setPepoles({ fname: firstname, lname: lastname });
                      lFromState.Peoples = false;
                    }}
                    className="btn btn-link underline open-tool"
                  >
                    Edit details
                  </a>
                  <a
                    href="#"
                    onClick={() => peopleFrom()}
                    className="btn btn-link underline close-add-card"
                  >
                    Close
                  </a>
                </div>
              </form>
            </div>
          </div>
          <a
            className="btn-list add-card"
            onClick={() => peopleFrom()}
            data-list_id="people"
            style={
              !lFromState.Peoples ? { display: "block" } : { display: "none" }
            }
          >
            + Add new person
          </a>
        </div>
      </div>
    );
  };

  const FPSkills = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.skill &&
        tagFilteredMegedData.skill.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="skills"
    >
      <div className="list list-bg-blue" data-list_id="skills">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-skill.png" /> Skills (
            <span>{PfoSkills.length}</span>){" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("skill")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              href="#"
              onClick={() => {
                toggleEXTnNOCards("skills", "", true, "list");
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("skill")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Skills")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Skills")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#skillsModal"
                >
                  Add new skill
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Skills")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation">
                <a role="menuitem" style={{ paddingRight: "5px" }}>
                  Show Notes
                  <label className="switch">
                    <input
                      name="show_notes_people"
                      className="dd-switch"
                      type="checkbox"
                    />
                    <span className="slider"></span>
                  </label>
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("skills", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show items owned by external
                </a>
              </li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("skills", "NO", false);
                }}
              >
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEndPF}
        >
          <div className="card-list sortable-card-list scrollbar">
            <div
              className="form-group notes"
              style={note.Skills ? { display: "block" } : { display: "none" }}
            >
              <textarea
                className="notes"
                name="Skills"
                onBlur={(e) => handleSave(e)}
                placeholder="Notes"
                maxLength="100"
                defaultValue={noteSaveData.Skills}
              ></textarea>
            </div>

            <Droppable
              className="panel panel-default"
              droppableId="skills-core"
              direction="vertical"
            >
              {(skillsProvidedPf) => (
                <div
                  ref={skillsProvidedPf.innerRef}
                  {...skillsProvidedPf.droppableProps}
                  id="core"
                  className="panel-body box-container"
                >
                  {PfoSkills && PfoSkills.length > 0
                    ? PfoSkills.map((e, index) => {
                        if (isTag || isEXT || isNO) {
                          let Proceed = TagWiseSorting(
                            e,
                            isTag,
                            isEXT,
                            isNO,
                            tagFilteredObj,
                            extNoFilteredObj,
                            "skill"
                          );
                          if (!Proceed) return;
                        }
                        let isExternal = IsOwnerExternal(e, "Skill");
                        let isSRLead = isSkillRoleLead(e, "Skill");
                        // console.log(isSRLead);
                        return (
                          <Draggable
                            key={e.id}
                            draggableId={`${e._id}`}
                            index={index}
                          >
                            {(skillsProvidedPf) => (
                              <div
                                ref={skillsProvidedPf.innerRef}
                                {...skillsProvidedPf.draggableProps}
                                {...skillsProvidedPf.dragHandleProps}
                                // onDragStart={(e) => skillsDragStart(e, index)}
                                // onDragEnter={(e) => skillsDragEnter(e, index)}
                                // onDragEnd={() => skillsDrops(e, "corSkill")}
                                // draggable
                                key={e._id}
                                className="card"
                                data-list_id="skills"
                                onClick={(event) => {
                                  if (
                                    event.target.classList.contains("card") ||
                                    event.target.classList.contains("card-edit")
                                  ) {
                                    setSkill(e, PfoSkills, index);
                                  }
                                }}
                              >
                                <div className="static-relations">
                                  {workloadToggle === true && (
                                    <PillComponent
                                      occupied={sumFTEBody_Focus(
                                        e.workload,
                                        focused.id
                                      )}
                                      // total={GetPersonAvailability(peoples,focused.id)}
                                      // total={GetTotalWorkLoad(e, "SKILLS")}
                                      total={e.planTotal}
                                      type="TEXT"
                                    />
                                  )}
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {e.owners.length === 0 ||
                                  !OwnerExist(e.owners) ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  ) : null}
                                  {e.ownerType.length > 0 &&
                                  e.ownerType === "Single owner" ? (
                                    <span className="custom-badge blue-o single-owner-pill">
                                      1
                                    </span>
                                  ) : null}
                                  {isSRLead ? (
                                    <span className="custom-badge direct-lead red-o">
                                      LEAD
                                    </span>
                                  ) : null}
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={indexs}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>
                                <a
                                  /* onClick={() => setSkill(e, PfoSkills, index)} */ className="card-edit"
                                >
                                  {e.skillName} {"  "}
                                </a>
                                <a
                                  className="card-unlink"
                                  style={{ display: "block", color: "red" }}
                                >
                                  <i
                                    onClick={() => PSkillSub(e)}
                                    className="icon far fa-times-circle"
                                  ></i>
                                </a>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}
                  {skillsProvidedPf.placeholder}
                </div>
              )}
            </Droppable>

            <span className="clearable2">
              <input
                type="text"
                ref={sPSref}
                style={{ color: "black" }}
                value={skillfSearch}
                onChange={(e) => setSkillfSearch(e.target.value)}
                className="form-control search_list2 transparent"
                placeholder="Start typing to search..."
                name="searchP_list"
              />
              {skillfSearch.length > 0 ? (
                <i
                  className="clearable__clear"
                  onClick={() => setSkillfSearch("")}
                  style={{ display: "inline", color: "black" }}
                >
                  ×
                </i>
              ) : null}
            </span>

            <Droppable
              className="panel panel-default"
              droppableId="skills-notmatched"
              direction="vertical"
            >
              {(skillsProvidedPf) => (
                <div
                  ref={skillsProvidedPf.innerRef}
                  {...skillsProvidedPf.droppableProps}
                  id="notmatched"
                  className="panel-body box-container"
                >
                  {filteredSFData.length > 0
                    ? filteredSFData.map((e, index) => {
                        if (isTag || isEXT || isNO) {
                          let Proceed = TagWiseSorting(
                            e,
                            isTag,
                            isEXT,
                            isNO,
                            tagFilteredObj,
                            extNoFilteredObj,
                            "skill"
                          );
                          if (!Proceed) return;
                        }
                        let isExternal = IsOwnerExternal(e, "Skill");
                        return (
                          <Draggable
                            key={e.id}
                            draggableId={`${e._id}`}
                            index={index}
                          >
                            {(skillsProvidedPf) => (
                              <div
                                ref={skillsProvidedPf.innerRef}
                                {...skillsProvidedPf.draggableProps}
                                {...skillsProvidedPf.dragHandleProps}
                                // onDragStart={(e) => skillsDragStart(e, index)}
                                // onDragEnter={(e) => skillsDragEnter(e, index)}
                                // onDragEnd={() => skillsDrops(e, "defSkill")}
                                draggable
                                key={e._id}
                                className={`card notmatched ${
                                  localStorage.getItem("latest-created") ===
                                  e._id
                                    ? " latest-created "
                                    : ""
                                }`}
                                data-list_id="skills"
                              >
                                <div className="static-relations">
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {e.owners.length === 0 ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  ) : null}
                                  {e.ownerType.length > 0 &&
                                  e.ownerType === "Single owner" ? (
                                    <span className="custom-badge blue-o single-owner-pill">
                                      1
                                    </span>
                                  ) : null}
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={indexs}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>
                                <a
                                  onClick={() =>
                                    setSkill(e, filteredSFData, index)
                                  }
                                  className="card-edit"
                                >
                                  {e.skillName}
                                </a>
                                <a
                                  className="card-link"
                                  style={{ display: "block" }}
                                >
                                  <i
                                    onClick={() => PSkillAdd(e)}
                                    className="icon fa fa-plus-circle"
                                  ></i>
                                </a>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}
                  {skillsProvidedPf.placeholder}
                </div>
              )}
            </Droppable>

            <div
              className="card2"
              style={
                lFromState.Skills ? { display: "block" } : { display: "none" }
              }
            >
              <form
                className="form-new-card"
                role="form"
                onSubmit={skillSubmit}
              >
                <div className="form-group">
                  <input
                    type="text"
                    ref={sref}
                    name="card_name"
                    required
                    placeholder="Add skill name"
                    className="form-control"
                    value={skill}
                    onChange={handleSkillChange}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group" style={{ marginTop: "10px" }}>
                  <button type="submit" className="btn btn-mytpt add-quick">
                    Save
                  </button>
                  <a
                    href="#"
                    type="button"
                    onClick={() => {
                      setSkills({ skillName: skill });
                      setSkillName("");
                      lFromState.Skills = false;
                    }}
                    className="btn btn-link underline open-tool"
                  >
                    Edit details
                  </a>
                  <a
                    href="#"
                    onClick={() => skillsFrom()}
                    className="btn btn-link underline close-add-card"
                  >
                    Close
                  </a>
                </div>
              </form>
            </div>
          </div>
        </DragDropContext>

        <a
          className="btn-list add-card"
          onClick={() => skillsFrom()}
          data-list_id="skills"
          style={
            !lFromState.Skills ? { display: "block" } : { display: "none" }
          }
        >
          + Add new skill
        </a>
      </div>
    </div>
  );

  const FPRoles = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.role &&
        tagFilteredMegedData.role.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="roles"
    >
      <div className="list list-bg-blue" data-list_id="roles">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-role.png" /> Roles (
            <span>{PfoRoles.length}</span>){" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("role")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              href="#"
              onClick={() => {
                toggleEXTnNOCards("roles", "", true);
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("role")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Roles")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Roles")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#rolesModal"
                >
                  Add new role
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Roles")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation">
                <a role="menuitem" style={{ paddingRight: "5px" }}>
                  Show Notes
                  <label className="switch">
                    <input
                      name="show_notes_people"
                      className="dd-switch"
                      type="checkbox"
                    />
                    <span className="slider"></span>
                  </label>
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("roles", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show items owned by external
                </a>
              </li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("roles", "NO", false);
                }}
              >
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEndPF}
        >
          <div className="card-list sortable-card-list scrollbar">
            <div
              className="form-group notes"
              style={note.Roles ? { display: "block" } : { display: "none" }}
            >
              <textarea
                className="notes"
                name="Roles"
                // defaultValue=''
                // value={noteSaveData.Roles}
                // onChange={(e) => handleSave(e)}
                onBlur={(e) => handleSave(e)}
                placeholder="Notes"
                maxLength="100"
                defaultValue={noteSaveData.Roles}
              ></textarea>
            </div>

            <Droppable
              className="panel panel-default"
              droppableId="roles-core"
              direction="vertical"
            >
              {(rolesProvidedPf) => (
                <div
                  ref={rolesProvidedPf.innerRef}
                  {...rolesProvidedPf.droppableProps}
                  id="core"
                  className="panel-body box-container"
                >
                  {PfoRoles && PfoRoles.length > 0
                    ? PfoRoles.map((e, index) => {
                        if (isTag || isEXT || isNO) {
                          let Proceed = TagWiseSorting(
                            e,
                            isTag,
                            isEXT,
                            isNO,
                            tagFilteredObj,
                            extNoFilteredObj,
                            "role"
                          );
                          if (!Proceed) return;
                        }
                        let isExternal = IsOwnerExternal(e, "Role");
                        let isSRLead = isSkillRoleLead(e, "Role");
                        return (
                          <Draggable
                            key={e.id}
                            draggableId={`${e._id}`}
                            index={index}
                          >
                            {(rolesProvidedPf) => (
                              <div
                                ref={rolesProvidedPf.innerRef}
                                {...rolesProvidedPf.draggableProps}
                                {...rolesProvidedPf.dragHandleProps}
                                // onDragStart={(e) => rolesDragStart(e, index)}
                                // onDragEnter={(e) => rolesDragEnter(e, index)}
                                // onDragEnd={() => rolesDrops(e, "corRoles")}
                                // draggable
                                key={e._id}
                                className="card"
                                data-list_id="roles"
                                onClick={(event) => {
                                  if (
                                    event.target.classList.contains("card") ||
                                    event.target.classList.contains("card-edit")
                                  ) {
                                    setRole(e, PfoRoles, index);
                                  }
                                }}
                              >
                                <div className="static-relations">
                                  {workloadToggle === true && (
                                    <PillComponent
                                      occupied={sumFTEBody_Focus(
                                        e.workload,
                                        focused.id
                                      )}
                                      // total={GetPersonAvailability(peoples,focused.id)}
                                      // total={GetTotalWorkLoad(e, "ROLES")}
                                      total={e.planTotal}
                                      type="TEXT"
                                    />
                                  )}
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {e.owners.length === 0 ||
                                  !OwnerExist(e.owners) ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  ) : null}
                                  {e.ownerRole.length > 0 &&
                                  e.ownerRole === "Single owner" ? (
                                    <span className="custom-badge blue-o single-owner-pill">
                                      1
                                    </span>
                                  ) : null}

                                  {isSRLead ? (
                                    <span className="custom-badge direct-lead red-o">
                                      LEAD
                                    </span>
                                  ) : null}

                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={indexs}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>

                                <a
                                  // onClick={() => setRole(e, PfoRoles, index)}
                                  className="card-edit"
                                >
                                  {e.roleName}
                                </a>
                                <a
                                  className="card-unlink"
                                  style={{ display: "block", color: "red" }}
                                >
                                  <i
                                    onClick={() => PRolesSub(e)}
                                    className="icon far fa-times-circle"
                                  ></i>
                                </a>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}

                  {SPFRole && SPFRole.length > 0
                    ? SPFRole.map((d, index) => {
                        return (
                          <div
                            key={index}
                            className="card default-role"
                            data-default="true"
                          >
                            <div className="static-relations">
                              {workloadToggle === true && (
                                <PillComponent
                                  occupied={sumFTEBody_Focus(
                                    d.workload,
                                    focused.id
                                  )}
                                  // total={GetPersonAvailability(peoples,focused.id)}
                                  // total={GetTotalWorkLoad(e, "ROLES")}
                                  total={d.planTotal}
                                  type="TEXT"
                                />
                              )}
                            </div>
                            <a
                              onClick={() => setRole(d, SPFRole, index)}
                              className="card-edit"
                            >
                              {d.roleName}
                            </a>
                          </div>
                        );
                      })
                    : null}

                  {rolesProvidedPf.placeholder}
                </div>
              )}
            </Droppable>

            <span className="clearable2">
              <input
                type="text"
                ref={fpRref}
                style={{ color: "black" }}
                value={rolefSearch}
                onChange={(e) => setRolefSearch(e.target.value)}
                className="form-control search_list2 transparent"
                name="search_list"
                placeholder="Search to select more"
                autoComplete="off"
              />
              {rolefSearch.length > 0 ? (
                <i
                  className="clearable__clear"
                  onClick={() => setRolefSearch("")}
                  style={{ display: "inline", color: "black" }}
                >
                  ×
                </i>
              ) : null}
            </span>

            <Droppable
              className="panel panel-default"
              droppableId="roles-notmatched"
              direction="vertical"
            >
              {(rolesProvidedPf) => (
                <div
                  ref={rolesProvidedPf.innerRef}
                  {...rolesProvidedPf.droppableProps}
                  id="notmatched"
                  className="panel-body box-container"
                >
                  {filteredRFData && filteredRFData.length > 0
                    ? filteredRFData.map((e, index) => {
                        if (isTag || isEXT || isNO) {
                          let Proceed = TagWiseSorting(
                            e,
                            isTag,
                            isEXT,
                            isNO,
                            tagFilteredObj,
                            extNoFilteredObj,
                            "role"
                          );
                          if (!Proceed) return;
                        }
                        let isExternal = IsOwnerExternal(e, "Role");
                        return (
                          <Draggable
                            key={e.id}
                            draggableId={`${e._id}`}
                            index={index}
                          >
                            {(rolesProvidedPf) => (
                              <div
                                ref={rolesProvidedPf.innerRef}
                                {...rolesProvidedPf.draggableProps}
                                {...rolesProvidedPf.dragHandleProps}
                                // onDragStart={(e) => rolesDragStart(e, index)}
                                // onDragEnter={(e) => rolesDragEnter(e, index)}
                                // onDragEnd={() => rolesDrops(e, "defRoles")}
                                // draggable
                                key={e._id}
                                className={`card notmatched ${
                                  localStorage.getItem("latest-created") ===
                                  e._id
                                    ? " latest-created "
                                    : ""
                                }`}
                                data-list_id="roles"
                              >
                                <div className="static-relations">
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {e.owners.length === 0 ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  ) : null}
                                  {e.ownerRole.length > 0 &&
                                  e.ownerRole === "Single owner" ? (
                                    <span className="custom-badge blue-o single-owner-pill">
                                      1
                                    </span>
                                  ) : null}
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={indexs}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>

                                <a
                                  onClick={() =>
                                    setRole(e, filteredRFData, index)
                                  }
                                  className="card-edit"
                                >
                                  {e.roleName}
                                </a>

                                <a
                                  className="card-link"
                                  style={{ display: "block" }}
                                >
                                  <i
                                    onClick={() => PRolesAdd(e)}
                                    className="icon fa fa-plus-circle"
                                  ></i>
                                </a>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}

                  {DPFRole && DPFRole.length > 0
                    ? DPFRole.map((d, index) => {
                        return (
                          <div
                            key={index}
                            className="card default-role"
                            data-card_name="Circle Lead"
                            data-default="true"
                            style={{ display: "none" }}
                          >
                            <a
                              onClick={() => setRole(d, DPFRole)}
                              className="card-edit"
                            >
                              {d.roleName}
                            </a>
                          </div>
                        );
                      })
                    : null}

                  {rolesProvidedPf.placeholder}
                </div>
              )}
            </Droppable>

            <div
              className="card2"
              style={
                lFromState.Roles ? { display: "block" } : { display: "none" }
              }
            >
              <form className="form-new-card" role="form" onSubmit={roleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    ref={rref}
                    name="card_name"
                    required
                    placeholder="Add role name"
                    className="form-control"
                    value={role}
                    onChange={handleRoleChange}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group" style={{ marginTop: "10px" }}>
                  <button type="submit" className="btn btn-mytpt add-quick">
                    Save
                  </button>
                  <a
                    href="#"
                    type="button"
                    onClick={() => {
                      setRoles({ roleName: role });
                      setRoleName("");
                      lFromState.Roles = false;
                    }}
                    className="btn btn-link underline open-tool"
                  >
                    Edit details
                  </a>
                  <a
                    href="#"
                    onClick={() => rolesFrom()}
                    className="btn btn-link underline close-add-card"
                  >
                    Close
                  </a>
                </div>
              </form>
            </div>
          </div>
        </DragDropContext>
        <a
          className="btn-list add-card"
          onClick={() => rolesFrom()}
          data-list_id="roles"
          style={!lFromState.Roles ? { display: "block" } : { display: "none" }}
        >
          + Add new role
        </a>
      </div>
    </div>
  );

  const FPDomains = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.domain &&
        tagFilteredMegedData.domain.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="domains"
    >
      <div className="list list-bg-blue" data-list_id="domains">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-domain.png" /> Domains (
            <span>{PfoDomains.length}</span>){" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("domain")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              href="#"
              onClick={() => {
                toggleEXTnNOCards("domains", "", true, "list");
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("domain")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Domains")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Domains")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#domainModal"
                >
                  Add new domain
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Domains")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation">
                <a role="menuitem" style={{ paddingRight: "5px" }}>
                  Show Notes
                  <label className="switch">
                    <input
                      name="show_notes_people"
                      className="dd-switch"
                      type="checkbox"
                    />
                    <span className="slider"></span>
                  </label>
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("domains", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show items owned by external
                </a>
              </li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("domains", "NO", false);
                }}
              >
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEndPF}
        >
          <div className="card-list scrollbar">
            <div
              className="form-group notes"
              style={note.Domains ? { display: "block" } : { display: "none" }}
            >
              <textarea
                className="notes"
                name="Domains"
                // defaultValue=''
                // value={noteSaveData.Domains}
                //   onChange={(e) => handleSave(e)}
                onBlur={(e) => handleSave(e)}
                placeholder="Notes"
                maxLength="100"
                defaultValue={noteSaveData.Domains}
              ></textarea>
            </div>

            <Droppable
              className="panel panel-default"
              droppableId="domains-core"
              direction="vertical"
            >
              {(domainsProvidedPf) => (
                <div
                  ref={domainsProvidedPf.innerRef}
                  {...domainsProvidedPf.droppableProps}
                  id="core"
                  className="panel-body box-container"
                >
                  {/* members listed here*/}
                  {PfoDomains && PfoDomains.length > 0
                    ? PfoDomains.map((e, index) => {
                        if (isTag || isEXT || isNO) {
                          let Proceed = TagWiseSorting(
                            e,
                            isTag,
                            isEXT,
                            isNO,
                            tagFilteredObj,
                            extNoFilteredObj,
                            "domain"
                          );
                          if (!Proceed) return;
                        }
                        let isExternal = IsOwnerExternal(e, "Domain");
                        let whoIsOwnerType = dWhoIsOwnerType(e);
                        let isDirectMember = dIsDirectMember(e);
                        let flag = false;
                        if (
                          e &&
                          e.standIn &&
                          e.standIn.length > 0 &&
                          peopleSorted.length > 0 &&
                          focused &&
                          focused.card &&
                          focused.card.length > 0
                        ) {
                          if (e.standIn === focused.id) {
                            flag = true;
                          }
                        }
                        return (
                          <Draggable
                            key={e.id}
                            draggableId={`${e._id}`}
                            index={index}
                          >
                            {(domainsProvidedPf) => (
                              <div
                                ref={domainsProvidedPf.innerRef}
                                {...domainsProvidedPf.draggableProps}
                                {...domainsProvidedPf.dragHandleProps}
                                // onDragStart={(e) => domainsDragStart(e, index)}
                                // onDragEnter={(e) => domainsDragEnter(e, index)}
                                // onDragEnd={() => domainsDrops(e, "corDomains")}
                                // draggable
                                key={e._id}
                                className="card"
                                data-list_id="domain"
                                onClick={(event) => {
                                  if (
                                    event.target.classList.contains("card") ||
                                    event.target.classList.contains("card-edit")
                                  ) {
                                    setDomain(e, PfoDomains, index);
                                  }
                                }}
                              >
                                <div className="static-relations">
                                  {workloadToggle === true && (
                                    <PillComponent
                                      occupied={sumFTEBody_Focus(
                                        e.workload,
                                        focused.id
                                      )}
                                      // total={GetPersonAvailability(peoples,focused.id)}
                                      // total={GetTotalWorkLoad(e, "DOMAINS")}
                                      total={e.planTotal}
                                      type="TEXT"
                                    />
                                  )}

                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}

                                  {whoIsOwnerType &&
                                  Object.keys(whoIsOwnerType).length > 0 ? (
                                    <span
                                      onClick={() =>
                                        cardOpensCfDomain(whoIsOwnerType)
                                      }
                                      className="custom-badge lightgray"
                                    >
                                      {whoIsOwnerType.name}
                                    </span>
                                  ) : null}

                                  {e.owners &&
                                  e.owners.owner &&
                                  e.owners.owner.length > 0 ? null : (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  )}

                                  {flag ? (
                                    <span className="custom-badge gray-o">
                                      STAND IN
                                    </span>
                                  ) : null}

                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={indexs}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>

                                <a
                                  /* onClick={() => setDomain(e, PfoDomains, index)} */ className="card-edit"
                                >
                                  {e.domainName}
                                </a>

                                {isDirectMember && !flag ? (
                                  <a
                                    className="card-unlink"
                                    style={{ display: "block", color: "red" }}
                                  >
                                    <i
                                      onClick={() => PDomainsSub(e)}
                                      className="icon far fa-times-circle"
                                    ></i>
                                  </a>
                                ) : null}
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}
                  {domainsProvidedPf.placeholder}
                </div>
              )}
            </Droppable>

            <span className="clearable2">
              <input
                type="text"
                ref={fpDref}
                style={{ color: "black" }}
                value={domainfSearch}
                onChange={(e) => setDomainfSearch(e.target.value)}
                className="form-control search_list2 transparent"
                name="search_list"
                placeholder="Search to select more"
                autoComplete="off"
              />
              {domainfSearch.length > 0 ? (
                <i
                  className="clearable__clear"
                  onClick={() => setDomainfSearch("")}
                  style={{ display: "inline", color: "black" }}
                >
                  ×
                </i>
              ) : null}
            </span>

            <Droppable
              className="panel panel-default"
              droppableId="domains-notmatched"
              direction="vertical"
            >
              {(domainsProvidedPf) => (
                <div
                  ref={domainsProvidedPf.innerRef}
                  {...domainsProvidedPf.droppableProps}
                  id="notmatched"
                  className="panel-body box-container"
                >
                  {filteredDFData && filteredDFData.length > 0
                    ? filteredDFData.map((e, index) => {
                        if (isTag || isEXT || isNO) {
                          let Proceed = TagWiseSorting(
                            e,
                            isTag,
                            isEXT,
                            isNO,
                            tagFilteredObj,
                            extNoFilteredObj,
                            "domain"
                          );
                          if (!Proceed) return;
                        }
                        let isExternal = IsOwnerExternal(e, "Domain");
                        let whoIsOwnerType = dWhoIsOwnerType(e);
                        return (
                          <Draggable
                            key={e.id}
                            draggableId={`${e._id}`}
                            index={index}
                            isDragDisabled={e.owners.type ? true : false}
                          >
                            {(domainsProvidedPf) => (
                              <div
                                ref={domainsProvidedPf.innerRef}
                                {...domainsProvidedPf.draggableProps}
                                {...domainsProvidedPf.dragHandleProps}
                                // onDragStart={(e) => domainsDragStart(e, index)}
                                // onDragEnter={(e) => domainsDragEnter(e, index)}
                                // onDragEnd={() => domainsDrops(e, "defDomains")}
                                // draggable
                                key={e._id}
                                className={`card notmatched ${
                                  localStorage.getItem("latest-created") ===
                                  e._id
                                    ? " latest-created "
                                    : ""
                                }`}
                                data-list_id="domains"
                              >
                                <div className="static-relations">
                                  {whoIsOwnerType &&
                                  Object.keys(whoIsOwnerType).length > 0 ? (
                                    <span
                                      onClick={() =>
                                        cardOpensCfDomain(whoIsOwnerType)
                                      }
                                      className="custom-badge lightgray"
                                    >
                                      {whoIsOwnerType.name}
                                    </span>
                                  ) : null}
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {e.owners &&
                                  e.owners.owner &&
                                  e.owners.owner.length > 0 ? null : (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  )}
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={indexs}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>

                                <a
                                  onClick={() =>
                                    setDomain(e, filteredDFData, index)
                                  }
                                  className="card-edit"
                                >
                                  {e.domainName}
                                </a>

                                {!e.owners.type ? (
                                  <a
                                    className="card-link"
                                    style={{ display: "block" }}
                                  >
                                    <i
                                      onClick={() => PDomainsAdd(e)}
                                      className="icon fa fa-plus-circle"
                                    ></i>
                                  </a>
                                ) : null}
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}
                  {domainsProvidedPf.placeholder}
                </div>
              )}
            </Droppable>

            <div
              className="card2"
              style={
                lFromState.Domains ? { display: "block" } : { display: "none" }
              }
            >
              <form
                className="form-new-card"
                role="form"
                onSubmit={domainSubmit}
              >
                <div className="form-group">
                  <input
                    type="text"
                    ref={dref}
                    name="card_name"
                    required
                    placeholder="Add domain name"
                    className="form-control"
                    value={domain}
                    onChange={handleDomainChange}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group" style={{ marginTop: "10px" }}>
                  <button type="submit" className="btn btn-mytpt add-quick">
                    Save
                  </button>
                  <a
                    href="#"
                    type="button"
                    onClick={() => {
                      setDomains({ domainName: domain });
                      setDomainName("");
                      lFromState.Domains = false;
                    }}
                    className="btn btn-link underline open-tool"
                  >
                    Edit details
                  </a>
                  <a
                    href="#"
                    onClick={() => domainsFrom()}
                    className="btn btn-link underline close-add-card"
                  >
                    Close
                  </a>
                </div>
              </form>
            </div>
          </div>
        </DragDropContext>
        <a
          className="btn-list add-card"
          onClick={() => domainsFrom()}
          data-list_id="domain"
          style={
            !lFromState.Domains ? { display: "block" } : { display: "none" }
          }
        >
          + Add new domain
        </a>
      </div>
    </div>
  );

  const FPLinks = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.link &&
        tagFilteredMegedData.link.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="links"
    >
      <div className="list list-bg-blue" data-list_id="links">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-link.png" /> Links (
            <span>{PfoLinks.length}</span>){" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("link")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              href="#"
              onClick={() => {
                toggleEXTnNOCards("links", "", true, "list");
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("link")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Links")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#linksModal"
                >
                  Add new link
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Links")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("links", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show items owned by external
                </a>
              </li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("links", "NO", false);
                }}
              >
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEndPF}
        >
          <div className="card-list scrollbar">
            <div
              className="form-group notes"
              style={note.Links ? { display: "block" } : { display: "none" }}
            >
              <textarea
                className="notes"
                name="Links"
                onBlur={(e) => handleSave(e)}
                placeholder="Notes"
                maxLength="100"
                defaultValue={noteSaveData.Links}
              ></textarea>
            </div>

            <Droppable
              className="panel panel-default"
              droppableId="links-core"
              direction="vertical"
            >
              {(linksProvidedPf) => (
                <div
                  ref={linksProvidedPf.innerRef}
                  {...linksProvidedPf.droppableProps}
                  id="core"
                  className="panel-body box-container"
                >
                  {PfoLinks && PfoLinks.length > 0
                    ? PfoLinks.map((e, index) => {
                        if (isTag || isEXT || isNO) {
                          let Proceed = TagWiseSorting(
                            e,
                            isTag,
                            isEXT,
                            isNO,
                            tagFilteredObj,
                            extNoFilteredObj,
                            "link"
                          );
                          if (!Proceed) return;
                        }

                        let isExternal = IsOwnerExternal(e, "Link");
                        let flag = false;
                        if (
                          e &&
                          e.standIn &&
                          e.standIn.length > 0 &&
                          peopleSorted.length > 0 &&
                          focused &&
                          focused.card &&
                          focused.card.length > 0
                        ) {
                          if (e.standIn === focused.id) {
                            flag = true;
                          }
                        }
                        return (
                          <Draggable
                            key={e.id}
                            draggableId={`${e._id}`}
                            index={index}
                          >
                            {(linksProvidedPf) => (
                              <div
                                ref={linksProvidedPf.innerRef}
                                {...linksProvidedPf.draggableProps}
                                {...linksProvidedPf.dragHandleProps}
                                // onDragStart={(e) => linksDragStart(e, index)}
                                // onDragEnter={(e) => linksDragEnter(e, index)}
                                // onDragEnd={() => linksDrops(e, "corLinks")}
                                // draggable
                                key={e._id}
                                className="card "
                                data-list_id="links"
                                onClick={(event) => {
                                  if (
                                    event.target.classList.contains("card") ||
                                    event.target.classList.contains("card-edit")
                                  ) {
                                    setLink(e, PfoLinks, index);
                                  }
                                }}
                              >
                                <div className="static-relations">
                                  {workloadToggle === true && (
                                    <PillComponent
                                      occupied={sumFTEBody_Focus(
                                        e.workload,
                                        focused.id
                                      )}
                                      // total={GetPersonAvailability(peoples,focused.id)}
                                      // total={GetTotalWorkLoad(e, "LINKS")}
                                      total={e.planTotal}
                                      type="TEXT"
                                    />
                                  )}
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {e.owner && e.owner.length > 0 ? null : (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  )}
                                  {flag ? (
                                    <span className="custom-badge gray-o">
                                      STAND IN
                                    </span>
                                  ) : null}
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={indexs}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>

                                <a
                                  // onClick={() => setLink(e, PfoLinks, index)}
                                  className="card-edit"
                                >
                                  {e.linkName}
                                </a>
                                <a
                                  className="card-unlink"
                                  style={{ display: "block", color: "red" }}
                                >
                                  <i
                                    onClick={() => PLinksSub(e)}
                                    className="icon far fa-times-circle"
                                  ></i>
                                </a>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}
                  {linksProvidedPf.placeholder}
                </div>
              )}
            </Droppable>

            <span className="clearable2">
              <input
                type="text"
                ref={fpLref}
                style={{ color: "black" }}
                value={linkfSearch}
                onChange={(e) => setLinkfSearch(e.target.value)}
                className="form-control search_list2 transparent"
                name="search_list"
                placeholder="Search to select more"
                autoComplete="off"
              />
              {linkfSearch.length > 0 ? (
                <i
                  className="clearable__clear"
                  onClick={() => setLinkfSearch("")}
                  style={{ display: "inline", color: "black" }}
                >
                  ×
                </i>
              ) : null}
            </span>

            <Droppable
              className="panel panel-default"
              droppableId="links-notmatched"
              direction="vertical"
            >
              {(linksProvidedPf) => (
                <div
                  ref={linksProvidedPf.innerRef}
                  {...linksProvidedPf.droppableProps}
                  id="notmatched"
                  className="panel-body box-container"
                >
                  {filteredLFData && filteredLFData.length > 0
                    ? filteredLFData.map((e, index) => {
                        if (isTag || isEXT || isNO) {
                          let Proceed = TagWiseSorting(
                            e,
                            isTag,
                            isEXT,
                            isNO,
                            tagFilteredObj,
                            extNoFilteredObj,
                            "link"
                          );
                          if (!Proceed) return;
                        }
                        let isExternal = IsOwnerExternal(e, "Link");
                        return (
                          <Draggable
                            key={e.id}
                            draggableId={`${e._id}`}
                            index={index}
                          >
                            {(linksProvidedPf) => (
                              <div
                                ref={linksProvidedPf.innerRef}
                                {...linksProvidedPf.draggableProps}
                                {...linksProvidedPf.dragHandleProps}
                                // onDragStart={(e) => linksDragStart(e, index)}
                                // onDragEnter={(e) => linksDragEnter(e, index)}
                                // onDragEnd={() => linksDrops(e, "defLinks")}
                                // draggable
                                key={e._id}
                                className={`card notmatched ${
                                  localStorage.getItem("latest-created") ===
                                  e._id
                                    ? " latest-created "
                                    : ""
                                }`}
                                data-list_id="links"
                              >
                                <div className="static-relations">
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {e.owner && e.owner.length > 0 ? null : (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  )}
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={indexs}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>

                                <a
                                  onClick={() =>
                                    setLink(e, filteredLFData, index)
                                  }
                                  className="card-edit"
                                >
                                  {e.linkName}
                                </a>

                                <a
                                  className="card-link"
                                  style={{ display: "block" }}
                                >
                                  <i
                                    onClick={() => PLinksAdd(e)}
                                    className="icon fa fa-plus-circle"
                                  ></i>
                                </a>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}
                  {linksProvidedPf.placeholder}
                </div>
              )}
            </Droppable>

            <div
              className="card2"
              style={
                lFromState.Links ? { display: "block" } : { display: "none" }
              }
            >
              <form className="form-new-card" role="form" onSubmit={linkSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    ref={lref}
                    name="card_name"
                    required
                    placeholder="Add link name"
                    className="form-control"
                    value={link}
                    onChange={handleLinkChange}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group" style={{ marginTop: "10px" }}>
                  <button type="submit" className="btn btn-mytpt add-quick">
                    Save
                  </button>
                  <a
                    href="#"
                    type="button"
                    onClick={() => {
                      setLinks({ linkName: link });
                      setLinkName("");
                      lFromState.Links = false;
                    }}
                    className="btn btn-link underline open-tool"
                  >
                    Edit details
                  </a>
                  <a
                    href="#"
                    onClick={() => linksFrom()}
                    className="btn btn-link underline close-add-card"
                  >
                    Close
                  </a>
                </div>
              </form>
            </div>
          </div>
        </DragDropContext>
        <a
          className="btn-list add-card"
          onClick={() => linksFrom()}
          data-list_id="links"
          style={!lFromState.Links ? { display: "block" } : { display: "none" }}
        >
          + Add new link
        </a>
      </div>
    </div>
  );

  const FPCircles = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.circle &&
        tagFilteredMegedData.circle.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="circles"
    >
      <div className="list list-bg-red" data-list_id="circles">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-circle.png" /> Circles (
            <span>{curPefCircles.length}</span>){" "}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Circles")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Circles")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#circlesModal"
                  onClick={() => setCircles()}
                >
                  Add new circle
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Circles")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation">
                <a role="menuitem" style={{ paddingRight: "5px" }}>
                  Show Notes
                  <label className="switch">
                    <input
                      name="show_notes_people"
                      className="dd-switch"
                      type="checkbox"
                    />
                    <span className="slider"></span>
                  </label>
                </a>
              </li>
              <li role="presentation" className="hide">
                <a role="menuitem" className="show-external">
                  Show external people
                </a>
              </li>
              <li role="presentation" className="hide">
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEndPF}
        >
          <div className="card-list scrollbar">
            <div
              className="form-group notes"
              style={note.Circles ? { display: "block" } : { display: "none" }}
            >
              <textarea
                className="notes"
                name="Circles"
                onBlur={(e) => handleSave(e)}
                placeholder="Notes"
                maxLength="100"
                defaultValue={noteSaveData.Circles}
              ></textarea>
            </div>

            <Droppable
              className="panel panel-default"
              droppableId="circles-core"
              direction="vertical"
            >
              {(circlesProvidedPf) => (
                <div
                  ref={circlesProvidedPf.innerRef}
                  {...circlesProvidedPf.droppableProps}
                  id="core"
                  className="panel-body box-container"
                >
                  {curPefCircles && curPefCircles.length > 0
                    ? curPefCircles.map((e, index) => {
                        if (
                          isTag &&
                          Object.keys(tagFilteredMegedData).length > 0 &&
                          tagFilteredMegedData.circle &&
                          tagFilteredMegedData.circle.length > 0 &&
                          !tagFilteredMegedData.circle.includes(e._id)
                        ) {
                          return null;
                        }

                        let MemData = [];
                        for (let i = 0; i < memberInDatas.length; i++) {
                          if (memberInDatas[i].memberId === e._id) {
                            MemData.push(memberInDatas[i]);
                          }
                        }
                        let flag = false;
                        if (
                          focused &&
                          focused.card.length > 0 &&
                          focused.card === "People"
                        ) {
                          flag = pfCicleLead(e);
                        }
                        let isAddAsP = false;
                        if (MemData && MemData.length === 1) {
                          if (MemData[0].cardType === "People") {
                            isAddAsP = true;
                          }
                        }
                        let curpOIndex = null;
                        if (MemData && MemData.length > 0) {
                          for (let i = 0; i < MemData.length; i++) {
                            if (MemData[i].cardType == "People") {
                              curpOIndex = i;
                              break;
                            }
                          }
                        }

                        let adminTasks = getAdminTasks(e);

                        return (
                          <Draggable
                            key={e.id}
                            draggableId={`${e._id}`}
                            index={index}
                            isDragDisabled={
                              (MemData.length === 1 ||
                                isAddAsP ||
                                curpOIndex !== null) &&
                              (!flag || isAddAsP)
                                ? false
                                : true
                            }
                          >
                            {(circlesProvidedPf) => (
                              <div
                                ref={circlesProvidedPf.innerRef}
                                {...circlesProvidedPf.draggableProps}
                                {...circlesProvidedPf.dragHandleProps}
                                key={e._id}
                                /* {...(MemData.length === 1 || isAddAsP || curpOIndex !== null) && (!flag || isAddAsP) ? {
                          onDragStart: (e) => circlesDragStart(e, index),
                          onDragEnter: (e) => circlesDragEnter(e, index),
                          onDragEnd: () => removeCircleFCoreOrExt(MemData[curpOIndex !== null ? curpOIndex : 0])
                        } : null} */
                                // draggable={(MemData.length === 1 || isAddAsP || curpOIndex !== null) && (!flag || isAddAsP) ? true : false}
                                // className={e.defaultCircle ? "card default-circle" : "card"}
                                className={`card ${
                                  localStorage.getItem("latest-created") ===
                                  e._id
                                    ? " latest-created "
                                    : ""
                                }`}
                                data-list_id="circles"
                                onClick={(event) => {
                                  if (
                                    event.target.classList.contains("card") ||
                                    event.target.classList.contains("card-edit")
                                  ) {
                                    setCircle(e, curPefCircles, index);
                                  }
                                }}
                              >
                                <div className="static-relations">
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, indexs) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={indexs}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}

                                  {flag && flag === "direct" ? (
                                    <span className="custom-badge direct-lead red-o">
                                      LEAD
                                    </span>
                                  ) : flag && flag === "indirect" ? (
                                    <span className="custom-badge direct-lead gray-o">
                                      LEAD
                                    </span>
                                  ) : null}

                                  {MemData && MemData.length > 0
                                    ? MemData.map((m, mindex) => {
                                        // console.log(m);
                                        return (
                                          <span
                                            key={mindex}
                                            onClick={() => cardOpens(m)}
                                            className={
                                              m.cardType == "People"
                                                ? "custom-badge lightgray text-red person-pill"
                                                : "custom-badge lightgray"
                                            }
                                          >
                                            {m.XTD && m.XTD.length > 0 ? (
                                              <span className="text-red">
                                                XTD{" "}
                                              </span>
                                            ) : null}
                                            {m.name}
                                          </span>
                                        );
                                      })
                                    : null}

                                  {/* admin stats owned by the person in focus to show here */}
                                  {adminTasks && adminTasks.length > 0
                                    ? adminTasks.map((aTask, atindex) => {
                                        return (
                                          <span
                                            key={atindex}
                                            className="custom-badge green-o"
                                          >
                                            {aTask.statusReport}
                                          </span>
                                        );
                                      })
                                    : null}
                                </div>

                                <a
                                  /* onClick={() => setCircle(e, curPefCircles, index)} */ className="card-edit"
                                >
                                  {e.circleName}
                                </a>
                                <a className="card-focus">
                                  <img
                                    onClick={() => PfToCAndP("Circles", e)}
                                    src="./images/newicons/focus.svg"
                                  />
                                </a>
                                {(MemData.length === 1 ||
                                  isAddAsP ||
                                  curpOIndex !== null) &&
                                (!flag || isAddAsP) ? (
                                  <a
                                    className="card-unlink"
                                    onClick={() =>
                                      removeCircleFCoreOrExt(
                                        MemData[
                                          curpOIndex !== null ? curpOIndex : 0
                                        ]
                                      )
                                    }
                                    style={{ display: "block", color: "red" }}
                                  >
                                    <i className="icon far fa-times-circle"></i>
                                  </a>
                                ) : null}
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}

                  {dCircles && dCircles.length > 0
                    ? dCircles.map((d, index) => {
                        let flag = false;
                        if (
                          focused &&
                          focused.card.length > 0 &&
                          focused.card === "People"
                        ) {
                          flag = pfCicleLead(d);
                        }

                        let adminTasks = getAdminTasks(d);
                        return (
                          <div
                            key={index}
                            className="card default-circle"
                            data-default="true"
                          >
                            <span className="static-relations">
                              {d.tags && d.tags.length > 0
                                ? d.tags.map((r, indexs) => (
                                    <span
                                      onClick={() => toggleTagCards(r)}
                                      key={indexs}
                                      className="custom-badge green tags"
                                    >
                                      {r}
                                    </span>
                                  ))
                                : null}

                              {flag && flag === "direct" ? (
                                <span className="custom-badge direct-lead red-o">
                                  LEAD
                                </span>
                              ) : flag && flag === "indirect" ? (
                                <span className="custom-badge direct-lead gray-o">
                                  LEAD
                                </span>
                              ) : null}

                              {/* admin stats owned by the person in focus to show here */}
                              {adminTasks && adminTasks.length > 0
                                ? adminTasks.map((aTask, atindex) => {
                                    return (
                                      <span
                                        key={atindex}
                                        className="custom-badge green-o"
                                      >
                                        {aTask.statusReport}
                                      </span>
                                    );
                                  })
                                : null}
                            </span>
                            <a
                              onClick={() => setCircle(d, dCircles, index)}
                              className="card-edit"
                            >
                              {d.circleName}
                            </a>
                          </div>
                        );
                      })
                    : null}

                  {temporarilyMovedItem &&
                  temporarilyMovedItem.cardType === "Circle" &&
                  Object.keys(temporarilyMovedItem.card).length > 0
                    ? (() => {
                        let flag = false;
                        if (
                          focused &&
                          focused.card.length > 0 &&
                          focused.card === "People"
                        ) {
                          flag = pfCicleLead(temporarilyMovedItem.card); // Pass the temporarilyMovedItem to the function
                        }

                        return (
                          <div
                            key={temporarilyMovedItem.card._id}
                            className="card move-up-down"
                            style={{
                              border: "1px dashed #000",
                              opacity: "0.7",
                            }}
                          >
                            <span className="static-relations">
                              {temporarilyMovedItem.card.tags &&
                              temporarilyMovedItem.card.tags.length > 0
                                ? temporarilyMovedItem.card.tags.map(
                                    (r, indexs) => (
                                      <span
                                        onClick={() => toggleTagCards(r)}
                                        key={indexs}
                                        className="custom-badge green tags"
                                      >
                                        {r}
                                      </span>
                                    )
                                  )
                                : null}

                              {flag && flag === "direct" ? (
                                <span className="custom-badge direct-lead red-o">
                                  LEAD
                                </span>
                              ) : flag && flag === "indirect" ? (
                                <span className="custom-badge direct-lead gray-o">
                                  LEAD
                                </span>
                              ) : null}
                            </span>
                            <a className="card-edit">
                              {temporarilyMovedItem.card.circleName}
                            </a>
                          </div>
                        );
                      })()
                    : null}

                  {circlesProvidedPf.placeholder}
                </div>
              )}
            </Droppable>

            <span className="clearable2">
              <input
                type="text"
                ref={fpCref}
                style={{ color: "black" }}
                value={circlefSearch}
                onChange={(e) => setCirclefSearch(e.target.value)}
                className="form-control search_list2 transparent"
                name="search_list"
                placeholder="Search to select more"
                autoComplete="off"
              />
              {circlefSearch.length > 0 ? (
                <i
                  className="clearable__clear"
                  onClick={() => setCirclefSearch("")}
                  style={{ display: "inline", color: "black" }}
                >
                  ×
                </i>
              ) : null}
            </span>

            <Droppable
              className="panel panel-default"
              droppableId="circles-notmatched"
              direction="vertical"
            >
              {(circlesProvidedPf) => (
                <div
                  ref={circlesProvidedPf.innerRef}
                  {...circlesProvidedPf.droppableProps}
                  id="notmatched"
                  className="panel-body box-container"
                >
                  {filteredCFData && filteredCFData.length > 0
                    ? filteredCFData.map((e, index) => {
                        if (
                          isTag &&
                          Object.keys(tagFilteredMegedData).length > 0 &&
                          tagFilteredMegedData.circle &&
                          tagFilteredMegedData.circle.length > 0 &&
                          !tagFilteredMegedData.circle.includes(e._id)
                        ) {
                          return null;
                        }
                        if (
                          temporarilyMovedItem &&
                          temporarilyMovedItem.cardType === "Circle" &&
                          Object.keys(temporarilyMovedItem.card).length > 0 &&
                          temporarilyMovedItem.card._id === e._id
                        )
                          return;

                        return (
                          <Draggable
                            key={e.id}
                            draggableId={`${e._id}`}
                            index={index}
                            isDragDisabled={e.defaultCircle ? true : false}
                          >
                            {(circlesProvidedPf) => (
                              <div
                                ref={circlesProvidedPf.innerRef}
                                {...circlesProvidedPf.draggableProps}
                                {...circlesProvidedPf.dragHandleProps}
                                key={e._id}
                                // onDragStart={(e) => circlesDragStart(e, index)}
                                // onDragEnter={(e) => circlesDragEnter(e, index)}
                                // onDragEnd={() => pfcircleOrProjectModes(e, "Circles")}
                                // draggable={e.defaultCircle ? false : true}
                                className={`card notmatched ${
                                  e.defaultCircle
                                    ? " default-circle "
                                    : localStorage.getItem("latest-created") ===
                                      e._id
                                    ? " latest-created "
                                    : ""
                                }`}
                                data-list_id="circles"
                              >
                                <div className="static-relations">
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, tindex) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={tindex}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>

                                <a
                                  onClick={() =>
                                    setCircle(e, filteredCFData, index)
                                  }
                                  className="card-edit"
                                >
                                  {e.circleName}
                                </a>
                                <a
                                  className="card-link"
                                  variant="success"
                                  style={{ display: "block" }}
                                >
                                  <i
                                    onClick={() =>
                                      pfcircleOrProjectModes(e, "Circles")
                                    }
                                    className="icon fa fa-plus-circle"
                                  ></i>
                                </a>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}
                  {circlesProvidedPf.placeholder}
                </div>
              )}
            </Droppable>

            <div
              className="card2"
              style={
                lFromState.Circles ? { display: "block" } : { display: "none" }
              }
            >
              <form
                className="form-new-card"
                role="form"
                onSubmit={circleSubmit}
              >
                <div className="form-group">
                  <input
                    type="text"
                    ref={cref}
                    name="card_name"
                    required
                    placeholder="Add circle name"
                    className="form-control"
                    value={circle}
                    onChange={handleCircleChange}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group" style={{ marginTop: "10px" }}>
                  <button type="submit" className="btn btn-mytpt add-quick">
                    Save
                  </button>
                  <a
                    href="#"
                    type="button"
                    onClick={() => {
                      setCircles({ circleName: circle });
                      setCircleName("");
                      lFromState.Circles = false;
                    }}
                    className="btn btn-link underline open-tool"
                  >
                    Edit details
                  </a>
                  <a
                    href="#"
                    onClick={() => circlesFrom()}
                    className="btn btn-link underline close-add-card"
                  >
                    Close
                  </a>
                </div>
              </form>
            </div>
          </div>
        </DragDropContext>
        <a
          className="btn-list add-card"
          onClick={() => circlesFrom()}
          data-list_id="circles"
          style={
            !lFromState.Circles ? { display: "block" } : { display: "none" }
          }
        >
          + Add new circle
        </a>
      </div>
    </div>
  );

  const FPProjects = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.project &&
        tagFilteredMegedData.project.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="projects"
    >
      <div className="list list-bg-red" data-list_id="projects">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-project.png" /> Projects (
            <span>{curPefProjects.length}</span>){" "}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Projects")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Projects")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#projectsModal"
                  onClick={() => setProjects()}
                >
                  Add new project
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Projects")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation">
                <a role="menuitem" style={{ paddingRight: "5px" }}>
                  Show Notes
                  <label className="switch">
                    <input
                      name="show_notes_people"
                      className="dd-switch"
                      type="checkbox"
                    />
                    <span className="slider"></span>
                  </label>
                </a>
              </li>
              <li role="presentation" className="hide">
                <a role="menuitem" className="show-external">
                  Show external people
                </a>
              </li>
              <li role="presentation" className="hide">
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <DragDropContext
          onDragStart={handleDragStart}
          onDragEnd={handleDragEndPF}
        >
          <div className="card-list scrollbar">
            <div
              className="form-group notes"
              style={note.Projects ? { display: "block" } : { display: "none" }}
            >
              <textarea
                className="notes"
                name="Projects"
                // defaultValue=''
                // value={noteSaveData.Projects}
                //   onChange={(e) => handleSave(e)}
                onBlur={(e) => handleSave(e)}
                placeholder="Notes"
                maxLength="100"
                defaultValue={noteSaveData.Projects}
              ></textarea>
            </div>

            <Droppable
              className="panel panel-default"
              droppableId="projects-core"
              direction="vertical"
            >
              {(projectsProvidedPf) => (
                <div
                  ref={projectsProvidedPf.innerRef}
                  {...projectsProvidedPf.droppableProps}
                  id="core"
                  className="panel-body box-container"
                >
                  {curPefProjects && curPefProjects.length > 0
                    ? curPefProjects.map((e, index) => {
                        if (
                          isTag &&
                          Object.keys(tagFilteredObj).length > 0 &&
                          tagFilteredObj.project &&
                          tagFilteredObj.project.length > 0 &&
                          !tagFilteredObj.project.includes(e._id)
                        ) {
                          return null;
                        }
                        let MemData = [];
                        for (let i = 0; i < memberInDatas.length; i++) {
                          if (memberInDatas[i].memberId === e._id) {
                            MemData.push(memberInDatas[i]);
                          }
                        }
                        let flag = false;
                        if (
                          focused &&
                          focused.card.length > 0 &&
                          focused.card === "People"
                        ) {
                          flag = pfProjectLead(e);
                        }
                        let isAddAsP = false;
                        if (MemData && MemData.length === 1) {
                          if (MemData[0].cardType === "People") {
                            isAddAsP = true;
                          }
                        }
                        let curpOIndex = null;
                        if (MemData && MemData.length > 0) {
                          for (let i = 0; i < MemData.length; i++) {
                            if (MemData[i].cardType == "People") {
                              curpOIndex = i;
                              break;
                            }
                          }
                        }

                        let adminTasks = getAdminTasks(e);

                        return (
                          <Draggable
                            key={e.id}
                            draggableId={`${e._id}`}
                            index={index}
                            isDragDisabled={
                              (MemData.length === 1 ||
                                isAddAsP ||
                                curpOIndex !== null) &&
                              (!flag || isAddAsP)
                                ? false
                                : true
                            }
                          >
                            {(projectsProvidedPf) => (
                              <div
                                ref={projectsProvidedPf.innerRef}
                                {...projectsProvidedPf.draggableProps}
                                {...projectsProvidedPf.dragHandleProps}
                                // onDragStart={(e) => projectsDragStart(e, index)}
                                // onDragEnter={(e) => projectsDragEnter(e, index)}
                                // onDragEnd={projectsDrop}
                                // draggable
                                key={e._id}
                                className="card"
                                data-list_id="projects"
                                onClick={(event) => {
                                  if (
                                    event.target.classList.contains("card") ||
                                    event.target.classList.contains("card-edit")
                                  ) {
                                    setProject(e, curPefProjects, index);
                                  }
                                }}
                              >
                                <div className="static-relations">
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, tindex) => (
                                        <span
                                          onClick={() => toggleTagCards(r)}
                                          key={tindex}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}

                                  {flag && flag === "direct" ? (
                                    <span className="custom-badge direct-lead red-o">
                                      LEAD
                                    </span>
                                  ) : flag && flag === "indirect" ? (
                                    <span className="custom-badge direct-lead gray-o">
                                      LEAD
                                    </span>
                                  ) : null}
                                  {MemData && MemData.length > 0
                                    ? MemData.map((m, index) => {
                                        return (
                                          <span
                                            onClick={(e) => cardOpens(m)}
                                            className={
                                              m.cardType == "People"
                                                ? "custom-badge lightgray text-red person-pill"
                                                : "custom-badge lightgray"
                                            }
                                          >
                                            {m.XTD && m.XTD.length > 0 ? (
                                              <span className="text-red">
                                                XTD{" "}
                                              </span>
                                            ) : null}
                                            {m.name}
                                          </span>
                                        );
                                      })
                                    : null}

                                  {adminTasks && adminTasks.length > 0
                                    ? adminTasks.map((aTask, atindex) => {
                                        return (
                                          <span
                                            key={atindex}
                                            className="custom-badge green-o"
                                          >
                                            {aTask.statusReport}
                                          </span>
                                        );
                                      })
                                    : null}
                                </div>
                                <a
                                  /* onClick={() => setProject(e, curPefProjects, index)} */ className="card-edit"
                                >
                                  {e.projectName}
                                </a>
                                <a className="card-focus">
                                  <img
                                    onClick={() => PfToCAndP("Projects", e)}
                                    src="./images/newicons/focus.svg"
                                  />
                                </a>
                                {(MemData.length === 1 ||
                                  isAddAsP ||
                                  curpOIndex !== null) &&
                                (!flag || isAddAsP) ? (
                                  <a
                                    className="card-unlink"
                                    onClick={() =>
                                      removeCircleFCoreOrExt(
                                        MemData[
                                          curpOIndex !== null ? curpOIndex : 0
                                        ]
                                      )
                                    }
                                    style={{ display: "block", color: "red" }}
                                  >
                                    <i className="icon far fa-times-circle"></i>
                                  </a>
                                ) : null}
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}

                  {temporarilyMovedItem &&
                  temporarilyMovedItem.cardType === "Project" &&
                  Object.keys(temporarilyMovedItem.card).length > 0
                    ? (() => {
                        let flag = false;
                        if (
                          focused &&
                          focused.card.length > 0 &&
                          focused.card === "People"
                        ) {
                          flag = pfProjectLead(temporarilyMovedItem.card); // Pass the temporarilyMovedItem to the function
                        }

                        return (
                          <div
                            key={temporarilyMovedItem.card._id}
                            className="card move-up-down"
                            style={{
                              border: "1px dashed #000",
                              opacity: "0.7",
                            }}
                          >
                            <span className="static-relations">
                              {temporarilyMovedItem.card.tags &&
                              temporarilyMovedItem.card.tags.length > 0
                                ? temporarilyMovedItem.card.tags.map(
                                    (r, indexs) => (
                                      <span
                                        onClick={() => toggleTagCards(r)}
                                        key={indexs}
                                        className="custom-badge green tags"
                                      >
                                        {r}
                                      </span>
                                    )
                                  )
                                : null}

                              {flag && flag === "direct" ? (
                                <span className="custom-badge direct-lead red-o">
                                  LEAD
                                </span>
                              ) : flag && flag === "indirect" ? (
                                <span className="custom-badge direct-lead gray-o">
                                  LEAD
                                </span>
                              ) : null}
                            </span>
                            <a className="card-edit">
                              {temporarilyMovedItem.card.projectName}
                            </a>
                          </div>
                        );
                      })()
                    : null}

                  {projectsProvidedPf.placeholder}
                </div>
              )}
            </Droppable>

            <span className="clearable2">
              <input
                type="text"
                ref={fpPrref}
                style={{ color: "black" }}
                value={projectsfSearch}
                onChange={(e) => setProjectsfSearch(e.target.value)}
                className="form-control search_list2 transparent"
                name="search_list"
                placeholder="Search to select more"
                autoComplete="off"
              />
              {projectsfSearch.length > 0 ? (
                <i
                  className="clearable__clear"
                  onClick={() => setProjectsfSearch("")}
                  style={{ display: "inline", color: "black" }}
                >
                  ×
                </i>
              ) : null}
            </span>

            <Droppable
              className="panel panel-default"
              droppableId="projects-notmatched"
              direction="vertical"
            >
              {(projectsProvidedPf) => (
                <div
                  ref={projectsProvidedPf.innerRef}
                  {...projectsProvidedPf.droppableProps}
                  id="notmatched"
                  className="panel-body box-container"
                >
                  {filteredPrFData && filteredPrFData.length > 0
                    ? filteredPrFData.map((e, index) => {
                        if (
                          isTag &&
                          Object.keys(tagFilteredObj).length > 0 &&
                          tagFilteredObj.project &&
                          tagFilteredObj.project.length > 0 &&
                          !tagFilteredObj.project.includes(e._id)
                        ) {
                          return null;
                        }
                        if (
                          temporarilyMovedItem &&
                          temporarilyMovedItem.cardType === "Project" &&
                          temporarilyMovedItem.card._id === e._id
                        )
                          return;

                        return (
                          <Draggable
                            key={e.id}
                            draggableId={`${e._id}`}
                            index={index}
                          >
                            {(projectsProvidedPf) => (
                              <div
                                ref={projectsProvidedPf.innerRef}
                                {...projectsProvidedPf.draggableProps}
                                {...projectsProvidedPf.dragHandleProps}
                                // onDragStart={(e) => projectsDragStart(e, index)}
                                // onDragEnter={(e) => projectsDragEnter(e, index)}
                                // onDragEnd={() => pfcircleOrProjectModes(e, "Projects")}
                                // draggable
                                key={e._id}
                                className={`card notmatched ${
                                  localStorage.getItem("latest-created") ===
                                  e._id
                                    ? " latest-created "
                                    : ""
                                }`}
                                data-list_id="projects"
                              >
                                <div className="static-relations">
                                  {e.tags && e.tags.length > 0
                                    ? e.tags.map((r, tindex) => (
                                        <span
                                          key={tindex}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                </div>
                                <a
                                  onClick={() =>
                                    setProject(e, filteredPrFData, index)
                                  }
                                  className="card-edit"
                                >
                                  {e.projectName}
                                </a>
                                <a
                                  className="card-link"
                                  variant="success"
                                  style={{ display: "block" }}
                                >
                                  <i
                                    onClick={() =>
                                      pfcircleOrProjectModes(e, "Projects")
                                    }
                                    className="icon fa fa-plus-circle"
                                  ></i>
                                </a>
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    : null}
                  {projectsProvidedPf.placeholder}
                </div>
              )}
            </Droppable>

            <div
              className="card2"
              style={
                lFromState.Projects ? { display: "block" } : { display: "none" }
              }
            >
              <form
                className="form-new-card"
                role="form"
                onSubmit={projectSubmit}
              >
                <div className="form-group">
                  <input
                    type="text"
                    ref={prref}
                    name="card_name"
                    required
                    placeholder="Add project name"
                    className="form-control"
                    value={project}
                    onChange={handleProjectChange}
                    autoComplete="off"
                  />
                </div>
                <div className="form-group" style={{ marginTop: "10px" }}>
                  <button type="submit" className="btn btn-mytpt add-quick">
                    Save
                  </button>
                  <a
                    href="#"
                    type="button"
                    onClick={() => {
                      setProjects({ projectName: project });
                      setProjectName("");
                      lFromState.Projects = false;
                    }}
                    className="btn btn-link underline open-tool"
                  >
                    Edit details
                  </a>
                  <a
                    href="#"
                    onClick={() => projectsFrom()}
                    className="btn btn-link underline close-add-card"
                  >
                    Close
                  </a>
                </div>
              </form>
            </div>
          </div>
        </DragDropContext>
        <a
          className="btn-list add-card"
          onClick={() => projectsFrom()}
          data-list_id="projects"
          style={
            !lFromState.Projects ? { display: "block" } : { display: "none" }
          }
        >
          + Add new project
        </a>
      </div>
    </div>
  );

  //'''''''''''''''''''''''''''''''''''''''' Xls And Jpg Downlod ''''''''''''''''''''''''''''''''''''''''''''''''

  let peopleFXls = [];
  if (focused && focused.card.length > 0 && focused.card === "People") {
    if (
      peopleSorted[focused.index] ||
      PfoSkills.length > 0 ||
      PfoRoles.length > 0 ||
      PfoDomains.length > 0 ||
      PfoLinks.length > 0 ||
      curPefCircles.length > 0 ||
      curPefProjects.length > 0
    ) {
      let arr = [
        1,
        PfoSkills.length,
        PfoRoles.length,
        PfoDomains.length,
        PfoLinks.length,
        curPefCircles.length,
        curPefProjects.length,
      ];
      if (arr && arr.length > 0) {
        const max = arr.reduce((a, b) => {
          return Math.max(a, b);
        });

        // let xlsPeople = [peopleSorted[focused.index]];

        let xlsPeople = [peopleSorted.find((p) => p._id === focused.id)];

        for (let i = 0; i < max; i++) {
          let obj = {
            People:
              xlsPeople[i] && xlsPeople[i]._id
                ? `${xlsPeople[i].fname} ${xlsPeople[i].lname}`
                : "",
            Skill:
              PfoSkills[i] && PfoSkills[i]._id
                ? `${PfoSkills[i].skillName}`
                : "",
            Role:
              PfoRoles[i] && PfoRoles[i]._id ? `${PfoRoles[i].roleName}` : "",
            Domain:
              PfoDomains[i] && PfoDomains[i]._id
                ? `${PfoDomains[i].domainName}`
                : "",
            Link:
              PfoLinks[i] && PfoLinks[i]._id ? `${PfoLinks[i].linkName}` : "",
            Circle:
              curPefCircles[i] && curPefCircles[i]._id
                ? `${curPefCircles[i].circleName}`
                : "",
            Project:
              curPefProjects[i] && curPefProjects[i]._id
                ? `${curPefProjects[i].projectName}`
                : "",
          };

          // console.log(obj);
          peopleFXls.push(obj);
        }
      }
    }
  }

  useEffect(() => {
    if (focused && focused.card.length > 0 && focused.card === "People") {
      setXls(peopleFXls);
    }
  }, [focused]);

  //::::::::::::::::::::::::::::::::::::::::::::::Popovers For Circle And Project:::::::::::::::::::::::::::::::::::::::::::::::::::::::

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header className="popover-title">
        Add as
        <a onClick={() => setShow(!show)} className="close">
          ×
        </a>
      </Popover.Header>
      <Popover.Body>
        <div className="popover-content">
          <a
            onClick={() => cFPeopleIdHandel("Core")}
            className="pop-actions"
            id="people-core-971"
            href="#"
          >
            Core member
          </a>
          <a
            onClick={() => cFPeopleIdHandel("Extended")}
            className="pop-actions"
            id="people-extended-971"
            href="#"
          >
            Extended member
          </a>
        </div>
      </Popover.Body>
    </Popover>
  );

  //==============================================Popover Actions==============================================
  // const {members, memberMessagee, memberError, memberMessage} = useSelector(state => state.members)

  const [popoverId, setPopoverId] = useState({
    teamId: null,
    memberType: null,
    coreMembers: {
      People: [],
      Skills: [],
      Roles: [],
      Links: [],
      Domains: [],
      Circles: [],
      Projects: [],
    },
    extendedMembers: {
      People: [],
      Skills: [],
      Roles: [],
      Links: [],
      Domains: [],
      Circles: [],
    },
  });

  const [popoverIds, setPopoverIds] = useState({
    teamId: null,
    memberType: null,
    coreMembers: {
      People: [],
      Skills: [],
      Roles: [],
      Links: [],
      Domains: [],
      Circles: [],
      Projects: [],
    },
    extendedMembers: {
      People: [],
      Skills: [],
      Roles: [],
      Links: [],
      Domains: [],
      Circles: [],
    },
  });

  const [pfmemberId, setPfmemberId] = useState({});
  const [pfmemberIds, setPfmemberIds] = useState({});

  // pfmembers
  useEffect(() => {
    if (
      focused &&
      circlesSorted.length > 0 &&
      focused.card === "Circles" &&
      circlesSorted &&
      circlesSorted.length > 0
    ) {
      let currentCircle = focused.id;

      setPopoverId({
        ...popoverId,
        memberType: currentCircle,
        teamId: localStorage.getItem("teamId"),
      });

      for (let i = 0; i < members.length; i++) {
        if (members[i].memberType === currentCircle) {
          // console.log(10860, 'useEffect pfmembers', members[i]);
          let arr = {
            teamId: members[i].teamId,
            memberType: members[i].memberType,
            coreMembers: {
              People: members[i].coreMembers.People,
              Skills: members[i].coreMembers.Skills,
              Roles: members[i].coreMembers.Roles,
              Links: members[i].coreMembers.Links,
              Domains: members[i].coreMembers.Domains,
              Circles: members[i].coreMembers.Circles,
              Projects: members[i].coreMembers.Projects,
            },
            extendedMembers: {
              People: members[i].extendedMembers.People,
              Skills: members[i].extendedMembers.Skills,
              Roles: members[i].extendedMembers.Roles,
              Links: members[i].extendedMembers.Links,
              Domains: members[i].extendedMembers.Domains,
              Circles: members[i].extendedMembers.Circles,
            },
          };

          if (arr && arr.teamId.length > 0) {
            // console.log(10882, arr);
            setPopoverId(arr);
            localStorage.setItem("popoverId", JSON.stringify(arr));
          }
          break;
        }
      }

      let count = 0;
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === currentCircle) {
          let arr = {
            teamId: pfmembers[i].teamId,
            memberType: pfmembers[i].memberType,
            coreMembers: {
              Skills: pfmembers[i].coreMembers.Skills,
              Roles: pfmembers[i].coreMembers.Roles,
              Links: pfmembers[i].coreMembers.Links,
              Domains: pfmembers[i].coreMembers.Domains,
            },
            extendedMembers: {
              Skills: pfmembers[i].extendedMembers.Skills,
              Roles: pfmembers[i].extendedMembers.Roles,
              Links: pfmembers[i].extendedMembers.Links,
              Domains: pfmembers[i].extendedMembers.Domains,
            },
            allOwnersFlag: pfmembers[i].allOwnersFlag,
          };
          if (arr && arr.teamId.length > 0) {
            setPfmemberId(arr);
            localStorage.setItem("pfmemberId", JSON.stringify(arr));
          }
          break;
        }
        count++;
      }

      if (
        count === pfmembers.length &&
        !JSON.parse(localStorage.getItem("pfmemberId"))
      ) {
        let arr = {
          teamId: localStorage.getItem("teamId"),
          memberType: currentCircle,
          coreMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          extendedMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          allOwnersFlag: {
            core: [],
            extended: [],
          },
        };
        if (arr && arr.teamId.length > 0) {
          setPfmemberId(arr);
          localStorage.setItem("pfmemberId", JSON.stringify(arr));
        }
      }
    } else if (
      focused &&
      focused.card === "Projects" &&
      projectsSorted &&
      projectsSorted.length > 0
    ) {
      let currentProject = focused.id;

      setPopoverIds({
        ...popoverIds,
        memberType: currentProject,
        teamId: localStorage.getItem("teamId"),
      });

      for (let i = 0; i < members.length; i++) {
        if (members[i].memberType === currentProject) {
          let arr = {
            teamId: members[i].teamId,
            memberType: members[i].memberType,
            coreMembers: {
              People: members[i].coreMembers.People,
              Skills: members[i].coreMembers.Skills,
              Roles: members[i].coreMembers.Roles,
              Links: members[i].coreMembers.Links,
              Domains: members[i].coreMembers.Domains,
              Circles: members[i].coreMembers.Circles,
            },
            extendedMembers: {
              People: members[i].extendedMembers.People,
              Skills: members[i].extendedMembers.Skills,
              Roles: members[i].extendedMembers.Roles,
              Links: members[i].extendedMembers.Links,
              Domains: members[i].extendedMembers.Domains,
            },
          };
          if (arr && arr.teamId.length > 0) {
            setPopoverIds(arr);
            localStorage.setItem("popoverIds", JSON.stringify(arr));
          }
          break;
        }
      }

      let count = 0;
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === currentProject) {
          let arr = {
            teamId: pfmembers[i].teamId,
            memberType: pfmembers[i].memberType,
            coreMembers: {
              Skills: pfmembers[i].coreMembers.Skills,
              Roles: pfmembers[i].coreMembers.Roles,
              Links: pfmembers[i].coreMembers.Links,
              Domains: pfmembers[i].coreMembers.Domains,
            },
            extendedMembers: {
              Skills: pfmembers[i].extendedMembers.Skills,
              Roles: pfmembers[i].extendedMembers.Roles,
              Links: pfmembers[i].extendedMembers.Links,
              Domains: pfmembers[i].extendedMembers.Domains,
            },
            allOwnersFlag: pfmembers[i].allOwnersFlag,
          };
          if (arr && arr.teamId.length > 0) {
            setPfmemberIds(arr);
            localStorage.setItem("pfmemberIds", JSON.stringify(arr));
          }
          break;
        }
        count++;
      }

      if (
        count === pfmembers.length &&
        !JSON.parse(localStorage.getItem("pfmemberIds"))
      ) {
        let arr = {
          teamId: localStorage.getItem("teamId"),
          memberType: currentProject,
          coreMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          extendedMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          allOwnersFlag: {
            core: [],
            extended: [],
          },
        };
        if (arr && arr.teamId.length > 0) {
          setPfmemberIds(arr);
          localStorage.setItem("pfmemberIds", JSON.stringify(arr));
        }
      }
    }
  }, [focused]);

  // Circle and Project member set/ reset from members state/ redux
  const pAndCMemderSet = () => {
    // console.log('pAndCMemderSet');
    if (
      focused &&
      circlesSorted.length > 0 &&
      focused.card === "Circles" &&
      circlesSorted &&
      circlesSorted.length > 0
    ) {
      let currentCircle = focused.id;
      for (let i = 0; i < members.length; i++) {
        if (members[i].memberType === currentCircle) {
          let arr = {
            teamId: members[i].teamId,
            memberType: members[i].memberType,
            coreMembers: {
              People: members[i].coreMembers.People,
              Skills: members[i].coreMembers.Skills,
              Roles: members[i].coreMembers.Roles,
              Links: members[i].coreMembers.Links,
              Domains: members[i].coreMembers.Domains,
              Circles: members[i].coreMembers.Circles,
              Projects: members[i].coreMembers.Projects,
            },
            extendedMembers: {
              People: members[i].extendedMembers.People,
              Skills: members[i].extendedMembers.Skills,
              Roles: members[i].extendedMembers.Roles,
              Links: members[i].extendedMembers.Links,
              Domains: members[i].extendedMembers.Domains,
              Circles: members[i].extendedMembers.Circles,
            },
          };

          if (arr && arr.teamId.length > 0) {
            setPopoverId(arr);
            localStorage.setItem("popoverId", JSON.stringify(arr));
          }
          break;
        }
      }

      let count = 0;
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === currentCircle) {
          let arr = {
            teamId: pfmembers[i].teamId,
            memberType: pfmembers[i].memberType,
            coreMembers: {
              Skills: pfmembers[i].coreMembers.Skills,
              Roles: pfmembers[i].coreMembers.Roles,
              Links: pfmembers[i].coreMembers.Links,
              Domains: pfmembers[i].coreMembers.Domains,
            },
            extendedMembers: {
              Skills: pfmembers[i].extendedMembers.Skills,
              Roles: pfmembers[i].extendedMembers.Roles,
              Links: pfmembers[i].extendedMembers.Links,
              Domains: pfmembers[i].extendedMembers.Domains,
            },
            allOwnersFlag: pfmembers[i].allOwnersFlag,
          };
          if (arr && arr.teamId.length > 0) {
            setPfmemberId(arr);
            localStorage.setItem("pfmemberId", JSON.stringify(arr));
          }
          break;
        }
        count++;
      }

      if (
        count === pfmembers.length &&
        !JSON.parse(localStorage.getItem("pfmemberId"))
      ) {
        let arr = {
          teamId: localStorage.getItem("teamId"),
          memberType: currentCircle,
          coreMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          extendedMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          allOwnersFlag: {
            core: [],
            extended: [],
          },
        };
        if (arr && arr.teamId.length > 0) {
          localStorage.setItem("pfmemberId", JSON.stringify(arr));
        }
      }
    } else if (
      focused &&
      focused.card === "Projects" &&
      projectsSorted &&
      projectsSorted.length > 0
    ) {
      let currentProject = focused.id;
      for (let i = 0; i < members.length; i++) {
        if (members[i].memberType === currentProject) {
          let arr = {
            teamId: members[i].teamId,
            memberType: members[i].memberType,
            coreMembers: {
              People: members[i].coreMembers.People,
              Skills: members[i].coreMembers.Skills,
              Roles: members[i].coreMembers.Roles,
              Links: members[i].coreMembers.Links,
              Domains: members[i].coreMembers.Domains,
              Circles: members[i].coreMembers.Circles,
            },
            extendedMembers: {
              People: members[i].extendedMembers.People,
              Skills: members[i].extendedMembers.Skills,
              Roles: members[i].extendedMembers.Roles,
              Links: members[i].extendedMembers.Links,
              Domains: members[i].extendedMembers.Domains,
            },
          };
          if (arr && arr.teamId.length > 0) {
            setPopoverIds(arr);
            localStorage.setItem("popoverIds", JSON.stringify(arr));
          }
          break;
        }
      }

      let count = 0;
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === currentProject) {
          let arr = {
            teamId: pfmembers[i].teamId,
            memberType: pfmembers[i].memberType,
            coreMembers: {
              Skills: pfmembers[i].coreMembers.Skills,
              Roles: pfmembers[i].coreMembers.Roles,
              Links: pfmembers[i].coreMembers.Links,
              Domains: pfmembers[i].coreMembers.Domains,
            },
            extendedMembers: {
              Skills: pfmembers[i].extendedMembers.Skills,
              Roles: pfmembers[i].extendedMembers.Roles,
              Links: pfmembers[i].extendedMembers.Links,
              Domains: pfmembers[i].extendedMembers.Domains,
            },
            allOwnersFlag: pfmembers[i].allOwnersFlag,
          };
          if (arr && arr.teamId.length > 0) {
            setPfmemberIds(arr);
            localStorage.setItem("pfmemberIds", JSON.stringify(arr));
          }
          break;
        }
        count++;
      }

      if (
        count === pfmembers.length &&
        !JSON.parse(localStorage.getItem("pfmemberIds"))
      ) {
        let arr = {
          teamId: localStorage.getItem("teamId"),
          memberType: currentProject,
          coreMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          extendedMembers: {
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
          allOwnersFlag: {
            core: [],
            extended: [],
          },
        };
        if (arr && arr.teamId.length > 0) {
          localStorage.setItem("pfmemberIds", JSON.stringify(arr));
        }
      }
    }
  };

  // Run pAndCMemderSet function whenever the members state is updated using redux
  // Credit: Indranil
  useEffect(() => {
    // console.log("third", skillsMessagee, roleMessagee);
    if (skillsMessagee || roleMessagee) {
      // let obj = {};
      // obj.userId = myInfo.userId;
      // obj.teamId = pfmemberId.teamId;
      // dispatch(getPFMember(obj))
      // console.log(skillsMessagee, roleMessagee)

      dispatch({ type: SKILL_SUCCESS_MESSAGE_CLEAR });
      dispatch({ type: ROLE_SUCCESS_MESSAGE_CLEAR });
    } else {
      pAndCMemderSet();
    }
  }, [members, pfmembers, skillsMessagee, roleMessagee]);

  const cFPeopleHandel = (e, type) => {
    // console.log('cFPeopleHandel');
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragCard", type);
    setTimeout(() => {
      const popoverElement = document.getElementById("popover-basic");
      if (popoverElement) {
        popoverElement.classList.remove("fade");
      }
    }, 300);
    // openPeopleModeTool();
  };

  const cFAction = (popoverId, actionType = "", cardId = "") => {
    // console.log('cFAction', popoverId);
    if (popoverId) {
      // console.log('popoverId', popoverId);
      setPopoverId(popoverId);
      localStorage.setItem("popoverId", JSON.stringify(popoverId));
      // localStorage.removeItem("popoverId");
      // console.log(11334, 'cFAction: ', popoverId);
      popoverId.adminId = myInfo.userId;
      popoverId.url = `${SERVER_URI}/user/member/create`;
      popoverId.actionType = actionType;
      popoverId.cardId = cardId;

      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverId.teamId;
      dispatch(updateMember(popoverId)).then(() => {
        dispatch(getMember(obj)).then(() => {
          localStorage.removeItem("dragCard");
          localStorage.removeItem("dragData");
          localStorage.removeItem("dragType");
          unfreezeWindow();
        });
        // localStorage.removeItem("dragCard");
        // localStorage.removeItem("dragData");
        // localStorage.removeItem("dragType");
        // unfreezeWindow();
      });
    }
  };

  const cFActionState = (pfmemberId) => {
    // console.log('cFActionState');
    if (pfmemberId) {
      pfmemberId.adminId = myInfo.userId;
      pfmemberId.url = `${SERVER_URI}/user/pfmember/create`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = pfmemberId.teamId;
      dispatch(createAndUpdateMember(pfmemberId)).then(() => {
        dispatch(getPFMember(obj));
        localStorage.removeItem("dragPepleCard");
        unfreezeWindow();
      });
    }
  };

  const pFAction = (popoverIds) => {
    // console.log('pFAction');
    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        dispatch(getMember(obj));
        localStorage.removeItem("dragCard");
        localStorage.removeItem("dragData");
        localStorage.removeItem("dragType");
        unfreezeWindow();
      });
    }
  };

  const pFActionState = (pfmemberIds) => {
    if (pfmemberIds) {
      pfmemberIds.adminId = myInfo.userId;
      pfmemberIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = pfmemberIds.teamId;
      dispatch(createAndUpdateMember(pfmemberIds)).then(() => {
        dispatch(getPFMember(obj));
        localStorage.removeItem("dragPepleCard");
        unfreezeWindow();
      });
    }
  };

  const cFPeopleIdHandel = (type, isPFCircle) => {
    let popoverValue = localStorage.getItem("dragCard");
    // console.log(popoverValue)
    let popoverMember = JSON.parse(localStorage.getItem("dragData"));
    // console.log(popoverMember)
    // console.log(focused)
    // console.log('cFPeopleIdHandel: ', popoverValue, popoverMember);
    if (focused.card === "Circles") {
      // console.log("popoverid", popoverId, popoverId.coreMembers);
      if (popoverValue === "People" && popoverMember) {
        // console.log("Adding People: ", popoverMember, popoverId);
        if (type === "Core") {
          popoverId.coreMembers.People = [
            ...new Set([...popoverId.coreMembers.People, popoverMember._id]),
          ];
        } else if (type === "Extended") {
          popoverId.extendedMembers.People = [
            ...new Set([
              ...popoverId.extendedMembers.People,
              popoverMember._id,
            ]),
          ];
        } else if (type === "cToe") {
          popoverId.coreMembers.People = popoverId.coreMembers.People.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverId.extendedMembers.People = [
            ...new Set([
              ...popoverId.extendedMembers.People,
              popoverMember._id,
            ]),
          ];
        } else if (type === "eToc") {
          popoverId.extendedMembers.People =
            popoverId.extendedMembers.People.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverId.coreMembers.People = [
            ...new Set([...popoverId.coreMembers.People, popoverMember._id]),
          ];
        }

        if (popoverId) {
          cFAction(popoverId);
        }
      } else if (popoverValue === "Skills" && popoverMember) {
        // console.log("Adding Skill: ", popoverMember, popoverId);
        if (type === "Core") {
          popoverId.coreMembers.Skills = [
            ...new Set([...popoverId.coreMembers.Skills, popoverMember._id]),
          ];
          cFAction(popoverId);
        } else if (type === "Extended") {
          popoverId.extendedMembers.Skills = [
            ...new Set([
              ...popoverId.extendedMembers.Skills,
              popoverMember._id,
            ]),
          ];
          cFAction(popoverId);
        } else if (type === "cToe") {
          popoverId.coreMembers.Skills = popoverId.coreMembers.Skills.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverId.extendedMembers.Skills = [
            ...new Set([
              ...popoverId.extendedMembers.Skills,
              popoverMember._id,
            ]),
          ];
          cFAction(popoverId);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberId.coreMembers.Skills =
              pfmemberId.coreMembers.Skills.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberId.extendedMembers.Skills = [
              ...new Set([
                ...pfmemberId.extendedMembers.Skills,
                ...curskillData,
              ]),
            ];
            cFActionState(pfmemberId);
          }
        } else if (type === "eToc") {
          popoverId.extendedMembers.Skills =
            popoverId.extendedMembers.Skills.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverId.coreMembers.Skills = [
            ...new Set([...popoverId.coreMembers.Skills, popoverMember._id]),
          ];
          cFAction(popoverId);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberId.extendedMembers.Skills =
              pfmemberId.extendedMembers.Skills.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberId.coreMembers.Skills = [
              ...new Set([...pfmemberId.coreMembers.Skills, ...curskillData]),
            ];
            cFActionState(pfmemberId);
          }
        }
      } else if (popoverValue === "Roles" && popoverMember) {
        if (type === "Core") {
          popoverId.coreMembers.Roles = [
            ...new Set([...popoverId.coreMembers.Roles, popoverMember._id]),
          ];
          cFAction(popoverId);
        } else if (type === "Extended") {
          popoverId.extendedMembers.Roles = [
            ...new Set([...popoverId.extendedMembers.Roles, popoverMember._id]),
          ];
        } else if (type === "cToe") {
          popoverId.coreMembers.Roles = popoverId.coreMembers.Roles.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverId.extendedMembers.Roles = [
            ...new Set([...popoverId.extendedMembers.Roles, popoverMember._id]),
          ];
          cFAction(popoverId);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberId.coreMembers.Roles = pfmemberId.coreMembers.Roles.filter(
              (e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              }
            );
            pfmemberId.extendedMembers.Roles = [
              ...new Set([
                ...pfmemberId.extendedMembers.Roles,
                ...curskillData,
              ]),
            ];
            cFActionState(pfmemberId);
          }
        } else if (type === "eToc") {
          popoverId.extendedMembers.Roles =
            popoverId.extendedMembers.Roles.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverId.coreMembers.Roles = [
            ...new Set([...popoverId.coreMembers.Roles, popoverMember._id]),
          ];
          cFAction(popoverId);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberId.extendedMembers.Roles =
              pfmemberId.extendedMembers.Roles.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberId.coreMembers.Roles = [
              ...new Set([...pfmemberId.coreMembers.Roles, ...curskillData]),
            ];
            cFActionState(pfmemberId);
          }
        }
      } else if (popoverValue === "Domains" && popoverMember) {
        if (type === "Core") {
          popoverId.coreMembers.Domains = [
            ...new Set([...popoverId.coreMembers.Domains, popoverMember._id]),
          ];
          cFAction(popoverId);
        } else if (type === "Extended") {
          popoverId.extendedMembers.Domains = [
            ...new Set([
              ...popoverId.extendedMembers.Domains,
              popoverMember._id,
            ]),
          ];
          cFAction(popoverId);
        } else if (type === "cToe") {
          popoverId.coreMembers.Domains = popoverId.coreMembers.Domains.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverId.extendedMembers.Domains = [
            ...new Set([
              ...popoverId.extendedMembers.Domains,
              popoverMember._id,
            ]),
          ];
          cFAction(popoverId);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberId.coreMembers.Domains =
              pfmemberId.coreMembers.Domains.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberId.extendedMembers.Domains = [
              ...new Set([
                ...pfmemberId.extendedMembers.Domains,
                ...curskillData,
              ]),
            ];
            cFActionState(pfmemberId);
          }
        } else if (type === "eToc") {
          popoverId.extendedMembers.Domains =
            popoverId.extendedMembers.Domains.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverId.coreMembers.Domains = [
            ...new Set([...popoverId.coreMembers.Domains, popoverMember._id]),
          ];
          cFAction(popoverId);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberId.extendedMembers.Domains =
              pfmemberId.extendedMembers.Domains.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberId.coreMembers.Domains = [
              ...new Set([...pfmemberId.coreMembers.Domains, ...curskillData]),
            ];
            cFActionState(pfmemberId);
          }
        }
      } else if (popoverValue === "Links" && popoverMember) {
        if (type === "Core") {
          popoverId.coreMembers.Links = [
            ...new Set([...popoverId.coreMembers.Links, popoverMember._id]),
          ];
          cFAction(popoverId);
        } else if (type === "Extended") {
          popoverId.extendedMembers.Links = [
            ...new Set([...popoverId.extendedMembers.Links, popoverMember._id]),
          ];
          cFAction(popoverId);
        } else if (type === "cToe") {
          popoverId.coreMembers.Links = popoverId.coreMembers.Links.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverId.extendedMembers.Links = [
            ...new Set([...popoverId.extendedMembers.Links, popoverMember._id]),
          ];
          cFAction(popoverId);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberId.coreMembers.Links = pfmemberId.coreMembers.Links.filter(
              (e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              }
            );
            pfmemberId.extendedMembers.Links = [
              ...new Set([
                ...pfmemberId.extendedMembers.Links,
                ...curskillData,
              ]),
            ];
            cFActionState(pfmemberId);
          }
        } else if (type === "eToc") {
          popoverId.extendedMembers.Links =
            popoverId.extendedMembers.Links.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverId.coreMembers.Links = [
            ...new Set([...popoverId.coreMembers.Links, popoverMember._id]),
          ];
          cFAction(popoverId);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = {};
            pfmemberId.extendedMembers.Links =
              pfmemberId.extendedMembers.Links.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberId.coreMembers.Links = [
              ...new Set([...pfmemberId.coreMembers.Links, ...curskillData]),
            ];
            cFActionState(pfmemberId);
          }
        }
      } else if (popoverValue === "Projects" && popoverMember) {
        if (type === "Core") {
          popoverId.coreMembers.Projects = [
            ...new Set([...popoverId.coreMembers.Projects, popoverMember._id]),
          ];
          cFAction(popoverId);
        }
      }
    } else if (focused.card === "Projects") {
      if (popoverValue === "People" && popoverMember) {
        if (type === "Core") {
          popoverIds.coreMembers.People = [
            ...new Set([...popoverIds.coreMembers.People, popoverMember._id]),
          ];
          pFAction(popoverIds);
        } else if (type === "Extended") {
          popoverIds.extendedMembers.People = [
            ...new Set([
              ...popoverIds.extendedMembers.People,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
        } else if (type === "cToe") {
          popoverIds.coreMembers.People = popoverIds.coreMembers.People.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverIds.extendedMembers.People = [
            ...new Set([
              ...popoverIds.extendedMembers.People,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
        } else if (type === "eToc") {
          popoverIds.extendedMembers.People =
            popoverIds.extendedMembers.People.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverIds.coreMembers.People = [
            ...new Set([...popoverIds.coreMembers.People, popoverMember._id]),
          ];
          pFAction(popoverIds);
        }
      } else if (popoverValue === "Skills" && popoverMember) {
        if (type === "Core") {
          popoverIds.coreMembers.Skills = [
            ...new Set([...popoverIds.coreMembers.Skills, popoverMember._id]),
          ];
          pFAction(popoverIds);
        } else if (type === "Extended") {
          popoverIds.extendedMembers.Skills = [
            ...new Set([
              ...popoverIds.extendedMembers.Skills,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
        } else if (type === "cToe") {
          popoverIds.coreMembers.Skills = popoverIds.coreMembers.Skills.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverIds.extendedMembers.Skills = [
            ...new Set([
              ...popoverIds.extendedMembers.Skills,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.coreMembers.Skills =
              pfmemberIds.coreMembers.Skills.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.extendedMembers.Skills = [
              ...new Set([
                ...pfmemberIds.extendedMembers.Skills,
                ...curskillData,
              ]),
            ];
            pFActionState(pfmemberIds);
          }
        } else if (type === "eToc") {
          popoverIds.extendedMembers.Skills =
            popoverIds.extendedMembers.Skills.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverIds.coreMembers.Skills = [
            ...new Set([...popoverIds.coreMembers.Skills, popoverMember._id]),
          ];
          pFAction(popoverIds);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.extendedMembers.Skills =
              pfmemberIds.extendedMembers.Skills.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.coreMembers.Skills = [
              ...new Set([...pfmemberIds.coreMembers.Skills, ...curskillData]),
            ];
            pFActionState(pfmemberIds);
          }
        }
      } else if (popoverValue === "Roles" && popoverMember) {
        if (type === "Core") {
          popoverIds.coreMembers.Roles = [
            ...new Set([...popoverIds.coreMembers.Roles, popoverMember._id]),
          ];
          pFAction(popoverIds);
        } else if (type === "Extended") {
          popoverIds.extendedMembers.Roles = [
            ...new Set([
              ...popoverIds.extendedMembers.Roles,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
        } else if (type === "cToe") {
          popoverIds.coreMembers.Roles = popoverIds.coreMembers.Roles.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverIds.extendedMembers.Roles = [
            ...new Set([
              ...popoverIds.extendedMembers.Roles,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.coreMembers.Roles =
              pfmemberIds.coreMembers.Roles.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.extendedMembers.Roles = [
              ...new Set([
                ...pfmemberIds.extendedMembers.Roles,
                ...curskillData,
              ]),
            ];
            pFActionState(pfmemberIds);
          }
        } else if (type === "eToc") {
          popoverIds.extendedMembers.Roles =
            popoverIds.extendedMembers.Roles.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverIds.coreMembers.Roles = [
            ...new Set([...popoverIds.coreMembers.Roles, popoverMember._id]),
          ];
          pFAction(popoverIds);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.extendedMembers.Roles =
              pfmemberIds.extendedMembers.Roles.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.coreMembers.Roles = [
              ...new Set([...pfmemberIds.coreMembers.Roles, ...curskillData]),
            ];
            pFActionState(pfmemberIds);
          }
        }
      } else if (popoverValue === "Domains" && popoverMember) {
        if (type === "Core") {
          popoverIds.coreMembers.Domains = [
            ...new Set([...popoverIds.coreMembers.Domains, popoverMember._id]),
          ];
          pFAction(popoverIds);
        } else if (type === "Extended") {
          popoverIds.extendedMembers.Domains = [
            ...new Set([
              ...popoverIds.extendedMembers.Domains,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
        } else if (type === "cToe") {
          popoverIds.coreMembers.Domains =
            popoverIds.coreMembers.Domains.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverIds.extendedMembers.Domains = [
            ...new Set([
              ...popoverIds.extendedMembers.Domains,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.coreMembers.Domains =
              pfmemberIds.coreMembers.Domains.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.extendedMembers.Domains = [
              ...new Set([
                ...pfmemberIds.extendedMembers.Domains,
                ...curskillData,
              ]),
            ];
            pFActionState(pfmemberIds);
          }
        } else if (type === "eToc") {
          popoverIds.extendedMembers.Domains =
            popoverIds.extendedMembers.Domains.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverIds.coreMembers.Domains = [
            ...new Set([...popoverIds.coreMembers.Domains, popoverMember._id]),
          ];
          pFAction(popoverIds);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.extendedMembers.Domains =
              pfmemberIds.extendedMembers.Domains.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.coreMembers.Domains = [
              ...new Set([...pfmemberIds.coreMembers.Domains, ...curskillData]),
            ];
            pFActionState(pfmemberIds);
          }
        }
      } else if (popoverValue === "Links" && popoverMember) {
        if (type === "Core") {
          popoverIds.coreMembers.Links = [
            ...new Set([...popoverIds.coreMembers.Links, popoverMember._id]),
          ];
          pFAction(popoverIds);
        } else if (type === "Extended") {
          popoverIds.extendedMembers.Links = [
            ...new Set([
              ...popoverIds.extendedMembers.Links,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
        } else if (type === "cToe") {
          popoverIds.coreMembers.Links = popoverIds.coreMembers.Links.filter(
            (e) => {
              return e !== popoverMember._id;
            }
          );
          popoverIds.extendedMembers.Links = [
            ...new Set([
              ...popoverIds.extendedMembers.Links,
              popoverMember._id,
            ]),
          ];
          pFAction(popoverIds);
          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.coreMembers.Links =
              pfmemberIds.coreMembers.Links.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.extendedMembers.Links = [
              ...new Set([
                ...pfmemberIds.extendedMembers.Links,
                ...curskillData,
              ]),
            ];
            pFActionState(pfmemberIds);
          }
        } else if (type === "eToc") {
          popoverIds.extendedMembers.Links =
            popoverIds.extendedMembers.Links.filter((e) => {
              return e !== popoverMember._id;
            });
          popoverIds.coreMembers.Links = [
            ...new Set([...popoverIds.coreMembers.Links, popoverMember._id]),
          ];
          pFAction(popoverIds);

          if (isPFCircle && isPFCircle.length > 0) {
            let curskillData = [];
            pfmemberIds.extendedMembers.Links =
              pfmemberIds.extendedMembers.Links.filter((e) => {
                if (e.CardId === popoverMember._id) {
                  curskillData.push(e);
                  return false;
                } else {
                  return true;
                }
              });
            pfmemberIds.coreMembers.Links = [
              ...new Set([...pfmemberIds.coreMembers.Links, ...curskillData]),
            ];
            pFActionState(pfmemberIds);
          }
        }
      } else if (popoverValue === "Circles" && popoverMember) {
        if (type === "Core") {
          popoverIds.coreMembers.Circles = [
            ...new Set([...popoverIds.coreMembers.Circles, popoverMember._id]),
          ];
          pFAction(popoverIds);
        }
      }
    }
  };

  //=========================================Circle FOCUSED Action===================================================

  const cFpeopleSub = (data, type) => {
    // console.log(11802, 'Removing People: ', data, popoverId);
    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.People
    ) {
      popoverId.coreMembers.People = popoverId.coreMembers.People.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverId &&
      popoverId.extendedMembers &&
      popoverId.extendedMembers.People
    ) {
      popoverId.extendedMembers.People =
        popoverId.extendedMembers.People.filter((e) => {
          return e !== data._id;
        });
    }
    // console.log(11808, 'Members after people is removed: ', data, popoverId);

    if (popoverId) {
      cFAction(popoverId);
    }
  };

  const cFskillSub = (data, type, isPFCircle) => {
    // console.log(11827, 'Removing Skill: ', data, popoverId);

    let updated_pfmemberId = { ...pfmemberId };

    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.Skills
    ) {
      popoverId.coreMembers.Skills = popoverId.coreMembers.Skills.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverId &&
      popoverId.extendedMembers &&
      popoverId.extendedMembers.Skills
    ) {
      popoverId.extendedMembers.Skills =
        popoverId.extendedMembers.Skills.filter((e) => {
          return e !== data._id;
        });
    }

    if (
      type === "core" &&
      updated_pfmemberId &&
      updated_pfmemberId.coreMembers &&
      updated_pfmemberId.coreMembers.Skills &&
      isPFCircle &&
      isPFCircle.length > 0
    ) {
      updated_pfmemberId = {
        ...updated_pfmemberId, // Use the correct variable name here
        coreMembers: {
          ...updated_pfmemberId.coreMembers,
          Skills: updated_pfmemberId.coreMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberId.coreMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberId.coreMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberId.coreMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberId.allOwnersFlag,
          core: updated_pfmemberId.allOwnersFlag.core.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberId(updated_pfmemberId);
    } else if (
      type === "extend" &&
      pfmemberId &&
      pfmemberId.extendedMembers &&
      pfmemberId.extendedMembers.Skills
    ) {
      updated_pfmemberId = {
        ...updated_pfmemberId, // Use the correct variable name here
        extendedMembers: {
          ...updated_pfmemberId.extendedMembers,
          Skills: updated_pfmemberId.extendedMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberId.extendedMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberId.extendedMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberId.extendedMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberId.allOwnersFlag,
          extended: updated_pfmemberId.allOwnersFlag.extended.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberId(updated_pfmemberId);
    }

    // console.log(11841, 'Members after skill is removed: ', data, popoverId);

    if (popoverId) {
      cFAction(popoverId);
    }

    if (updated_pfmemberId && isPFCircle && isPFCircle.length > 0) {
      cFActionState(updated_pfmemberId);
    }
  };

  const cFroleSub = (data, type, isPFCircle) => {
    // console.log(11827, 'Removing Skill: ', data, popoverId);

    let updated_pfmemberId = { ...pfmemberId };

    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.Roles
    ) {
      popoverId.coreMembers.Roles = popoverId.coreMembers.Roles.filter((e) => {
        return e !== data._id;
      });
    } else if (
      type === "extend" &&
      popoverId &&
      popoverId.extendedMembers &&
      popoverId.extendedMembers.Roles
    ) {
      popoverId.extendedMembers.Roles = popoverId.extendedMembers.Roles.filter(
        (e) => {
          return e !== data._id;
        }
      );
    }

    if (
      type === "core" &&
      updated_pfmemberId &&
      updated_pfmemberId.coreMembers &&
      updated_pfmemberId.coreMembers.Roles &&
      isPFCircle &&
      isPFCircle.length > 0
    ) {
      // console.log(pfmemberId);

      updated_pfmemberId = {
        ...updated_pfmemberId, // Use the correct variable name here
        coreMembers: {
          ...updated_pfmemberId.coreMembers,
          Skills: updated_pfmemberId.coreMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberId.coreMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberId.coreMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberId.coreMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberId.allOwnersFlag,
          core: updated_pfmemberId.allOwnersFlag.core.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberId(updated_pfmemberId);
    } else if (
      type === "extend" &&
      pfmemberId &&
      pfmemberId.extendedMembers &&
      pfmemberId.extendedMembers.Roles
    ) {
      updated_pfmemberId = {
        ...updated_pfmemberId, // Use the correct variable name here
        extendedMembers: {
          ...updated_pfmemberId.extendedMembers,
          Skills: updated_pfmemberId.extendedMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberId.extendedMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberId.extendedMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberId.extendedMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberId.allOwnersFlag,
          extended: updated_pfmemberId.allOwnersFlag.extended.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberId(updated_pfmemberId);
    }

    if (popoverId) {
      cFAction(popoverId);
    }

    if (updated_pfmemberId && isPFCircle && isPFCircle.length > 0) {
      cFActionState(updated_pfmemberId);
    }
  };

  const cFdomainSub = (data, type, isPFCircle) => {
    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.Domains
    ) {
      popoverId.coreMembers.Domains = popoverId.coreMembers.Domains.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverId &&
      popoverId.extendedMembers &&
      popoverId.extendedMembers.Domains
    ) {
      popoverId.extendedMembers.Domains =
        popoverId.extendedMembers.Domains.filter((e) => {
          return e !== data._id;
        });
    }

    if (
      type === "core" &&
      pfmemberId &&
      pfmemberId.coreMembers &&
      pfmemberId.coreMembers.Domains &&
      isPFCircle &&
      isPFCircle.length > 0
    ) {
      pfmemberId.coreMembers.Domains = pfmemberId.coreMembers.Domains.filter(
        (e) => {
          return e.CardId !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      pfmemberId &&
      pfmemberId.extendedMembers &&
      pfmemberId.extendedMembers.Domains
    ) {
      pfmemberId.extendedMembers.Domains =
        pfmemberId.extendedMembers.Domains.filter((e) => {
          return e.CardId !== data._id;
        });
    }

    if (popoverId) {
      cFAction(popoverId);
    }

    if (pfmemberId && isPFCircle && isPFCircle.length > 0) {
      cFActionState(pfmemberId);
    }
  };

  const cFlinkSub = (data, type, isPFCircle) => {
    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.Links
    ) {
      popoverId.coreMembers.Links = popoverId.coreMembers.Links.filter((e) => {
        return e !== data._id;
      });
    } else if (
      type === "extend" &&
      popoverId &&
      popoverId.extendedMembers &&
      popoverId.extendedMembers.Links
    ) {
      popoverId.extendedMembers.Links = popoverId.extendedMembers.Links.filter(
        (e) => {
          return e !== data._id;
        }
      );
    }

    if (
      type === "core" &&
      pfmemberId &&
      pfmemberId.coreMembers &&
      pfmemberId.coreMembers.Links &&
      isPFCircle &&
      isPFCircle.length > 0
    ) {
      pfmemberId.coreMembers.Links = pfmemberId.coreMembers.Links.filter(
        (e) => {
          return e.CardId !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      pfmemberId &&
      pfmemberId.extendedMembers &&
      pfmemberId.extendedMembers.Links
    ) {
      pfmemberId.extendedMembers.Links =
        pfmemberId.extendedMembers.Links.filter((e) => {
          return e.CardId !== data._id;
        });
    }

    if (popoverId) {
      cFAction(popoverId);
    }

    if (pfmemberId && isPFCircle && isPFCircle.length > 0) {
      cFActionState(pfmemberId);
    }
  };

  const cFprojectSub = (data, type) => {
    // console.log(10834, data._id)
    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.Projects
    ) {
      popoverId.coreMembers.Projects = popoverId.coreMembers.Projects.filter(
        (e) => {
          return e !== data._id;
        }
      );
    }
    if (popoverId) {
      cFAction(popoverId, "remove", data._id);
    }
  };

  //=========================================Circle FOCUSED Drag and Drop===================================================

  //People
  const cFPonDrag = (e, type) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "People");
  };

  const cFPonDragOver = (e) => {
    e.preventDefault();
  };

  const cFPonDrop = (type) => {
    let cFPonDragData = JSON.parse(localStorage.getItem("dragData"));
    let cFPonDragType = localStorage.getItem("dragType");
    if (type === "cFpCore") {
      if (cFPonDragType === "cFpExtend") {
        cFPeopleIdHandel("eToc");
      } else if (cFPonDragType === "cFpDefult") {
        cFPeopleIdHandel("Core");
      }
    } else if (type === "cFpExtend") {
      if (cFPonDragType === "cFpCore") {
        cFPeopleIdHandel("cToe");
      } else if (cFPonDragType === "cFpDefult") {
        cFPeopleIdHandel("Extended");
      }
    } else if (type === "cFpDefult") {
      if (cFPonDragType === "cFpExtend") {
        cFpeopleSub(cFPonDragData, "extend");
      } else if (cFPonDragType === "cFpCore") {
        cFpeopleSub(cFPonDragData, "core");
      }
    }
  };

  //Skills
  const cFSonDrag = (e, type, isPFCircle) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Skills");
    localStorage.setItem("dragPepleCard", isPFCircle);
  };

  const cFSonDragOver = (e) => {
    e.preventDefault();
  };

  const cFSonDrop = (type) => {
    let cFSonDragData = JSON.parse(localStorage.getItem("dragData"));
    let cFSonDragType = localStorage.getItem("dragType");
    let isPFCircle = localStorage.getItem("dragPepleCard");
    // console.log(cFSonDragData)
    if (type === "cFsCore") {
      if (cFSonDragType === "cFsExtend") {
        cFPeopleIdHandel("eToc", isPFCircle);
      } else if (cFSonDragType === "cFsDefult") {
        // cFPeopleIdHandel("Core", isPFCircle);
        cfcircleOrProjectModes(cFSonDragData, "Skills", "Core");
      }
    } else if (type === "cFsExtend") {
      if (cFSonDragType === "cFsCore") {
        cFPeopleIdHandel("cToe", isPFCircle);
      } else if (cFSonDragType === "cFsDefult") {
        // cFPeopleIdHandel("Extended", isPFCircle);
        cfcircleOrProjectModes(cFSonDragData, "Skills", "Extended");
      }
    } else if (type === "cFsDefult") {
      if (cFSonDragType === "cFsExtend") {
        cFskillSub(cFSonDragData, "extend", isPFCircle);
      } else if (cFSonDragType === "cFsCore") {
        cFskillSub(cFSonDragData, "core", isPFCircle);
      }
    }
  };

  //Roles
  const cFRonDrag = (e, type, isPFCircle) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Roles");
    localStorage.setItem("dragPepleCard", isPFCircle);
  };

  const cFRonDragOver = (e) => {
    e.preventDefault();
  };

  const cFRonDrop = (type) => {
    let cFRonDragData = JSON.parse(localStorage.getItem("dragData"));
    let cFRonDragType = localStorage.getItem("dragType");
    let isPFCircle = localStorage.getItem("dragPepleCard");
    if (type === "cFrCore") {
      if (cFRonDragType === "cFrExtend") {
        cFPeopleIdHandel("eToc", isPFCircle);
      } else if (cFRonDragType === "cFrDefult") {
        // cFPeopleIdHandel("Core", isPFCircle);
        cfcircleOrProjectModes(cFRonDragData, "Roles", "Core");
      }
    } else if (type === "cFrExtend") {
      if (cFRonDragType === "cFrCore") {
        cFPeopleIdHandel("cToe", isPFCircle);
      } else if (cFRonDragType === "cFrDefult") {
        // cFPeopleIdHandel("Extended", isPFCircle);
        cfcircleOrProjectModes(cFRonDragData, "Roles", "Extended");
      }
    } else if (type === "cFrDefult") {
      if (cFRonDragType === "cFrExtend") {
        cFroleSub(cFRonDragData, "extend", isPFCircle);
      } else if (cFRonDragType === "cFrCore") {
        cFroleSub(cFRonDragData, "core", isPFCircle);
      }
    }
  };

  //Domains
  const cFDonDrag = (e, type, isPFCircle) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Domains");
    localStorage.setItem("dragPepleCard", isPFCircle);
  };

  const cFDonDragOver = (e) => {
    e.preventDefault();
  };

  const cFDonDrop = (type) => {
    let cFDonDragData = JSON.parse(localStorage.getItem("dragData"));
    let cFDonDragType = localStorage.getItem("dragType");
    let isPFCircle = localStorage.getItem("dragPepleCard");
    // console.log(type, cFDonDragData, cFDonDragType, isPFCircle);

    if (type === "cFdCore") {
      // A domain is dragged from notmatched to core
      if (cFDonDragType === "cFdExtend") {
        cFPeopleIdHandel("eToc", isPFCircle);
      } else if (cFDonDragType === "cFdDefult") {
        cFPeopleIdHandel("Core", isPFCircle);
      }
    } else if (type === "cFdExtend") {
      if (cFDonDragType === "cFdCore") {
        cFPeopleIdHandel("cToe", isPFCircle);
      } else if (cFDonDragType === "cFdDefult") {
        cFPeopleIdHandel("Extended", isPFCircle);
      }
    } else if (type === "cFdDefult") {
      if (cFDonDragType === "cFdExtend") {
        cFdomainSub(cFDonDragData, "extend", isPFCircle);
      } else if (cFDonDragType === "cFdCore") {
        cFdomainSub(cFDonDragData, "core", isPFCircle);
      }
    }
  };

  //Links
  const cFLonDrag = (e, type, isPFCircle) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Links");
    localStorage.setItem("dragPepleCard", isPFCircle);
  };

  const cFLonDragOver = (e) => {
    e.preventDefault();
  };

  const cFLonDrop = (type) => {
    let cFLonDragData = JSON.parse(localStorage.getItem("dragData"));
    let cFLonDragType = localStorage.getItem("dragType");
    let isPFCircle = localStorage.getItem("dragPepleCard");
    if (type === "cFlCore") {
      if (cFLonDragType === "cFlExtend") {
        cFPeopleIdHandel("eToc", isPFCircle);
      } else if (cFLonDragType === "cFlDefult") {
        cFPeopleIdHandel("Core", isPFCircle);
      }
    } else if (type === "cFlExtend") {
      if (cFLonDragType === "cFlCore") {
        cFPeopleIdHandel("cToe", isPFCircle);
      } else if (cFLonDragType === "cFlDefult") {
        cFPeopleIdHandel("Extended", isPFCircle);
      }
    } else if (type === "cFlDefult") {
      if (cFLonDragType === "cFlExtend") {
        cFlinkSub(cFLonDragData, "extend", isPFCircle);
      } else if (cFLonDragType === "cFlCore") {
        cFlinkSub(cFLonDragData, "core", isPFCircle);
      }
    }
  };

  //Projects
  const cFPronDrag = (e, type) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Projects");
  };

  const cFPronDragOver = (e) => {
    e.preventDefault();
  };

  const cFPronDrop = (type) => {
    let cFPronDragData = JSON.parse(localStorage.getItem("dragData"));
    let cFPronDragType = localStorage.getItem("dragType");
    if (type === "cFPrCore") {
      if (cFPronDragType === "cFPrDefult") {
        cFPeopleIdHandel("Core");
      }
    } else if (type === "cFPrDefult") {
      if (cFPronDragType === "cFPrCore") {
        cFprojectSub(cFPronDragData, "core");
      }
    }
  };

  //=========================================Circle FOCUSED algorithm===================================================

  ///Skills
  const [cfSkillSearch, setCfSkillSearch] = useState("");

  const fcSref = useRef(null);
  useEffect(() => {
    if (cfSkillSearch.length > 0) {
      fcSref.current.focus();
    }
  }, [cfSkillSearch]);

  var filteredCFSData = [];

  if (
    (cfSkillSearch.length > 0 || allSearch.length > 0) &&
    CedeffoSkill.length > 0
  ) {
    filteredCFSData = CedeffoSkill.filter((value) => {
      const searchStr = cfSkillSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.skillName}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredCFSData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Skills");
    }
  }

  if (
    filteredCFSData.length === 0 &&
    cfSkillSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCFSData = [...CedeffoSkill];
  }

  let nonFilteSFPData = [];
  if (
    cfSkillSearch.length > 0 &&
    filteredCFSData.length !== CedeffoSkill.length
  ) {
    nonFilteSFPData = [...CedeffoSkill];
    for (let i = 0; i < filteredCFSData.length; i++) {
      nonFilteSFPData = nonFilteSFPData.filter((value, key) => {
        return value._id !== filteredCFSData[i]._id;
      });
    }
    if (filteredCFSData.length === 0) {
      nonFilteSFPData = [...CedeffoSkill];
    }
  }

  const isPFCircleAddS = (data, type) => {
    if (
      focused &&
      focused.card === "Circles" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          let cuPeoples = [];
          for (let j = 0; j < pfmembers[i].coreMembers.Skills.length; j++) {
            if (pfmembers[i].coreMembers.Skills[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Skills[j].peopleIds.toString()
                ) {
                  cuPeoples.push({
                    Id: peopleSorted[k]._id.toString(),
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                  });
                  // return;
                }
              }
            }
          }
          return cuPeoples;
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          let cupPeople = [];
          for (let j = 0; j < pfmembers[i].extendedMembers.Skills.length; j++) {
            if (pfmembers[i].extendedMembers.Skills[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Skills[j].peopleIds.toString()
                ) {
                  cupPeople.push({
                    Id: peopleSorted[k]._id.toString(),
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                  });
                }
              }
            }
          }
          return cupPeople;
        }
      }
      return [];
    }
  };

  const OpenCFPeople = (Id) => {
    if (peopleSorted && peopleSorted.length > 0) {
      for (let i = 0; i < peopleSorted.length; i++) {
        if (peopleSorted[i]._id.toString() === Id.toString()) {
          setPepole(peopleSorted[i]);
          break;
        }
      }
    }
  };

  // Edit detailsModel for people focused circle and project both

  const cfcircleOrProjectModes = (e, type, TypeLoc) => {
    if (focused && focused.card.length > 0 && focused.card === "Circles") {
      let focusedCircle = circlesSorted.find((c) => c._id === focused.id);
      localStorage.setItem("fcData", focused.id);
      localStorage.setItem("fcDatas", JSON.stringify(focusedCircle));
      localStorage.setItem("dragData", JSON.stringify(e));
      localStorage.setItem("dragCard", type);
    } else if (
      focused &&
      focused.card.length > 0 &&
      focused.card === "Projects"
    ) {
      let focusedProject = projectsSorted.find((p) => p._id === focused.id);
      localStorage.setItem("fcData", focused.id);
      localStorage.setItem("fcDatas", JSON.stringify(focusedProject));
      localStorage.setItem("dragData", JSON.stringify(e));
      localStorage.setItem("dragCard", type);
    }
    if (TypeLoc && TypeLoc.length > 0) {
      localStorage.setItem("TypeLoc", TypeLoc);
    }
    pAndCMemderSet();
    openCircleModeTool();

    setTemporarilyMovedItem({ cardType: type, area: TypeLoc, card: e });
  };

  ///Roles
  const [cfRolesSearch, setCfRolesSearch] = useState("");

  const fcRref = useRef(null);
  useEffect(() => {
    if (cfRolesSearch.length > 0) {
      fcRref.current.focus();
    }
  }, [cfRolesSearch]);

  var filteredCFRData = [];

  if (
    (cfRolesSearch.length > 0 || allSearch.length > 0) &&
    CedeffoRoles.length > 0
  ) {
    filteredCFRData = CedeffoRoles.filter((value) => {
      const searchStr = cfRolesSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.roleName}`.toLowerCase().includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredCFRData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Roles");
    }
  }

  if (
    filteredCFRData.length === 0 &&
    cfRolesSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCFRData = [...CedeffoRoles];
  }

  let nonFilteRFCData = [];
  if (
    cfRolesSearch.length > 0 &&
    filteredCFRData.length !== CedeffoRoles.length
  ) {
    nonFilteRFCData = [...CedeffoRoles];
    for (let i = 0; i < filteredCFRData.length; i++) {
      nonFilteRFCData = nonFilteRFCData.filter((value, key) => {
        return value._id !== filteredCFRData[i]._id;
      });
    }
    if (filteredCFSData.length === 0) {
      nonFilteRFCData = [...CedeffoRoles];
    }
  }

  const isPFCircleAddR = (data, type) => {
    if (
      focused &&
      focused.card === "Circles" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          let cuPeoples = [];
          for (let j = 0; j < pfmembers[i].coreMembers.Roles.length; j++) {
            if (pfmembers[i].coreMembers.Roles[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                    pfmembers[i].coreMembers.Roles[j].peopleIds.toString() &&
                  !cuPeoples.some(
                    (cp) =>
                      cp.Id === peopleSorted[k]._id.toString() &&
                      cp.name ===
                        `${peopleSorted[k].fname} ${peopleSorted[k].lname}`
                  )
                ) {
                  cuPeoples.push({
                    Id: peopleSorted[k]._id.toString(),
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                  });
                }
              }
            }
          }
          return cuPeoples;
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          let cuPeoples = [];
          for (let j = 0; j < pfmembers[i].extendedMembers.Roles.length; j++) {
            if (pfmembers[i].extendedMembers.Roles[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                    pfmembers[i].extendedMembers.Roles[
                      j
                    ].peopleIds.toString() &&
                  !cuPeoples.some(
                    (cp) =>
                      cp.Id === peopleSorted[k]._id.toString() &&
                      cp.name ===
                        `${peopleSorted[k].fname} ${peopleSorted[k].lname}`
                  )
                ) {
                  cuPeoples.push({
                    Id: peopleSorted[k]._id.toString(),
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                  });
                }
              }
            }
          }
          return cuPeoples;
        }
      }
      return [];
    }
  };

  /// Domains
  const [cfDomainsSearch, setCfDomainsSearch] = useState("");

  const fcDref = useRef(null);
  useEffect(() => {
    if (cfDomainsSearch.length > 0) {
      fcDref.current.focus();
    }
  }, [cfDomainsSearch]);

  var filteredCFDData = [];

  if (
    (cfDomainsSearch.length > 0 || allSearch.length > 0) &&
    CedeffoDomains.length > 0
  ) {
    filteredCFDData = CedeffoDomains.filter((value) => {
      const searchStr =
        cfDomainsSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.domainName}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredCFDData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Domains");
    }
  }

  if (
    filteredCFDData.length === 0 &&
    cfDomainsSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCFDData = [...CedeffoDomains];
  }

  let nonFilteDFCData = [];
  if (
    cfDomainsSearch.length > 0 &&
    filteredCFDData.length !== CedeffoDomains.length
  ) {
    nonFilteDFCData = [...CedeffoDomains];
    for (let i = 0; i < filteredCFDData.length; i++) {
      nonFilteDFCData = nonFilteDFCData.filter((value, key) => {
        return value._id !== filteredCFDData[i]._id;
      });
    }
    if (filteredCFDData.length === 0) {
      nonFilteDFCData = [...CedeffoDomains];
    }
  }

  let cfRAnfdSDomain = [];
  if (focused && focused.card === "Circles") {
    if (CcorfoSkill && CcorfoSkill.length > 0) {
      for (let i = 0; i < CcorfoSkill.length; i++) {
        if (CcorfoSkill[i].domains && CcorfoSkill[i].domains.length > 0) {
          for (let j = 0; j < CcorfoSkill[i].domains.length; j++) {
            if (CcorfoSkill[i].domains[j].domain) {
              cfRAnfdSDomain.push({
                cardId: CcorfoSkill[i]._id,
                cardName: CcorfoSkill[i].skillName,
                type: "Skill",
                domainId: CcorfoSkill[i].domains[j].domain,
              });
            }
          }
        }
      }
    }
    if (CcorfoRoles && CcorfoRoles.length > 0) {
      for (let i = 0; i < CcorfoRoles.length; i++) {
        if (CcorfoRoles[i].domains && CcorfoRoles[i].domains.length > 0) {
          for (let j = 0; j < CcorfoRoles[i].domains.length; j++) {
            if (CcorfoRoles[i].domains[j].domain) {
              cfRAnfdSDomain.push({
                cardId: CcorfoRoles[i]._id,
                cardName: CcorfoRoles[i].roleName,
                type: "Role",
                domainId: CcorfoRoles[i].domains[j].domain,
              });
            }
          }
        }
      }
    }

    // V3-772 domains disappear

    /* if (cfRAnfdSDomain && cfRAnfdSDomain.length > 0) {
      if (filteredCFDData && filteredCFDData.length > 0) {
        let addNewDoamin = [];
        filteredCFDData = filteredCFDData.filter((value, key) => {
          let flag = true;
          for (let i = 0; i < cfRAnfdSDomain.length; i++) {
            if (cfRAnfdSDomain[i].domainId === value._id) {
              console.log(11238, value);
              addNewDoamin.push(value);
              flag = false;
              break;
            }
          }
          if (flag) {
            return value;
          }
        });
        if (addNewDoamin && addNewDoamin.length > 0) {
          CcorfoDomains = [...CcorfoDomains, ...addNewDoamin];
        }
      }
    } */
  }

  let cfRAnfdSExDomain = [];
  if (focused && focused.card === "Circles") {
    if (CextfoSkill && CextfoSkill.length > 0) {
      for (let i = 0; i < CextfoSkill.length; i++) {
        if (CextfoSkill[i].domains && CextfoSkill[i].domains.length > 0) {
          for (let j = 0; j < CextfoSkill[i].domains.length; j++) {
            if (CextfoSkill[i].domains[j].domain) {
              cfRAnfdSExDomain.push({
                cardId: CextfoSkill[i]._id,
                cardName: CextfoSkill[i].skillName,
                type: "Skill",
                domainId: CextfoSkill[i].domains[j].domain,
              });
            }
          }
        }
      }
    }
    if (CextfoRoles && CextfoRoles.length > 0) {
      for (let i = 0; i < CextfoRoles.length; i++) {
        if (CextfoRoles[i].domains && CextfoRoles[i].domains.length > 0) {
          for (let j = 0; j < CextfoRoles[i].domains.length; j++) {
            if (CextfoRoles[i].domains[j].domain) {
              cfRAnfdSExDomain.push({
                cardId: CextfoRoles[i]._id,
                cardName: CextfoRoles[i].roleName,
                type: "Role",
                domainId: CextfoRoles[i].domains[j].domain,
              });
            }
          }
        }
      }
    }
    if (cfRAnfdSExDomain && cfRAnfdSExDomain.length > 0) {
      if (filteredCFDData && filteredCFDData.length > 0) {
        let addNewDoamin = [];
        filteredCFDData = filteredCFDData.filter((value, key) => {
          let flag = true;
          for (let i = 0; i < cfRAnfdSExDomain.length; i++) {
            if (cfRAnfdSExDomain[i].domainId === value._id) {
              addNewDoamin.push(value);
              flag = false;
              break;
            }
          }
          if (flag) {
            return value;
          }
        });
        if (addNewDoamin && addNewDoamin.length > 0) {
          CextfoDomains = [...CextfoDomains, ...addNewDoamin];
        }
      }
    }
  }

  const isPFCircleAddD = (data, type) => {
    if (
      focused &&
      focused.card === "Circles" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          for (let j = 0; j < pfmembers[i].coreMembers.Domains.length; j++) {
            if (pfmembers[i].coreMembers.Domains[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Domains[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          for (
            let j = 0;
            j < pfmembers[i].extendedMembers.Domains.length;
            j++
          ) {
            if (pfmembers[i].extendedMembers.Domains[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Domains[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        }
      }
      return "";
    }
  };

  const cardOpensCfDomain = (datas) => {
    // console.log(12903, datas);
    if (datas.type === "Skill") {
      skillsSorted.filter((value, key) => {
        if (value._id.toString() === datas.cardId.toString()) {
          indexSet(value);
          openSkill();
        }
      });
    } else if (datas.type === "Role") {
      rolesSorted.filter((value, key) => {
        if (value._id.toString() === datas.cardId.toString()) {
          indexSet(value);
          openRole();
        }
      });
    }
  };

  //Links
  const [cfLinksSearch, setCfLinksSearch] = useState("");

  const fcLref = useRef(null);
  useEffect(() => {
    if (cfLinksSearch.length > 0) {
      fcLref.current.focus();
    }
  }, [cfLinksSearch]);

  var filteredCFLData = [];

  if (
    (cfLinksSearch.length > 0 || allSearch.length > 0) &&
    CedeffoLinks.length > 0
  ) {
    filteredCFLData = CedeffoLinks.filter((value) => {
      const searchStr = cfLinksSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.linkName}`.toLowerCase().includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredCFLData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Links");
    }
  }

  if (
    filteredCFLData.length === 0 &&
    cfLinksSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCFLData = [...CedeffoLinks];
  }

  let nonFilteLFCData = [];
  if (
    cfLinksSearch.length > 0 &&
    filteredCFLData.length !== CedeffoLinks.length
  ) {
    nonFilteLFCData = [...CedeffoLinks];
    for (let i = 0; i < filteredCFLData.length; i++) {
      nonFilteLFCData = nonFilteLFCData.filter((value, key) => {
        return value._id !== filteredCFLData[i]._id;
      });
    }
    if (filteredCFLData.length === 0) {
      nonFilteLFCData = [...CedeffoLinks];
    }
  }

  const isPFCircleAddL = (data, type) => {
    if (
      focused &&
      focused.card === "Circles" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          for (let j = 0; j < pfmembers[i].coreMembers.Links.length; j++) {
            if (pfmembers[i].coreMembers.Links[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Links[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          for (let j = 0; j < pfmembers[i].extendedMembers.Links.length; j++) {
            if (pfmembers[i].extendedMembers.Links[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Links[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        }
      }
      return "";
    }
  };

  // Circles
  let CFCircleSorted = [...circlesSorted];

  // Project
  const [cfProjectsSearch, setCfProjectsSearch] = useState("");

  const fcPrref = useRef(null);
  useEffect(() => {
    if (cfProjectsSearch.length > 0) {
      fcPrref.current.focus();
    }
  }, [cfProjectsSearch]);

  var filteredCFPrData = [];

  if (
    (cfProjectsSearch.length > 0 || allSearch.length > 0) &&
    CedeffoProjects.length > 0
  ) {
    filteredCFPrData = CedeffoProjects.filter((value) => {
      const searchStr =
        cfProjectsSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.projectName}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredCFPrData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Projects");
    }
  }

  if (
    filteredCFPrData.length === 0 &&
    cfProjectsSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCFPrData = [...CedeffoProjects];
  }

  let nonFilteCFPrData = [];
  if (
    cfProjectsSearch.length > 0 &&
    filteredCFPrData.length !== CedeffoProjects.length
  ) {
    nonFilteCFPrData = [...CedeffoProjects];
    for (let i = 0; i < filteredCFPrData.length; i++) {
      nonFilteCFPrData = nonFilteCFPrData.filter((value, key) => {
        return value._id !== filteredCFPrData[i]._id;
      });
    }
    if (filteredCFPrData.length === 0) {
      nonFilteCFPrData = [...CedeffoProjects];
    }
  }

  //People
  const [cfPeopleSearch, setCfPeopleSearch] = useState("");
  const fcPref = useRef(null);
  useEffect(() => {
    if (cfPeopleSearch.length > 0) {
      fcPref.current.focus();
    }
  }, [cfPeopleSearch]);

  var filteredCFPData = [];

  if (
    (cfPeopleSearch.length > 0 || allSearch.length > 0) &&
    CedeffoPeople.length > 0
  ) {
    filteredCFPData = CedeffoPeople.filter((value) => {
      const searchStr = cfPeopleSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.fname} ${value.lname}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredCFPData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("People");
    }
  }

  if (
    filteredCFPData.length === 0 &&
    cfPeopleSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredCFPData = [...CedeffoPeople];
  }

  let nonFilteCFPData = [];
  if (
    cfPeopleSearch.length > 0 &&
    filteredCFPData.length !== CedeffoPeople.length
  ) {
    nonFilteCFPData = [...CedeffoPeople];
    for (let i = 0; i < filteredCFPData.length; i++) {
      nonFilteCFPData = nonFilteCFPData.filter((value, key) => {
        return value._id !== filteredCFPData[i]._id;
      });
    }
    if (filteredCFPData.length === 0) {
      nonFilteCFPData = [...CedeffoPeople];
    }
  }

  let curPFMemberState = {};
  if (focused && focused.card === "Circles" && focused.id) {
    let cFDataId = focused.id;
    if (cFDataId && pfmembers && pfmembers.length > 0) {
      for (let i = 0; i < pfmembers.length; i++) {
        if (
          pfmembers[i].memberType &&
          pfmembers[i].memberType.toString() === cFDataId.toString()
        ) {
          curPFMemberState = pfmembers[i];
          break;
        }
      }
    }
  }

  let CFPeoplePills = [];
  let CFPeoplePillsE = [];
  if (
    focused &&
    focused.card === "Circles" &&
    curPFMemberState &&
    Object.keys(curPFMemberState).length > 0
  ) {
    if (
      CcorfoSkill &&
      CcorfoSkill.length > 0 &&
      Object.keys(curPFMemberState.coreMembers).length > 0 &&
      curPFMemberState.coreMembers.Skills &&
      curPFMemberState.coreMembers.Skills.length > 0
    ) {
      for (let i = 0; i < CcorfoSkill.length; i++) {
        for (let j = 0; j < curPFMemberState.coreMembers.Skills.length; j++) {
          if (
            CcorfoSkill[i]._id === curPFMemberState.coreMembers.Skills[j].CardId
          ) {
            CFPeoplePills.push({
              peopleId: curPFMemberState.coreMembers.Skills[j].peopleIds,
              Id: CcorfoSkill[i]._id,
              name: `${CcorfoSkill[i].skillName} (Skill)`,
              cardType: "Skills",
              standIn: false,
            });
          }
        }
      }
    }

    if (
      CextfoSkill &&
      CextfoSkill.length > 0 &&
      Object.keys(curPFMemberState.extendedMembers).length > 0 &&
      curPFMemberState.extendedMembers.Skills &&
      curPFMemberState.extendedMembers.Skills.length > 0
    ) {
      for (let i = 0; i < CextfoSkill.length; i++) {
        for (
          let j = 0;
          j < curPFMemberState.extendedMembers.Skills.length;
          j++
        ) {
          if (
            CextfoSkill[i]._id ===
            curPFMemberState.extendedMembers.Skills[j].CardId
          ) {
            CFPeoplePillsE.push({
              peopleId: curPFMemberState.extendedMembers.Skills[j].peopleIds,
              Id: CextfoSkill[i]._id,
              name: `${CextfoSkill[i].skillName} (Skill)`,
              cardType: "Skills",
              standIn: false,
            });
          }
        }
      }
    }

    if (
      CcorfoRoles &&
      CcorfoRoles.length > 0 &&
      Object.keys(curPFMemberState.coreMembers).length > 0 &&
      curPFMemberState.coreMembers.Roles &&
      curPFMemberState.coreMembers.Roles.length > 0
    ) {
      for (let i = 0; i < CcorfoRoles.length; i++) {
        for (let j = 0; j < curPFMemberState.coreMembers.Roles.length; j++) {
          if (
            CcorfoRoles[i]._id === curPFMemberState.coreMembers.Roles[j].CardId
          ) {
            CFPeoplePills.push({
              peopleId: curPFMemberState.coreMembers.Roles[j].peopleIds,
              Id: CcorfoRoles[i]._id,
              name: `${CcorfoRoles[i].roleName} (Role)`,
              cardType: "Roles",
              standIn: false,
            });
          }
        }
      }
    }

    if (
      CextfoRoles &&
      CextfoRoles.length > 0 &&
      Object.keys(curPFMemberState.extendedMembers).length > 0 &&
      curPFMemberState.extendedMembers.Roles &&
      curPFMemberState.extendedMembers.Roles.length > 0
    ) {
      for (let i = 0; i < CextfoRoles.length; i++) {
        for (
          let j = 0;
          j < curPFMemberState.extendedMembers.Roles.length;
          j++
        ) {
          if (
            CextfoRoles[i]._id &&
            CextfoRoles[i]._id ===
              curPFMemberState.extendedMembers.Roles[j].CardId
          ) {
            CFPeoplePillsE.push({
              peopleId: curPFMemberState.extendedMembers.Roles[j].peopleIds,
              Id: CextfoRoles[i]._id,
              name: `${CextfoRoles[i].roleName} (Role)`,
              cardType: "Roles",
              standIn: false,
            });
          }
        }
      }
    }

    if (CcorfoDomains && CcorfoDomains.length > 0) {
      for (let i = 0; i < CcorfoDomains.length; i++) {
        if (CcorfoDomains[i].owners && CcorfoDomains[i].owners.owner) {
          CFPeoplePills.push({
            peopleId: CcorfoDomains[i].owners.owner,
            Id: CcorfoDomains[i]._id,
            name: `${CcorfoDomains[i].domainName} (Domain)`,
            cardType: "Domains",
            standIn: false,
          });
        }
        if (CcorfoDomains[i].standIn) {
          CFPeoplePills.push({
            peopleId: CcorfoDomains[i].standIn,
            Id: CcorfoDomains[i]._id,
            name: `${CcorfoDomains[i].domainName} (Domain)`,
            cardType: "Domains",
            standIn: true,
          });
        }
      }
    }

    if (CextfoDomains && CextfoDomains.length > 0) {
      for (let i = 0; i < CextfoDomains.length; i++) {
        if (CextfoDomains[i].owners && CextfoDomains[i].owners.owner) {
          CFPeoplePillsE.push({
            peopleId: CextfoDomains[i].owners.owner,
            Id: CextfoDomains[i]._id,
            name: `${CextfoDomains[i].domainName} (Domain)`,
            cardType: "Domains",
            standIn: false,
          });
        }
        if (CextfoDomains[i].standIn) {
          CFPeoplePillsE.push({
            peopleId: CextfoDomains[i].standIn,
            Id: CextfoDomains[i]._id,
            name: `${CextfoDomains[i].domainName} (Domain)`,
            cardType: "Domains",
            standIn: true,
          });
        }
      }
    }

    if (CcorfoLinks && CcorfoLinks.length > 0) {
      for (let i = 0; i < CcorfoLinks.length; i++) {
        if (CcorfoLinks[i].owner) {
          CFPeoplePills.push({
            peopleId: CcorfoLinks[i].owner,
            Id: CcorfoLinks[i]._id,
            name: `${CcorfoLinks[i].linkName} (Link)`,
            cardType: "Links",
            standIn: false,
          });
        }
        if (CcorfoLinks[i].standIn) {
          CFPeoplePills.push({
            peopleId: CcorfoLinks[i].standIn,
            Id: CcorfoLinks[i]._id,
            name: `${CcorfoLinks[i].linkName} (Link)`,
            cardType: "Links",
            standIn: true,
          });
        }
      }
    }

    if (CextfoLinks && CextfoLinks.length > 0) {
      for (let i = 0; i < CextfoLinks.length; i++) {
        if (CextfoLinks[i].owner) {
          CFPeoplePillsE.push({
            peopleId: CextfoLinks[i].owner,
            Id: CextfoLinks[i]._id,
            name: `${CextfoLinks[i].linkName} (Link)`,
            cardType: "Links",
            standIn: false,
          });
        }
        if (CextfoLinks[i].standIn) {
          CFPeoplePillsE.push({
            peopleId: CextfoLinks[i].standIn,
            Id: CextfoLinks[i]._id,
            name: `${CextfoLinks[i].linkName} (Link)`,
            cardType: "Links",
            standIn: true,
          });
        }
      }
    }
  }

  // console.log(13820, "CcorfoPeople", CcorfoPeople);
  let CcorfoPeoples = [...CcorfoPeople];
  let CExtfoPeoples = [...CextfoPeople];
  let filteredCFPDatas = [...filteredCFPData];

  if (focused && focused.card === "Circles") {
    if (CFPeoplePills && CFPeoplePills.length > 0) {
      let pillsData = [];
      for (let i = 0; i < CFPeoplePills.length; i++) {
        pillsData = [...new Set([...pillsData, CFPeoplePills[i].peopleId])];
      }
      if (pillsData && pillsData.length > 0) {
        for (let i = 0; i < pillsData.length; i++) {
          filteredCFPDatas = filteredCFPDatas.filter((value) => {
            if (value._id.toString() === pillsData[i].toString()) {
              CcorfoPeoples = [...new Set([...CcorfoPeoples, value])];
              return false;
            } else {
              return true;
            }
          });
        }
      }
    }

    if (CFPeoplePillsE && CFPeoplePillsE.length > 0) {
      let pillsDatas = [];
      for (let i = 0; i < CFPeoplePillsE.length; i++) {
        pillsDatas = [...new Set([...pillsDatas, CFPeoplePillsE[i].peopleId])];
      }
      if (pillsDatas && pillsDatas.length > 0) {
        for (let i = 0; i < pillsDatas.length; i++) {
          filteredCFPDatas = filteredCFPDatas.filter((value) => {
            if (value._id.toString() === pillsDatas[i].toString()) {
              CExtfoPeoples = [...new Set([...CExtfoPeoples, value])];
              return false;
            } else {
              return true;
            }
          });
          if (CcorfoPeoples && CcorfoPeoples.length > 0) {
            for (let j = 0; j < CcorfoPeoples.length; j++) {
              if (CcorfoPeoples[j]._id === pillsDatas[i].toString()) {
                CExtfoPeoples = [
                  ...new Set([...CExtfoPeoples, CcorfoPeoples[j]]),
                ];
                break;
              }
            }
          }
        }
      }
    }
  } else if (
    focused &&
    focused.card === "Circles" &&
    CFPeoplePills.length === 0 &&
    CFPeoplePillsE.length === 0
  ) {
    filteredCFPDatas = [...filteredCFPData];
  }

  if (focused && focused.card === "Circles") {
    // console.log(CcorfoPeoples, CextfoPeople);
    let curCFDatas = [...CcorfoPeoples, ...CextfoPeople];
    if (curCFDatas && curCFDatas.length > 0) {
      for (let i = 0; i < curCFDatas.length; i++) {
        filteredCFPDatas = filteredCFPDatas.filter((value) => {
          return value._id.toString() !== curCFDatas[i]._id.toString();
        });
      }
    }
  }

  const cardOpensCf = (datas) => {
    if (datas.cardType === "People") {
      peopleSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openPeople();
        }
      });
    } else if (datas.cardType === "Skills") {
      skillsSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openSkill();
        }
      });
    } else if (datas.cardType === "Roles") {
      rolesSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openRole();
        }
      });
    } else if (datas.cardType === "Links") {
      linksSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openLink();
        }
      });
    } else if (datas.cardType === "Domains") {
      domainsSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openDomain();
        }
      });
    }
  };

  const cFPeopleCard = (e) => {
    if (Object.keys(e).length > 0) {
      for (let i = 0; i < peopleSorted.length; i++) {
        if (peopleSorted[i]._id.toString() === e._id.toString()) {
          setPepole(e, i);
          break;
        }
      }
    }
  };

  const isAddedAsPersonCF = (Id, type) => {
    if (type === "Core") {
      if (
        curCoreMembers &&
        curCoreMembers.People &&
        curCoreMembers.People.length > 0
      ) {
        let inc = curCoreMembers.People.includes(Id);
        if (inc) {
          return true;
        }
      }
    } else if (type === "Extended") {
      if (
        curExtendedMembers &&
        curExtendedMembers.People &&
        curExtendedMembers.People.length > 0
      ) {
        let inc = curExtendedMembers.People.includes(Id);
        if (inc) {
          return true;
        }
      }
    }
    return false;
  };

  const cfIsDuplicatePerson = (data) => {
    if (
      data &&
      Object.keys(data).length > 0 &&
      CcorfoPeoples &&
      CcorfoPeoples.length > 0
    ) {
      let flag = true;
      for (let i = 0; i < CcorfoPeoples.length; i++) {
        if (CcorfoPeoples[i]._id === data._id) {
          flag = false;
          break;
        }
      }
      return flag;
    }
  };

  // Check if all owners was clicked/ marked (used in circle and project focus modes both)
  const isAllOwnersMarked = (data, type) => {
    if (
      focused &&
      focused.card === "Projects" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      // console.log('first')
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          if (pfmembers[i].allOwnersFlag.core.includes(data)) {
            return true;
          }
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          if (pfmembers[i].allOwnersFlag.core.includes(data)) {
            return true;
          }
        }
      }
    } else if (
      focused &&
      focused.card === "Circles" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      // console.log('second')
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          if (pfmembers[i].allOwnersFlag.core.includes(data)) {
            return true;
          }
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          if (pfmembers[i].allOwnersFlag.extended.includes(data)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  // Check if any circle already has a project associated to it's members
  const IsAlreadyAssociated = (data) => {
    // console.log(data, members);
    let associated = false;
    members.map((member) => {
      if (
        member.coreMembers &&
        member.coreMembers.Projects &&
        member.coreMembers.Projects.includes(data._id)
      ) {
        associated = true;
      }
    });
    return associated;
  };

  /* ========================================= Circle focus mode =================================================== */
  const handleDragEndCF = (result) => {
    if (!result.draggableId || !result.source || !result.destination) {
      unfreezeWindow(false);
      return false;
    }

    if (
      result.source.droppableId &&
      result.destination.droppableId &&
      result.source.droppableId === result.destination.droppableId
    ) {
      unfreezeWindow(false);
      return false;
    }

    // console.log(result);
    // console.log(`Dropped in ${result.destination.droppableId}: `);

    /* result.draggableId // - ID of the dragged item
    result.source.index // - source index of the dragged item
    result.destination.index // - destination index of the dragged item */
    // setTemporarilyMovedItem({cardType: 'Circle', card: circleDropped });
  };

  // { console.log(CcorfoPeoples) }

  const FCPeople = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.people &&
        tagFilteredMegedData.people.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="people"
    >
      <div className="list list-bg-yellow" data-list_id="people">
        <div className="dropdown" style={{ position: "static" }}>
          <h4 className="list-title">
            <img alt="" src="images/icon-people.png" /> People (
            <span>{CcorfoPeoples.length + CExtfoPeoples.length}</span>){" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("people")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              href="#"
              onClick={() => {
                toggleEXTnNOCards("people", "", true, "list");
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("people")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("People")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("People")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#peopleModal"
                >
                  Add new person
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("People")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>

              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("people", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show external people
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          <div
            className="form-group notes"
            style={note.People ? { display: "block" } : { display: "none" }}
          >
            <textarea
              className="notes"
              ref={pnoteRef}
              name="People"
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              maxLength="100"
              autoFocus={null}
              defaultValue={noteSaveData.People}
            ></textarea>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => cFPonDrop("cFpCore")}
              onDragOver={(event) => cFPonDragOver(event)}
              id="core"
              className="panel-body box-container"
            >
              {CcorfoPeoples && CcorfoPeoples.length > 0
                ? CcorfoPeoples.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "people"
                      );
                      if (!Proceed) return;
                    }
                    let flagRed = false;
                    let flagGray = false;
                    let flagLGray = false;
                    if (focused && focused.card === "Circles") {
                      let focusedCircle = circlesSorted.find(
                        (c) => c._id === focused.id
                      );
                      let leadOfCicleRedInHtml = focusedCircle.lead;
                      let leadOfCicleGrayInHtml = focusedCircle.standIn;
                      if (leadOfCicleRedInHtml) {
                        if (leadOfCicleRedInHtml === e._id) {
                          flagRed = true;
                        } else {
                          let curPLead = "";
                          if (
                            skillsSorted &&
                            skillsSorted.length > 0 &&
                            leadOfCicleRedInHtml
                          ) {
                            for (let j = 0; j < skillsSorted.length; j++) {
                              if (
                                skillsSorted[j].ownerType === "Single owner" &&
                                skillsSorted[j].owners &&
                                skillsSorted[j].owners.length > 0
                              ) {
                                if (
                                  skillsSorted[j]._id === leadOfCicleRedInHtml
                                ) {
                                  curPLead = skillsSorted[j].owners[0];
                                  break;
                                }
                              }
                            }
                          }
                          if (!curPLead) {
                            if (
                              rolesSorted &&
                              rolesSorted.length > 0 &&
                              leadOfCicleRedInHtml
                            ) {
                              for (let j = 0; j < rolesSorted.length; j++) {
                                if (
                                  rolesSorted[j].ownerRole === "Single owner" &&
                                  rolesSorted[j].owners &&
                                  rolesSorted[j].owners.length > 0
                                ) {
                                  if (
                                    rolesSorted[j]._id === leadOfCicleRedInHtml
                                  ) {
                                    curPLead = rolesSorted[j].owners[0];
                                    break;
                                  }
                                }
                              }
                            }
                          }
                          if (curPLead && curPLead.length > 0) {
                            if (e._id === curPLead) {
                              flagLGray = true;
                            }
                          }
                        }
                      }
                      if (leadOfCicleGrayInHtml === e._id) {
                        flagGray = true;
                      }
                    }

                    let curPills = [];
                    if (CFPeoplePills && CFPeoplePills.length > 0) {
                      // console.log("Initial CFPeoplePills: ",CFPeoplePills)
                      for (let i = 0; i < CFPeoplePills.length; i++) {
                        let duplicate = false;
                        if (curPills.length > 0) {
                          for (let j = 0; j < curPills.length; j++) {
                            if (
                              curPills[j].peopleId ===
                                CFPeoplePills[i].peopleId &&
                              curPills[j].Id === CFPeoplePills[i].Id
                            ) {
                              // console.log("Duplicate found: ",curPills[j], CFPeoplePills[i]);
                              duplicate = true;
                              break;
                            }
                          }
                        }

                        if (CFPeoplePills[i].peopleId === e._id && !duplicate) {
                          curPills.push(CFPeoplePills[i]);
                        }
                      }
                    }
                    // console.log("Current pills: ",curPills);

                    let addAsPerson = isAddedAsPersonCF(e._id, "Core");
                    return (
                      <div
                        onDrag={() => cFPonDrag(e, "cFpCore")}
                        key={e._id}
                        draggable
                        // className="card "
                        className={`card ${
                          localStorage.getItem("latest-created") === e._id
                            ? " latest-created "
                            : ""
                        }`}
                        data-list_id="people"
                        style={{ minHeight: "60px" }}
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setPepole(e, CcorfoPeoples, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {e.workRole === "External" ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {curPills && curPills.length > 0
                            ? curPills.map((d, index) => {
                                return (
                                  <span onClick={() => cardOpensCf(d)}>
                                    {!d.standIn ? (
                                      <span className="custom-badge lightgray">
                                        {d.name}
                                      </span>
                                    ) : (
                                      <span className="custom-badge gray-o">
                                        {d.name}
                                      </span>
                                    )}
                                  </span>
                                );
                              })
                            : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <div className="relations">
                          {addAsPerson ? (
                            <span className="custom-badge lightgray text-red person-pill">
                              Added as person
                            </span>
                          ) : null}
                          {flagRed ? (
                            <span className="custom-badge red-o direct-lead">
                              LEAD
                            </span>
                          ) : null}
                          {flagGray ? (
                            <span className="custom-badge gray-o">
                              LEAD (Stand-In)
                            </span>
                          ) : null}
                          {flagLGray ? (
                            <span className="custom-badge gray-o">LEAD</span>
                          ) : null}
                        </div>
                        <a
                          /* onClick={() => setPepole(e, CcorfoPeoples, index)} */ className="card-edit"
                        >{`${e.fname} ${e.lname}`}</a>
                        <a className="card-focus">
                          <img
                            onClick={() => PfToCAndP("People", e)}
                            src="./images/newicons/focus.svg"
                          />
                        </a>
                        {addAsPerson ? (
                          <a
                            className="card-unlink"
                            style={{ display: "block", color: "red" }}
                          >
                            <i
                              onClick={() => cFpeopleSub(e, "core")}
                              className="icon far fa-times-circle"
                            ></i>
                          </a>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => cFPonDrop("cFpExtend")}
              onDragOver={(event) => cFPonDragOver(event)}
              id="extended"
              className="panel-body box-container"
            >
              {CExtfoPeoples && CExtfoPeoples.length > 0
                ? CExtfoPeoples.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "people"
                      );
                      if (!Proceed) return;
                    }
                    let flagRed = false;
                    let flagGray = false;
                    if (focused && focused.card === "Circles") {
                      let focusedCircle = circlesSorted.find(
                        (c) => c._id === focused.id
                      );
                      let leadOfCicleRedInHtml = focusedCircle.lead;
                      let leadOfCicleGrayInHtml = focusedCircle.standIn;
                      if (leadOfCicleRedInHtml === e._id) {
                        flagRed = true;
                      }
                      if (leadOfCicleGrayInHtml === e._id) {
                        flagGray = true;
                      }
                    }
                    let curPills = [];
                    if (CFPeoplePillsE && CFPeoplePillsE.length > 0) {
                      for (let i = 0; i < CFPeoplePillsE.length; i++) {
                        if (CFPeoplePillsE[i].peopleId === e._id) {
                          curPills.push(CFPeoplePillsE[i]);
                        }
                      }
                    }
                    let flag = true;
                    if (e && Object.keys(e).length > 0) {
                      flag = cfIsDuplicatePerson(e);
                    }
                    let addAsPerson = isAddedAsPersonCF(e._id, "Extended");
                    return (
                      <div
                        // onDragStart={(e) => peopleDragStart(e, index)}
                        // onDragEnter={(e) => peopleDragEnter(e, index)}
                        // onDragEnd={peopleDrop}
                        onDrag={() => cFPonDrag(e, "cFpExtend")}
                        key={e._id}
                        draggable
                        // className="card "
                        className={`card ${
                          localStorage.getItem("latest-created") === e._id
                            ? " latest-created "
                            : ""
                        }`}
                        data-list_id="people"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setPepole(e, CextfoPeople, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {e.workRole === "External" ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                          {curPills && curPills.length > 0
                            ? curPills.map((d, index) => {
                                return (
                                  <span onClick={() => cardOpensCf(d)}>
                                    {!d.standIn ? (
                                      <span className="custom-badge lightgray">
                                        {d.name}
                                      </span>
                                    ) : (
                                      <span className="custom-badge gray-o">
                                        {d.name}
                                      </span>
                                    )}
                                  </span>
                                );
                              })
                            : null}
                        </div>
                        <div className="relations">
                          {addAsPerson ? (
                            <span className="custom-badge lightgray text-red person-pill">
                              Added as person
                            </span>
                          ) : null}

                          {flagGray ? (
                            <span className="custom-badge gray-o">
                              LEAD (Stand-In)
                            </span>
                          ) : null}
                        </div>

                        <a
                          /* onClick={() => setPepole(e, CextfoPeople, index)} */ className="card-edit"
                        >{`${e.fname} ${e.lname}`}</a>
                        {flag ? (
                          <a className="card-focus">
                            <img
                              onClick={() => PfToCAndP("People", e)}
                              src="./images/newicons/focus.svg"
                            />
                          </a>
                        ) : null}
                        {curPills && curPills.length === 0 ? (
                          <a
                            className="card-unlink"
                            style={{ display: "block", color: "red" }}
                          >
                            <i
                              onClick={() => cFpeopleSub(e, "extend")}
                              className="icon far fa-times-circle"
                            ></i>
                          </a>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <span className="clearable2">
            <input
              type="text"
              ref={fcPref}
              style={{ color: "black" }}
              value={cfPeopleSearch}
              onChange={(e) => setCfPeopleSearch(e.target.value)}
              className="form-control search_list2 transparent"
              name="search_list"
              placeholder="Search to select more"
              autoComplete="off"
            />
            {cfPeopleSearch.length > 0 ? (
              <i
                className="clearable__clear"
                onClick={() => setCfPeopleSearch("")}
                style={{ display: "inline", color: "black" }}
              >
                ×
              </i>
            ) : null}
          </span>

          <div className="panel panel-default">
            <div
              onDrop={() => cFPonDrop("cFpDefult")}
              onDragOver={(event) => cFPonDragOver(event)}
              id="notmatched"
              className="panel-body box-container"
            >
              {filteredCFPDatas && filteredCFPDatas.length > 0
                ? filteredCFPDatas.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "people"
                      );
                      if (!Proceed) return;
                    }
                    return (
                      <div
                        // onDragStart={(e) => peopleDragStart(e, index)}
                        // onDragEnter={(e) => peopleDragEnter(e, index)}
                        // onDragEnd={peopleDrop}
                        onDrag={() => cFPonDrag(e, "cFpDefult")}
                        key={index}
                        draggable
                        className={`card notmatched ${
                          localStorage.getItem("latest-created") !== "" &&
                          cuPeopleId === e._id
                            ? " latest-created "
                            : ""
                        }`}
                        data-list_id="people"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setPepole(e, filteredCFPDatas, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {e.workRole === "External" ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>

                        <a
                          onClick={() => setPepole(e, filteredCFPDatas, index)}
                          className="card-edit"
                        >{`${e.fname} ${e.lname}`}</a>
                        <OverlayTrigger
                          trigger="click"
                          placement="right"
                          overlay={popover}
                        >
                          <a
                            className="card-link"
                            variant="success"
                            style={{ display: "block" }}
                          >
                            <i
                              onClick={() => cFPeopleHandel(e, "People")}
                              className="icon fa fa-plus-circle"
                            ></i>
                          </a>
                        </OverlayTrigger>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <div
            className="card2"
            style={
              lFromState.Peoples ? { display: "block" } : { display: "none" }
            }
          >
            <form className="form-new-card" onSubmit={peopleSubmit} role="form">
              <div className="form-group">
                <input
                  type="text"
                  ref={pref}
                  name="card_name"
                  placeholder="Add first name"
                  data-mode="Circle focus mode"
                  className="form-control"
                  value={fName}
                  onChange={handleNameChange}
                  onKeyPress={pfNameHandel}
                  autoComplete="off"
                  required
                />
              </div>

              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  href="#"
                  type="button" // onClick={() => setPepoles({ fname: fName })}
                  onClick={() => {
                    // Added by Debashis on 13-12-2023 (updated 18-12-2023)
                    let lastname = "";
                    let firstname = "";
                    if (fName.trim().indexOf(" ") >= 0) {
                      let nameArray = fName.split(" ");
                      lastname = nameArray.pop();
                      firstname = nameArray.join(" ");
                    } else {
                      lastname = "";
                      firstname = fName;
                    }
                    setFName("");
                    setPepoles({ fname: firstname, lname: lastname });
                    lFromState.Peoples = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  href="#"
                  onClick={() => peopleFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => peopleFrom()}
          data-list_id="people"
          style={
            !lFromState.Peoples ? { display: "block" } : { display: "none" }
          }
        >
          + Add new person
        </a>
      </div>
    </div>
  );

  const FCSkills = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.skill &&
        tagFilteredMegedData.skill.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="skills"
    >
      <div className="list list-bg-blue" data-list_id="skills">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-skill.png" /> Skills (
            <span>{CcorfoSkill.length + CextfoSkill.length}</span>){" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("skill")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              href="#"
              onClick={() => {
                toggleEXTnNOCards("skills", "", true, "list");
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("skill")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Skills")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Skills")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#skillsModal"
                >
                  Add new skill
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Skills")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("skills", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show items owned by external
                </a>
              </li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("skills", "NO", false);
                }}
              >
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          <div
            className="form-group notes"
            style={note.Skills ? { display: "block" } : { display: "none" }}
          >
            <textarea
              className="notes"
              name="Skills"
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              maxLength="100"
              defaultValue={noteSaveData.Skills}
            ></textarea>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => cFSonDrop("cFsCore")}
              onDragOver={(event) => cFSonDragOver(event)}
              id="core"
              className="panel-body box-container"
            >
              {CcorfoSkill && CcorfoSkill.length > 0
                ? CcorfoSkill.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "skill"
                      );
                      if (!Proceed) return;
                    }
                    let flagRed = false;
                    let focusedCircle = circlesSorted.find(
                      (c) => c._id === focused.id
                    );
                    let leadOfCicleRedInHtml = focusedCircle.lead;
                    if (leadOfCicleRedInHtml === e._id) {
                      flagRed = true;
                    }
                    let isExternal = IsOwnerExternal(e, "Skill");
                    let isPFCircle = isPFCircleAddS(e._id, "core");
                    let allOwnersFlag = isAllOwnersMarked(e._id, "core");
                    // console.log(allOwnersFlag)
                    // console.log(14649, isPFCircle)

                    return (
                      <div
                        // onDragStart={(e) => skillsDragStart(e, index)}
                        // onDragEnter={(e) => skillsDragEnter(e, index)}
                        // onDragEnd={skillsDrop}
                        onDrag={() => cFSonDrag(e, "cFsCore", isPFCircle)}
                        draggable={flagRed ? false : true}
                        key={e._id}
                        className="card"
                        data-list_id="skills"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setSkill(e, CcorfoSkill, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          ) : null}
                          {e.ownerType &&
                          e.ownerType.length > 0 &&
                          e.ownerType === "Single owner" ? (
                            <span className="custom-badge blue-o single-owner-pill">
                              1
                            </span>
                          ) : null}
                          {flagRed ? (
                            <span className="custom-badge red-o direct-lead">
                              LEAD
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>

                        <a
                          /* onClick={() => setSkill(e, CcorfoSkill, index)} */ className="card-edit"
                        >
                          {e.skillName}
                        </a>
                        {!flagRed ? (
                          <a
                            className="card-unlink"
                            style={{ display: "block", color: "red" }}
                          >
                            <i
                              onClick={() => cFskillSub(e, "core", isPFCircle)}
                              className="icon far fa-times-circle"
                            ></i>
                          </a>
                        ) : null}
                        <br />
                        {isPFCircle &&
                        isPFCircle.length > 0 &&
                        e.owners &&
                        !allOwnersFlag
                          ? isPFCircle.map((pData, indexp) => {
                              return (
                                <span
                                  key={indexp}
                                  onClick={() => OpenCFPeople(pData.Id)}
                                  className="custom-badge tags blue-text"
                                >{`${pData.name}`}</span>
                              );
                            })
                          : null}
                      </div>
                    );
                  })
                : null}

              {temporarilyMovedItem &&
              temporarilyMovedItem.area === "Core" &&
              temporarilyMovedItem.cardType === "Skills" &&
              Object.keys(temporarilyMovedItem.card).length > 0
                ? (() => {
                    return (
                      <div
                        key={temporarilyMovedItem.card._id}
                        className="card move-up-down"
                        style={{ border: "1px dashed #000", opacity: "0.7" }}
                      >
                        <span className="static-relations">
                          {temporarilyMovedItem.card.tags &&
                          temporarilyMovedItem.card.tags.length > 0
                            ? temporarilyMovedItem.card.tags.map(
                                (r, indexs) => (
                                  <span
                                    onClick={() => toggleTagCards(r)}
                                    key={indexs}
                                    className="custom-badge green tags"
                                  >
                                    {r}
                                  </span>
                                )
                              )
                            : null}
                        </span>
                        <a className="card-edit">
                          {temporarilyMovedItem.card.skillName}
                        </a>
                      </div>
                    );
                  })()
                : null}
            </div>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => cFSonDrop("cFsExtend")}
              onDragOver={(event) => cFSonDragOver(event)}
              id="extended"
              className="panel-body box-container"
            >
              {CextfoSkill && CextfoSkill.length > 0
                ? CextfoSkill.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "skill"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Skill");
                    let isPFCircle = isPFCircleAddS(e._id, "extended");
                    let allOwnersFlag = isAllOwnersMarked(e._id, "extended");
                    let flagRed = false;
                    if (focused && focused.card === "Circles") {
                      let focusedCircle = circlesSorted.find(
                        (c) => c._id === focused.id
                      );
                      let leadOfCicleRedInHtml = focusedCircle.lead;
                      if (leadOfCicleRedInHtml === e._id) {
                        flagRed = true;
                      }
                    }

                    return (
                      <div
                        // onDragStart={(e) => skillsDragStart(e, index)}
                        // onDragEnter={(e) => skillsDragEnter(e, index)}
                        // onDragEnd={skillsDrop}
                        onDrag={() => cFSonDrag(e, "cFsExtend", isPFCircle)}
                        draggable
                        key={e._id}
                        className="card"
                        data-list_id="skills"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setSkill(e, CextfoSkill, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          ) : null}
                          {e.ownerType.length > 0 &&
                          e.ownerType === "Single owner" ? (
                            <span className="custom-badge blue-o single-owner-pill">
                              1
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <a
                          /* onClick={() => setSkill(e, CextfoSkill, index)} */ className="card-edit"
                        >
                          {e.skillName}
                        </a>
                        <a
                          className="card-unlink"
                          style={{ display: "block", color: "red" }}
                        >
                          <i
                            onClick={() => cFskillSub(e, "extend", isPFCircle)}
                            className="icon far fa-times-circle"
                          ></i>
                        </a>
                        <br />
                        {isPFCircle &&
                        isPFCircle.length > 0 &&
                        e.owners &&
                        !allOwnersFlag
                          ? isPFCircle.map((pData, indexp) => {
                              return (
                                <span
                                  key={indexp}
                                  onClick={() => OpenCFPeople(pData.Id)}
                                  className="custom-badge tags blue-text"
                                >{`${pData.name}`}</span>
                              );
                            })
                          : null}
                      </div>
                    );
                  })
                : null}

              {temporarilyMovedItem &&
              temporarilyMovedItem.area === "Extended" &&
              temporarilyMovedItem.cardType === "Skills" &&
              Object.keys(temporarilyMovedItem.card).length > 0
                ? (() => {
                    return (
                      <div
                        key={temporarilyMovedItem.card._id}
                        className="card move-up-down"
                        style={{ border: "1px dashed #000", opacity: "0.7" }}
                      >
                        <span className="static-relations">
                          {temporarilyMovedItem.card.tags &&
                          temporarilyMovedItem.card.tags.length > 0
                            ? temporarilyMovedItem.card.tags.map(
                                (r, indexs) => (
                                  <span
                                    onClick={() => toggleTagCards(r)}
                                    key={indexs}
                                    className="custom-badge green tags"
                                  >
                                    {r}
                                  </span>
                                )
                              )
                            : null}
                        </span>
                        <a className="card-edit">
                          {temporarilyMovedItem.card.skillName}
                        </a>
                      </div>
                    );
                  })()
                : null}
            </div>
          </div>

          <span className="clearable2">
            <input
              type="text"
              ref={fcSref}
              style={{ color: "black" }}
              value={cfSkillSearch}
              onChange={(e) => setCfSkillSearch(e.target.value)}
              className="form-control search_list2 transparent"
              name="search_list"
              placeholder="Search to select more"
              autoComplete="off"
            />
            {cfSkillSearch.length > 0 ? (
              <i
                className="clearable__clear"
                onClick={() => setCfSkillSearch("")}
                style={{ display: "inline", color: "black" }}
              >
                ×
              </i>
            ) : null}
          </span>

          <div className="panel panel-default">
            <div
              onDrop={() => cFSonDrop("cFsDefult")}
              onDragOver={(event) => cFSonDragOver(event)}
              id="notmatched"
              className="panel-body box-container"
            >
              {filteredCFSData && filteredCFSData.length > 0
                ? filteredCFSData.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "skill"
                      );
                      if (!Proceed) return;
                    }
                    if (
                      temporarilyMovedItem &&
                      temporarilyMovedItem.cardType === "Skills" &&
                      Object.keys(temporarilyMovedItem.card).length > 0 &&
                      temporarilyMovedItem.card._id === e._id
                    )
                      return;

                    let isExternal = IsOwnerExternal(e, "Skill");

                    /* 
            The below two lines are added to prevent items from CcorfoSkill and/ or CextfoSkill appearing in notmatched section. This is done as a quick fix instead of customizing drop function.
            */
                    if (CcorfoSkill.some((item) => item._id === e._id)) return;
                    if (CextfoSkill.some((item) => item._id === e._id)) return;

                    return (
                      <div
                        // onDragStart={(e) => skillsDragStart(e, index)}
                        // onDragEnter={(e) => skillsDragEnter(e, index)}
                        // onDragEnd={skillsDrop}
                        onDrag={() => cFSonDrag(e, "cFsDefult")}
                        draggable
                        key={e._id}
                        className={`card notmatched ${
                          localStorage.getItem("latest-created") === e._id
                            ? " latest-created "
                            : ""
                        }`}
                        data-list_id="skills"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setSkill(e, filteredCFSData, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          ) : null}
                          {e.ownerType &&
                          e.ownerType.length > 0 &&
                          e.ownerType === "Single owner" ? (
                            <span className="custom-badge blue-o single-owner-pill">
                              1
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>

                        <a
                          /* onClick={() => setSkill(e, filteredCFSData, index)} */ className="card-edit"
                        >
                          {e.skillName}
                        </a>
                        <a
                          className="card-link"
                          variant="success"
                          style={{ display: "block" }}
                        >
                          <i
                            onClick={() => cfcircleOrProjectModes(e, "Skills")}
                            className="icon fa fa-plus-circle"
                          ></i>
                        </a>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <div
            className="card2"
            style={
              lFromState.Skills ? { display: "block" } : { display: "none" }
            }
          >
            <form className="form-new-card" role="form" onSubmit={skillSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  ref={sref}
                  name="card_name"
                  required
                  placeholder="Add skill name"
                  className="form-control"
                  value={skill}
                  onChange={handleSkillChange}
                  autoComplete="off"
                />
              </div>

              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  href="#"
                  type="button"
                  onClick={() => {
                    setSkills({ skillName: skill });
                    setSkillName("");
                    lFromState.Skills = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  href="#"
                  onClick={() => skillsFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => skillsFrom()}
          data-list_id="skills"
          style={
            !lFromState.Skills ? { display: "block" } : { display: "none" }
          }
        >
          + Add new skill
        </a>
      </div>
    </div>
  );

  const FCRoles = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.role &&
        tagFilteredMegedData.role.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="roles"
    >
      <div className="list list-bg-blue" data-list_id="roles">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-role.png" /> Roles (
            <span>{CcorfoRoles.length + CextfoRoles.length}</span>){" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("role")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              href="#"
              onClick={() => {
                toggleEXTnNOCards("roles", "", true, "list");
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("role")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Roles")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Roles")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#rolesModal"
                >
                  Add new role
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Roles")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("roles", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show items owned by external
                </a>
              </li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("roles", "NO", false);
                }}
              >
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          <div
            className="form-group notes"
            style={note.Roles ? { display: "block" } : { display: "none" }}
          >
            <textarea
              className="notes"
              name="Roles"
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              maxLength="100"
              defaultValue={noteSaveData.Roles}
            ></textarea>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => cFRonDrop("cFrCore")}
              onDragOver={(event) => cFRonDragOver(event)}
              id="core"
              className="panel-body box-container"
            >
              {CcorfoRoles && CcorfoRoles.length > 0
                ? CcorfoRoles.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "role"
                      );
                      if (!Proceed) return;
                    }
                    let flagRed = false;
                    let focusedCircle = circlesSorted.find(
                      (c) => c._id === focused.id
                    );
                    let leadOfCicleRedInHtml = focusedCircle.lead;
                    if (leadOfCicleRedInHtml === e._id) {
                      flagRed = true;
                    }

                    let isExternal = IsOwnerExternal(e, "Role");
                    let isPFCircle = isPFCircleAddR(e._id, "core");
                    let allOwnersFlag = isAllOwnersMarked(e._id, "core");
                    // console.log(12491, isPFCircle)
                    return (
                      <div
                        // onDragStart={(e) => rolesDragStart(e, index)}
                        // onDragEnter={(e) => rolesDragEnter(e, index)}
                        // onDragEnd={rolesDrop}
                        onDrag={() => cFRonDrag(e, "cFrCore", isPFCircle)}
                        draggable={flagRed ? false : true}
                        key={e._id}
                        className="card "
                        data-list_id="roles"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setRole(e, CcorfoRoles, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          ) : null}
                          {e.ownerRole.length > 0 &&
                          e.ownerRole === "Single owner" ? (
                            <span className="custom-badge blue-o single-owner-pill">
                              1
                            </span>
                          ) : null}
                          {flagRed ? (
                            <span className="custom-badge red-o direct-lead">
                              LEAD
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <a
                          /* onClick={() => setRole(e, CcorfoRoles, index)} */ className="card-edit"
                        >
                          {e.roleName}
                        </a>
                        {!flagRed ? (
                          <a
                            className="card-unlink"
                            style={{ display: "block", color: "red" }}
                          >
                            <i
                              onClick={() => cFroleSub(e, "core", isPFCircle)}
                              className="icon far fa-times-circle"
                            ></i>
                          </a>
                        ) : null}
                        <br />
                        {isPFCircle &&
                        isPFCircle.length > 0 &&
                        e.owners &&
                        !allOwnersFlag
                          ? isPFCircle.map((pData, indexp) => {
                              return (
                                <span
                                  key={indexp}
                                  onClick={() => OpenCFPeople(pData.Id)}
                                  className="custom-badge tags blue-text"
                                >{`${pData.name}`}</span>
                              );
                            })
                          : null}
                      </div>
                    );
                  })
                : null}

              {temporarilyMovedItem &&
              temporarilyMovedItem.area === "Core" &&
              temporarilyMovedItem.cardType === "Roles" &&
              Object.keys(temporarilyMovedItem.card).length > 0
                ? (() => {
                    return (
                      <div
                        key={temporarilyMovedItem.card._id}
                        className="card move-up-down"
                        style={{ border: "1px dashed #000", opacity: "0.7" }}
                      >
                        <span className="static-relations">
                          {temporarilyMovedItem.card.tags &&
                          temporarilyMovedItem.card.tags.length > 0
                            ? temporarilyMovedItem.card.tags.map(
                                (r, indexs) => (
                                  <span
                                    onClick={() => toggleTagCards(r)}
                                    key={indexs}
                                    className="custom-badge green tags"
                                  >
                                    {r}
                                  </span>
                                )
                              )
                            : null}
                        </span>
                        <a className="card-edit">
                          {temporarilyMovedItem.card.roleName}
                        </a>
                      </div>
                    );
                  })()
                : null}
            </div>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => cFRonDrop("cFrExtend")}
              onDragOver={(event) => cFRonDragOver(event)}
              id="extended"
              className="panel-body box-container"
            >
              {/* Extended members listed here*/}
              {CextfoRoles && CextfoRoles.length > 0
                ? CextfoRoles.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "role"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Role");
                    let isPFCircle = isPFCircleAddR(e._id, "extended");
                    let allOwnersFlag = isAllOwnersMarked(e._id, "extended");

                    return (
                      <div
                        // onDragStart={(e) => rolesDragStart(e, index)}
                        // onDragEnter={(e) => rolesDragEnter(e, index)}
                        // onDragEnd={rolesDrop}
                        onDrag={() => cFRonDrag(e, "cFrExtend", isPFCircle)}
                        draggable
                        key={e._id}
                        className="card "
                        data-list_id="roles"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setRole(e, CextfoRoles, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          ) : null}
                          {e.ownerRole.length > 0 &&
                          e.ownerRole === "Single owner" ? (
                            <span className="custom-badge blue-o single-owner-pill">
                              1
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <a
                          // onClick={() => setRole(e, CextfoRoles, index)}
                          className="card-edit"
                        >
                          {e.roleName}
                        </a>
                        <a
                          className="card-unlink"
                          style={{ display: "block", color: "red" }}
                        >
                          <i
                            onClick={() => cFroleSub(e, "extend", isPFCircle)}
                            className="icon far fa-times-circle"
                          ></i>
                        </a>
                        <br />
                        {isPFCircle &&
                        isPFCircle.length > 0 &&
                        e.owners &&
                        !allOwnersFlag
                          ? isPFCircle.map((pData, indexp) => {
                              return (
                                <span
                                  key={indexp}
                                  onClick={() => OpenCFPeople(pData.Id)}
                                  className="custom-badge tags blue-text"
                                >{`${pData.name}`}</span>
                              );
                            })
                          : null}
                      </div>
                    );
                  })
                : null}

              {temporarilyMovedItem &&
              temporarilyMovedItem.area === "Extended" &&
              temporarilyMovedItem.cardType === "Roles" &&
              Object.keys(temporarilyMovedItem.card).length > 0
                ? (() => {
                    return (
                      <div
                        key={temporarilyMovedItem.card._id}
                        className="card move-up-down"
                        style={{ border: "1px dashed #000", opacity: "0.7" }}
                      >
                        <span className="static-relations">
                          {temporarilyMovedItem.card.tags &&
                          temporarilyMovedItem.card.tags.length > 0
                            ? temporarilyMovedItem.card.tags.map(
                                (r, indexs) => (
                                  <span
                                    onClick={() => toggleTagCards(r)}
                                    key={indexs}
                                    className="custom-badge green tags"
                                  >
                                    {r}
                                  </span>
                                )
                              )
                            : null}
                        </span>
                        <a className="card-edit">
                          {temporarilyMovedItem.card.roleName}
                        </a>
                      </div>
                    );
                  })()
                : null}
            </div>
          </div>

          <span className="clearable2">
            <input
              type="text"
              ref={fcRref}
              style={{ color: "black" }}
              value={cfRolesSearch}
              onChange={(e) => setCfRolesSearch(e.target.value)}
              className="form-control search_list2 transparent"
              name="search_list"
              placeholder="Search to select more"
              autoComplete="off"
            />
            {cfRolesSearch.length > 0 ? (
              <i
                className="clearable__clear"
                onClick={() => setCfRolesSearch("")}
                style={{ display: "inline", color: "black" }}
              >
                ×
              </i>
            ) : null}
          </span>

          <div className="panel panel-default">
            <div
              onDrop={() => cFRonDrop("cFrDefult")}
              onDragOver={(event) => cFRonDragOver(event)}
              id="notmatched"
              className="panel-body box-container"
            >
              {filteredCFRData && filteredCFRData.length > 0
                ? filteredCFRData.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "role"
                      );
                      if (!Proceed) return;
                    }
                    if (
                      temporarilyMovedItem &&
                      temporarilyMovedItem.cardType === "Roles" &&
                      Object.keys(temporarilyMovedItem.card).length > 0 &&
                      temporarilyMovedItem.card._id === e._id
                    )
                      return;

                    let isExternal = IsOwnerExternal(e, "Role");

                    /* 
            The below two lines are added to prevent items from CcorfoRoles and/ or CextfoRoles appearing in notmatched section. This is done as a quick fix instead of customizing drop function.
            */
                    if (CcorfoRoles.some((item) => item._id === e._id)) return;
                    if (CextfoRoles.some((item) => item._id === e._id)) return;

                    return (
                      <div
                        // onDragStart={(e) => rolesDragStart(e, index)}
                        // onDragEnter={(e) => rolesDragEnter(e, index)}
                        // onDragEnd={rolesDrop}
                        onDrag={() => cFRonDrag(e, "cFrDefult")}
                        draggable
                        key={index}
                        className={`card notmatched ${
                          localStorage.getItem("latest-created") === e._id
                            ? " latest-created "
                            : ""
                        }`}
                        data-list_id="roles"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setRole(e, filteredCFRData, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          ) : null}
                          {e.ownerRole.length > 0 &&
                          e.ownerRole === "Single owner" ? (
                            <span className="custom-badge blue-o single-owner-pill">
                              1
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>

                        <a
                          // onClick={() => setRole(e, filteredCFRData, index)}
                          className="card-edit"
                        >
                          {e.roleName}
                        </a>

                        <OverlayTrigger
                          trigger="click"
                          placement="right"
                          overlay={popover}
                        >
                          <a
                            className="card-link"
                            variant="success"
                            style={{ display: "block" }}
                          >
                            <i
                              onClick={() => cfcircleOrProjectModes(e, "Roles")}
                              className="icon fa fa-plus-circle"
                            ></i>
                          </a>
                        </OverlayTrigger>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <div
            className="card2"
            style={
              lFromState.Roles ? { display: "block" } : { display: "none" }
            }
          >
            <form className="form-new-card" role="form" onSubmit={roleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  ref={rref}
                  name="card_name"
                  required
                  placeholder="Add role name"
                  className="form-control"
                  value={role}
                  onChange={handleRoleChange}
                  autoComplete="off"
                />
              </div>
              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  href="#"
                  type="button"
                  onClick={() => {
                    setRoles({ roleName: role });
                    setRoleName("");
                    lFromState.Roles = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  href="#"
                  onClick={() => rolesFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => rolesFrom()}
          data-list_id="roles"
          style={!lFromState.Roles ? { display: "block" } : { display: "none" }}
        >
          + Add new role
        </a>
      </div>
    </div>
  );

  const FCDomains = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.domain &&
        tagFilteredMegedData.domain.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="domains"
    >
      <div className="list list-bg-blue" data-list_id="domains">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-domain.png" /> Domains (
            <span>{CcorfoDomains.length + CextfoDomains.length}</span>){" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("domain")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              href="#"
              onClick={() => {
                toggleEXTnNOCards("domains", "", true, "list");
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("domain")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Domains")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Domains")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#domainModal"
                >
                  Add new domain
                </a>
              </li>

              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Domains")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("domains", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show items owned by external
                </a>
              </li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("domains", "NO", false);
                }}
              >
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          <div
            className="form-group notes"
            style={note.Domains ? { display: "block" } : { display: "none" }}
          >
            <textarea
              className="notes"
              name="Domains"
              // defaultValue=''
              // value={noteSaveData.Domains}
              //   onChange={(e) => handleSave(e)}
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              maxLength="100"
              defaultValue={noteSaveData.Domains}
            ></textarea>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => cFDonDrop("cFdCore")}
              onDragOver={(event) => cFDonDragOver(event)}
              id="core"
              className="panel-body box-container"
            >
              {/* Core members listed here*/}
              {CcorfoDomains && CcorfoDomains.length > 0
                ? CcorfoDomains.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "domain"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Domain");
                    let whoIsOwnerType = dWhoIsOwnerType(e);
                    // console.log('my ', e.domainName, whoIsOwnerType, Object.keys(whoIsOwnerType).length)
                    let isPFCircle = isPFCircleAddD(e._id, "core");
                    let curPills = [];
                    if (cfRAnfdSDomain && cfRAnfdSDomain.length > 0) {
                      for (let i = 0; i < cfRAnfdSDomain.length; i++) {
                        // console.log('km ', cfRAnfdSDomain[i], e.domainName)
                        if (
                          cfRAnfdSDomain[i].domainId === e._id &&
                          Object.keys(whoIsOwnerType).length &&
                          e._id !== whoIsOwnerType.cardId
                        ) {
                          curPills.push(cfRAnfdSDomain[i]);
                        }
                      }
                    }

                    let inc = curCoreMembers.Domains.includes(e._id);
                    if (!inc) return;

                    return (
                      <div
                        // onDragStart={(e) => domainsDragStart(e, index)}
                        // onDragEnter={(e) => domainsDragEnter(e, index)}
                        // onDragEnd={domainsDrop}
                        onDrag={() => cFDonDrag(e, "cFdCore", isPFCircle)}
                        /* draggable={
                    curPills && curPills.length > 0 ? false : true
                  } */
                        draggable
                        key={index}
                        className="card "
                        data-list_id="domain"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setDomain(e, CcorfoDomains, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners &&
                          e.owners.owner &&
                          e.owners.owner.length > 0 &&
                          OwnerExist([e.owners.owner]) ? null : (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          )}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}

                          {curPills && curPills.length > 0 ? (
                            curPills.map((r, index) => (
                              <span
                                onClick={() => cardOpensCfDomain(r)}
                                key={index}
                                className="custom-badge lightgray"
                              >
                                {`${r.cardName} (${r.type})`}
                              </span>
                            ))
                          ) : whoIsOwnerType &&
                            Object.keys(whoIsOwnerType).length > 0 ? (
                            <span
                              onClick={() => cardOpensCfDomain(whoIsOwnerType)}
                              className="custom-badge lightgray"
                            >
                              {whoIsOwnerType.name}
                            </span>
                          ) : null}
                        </div>
                        <a
                          // onClick={() => setDomain(e, CcorfoDomains, index)}
                          className="card-edit"
                        >
                          {e.domainName}
                        </a>
                        {/* {curPills.length === 0 ? ( */}
                        <a
                          className="card-unlink"
                          style={{ display: "block", color: "red" }}
                        >
                          <i
                            onClick={() => cFdomainSub(e, "core")}
                            className="icon far fa-times-circle"
                          ></i>
                        </a>
                        {/* ) : null} */}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => cFDonDrop("cFdExtend")}
              onDragOver={(event) => cFDonDragOver(event)}
              id="extended"
              className="panel-body box-container"
            >
              {/* Extended members listed here*/}
              {CextfoDomains && CextfoDomains.length > 0
                ? CextfoDomains.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "domain"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Domain");
                    let whoIsOwnerType = dWhoIsOwnerType(e);
                    let isPFCircle = isPFCircleAddD(e._id, "extended");
                    let curPills = [];
                    if (cfRAnfdSExDomain && cfRAnfdSExDomain.length > 0) {
                      for (let i = 0; i < cfRAnfdSExDomain.length; i++) {
                        if (
                          cfRAnfdSExDomain[i].domainId === e._id &&
                          e._id !== whoIsOwnerType.cardId
                        ) {
                          curPills.push(cfRAnfdSExDomain[i]);
                        }
                      }
                    }
                    let inc = curExtendedMembers.Domains.includes(e._id);
                    if (!inc) return;

                    return (
                      <div
                        // onDragStart={(e) => domainsDragStart(e, index)}
                        // onDragEnter={(e) => domainsDragEnter(e, index)}
                        // onDragEnd={domainsDrop}
                        onDrag={() => cFDonDrag(e, "cFdExtend", isPFCircle)}
                        draggable
                        key={index}
                        className="card"
                        data-list_id="domain"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setDomain(e, CextfoDomains, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners &&
                          e.owners.owner &&
                          e.owners.owner.length > 0 &&
                          OwnerExist([e.owners.owner]) ? null : (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          )}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                          {curPills && curPills.length > 0 ? (
                            curPills.map((r, index) => (
                              <span
                                onClick={() => cardOpensCfDomain(r)}
                                key={index}
                                className="custom-badge lightgray"
                              >
                                {`${r.cardName} (${r.type})`}
                              </span>
                            ))
                          ) : whoIsOwnerType &&
                            Object.keys(whoIsOwnerType).length > 0 ? (
                            <span
                              onClick={() => cardOpensCfDomain(whoIsOwnerType)}
                              className="custom-badge lightgray"
                            >
                              {whoIsOwnerType.name}
                            </span>
                          ) : null}
                        </div>
                        <a
                          /* onClick={() => setDomain(e, CextfoDomains, index)} */ className="card-edit"
                        >
                          {e.domainName}
                        </a>
                        {curPills.length === 0 ? (
                          <a
                            className="card-unlink"
                            style={{ display: "block", color: "red" }}
                          >
                            <i
                              onClick={() => cFdomainSub(e, "extend")}
                              className="icon far fa-times-circle"
                            ></i>
                          </a>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <span className="clearable2">
            <input
              type="text"
              ref={fcDref}
              style={{ color: "black" }}
              value={cfDomainsSearch}
              onChange={(e) => setCfDomainsSearch(e.target.value)}
              className="form-control search_list2 transparent"
              name="search_list"
              placeholder="Search to select more"
              autoComplete="off"
            />
            {cfDomainsSearch.length > 0 ? (
              <i
                className="clearable__clear"
                onClick={() => setCfDomainsSearch("")}
                style={{ display: "inline", color: "black" }}
              >
                ×
              </i>
            ) : null}
          </span>

          <div className="panel panel-default">
            <div
              onDrop={() => cFDonDrop("cFdDefult")}
              onDragOver={(event) => cFDonDragOver(event)}
              id="notmatched"
              className="panel-body box-container"
            >
              {filteredCFDData && filteredCFDData.length > 0
                ? filteredCFDData.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "domain"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Domain");
                    let whoIsOwnerType = dWhoIsOwnerType(e);
                    return (
                      <div
                        // onDragStart={(e) => domainsDragStart(e, index)}
                        // onDragEnter={(e) => domainsDragEnter(e, index)}
                        // onDragEnd={domainsDrop}
                        onDrag={() => cFDonDrag(e, "cFdDefult")}
                        // draggable={e.owners.type ? false : true}
                        draggable
                        key={index}
                        className={`card notmatched ${
                          localStorage.getItem("latest-created") === e._id
                            ? " latest-created "
                            : ""
                        }`}
                        data-list_id="domains"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setDomain(e, filteredCFDData, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners &&
                          e.owners.owner &&
                          e.owners.owner.length > 0 &&
                          OwnerExist([e.owners.owner]) ? null : (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          )}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}

                          {whoIsOwnerType &&
                          Object.keys(whoIsOwnerType).length > 0 ? (
                            <span
                              // onClick={() => openSkillAndRoleByDPill(whoIsOwnerType)}
                              onClick={() => cardOpensCfDomain(whoIsOwnerType)}
                              className="custom-badge lightgray"
                            >
                              {whoIsOwnerType.name}
                            </span>
                          ) : null}
                        </div>

                        <a
                          className="card-edit" /* onClick={() => setDomain(e, filteredCFDData, index)} */
                        >
                          {e.domainName}
                        </a>

                        {/* {!e.owners.type ? */}
                        <OverlayTrigger
                          trigger="click"
                          placement={
                            list[list.length - 1] === "Domains"
                              ? "left"
                              : "right"
                          }
                          overlay={popover}
                        >
                          <a
                            className="card-link"
                            variant="success"
                            style={{ display: "block" }}
                          >
                            <i
                              onClick={() => cFPeopleHandel(e, "Domains")}
                              className="icon fa fa-plus-circle"
                            ></i>
                          </a>
                        </OverlayTrigger>
                        {/* : null} */}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <div
            className="card2"
            style={
              lFromState.Domains ? { display: "block" } : { display: "none" }
            }
          >
            <form className="form-new-card" role="form" onSubmit={domainSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  ref={dref}
                  name="card_name"
                  required
                  placeholder="Add domain name"
                  className="form-control"
                  value={domain}
                  onChange={handleDomainChange}
                  autoComplete="off"
                />
              </div>
              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  href="#"
                  type="button"
                  onClick={() => {
                    setDomains({ domainName: domain });
                    setDomainName("");
                    lFromState.Domains = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  href="#"
                  onClick={() => domainsFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => domainsFrom()}
          data-list_id="domain"
          style={
            !lFromState.Domains ? { display: "block" } : { display: "none" }
          }
        >
          + Add new domain
        </a>
      </div>
    </div>
  );

  const FCLinks = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.link &&
        tagFilteredMegedData.link.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="links"
    >
      <div className="list list-bg-blue" data-list_id="links">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-link.png" /> Links (
            <span>{CcorfoLinks.length + CextfoLinks.length}</span>){" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("link")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              href="#"
              onClick={() => {
                toggleEXTnNOCards("links", "", true, "list");
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("link")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Links")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Links")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#linksModal"
                >
                  Add new link
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Links")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("links", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show items owned by external
                </a>
              </li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("links", "NO", false);
                }}
              >
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          <div
            className="form-group notes"
            style={note.Links ? { display: "block" } : { display: "none" }}
          >
            <textarea
              className="notes"
              name="Links"
              // defaultValue=''
              // value={noteSaveData.Links}
              //   onChange={(e) => handleSave(e)}
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              maxLength="100"
              defaultValue={noteSaveData.Links}
            ></textarea>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => cFLonDrop("cFlCore")}
              onDragOver={(event) => cFLonDragOver(event)}
              id="core"
              className="panel-body box-container"
            >
              {/* Core members listed here*/}
              {CcorfoLinks && CcorfoLinks.length > 0
                ? CcorfoLinks.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "link"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Link");
                    let isPFCircle = isPFCircleAddL(e._id, "core");
                    return (
                      <div
                        // onDragStart={(e) => linksDragStart(e, index)}
                        // onDragEnter={(e) => linksDragEnter(e, index)}
                        // onDragEnd={linksDrop}
                        onDrag={() => cFLonDrag(e, "cFlCore", isPFCircle)}
                        draggable
                        key={index}
                        className="card"
                        data-list_id="links"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setLink(e, CcorfoLinks, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owner &&
                          e.owner.length > 0 &&
                          OwnerExist([e.owner]) ? null : (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          )}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <a
                          // onClick={() => setLink(e, CcorfoLinks, index)}
                          className="card-edit"
                        >
                          {e.linkName}
                        </a>
                        <a
                          className="card-unlink"
                          style={{ display: "block", color: "red" }}
                        >
                          <i
                            onClick={() => cFlinkSub(e, "core")}
                            className="icon far fa-times-circle"
                          ></i>
                        </a>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => cFLonDrop("cFlExtend")}
              onDragOver={(event) => cFLonDragOver(event)}
              id="extended"
              className="panel-body box-container"
            >
              {/* Extended members listed here*/}
              {CextfoLinks && CextfoLinks.length > 0
                ? CextfoLinks.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "link"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Link");
                    let isPFCircle = isPFCircleAddL(e._id, "extended");
                    return (
                      <div
                        // onDragStart={(e) => linksDragStart(e, index)}
                        // onDragEnter={(e) => linksDragEnter(e, index)}
                        // onDragEnd={linksDrop}
                        onDrag={() => cFLonDrag(e, "cFlExtend", isPFCircle)}
                        draggable
                        key={index}
                        className="card "
                        data-list_id="links"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setLink(e, CextfoLinks, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owner &&
                          e.owner.length > 0 &&
                          OwnerExist([e.owner]) ? null : (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          )}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <a
                          // onClick={() => setLink(e, CextfoLinks, index)}
                          className="card-edit"
                        >
                          {e.linkName}
                        </a>
                        <a
                          className="card-unlink"
                          style={{ display: "block", color: "red" }}
                        >
                          <i
                            onClick={() => cFlinkSub(e, "extend")}
                            className="icon far fa-times-circle"
                          ></i>
                        </a>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <span className="clearable2">
            <input
              type="text"
              ref={fcLref}
              style={{ color: "black" }}
              value={cfLinksSearch}
              onChange={(e) => setCfLinksSearch(e.target.value)}
              className="form-control search_list2 transparent"
              name="search_list"
              placeholder="Search to select more"
              autoComplete="off"
            />
            {cfLinksSearch.length > 0 ? (
              <i
                className="clearable__clear"
                onClick={() => setCfLinksSearch("")}
                style={{ display: "inline", color: "black" }}
              >
                ×
              </i>
            ) : null}
          </span>

          <div className="panel panel-default">
            <div
              onDrop={() => cFLonDrop("cFlDefult")}
              onDragOver={(event) => cFLonDragOver(event)}
              id="notmatched"
              className="panel-body box-container"
            >
              {filteredCFLData && filteredCFLData.length > 0
                ? filteredCFLData.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "link"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Link");
                    return (
                      <div
                        // onDragStart={(e) => linksDragStart(e, index)}
                        // onDragEnter={(e) => linksDragEnter(e, index)}
                        // onDragEnd={linksDrop}
                        onDrag={() => cFLonDrag(e, "cFlDefult")}
                        draggable
                        key={index}
                        className={`card notmatched ${
                          localStorage.getItem("latest-created") === e._id
                            ? " latest-created "
                            : ""
                        }`}
                        data-list_id="links"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setLink(e, filteredCFLData, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owner &&
                          e.owner.length > 0 &&
                          OwnerExist([e.owner]) ? null : (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          )}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <a
                          className="card-edit"
                          // onClick={() => setLink(e, filteredCFLData, index)}
                        >
                          {e.linkName}
                        </a>

                        <OverlayTrigger
                          trigger="click"
                          placement={
                            list[list.length - 1] === "Links" ? "left" : "right"
                          }
                          overlay={popover}
                        >
                          <a
                            className="card-link"
                            variant="success"
                            style={{ display: "block" }}
                          >
                            <i
                              onClick={() => cFPeopleHandel(e, "Links")}
                              className="icon fa fa-plus-circle"
                            ></i>
                          </a>
                        </OverlayTrigger>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <div
            className="card2"
            style={
              lFromState.Links ? { display: "block" } : { display: "none" }
            }
          >
            <form className="form-new-card" role="form" onSubmit={linkSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  ref={lref}
                  name="card_name"
                  required
                  placeholder="Add link name"
                  className="form-control"
                  value={link}
                  onChange={handleLinkChange}
                  autoComplete="off"
                />
              </div>
              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  href="#"
                  type="button"
                  onClick={() => {
                    setLinks({ linkName: link });
                    setLinkName("");
                    lFromState.Links = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  href="#"
                  onClick={() => linksFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => linksFrom()}
          data-list_id="links"
          style={!lFromState.Links ? { display: "block" } : { display: "none" }}
        >
          + Add new link
        </a>
      </div>
    </div>
  );

  const FCCircles = () => (
    <div className="board-col" data-board_id="circles">
      <div className="list list-bg-red" data-list_id="circles">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-circle.png" /> Circles ({circlesSorted.length}
            ){" "}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Circles")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Circles")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#circlesModal"
                  onClick={() => setCircles()}
                >
                  Add new circle
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Circles")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="hide">
                <a role="menuitem" className="show-external">
                  Show external people
                </a>
              </li>
              <li role="presentation" className="hide">
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>
        <div className="card-list scrollbar" ref={focusListRef}>
          <div
            className="form-group notes"
            style={note.Circles ? { display: "block" } : { display: "none" }}
          >
            <textarea
              className="notes"
              name="Circles"
              // defaultValue=''
              // value={noteSaveData.Circles}
              //   onChange={(e) => handleSave(e)}
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              maxLength="100"
              defaultValue={noteSaveData.Circles}
            ></textarea>
          </div>

          {CFCircleSorted && CFCircleSorted.length > 0
            ? CFCircleSorted.map((e, index) => {
                /* you can use existing function or tagFilterdata necause it is only affected by tagFilter Day */
                if ((isTag || isEXT || isNO) && e._id !== focused.id) {
                  let Proceed = TagWiseSorting(
                    e,
                    isTag,
                    isEXT,
                    isNO,
                    tagFilteredObj,
                    extNoFilteredObj,
                    "circle"
                  );
                  if (!Proceed) return;
                }
                return (
                  <div
                    key={index}
                    // onDragStart={(e) => circlesDragStart(e, index)}
                    // onDragEnter={(e) => circlesDragEnter(e, index)}
                    // onDragEnd={circlesDrop}
                    draggable={
                      focused && focused.card && focused.card !== "Circles"
                        ? true
                        : false
                    }
                    className={`card ${
                      focused.card === "Circles" && focused.id === e._id
                        ? "focused-card "
                        : localStorage.getItem("latest-created") === e._id
                        ? " latest-created "
                        : ""
                    }`}
                    data-list_id="circles"
                    onClick={(event) => {
                      if (
                        event.target.classList.contains("card") ||
                        event.target.classList.contains("card-edit")
                      ) {
                        setCircle(e, CFCircleSorted, index);
                      }

                      if (parentCircleRef.current) {
                        const scrollTop = parentCircleRef.current.scrollTop;
                        // console.log("Scroll position:", scrollTop);
                        setFocusedRef(scrollTop);
                      }
                    }}
                  >
                    <div className="static-relations">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, index) => (
                            <span
                              onClick={() => toggleTagCards(r)}
                              key={index}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                        : null}
                    </div>
                    <a
                      // onClick={() => setCircle(e, CFCircleSorted, index)}
                      className="card-edit"
                    >
                      {e.circleName}
                    </a>
                    {focused.card === "Circles" && focused.id === e._id ? (
                      <a className="card-unlink" style={{ display: "block" }}>
                        <i
                          onClick={() =>
                            handelFocused({
                              card: "CCircles",
                              index: null,
                              id: null,
                            })
                          }
                          className="icon far fa-times-circle fas"
                        ></i>
                      </a>
                    ) : focused.card === "Circles" && focused.id !== e._id ? (
                      <a className="card-focus">
                        <img
                          onClick={() =>
                            handelFocused({
                              card: "Circles",
                              index: index,
                              id: e._id,
                            })
                          }
                          src="./images/newicons/focus.svg"
                        />
                      </a>
                    ) : null}
                  </div>
                );
              })
            : null}
          <div
            className="card2"
            style={
              lFromState.Circles ? { display: "block" } : { display: "none" }
            }
          >
            <form className="form-new-card" role="form" onSubmit={circleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  ref={cref}
                  name="card_name"
                  required
                  placeholder="Add circle name"
                  className="form-control"
                  value={circle}
                  onChange={handleCircleChange}
                  autoComplete="off"
                />
              </div>
              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  href="#"
                  type="button"
                  onClick={() => {
                    setCircles();
                    setCircleName("");
                    lFromState.Circles = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  href="#"
                  onClick={() => circlesFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => circlesFrom()}
          data-list_id="circles"
          style={
            !lFromState.Circles ? { display: "block" } : { display: "none" }
          }
        >
          + Add new circle
        </a>
      </div>
    </div>
  );

  const FCProjects = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.project &&
        tagFilteredMegedData.project.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="projects"
    >
      <div className="list list-bg-red" data-list_id="projects">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-project.png" /> Projects (
            <span>{CcorfoProjects.length}</span>){" "}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Projects")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Projects")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#projectsModal"
                  onClick={() => setProjects()}
                >
                  Add new project
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Projects")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="hide">
                <a role="menuitem" className="show-external">
                  Show external people
                </a>
              </li>
              <li role="presentation" className="hide">
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          <div
            className="form-group notes"
            style={note.Projects ? { display: "block" } : { display: "none" }}
          >
            <textarea
              className="notes"
              name="Projects"
              // defaultValue=''
              // value={noteSaveData.Projects}
              //   onChange={(e) => handleSave(e)}
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              maxLength="100"
              defaultValue={noteSaveData.Projects}
            ></textarea>
          </div>
          {/* <h6 style={{"borderBottom": "1px solid #000"}}>Core members</h6> */}
          <div className="panel panel-default">
            <div
              onDrop={() => cFPronDrop("cFPrCore")}
              onDragOver={(event) => cFPronDragOver(event)}
              id="core"
              className="panel-body box-container"
            >
              {/* Core members listed here*/}
              {CcorfoProjects && CcorfoProjects.length > 0
                ? CcorfoProjects.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "project"
                      );
                      if (!Proceed) return;
                    }
                    return (
                      <div
                        // onDragStart={(e) => projectsDragStart(e, index)}
                        // onDragEnter={(e) => projectsDragEnter(e, index)}
                        // onDragEnd={projectsDrop}
                        onDrag={() => cFPronDrag(e, "cFPrCore")}
                        draggable
                        key={index}
                        className="card "
                        data-list_id="projects"
                        style={{ minHeight: "60px" }}
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setProject(e, CcorfoProjects, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <a
                          // onClick={() => setProject(e, CcorfoProjects, index)}
                          className="card-edit"
                        >
                          {e.projectName}
                        </a>
                        <a className="card-focus">
                          <img
                            onClick={() => PfToCAndP("Projects", e)}
                            src="./images/newicons/focus.svg"
                          />
                        </a>
                        <a
                          className="card-unlink"
                          style={{ display: "block", color: "red" }}
                        >
                          <i
                            onClick={() => cFprojectSub(e, "core")}
                            className="icon far fa-times-circle"
                          ></i>
                        </a>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <span className="clearable2">
            <input
              type="text"
              ref={fcPrref}
              style={{ color: "black" }}
              value={cfProjectsSearch}
              onChange={(e) => setCfProjectsSearch(e.target.value)}
              className="form-control search_list2 transparent"
              name="search_list"
              placeholder="Search to select more"
              autoComplete="off"
            />
            {cfProjectsSearch.length > 0 ? (
              <i
                className="clearable__clear"
                onClick={() => setCfProjectsSearch("")}
                style={{ display: "inline", color: "black" }}
              >
                ×
              </i>
            ) : null}
          </span>

          <div className="panel panel-default">
            <div
              onDrop={() => cFPronDrop("cFPrDefult")}
              onDragOver={(event) => cFPronDragOver(event)}
              id="notmatched"
              className="panel-body box-container"
            >
              {filteredCFPrData && filteredCFPrData.length > 0
                ? filteredCFPrData.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "project"
                      );
                      if (!Proceed) return;
                    }
                    let isAlreadyAssociated = IsAlreadyAssociated(e);
                    return (
                      <div
                        // onDragStart={(e) => projectsDragStart(e, index)}
                        // onDragEnter={(e) => projectsDragEnter(e, index)}
                        // onDragEnd={projectsDrop}
                        onDrag={() => cFPronDrag(e, "cFPrDefult")}
                        draggable={!isAlreadyAssociated}
                        key={index}
                        className={`card notmatched ${
                          localStorage.getItem("latest-created") === e._id
                            ? " latest-created "
                            : ""
                        }`}
                        data-list_id="projects"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setProject(e, filteredCFPrData, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <a
                          // onClick={() => setProject(e, filteredCFPrData, index)}
                          className="card-edit"
                        >
                          {e.projectName}
                        </a>
                        {!isAlreadyAssociated ? (
                          <a
                            className="card-link"
                            variant="success"
                            style={{ display: "block" }}
                          >
                            <i
                              onClick={() => {
                                cFPeopleHandel(e, "Projects");
                                cFPeopleIdHandel("Core");
                              }}
                              className="icon fa fa-plus-circle"
                            ></i>
                          </a>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <div
            className="card2"
            style={
              lFromState.Projects ? { display: "block" } : { display: "none" }
            }
          >
            <form
              className="form-new-card"
              role="form"
              onSubmit={projectSubmit}
            >
              <div className="form-group">
                <input
                  type="text"
                  ref={prref}
                  name="card_name"
                  value={project}
                  onChange={handleProjectChange}
                  required
                  placeholder="Add project name"
                  className="form-control"
                  autoComplete="off"
                />
              </div>
              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  href="#"
                  type="button"
                  onClick={() => {
                    setProjects({ projectName: project });
                    setProjectName("");
                    lFromState.Projects = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  href="#"
                  onClick={() => projectsFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => projectsFrom()}
          data-list_id="projects"
          style={
            !lFromState.Projects ? { display: "block" } : { display: "none" }
          }
        >
          + Add new project
        </a>
      </div>
    </div>
  );

  //'''''''''''''''''''''''''''''''''''''''' Xls And Jpg Downlod ''''''''''''''''''''''''''''''''''''''''''''''''
  let circleFXls = [];
  let xlsCPeople = [...CcorfoPeople, ...CextfoPeople];
  let xlsCSkill = [...CcorfoSkill, ...CextfoSkill];
  let xlsCRole = [...CcorfoRoles, ...CextfoRoles];
  let xlsCDomain = [...CcorfoDomains, ...CextfoDomains];
  let xlsCLink = [...CcorfoLinks, ...CextfoLinks];

  if (focused && focused.card.length > 0 && focused.card === "Circles") {
    if (
      xlsCPeople.length > 0 ||
      xlsCSkill.length > 0 ||
      xlsCRole.length > 0 ||
      xlsCDomain.length > 0 ||
      xlsCLink.length > 0 ||
      circlesSorted[focused.index] ||
      CcorfoProjects.length > 0
    ) {
      let arr = [
        xlsCPeople.length,
        xlsCSkill.length,
        xlsCRole.length,
        xlsCDomain.length,
        xlsCLink.length,
        1,
        CcorfoProjects.length,
      ];

      if (arr && arr.length > 0) {
        const max = arr.reduce((a, b) => {
          return Math.max(a, b);
        });

        let xlsCircle = circlesSorted.find((c) => c._id === focused.id); // [circlesSorted[focused.index]];
        if (xlsCircle) {
          for (let i = 0; i < max; i++) {
            let obj = {
              People:
                xlsCPeople[i] && xlsCPeople[i]._id
                  ? `${xlsCPeople[i].fname} ${xlsCPeople[i].lname}`
                  : "",
              Skill:
                xlsCSkill[i] && xlsCSkill[i]._id
                  ? `${xlsCSkill[i].skillName}`
                  : "",
              Role:
                xlsCRole[i] && xlsCRole[i]._id ? `${xlsCRole[i].roleName}` : "",
              Domain:
                xlsCDomain[i] && xlsCDomain[i]._id
                  ? `${xlsCDomain[i].domainName}`
                  : "",
              Link:
                xlsCLink[i] && xlsCLink[i]._id ? `${xlsCLink[i].linkName}` : "",
              Circle:
                xlsCircle[i] && xlsCircle[i]._id
                  ? `${xlsCircle[i].circleName}`
                  : "",
              Project:
                CcorfoProjects[i] && CcorfoProjects[i]._id
                  ? `${CcorfoProjects[i].projectName}`
                  : "",
            };
            // console.log(obj);
            circleFXls.push(obj);
          }
        }
      }
    }
  }

  useEffect(() => {
    if (focused && focused.card.length > 0 && focused.card === "Circles") {
      setXls(circleFXls);
    }
  }, [focused]);
  /* Circle Focus - End */

  //========================================= Project focus Action ===================================================
  const pFpeopleSub = (data, type) => {
    // console.log('pFpeopleSub', data, type === "core");
    if (
      type === "core" &&
      popoverIds &&
      popoverIds.coreMembers &&
      popoverIds.coreMembers.People
    ) {
      popoverIds.coreMembers.People = popoverIds.coreMembers.People.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverIds &&
      popoverIds.extendedMembers &&
      popoverIds.extendedMembers.People
    ) {
      popoverIds.extendedMembers.People =
        popoverIds.extendedMembers.People.filter((e) => {
          return e !== data._id;
        });
    }

    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        dispatch(getMember(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  const pFskillSub = (data, type) => {
    if (
      type === "core" &&
      popoverIds &&
      popoverIds.coreMembers &&
      popoverIds.coreMembers.Skills
    ) {
      popoverIds.coreMembers.Skills = popoverIds.coreMembers.Skills.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverIds &&
      popoverIds.extendedMembers &&
      popoverIds.extendedMembers.Skills
    ) {
      popoverIds.extendedMembers.Skills =
        popoverIds.extendedMembers.Skills.filter((e) => {
          return e !== data._id;
        });
    }

    let updated_pfmemberIds = { ...pfmemberIds };

    if (
      type === "core" &&
      updated_pfmemberIds &&
      updated_pfmemberIds.coreMembers &&
      updated_pfmemberIds.coreMembers.Skills &&
      updated_pfmemberIds.coreMembers.Skills.length > 0
    ) {
      updated_pfmemberIds = {
        ...updated_pfmemberIds, // Use the correct variable name here
        coreMembers: {
          ...updated_pfmemberIds.coreMembers,
          Skills: updated_pfmemberIds.coreMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberIds.coreMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberIds.coreMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberIds.coreMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberIds.allOwnersFlag,
          core: updated_pfmemberIds.allOwnersFlag.core.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberIds(updated_pfmemberIds);
    }

    if (
      type === "core" &&
      updated_pfmemberIds &&
      updated_pfmemberIds.coreMembers &&
      updated_pfmemberIds.extendedMembers.Skills &&
      updated_pfmemberIds.extendedMembers.Skills.length > 0
    ) {
      updated_pfmemberIds = {
        ...updated_pfmemberIds, // Use the correct variable name here
        extendedMembers: {
          ...updated_pfmemberIds.extendedMembers,
          Skills: updated_pfmemberIds.extendedMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberIds.extendedMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberIds.extendedMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberIds.extendedMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberIds.allOwnersFlag,
          extended: updated_pfmemberIds.allOwnersFlag.extended.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberIds(updated_pfmemberIds);
    }

    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        dispatch(getMember(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }

    if (updated_pfmemberIds) {
      updated_pfmemberIds.adminId = myInfo.userId;
      updated_pfmemberIds.url = `${SERVER_URI}/user/pfmember/create`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = updated_pfmemberIds.teamId;
      dispatch(createAndUpdateMember(updated_pfmemberIds)).then(() => {
        dispatch(getPFMember(obj));
        unfreezeWindow();
      });
    }
  };

  const pFroleSub = (data, type) => {
    if (
      type === "core" &&
      popoverIds &&
      popoverIds.coreMembers &&
      popoverIds.coreMembers.Roles
    ) {
      popoverIds.coreMembers.Roles = popoverIds.coreMembers.Roles.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverIds &&
      popoverIds.extendedMembers &&
      popoverIds.extendedMembers.Roles
    ) {
      popoverIds.extendedMembers.Roles =
        popoverIds.extendedMembers.Roles.filter((e) => {
          return e !== data._id;
        });
    }

    let updated_pfmemberIds = { ...pfmemberIds };

    if (
      type === "core" &&
      updated_pfmemberIds &&
      updated_pfmemberIds.coreMembers &&
      updated_pfmemberIds.coreMembers.Roles &&
      updated_pfmemberIds.coreMembers.Roles.length > 0
    ) {
      updated_pfmemberIds = {
        ...updated_pfmemberIds, // Use the correct variable name here
        coreMembers: {
          ...updated_pfmemberIds.coreMembers,
          Skills: updated_pfmemberIds.coreMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberIds.coreMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberIds.coreMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberIds.coreMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberIds.allOwnersFlag,
          core: updated_pfmemberIds.allOwnersFlag.core.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberIds(updated_pfmemberIds);
    }

    if (
      type === "core" &&
      updated_pfmemberIds &&
      updated_pfmemberIds.coreMembers &&
      updated_pfmemberIds.extendedMembers.Roles &&
      updated_pfmemberIds.extendedMembers.Roles.length > 0
    ) {
      updated_pfmemberIds = {
        ...updated_pfmemberIds, // Use the correct variable name here
        extendedMembers: {
          ...updated_pfmemberIds.extendedMembers,
          Skills: updated_pfmemberIds.extendedMembers.Skills.filter(
            (e) => e.CardId !== data._id
          ),
          Roles: updated_pfmemberIds.extendedMembers.Roles.filter(
            (e) => e.CardId !== data._id
          ),
          Links: updated_pfmemberIds.extendedMembers.Links.filter(
            (e) => e.CardId !== data._id
          ),
          Domains: updated_pfmemberIds.extendedMembers.Domains.filter(
            (e) => e.CardId !== data._id
          ),
        },
        allOwnersFlag: {
          ...updated_pfmemberIds.allOwnersFlag,
          extended: updated_pfmemberIds.allOwnersFlag.extended.filter(
            (e) => e !== data._id
          ),
        },
      };

      setPfmemberIds(updated_pfmemberIds);
    }

    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        dispatch(getMember(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }

    if (updated_pfmemberIds) {
      updated_pfmemberIds.adminId = myInfo.userId;
      updated_pfmemberIds.url = `${SERVER_URI}/user/pfmember/create`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = updated_pfmemberIds.teamId;
      dispatch(createAndUpdateMember(updated_pfmemberIds)).then(() => {
        dispatch(getPFMember(obj));
        unfreezeWindow();
      });
    }
  };

  const pFdomainSub = (data, type) => {
    if (
      type === "core" &&
      popoverIds &&
      popoverIds.coreMembers &&
      popoverIds.coreMembers.Domains
    ) {
      popoverIds.coreMembers.Domains = popoverIds.coreMembers.Domains.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverIds &&
      popoverIds.extendedMembers &&
      popoverIds.extendedMembers.Domains
    ) {
      popoverIds.extendedMembers.Domains =
        popoverIds.extendedMembers.Domains.filter((e) => {
          return e !== data._id;
        });
    }

    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        dispatch(getMember(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  const pFlinkSub = (data, type) => {
    if (
      type === "core" &&
      popoverIds &&
      popoverIds.coreMembers &&
      popoverIds.coreMembers.Links
    ) {
      popoverIds.coreMembers.Links = popoverIds.coreMembers.Links.filter(
        (e) => {
          return e !== data._id;
        }
      );
    } else if (
      type === "extend" &&
      popoverIds &&
      popoverIds.extendedMembers &&
      popoverIds.extendedMembers.Links
    ) {
      popoverIds.extendedMembers.Links =
        popoverIds.extendedMembers.Links.filter((e) => {
          return e !== data._id;
        });
    }

    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        dispatch(getMember(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  const pFcircleSub = (data, type) => {
    if (
      type === "core" &&
      popoverIds &&
      popoverIds.coreMembers &&
      popoverIds.coreMembers.Circles
    ) {
      popoverIds.coreMembers.Circles = popoverIds.coreMembers.Circles.filter(
        (e) => {
          return e !== data._id;
        }
      );
    }

    if (popoverIds) {
      popoverIds.adminId = myInfo.userId;
      popoverIds.url = `${SERVER_URI}/user/member/update`;
      popoverIds.actionType = "remove";
      popoverIds.cardId = data._id;

      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverIds.teamId;
      dispatch(updateMember(popoverIds)).then(() => {
        dispatch(getMember(obj)).then(() => {
          unfreezeWindow();
        });
      });
    }
  };

  //========================================= Project focus Drag and Drop ===================================================

  //People
  const pFPonDrag = (e, type) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "People");
  };

  const pFPonDragOver = (e) => {
    e.preventDefault();
  };

  const pFPonDrop = (type) => {
    let pFPonDragData = JSON.parse(localStorage.getItem("dragData"));
    let pFPonDragType = localStorage.getItem("dragType");
    if (type === "pFpCore") {
      if (pFPonDragType === "pFpExtend") {
        cFPeopleIdHandel("eToc");
      } else if (pFPonDragType === "pFpDefult") {
        cFPeopleIdHandel("Core");
      }
    } else if (type === "pFpExtend") {
      if (pFPonDragType === "pFpCore") {
        cFPeopleIdHandel("cToe");
      } else if (pFPonDragType === "pFpDefult") {
        cFPeopleIdHandel("Extended");
      }
    } else if (type === "pFpDefult") {
      if (pFPonDragType === "pFpExtend") {
        cFpeopleSub(pFPonDragData, "extend");
      } else if (pFPonDragType === "pFpCore") {
        cFpeopleSub(pFPonDragData, "core");
      }
    }
  };

  //Skills
  const pFSonDrag = (e, type, isPFProjects) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Skills");
    localStorage.setItem("dragPepleCard", isPFProjects);
  };

  const pFSonDragOver = (e) => {
    e.preventDefault();
  };

  const pFSonDrop = (type) => {
    // console.log('pFSonDrop', type);
    let pFSonDragData = JSON.parse(localStorage.getItem("dragData"));
    let pFSonDragType = localStorage.getItem("dragType");
    let isPFCircle = localStorage.getItem("dragPepleCard");
    if (type === "pFsCore") {
      if (pFSonDragType === "pFsExtend") {
        cFPeopleIdHandel("eToc", isPFCircle);
      } else if (pFSonDragType === "pFsDefult") {
        // cFPeopleIdHandel("Core");
        cfcircleOrProjectModes(pFSonDragData, "Skills", "Core");
      }
    } else if (type === "pFsExtend") {
      if (pFSonDragType === "pFsCore") {
        cFPeopleIdHandel("cToe", isPFCircle);
      } else if (pFSonDragType === "pFsDefult") {
        // cFPeopleIdHandel("Extended");
        cfcircleOrProjectModes(pFSonDragData, "Skills", "Extended");
      }
    } else if (type === "pFsDefult") {
      if (pFSonDragType === "pFsExtend") {
        pFskillSub(pFSonDragData, "extend");
      } else if (pFSonDragType === "pFsCore") {
        pFskillSub(pFSonDragData, "core");
      }
    }
  };

  // cFpExtend
  //Roles
  const pFRonDrag = (e, type, isPFProjects) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Roles");
    localStorage.setItem("dragPepleCard", isPFProjects);
  };

  const pFRonDragOver = (e) => {
    e.preventDefault();
  };

  const pFRonDrop = (type) => {
    let pFRonDragData = JSON.parse(localStorage.getItem("dragData"));
    let pFRonDragType = localStorage.getItem("dragType");
    let isPFCircle = localStorage.getItem("dragPepleCard");
    if (type === "pFrCore") {
      if (pFRonDragType === "pFrExtend") {
        cFPeopleIdHandel("eToc", isPFCircle);
      } else if (pFRonDragType === "pFrDefult") {
        // cFPeopleIdHandel("Core");
        cfcircleOrProjectModes(pFRonDragData, "Roles", "Core");
      }
    } else if (type === "pFrExtend") {
      if (pFRonDragType === "pFrCore") {
        cFPeopleIdHandel("cToe", isPFCircle);
      } else if (pFRonDragType === "pFrDefult") {
        // cFPeopleIdHandel("Extended");
        cfcircleOrProjectModes(pFRonDragData, "Roles", "Extended");
      }
    } else if (type === "pFrDefult") {
      if (pFRonDragType === "pFrExtend") {
        pFroleSub(pFRonDragData, "extend");
      } else if (pFRonDragType === "pFrCore") {
        pFroleSub(pFRonDragData, "core");
      }
    }
  };

  // cFpExtend
  //Domains
  const pFDonDrag = (e, type, isPFProjects) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Domains");
    localStorage.setItem("dragPepleCard", isPFProjects);
  };

  const pFDonDragOver = (e) => {
    e.preventDefault();
  };

  const pFDonDrop = (type) => {
    let pFDonDragData = JSON.parse(localStorage.getItem("dragData"));
    let pFDonDragType = localStorage.getItem("dragType");
    let isPFCircle = localStorage.getItem("dragPepleCard");
    if (type === "pFdCore") {
      if (pFDonDragType === "pFdExtend") {
        cFPeopleIdHandel("eToc");
      } else if (pFDonDragType === "pFdDefult") {
        cFPeopleIdHandel("Core");
      }
    } else if (type === "pFdExtend") {
      if (pFDonDragType === "pFdCore") {
        cFPeopleIdHandel("cToe");
      } else if (pFDonDragType === "pFdDefult") {
        cFPeopleIdHandel("Extended");
      }
    } else if (type === "pFdDefult") {
      if (pFDonDragType === "pFdExtend") {
        pFdomainSub(pFDonDragData, "extend");
      } else if (pFDonDragType === "pFdCore") {
        pFdomainSub(pFDonDragData, "core");
      }
    }
  };

  // cFpExtend
  //Links
  const pFLonDrag = (e, type, isPFProjects) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Links");
    localStorage.setItem("dragPepleCard", isPFProjects);
  };

  const pFLonDragOver = (e) => {
    e.preventDefault();
  };

  const pFLonDrop = (type) => {
    let pFLonDragData = JSON.parse(localStorage.getItem("dragData"));
    let pFLonDragType = localStorage.getItem("dragType");
    if (type === "pFlCore") {
      if (pFLonDragType === "pFlExtend") {
        // console.log("drag");
        cFPeopleIdHandel("eToc");
      } else if (pFLonDragType === "pFlDefult") {
        cFPeopleIdHandel("Core");
      }
    } else if (type === "pFlExtend") {
      if (pFLonDragType === "pFlCore") {
        cFPeopleIdHandel("cToe");
      } else if (pFLonDragType === "pFlDefult") {
        cFPeopleIdHandel("Extended");
      }
    } else if (type === "pFlDefult") {
      if (pFLonDragType === "pFlExtend") {
        cFlinkSub(pFLonDragData, "extend");
      } else if (pFLonDragType === "pFlCore") {
        cFlinkSub(pFLonDragData, "core");
      }
    }
  };

  // cFpExtend
  //Circles
  const pFConDrag = (e, type) => {
    localStorage.setItem("dragData", JSON.stringify(e));
    localStorage.setItem("dragType", type);
    localStorage.setItem("dragCard", "Circles");
  };

  const pFConDragOver = (e) => {
    e.preventDefault();
  };

  const pFConDrop = (type) => {
    let pFConDragData = JSON.parse(localStorage.getItem("dragData"));
    let pFConDragType = localStorage.getItem("dragType");
    if (type === "pFcCore") {
      if (pFConDragType === "pFcDefult") {
        cFPeopleIdHandel("Core");
      }
    } else if (type === "pFcDefult") {
      if (pFConDragType === "pFcCore") {
        pFcircleSub(pFConDragData, "core");
      }
    }
  };

  // cFpExtend

  //=========================================Circle focus algorithm===================================================

  // Skills
  const [pfSkillsSearch, setPfSkillsSearch] = useState("");

  const fpSref = useRef(null);

  useEffect(() => {
    if (pfSkillsSearch.length > 0) {
      fpSref.current.focus();
    }
  }, [pfSkillsSearch]);

  var filteredPFSData = [];
  if (
    (pfSkillsSearch.length > 0 || allSearch.length > 0) &&
    PredeffoSkill.length > 0
  ) {
    filteredPFSData = PredeffoSkill.filter((value) => {
      const searchStr = pfSkillsSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.skillName}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredPFSData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Skills");
    }
  }

  if (
    filteredPFSData.length === 0 &&
    pfSkillsSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPFSData = [...PredeffoSkill];
  }

  let nonFiltePFSData = [];
  if (
    pfSkillsSearch.length > 0 &&
    filteredPFSData.length !== PredeffoSkill.length
  ) {
    nonFiltePFSData = [...PredeffoSkill];
    for (let i = 0; i < filteredPFSData.length; i++) {
      nonFiltePFSData = nonFiltePFSData.filter((value, key) => {
        return value._id !== filteredPFSData[i]._id;
      });
    }
    if (filteredPFSData.length === 0) {
      nonFiltePFSData = [...PredeffoSkill];
    }
  }

  const isPFProjectsAddS = (data, type) => {
    if (
      focused &&
      focused.card === "Projects" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          let curPeople = [];
          for (let j = 0; j < pfmembers[i].coreMembers.Skills.length; j++) {
            if (pfmembers[i].coreMembers.Skills[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Skills[j].peopleIds.toString()
                ) {
                  curPeople.push({
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                    Id: peopleSorted[k]._id,
                  });
                  break;
                }
              }
            }
          }
          return curPeople;
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          let curPeople = [];
          for (let j = 0; j < pfmembers[i].extendedMembers.Skills.length; j++) {
            if (pfmembers[i].extendedMembers.Skills[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Skills[j].peopleIds.toString()
                ) {
                  curPeople.push({
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                    Id: peopleSorted[k]._id,
                  });
                  break;
                }
              }
            }
          }
          return curPeople;
        }
      }
      return [];
    }
  };

  // Roles
  const [pfRolesSearch, setPfRolesSearch] = useState("");

  const fprRref = useRef(null);

  useEffect(() => {
    if (pfRolesSearch.length > 0) {
      fprRref.current.focus();
    }
  }, [pfRolesSearch]);

  var filteredPFRData = [];

  if (
    (pfRolesSearch.length > 0 || allSearch.length > 0) &&
    PredeffoRoles.length > 0
  ) {
    filteredPFRData = PredeffoRoles.filter((value) => {
      const searchStr = pfRolesSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.roleName}`.toLowerCase().includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredPFRData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Roles");
    }
  }

  if (
    filteredPFRData.length === 0 &&
    pfRolesSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPFRData = [...PredeffoRoles];
  }

  let nonFiltePFRData = [];
  if (
    pfRolesSearch.length > 0 &&
    filteredPFSData.length !== PredeffoRoles.length
  ) {
    nonFiltePFRData = [...PredeffoRoles];
    for (let i = 0; i < filteredPFRData.length; i++) {
      nonFiltePFRData = nonFiltePFRData.filter((value, key) => {
        return value._id !== filteredPFRData[i]._id;
      });
    }
    if (filteredPFRData.length === 0) {
      nonFiltePFRData = [...PredeffoRoles];
    }
  }

  const isPFProjectsAddR = (data, type) => {
    if (
      focused &&
      focused.card === "Projects" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          let curPeople = [];
          for (let j = 0; j < pfmembers[i].coreMembers.Roles.length; j++) {
            if (pfmembers[i].coreMembers.Roles[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Roles[j].peopleIds.toString()
                ) {
                  curPeople.push({
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                    Id: peopleSorted[k]._id,
                  });
                  break;
                }
              }
            }
          }
          return curPeople;
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          let curPeople = [];
          for (let j = 0; j < pfmembers[i].extendedMembers.Roles.length; j++) {
            if (pfmembers[i].extendedMembers.Roles[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Roles[j].peopleIds.toString()
                ) {
                  curPeople.push({
                    name: `${peopleSorted[k].fname} ${peopleSorted[k].lname}`,
                    Id: peopleSorted[k]._id,
                  });
                  break;
                }
              }
            }
          }
          return curPeople;
        }
      }
      return [];
    }
  };

  // Domain
  const [pfDomainsSearch, setPfDomainsSearch] = useState("");

  const fprDref = useRef(null);

  useEffect(() => {
    if (pfDomainsSearch.length > 0) {
      fprDref.current.focus();
    }
  }, [pfDomainsSearch]);

  var filteredPFFDData = [];

  if (
    (pfDomainsSearch.length > 0 || allSearch.length > 0) &&
    PredeffoDomains.length > 0
  ) {
    filteredPFFDData = PredeffoDomains.filter((value) => {
      const searchStr =
        pfDomainsSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.domainName}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredPFFDData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Domains");
    }
  }

  if (
    filteredPFFDData.length === 0 &&
    pfDomainsSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPFFDData = [...PredeffoDomains];
  }

  let nonFiltePFDData = [];
  if (
    pfDomainsSearch.length > 0 &&
    filteredPFFDData.length !== PredeffoDomains.length
  ) {
    nonFiltePFDData = [...PredeffoDomains];
    for (let i = 0; i < filteredPFFDData.length; i++) {
      nonFiltePFDData = nonFiltePFDData.filter((value, key) => {
        return value._id !== filteredPFFDData[i]._id;
      });
    }
    if (filteredPFFDData.length === 0) {
      nonFiltePFDData = [...PredeffoDomains];
    }
  }

  let prfRAnfdSDomain = [];
  if (focused && focused.card === "Projects") {
    if (PrcorfoSkill && PrcorfoSkill.length > 0) {
      for (let i = 0; i < PrcorfoSkill.length; i++) {
        if (PrcorfoSkill[i].domains && PrcorfoSkill[i].domains.length > 0) {
          for (let j = 0; j < PrcorfoSkill[i].domains.length; j++) {
            if (PrcorfoSkill[i].domains[j].domain) {
              prfRAnfdSDomain.push({
                cardId: PrcorfoSkill[i]._id,
                cardName: PrcorfoSkill[i].skillName,
                type: "Skill",
                domainId: PrcorfoSkill[i].domains[j].domain,
              });
            }
          }
        }
      }
    }
    if (PrcorfoRoles && PrcorfoRoles.length > 0) {
      for (let i = 0; i < PrcorfoRoles.length; i++) {
        if (PrcorfoRoles[i].domains && PrcorfoRoles[i].domains.length > 0) {
          for (let j = 0; j < PrcorfoRoles[i].domains.length; j++) {
            if (PrcorfoRoles[i].domains[j].domain) {
              prfRAnfdSDomain.push({
                cardId: PrcorfoRoles[i]._id,
                cardName: PrcorfoRoles[i].roleName,
                type: "Role",
                domainId: PrcorfoRoles[i].domains[j].domain,
              });
            }
          }
        }
      }
    }
    if (prfRAnfdSDomain && prfRAnfdSDomain.length > 0) {
      if (filteredPFFDData && filteredPFFDData.length > 0) {
        let addNewDoamin = [];
        filteredPFFDData = filteredPFFDData.filter((value, key) => {
          let flag = true;
          for (let i = 0; i < prfRAnfdSDomain.length; i++) {
            if (prfRAnfdSDomain[i].domainId === value._id) {
              addNewDoamin.push(value);
              flag = false;
              break;
            }
          }
          if (flag) {
            return value;
          }
        });
        if (addNewDoamin && addNewDoamin.length > 0) {
          PrcorfoDomains = [...PrcorfoDomains, ...addNewDoamin];
        }
      }
    }
  }

  let prfRAnfdSExDomain = [];
  if (focused && focused.card === "Projects") {
    if (PrextfoSkill && PrextfoSkill.length > 0) {
      for (let i = 0; i < PrextfoSkill.length; i++) {
        if (PrextfoSkill[i].domains && PrextfoSkill[i].domains.length > 0) {
          for (let j = 0; j < PrextfoSkill[i].domains.length; j++) {
            if (PrextfoSkill[i].domains[j].domain) {
              prfRAnfdSExDomain.push({
                cardId: PrextfoSkill[i]._id,
                cardName: PrextfoSkill[i].skillName,
                type: "Skill",
                domainId: PrextfoSkill[i].domains[j].domain,
              });
            }
          }
        }
      }
    }
    if (PrextfoRoles && PrextfoRoles.length > 0) {
      for (let i = 0; i < PrextfoRoles.length; i++) {
        if (PrextfoRoles[i].domains && PrextfoRoles[i].domains.length > 0) {
          for (let j = 0; j < PrextfoRoles[i].domains.length; j++) {
            if (PrextfoRoles[i].domains[j].domain) {
              prfRAnfdSExDomain.push({
                cardId: PrextfoRoles[i]._id,
                cardName: PrextfoRoles[i].roleName,
                type: "Role",
                domainId: PrextfoRoles[i].domains[j].domain,
              });
            }
          }
        }
      }
    }
    if (prfRAnfdSExDomain && prfRAnfdSExDomain.length > 0) {
      if (filteredPFFDData && filteredPFFDData.length > 0) {
        let addNewDoamin = [];
        filteredPFFDData = filteredPFFDData.filter((value, key) => {
          let flag = true;
          for (let i = 0; i < prfRAnfdSExDomain.length; i++) {
            if (prfRAnfdSExDomain[i].domainId === value._id) {
              addNewDoamin.push(value);
              flag = false;
              break;
            }
          }
          if (flag) {
            return value;
          }
        });
        if (addNewDoamin && addNewDoamin.length > 0) {
          PrextfoDomains = [...PrextfoDomains, ...addNewDoamin];
        }
      }
    }
  }

  const isPFProjectsAddD = (data, type) => {
    if (
      focused &&
      focused.card === "Projects" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          for (let j = 0; j < pfmembers[i].coreMembers.Domains.length; j++) {
            if (pfmembers[i].coreMembers.Domains[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Domains[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          for (
            let j = 0;
            j < pfmembers[i].extendedMembers.Domains.length;
            j++
          ) {
            if (pfmembers[i].extendedMembers.Domains[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Domains[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        }
      }
      return "";
    }
  };

  // Links
  const [pfLinksSearch, setPfLinksSearch] = useState("");

  const fsprLref = useRef(null);

  useEffect(() => {
    if (pfLinksSearch.length > 0) {
      fsprLref.current.focus();
    }
  }, [pfLinksSearch]);

  var filteredPFLData = [];

  if (
    (pfLinksSearch.length > 0 || allSearch.length > 0) &&
    PredeffoLinks.length > 0
  ) {
    filteredPFLData = PredeffoLinks.filter((value) => {
      const searchStr = pfLinksSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.linkName}`.toLowerCase().includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredPFLData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Links");
    }
  }

  if (
    filteredPFLData.length === 0 &&
    pfLinksSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPFLData = [...PredeffoLinks];
  }

  let nonFiltePFLData = [];
  if (
    pfLinksSearch.length > 0 &&
    filteredPFLData.length !== PredeffoLinks.length
  ) {
    nonFiltePFLData = [...PredeffoLinks];
    for (let i = 0; i < filteredPFLData.length; i++) {
      nonFiltePFLData = nonFiltePFLData.filter((value, key) => {
        return value._id !== filteredPFLData[i]._id;
      });
    }
    if (filteredPFLData.length === 0) {
      nonFiltePFLData = [...PredeffoLinks];
    }
  }

  const isPFProjectsAddL = (data, type) => {
    if (
      focused &&
      focused.card === "Projects" &&
      pfmembers &&
      pfmembers.length > 0
    ) {
      let memberType = focused.id.toString();
      for (let i = 0; i < pfmembers.length; i++) {
        if (pfmembers[i].memberType === memberType && type === "core") {
          for (let j = 0; j < pfmembers[i].coreMembers.Links.length; j++) {
            if (pfmembers[i].coreMembers.Links[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].coreMembers.Links[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        } else if (
          pfmembers[i].memberType === memberType &&
          type === "extended"
        ) {
          for (let j = 0; j < pfmembers[i].extendedMembers.Links.length; j++) {
            if (pfmembers[i].extendedMembers.Links[j].CardId === data) {
              for (let k = 0; k < peopleSorted.length; k++) {
                if (
                  peopleSorted[k]._id.toString() ===
                  pfmembers[i].extendedMembers.Links[j].peopleIds.toString()
                ) {
                  return `${peopleSorted[k].fname} ${peopleSorted[k].lname}`;
                }
              }
            }
          }
        }
      }
      return "";
    }
  };

  // Circles
  const [pfCirclesSearch, setPfCirclesSearch] = useState("");

  const fprCref = useRef(null);

  useEffect(() => {
    if (pfCirclesSearch.length > 0) {
      fprCref.current.focus();
    }
  }, [pfCirclesSearch]);

  var filteredPFCData = [];

  if (
    (pfCirclesSearch.length > 0 || allSearch.length > 0) &&
    PredeffoCircles.length > 0
  ) {
    filteredPFCData = PredeffoCircles.filter((value) => {
      const searchStr =
        pfCirclesSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.circleName}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredPFCData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("Circles");
    }
  }

  if (
    filteredPFCData.length === 0 &&
    pfCirclesSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPFCData = [...PredeffoCircles];
  }

  let nonFiltePrFCData = [];
  if (
    pfCirclesSearch.length > 0 &&
    filteredPFCData.length !== PredeffoCircles.length
  ) {
    nonFiltePrFCData = [...PredeffoCircles];
    for (let i = 0; i < filteredPFCData.length; i++) {
      nonFiltePrFCData = nonFiltePrFCData.filter((value, key) => {
        return value._id !== filteredPFCData[i]._id;
      });
    }
    if (filteredPFCData.length === 0) {
      nonFiltePrFCData = [...PredeffoCircles];
    }
  }

  // Projects
  let PrFCircleSorted = [...projectsSorted];

  // People
  const [pfPeopleSearch, setPfPeopleSearch] = useState("");

  const fpPref = useRef(null);

  useEffect(() => {
    if (pfPeopleSearch.length > 0) {
      fpPref.current.focus();
    }
  }, [pfPeopleSearch]);

  var filteredPFPData = [];

  if (
    (pfPeopleSearch.length > 0 || allSearch.length > 0) &&
    PredeffoPeople.length > 0
  ) {
    filteredPFPData = PredeffoPeople.filter((value) => {
      const searchStr = pfPeopleSearch.toLowerCase() || allSearch.toLowerCase();
      const nameMatches = `${value.fname} ${value.lname}`
        .toLowerCase()
        .includes(searchStr);
      return nameMatches;
    });
    if (allSearch && allSearch.length > 0 && filteredPFPData.length > 0) {
      // list = list.filter((e)=>{e !== 'People'})
      ListData.push("People");
    }
  }

  if (
    filteredPFPData.length === 0 &&
    pfPeopleSearch.length === 0 &&
    allSearch.length === 0
  ) {
    filteredPFPData = [...PredeffoPeople];
  }

  let nonFiltePFPData = [];
  if (
    pfPeopleSearch.length > 0 &&
    filteredPFPData.length !== PredeffoPeople.length
  ) {
    nonFiltePFPData = [...PredeffoPeople];
    for (let i = 0; i < filteredPFPData.length; i++) {
      nonFiltePFPData = nonFiltePFPData.filter((value, key) => {
        return value._id !== filteredPFPData[i]._id;
      });
    }
    if (filteredPFPData.length === 0) {
      nonFiltePFPData = [...PredeffoPeople];
    }
  }

  let curPrFMemberState = {};
  if (
    focused &&
    focused.card === "Projects" &&
    projectsSorted &&
    projectsSorted.length > 0
  ) {
    let cFDataId =
      projectsSorted[focused.index] && focused.id ? focused.id : null;
    if (cFDataId && pfmembers && pfmembers.length > 0) {
      for (let i = 0; i < pfmembers.length; i++) {
        if (
          pfmembers[i].memberType &&
          pfmembers[i].memberType.toString() === cFDataId.toString()
        ) {
          curPrFMemberState = pfmembers[i];
          break;
        }
      }
    }
  }

  let PrFPeoplePills = [];
  let PrFPeoplePillsE = [];
  if (focused && focused.card === "Projects" && curPrFMemberState) {
    if (
      curPrFMemberState.coreMembers &&
      curPrFMemberState.coreMembers.Skills &&
      curPrFMemberState.coreMembers.Skills.length > 0
    ) {
      for (let i = 0; i < PrcorfoSkill.length; i++) {
        for (let j = 0; j < curPrFMemberState.coreMembers.Skills.length; j++) {
          if (
            PrcorfoSkill[i]._id.toString() ===
            curPrFMemberState.coreMembers.Skills[j].CardId.toString()
          ) {
            PrFPeoplePills.push({
              peopleId: curPrFMemberState.coreMembers.Skills[j].peopleIds,
              Id: PrcorfoSkill[i]._id,
              name: `${PrcorfoSkill[i].skillName} (Skill)`,
              cardType: "Skills",
              standIn: false,
            });
          }
        }
      }
    }
    if (
      curPrFMemberState.extendedMembers &&
      curPrFMemberState.extendedMembers.Skills &&
      curPrFMemberState.extendedMembers.Skills.length > 0
    ) {
      for (let i = 0; i < PrextfoSkill.length; i++) {
        for (
          let j = 0;
          j < curPrFMemberState.extendedMembers.Skills.length;
          j++
        ) {
          if (
            PrextfoSkill[i]._id.toString() ===
            curPrFMemberState.extendedMembers.Skills[j].CardId.toString()
          ) {
            PrFPeoplePillsE.push({
              peopleId: curPrFMemberState.extendedMembers.Skills[j].peopleIds,
              Id: PrextfoSkill[i]._id,
              name: `${PrextfoSkill[i].skillName} (Skill)`,
              cardType: "Skills",
              standIn: false,
            });
          }
        }
      }
    }

    if (
      curPrFMemberState.coreMembers &&
      curPrFMemberState.coreMembers.Roles &&
      curPrFMemberState.coreMembers.Roles.length > 0
    ) {
      for (let i = 0; i < PrcorfoRoles.length; i++) {
        for (let j = 0; j < curPrFMemberState.coreMembers.Roles.length; j++) {
          if (
            PrcorfoRoles[i]._id.toString() ===
            curPrFMemberState.coreMembers.Roles[j].CardId.toString()
          ) {
            PrFPeoplePills.push({
              peopleId: curPrFMemberState.coreMembers.Roles[j].peopleIds,
              Id: PrcorfoRoles[i]._id,
              name: `${PrcorfoRoles[i].roleName} (Role)`,
              cardType: "Roles",
              standIn: false,
            });
          }
        }
      }
    }

    if (
      curPrFMemberState.extendedMembers &&
      curPrFMemberState.extendedMembers.Roles &&
      curPrFMemberState.extendedMembers.Roles.length > 0
    ) {
      for (let i = 0; i < PrextfoRoles.length; i++) {
        for (
          let j = 0;
          j < curPrFMemberState.extendedMembers.Roles.length;
          j++
        ) {
          if (
            PrextfoRoles[i]._id.toString() ===
            curPrFMemberState.extendedMembers.Roles[j].CardId
          ) {
            PrFPeoplePillsE.push({
              peopleId: curPrFMemberState.extendedMembers.Roles[j].peopleIds,
              Id: PrextfoRoles[i]._id,
              name: `${PrextfoRoles[i].roleName} (Role)`,
              cardType: "Roles",
              standIn: false,
            });
          }
        }
      }
    }

    if (PrcorfoDomains && PrcorfoDomains.length > 0) {
      for (let i = 0; i < PrcorfoDomains.length; i++) {
        if (PrcorfoDomains[i].owners && PrcorfoDomains[i].owners.owner) {
          PrFPeoplePills.push({
            peopleId: PrcorfoDomains[i].owners.owner,
            Id: PrcorfoDomains[i]._id,
            name: `${PrcorfoDomains[i].domainName} (Domain)`,
            cardType: "Domains",
            standIn: false,
          });
        }
        if (PrcorfoDomains[i].standIn) {
          PrFPeoplePills.push({
            peopleId: PrcorfoDomains[i].standIn,
            Id: PrcorfoDomains[i]._id,
            name: `${PrcorfoDomains[i].domainName} (Domain)`,
            cardType: "Domains",
            standIn: true,
          });
        }
      }
    }

    if (PrextfoDomains && PrextfoDomains.length > 0) {
      for (let i = 0; i < PrextfoDomains.length; i++) {
        if (PrextfoDomains[i].owners && PrextfoDomains[i].owners.owner) {
          PrFPeoplePillsE.push({
            peopleId: PrextfoDomains[i].owners.owner,
            Id: PrextfoDomains[i]._id,
            name: `${PrextfoDomains[i].domainName} (Domain)`,
            cardType: "Domains",
            standIn: false,
          });
        }
        if (PrextfoDomains[i].standIn) {
          PrFPeoplePillsE.push({
            peopleId: PrextfoDomains[i].standIn,
            Id: PrextfoDomains[i]._id,
            name: `${PrextfoDomains[i].domainName} (Domain)`,
            cardType: "Domains",
            standIn: true,
          });
        }
      }
    }

    if (PrcorfoLinks && PrcorfoLinks.length > 0) {
      for (let i = 0; i < PrcorfoLinks.length; i++) {
        if (PrcorfoLinks[i].owner) {
          PrFPeoplePills.push({
            peopleId: PrcorfoLinks[i].owner,
            Id: PrcorfoLinks[i]._id,
            name: `${PrcorfoLinks[i].linkName} (Link)`,
            cardType: "Links",
            standIn: false,
          });
        }
        if (PrcorfoLinks[i].standIn) {
          PrFPeoplePills.push({
            peopleId: PrcorfoLinks[i].standIn,
            Id: PrcorfoLinks[i]._id,
            name: `${PrcorfoLinks[i].linkName} (Link)`,
            cardType: "Links",
            standIn: true,
          });
        }
      }
    }

    if (
      PrextfoLinks &&
      PrextfoLinks.length > 0 &&
      curPrFMemberState.extendedMembers &&
      curPrFMemberState.extendedMembers.Links &&
      curPrFMemberState.extendedMembers.Links.length > 0
    ) {
      for (let i = 0; i < PrextfoLinks.length; i++) {
        for (
          let j = 0;
          j < curPrFMemberState.extendedMembers.Links.length;
          j++
        ) {
          if (
            PrextfoLinks[i]._id.toString() ===
            curPrFMemberState.extendedMembers.Links[j].CardId.toString()
          ) {
            PrFPeoplePills.push({
              peopleId: curPrFMemberState.extendedMembers.Links[j].peopleIds,
              Id: PrextfoLinks[i]._id,
              name: `${PrextfoLinks[i].linkName} (Link)`,
              cardType: "Links",
              standIn: false,
            });
          }
        }
      }
    }

    if (PrextfoLinks && PrextfoLinks.length > 0) {
      for (let i = 0; i < PrextfoLinks.length; i++) {
        if (PrextfoLinks[i].owner) {
          PrFPeoplePillsE.push({
            peopleId: PrextfoLinks[i].owner,
            Id: PrextfoLinks[i]._id,
            name: `${PrextfoLinks[i].linkName} (Link)`,
            cardType: "Links",
            standIn: false,
          });
        }
        if (PrextfoLinks[i].standIn) {
          PrFPeoplePillsE.push({
            peopleId: PrextfoLinks[i].standIn,
            Id: PrextfoLinks[i]._id,
            name: `${PrextfoLinks[i].linkName} (Link)`,
            cardType: "Links",
            standIn: true,
          });
        }
      }
    }
  }

  let PrcorfoPeoples = [...PrcorfoPeople];
  let PrExtfoPeoples = [...PrextfoPeople];
  let filteredPFPDatas = [...filteredPFPData];
  if (focused && focused.card === "Projects") {
    if (PrFPeoplePills && PrFPeoplePills.length > 0) {
      let pillsData = [];
      for (let i = 0; i < PrFPeoplePills.length; i++) {
        pillsData = [...new Set([...pillsData, PrFPeoplePills[i].peopleId])];
      }
      for (let i = 0; i < PrcorfoPeoples.length; i++) {
        pillsData = [...new Set([...pillsData, PrcorfoPeoples[i]._id])];
      }
      filteredPFPDatas = filteredPFPDatas.filter((value) => {
        let flag = true;
        for (let i = 0; i < pillsData.length; i++) {
          if (value._id.toString() === pillsData[i].toString()) {
            PrcorfoPeoples = [...new Set([...PrcorfoPeoples, value])];
            flag = true;
            break;
          }
        }
        if (flag) {
          return value;
        }
      });
    }
    if (PrFPeoplePillsE && PrFPeoplePillsE.length > 0) {
      let pillsDatas = [];
      for (let i = 0; i < PrFPeoplePillsE.length; i++) {
        pillsDatas = [...new Set([...pillsDatas, PrFPeoplePillsE[i].peopleId])];
      }
      if (pillsDatas && pillsDatas.length > 0) {
        for (let i = 0; i < pillsDatas.length; i++) {
          filteredPFPDatas = filteredPFPDatas.filter((value) => {
            if (value._id.toString() === pillsDatas[i].toString()) {
              PrExtfoPeoples = [...new Set([...PrExtfoPeoples, value])];
              return false;
            } else {
              return true;
            }
          });
          if (PrcorfoPeoples && PrcorfoPeoples.length > 0) {
            for (let j = 0; j < PrcorfoPeoples.length; j++) {
              if (PrcorfoPeoples[j]._id === pillsDatas[i].toString()) {
                PrExtfoPeoples = [
                  ...new Set([...PrExtfoPeoples, PrcorfoPeoples[j]]),
                ];
                break;
              }
            }
          }
        }
      }
    }
  }

  if (focused && focused.card === "Projects") {
    let curPrFDatas = [...PrcorfoPeoples, ...PrextfoPeople];
    if (curPrFDatas && curPrFDatas.length > 0) {
      for (let i = 0; i < curPrFDatas.length; i++) {
        filteredPFPDatas = filteredPFPDatas.filter((value) => {
          return value._id.toString() !== curPrFDatas[i]._id.toString();
        });
      }
    }
  }

  const cardOpensPf = (datas) => {
    if (datas.cardType === "People") {
      peopleSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openPeople();
        }
      });
    } else if (datas.cardType === "Skills") {
      skillsSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openSkill();
        }
      });
    } else if (datas.cardType === "Roles") {
      rolesSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openRole();
        }
      });
    } else if (datas.cardType === "Links") {
      linksSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openLink();
        }
      });
    } else if (datas.cardType === "Domains") {
      domainsSorted.filter((value, key) => {
        if (value._id.toString() === datas.Id.toString()) {
          indexSet(value);
          openDomain();
        }
      });
    }
  };

  const isAddedAsPersonPrF = (Id, type) => {
    if (type === "Core") {
      if (
        curPCoreMembers &&
        curPCoreMembers.People &&
        curPCoreMembers.People.length > 0
      ) {
        let inc = curPCoreMembers.People.includes(Id);
        if (inc) {
          return true;
        }
      }
    } else if (type === "Extended") {
      if (
        curPExtendedMembers &&
        curPExtendedMembers.People &&
        curPExtendedMembers.People.length > 0
      ) {
        let inc = curPExtendedMembers.People.includes(Id);
        if (inc) {
          return true;
        }
      }
    }
    return false;
  };

  const prfIsDuplicatePerson = (data) => {
    if (
      data &&
      Object.keys(data).length > 0 &&
      PrcorfoPeoples &&
      PrcorfoPeoples.length > 0
    ) {
      let flag = true;
      for (let i = 0; i < PrcorfoPeoples.length; i++) {
        if (PrcorfoPeoples[i]._id === data._id) {
          flag = false;
          break;
        }
      }
      return flag;
    }
  };

  //================================Project focus mode==================================================================================

  const FPrPeople = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.people &&
        tagFilteredMegedData.people.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="people"
    >
      <div className="list list-bg-yellow" data-list_id="people">
        <div className="dropdown" style={{ position: "static" }}>
          <h4 className="list-title">
            <img alt="" src="images/icon-people.png" /> People (
            <span>{PrcorfoPeople.length + PrExtfoPeoples.length}</span>){" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("people")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              href="#"
              onClick={() => {
                toggleEXTnNOCards("people", "", true, "list");
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("people")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("People")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("People")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#peopleModal"
                >
                  Add new person
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("People")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("people", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show external people
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          <div
            className="form-group notes"
            style={note.People ? { display: "block" } : { display: "none" }}
          >
            <textarea
              className="notes"
              ref={pnoteRef}
              name="People"
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              maxLength="100"
              autoFocus={null}
              defaultValue={noteSaveData.People}
            ></textarea>
          </div>
          <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>

          <div className="panel panel-default">
            <div
              onDrop={() => pFPonDrop("pFpCore")}
              onDragOver={(event) => pFPonDragOver(event)}
              id="core"
              className="panel-body box-container"
            >
              {PrcorfoPeoples && PrcorfoPeoples.length > 0
                ? PrcorfoPeoples.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "people"
                      );
                      if (!Proceed) return;
                    }
                    let flagRed = false;
                    let flagGray = false;
                    let flagLGray = false;
                    if (focused && focused.card === "Projects") {
                      let focusedProject = projectsSorted.find(
                        (p) => p._id === focused.id
                      );
                      let leadOfCicleRedInHtml = focusedProject.lead;
                      let leadOfCicleGrayInHtml = focusedProject.standIn;
                      if (leadOfCicleRedInHtml) {
                        if (leadOfCicleRedInHtml === e._id) {
                          flagRed = true;
                        } else {
                          let curPLead = "";
                          if (
                            skillsSorted &&
                            skillsSorted.length > 0 &&
                            leadOfCicleRedInHtml
                          ) {
                            for (let j = 0; j < skillsSorted.length; j++) {
                              if (
                                skillsSorted[j].ownerType === "Single owner" &&
                                skillsSorted[j].owners &&
                                skillsSorted[j].owners.length > 0
                              ) {
                                if (
                                  skillsSorted[j]._id === leadOfCicleRedInHtml
                                ) {
                                  curPLead = skillsSorted[j].owners[0];
                                  break;
                                }
                              }
                            }
                          }
                          if (!curPLead) {
                            if (
                              rolesSorted &&
                              rolesSorted.length > 0 &&
                              leadOfCicleRedInHtml
                            ) {
                              for (let j = 0; j < rolesSorted.length; j++) {
                                if (
                                  rolesSorted[j].ownerRole === "Single owner" &&
                                  rolesSorted[j].owners &&
                                  rolesSorted[j].owners.length > 0
                                ) {
                                  if (
                                    rolesSorted[j]._id === leadOfCicleRedInHtml
                                  ) {
                                    curPLead = rolesSorted[j].owners[0];
                                    break;
                                  }
                                }
                              }
                            }
                          }
                          if (curPLead && curPLead.length > 0) {
                            if (e._id === curPLead) {
                              flagLGray = true;
                            }
                          }
                        }
                      }
                      if (leadOfCicleGrayInHtml === e._id) {
                        flagGray = true;
                      }
                    }
                    let curPills = [];
                    if (PrFPeoplePills && PrFPeoplePills.length > 0) {
                      // console.log("Initial PrFPeoplePills: ",PrFPeoplePills)
                      for (let i = 0; i < PrFPeoplePills.length; i++) {
                        let duplicate = false;
                        if (curPills.length > 0) {
                          for (let j = 0; j < curPills.length; j++) {
                            if (
                              curPills[j].peopleId ===
                                PrFPeoplePills[i].peopleId &&
                              curPills[j].Id === PrFPeoplePills[i].Id
                            ) {
                              // console.log("Duplicate found: ",curPills[j], PrFPeoplePills[i]);
                              duplicate = true;
                              break;
                            }
                          }
                        }

                        if (
                          PrFPeoplePills[i].peopleId === e._id &&
                          !duplicate
                        ) {
                          curPills.push(PrFPeoplePills[i]);
                        }
                      }
                    }
                    // console.log("Current pills: ",curPills);
                    let addAsPerson = isAddedAsPersonPrF(e._id, "Core");

                    return (
                      <div
                        onDrag={() => pFPonDrag(e, "pFpCore")}
                        key={index}
                        draggable
                        className="card"
                        data-list_id="people"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setPepole(e, PrcorfoPeoples, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {e.workRole === "External" ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {curPills && curPills.length > 0
                            ? curPills.map((d, index) => {
                                return (
                                  <span onClick={() => cardOpensPf(d)}>
                                    {!d.standIn ? (
                                      <span className="custom-badge lightgray">
                                        {d.name}
                                      </span>
                                    ) : (
                                      <span className="custom-badge gray-o">
                                        {d.name}
                                      </span>
                                    )}
                                  </span>
                                );
                              })
                            : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <div className="relations">
                          {addAsPerson ? (
                            <span className="custom-badge lightgray text-red person-pill">
                              Added as person
                            </span>
                          ) : null}
                          {flagRed ? (
                            <span className="custom-badge red-o direct-lead">
                              LEAD
                            </span>
                          ) : null}
                          {flagGray ? (
                            <span className="custom-badge gray-o">
                              LEAD (Stand-In)
                            </span>
                          ) : null}
                          {flagLGray ? (
                            <span className="custom-badge gray-o">LEAD</span>
                          ) : null}
                        </div>

                        <a
                          /* onClick={() => setPepole(e, PrcorfoPeoples, index)} */ className="card-edit"
                        >{`${e.fname} ${e.lname}`}</a>
                        <a className="card-focus">
                          <img
                            onClick={() => PfToCAndP("People", e)}
                            src="./images/newicons/focus.svg"
                          />
                        </a>
                        {addAsPerson ? (
                          <a
                            className="card-unlink"
                            style={{ display: "block", color: "red" }}
                          >
                            <i
                              onClick={() => pFpeopleSub(e, "core")}
                              className="icon far fa-times-circle"
                            ></i>
                          </a>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => pFPonDrop("pFpExtend")}
              onDragOver={(event) => pFPonDragOver(event)}
              id="extended"
              className="panel-body box-container"
            >
              {PrExtfoPeoples && PrExtfoPeoples.length > 0
                ? PrExtfoPeoples.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "people"
                      );
                      if (!Proceed) return;
                    }
                    let curPills = [];
                    if (PrFPeoplePillsE && PrFPeoplePillsE.length > 0) {
                      for (let i = 0; i < PrFPeoplePillsE.length; i++) {
                        if (PrFPeoplePillsE[i].peopleId === e._id) {
                          curPills.push(PrFPeoplePillsE[i]);
                        }
                      }
                    }
                    let flag = true;
                    if (e && Object.keys(e).length > 0) {
                      flag = prfIsDuplicatePerson(e);
                    }
                    let addAsPerson = isAddedAsPersonPrF(e._id, "Extended");
                    return (
                      <div
                        // onDragStart={(e) => peopleDragStart(e, index)}
                        // onDragEnter={(e) => peopleDragEnter(e, index)}
                        // onDragEnd={peopleDrop}
                        onDrag={() => pFPonDrag(e, "pFpExtend")}
                        key={index}
                        draggable
                        className="card"
                        data-list_id="people"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setPepole(e, PrextfoPeople, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {e.workRole === "External" ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}

                          {curPills && curPills.length > 0
                            ? curPills.map((d, index) => {
                                return (
                                  <span onClick={() => cardOpensPf(d)}>
                                    {!d.standIn ? (
                                      <span className="custom-badge lightgray">
                                        {d.name}
                                      </span>
                                    ) : (
                                      <span className="custom-badge gray-o">
                                        {d.name}
                                      </span>
                                    )}
                                  </span>
                                );
                              })
                            : null}

                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>

                        <div className="relations">
                          {addAsPerson ? (
                            <span className="custom-badge lightgray text-red person-pill">
                              Added as person
                            </span>
                          ) : null}
                        </div>

                        <a
                          /* onClick={() => setPepole(e, PrextfoPeople, index)} */ className="card-edit"
                        >{`${e.fname} ${e.lname}`}</a>

                        {flag ? (
                          <a className="card-focus">
                            <img
                              onClick={() => PfToCAndP("People", e)}
                              src="./images/newicons/focus.svg"
                            />
                          </a>
                        ) : null}

                        {addAsPerson ? (
                          <a
                            className="card-unlink"
                            style={{ display: "block", color: "red" }}
                          >
                            <i
                              onClick={() => pFpeopleSub(e, "extend")}
                              className="icon far fa-times-circle"
                            ></i>
                          </a>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <span className="clearable2">
            <input
              type="text"
              ref={fpPref}
              style={{ color: "black" }}
              value={pfPeopleSearch}
              onChange={(e) => setPfPeopleSearch(e.target.value)}
              className="form-control search_list2 transparent"
              name="search_list"
              placeholder="Search to select more"
              autoComplete="off"
            />
            {pfPeopleSearch.length > 0 ? (
              <i
                className="clearable__clear"
                onClick={() => setPfPeopleSearch("")}
                style={{ display: "inline", color: "black" }}
              >
                ×
              </i>
            ) : null}
          </span>

          <div className="panel panel-default">
            <div
              onDrop={() => pFPonDrop("pFpDefult")}
              onDragOver={(event) => pFPonDragOver(event)}
              id="notmatched"
              className="panel-body box-container"
            >
              {filteredPFPDatas && filteredPFPDatas.length > 0
                ? filteredPFPDatas.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "people"
                      );
                      if (!Proceed) return;
                    }
                    return (
                      <div
                        // onDragStart={(e) => peopleDragStart(e, index)}
                        // onDragEnter={(e) => peopleDragEnter(e, index)}
                        // onDragEnd={peopleDrop}
                        onDrag={() => pFPonDrag(e, "pFpDefult")}
                        key={index}
                        draggable
                        className={`card notmatched ${
                          localStorage.getItem("latest-created") === e._id
                            ? " latest-created "
                            : ""
                        }`}
                        data-list_id="people"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setPepole(e, filteredPFPDatas, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {e.workRole === "External" ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>

                        <a
                          /* onClick={() => setPepole(e, filteredPFPDatas, index)} */ className="card-edit"
                        >{`${e.fname} ${e.lname}`}</a>
                        <OverlayTrigger
                          trigger="click"
                          placement="right"
                          overlay={popover}
                        >
                          <a
                            className="card-link"
                            variant="success"
                            style={{ display: "block" }}
                          >
                            <i
                              onClick={() => cFPeopleHandel(e, "People")}
                              className="icon fa fa-plus-circle"
                            ></i>
                          </a>
                        </OverlayTrigger>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          <div
            className="card2"
            style={
              lFromState.Peoples ? { display: "block" } : { display: "none" }
            }
          >
            <form className="form-new-card" role="form" onSubmit={peopleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  ref={pref}
                  name="card_name"
                  placeholder="Add first name"
                  data-mode="Project focus mode"
                  className="form-control"
                  value={fName}
                  onChange={handleNameChange}
                  onKeyPress={pfNameHandel}
                  autoComplete="off"
                  required
                />
              </div>
              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  href="#"
                  type="button"
                  // onClick={() => {setPepoles({ fname: fName })}}
                  onClick={() => {
                    // Added by Debashis on 13-12-2023 (updated 18-12-2023)
                    let lastname = "";
                    let firstname = "";
                    if (fName.trim().indexOf(" ") >= 0) {
                      let nameArray = fName.split(" ");
                      lastname = nameArray.pop();
                      firstname = nameArray.join(" ");
                    } else {
                      lastname = "";
                      firstname = fName;
                    }
                    setFName("");
                    setPepoles({ fname: firstname, lname: lastname });
                    lFromState.Peoples = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  href="#"
                  onClick={() => peopleFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => peopleFrom()}
          data-list_id="people"
          style={
            !lFromState.Peoples ? { display: "block" } : { display: "none" }
          }
        >
          + Add new person
        </a>
      </div>
    </div>
  );

  const FPrSkills = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.skill &&
        tagFilteredMegedData.skill.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="skills"
    >
      <div className="list list-bg-blue" data-list_id="skills">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-skill.png" /> Skills (
            <span>{PrcorfoSkill.length + PrextfoSkill.length}</span>){" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("skill")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              href="#"
              onClick={() => {
                toggleEXTnNOCards("skills", "", true);
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("skill")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Skills")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Skills")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#skillsModal"
                >
                  Add new skill
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Skills")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("skills", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show items owned by external
                </a>
              </li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("skills", "NO", false);
                }}
              >
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          <div
            className="form-group notes"
            style={note.Skills ? { display: "block" } : { display: "none" }}
          >
            <textarea
              className="notes"
              name="Skills"
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              maxLength="100"
              defaultValue={noteSaveData.Skills}
            ></textarea>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => pFSonDrop("pFsCore")}
              onDragOver={(event) => pFSonDragOver(event)}
              id="core"
              className="panel-body box-container"
            >
              {PrcorfoSkill && PrcorfoSkill.length > 0
                ? PrcorfoSkill.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "skill"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Skill");
                    let isPFProjects = isPFProjectsAddS(e._id, "core");
                    let allOwnersFlag = isAllOwnersMarked(e._id, "core");
                    // console.log(16045, isPFProjects, allOwnersFlag);
                    let flagRed = false;
                    if (focused && focused.card === "Projects") {
                      let focusedProject = projectsSorted.find(
                        (p) => p._id === focused.id
                      );
                      let leadOfProjectRedInHtml = focusedProject.lead;
                      if (leadOfProjectRedInHtml === e._id) {
                        flagRed = true;
                      }
                    }
                    return (
                      <div
                        // onDragStart={(e) => skillsDragStart(e, index)}
                        // onDragEnter={(e) => skillsDragEnter(e, index)}
                        // onDragEnd={skillsDrop}
                        onDrag={() => pFSonDrag(e, "pFsCore", isPFProjects)}
                        draggable
                        key={index}
                        className="card "
                        data-list_id="skills"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setSkill(e, PrcorfoSkill, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          ) : null}
                          {e.ownerType.length > 0 &&
                          e.ownerType === "Single owner" ? (
                            <span className="custom-badge blue-o single-owner-pill">
                              1
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <div className="relations">
                          {flagRed ? (
                            <span className="custom-badge red-o direct-lead">
                              LEAD
                            </span>
                          ) : null}
                        </div>
                        <a
                          /* onClick={() => setSkill(e, PrcorfoSkill, index)} */ className="card-edit"
                        >
                          {e.skillName}
                        </a>
                        {!flagRed ? (
                          <a
                            className="card-unlink"
                            style={{ display: "block", color: "red" }}
                          >
                            <i
                              onClick={() =>
                                pFskillSub(e, "core", isPFProjects)
                              }
                              className="icon far fa-times-circle"
                            ></i>
                          </a>
                        ) : null}
                        <br />
                        {isPFProjects &&
                        isPFProjects.length > 0 &&
                        e.owners &&
                        !allOwnersFlag /* e.owners.length > isPFProjects.length */
                          ? isPFProjects.map((value, pdindex) => {
                              return (
                                <span
                                  key={pdindex}
                                  onClick={() => OpenCFPeople(value.Id)}
                                  className="custom-badge tags blue-text"
                                >{`${value.name}`}</span>
                              );
                            })
                          : null}
                      </div>
                    );
                  })
                : null}

              {temporarilyMovedItem &&
              temporarilyMovedItem.area === "Core" &&
              temporarilyMovedItem.cardType === "Skills" &&
              Object.keys(temporarilyMovedItem.card).length > 0
                ? (() => {
                    return (
                      <div
                        key={temporarilyMovedItem.card._id}
                        className="card move-up-down"
                        style={{ border: "1px dashed #000", opacity: "0.7" }}
                      >
                        <span className="static-relations">
                          {temporarilyMovedItem.card.tags &&
                          temporarilyMovedItem.card.tags.length > 0
                            ? temporarilyMovedItem.card.tags.map(
                                (r, indexs) => (
                                  <span
                                    onClick={() => toggleTagCards(r)}
                                    key={indexs}
                                    className="custom-badge green tags"
                                  >
                                    {r}
                                  </span>
                                )
                              )
                            : null}
                        </span>
                        <a className="card-edit">
                          {temporarilyMovedItem.card.skillName}
                        </a>
                      </div>
                    );
                  })()
                : null}
            </div>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => pFSonDrop("pFsExtend")}
              onDragOver={(event) => pFSonDragOver(event)}
              id="extended"
              className="panel-body box-container"
            >
              {/* Extended members listed here*/}
              {PrextfoSkill.length > 0
                ? PrextfoSkill.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "skill"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Skill");
                    let isPFProjects = isPFProjectsAddS(e._id, "extended");
                    let allOwnersFlag = isAllOwnersMarked(e._id, "extended");
                    // console.log(16045, isPFProjects, allOwnersFlag);
                    return (
                      <div
                        // onDragStart={(e) => skillsDragStart(e, index)}
                        // onDragEnter={(e) => skillsDragEnter(e, index)}
                        // onDragEnd={skillsDrop}
                        onDrag={() => pFSonDrag(e, "pFsExtend", isPFProjects)}
                        draggable
                        key={index}
                        className="card "
                        data-list_id="skills"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setSkill(e, PrextfoSkill, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          ) : null}
                          {e.ownerType.length > 0 &&
                          e.ownerType === "Single owner" ? (
                            <span className="custom-badge blue-o single-owner-pill">
                              1
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>

                        <a
                          // onClick={() => setSkill(e, PrextfoSkill, index)}
                          className="card-edit"
                        >
                          {e.skillName}
                        </a>
                        <a
                          className="card-unlink"
                          style={{ display: "block", color: "red" }}
                        >
                          <i
                            onClick={() => pFskillSub(e, "extend")}
                            className="icon far fa-times-circle"
                          ></i>
                        </a>
                        <br />
                        {isPFProjects &&
                        isPFProjects.length > 0 &&
                        e.owners &&
                        !allOwnersFlag /* e.owners.length > isPFProjects.length */
                          ? isPFProjects.map((value, pdindex) => {
                              return (
                                <span
                                  key={pdindex}
                                  onClick={() => OpenCFPeople(value.Id)}
                                  className="custom-badge tags blue-text"
                                >{`${value.name}`}</span>
                              );
                            })
                          : null}
                      </div>
                    );
                  })
                : null}

              {temporarilyMovedItem &&
              temporarilyMovedItem.area === "Extended" &&
              temporarilyMovedItem.cardType === "Skills" &&
              Object.keys(temporarilyMovedItem.card).length > 0
                ? (() => {
                    return (
                      <div
                        key={temporarilyMovedItem.card._id}
                        className="card move-up-down"
                        style={{ border: "1px dashed #000", opacity: "0.7" }}
                      >
                        <span className="static-relations">
                          {temporarilyMovedItem.card.tags &&
                          temporarilyMovedItem.card.tags.length > 0
                            ? temporarilyMovedItem.card.tags.map(
                                (r, indexs) => (
                                  <span
                                    onClick={() => toggleTagCards(r)}
                                    key={indexs}
                                    className="custom-badge green tags"
                                  >
                                    {r}
                                  </span>
                                )
                              )
                            : null}
                        </span>
                        <a className="card-edit">
                          {temporarilyMovedItem.card.skillName}
                        </a>
                      </div>
                    );
                  })()
                : null}
            </div>
          </div>

          <span className="clearable2">
            <input
              type="text"
              ref={fpSref}
              style={{ color: "black" }}
              value={pfSkillsSearch}
              onChange={(e) => setPfSkillsSearch(e.target.value)}
              className="form-control search_list2 transparent"
              name="search_list"
              placeholder="Search to select more"
              autoComplete="off"
            />
            {pfSkillsSearch.length > 0 ? (
              <i
                className="clearable__clear"
                onClick={() => setPfSkillsSearch("")}
                style={{ display: "inline", color: "black" }}
              >
                ×
              </i>
            ) : null}
          </span>

          <div className="panel panel-default">
            <div
              onDrop={() => pFSonDrop("pFsDefult")}
              onDragOver={(event) => pFSonDragOver(event)}
              id="notmatched"
              className="panel-body box-container"
            >
              {filteredPFSData.length > 0
                ? filteredPFSData.filter(
                  item => ![...PrcorfoSkill,...PrextfoSkill].some(skill => skill._id === item._id)
                ).map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "skill"
                      );
                      if (!Proceed) return;
                    }
                    if (
                      temporarilyMovedItem &&
                      temporarilyMovedItem.cardType === "Skills" &&
                      Object.keys(temporarilyMovedItem.card).length > 0 &&
                      temporarilyMovedItem.card._id === e._id
                    )
                      return;

                    let isExternal = IsOwnerExternal(e, "Skill");
                    return (
                      <div
                        // onDragStart={(e) => skillsDragStart(e, index)}
                        // onDragEnter={(e) => skillsDragEnter(e, index)}
                        // onDragEnd={skillsDrop}
                        onDrag={() => pFSonDrag(e, "pFsDefult")}
                        draggable
                        key={index}
                        className={`card notmatched ${
                          localStorage.getItem("latest-created") === e._id
                            ? " latest-created "
                            : ""
                        }`}
                        data-list_id="skills"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setSkill(e, filteredPFSData, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          ) : null}
                          {e.ownerType.length > 0 &&
                          e.ownerType === "Single owner" ? (
                            <span className="custom-badge blue-o single-owner-pill">
                              1
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>

                        <a
                          // onClick={() => setSkill(e, filteredPFSData, index)}
                          className="card-edit"
                        >
                          {e.skillName}
                        </a>
                        <OverlayTrigger
                          trigger="click"
                          placement="right"
                          overlay={popover}
                        >
                          <a
                            className="card-link"
                            variant="success"
                            style={{ display: "block" }}
                          >
                            <i
                              onClick={() =>
                                cfcircleOrProjectModes(e, "Skills")
                              }
                              className="icon fa fa-plus-circle"
                            ></i>
                          </a>
                        </OverlayTrigger>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <div
            className="card2"
            style={
              lFromState.Skills ? { display: "block" } : { display: "none" }
            }
          >
            <form className="form-new-card" role="form" onSubmit={skillSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  ref={sref}
                  name="card_name"
                  required
                  placeholder="Add skill name"
                  className="form-control"
                  value={skill}
                  onChange={handleSkillChange}
                  autoComplete="off"
                />
              </div>
              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  href="#"
                  type="button"
                  onClick={() => {
                    setSkills({ skillName: skill });
                    setSkillName("");
                    lFromState.Skills = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  href="#"
                  onClick={() => skillsFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => skillsFrom()}
          data-list_id="skills"
          style={
            !lFromState.Skills ? { display: "block" } : { display: "none" }
          }
        >
          + Add new skill
        </a>
      </div>
    </div>
  );

  const FPrRoles = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.role &&
        tagFilteredMegedData.role.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="roles"
    >
      <div className="list list-bg-blue" data-list_id="roles">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-role.png" /> Roles (
            <span>{PrcorfoRoles.length + PrextfoRoles.length}</span>){" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("role")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              href="#"
              onClick={() => {
                toggleEXTnNOCards("roles", "", true, "list");
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("role")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Roles")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Roles")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#rolesModal"
                >
                  Add new role
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Roles")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("roles", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show items owned by external
                </a>
              </li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("roles", "NO", false);
                }}
              >
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          <div
            className="form-group notes"
            style={note.Roles ? { display: "block" } : { display: "none" }}
          >
            <textarea
              className="notes"
              name="Roles"
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              maxLength="100"
              defaultValue={noteSaveData.Roles}
            ></textarea>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => pFRonDrop("pFrCore")}
              onDragOver={(event) => pFRonDragOver(event)}
              id="core"
              className="panel-body box-container"
            >
              {/* Core members listed here*/}
              {PrcorfoRoles && PrcorfoRoles.length > 0
                ? PrcorfoRoles.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "role"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Role");
                    let isPFProjects = isPFProjectsAddR(e._id, "core");
                    let allOwnersFlag = isAllOwnersMarked(e._id, "core");
                    let flagRed = false;
                    if (focused && focused.card === "Projects") {
                      let focusedProject = projectsSorted.find(
                        (p) => p._id === focused.id
                      );
                      let leadOfProjectRedInHtml = focusedProject.lead;
                      if (leadOfProjectRedInHtml === e._id) {
                        flagRed = true;
                      }
                    }

                    return (
                      <div
                        // onDragStart={(e) => rolesDragStart(e, index)}
                        // onDragEnter={(e) => rolesDragEnter(e, index)}
                        // onDragEnd={rolesDrop}
                        onDrag={() => pFRonDrag(e, "pFrCore", isPFProjects)}
                        draggable
                        key={index}
                        className="card "
                        data-list_id="roles"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setRole(e, PrcorfoRoles, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          ) : null}
                          {e.ownerRole.length > 0 &&
                          e.ownerRole === "Single owner" ? (
                            <span className="custom-badge blue-o single-owner-pill">
                              1
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>

                        <div className="relations">
                          {flagRed ? (
                            <span className="custom-badge red-o direct-lead">
                              LEAD
                            </span>
                          ) : null}
                        </div>
                        <a
                          // onClick={() => setRole(e, PrcorfoRoles, index)}
                          className="card-edit"
                        >
                          {e.roleName}
                        </a>
                        {!flagRed ? (
                          <a
                            className="card-unlink"
                            style={{ display: "block", color: "red" }}
                          >
                            <i
                              onClick={() => pFroleSub(e, "core")}
                              className="icon far fa-times-circle"
                            ></i>
                          </a>
                        ) : null}
                        <br />
                        {isPFProjects &&
                        isPFProjects.length > 0 &&
                        e.owners &&
                        !allOwnersFlag
                          ? isPFProjects.map((value, pdindex) => {
                              return (
                                <span
                                  key={pdindex}
                                  onClick={() => OpenCFPeople(value.Id)}
                                  className="custom-badge tags blue-text"
                                >{`${value.name}`}</span>
                              );
                            })
                          : null}
                      </div>
                    );
                  })
                : null}

              {temporarilyMovedItem &&
              temporarilyMovedItem.area === "Core" &&
              temporarilyMovedItem.cardType === "Roles" &&
              Object.keys(temporarilyMovedItem.card).length > 0
                ? (() => {
                    return (
                      <div
                        key={temporarilyMovedItem.card._id}
                        className="card move-up-down"
                        style={{ border: "1px dashed #000", opacity: "0.7" }}
                      >
                        <span className="static-relations">
                          {temporarilyMovedItem.card.tags &&
                          temporarilyMovedItem.card.tags.length > 0
                            ? temporarilyMovedItem.card.tags.map(
                                (r, indexs) => (
                                  <span
                                    onClick={() => toggleTagCards(r)}
                                    key={indexs}
                                    className="custom-badge green tags"
                                  >
                                    {r}
                                  </span>
                                )
                              )
                            : null}
                        </span>
                        <a className="card-edit">
                          {temporarilyMovedItem.card.roleName}
                        </a>
                      </div>
                    );
                  })()
                : null}
            </div>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => pFRonDrop("pFrExtend")}
              onDragOver={(event) => pFRonDragOver(event)}
              id="extended"
              className="panel-body box-container"
            >
              {/* Extended members listed here*/}
              {PrextfoRoles && PrextfoRoles.length > 0
                ? PrextfoRoles.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "role"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Role");
                    let isPFProjects = isPFProjectsAddR(e._id, "extended");
                    let allOwnersFlag = isAllOwnersMarked(e._id, "extended");
                    return (
                      <div
                        // onDragStart={(e) => rolesDragStart(e, index)}
                        // onDragEnter={(e) => rolesDragEnter(e, index)}
                        // onDragEnd={rolesDrop}
                        onDrag={() => pFRonDrag(e, "pFrExtend", isPFProjects)}
                        draggable
                        key={index}
                        className="card "
                        data-list_id="roles"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setRole(e, PrextfoRoles, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          ) : null}
                          {e.ownerRole.length > 0 &&
                          e.ownerRole === "Single owner" ? (
                            <span className="custom-badge blue-o single-owner-pill">
                              1
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>

                        <a
                          // onClick={() => setRole(e, PrextfoRoles, index)}
                          className="card-edit"
                        >
                          {e.roleName}
                        </a>
                        <a
                          className="card-unlink"
                          style={{ display: "block", color: "red" }}
                        >
                          <i
                            onClick={() => pFroleSub(e, "extend")}
                            className="icon far fa-times-circle"
                          ></i>
                        </a>
                        <br />
                        {isPFProjects &&
                        isPFProjects.length > 0 &&
                        e.owners &&
                        !allOwnersFlag
                          ? isPFProjects.map((value, pdindex) => {
                              return (
                                <span
                                  key={pdindex}
                                  onClick={() => OpenCFPeople(value.Id)}
                                  className="custom-badge tags blue-text"
                                >{`${value.name}`}</span>
                              );
                            })
                          : null}
                      </div>
                    );
                  })
                : null}

              {temporarilyMovedItem &&
              temporarilyMovedItem.area === "Extended" &&
              temporarilyMovedItem.cardType === "Roles" &&
              Object.keys(temporarilyMovedItem.card).length > 0
                ? (() => {
                    return (
                      <div
                        key={temporarilyMovedItem.card._id}
                        className="card move-up-down"
                        style={{ border: "1px dashed #000", opacity: "0.7" }}
                      >
                        <span className="static-relations">
                          {temporarilyMovedItem.card.tags &&
                          temporarilyMovedItem.card.tags.length > 0
                            ? temporarilyMovedItem.card.tags.map(
                                (r, indexs) => (
                                  <span
                                    onClick={() => toggleTagCards(r)}
                                    key={indexs}
                                    className="custom-badge green tags"
                                  >
                                    {r}
                                  </span>
                                )
                              )
                            : null}
                        </span>
                        <a className="card-edit">
                          {temporarilyMovedItem.card.roleName}
                        </a>
                      </div>
                    );
                  })()
                : null}
            </div>
          </div>

          <span className="clearable2">
            <input
              type="text"
              ref={fprRref}
              style={{ color: "black" }}
              value={pfRolesSearch}
              onChange={(e) => setPfRolesSearch(e.target.value)}
              className="form-control search_list2 transparent"
              name="search_list"
              placeholder="Search to select more"
              autoComplete="off"
            />
            {pfRolesSearch.length > 0 ? (
              <i
                className="clearable__clear"
                onClick={() => setPfRolesSearch("")}
                style={{ display: "inline", color: "black" }}
              >
                ×
              </i>
            ) : null}
          </span>

          <div className="panel panel-default">
            <div
              onDrop={() => pFRonDrop("pFrDefult")}
              onDragOver={(event) => pFRonDragOver(event)}
              id="notmatched"
              className="panel-body box-container"
            >
              {filteredPFRData && filteredPFRData.length > 0
                ? filteredPFRData.filter(
                  item => ![...PrcorfoRoles,...PrextfoRoles].some(role => role._id === item._id)
                ).map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "role"
                      );
                      if (!Proceed) return;
                    }
                    if (
                      temporarilyMovedItem &&
                      temporarilyMovedItem.cardType === "Roles" &&
                      Object.keys(temporarilyMovedItem.card).length > 0 &&
                      temporarilyMovedItem.card._id === e._id
                    )
                      return;

                    let isExternal = IsOwnerExternal(e, "Role");
                    if (e.defaultRole) return;

                    return (
                      <div
                        // onDragStart={(e) => rolesDragStart(e, index)}
                        // onDragEnter={(e) => rolesDragEnter(e, index)}
                        // onDragEnd={rolesDrop}
                        onDrag={() => pFRonDrag(e, "pFrDefult")}
                        draggable
                        key={index}
                        className={`card notmatched ${
                          localStorage.getItem("latest-created") === e._id
                            ? " latest-created "
                            : ""
                        }`}
                        data-list_id="roles"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setRole(e, filteredPFRData, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          ) : null}
                          {e.ownerRole.length > 0 &&
                          e.ownerRole === "Single owner" ? (
                            <span className="custom-badge blue-o single-owner-pill">
                              1
                            </span>
                          ) : null}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>

                        <a
                          // onClick={() => setRole(e, filteredPFRData, index)}
                          className="card-edit"
                        >
                          {e.roleName}
                        </a>

                        <OverlayTrigger
                          trigger="click"
                          placement="right"
                          overlay={popover}
                        >
                          <a
                            className="card-link"
                            variant="success"
                            style={{ display: "block" }}
                          >
                            <i
                              onClick={() => cfcircleOrProjectModes(e, "Roles")}
                              className="icon fa fa-plus-circle"
                            ></i>
                          </a>
                        </OverlayTrigger>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          <div
            className="card2"
            style={
              lFromState.Roles ? { display: "block" } : { display: "none" }
            }
          >
            <form className="form-new-card" role="form" onSubmit={roleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  ref={rref}
                  name="card_name"
                  required
                  placeholder="Add role name"
                  className="form-control"
                  value={role}
                  onChange={handleRoleChange}
                  autoComplete="off"
                />
              </div>
              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  href="#"
                  type="button"
                  onClick={() => {
                    setRoles({ roleName: role });
                    setRoleName("");
                    lFromState.Roles = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  href="#"
                  onClick={() => rolesFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => rolesFrom()}
          data-list_id="roles"
          style={!lFromState.Roles ? { display: "block" } : { display: "none" }}
        >
          + Add new role
        </a>
      </div>
    </div>
  );

  const FPrDomains = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.domain &&
        tagFilteredMegedData.domain.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="domains"
    >
      <div className="list list-bg-blue" data-list_id="domains">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-domain.png" /> Domains (
            <span>{PrcorfoDomains.length + PrextfoDomains.length}</span>){" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("domain")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              href="#"
              onClick={() => {
                toggleEXTnNOCards("domains", "", true, "list");
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("domain")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Domains")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Doamins")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#domainModal"
                >
                  Add new domain
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Domains")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("domains", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show items owned by external
                </a>
              </li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("domains", "NO", false);
                }}
              >
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          <div
            className="form-group notes"
            style={note.Domains ? { display: "block" } : { display: "none" }}
          >
            <textarea
              className="notes"
              name="Domains"
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              maxLength="100"
              defaultValue={noteSaveData.Domains}
            ></textarea>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => pFDonDrop("pFdCore")}
              onDragOver={(event) => pFDonDragOver(event)}
              id="core"
              className="panel-body box-container"
            >
              {PrcorfoDomains && PrcorfoDomains.length > 0
                ? PrcorfoDomains.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "domain"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Domain");
                    let whoIsOwnerType = dWhoIsOwnerType(e);
                    let isPFProjects = isPFProjectsAddD(e._id, "core");
                    let curPills = [];
                    if (prfRAnfdSDomain && prfRAnfdSDomain.length > 0) {
                      for (let i = 0; i < prfRAnfdSDomain.length; i++) {
                        if (
                          prfRAnfdSDomain[i].domainId === e._id &&
                          e._id !== whoIsOwnerType.cardId
                        ) {
                          curPills.push(prfRAnfdSDomain[i]);
                        }
                      }
                    }

                    return (
                      <div
                        // onDragStart={(e) => domainsDragStart(e, index)}
                        // onDragEnter={(e) => domainsDragEnter(e, index)}
                        // onDragEnd={domainsDrop}
                        onDrag={() => pFDonDrag(e, "pFdCore", isPFProjects)}
                        draggable
                        key={index}
                        className="card "
                        data-list_id="domain"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setDomain(e, PrcorfoDomains, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners &&
                          e.owners.owner &&
                          e.owners.owner.length > 0 &&
                          OwnerExist([e.owners.owner]) ? null : (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          )}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                          {curPills && curPills.length > 0 ? (
                            curPills.map((r, index) => (
                              <span
                                onClick={() => cardOpensCfDomain(r)}
                                key={index}
                                className="custom-badge lightgray"
                              >
                                {`${r.cardName} (${r.type})`}
                              </span>
                            ))
                          ) : whoIsOwnerType &&
                            Object.keys(whoIsOwnerType).length > 0 ? (
                            <span
                              onClick={() => cardOpensCfDomain(whoIsOwnerType)}
                              className="custom-badge lightgray"
                            >
                              {whoIsOwnerType.name}
                            </span>
                          ) : null}
                        </div>
                        <a
                          // onClick={() => setDomain(e, PrcorfoDomains, index)}
                          className="card-edit"
                        >
                          {e.domainName}
                        </a>
                        {curPills.length === 0 ? (
                          <a
                            className="card-unlink"
                            style={{ display: "block", color: "red" }}
                          >
                            <i
                              onClick={() => pFdomainSub(e, "core")}
                              className="icon far fa-times-circle"
                            ></i>
                          </a>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => pFDonDrop("pFdExtend")}
              onDragOver={(event) => pFDonDragOver(event)}
              id="extended"
              className="panel-body box-container"
            >
              {/* Extended members listed here*/}
              {PrextfoDomains && PrextfoDomains.length > 0
                ? PrextfoDomains.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "domain"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Domain");
                    let whoIsOwnerType = dWhoIsOwnerType(e);
                    let isPFProjects = isPFProjectsAddD(e._id, "extended");
                    let curPills = [];
                    if (prfRAnfdSExDomain && prfRAnfdSExDomain.length > 0) {
                      for (let i = 0; i < prfRAnfdSExDomain.length; i++) {
                        if (
                          prfRAnfdSExDomain[i].domainId === e._id &&
                          e._id !== whoIsOwnerType.cardId
                        ) {
                          curPills.push(prfRAnfdSExDomain[i]);
                        }
                      }
                    }
                    return (
                      <div
                        // onDragStart={(e) => domainsDragStart(e, index)}
                        // onDragEnter={(e) => domainsDragEnter(e, index)}
                        // onDragEnd={domainsDrop}
                        onDrag={() => pFDonDrag(e, "pFdExtend", isPFProjects)}
                        draggable
                        key={index}
                        className="card "
                        data-list_id="domain"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setDomain(e, PrextfoDomains, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners &&
                          e.owners.owner &&
                          e.owners.owner.length > 0 &&
                          OwnerExist([e.owners.owner]) ? null : (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          )}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                          {curPills && curPills.length > 0 ? (
                            curPills.map((r, index) => (
                              <span
                                onClick={() => cardOpensCfDomain(r)}
                                key={index}
                                className="custom-badge lightgray"
                              >
                                {`${r.cardName} (${r.type})`}
                              </span>
                            ))
                          ) : whoIsOwnerType &&
                            Object.keys(whoIsOwnerType).length > 0 ? (
                            <span
                              onClick={() => cardOpensCfDomain(whoIsOwnerType)}
                              className="custom-badge lightgray"
                            >
                              {whoIsOwnerType.name}
                            </span>
                          ) : null}
                        </div>
                        <a
                          // onClick={() => setDomain(e, PrextfoDomains, index)}
                          className="card-edit"
                        >
                          {e.domainName}
                        </a>
                        {curPills.length === 0 ? (
                          <a
                            className="card-unlink"
                            style={{ display: "block", color: "red" }}
                          >
                            <i
                              onClick={() => pFdomainSub(e, "extend")}
                              className="icon far fa-times-circle"
                            ></i>
                          </a>
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <span className="clearable2">
            <input
              type="text"
              ref={fprDref}
              style={{ color: "black" }}
              value={pfDomainsSearch}
              onChange={(e) => setPfDomainsSearch(e.target.value)}
              className="form-control search_list2 transparent"
              name="search_list"
              placeholder="Search to select more"
              autoComplete="off"
            />
            {pfDomainsSearch.length > 0 ? (
              <i
                className="clearable__clear"
                onClick={() => setPfDomainsSearch("")}
                style={{ display: "inline", color: "black" }}
              >
                ×
              </i>
            ) : null}
          </span>

          <div className="panel panel-default">
            <div
              onDrop={() => pFDonDrop("pFdDefult")}
              onDragOver={(event) => pFDonDragOver(event)}
              id="notmatched"
              className="panel-body box-container"
            >
              {filteredPFFDData && filteredPFFDData.length > 0
                ? filteredPFFDData.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "domain"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Domain");
                    let whoIsOwnerType = dWhoIsOwnerType(e);
                    return (
                      <div
                        // onDragStart={(e) => domainsDragStart(e, index)}
                        // onDragEnter={(e) => domainsDragEnter(e, index)}
                        // onDragEnd={domainsDrop}
                        onDrag={() => pFDonDrag(e, "pFdDefult")}
                        // draggable={e.owners && !e.owners.type ? true : false}
                        draggable
                        key={index}
                        className={`card notmatched ${
                          localStorage.getItem("latest-created") === e._id
                            ? " latest-created "
                            : ""
                        }`}
                        data-list_id="domains"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setDomain(e, filteredPFFDData, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owners &&
                          e.owners.owner &&
                          e.owners.owner.length > 0 &&
                          OwnerExist([e.owners.owner]) ? null : (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          )}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}

                          {whoIsOwnerType &&
                          Object.keys(whoIsOwnerType).length > 0 ? (
                            <span
                              onClick={() => cardOpensCfDomain(whoIsOwnerType)}
                              className="custom-badge lightgray"
                            >
                              {whoIsOwnerType.name}
                            </span>
                          ) : null}
                        </div>
                        <a
                          // onClick={() => setDomain(e, filteredPFFDData, index)}
                          className="card-edit"
                        >
                          {e.domainName}
                        </a>
                        {/* {!e.owners.type ? */}
                        <OverlayTrigger
                          trigger="click"
                          placement={
                            list[list.length - 1] === "Domains"
                              ? "left"
                              : "right"
                          }
                          overlay={popover}
                        >
                          <a
                            className="card-link"
                            variant="success"
                            style={{ display: "block" }}
                          >
                            <i
                              onClick={() => cFPeopleHandel(e, "Domains")}
                              className="icon fa fa-plus-circle"
                            ></i>
                          </a>
                        </OverlayTrigger>
                        {/* : null} */}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          <div
            className="card2"
            style={
              lFromState.Domains ? { display: "block" } : { display: "none" }
            }
          >
            <form className="form-new-card" role="form" onSubmit={domainSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  ref={dref}
                  name="card_name"
                  required
                  placeholder="Add domain name"
                  className="form-control"
                  value={domain}
                  onChange={handleDomainChange}
                  autoComplete="off"
                />
              </div>
              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  href="#"
                  type="button"
                  onClick={() => {
                    setDomains({ domainName: domain });
                    setDomainName("");
                    lFromState.Domains = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  href="#"
                  onClick={() => domainsFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => domainsFrom()}
          data-list_id="domain"
          style={
            !lFromState.Domains ? { display: "block" } : { display: "none" }
          }
        >
          + Add new domain
        </a>
      </div>
    </div>
  );

  const FPrLinks = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.link &&
        tagFilteredMegedData.link.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="links"
    >
      <div className="list list-bg-blue" data-list_id="links">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-link.png" /> Links (
            <span>{PrcorfoLinks.length + PrextfoLinks.length}</span>){" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("link")
                  ? "list-menu refresh-list"
                  : "list-menu refresh-list hide"
              }
              href="#"
              onClick={() => {
                toggleEXTnNOCards("links", "", true, "list");
              }}
            >
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className={
                clickedOnList.isClicked &&
                clickedOnList.listName.includes("link")
                  ? "list-menu dropdown-toggle hide"
                  : "list-menu dropdown-toggle"
              }
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Links")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Links")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#linksModal"
                >
                  Add new link
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Links")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="divider"></li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("links", "EXT", false);
                }}
              >
                <a role="menuitem" className="show-external">
                  Show items owned by external
                </a>
              </li>
              <li
                role="presentation"
                onClick={() => {
                  toggleEXTnNOCards("links", "NO", false);
                }}
              >
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          <div
            className="form-group notes"
            style={note.Links ? { display: "block" } : { display: "none" }}
          >
            <textarea
              className="notes"
              name="Links"
              // defaultValue=''
              // value={noteSaveData.Links}
              //   onChange={(e) => handleSave(e)}
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              maxLength="100"
              defaultValue={noteSaveData.Links}
            ></textarea>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => pFLonDrop("pFlCore")}
              onDragOver={(event) => pFLonDragOver(event)}
              id="core"
              className="panel-body box-container"
            >
              {/* Core members listed here*/}
              {PrcorfoLinks && PrcorfoLinks.length > 0
                ? PrcorfoLinks.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "link"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Link");
                    let isPFProjects = isPFProjectsAddL(e._id, "core");
                    return (
                      <div
                        // onDragStart={(e) => linksDragStart(e, index)}
                        // onDragEnter={(e) => linksDragEnter(e, index)}
                        // onDragEnd={linksDrop}
                        onDrag={() => pFLonDrag(e, "pFlCore", isPFProjects)}
                        draggable
                        key={index}
                        className="card "
                        data-list_id="links"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setLink(e, PrcorfoLinks, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owner &&
                          e.owner.length > 0 &&
                          OwnerExist([e.owner]) ? null : (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          )}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>

                        <a
                          // onClick={() => setLink(e, PrcorfoLinks, index)}
                          className="card-edit"
                        >
                          {e.linkName}
                        </a>
                        <a
                          className="card-unlink"
                          style={{ display: "block", color: "red" }}
                        >
                          <i
                            onClick={() => pFlinkSub(e, "core")}
                            className="icon far fa-times-circle"
                          ></i>
                        </a>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
          <div className="panel panel-default">
            <div
              onDrop={() => pFLonDrop("pFlExtend")}
              onDragOver={(event) => pFLonDragOver(event)}
              id="extended"
              className="panel-body box-container"
            >
              {/* Extended members listed here*/}
              {PrextfoLinks && PrextfoLinks.length > 0
                ? PrextfoLinks.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "link"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Link");
                    let isPFProjects = isPFProjectsAddL(e._id, "extended");
                    return (
                      <div
                        // onDragStart={(e) => linksDragStart(e, index)}
                        // onDragEnter={(e) => linksDragEnter(e, index)}
                        // onDragEnd={linksDrop}
                        onDrag={() => pFLonDrag(e, "pFlExtend", isPFProjects)}
                        draggable
                        key={index}
                        className="card "
                        data-list_id="links"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setLink(e, PrextfoLinks, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owner &&
                          e.owner.length > 0 &&
                          OwnerExist([e.owner]) ? null : (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          )}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>

                        <a
                          // onClick={() => setLink(e, PrextfoLinks, index)}
                          className="card-edit"
                        >
                          {e.linkName}
                        </a>
                        <a
                          className="card-unlink"
                          style={{ display: "block", color: "red" }}
                        >
                          <i
                            onClick={() => pFlinkSub(e, "extend")}
                            className="icon far fa-times-circle"
                          ></i>
                        </a>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <span className="clearable2">
            <input
              type="text"
              ref={fsprLref}
              style={{ color: "black" }}
              value={pfLinksSearch}
              onChange={(e) => setPfLinksSearch(e.target.value)}
              className="form-control search_list2 transparent"
              name="search_list"
              placeholder="Search to select more"
              autoComplete="off"
            />
            {pfLinksSearch.length > 0 ? (
              <i
                className="clearable__clear"
                onClick={() => setPfLinksSearch("")}
                style={{ display: "inline", color: "black" }}
              >
                ×
              </i>
            ) : null}
          </span>

          <div className="panel panel-default">
            <div
              onDrop={() => pFLonDrop("pFlDefult")}
              onDragOver={(event) => pFLonDragOver(event)}
              id="notmatched"
              className="panel-body box-container"
            >
              {filteredPFLData && filteredPFLData.length > 0
                ? filteredPFLData.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "link"
                      );
                      if (!Proceed) return;
                    }
                    let isExternal = IsOwnerExternal(e, "Link");
                    return (
                      <div
                        // onDragStart={(e) => linksDragStart(e, index)}
                        // onDragEnter={(e) => linksDragEnter(e, index)}
                        // onDragEnd={linksDrop}
                        onDrag={() => pFLonDrag(e, "pFlDefult")}
                        draggable
                        key={index}
                        className={`card notmatched ${
                          localStorage.getItem("latest-created") === e._id
                            ? " latest-created "
                            : ""
                        }`}
                        data-list_id="links"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setLink(e, filteredPFLData, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {isExternal ? (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "EXT", false, "pill");
                              }}
                              className="custom-badge blue ext-owner-pill"
                            >
                              EXT
                            </span>
                          ) : null}
                          {e.owner &&
                          e.owner.length > 0 &&
                          OwnerExist([e.owner]) ? null : (
                            <span
                              onClick={() => {
                                toggleEXTnNOCards(e._id, "NO", false, "pill");
                              }}
                              className="custom-badge no-owner-pill red"
                            >
                              NO
                            </span>
                          )}
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>

                        <a
                          // onClick={() => setLink(e, filteredPFLData, index)}
                          className="card-edit"
                        >
                          {e.linkName}
                        </a>

                        <OverlayTrigger
                          trigger="click"
                          placement={
                            list[list.length - 1] === "Links" ? "left" : "right"
                          }
                          overlay={popover}
                        >
                          <a
                            className="card-link"
                            variant="success"
                            style={{ display: "block" }}
                          >
                            <i
                              onClick={() => cFPeopleHandel(e, "Links")}
                              className="icon fa fa-plus-circle"
                            ></i>
                          </a>
                        </OverlayTrigger>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
          <div
            className="card2"
            style={
              lFromState.Links ? { display: "block" } : { display: "none" }
            }
          >
            <form className="form-new-card" role="form" onSubmit={linkSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  ref={lref}
                  name="card_name"
                  required
                  placeholder="Add link name"
                  className="form-control"
                  value={link}
                  onChange={handleLinkChange}
                  autoComplete="off"
                />
              </div>
              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  href="#"
                  type="button"
                  onClick={() => {
                    setLinks({ linkName: link });
                    setLinkName("");
                    lFromState.Links = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  href="#"
                  onClick={() => linksFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => linksFrom()}
          data-list_id="links"
          style={!lFromState.Links ? { display: "block" } : { display: "none" }}
        >
          + Add new link
        </a>
      </div>
    </div>
  );

  const FPrCircles = () => (
    <div
      className={
        Object.keys(tagFilteredMegedData).length > 0 &&
        tagFilteredMegedData.circle &&
        tagFilteredMegedData.circle.length === 0
          ? "board-col hide"
          : "board-col"
      }
      data-board_id="circles"
    >
      <div className="list list-bg-red" data-list_id="circles">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-circle.png" /> Circles (
            <span>{PrcorfoCircles.length}</span>){" "}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>{" "}
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Circles")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Circles")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#circlesModal"
                  onClick={() => setCircles()}
                >
                  Add new circle
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Circles")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="hide">
                <a role="menuitem" className="show-external">
                  Show external people
                </a>
              </li>
              <li role="presentation" className="hide">
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar">
          <div
            className="form-group notes"
            style={note.Circles ? { display: "block" } : { display: "none" }}
          >
            <textarea
              className="notes"
              name="Circles"
              // defaultValue=''
              // value={noteSaveData.Circles}
              //   onChange={(e) => handleSave(e)}
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              maxLength="100"
              defaultValue={noteSaveData.Circles}
            ></textarea>
          </div>
          {/* <h6 style={{"borderBottom": "1px solid #000"}}>Core members</h6> */}
          <div className="panel panel-default">
            <div
              onDrop={() => pFConDrop("pFcCore")}
              onDragOver={(event) => pFConDragOver(event)}
              id="core"
              className="panel-body single box-container"
            >
              {/* Core members listed here*/}
              {PrcorfoCircles && PrcorfoCircles.length > 0
                ? PrcorfoCircles.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "circle"
                      );
                      if (!Proceed) return;
                    }
                    return (
                      <div
                        key={index}
                        // onDragStart={(e) => circlesDragStart(e, index)}
                        // onDragEnter={(e) => circlesDragEnter(e, index)}
                        // onDragEnd={circlesDrop}
                        onDrag={() => pFConDrag(e, "pFcCore")}
                        draggable
                        className="card "
                        data-list_id="circles"
                        style={{ minHeight: "54px" }}
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setCircle(e, PrcorfoCircles, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <a
                          // onClick={() => setCircle(e, PrcorfoCircles, index)}
                          className="card-edit"
                        >
                          {e.circleName}
                        </a>
                        <a className="card-focus">
                          <img
                            onClick={() => PfToCAndP("Circles", e)}
                            src="./images/newicons/focus.svg"
                          />
                        </a>
                        <a
                          className="card-unlink"
                          style={{ display: "block", color: "red" }}
                        >
                          <i
                            onClick={() => pFcircleSub(e, "core")}
                            className="icon far fa-times-circle"
                          ></i>
                        </a>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <span className="clearable2">
            <input
              type="text"
              ref={fprCref}
              style={{ color: "black" }}
              value={pfCirclesSearch}
              onChange={(e) => setPfCirclesSearch(e.target.value)}
              className="form-control search_list2 transparent"
              name="search_list"
              placeholder="Search to select more"
              autoComplete="off"
            />
            {pfLinksSearch.length > 0 ? (
              <i
                className="clearable__clear"
                onClick={() => setPfLinksSearch("")}
                style={{ display: "inline", color: "black" }}
              >
                ×
              </i>
            ) : null}
          </span>

          <div className="panel panel-default">
            <div
              onDrop={() => pFConDrop("pFcDefult")}
              onDragOver={(event) => pFConDragOver(event)}
              id="notmatched"
              className="panel-body box-container"
            >
              {filteredPFCData && filteredPFCData.length > 0
                ? filteredPFCData.map((e, index) => {
                    if (isTag || isEXT || isNO) {
                      let Proceed = TagWiseSorting(
                        e,
                        isTag,
                        isEXT,
                        isNO,
                        tagFilteredObj,
                        extNoFilteredObj,
                        "circle"
                      );
                      if (!Proceed) return;
                    }
                    return (
                      <div
                        key={index}
                        // onDragStart={(e) => circlesDragStart(e, index)}
                        // onDragEnter={(e) => circlesDragEnter(e, index)}
                        // onDragEnd={circlesDrop}
                        onDrag={() => pFConDrag(e, "pFcDefult")}
                        draggable={
                          PrcorfoCircles && PrcorfoCircles.length > 0
                            ? false
                            : true
                        }
                        className={`card notmatched ${
                          localStorage.getItem("latest-created") === e._id
                            ? " latest-created "
                            : ""
                        }`}
                        data-list_id="circles"
                        onClick={(event) => {
                          if (
                            event.target.classList.contains("card") ||
                            event.target.classList.contains("card-edit")
                          ) {
                            setCircle(e, filteredPFCData, index);
                          }
                        }}
                      >
                        <div className="static-relations">
                          {e.tags && e.tags.length > 0
                            ? e.tags.map((r, index) => (
                                <span
                                  onClick={() => toggleTagCards(r)}
                                  key={index}
                                  className="custom-badge green tags"
                                >
                                  {r}
                                </span>
                              ))
                            : null}
                        </div>
                        <a
                          // onClick={() => setCircle(e, filteredPFCData, index)}
                          className="card-edit"
                        >
                          {e.circleName}
                        </a>
                        {PrcorfoCircles && PrcorfoCircles.length === 0 ? (
                          <a
                            className="card-link"
                            variant="success"
                            style={{ display: "block" }}
                          >
                            <i
                              onClick={() => {
                                cFPeopleHandel(e, "Circles");
                                cFPeopleIdHandel("Core");
                              }}
                              className="icon fa fa-plus-circle"
                            ></i>
                          </a>
                        ) : null}
                      </div>
                    );
                  })
                : null}
              <span className="cardend" style={{ display: "none" }} />
            </div>
          </div>
          <div
            className="card2"
            style={
              lFromState.Circles ? { display: "block" } : { display: "none" }
            }
          >
            <form className="form-new-card" role="form" onSubmit={circleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  ref={cref}
                  name="card_name"
                  required
                  placeholder="Add circle name"
                  className="form-control"
                  value={circle}
                  onChange={handleCircleChange}
                  autoComplete="off"
                />
              </div>
              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  href="#"
                  type="button"
                  onClick={() => {
                    setCircles({ circleName: circle });
                    setCircleName("");
                    lFromState.Circles = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  href="#"
                  onClick={() => circlesFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => circlesFrom()}
          data-list_id="circles"
          style={
            !lFromState.Circles ? { display: "block" } : { display: "none" }
          }
        >
          + Add new circle
        </a>
      </div>
    </div>
  );

  const FPrProjects = () => (
    <div className="board-col" data-board_id="projects">
      <div className="list list-bg-red" data-list_id="projects">
        <div className="dropdown">
          <h4 className="list-title">
            <img alt="" src="images/icon-project.png" /> Projects (
            {projectsSorted.length}){" "}
            <a className="list-menu refresh-list hide" href="#">
              <i className="fas fa-sync-alt"></i>
            </a>
            <a
              className="list-menu dropdown-toggle"
              data-toggle="dropdown"
              href="#"
            >
              {getSortIcon("Projects")}
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-right" role="menu">
              <li role="presentation">
                <a
                  onClick={() => isInfos("Projects")}
                  role="menuitem"
                  className="mytpt-list-info"
                >
                  Info
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  data-toggle="modal"
                  data-target="#projectsModal"
                  onClick={() => setProjects()}
                >
                  Add new project
                </a>
              </li>
              <li role="presentation">
                <a
                  role="menuitem"
                  onClick={() => pCardHideOnchange("Projects")}
                  className="hide_list"
                >
                  Hide list
                </a>
              </li>
              <li role="presentation" className="hide">
                <a role="menuitem" className="show-external">
                  Show external people
                </a>
              </li>
              <li role="presentation" className="hide">
                <a role="menuitem" className="show-noowners">
                  Show items without owners
                </a>
              </li>
            </ul>
          </h4>
        </div>

        <div className="card-list scrollbar" ref={focusListRef}>
          <div
            className="form-group notes"
            style={note.Projects ? { display: "block" } : { display: "none" }}
          >
            <textarea
              className="notes"
              name="Projects"
              // defaultValue=''
              // value={noteSaveData.Projects}
              //   onChange={(e) => handleSave(e)}
              onBlur={(e) => handleSave(e)}
              placeholder="Notes"
              maxLength="100"
              defaultValue={noteSaveData.Projects}
            ></textarea>
          </div>

          {PrFCircleSorted && PrFCircleSorted.length > 0
            ? PrFCircleSorted.map((e, index) => {
                /* you can use existing function or tagFilterdata necause it is only affected by tagFilter Day */
                if ((isTag || isEXT || isNO) && e._id !== focused.id) {
                  let Proceed = TagWiseSorting(
                    e,
                    isTag,
                    isEXT,
                    isNO,
                    tagFilteredObj,
                    extNoFilteredObj,
                    "project"
                  );
                  if (!Proceed) return;
                }
                return (
                  <div
                    // onDragStart={(e) => projectsDragStart(e, index)}
                    // onDragEnter={(e) => projectsDragEnter(e, index)}
                    // onDragEnd={projectsDrop}
                    draggable={
                      focused && focused.card && focused.card !== "Projects"
                        ? true
                        : false
                    }
                    key={index}
                    className={`card ${
                      focused.card === "Projects" && focused.id === e._id
                        ? "focused-card"
                        : localStorage.getItem("latest-created") === e._id
                        ? " latest-created "
                        : ""
                    }`}
                    data-list_id="projects"
                    onClick={(event) => {
                      if (
                        event.target.classList.contains("card") ||
                        event.target.classList.contains("card-edit")
                      ) {
                        setProject(e, PrFCircleSorted, index);
                      }

                      if (parentProjectRef.current) {
                        const scrollTop = parentProjectRef.current.scrollTop;
                        // console.log("Scroll position:", scrollTop);
                        setFocusedRef(scrollTop);
                      }
                    }}
                  >
                    <div className="static-relations">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((r, index) => (
                            <span
                              onClick={() => toggleTagCards(r)}
                              key={index}
                              className="custom-badge green tags"
                            >
                              {r}
                            </span>
                          ))
                        : null}
                    </div>
                    <a
                      /* onClick={() => setProject(e, PrFCircleSorted, index)} */ className="card-edit"
                    >
                      {e.projectName}
                    </a>
                    {focused.card === "Projects" && focused.id === e._id ? (
                      <a className="card-unlink" style={{ display: "block" }}>
                        <i
                          onClick={() =>
                            handelFocused({
                              card: "CProjects",
                              index: null,
                              id: null,
                            })
                          }
                          className="icon far fa-times-circle fas"
                        ></i>
                      </a>
                    ) : focused.card === "Projects" && focused.id !== e._id ? (
                      <a className="card-focus">
                        <img
                          onClick={() =>
                            handelFocused({
                              card: "Projects",
                              index: index,
                              id: e._id,
                            })
                          }
                          src="./images/newicons/focus.svg"
                        />
                      </a>
                    ) : null}
                  </div>
                );
              })
            : null}
          <div
            className="card2"
            style={
              lFromState.Projects ? { display: "block" } : { display: "none" }
            }
          >
            <form
              className="form-new-card"
              role="form"
              onSubmit={projectSubmit}
            >
              <div className="form-group">
                <input
                  type="text"
                  ref={prref}
                  name="card_name"
                  required
                  placeholder="Add project name"
                  className="form-control"
                  value={project}
                  onChange={handleProjectChange}
                  autoComplete="off"
                />
              </div>
              <div className="form-group" style={{ marginTop: "10px" }}>
                <button type="submit" className="btn btn-mytpt add-quick">
                  Save
                </button>
                <a
                  href="#"
                  type="button"
                  onClick={() => {
                    setProjects({ projectName: project });
                    setProjectName("");
                    lFromState.Projects = false;
                  }}
                  className="btn btn-link underline open-tool"
                >
                  Edit details
                </a>
                <a
                  href="#"
                  onClick={() => projectsFrom()}
                  className="btn btn-link underline close-add-card"
                >
                  Close
                </a>
              </div>
            </form>
          </div>
        </div>
        <a
          className="btn-list add-card"
          onClick={() => projectsFrom()}
          data-list_id="projects"
          style={
            !lFromState.Projects ? { display: "block" } : { display: "none" }
          }
        >
          + Add new project
        </a>
      </div>
    </div>
  );

  /* '''''''''''''''''''''''''''''''''''''''' Project Focus - XLS And JPEG Downlod '''''''''''''''''''''''''''''''''''''''''''''''' */
  let peojectFXls = [];
  let xlsPPeople = [...PrcorfoPeople, ...PrextfoPeople];
  let xlsPSkill = [...PrcorfoSkill, ...PrextfoSkill];
  let xlsPRole = [...PrcorfoRoles, ...PrextfoRoles];
  let xlsPDomain = [...PrcorfoDomains, ...PrextfoDomains];
  let xlsPLink = [...PrcorfoLinks, ...PrextfoLinks];

  if (focused && focused.card.length > 0 && focused.card === "Projects") {
    if (
      xlsPPeople.length > 0 ||
      xlsPSkill.length > 0 ||
      xlsPRole.length > 0 ||
      xlsPDomain.length > 0 ||
      xlsPLink.length > 0 ||
      PrcorfoCircles.length > 0 ||
      projectsSorted[focused.index]
    ) {
      let arr = [
        xlsPPeople.length,
        xlsPSkill.length,
        xlsPRole.length,
        xlsPDomain.length,
        xlsPLink.length,
        PrcorfoCircles.length,
        1,
      ];

      if (arr && arr.length > 0) {
        const max = arr.reduce((a, b) => {
          return Math.max(a, b);
        });

        let xlsProject = [projectsSorted.find((p) => p._id === focused.id)]; // [projectsSorted[focused.index]];

        for (let i = 0; i < max; i++) {
          let obj = {
            People:
              xlsPPeople[i] && xlsPPeople[i]._id
                ? `${xlsPPeople[i].fname} ${xlsPPeople[i].lname}`
                : "",
            Skill:
              xlsPSkill[i] && xlsPSkill[i]._id
                ? `${xlsPSkill[i].skillName}`
                : "",
            Role:
              xlsPRole[i] && xlsPRole[i]._id ? `${xlsPRole[i].roleName}` : "",
            Domain:
              xlsPDomain[i] && xlsPDomain[i]._id
                ? `${xlsPDomain[i].domainName}`
                : "",
            Link:
              xlsPLink[i] && xlsPLink[i]._id ? `${xlsPLink[i].linkName}` : "",
            Circle:
              PrcorfoCircles[i] && PrcorfoCircles[i]._id
                ? `${PrcorfoCircles[i].circleName}`
                : "",
            Project:
              xlsProject[i] && xlsProject[i]._id
                ? `${xlsProject[i].projectName}`
                : "",
          };
          peojectFXls.push(obj);
        }
      }
    }
  }

  useEffect(() => {
    if (focused && focused.card.length > 0 && focused.card === "Projects") {
      setXls(peojectFXls);
    }
  }, [focused]);

  /* ============================== Meetings actions ================================================ */

  const cAndpADatas = (meetingsData) => {
    let cursMeetings = [];
    for (let i = 0; i < meetings.length; i++) {
      let inc = meetingsData.includes(meetings[i]._id);
      if (inc) {
        cursMeetings.push(meetings[i]);
      }
    }
    return cursMeetings;
  };

  /* ============================== Mode MODELS Action ============================================== */

  /* ============================== Circle mode actions ============================================= */

  const cMmembes = (curCoresMembers, curExtendedsMembers, data) => {
    let res = { people: null, rAndS: null, curType: null };
    let cardData = {};
    if (data && data.lead && data.lead.length > 0) {
      if (
        res &&
        res.rAndS === null &&
        skillsSorted &&
        skillsSorted.length > 0
      ) {
        for (let i = 0; i < skillsSorted.length; i++) {
          if (skillsSorted[i]._id === data.lead) {
            res = {
              ...res,
              ["rAndS"]: skillsSorted[i]._id,
              ["curType"]: "Skills",
            };
            cardData = skillsSorted[i];
            break;
          }
        }
      }
      if (
        res &&
        res.rAndS === null &&
        Object.keys(cardData).length === 0 &&
        rolesSorted &&
        rolesSorted.length > 0
      ) {
        for (let i = 0; i < rolesSorted.length; i++) {
          if (rolesSorted[i]._id === data.lead) {
            res = {
              ...res,
              ["rAndS"]: rolesSorted[i]._id,
              ["curType"]: "Roles",
            };
            cardData = rolesSorted[i];
            break;
          }
        }
      }
      if (res && res.rAndS === null) {
        if (peopleSorted && peopleSorted.length > 0) {
          for (let i = 0; i < peopleSorted.length; i++) {
            if (peopleSorted[i]._id === data.lead) {
              res = {
                ...res,
                ["people"]: peopleSorted[i]._id,
                ["curType"]: "People",
              };
              break;
            }
          }
        }
      } else if (
        res &&
        res.rAndS &&
        res.rAndS.length > 0 &&
        cardData &&
        Object.keys(cardData).length > 0
      ) {
        if (
          (cardData.ownerRole === "Single owner" ||
            cardData.ownerType === "Single owner") &&
          cardData.owners &&
          cardData.owners.length > 0
        ) {
          if (peopleSorted && peopleSorted.length > 0) {
            for (let i = 0; i < peopleSorted.length; i++) {
              if (peopleSorted[i]._id === cardData.owners[0]) {
                res = { ...res, ["people"]: peopleSorted[i]._id };
                break;
              }
            }
          }
        }
      }
    }

    //People
    let CcorfoPeople = [];
    if (curCoresMembers && curCoresMembers.People.length > 0) {
      for (let i = 0; i < peopleSorted.length; i++) {
        let inc = curCoresMembers.People.includes(peopleSorted[i]._id);
        if (inc) {
          CcorfoPeople.push(peopleSorted[i]);
        }
      }
    }

    if (
      res &&
      Object.keys(res).length > 0 &&
      res.people &&
      res.people.length > 0
    ) {
      let flag = true;
      if (CcorfoPeople && CcorfoPeople.length > 0) {
        for (let i = 0; i < CcorfoPeople.length; i++) {
          if (CcorfoPeople[i]._id === res.people) {
            flag = false;
            break;
          }
        }
      }
      if (flag) {
        if (peopleSorted && peopleSorted.length > 0) {
          for (let i = 0; i < peopleSorted.length; i++) {
            if (peopleSorted[i]._id === res.people) {
              CcorfoPeople.push(peopleSorted[i]);
              break;
            }
          }
        }
      }
    }

    let CextfoPeople = [];
    if (curExtendedsMembers && curExtendedsMembers.People.length > 0) {
      for (let i = 0; i < peopleSorted.length; i++) {
        let inc = curExtendedsMembers.People.includes(peopleSorted[i]._id);
        if (inc) {
          CextfoPeople.push(peopleSorted[i]);
        }
      }
    }

    //Skills
    let CcorfoSkill = [];
    if (curCoresMembers && curCoresMembers.Skills.length > 0) {
      for (let i = 0; i < skillsSorted.length; i++) {
        let inc = curCoresMembers.Skills.includes(skillsSorted[i]._id);
        if (inc) {
          CcorfoSkill.push(skillsSorted[i]);
        }
      }
    }

    if (
      res &&
      Object.keys(res).length > 0 &&
      res.rAndS &&
      res.rAndS.length > 0 &&
      res.curType === "Skills"
    ) {
      let flag = true;
      if (CcorfoSkill && CcorfoSkill.length > 0) {
        for (let i = 0; i < CcorfoSkill.length; i++) {
          if (CcorfoSkill[i]._id === res.rAndS) {
            flag = false;
            break;
          }
        }
      }
      if (flag) {
        if (skillsSorted && skillsSorted.length > 0) {
          for (let i = 0; i < skillsSorted.length; i++) {
            if (skillsSorted[i]._id === res.rAndS) {
              CcorfoSkill.push(skillsSorted[i]);
              break;
            }
          }
        }
      }
    }

    let CextfoSkill = [];
    if (curExtendedsMembers && curExtendedsMembers.Skills.length > 0) {
      for (let i = 0; i < skillsSorted.length; i++) {
        let inc = curExtendedsMembers.Skills.includes(skillsSorted[i]._id);
        if (inc) {
          CextfoSkill.push(skillsSorted[i]);
        }
      }
    }

    //Roles
    let CcorfoRoles = [];
    if (curCoresMembers && curCoresMembers.Roles.length > 0) {
      for (let i = 0; i < rolesSorted.length; i++) {
        let inc = curCoresMembers.Roles.includes(rolesSorted[i]._id);
        if (inc) {
          CcorfoRoles.push(rolesSorted[i]);
        }
      }
    }

    if (
      res &&
      Object.keys(res).length > 0 &&
      res.rAndS &&
      res.rAndS.length > 0 &&
      res.curType === "Roles"
    ) {
      let flag = true;
      if (CcorfoRoles && CcorfoRoles.length > 0) {
        for (let i = 0; i < CcorfoRoles.length; i++) {
          if (CcorfoRoles[i]._id === res.rAndS) {
            flag = false;
            break;
          }
        }
      }
      if (flag) {
        if (rolesSorted && rolesSorted.length > 0) {
          for (let i = 0; i < rolesSorted.length; i++) {
            if (rolesSorted[i]._id === res.rAndS) {
              CcorfoRoles.push(rolesSorted[i]);
              break;
            }
          }
        }
      }
    }

    let CextfoRoles = [];
    if (curExtendedsMembers && curExtendedsMembers.Roles.length > 0) {
      for (let i = 0; i < rolesSorted.length; i++) {
        let inc = curExtendedsMembers.Roles.includes(rolesSorted[i]._id);
        if (inc) {
          CextfoRoles.push(rolesSorted[i]);
        }
      }
    }

    //Links
    let CcorfoLinks = [];
    if (curCoresMembers && curCoresMembers.Links.length > 0) {
      for (let i = 0; i < linksSorted.length; i++) {
        let inc = curCoresMembers.Links.includes(linksSorted[i]._id);
        if (inc) {
          CcorfoLinks.push(linksSorted[i]);
        }
      }
    }

    let CextfoLinks = [];
    if (curExtendedsMembers && curExtendedsMembers.Links.length > 0) {
      for (let i = 0; i < linksSorted.length; i++) {
        let inc = curExtendedsMembers.Links.includes(linksSorted[i]._id);
        if (inc) {
          CextfoLinks.push(linksSorted[i]);
        }
      }
    }

    //Domains
    let CcorfoDomains = [];
    if (curCoresMembers && curCoresMembers.Domains.length > 0) {
      for (let i = 0; i < domainsSorted.length; i++) {
        let inc = curCoresMembers.Domains.includes(domainsSorted[i]._id);
        if (inc) {
          CcorfoDomains.push(domainsSorted[i]);
        }
      }
    }

    let CextfoDomains = [];
    if (curExtendedsMembers && curExtendedsMembers.Domains.length > 0) {
      for (let i = 0; i < domainsSorted.length; i++) {
        let inc = curExtendedsMembers.Domains.includes(domainsSorted[i]._id);
        if (inc) {
          CextfoDomains.push(domainsSorted[i]);
        }
      }
    }

    //Projects
    let CcorfoProjects = [];
    if (curCoresMembers && curCoresMembers.Projects.length > 0) {
      for (let i = 0; i < projectsSorted.length; i++) {
        let inc = curCoresMembers.Projects.includes(projectsSorted[i]._id);
        if (inc) {
          CcorfoProjects.push(projectsSorted[i]);
        }
      }
    }

    let curData = {};
    if (
      CcorfoPeople.length > 0 ||
      CextfoPeople.length > 0 ||
      CcorfoSkill.length > 0 ||
      CextfoSkill.length > 0 ||
      CcorfoRoles.length > 0 ||
      CextfoRoles.length > 0 ||
      CcorfoLinks.length > 0 ||
      CextfoLinks.length > 0 ||
      CcorfoDomains.length > 0 ||
      CextfoDomains.length > 0 ||
      CcorfoProjects.length > 0
    ) {
      curData = {
        coreMembers: {
          People: CcorfoPeople,
          Skills: CcorfoSkill,
          Roles: CcorfoRoles,
          Links: CcorfoLinks,
          Domains: CcorfoDomains,
          Projects: CcorfoProjects,
        },
        extendedMembers: {
          People: CextfoPeople,
          Skills: CextfoSkill,
          Roles: CextfoRoles,
          Links: CextfoLinks,
          Domains: CextfoDomains,
        },
      };
    }
    return curData;
  };

  var curCModeData = [];

  if (focusMode.length > 0 && focusMode === "Circle") {
    let allCircles = [...circles, ...dCircles];
    // console.log(allCircles,members);
    for (let j = 0; j < allCircles.length; j++) {
      let cId = allCircles[j]._id;
      for (let i = 0; i < members.length; i++) {
        let curCoresMembers = {};
        let curExtendedsMembers = {};
        if (members[i].memberType === cId) {
          curCoresMembers = members[i].coreMembers;
          curExtendedsMembers = members[i].extendedMembers;
          let res = cMmembes(
            curCoresMembers,
            curExtendedsMembers,
            allCircles[j]
          );
          if (res) {
            curCModeData.push({ ...allCircles[j], ...res });
            break;
          }
        }
      }
      // console.log({curCModeData});
      let allCircleWithMember = curCModeData.map((sc) => sc._id);
      // console.log(allCircleWithMember,cId,allCircleWithMember.includes(cId));
      if (!allCircleWithMember.includes(cId)) {
        let curData = {
          coreMembers: {
            People: [],
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
            Projects: [],
          },
          extendedMembers: {
            People: [],
            Skills: [],
            Roles: [],
            Links: [],
            Domains: [],
          },
        };
        curCModeData.push({ ...allCircles[j], ...curData });
      }
    }
    // console.log({curCModeData});

    // if (dCircles && dCircles.length > 0) {
    //   for (let i = 0; i < dCircles.length; i++) {
    //     let curData = {
    //       coreMembers: {
    //         People: [],
    //         Skills: [],
    //         Roles: [],
    //         Links: [],
    //         Domains: [],
    //         Projects: [],
    //       },
    //       extendedMembers: {
    //         People: [],
    //         Skills: [],
    //         Roles: [],
    //         Links: [],
    //         Domains: [],
    //       },
    //     };
    //     curCModeData.push({ ...dCircles[i], ...curData });
    //     console.log(curCModeData);
    //   }
    // }
  }

  //People As Action
  var peopleAsCData = [...curCModeData];

  if (
    focusMode.length > 0 &&
    curCModeData &&
    curCModeData.length > 0 &&
    focusMode === "Circle" &&
    isModeSwitched
  ) {
    for (let i = 0; i < curCModeData.length; i++) {
      let totalPeopleCircleData = [];
      // Core
      if (
        curCModeData[i].coreMembers &&
        curCModeData[i].coreMembers.People &&
        curCModeData[i].coreMembers.People.length > 0
      ) {
        let curCirclePeople = [];
        for (let j = 0; j < curCModeData[i].coreMembers.People.length; j++) {
          for (let l = 0; l < peopleSorted.length; l++) {
            if (
              curCModeData[i].coreMembers.People[j]._id === peopleSorted[l]._id
            ) {
              curCirclePeople.push({
                ...peopleSorted[l],
                field: `${curCModeData[i].coreMembers.People[j].fname} ${curCModeData[i].coreMembers.People[j].lname} (Person)`,
              });
              // totalPeopleCircleData.push({...peopleSorted[l], field: `${curCModeData[i].coreMembers.People[j].fname} ${curCModeData[i].coreMembers.People[j].lname} (Person)` });

              /* totalPeopleCircleData.push({
                ...peopleSorted[l],
                field: {
                  name: `${curCModeData[i].coreMembers.People[j].fname} ${curCModeData[i].coreMembers.People[j].lname} (Person)`,
                  obj: curCModeData[i].coreMembers.People[j],
                  cardType: "People",
                  Id: curCModeData[i].coreMembers.People[j]._id,
                },
              }); */
            }
          }
        }
        if (curCirclePeople.length > 0) {
          peopleAsCData[i].coreMembers.People = curCirclePeople;
          // peopleAsCData.push(curCircleSkill)
        }
      }

      if (
        curCModeData[i].coreMembers &&
        curCModeData[i].coreMembers.Skills &&
        curCModeData[i].coreMembers.Skills.length > 0
      ) {
        let curCircleSkill = [];
        for (let j = 0; j < curCModeData[i].coreMembers.Skills.length; j++) {
          let currentCirclePfmember = pfmembers.find(
            (pfm) => pfm.memberType === curCModeData[i]._id
          );
          // console.log('Circle name ', curCModeData[i].circleName);

          if (curCModeData[i].coreMembers.Skills[j].owners.length > 0) {
            for (let l = 0; l < peopleSorted.length; l++) {
              let inc = curCModeData[i].coreMembers.Skills[j].owners.includes(
                peopleSorted[l]._id
              );

              /* if (inc) {
                console.log(curCModeData[i].coreMembers.Skills[j].skillName + ' has ' + peopleSorted[l].fname + ' as owners.')
                curCircleSkill.push({
                  ...peopleSorted[l],
                  field: `${curCModeData[i].coreMembers.Skills[j].skillName} (Skill)`,
                });
                // totalPeopleCircleData.push({...peopleSorted[l], field: `${curCModeData[i].coreMembers.Skills[j].skillName} (Skill)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[l],
                  field: {
                    name: `${curCModeData[i].coreMembers.Skills[j].skillName} (Skill)`,
                    obj: curCModeData[i].coreMembers.Skills[j],
                    cardType: "Skills",
                    Id: curCModeData[i].coreMembers.Skills[j]._id,
                  },
                });
              } */

              /* Respect allOwnersFlag */
              if (currentCirclePfmember) {
                // console.log('currentCirclePfmember', currentCirclePfmember);
                let incpf = currentCirclePfmember.coreMembers.Skills.some(
                  (item) =>
                    item &&
                    item.peopleIds.toString() === peopleSorted[l]._id.toString()
                );
                if (inc && incpf) {
                  // console.log(currentCirclePfmember.coreMembers.Skills, ' has ' + peopleSorted[l].fname);
                  // console.log(curCModeData[i].coreMembers.Skills[j].skillName + ' has ' + peopleSorted[l].fname + ' as owners.')
                  curCircleSkill.push({
                    ...peopleSorted[l],
                    field: `${curCModeData[i].coreMembers.Skills[j].skillName} (Skill)`,
                  });
                  // totalPeopleCircleData.push({...peopleSorted[l], field: `${curCModeData[i].coreMembers.Skills[j].skillName} (Skill)` });

                  totalPeopleCircleData.push({
                    ...peopleSorted[l],
                    field: {
                      name: `${curCModeData[i].coreMembers.Skills[j].skillName} (Skill)`,
                      obj: curCModeData[i].coreMembers.Skills[j],
                      cardType: "Skills",
                      Id: curCModeData[i].coreMembers.Skills[j]._id,
                    },
                  });
                }
              }
              /* END */
            }
          }
        }
        if (curCircleSkill.length > 0) {
          // console.log('curCircleSkill', curCircleSkill)
          // console.log("before change skill", peopleAsCData[i].coreMembers.Skills);
          peopleAsCData[i].coreMembers.Skills = curCircleSkill;
          // peopleAsCData.push(curCircleSkill)
          // console.log("after change skill", peopleAsCData[i].coreMembers.Skills);
        }
      }

      if (
        curCModeData[i].coreMembers &&
        curCModeData[i].coreMembers.Roles &&
        curCModeData[i].coreMembers.Roles.length > 0
      ) {
        let curCircleRole = [];
        // console.log({curCircleRole,length: curCircleRole.length});
        // console.log('curCModeData['+i+'].coreMembers.Roles', curCModeData[i].coreMembers.Roles);
        // console.log("curCModeData[0].coreMembers.Roles[1]---",curCModeData[0].coreMembers.Roles[1]);
        for (let j = 0; j < curCModeData[i].coreMembers.Roles.length; j++) {
          // console.log(curCModeData[i].coreMembers.Roles[j])
          // console.log('curCModeData['+i+'].coreMembers.Roles['+j+']', curCModeData[i].coreMembers.Roles[j].roleName)
          let currentCirclePfmember = pfmembers.find(
            (pfm) => pfm.memberType === curCModeData[i]._id
          );
          // console.log('Circle name: ', curCModeData[i].circleName, 'Role name: ', curCModeData[i].coreMembers.Roles[j].roleName);
          if (
            curCModeData[i].coreMembers.Roles[j].owners &&
            curCModeData[i].coreMembers.Roles[j].owners.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // console.log("k-----",k,{curCircleRole,length: curCircleRole.length});
              let inc = curCModeData[i].coreMembers.Roles[j].owners.includes(
                peopleSorted[k]._id
              );

              // console.log({curCircleRole,length: curCircleRole.length});
              // console.log(curCModeData[i].coreMembers.Roles[j], peopleSorted[k]._id);
              /* if (inc) {
                curCircleRole.push({
                  ...peopleSorted[k],
                  field: `${curCModeData[i].coreMembers.Roles[j].roleName} (Role)`,
                });
                // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].coreMembers.Roles[j].roleName} (Role)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curCModeData[i].coreMembers.Roles[j].roleName} (Role)`,
                    obj: curCModeData[i].coreMembers.Roles[j],
                    cardType: "Roles",
                    Id: curCModeData[i].coreMembers.Roles[j]._id,
                  },
                });
              } */

              /* Respect allOwnersFlag */
              if (currentCirclePfmember) {
                let incpf = currentCirclePfmember.coreMembers.Roles.some(
                  (item) =>
                    item &&
                    item.peopleIds.toString() === peopleSorted[k]._id.toString()
                );
                // console.log(currentCirclePfmember.coreMembers.Roles, ' has ' + peopleSorted[k].fname);
                // console.log(curCModeData[i].coreMembers.Roles[j].roleName + ' has ' + peopleSorted[k].fname + ' as owners.')
                // console.log(inc, incpf)
                // console.log({curCircleRole,length: curCircleRole.length});

                if (inc && incpf) {
                  // console.log("inside inc && incpf",{curCircleRole,length: curCircleRole.length});
                  curCircleRole.push({
                    ...peopleSorted[k],
                    field: `${curCModeData[i].coreMembers.Roles[j].roleName} (Role)`,
                  });
                  // console.log({curCircleRole,length: curCircleRole.length});

                  // totalPeopleCircleData.push({ ...peopleSorted[k], field: `${curCModeData[i].coreMembers.Roles[j].roleName} (Role)` });

                  totalPeopleCircleData.push({
                    ...peopleSorted[k],
                    field: {
                      name: `${curCModeData[i].coreMembers.Roles[j].roleName} (Role)`,
                      obj: curCModeData[i].coreMembers.Roles[j],
                      cardType: "Roles",
                      Id: curCModeData[i].coreMembers.Roles[j]._id,
                    },
                  });
                }
              }
              /* END */
            }
          }

          // if (curCircleRole.length > 0) {
          //   console.log("inside if late")
          //   console.log("curCircleRole", curCircleRole);
          //   curCircleRole.forEach((element, index) => {
          //       console.log(`Element at index ${index}:`, element);
          //   });
          //   // console.log("curCircleRole", JSON.stringify(curCircleRole, null, 2));

          //   // console.log("before change role", peopleAsCData[i].coreMembers.Roles);
          //   peopleAsCData[i].coreMembers.Roles = curCircleRole;
          //   // console.log("after change role",peopleAsCData[i].coreMembers.Roles);
          // }
        }
      }

      if (
        curCModeData[i].coreMembers &&
        curCModeData[i].coreMembers.Domains &&
        curCModeData[i].coreMembers.Domains.length > 0
      ) {
        let curCircleDomain = [];
        for (let j = 0; j < curCModeData[i].coreMembers.Domains.length; j++) {
          if (
            curCModeData[i].coreMembers.Domains[j].owners.owner &&
            curCModeData[i].coreMembers.Domains[j].owners.owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // let inc = curCModeData[i].coreMembers.Domains[j].owners.owner.includes(peopleSorted[k]._id)
              if (
                curCModeData[i].coreMembers.Domains[j].owners.owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleDomain.push({
                  ...peopleSorted[k],
                  field: `${curCModeData[i].coreMembers.Domains[j].domainName} (Domain)`,
                });
                // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].coreMembers.Domains[j].domainName} (Domain)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curCModeData[i].coreMembers.Domains[j].domainName} (Domain)`,
                    obj: curCModeData[i].coreMembers.Domains[j],
                    cardType: "Domains",
                    Id: curCModeData[i].coreMembers.Domains[j]._id,
                  },
                });
              }
            }
          }
          if (curCircleDomain.length > 0) {
            peopleAsCData[i].coreMembers.Domains = curCircleDomain;
          }
        }
      }

      if (
        curCModeData[i].coreMembers &&
        curCModeData[i].coreMembers.Links &&
        curCModeData[i].coreMembers.Links.length > 0
      ) {
        let curCircleLink = [];
        for (let j = 0; j < curCModeData[i].coreMembers.Links.length; j++) {
          if (
            curCModeData[i].coreMembers.Links[j].owner &&
            curCModeData[i].coreMembers.Links[j].owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // let inc = curCModeData[i].coreMembers.Links[j].owner.includes(peopleSorted[k]._id)
              if (
                curCModeData[i].coreMembers.Links[j].owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleLink.push({
                  ...peopleSorted[k],
                  field: `${curCModeData[i].coreMembers.Links[j].linkName} (Link)`,
                });
                // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].coreMembers.Links[j].linkName} (Link)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curCModeData[i].coreMembers.Links[j].linkName} (Link)`,
                    obj: curCModeData[i].coreMembers.Links[j],
                    cardType: "Links",
                    Id: curCModeData[i].coreMembers.Links[j]._id,
                  },
                });
              }
            }
          }
          if (curCircleLink.length > 0) {
            peopleAsCData[i].coreMembers.Links = curCircleLink;
          }
        }
      }

      if (
        curCModeData[i].coreMembers &&
        curCModeData[i].coreMembers.Projects &&
        curCModeData[i].coreMembers.Projects.length > 0
      ) {
        let curCircleProject = [];
        for (let j = 0; j < curCModeData[i].coreMembers.Projects.length; j++) {
          if (
            curCModeData[i].coreMembers.Projects[j].lead &&
            curCModeData[i].coreMembers.Projects[j].lead.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              let inc = curCModeData[i].coreMembers.Projects[j].lead.includes(
                peopleSorted[k]._id
              );
              if (inc) {
                curCircleProject.push({
                  ...peopleSorted[k],
                  field: `${curCModeData[i].coreMembers.Projects[j].projectName} (Project)`,
                });
                // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].coreMembers.Projects[j].projectName} (Project)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curCModeData[i].coreMembers.Projects[j].projectName} (Project)`,
                    obj: curCModeData[i].coreMembers.Projects[j],
                    cardType: "Projects",
                    Id: curCModeData[i].coreMembers.Projects[j]._id,
                  },
                });
              }
            }
          }
          if (curCircleProject.length > 0) {
            peopleAsCData[i].coreMembers.Projects = curCircleProject;
          }
        }
      }

      //External
      if (
        curCModeData[i].extendedMembers &&
        curCModeData[i].extendedMembers.People &&
        curCModeData[i].extendedMembers.People.length > 0
      ) {
        let curCirclePeople = [];
        for (
          let j = 0;
          j < curCModeData[i].extendedMembers.People.length;
          j++
        ) {
          for (let l = 0; l < peopleSorted.length; l++) {
            if (
              curCModeData[i].extendedMembers.People[j]._id ===
              peopleSorted[l]._id
            ) {
              curCirclePeople.push({
                ...peopleSorted[l],
                field: `${curCModeData[i].extendedMembers.People[j].fname} ${curCModeData[i].extendedMembers.People[j].lname} (Person)`,
              });
              // totalPeopleCircleData.push({...peopleSorted[l], field: `${curCModeData[i].extendedMembers.People[j].fname} ${curCModeData[i].extendedMembers.People[j].lname} (Person)` });

              totalPeopleCircleData.push({
                ...peopleSorted[l],
                field: {
                  name: `${curCModeData[i].extendedMembers.People[j].fname} ${curCModeData[i].extendedMembers.People[j].lname} (Person)`,
                  obj: curCModeData[i].extendedMembers.People[j],
                  cardType: "People",
                  Id: curCModeData[i].extendedMembers.People[j]._id,
                },
              });
            }
          }
        }
        if (curCirclePeople.length > 0) {
          peopleAsCData[i].extendedMembers.People = curCirclePeople;
          // peopleAsCData.push(curCircleSkill)
        }
      }

      if (
        curCModeData[i].extendedMembers &&
        curCModeData[i].extendedMembers.Skills &&
        curCModeData[i].extendedMembers.Skills.length > 0
      ) {
        let curCircleSkill = [];
        for (
          let j = 0;
          j < curCModeData[i].extendedMembers.Skills.length;
          j++
        ) {
          let currentCirclePfmember = pfmembers.find(
            (pfm) => pfm.memberType === curCModeData[i]._id
          );
          // console.log('currentCirclePfmember', currentCirclePfmember);
          if (curCModeData[i].extendedMembers.Skills[j].owners.length > 0) {
            for (let l = 0; l < peopleSorted.length; l++) {
              let inc = curCModeData[i].extendedMembers.Skills[
                j
              ].owners.includes(peopleSorted[l]._id);

              /* if (inc) {
                curCircleSkill.push({
                  ...peopleSorted[l],
                  field: `${curCModeData[i].extendedMembers.Skills[j].skillName} (Skill)`,
                });
                // totalPeopleCircleData.push({...peopleSorted[l], field: `${curCModeData[i].extendedMembers.Skills[j].skillName} (Skill)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[l],
                  field: {
                    name: `${curCModeData[i].extendedMembers.Skills[j].skillName} (Skill)`,
                    obj: curCModeData[i].extendedMembers.Skills[j],
                    cardType: "Skills",
                    Id: curCModeData[i].extendedMembers.Skills[j]._id,
                  },
                });
              } */

              /* Respect allOwnersFlag */
              if (currentCirclePfmember) {
                let incpf = currentCirclePfmember.extendedMembers.Skills.some(
                  (item) =>
                    item &&
                    item.peopleIds.toString() === peopleSorted[l]._id.toString()
                );
                if (inc && incpf) {
                  curCircleSkill.push({
                    ...peopleSorted[l],
                    field: `${curCModeData[i].extendedMembers.Skills[j].skillName} (Skill)`,
                  });
                  // totalPeopleCircleData.push({...peopleSorted[l], field: `${curCModeData[i].extendedMembers.Skills[j].skillName} (Skill)` });

                  totalPeopleCircleData.push({
                    ...peopleSorted[l],
                    field: {
                      name: `${curCModeData[i].extendedMembers.Skills[j].skillName} (Skill)`,
                      obj: curCModeData[i].extendedMembers.Skills[j],
                      cardType: "Skills",
                      Id: curCModeData[i].extendedMembers.Skills[j]._id,
                    },
                  });
                }
              }
              /* END */
            }
          }
        }
        peopleAsCData[i].extendedMembers.Skills = curCircleSkill;
        // peopleAsCData.push(curCircleSkill)
      }

      if (
        curCModeData[i].extendedMembers &&
        curCModeData[i].extendedMembers.Roles &&
        curCModeData[i].extendedMembers.Roles.length > 0
      ) {
        let curCircleRole = [];
        for (let j = 0; j < curCModeData[i].extendedMembers.Roles.length; j++) {
          let currentCirclePfmember = pfmembers.find(
            (pfm) => pfm.memberType === curCModeData[i]._id
          );
          if (
            curCModeData[i].extendedMembers.Roles[j].owners &&
            curCModeData[i].extendedMembers.Roles[j].owners.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              let inc = curCModeData[i].extendedMembers.Roles[
                j
              ].owners.includes(peopleSorted[k]._id);
              /* if (inc) {
                curCircleRole.push({
                  ...peopleSorted[k],
                  field: `${curCModeData[i].extendedMembers.Roles[j].roleName} (Role)`,
                });
                // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].extendedMembers.Roles[j].roleName} (Role)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curCModeData[i].extendedMembers.Roles[j].roleName} (Role)`,
                    obj: curCModeData[i].extendedMembers.Roles[j].roleName,
                    cardType: "Roles",
                    Id: curCModeData[i].extendedMembers.Roles[j]._id,
                  },
                });
              } */

              /* Respect allOwnersFlag */
              if (currentCirclePfmember) {
                let incpf = currentCirclePfmember.extendedMembers.Roles.some(
                  (item) =>
                    item &&
                    item.peopleIds.toString() === peopleSorted[k]._id.toString()
                );
                if (inc && incpf) {
                  curCircleRole.push({
                    ...peopleSorted[k],
                    field: `${curCModeData[i].extendedMembers.Roles[j].roleName} (Role)`,
                  });
                  // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].extendedMembers.Roles[j].roleName} (Role)` });

                  totalPeopleCircleData.push({
                    ...peopleSorted[k],
                    field: {
                      name: `${curCModeData[i].extendedMembers.Roles[j].roleName} (Role)`,
                      obj: curCModeData[i].extendedMembers.Roles[j].roleName,
                      cardType: "Roles",
                      Id: curCModeData[i].extendedMembers.Roles[j]._id,
                    },
                  });
                }
              }
              /* END */
            }
          }
          peopleAsCData[i].extendedMembers.Roles = curCircleRole;
        }
      }

      if (
        curCModeData[i].extendedMembers &&
        curCModeData[i].extendedMembers.Domains &&
        curCModeData[i].extendedMembers.Domains.length > 0
      ) {
        let curCircleDomain = [];
        for (
          let j = 0;
          j < curCModeData[i].extendedMembers.Domains.length;
          j++
        ) {
          if (
            curCModeData[i].extendedMembers.Domains[j].owners.owner &&
            curCModeData[i].extendedMembers.Domains[j].owners.owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // let inc = curCModeData[i].extendedMembers.Domains[j].owners.owner.includes(peopleSorted[k]._id)
              if (
                curCModeData[i].extendedMembers.Domains[j].owners.owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleDomain.push({
                  ...peopleSorted[k],
                  field: `${curCModeData[i].extendedMembers.Domains[j].domainName} (Domain)`,
                });
                // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].extendedMembers.Domains[j].domainName} (Domain)` });

                totalPeopleCircleData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curCModeData[i].extendedMembers.Domains[j].domainName} (Domain)`,
                    obj: curCModeData[i].extendedMembers.Domains[j],
                    cardType: "Domains",
                    Id: curCModeData[i].extendedMembers.Domains[j]._id,
                  },
                });
              }
            }
          }
          peopleAsCData[i].extendedMembers.Domains = curCircleDomain;
        }
      }

      if (
        curCModeData[i].extendedMembers &&
        curCModeData[i].extendedMembers.Links &&
        curCModeData[i].extendedMembers.Links.length > 0
      ) {
        let curCircleLink = [];
        for (let j = 0; j < curCModeData[i].extendedMembers.Links.length; j++) {
          if (
            curCModeData[i].extendedMembers.Links[j].owner &&
            curCModeData[i].extendedMembers.Links[j].owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              if (
                curCModeData[i].extendedMembers.Links[j].owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleLink.push({
                  ...peopleSorted[k],
                  field: `${curCModeData[i].extendedMembers.Links[j].linkName} (Link)`,
                });

                // totalPeopleCircleData.push({...peopleSorted[k], field: `${curCModeData[i].extendedMembers.Links[j].linkName} (Link)` } });

                totalPeopleCircleData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curCModeData[i].extendedMembers.Links[j].linkName} (Link)`,
                    obj: curCModeData[i].extendedMembers.Links[j],
                    cardType: "Links",
                    Id: curCModeData[i].extendedMembers.Links[j]._id,
                  },
                });
              }
            }
          }
          peopleAsCData[i].extendedMembers.Links = curCircleLink;
        }
      }

      let peopleAsData = [];

      if (totalPeopleCircleData.length > 0) {
        for (let i = 0; i < totalPeopleCircleData.length; i++) {
          peopleAsData.push(totalPeopleCircleData[i]._id);
        }
      }

      let peopleAsDataSet = [...new Set([...peopleAsData])];

      let PeopleAsFilterData = [];

      if (totalPeopleCircleData.length > 0 && peopleAsDataSet.length > 0) {
        for (let i = 0; i < peopleAsDataSet.length; i++) {
          let inc = [];
          for (let j = 0; j < totalPeopleCircleData.length; j++) {
            if (totalPeopleCircleData[j]._id === peopleAsDataSet[i]) {
              inc.push(totalPeopleCircleData[j].field);
            }
          }
          if (inc.length > 0) {
            PeopleAsFilterData.push({ id: peopleAsDataSet[i], FildName: inc });
          }
        }
      }

      let PeopleAsAllFilterData = [];

      if (PeopleAsFilterData.length > 0) {
        for (let k = 0; k < PeopleAsFilterData.length; k++) {
          for (let l = 0; l < peopleSorted.length; l++) {
            if (
              PeopleAsFilterData[k].id.toString() ===
              peopleSorted[l]._id.toString()
            ) {
              PeopleAsAllFilterData.push({
                ...peopleSorted[l],
                FildName: PeopleAsFilterData[k].FildName,
              });
            }
          }
        }
      }
      peopleAsCData[i].pepoleData = PeopleAsAllFilterData;
    }
  }

  let curCModeDatas = [...curCModeData];
  if (focusMode === "Circle" && !isModeSwitched) {
    let curCMode = [];
    curCModeDatas = curCModeData.filter((e) => {
      return !fList[e.circleName];
    });
    // console.log(26862,{curCModeDatas});
    // console.log(26863,{focusList});
    if (focusList && focusList.length > 0) {
      for (let i = 0; i < focusList.length; i++) {
        for (let j = 0; j < curCModeDatas.length; j++) {
          if (focusList[i].id === curCModeDatas[j]._id) {
            curCMode.push(curCModeDatas[j]);
          }
        }
      }
    }
    if (curCMode.length > 0) {
      curCModeDatas = curCMode;
    }
  }

  const cmLeadVerify = (data) => {
    if (data && data.lead && data.lead.length > 0) {
      let res = { people: null, rAndS: null, curType: null };
      let cardData = {};
      if (
        res &&
        res.rAndS === null &&
        skillsSorted &&
        skillsSorted.length > 0
      ) {
        for (let i = 0; i < skillsSorted.length; i++) {
          if (skillsSorted[i]._id === data.lead) {
            res = {
              ...res,
              ["rAndS"]: skillsSorted[i]._id,
              ["curType"]: "Skills",
            };
            cardData = skillsSorted[i];
            break;
          }
        }
      }
      if (res && res.rAndS === null && rolesSorted && rolesSorted.length > 0) {
        for (let i = 0; i < rolesSorted.length; i++) {
          if (rolesSorted[i]._id === data.lead) {
            res = {
              ...res,
              ["rAndS"]: rolesSorted[i]._id,
              ["curType"]: "Roles",
            };
            cardData = rolesSorted[i];
            break;
          }
        }
      }
      if (res && res.rAndS === null) {
        if (peopleSorted && peopleSorted.length > 0) {
          for (let i = 0; i < peopleSorted.length; i++) {
            if (peopleSorted[i]._id === data.lead) {
              res = {
                ...res,
                ["people"]: peopleSorted[i]._id,
                ["curType"]: "People",
              };
              break;
            }
          }
        }
      } else if (
        res &&
        res.rAndS &&
        res.rAndS.length > 0 &&
        cardData &&
        Object.keys(cardData).length > 0
      ) {
        if (
          (cardData.ownerRole === "Single owner" ||
            cardData.ownerType === "Single owner") &&
          cardData.owners &&
          cardData.owners.length > 0
        ) {
          if (peopleSorted && peopleSorted.length > 0) {
            for (let i = 0; i < peopleSorted.length; i++) {
              if (peopleSorted[i]._id === cardData.owners[0]) {
                res = { ...res, ["people"]: peopleSorted[i]._id };
                break;
              }
            }
          }
        }
      }
      return res;
    }
  };

  // rolesSorted skillsSorted
  const cmHandelFocused = (index) => {
    setFocusedMode("");
    let indexedCard = circlesSorted[index];
    handelFocused({ card: "Circles", index: index, id: indexedCard._id });
  };

  /*
   * Get the owner names to show in blue text pills 
   @parameters 
   # data: skill/ role card data 
   # cpid: circle/ project id
   # type: core/ extended
   */
  const getAllOwnerFCM = (data, cpId = false, type = false) => {
    let allData = [];
    if (
      data &&
      Object.keys(data).length > 0 &&
      data.owners &&
      data.owners.length > 0
    ) {
      let currentPfmembers = pfmembers.find((pfm) => pfm.memberType === cpId);
      if (currentPfmembers) {
        // If the skill/ role is in the allOwners flag array
        if (currentPfmembers.allOwnersFlag[type].includes(data._id)) {
          // console.log('all owners');
          /* if (peopleSorted && peopleSorted.length > 0) {
            for (let i = 0; i < data.owners.length; i++) {
              for (let j = 0; j < peopleSorted.length; j++) {
                if (data.owners[i] === peopleSorted[j]._id) {
                  allData.push(peopleSorted[j])
                  break;
                }
              }
            }
          } */
        }
        // If the skill/ role is not in the allOwners flag array
        else {
          // console.log('not all owners');
          for (let i = 0; i < data.owners.length; i++) {
            for (let j = 0; j < peopleSorted.length; j++) {
              if (type === "core") {
                let item = currentPfmembers.coreMembers.Skills.filter(
                  (sk) =>
                    sk.CardId === data._id &&
                    sk.peopleIds === peopleSorted[j]._id
                );
                if (
                  item &&
                  item.length > 0 &&
                  !allData.includes(peopleSorted[j])
                ) {
                  allData.push(peopleSorted[j]);
                  break;
                }

                item =
                  item.length === 0
                    ? currentPfmembers.coreMembers.Roles.filter(
                        (rk) =>
                          rk.CardId === data._id &&
                          rk.peopleIds === peopleSorted[j]._id
                      )
                    : [];
                if (
                  item &&
                  item.length > 0 &&
                  !allData.includes(peopleSorted[j])
                ) {
                  allData.push(peopleSorted[j]);
                  break;
                }
              }

              if (type === "extended") {
                let item = currentPfmembers.extendedMembers.Skills.filter(
                  (sk) =>
                    sk.CardId === data._id &&
                    sk.peopleIds === peopleSorted[j]._id
                );
                if (
                  item &&
                  item.length > 0 &&
                  !allData.includes(peopleSorted[j])
                ) {
                  allData.push(peopleSorted[j]);
                  break;
                }

                item =
                  item.length === 0
                    ? currentPfmembers.extendedMembers.Roles.filter(
                        (rk) =>
                          rk.CardId === data._id &&
                          rk.peopleIds === peopleSorted[j]._id
                      )
                    : [];
                if (
                  item &&
                  item.length > 0 &&
                  !allData.includes(peopleSorted[j])
                ) {
                  allData.push(peopleSorted[j]);
                  break;
                }
              }
            }
          }
        }
      }
    }

    return allData;
  };

  const getOwnerTypeFCM = (data) => {
    if (
      data &&
      Object.keys(data).length > 0 &&
      data.owners &&
      data.owners.type
    ) {
      let curdata = { type: null, cardId: null, name: null };
      if (skillsSorted && skillsSorted.length > 0) {
        for (let i = 0; i < skillsSorted.length; i++) {
          if (skillsSorted[i]._id === data.owners.type) {
            curdata = {
              ...curdata,
              ["type"]: "Skill",
              ["cardId"]: data.owners.type,
              ["name"]: `${skillsSorted[i].skillName}`,
            };
          }
        }
      }
      if (curdata.type === null && rolesSorted && rolesSorted.length > 0) {
        for (let i = 0; i < rolesSorted.length; i++) {
          if (rolesSorted[i]._id === data.owners.type) {
            curdata = {
              ...curdata,
              ["type"]: "Role",
              ["cardId"]: data.owners.type,
              ["name"]: `${rolesSorted[i].roleName}`,
            };
          }
        }
      }
      return curdata;
    }
  };

  const getAllPeopleFD = () => {
    if (
      focusMode.length > 0 &&
      focusMode === "Circle" &&
      peopleSorted &&
      peopleSorted.length > 0
    ) {
      return peopleSorted;
    }
  };

  /* '''''''''''''''''''''''''''''''''''''''' Circle Mode XLS And JPEG Downlod ''''''''''''''''''''''''''''''''''''''''''''''''' */
  let xlsCMData = [];
  let xlsCMList = [];

  if (focusMode.length > 0 && focusMode === "Circle" && !isModeSwitched) {
    let obj = {};
    for (let i = 0; i < curCModeData.length; i++) {
      let curCircleData = [];

      if (curCModeData[i].coreMembers) {
        if (
          curCModeData[i].coreMembers.People &&
          curCModeData[i].coreMembers.People.length > 0
        ) {
          for (let j = 0; j < curCModeData[i].coreMembers.People.length; j++) {
            curCircleData.push(
              `${curCModeData[i].coreMembers.People[j].fname} ${curCModeData[i].coreMembers.People[j].lname}`
            );
          }
        }
        if (
          curCModeData[i].coreMembers.Skills &&
          curCModeData[i].coreMembers.Skills.length > 0
        ) {
          for (let j = 0; j < curCModeData[i].coreMembers.Skills.length; j++) {
            curCircleData.push(
              `${curCModeData[i].coreMembers.Skills[j].skillName}`
            );
          }
        }
        if (
          curCModeData[i].coreMembers.Roles &&
          curCModeData[i].coreMembers.Roles.length > 0
        ) {
          for (let j = 0; j < curCModeData[i].coreMembers.Roles.length; j++) {
            curCircleData.push(
              `${curCModeData[i].coreMembers.Roles[j].roleName}`
            );
          }
        }
        if (
          curCModeData[i].coreMembers.Links &&
          curCModeData[i].coreMembers.Links.length > 0
        ) {
          for (let j = 0; j < curCModeData[i].coreMembers.Links.length; j++) {
            curCircleData.push(
              `${curCModeData[i].coreMembers.Links[j].linkName}`
            );
          }
        }
        if (
          curCModeData[i].coreMembers.Domains &&
          curCModeData[i].coreMembers.Domains.length > 0
        ) {
          for (let j = 0; j < curCModeData[i].coreMembers.Domains.length; j++) {
            curCircleData.push(
              `${curCModeData[i].coreMembers.Domains[j].domainName}`
            );
          }
        }
        if (
          curCModeData[i].coreMembers.Projects &&
          curCModeData[i].coreMembers.Projects.length > 0
        ) {
          for (
            let j = 0;
            j < curCModeData[i].coreMembers.Projects.length;
            j++
          ) {
            curCircleData.push(
              `${curCModeData[i].coreMembers.Projects[j].projectName}`
            );
          }
        }
      }
      if (curCModeData[i].extendedMembers) {
        if (
          curCModeData[i].extendedMembers.People &&
          curCModeData[i].extendedMembers.People.length > 0
        ) {
          for (
            let j = 0;
            j < curCModeData[i].extendedMembers.People.length;
            j++
          ) {
            curCircleData.push(
              `${curCModeData[i].extendedMembers.People[j].fname} ${curCModeData[i].extendedMembers.People[j].lname}`
            );
          }
        }
        if (
          curCModeData[i].extendedMembers.Skills &&
          curCModeData[i].extendedMembers.Skills.length > 0
        ) {
          for (
            let j = 0;
            j < curCModeData[i].extendedMembers.Skills.length;
            j++
          ) {
            curCircleData.push(
              `${curCModeData[i].extendedMembers.Skills[j].skillName}`
            );
          }
        }
        if (
          curCModeData[i].extendedMembers.Roles &&
          curCModeData[i].extendedMembers.Roles.length > 0
        ) {
          for (
            let j = 0;
            j < curCModeData[i].extendedMembers.Roles.length;
            j++
          ) {
            curCircleData.push(
              `${curCModeData[i].extendedMembers.Roles[j].roleName}`
            );
          }
        }
        if (
          curCModeData[i].extendedMembers.Links &&
          curCModeData[i].extendedMembers.Links.length > 0
        ) {
          for (
            let j = 0;
            j < curCModeData[i].extendedMembers.Links.length;
            j++
          ) {
            curCircleData.push(
              `${curCModeData[i].extendedMembers.Links[j].linkName}`
            );
          }
        }
        if (
          curCModeData[i].extendedMembers.Domains &&
          curCModeData[i].extendedMembers.Domains.length > 0
        ) {
          for (
            let j = 0;
            j < curCModeData[i].extendedMembers.Domains.length;
            j++
          ) {
            curCircleData.push(
              `${curCModeData[i].extendedMembers.Domains[j].domainName}`
            );
          }
        }
      }
      if (
        curCircleData &&
        curCircleData.length > 0 &&
        curCModeData[i] &&
        curCModeData[i].circleName &&
        curCModeData[i].circleName.length > 0
      ) {
        xlsCMList.push(curCModeData[i].circleName);
        obj[i] = curCircleData;
      }
    }
    // console.log(27276, curCModeData);

    if (Object.keys(obj).length > 0) {
      let arr = [];
      for (let l = 0; l < Object.keys(obj).length; l++) {
        if (obj[l] === "" || obj[l]) {
          arr.push(obj[l].length);
        }
      }
      if (arr && arr.length > 0) {
        const max = arr.reduce((a, b) => {
          return Math.max(a, b);
        });
        for (let k = 0; k < max; k++) {
          let xlsObj = {};
          for (let b = 0; b < Object.keys(obj).length; b++) {
            if (obj[b] === "" || obj[b]) {
              xlsObj[b] = obj[b][k] ? obj[b][k] : "";
            }
          }
          xlsCMData.push(xlsObj);
        }
      }
    }
  }

  useEffect(() => {
    if (
      focusMode &&
      focusMode.length > 0 &&
      focusMode === "Circle" &&
      !isModeSwitched
    ) {
      setXlsCM(xlsCMData);
      setXlsName(xlsCMList);
    }
  }, [focusMode, isModeSwitched]);

  // // ============================== Project mode actions ================================================
  const pMmembes = (curCoresMembers, curExtendedsMembers, data) => {
    let res = { people: null, rAndS: null, curType: null };
    let cardData = {};
    if (data && data.lead && data.lead.length > 0) {
      if (
        res &&
        res.rAndS === null &&
        skillsSorted &&
        skillsSorted.length > 0
      ) {
        for (let i = 0; i < skillsSorted.length; i++) {
          if (skillsSorted[i]._id === data.lead) {
            res = {
              ...res,
              ["rAndS"]: skillsSorted[i]._id,
              ["curType"]: "Skills",
            };
            cardData = skillsSorted[i];
            break;
          }
        }
      }
      if (res && res.rAndS === null && rolesSorted && rolesSorted.length > 0) {
        for (let i = 0; i < rolesSorted.length; i++) {
          if (rolesSorted[i]._id === data.lead) {
            res = {
              ...res,
              ["rAndS"]: rolesSorted[i]._id,
              ["curType"]: "Roles",
            };
            cardData = rolesSorted[i];
            break;
          }
        }
      }
      if (res && res.rAndS === null) {
        if (peopleSorted && peopleSorted.length > 0) {
          for (let i = 0; i < peopleSorted.length; i++) {
            if (peopleSorted[i]._id === data.lead) {
              res = {
                ...res,
                ["people"]: peopleSorted[i]._id,
                ["curType"]: "People",
              };
              break;
            }
          }
        }
      } else if (
        res &&
        res.rAndS &&
        res.rAndS.length > 0 &&
        cardData &&
        Object.keys(cardData).length > 0
      ) {
        if (
          (cardData.ownerRole === "Single owner" ||
            cardData.ownerType === "Single owner") &&
          cardData.owners &&
          cardData.owners.length > 0
        ) {
          if (peopleSorted && peopleSorted.length > 0) {
            for (let i = 0; i < peopleSorted.length; i++) {
              if (peopleSorted[i]._id === cardData.owners[0]) {
                res = { ...res, ["people"]: peopleSorted[i]._id };
                break;
              }
            }
          }
        }
      }
    }

    //People
    let CcorfoPeople = [];
    if (curCoresMembers && curCoresMembers.People.length > 0) {
      for (let i = 0; i < peopleSorted.length; i++) {
        let inc = curCoresMembers.People.includes(peopleSorted[i]._id);
        if (inc) {
          CcorfoPeople.push(peopleSorted[i]);
        }
      }
    }

    if (
      res &&
      Object.keys(res).length > 0 &&
      res.people &&
      res.people.length > 0
    ) {
      let flag = true;
      if (CcorfoPeople && CcorfoPeople.length > 0) {
        for (let i = 0; i < CcorfoPeople.length; i++) {
          if (CcorfoPeople[i]._id === res.people) {
            flag = false;
            break;
          }
        }
      }
      if (flag) {
        if (peopleSorted && peopleSorted.length > 0) {
          for (let i = 0; i < peopleSorted.length; i++) {
            if (peopleSorted[i]._id === res.people) {
              CcorfoPeople.push(peopleSorted[i]);
              break;
            }
          }
        }
      }
    }

    let CextfoPeople = [];
    if (curExtendedsMembers && curExtendedsMembers.People.length > 0) {
      for (let i = 0; i < peopleSorted.length; i++) {
        let inc = curExtendedsMembers.People.includes(peopleSorted[i]._id);
        if (inc) {
          CextfoPeople.push(peopleSorted[i]);
        }
      }
    }

    //Skills
    let CcorfoSkill = [];
    if (curCoresMembers && curCoresMembers.Skills.length > 0) {
      for (let i = 0; i < skillsSorted.length; i++) {
        let inc = curCoresMembers.Skills.includes(skillsSorted[i]._id);
        if (inc) {
          CcorfoSkill.push(skillsSorted[i]);
        }
      }
    }

    if (
      res &&
      Object.keys(res).length > 0 &&
      res.rAndS &&
      res.rAndS.length > 0 &&
      res.curType === "Skills"
    ) {
      let flag = true;
      if (CcorfoSkill && CcorfoSkill.length > 0) {
        for (let i = 0; i < CcorfoSkill.length; i++) {
          if (CcorfoSkill[i]._id === res.rAndS) {
            flag = false;
            break;
          }
        }
      }
      if (flag) {
        if (skillsSorted && skillsSorted.length > 0) {
          for (let i = 0; i < skillsSorted.length; i++) {
            // console.log(21183, skillsSorted[i]._id === res.rAndS)
            if (skillsSorted[i]._id === res.rAndS) {
              CcorfoSkill.push(skillsSorted[i]);
              break;
            }
          }
        }
      }
    }

    let CextfoSkill = [];
    if (curExtendedsMembers && curExtendedsMembers.Skills.length > 0) {
      for (let i = 0; i < skillsSorted.length; i++) {
        let inc = curExtendedsMembers.Skills.includes(skillsSorted[i]._id);
        if (inc) {
          CextfoSkill.push(skillsSorted[i]);
        }
      }
    }

    //Roles
    let CcorfoRoles = [];
    if (curCoresMembers && curCoresMembers.Roles.length > 0) {
      for (let i = 0; i < rolesSorted.length; i++) {
        let inc = curCoresMembers.Roles.includes(rolesSorted[i]._id);
        if (inc) {
          CcorfoRoles.push(rolesSorted[i]);
        }
      }
    }

    if (
      res &&
      Object.keys(res).length > 0 &&
      res.rAndS &&
      res.rAndS.length > 0 &&
      res.curType === "Roles"
    ) {
      let flag = true;
      if (CcorfoRoles && CcorfoRoles.length > 0) {
        for (let i = 0; i < CcorfoRoles.length; i++) {
          if (CcorfoRoles[i]._id === res.rAndS) {
            flag = false;
            break;
          }
        }
      }
      if (flag) {
        if (rolesSorted && rolesSorted.length > 0) {
          for (let i = 0; i < rolesSorted.length; i++) {
            if (rolesSorted[i]._id === res.rAndS) {
              CcorfoRoles.push(rolesSorted[i]);
              break;
            }
          }
        }
      }
    }

    let CextfoRoles = [];

    if (curExtendedsMembers && curExtendedsMembers.Roles.length > 0) {
      for (let i = 0; i < rolesSorted.length; i++) {
        let inc = curExtendedsMembers.Roles.includes(rolesSorted[i]._id);
        if (inc) {
          CextfoRoles.push(rolesSorted[i]);
        }
      }
    }

    //Links
    let CcorfoLinks = [];

    if (curCoresMembers && curCoresMembers.Links.length > 0) {
      for (let i = 0; i < linksSorted.length; i++) {
        let inc = curCoresMembers.Links.includes(linksSorted[i]._id);
        if (inc) {
          CcorfoLinks.push(linksSorted[i]);
        }
      }
    }

    let CextfoLinks = [];

    if (curExtendedsMembers && curExtendedsMembers.Links.length > 0) {
      for (let i = 0; i < linksSorted.length; i++) {
        let inc = curExtendedsMembers.Links.includes(linksSorted[i]._id);
        if (inc) {
          CextfoLinks.push(linksSorted[i]);
        }
      }
    }

    //Domains
    let CcorfoDomains = [];

    if (curCoresMembers && curCoresMembers.Domains.length > 0) {
      for (let i = 0; i < domainsSorted.length; i++) {
        let inc = curCoresMembers.Domains.includes(domainsSorted[i]._id);
        if (inc) {
          CcorfoDomains.push(domainsSorted[i]);
        }
      }
    }

    let CextfoDomains = [];

    if (curExtendedsMembers && curExtendedsMembers.Domains.length > 0) {
      for (let i = 0; i < domainsSorted.length; i++) {
        let inc = curExtendedsMembers.Domains.includes(domainsSorted[i]._id);
        if (inc) {
          CextfoDomains.push(domainsSorted[i]);
        }
      }
    }

    //Circles
    let PrcorfoCircles = [];

    if (curCoresMembers && curCoresMembers.Circles.length > 0) {
      for (let i = 0; i < circlesSorted.length; i++) {
        let inc = curCoresMembers.Circles.includes(circlesSorted[i]._id);
        if (inc) {
          PrcorfoCircles.push(circlesSorted[i]);
        }
      }
    }

    let curData = {};

    if (
      CcorfoPeople.length > 0 ||
      CextfoPeople.length > 0 ||
      CcorfoSkill.length > 0 ||
      CextfoSkill.length > 0 ||
      CcorfoRoles.length > 0 ||
      CextfoRoles.length > 0 ||
      CcorfoLinks.length > 0 ||
      CextfoLinks.length > 0 ||
      CcorfoDomains.length > 0 ||
      CextfoDomains.length > 0 ||
      PrcorfoCircles.length > 0
    ) {
      curData = {
        coreMembers: {
          People: CcorfoPeople,
          Skills: CcorfoSkill,
          Roles: CcorfoRoles,
          Links: CcorfoLinks,
          Domains: CcorfoDomains,
          Circles: PrcorfoCircles,
        },
        extendedMembers: {
          People: CextfoPeople,
          Skills: CextfoSkill,
          Roles: CextfoRoles,
          Links: CextfoLinks,
          Domains: CextfoDomains,
        },
      };
    }
    return curData;
  };

  var curPModeData = [];
  if (focusMode.length > 0 && focusMode === "Project") {
    for (let j = 0; j < projects.length; j++) {
      let cId = projects[j]._id;
      for (let i = 0; i < members.length; i++) {
        let curCoresMembers = {};
        let curExtendedsMembers = {};
        if (members[i].memberType === cId) {
          curCoresMembers = members[i].coreMembers;
          curExtendedsMembers = members[i].extendedMembers;
          let res = pMmembes(curCoresMembers, curExtendedsMembers, projects[j]);
          if (res) {
            curPModeData.push({ ...projects[j], ...res });
            break;
          }
        }
      }
    }
  }

  //People As Action
  var peopleAsPData = [...curPModeData];
  if (
    focusMode.length > 0 &&
    curPModeData &&
    curPModeData.length > 0 &&
    focusMode === "Project" &&
    isModeSwitched
  ) {
    for (let i = 0; i < curPModeData.length; i++) {
      let totalPeopleProjectData = [];
      // Core
      if (
        curPModeData[i].coreMembers &&
        curPModeData[i].coreMembers.People &&
        curPModeData[i].coreMembers.People.length > 0
      ) {
        let curCirclePeople = [];
        for (let j = 0; j < curPModeData[i].coreMembers.People.length; j++) {
          for (let l = 0; l < peopleSorted.length; l++) {
            if (
              curPModeData[i].coreMembers.People[j]._id === peopleSorted[l]._id
            ) {
              curCirclePeople.push({
                ...peopleSorted[l],
                field: `${curPModeData[i].coreMembers.People[j].fname} ${curPModeData[i].coreMembers.People[j].lname} (Person)`,
              });
              // totalPeopleProjectData.push({...peopleSorted[l], field: `${curPModeData[i].coreMembers.People[j].fname} ${curPModeData[i].coreMembers.People[j].lname} (Person)` });

              totalPeopleProjectData.push({
                ...peopleSorted[l],
                field: {
                  name: `${curPModeData[i].coreMembers.People[j].fname} ${curPModeData[i].coreMembers.People[j].lname} (Person)`,
                  obj: curPModeData[i].coreMembers.People[j],
                  cardType: "People",
                  Id: curPModeData[i].coreMembers.People[j]._id,
                },
              });
            }
          }
        }
        if (curCirclePeople.length > 0) {
          peopleAsPData[i].coreMembers.People = curCirclePeople;
          // peopleAsCData.push(curCircleSkill)
        }
      }

      if (
        curPModeData[i].coreMembers &&
        curPModeData[i].coreMembers.Skills &&
        curPModeData[i].coreMembers.Skills.length > 0
      ) {
        let curCircleSkill = [];
        for (let j = 0; j < curPModeData[i].coreMembers.Skills.length; j++) {
          if (
            curPModeData[i].coreMembers.Skills[j].owners &&
            curPModeData[i].coreMembers.Skills[j].owners.length > 0
          ) {
            for (let l = 0; l < peopleSorted.length; l++) {
              let inc = curPModeData[i].coreMembers.Skills[j].owners.includes(
                peopleSorted[l]._id
              );
              if (inc) {
                curCircleSkill.push({
                  ...peopleSorted[l],
                  field: `${curPModeData[i].coreMembers.Skills[j].skillName} (Skill)`,
                });
                // totalPeopleProjectData.push({...peopleSorted[l], field: `${curPModeData[i].coreMembers.Skills[j].skillName} (Skill)`, });

                totalPeopleProjectData.push({
                  ...peopleSorted[l],
                  field: {
                    name: `${curPModeData[i].coreMembers.Skills[j].skillName} (Skill)`,
                    obj: curPModeData[i].coreMembers.Skills[j],
                    cardType: "Skills",
                    Id: curPModeData[i].coreMembers.Skills[j]._id,
                  },
                });
              }
            }
          }
        }
        if (curCircleSkill.length > 0) {
          peopleAsPData[i].coreMembers.Skills = curCircleSkill;
          // peopleAsCData.push(curCircleSkill)
        }
      }

      if (
        curPModeData[i].coreMembers &&
        curPModeData[i].coreMembers.Roles &&
        curPModeData[i].coreMembers.Roles.length > 0
      ) {
        let curCircleRole = [];
        for (let j = 0; j < curPModeData[i].coreMembers.Roles.length; j++) {
          if (
            curPModeData[i].coreMembers.Roles[j].owners &&
            curPModeData[i].coreMembers.Roles[j].owners.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              let inc = curPModeData[i].coreMembers.Roles[j].owners.includes(
                peopleSorted[k]._id
              );
              if (inc) {
                curCircleRole.push({
                  ...peopleSorted[k],
                  field: `${curPModeData[i].coreMembers.Roles[j].roleName} (Role)`,
                });
                // totalPeopleProjectData.push({...peopleSorted[k], field: `${curPModeData[i].coreMembers.Roles[j].roleName} (Role)`, });

                totalPeopleProjectData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curPModeData[i].coreMembers.Roles[j].roleName} (Role)`,
                    obj: curPModeData[i].coreMembers.Roles[j],
                    cardType: "Roles",
                    Id: curPModeData[i].coreMembers.Roles[j]._id,
                  },
                });
              }
            }
          }
          if (curCircleRole.length > 0) {
            peopleAsPData[i].coreMembers.Roles = curCircleRole;
          }
        }
      }

      if (
        curPModeData[i].coreMembers &&
        curPModeData[i].coreMembers.Domains &&
        curPModeData[i].coreMembers.Domains.length > 0
      ) {
        let curCircleDomain = [];
        for (let j = 0; j < curPModeData[i].coreMembers.Domains.length; j++) {
          // console.log(curPModeData[i].coreMembers.Domains)
          if (
            curPModeData[i].coreMembers.Domains[j].owners.owner &&
            curPModeData[i].coreMembers.Domains[j].owners.owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // let inc = curPModeData[i].coreMembers.Domains[j].owners.owner.includes(peopleSorted[k]._id)
              if (
                curPModeData[i].coreMembers.Domains[j].owners.owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleDomain.push({
                  ...peopleSorted[k],
                  field: `${curPModeData[i].coreMembers.Domains[j].domainName} (Domain)`,
                });
                // totalPeopleProjectData.push({...peopleSorted[k], field: `${curPModeData[i].coreMembers.Domains[j].domainName} (Domain)`});
                totalPeopleProjectData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curPModeData[i].coreMembers.Domains[j].domainName} (Domain)`,
                    obj: curPModeData[i].coreMembers.Domains[j],
                    cardType: "Domains",
                    Id: curPModeData[i].coreMembers.Domains[j]._id,
                  },
                });
              }
            }
          }
          if (curCircleDomain.length > 0) {
            peopleAsPData[i].coreMembers.Domains = curCircleDomain;
          }
        }
      }

      if (
        curPModeData[i].coreMembers &&
        curPModeData[i].coreMembers.Links &&
        curPModeData[i].coreMembers.Links.length > 0
      ) {
        let curCircleLink = [];
        for (let j = 0; j < curPModeData[i].coreMembers.Links.length; j++) {
          // console.log(curCModeData[i].coreMembers.Links)
          if (
            curPModeData[i].coreMembers.Links[j].owner &&
            curPModeData[i].coreMembers.Links[j].owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // let inc = curCModeData[i].coreMembers.Links[j].owner.includes(peopleSorted[k]._id)
              if (
                curPModeData[i].coreMembers.Links[j].owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleLink.push({
                  ...peopleSorted[k],
                  field: `${curPModeData[i].coreMembers.Links[j].linkName} (Link)`,
                });
                // totalPeopleProjectData.push({...peopleSorted[k], field: `${curPModeData[i].coreMembers.Links[j].linkName} (Link)` });
                totalPeopleProjectData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curPModeData[i].coreMembers.Links[j].linkName} (Link)`,
                    obj: curPModeData[i].coreMembers.Links[j],
                    cardType: "Links",
                    Id: curPModeData[i].coreMembers.Links[j]._id,
                  },
                });
              }
            }
          }
          if (curCircleLink.length > 0) {
            peopleAsPData[i].coreMembers.Links = curCircleLink;
          }
        }
      }

      if (
        curPModeData[i].coreMembers &&
        curPModeData[i].coreMembers.Circles &&
        curPModeData[i].coreMembers.Circles.length > 0
      ) {
        let curCircleProject = [];
        for (let j = 0; j < curPModeData[i].coreMembers.Circles.length; j++) {
          // console.log(curCModeData[i].coreMembers.Projects)
          if (
            curPModeData[i].coreMembers.Circles[j].lead &&
            curPModeData[i].coreMembers.Circles[j].lead.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              let inc = curPModeData[i].coreMembers.Circles[j].lead.includes(
                peopleSorted[k]._id
              );
              if (inc) {
                curCircleProject.push({
                  ...peopleSorted[k],
                  field: `${curPModeData[i].coreMembers.Circles[j].circleName} (Circle)`,
                });
                // totalPeopleProjectData.push({...peopleSorted[k], field: `${curPModeData[i].coreMembers.Circles[j].circleName} (Circle)`, });
                totalPeopleProjectData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curPModeData[i].coreMembers.Circles[j].circleName} (Circle)`,
                    obj: curPModeData[i].coreMembers.Circles[j],
                    cardType: "Circles",
                    Id: curPModeData[i].coreMembers.Circles[j]._id,
                  },
                });
              }
            }
          }
          if (curCircleProject.length > 0) {
            peopleAsPData[i].coreMembers.Circles = curCircleProject;
          }
        }
      }

      //External
      if (
        curPModeData[i].extendedMembers &&
        curPModeData[i].extendedMembers.People &&
        curPModeData[i].extendedMembers.People.length > 0
      ) {
        let curCirclePeople = [];
        for (
          let j = 0;
          j < curPModeData[i].extendedMembers.People.length;
          j++
        ) {
          for (let l = 0; l < peopleSorted.length; l++) {
            if (
              curPModeData[i].extendedMembers.People[j]._id ===
              peopleSorted[l]._id
            ) {
              curCirclePeople.push({
                ...peopleSorted[l],
                field: `${curPModeData[i].extendedMembers.People[j].fname} ${curPModeData[i].extendedMembers.People[j].lname} (Person)`,
              });

              // totalPeopleProjectData.push({...peopleSorted[l], field: `${curPModeData[i].extendedMembers.People[j].fname} ${curPModeData[i].extendedMembers.People[j].lname} (Person)` });

              totalPeopleProjectData.push({
                ...peopleSorted[l],
                field: {
                  name: `${curPModeData[i].extendedMembers.People[j].fname} ${curPModeData[i].extendedMembers.People[j].lname} (Person)`,
                  obj: curPModeData[i].extendedMembers.People[j],
                  cardType: "People",
                  Id: curPModeData[i].extendedMembers.People[j]._id,
                },
              });
            }
          }
        }
        if (curCirclePeople.length > 0) {
          peopleAsPData[i].extendedMembers.People = curCirclePeople;
          // peopleAsCData.push(curCircleSkill)
        }
      }

      if (
        curPModeData[i].extendedMembers &&
        curPModeData[i].extendedMembers.Skills &&
        curPModeData[i].extendedMembers.Skills.length > 0
      ) {
        let curCircleSkill = [];
        for (
          let j = 0;
          j < curPModeData[i].extendedMembers.Skills.length;
          j++
        ) {
          // console.log(curCModeData[i].extendedMembers.Skills)
          if (curPModeData[i].extendedMembers.Skills[j].owners.length > 0) {
            for (let l = 0; l < peopleSorted.length; l++) {
              let inc = curPModeData[i].extendedMembers.Skills[
                j
              ].owners.includes(peopleSorted[l]._id);
              if (inc) {
                curCircleSkill.push({
                  ...peopleSorted[l],
                  field: `${curPModeData[i].extendedMembers.Skills[j].skillName} (Skill)`,
                });
                // totalPeopleProjectData.push({...peopleSorted[l], field: `${curPModeData[i].extendedMembers.Skills[j].skillName} (Skill)`, });

                totalPeopleProjectData.push({
                  ...peopleSorted[l],
                  field: {
                    name: `${curPModeData[i].extendedMembers.Skills[j].skillName} (Skill)`,
                    obj: curPModeData[i].extendedMembers.Skills[j],
                    cardType: "Skills",
                    Id: curPModeData[i].extendedMembers.Skills[j]._id,
                  },
                });
              }
            }
          }
        }
        peopleAsPData[i].extendedMembers.Skills = curCircleSkill;
      }

      if (
        curPModeData[i].extendedMembers &&
        curPModeData[i].extendedMembers.Roles &&
        curPModeData[i].extendedMembers.Roles.length > 0
      ) {
        let curCircleRole = [];
        for (let j = 0; j < curPModeData[i].extendedMembers.Roles.length; j++) {
          // console.log(curCModeData[i].extendedMembers.Roles)
          if (
            curPModeData[i].extendedMembers.Roles[j].owners &&
            curPModeData[i].extendedMembers.Roles[j].owners.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              let inc = curPModeData[i].extendedMembers.Roles[
                j
              ].owners.includes(peopleSorted[k]._id);
              if (inc) {
                curCircleRole.push({
                  ...peopleSorted[k],
                  field: `${curPModeData[i].extendedMembers.Roles[j].roleName} (Role)`,
                });
                // totalPeopleProjectData.push({...peopleSorted[k], field: `${curPModeData[i].extendedMembers.Roles[j].roleName} (Role)`, });
                totalPeopleProjectData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curPModeData[i].extendedMembers.Roles[j].roleName} (Role)`,
                    obj: curPModeData[i].extendedMembers.Roles[j],
                    cardType: "Roles",
                    Id: curPModeData[i].extendedMembers.Roles[j]._id,
                  },
                });
              }
            }
          }
          peopleAsPData[i].extendedMembers.Roles = curCircleRole;
        }
      }

      if (
        curPModeData[i].extendedMembers &&
        curPModeData[i].extendedMembers.Domain &&
        curPModeData[i].extendedMembers.Domains.length > 0
      ) {
        let curCircleDomain = [];
        for (
          let j = 0;
          j < curPModeData[i].extendedMembers.Domains.length;
          j++
        ) {
          // console.log(curCModeData[i].extendedMembers.Domains)
          if (
            curPModeData[i].extendedMembers.Domains[j].owners.owner &&
            curPModeData[i].extendedMembers.Domains[j].owners.owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // let inc = curCModeData[i].extendedMembers.Domains[j].owners.owner.includes(peopleSorted[k]._id)
              if (
                curPModeData[i].extendedMembers.Domains[j].owners.owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleDomain.push({
                  ...peopleSorted[k],
                  field: `${curPModeData[i].extendedMembers.Domains[j].domainName} (Domain)`,
                });
                // totalPeopleProjectData.push({...peopleSorted[k], field: `${curPModeData[i].extendedMembers.Domains[j].domainName} (Domain)`, });

                totalPeopleProjectData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curPModeData[i].extendedMembers.Domains[j].domainName} (Domain)`,
                    obj: curPModeData[i].extendedMembers.Domains[j],
                    cardType: "Domains",
                    Id: curPModeData[i].extendedMembers.Domains[j]._id,
                  },
                });
              }
            }
          }
          peopleAsPData[i].extendedMembers.Domains = curCircleDomain;
        }
      }

      if (
        curPModeData[i].extendedMembers &&
        curPModeData[i].extendedMembers.Links &&
        curPModeData[i].extendedMembers.Links.length > 0
      ) {
        let curCircleLink = [];
        for (let j = 0; j < curPModeData[i].extendedMembers.Links.length; j++) {
          // console.log(curCModeData[i].extendedMembers.Links)
          if (
            curPModeData[i].extendedMembers.Links[j].owner &&
            curPModeData[i].extendedMembers.Links[j].owner.length > 0
          ) {
            for (let k = 0; k < peopleSorted.length; k++) {
              // let inc = curCModeData[i].extendedMembers.Links[j].owner.includes(peopleSorted[k]._id)
              if (
                curPModeData[i].extendedMembers.Links[j].owner ===
                peopleSorted[k]._id.toString()
              ) {
                curCircleLink.push({
                  ...peopleSorted[k],
                  field: `${curPModeData[i].extendedMembers.Links[j].linkName} (Link)`,
                });

                // totalPeopleProjectData.push({...peopleSorted[k], field: `${curPModeData[i].extendedMembers.Links[j].linkName} (Link)` });

                totalPeopleProjectData.push({
                  ...peopleSorted[k],
                  field: {
                    name: `${curPModeData[i].extendedMembers.Links[j].linkName} (Link)`,
                    obj: curPModeData[i].extendedMembers.Links[j],
                    cardType: "Links",
                    Id: curPModeData[i].extendedMembers.Links[j]._id,
                  },
                });
              }
            }
          }
          peopleAsPData[i].extendedMembers.Links = curCircleLink;
        }
      }

      let peopleAsData = [];
      if (totalPeopleProjectData.length > 0) {
        for (let i = 0; i < totalPeopleProjectData.length; i++) {
          peopleAsData.push(totalPeopleProjectData[i]._id);
        }
      }

      let peopleAsDataSet = [...new Set([...peopleAsData])];

      let PeopleAsFilterData = [];
      if (totalPeopleProjectData.length > 0 && peopleAsDataSet.length > 0) {
        for (let i = 0; i < peopleAsDataSet.length; i++) {
          let inc = [];
          for (let j = 0; j < totalPeopleProjectData.length; j++) {
            if (totalPeopleProjectData[j]._id === peopleAsDataSet[i]) {
              inc.push(totalPeopleProjectData[j].field);
            }
          }
          if (inc.length > 0) {
            PeopleAsFilterData.push({ id: peopleAsDataSet[i], FildName: inc });
          }
        }
      }

      let PeopleAsAllFilterData = [];
      if (PeopleAsFilterData.length > 0) {
        for (let k = 0; k < PeopleAsFilterData.length; k++) {
          // console.log('dev:', PeopleAsFilterData);
          for (let l = 0; l < peopleSorted.length; l++) {
            // console.log(peopleSorted);
            if (
              PeopleAsFilterData[k].id.toString() ===
              peopleSorted[l]._id.toString()
            ) {
              PeopleAsAllFilterData.push({
                ...peopleSorted[l],
                FildName: PeopleAsFilterData[k].FildName,
              });
            }
          }
        }
      }
      peopleAsPData[i].pepoleData = PeopleAsAllFilterData;
    }
  }

  /* ==================================== Project mode FOCUSED MODE ============================================= */
  let curPModeDatas = [...curPModeData];
  if (focusMode === "Project" && !isModeSwitched) {
    let curPMode = [];
    curPModeDatas = curPModeData.filter((e) => {
      // console.log(fList[e.projectName]);
      return !fList[e.projectName];
    });
    if (focusList && focusList.length > 0) {
      for (let i = 0; i < focusList.length; i++) {
        for (let j = 0; j < curPModeDatas.length; j++) {
          if (focusList[i].id === curPModeDatas[j]._id) {
            curPMode.push(curPModeDatas[j]);
          }
        }
      }
    }
    if (curPMode.length > 0) {
      curPModeDatas = curPMode;
    }
  }

  const prmHandelFocused = (index) => {
    setFocusedMode("");
    let indexedCard = peopleSorted[index];
    handelFocused({ card: "Projects", index: index, id: indexedCard._id });
  };

  /* ============================== Circle mode and Project mode UI ================================================ */

  const CircleMode = () => (
    <div className="wrap-lists" id="circle-mode">
      {curCModeDatas && curCModeDatas.length > 0
        ? curCModeDatas.map((e, index) => {
            var meetingsData = [];
            if (e.meetings && e.meetings.length > 0) {
              meetingsData = cAndpADatas(e.meetings);
            }

            let cardData = {};
            if (e.lead && e.lead.length > 0) {
              cardData = cmLeadVerify(e);
            }
            let allPeopleFD = [];
            if (e.defaultCircle && e.defaultCircle === true) {
              allPeopleFD = getAllPeopleFD();
            }

            let isExtNoF = false;
            let curCAndPrId = localStorage.getItem("circleOrProjectId");
            if (curCAndPrId === e._id) {
              isExtNoF = true;
            }
            let hideColumn = Get_ShowHideColumn_Project_Circle_Mode(
              e,
              tagFilteredMegedData
            );
            const specificData = specialModeDataObj.find(
              (item) => item.id === e._id
            );

            if (
              tagFilteredMegedData &&
              Object.keys(tagFilteredMegedData).length > 0 &&
              hideColumn
            )
              return null;
            return (
              <>
                <div
                  key={index}
                  className="board-col board-col-circles"
                  data-board_id={e._id}
                >
                  <div
                    className={
                      e.defaultCircle
                        ? "list default-circle list-bg-red"
                        : "list list-bg-red"
                    }
                    data-list_id={e._id}
                  >
                    <div className="dropdown">
                      <h4 className="list-title">
                        <img alt="" src="images/icon-circle.png" />
                        {e.circleName}

                        <a
                          className={
                            specialModeDataObj.some((item) => item.id === e._id)
                              ? "list-menu refresh-list"
                              : "list-menu refresh-list hide"
                          }
                          // className="list-menu refresh-list hide"
                          href="#"
                          onClick={() => {
                            toggleEXTnNOCards(
                              "",
                              "EXT",
                              true,
                              "list",
                              "CircleMode",
                              e._id
                            );
                          }}
                        >
                          <i className="fas fa-sync-alt"></i>
                        </a>

                        <a
                          className={
                            specialModeDataObj.some((item) => item.id === e._id)
                              ? "list-menu dropdown-toggle hide"
                              : "list-menu dropdown-toggle"
                          }
                          // className="list-menu dropdown-toggle"
                          data-toggle="dropdown"
                          href="#"
                        >
                          {getSortIcon("Circles")}
                          <i className="fas fa-ellipsis-v"></i>
                        </a>

                        <ul
                          className="dropdown-menu dropdown-menu-right"
                          role="menu"
                        >
                          <li onClick={() => setCircles(e)} role="presentation">
                            <a
                              role="menuitem"
                              className="tool-from-mode"
                              data-list_id="circles"
                            >
                              Edit this circle
                            </a>
                          </li>
                          {e.defaultCircle &&
                          e.defaultCircle === true ? null : (
                            <li
                              onClick={() => cmHandelFocused(index)}
                              role="presentation"
                            >
                              <a
                                role="menuitem"
                                className="focus-from-mode"
                                data-list_id="circles"
                              >
                                Enter focus mode
                              </a>
                            </li>
                          )}
                          <li role="presentation" className="divider"></li>
                          <li
                            role="presentation"
                            onClick={() => {
                              // toggleEXTnNOCards(e._id, "EXT", false);
                              toggleEXTnNOCards(
                                "",
                                "EXT",
                                false,
                                "list",
                                "CircleMode",
                                e._id
                              );
                            }}
                          >
                            <a role="menuitem" className="show-external">
                              Show items owned by external
                            </a>
                          </li>
                          <li
                            role="presentation"
                            onClick={() => {
                              // toggleEXTnNOCards(e._id, "NO", false);
                              toggleEXTnNOCards(
                                "",
                                "NO",
                                false,
                                "list",
                                "CircleMode",
                                e._id
                              );
                            }}
                          >
                            <a role="menuitem" className="show-noowners">
                              Show items without owners
                            </a>
                          </li>
                        </ul>
                      </h4>
                    </div>
                    <div className="card-list only scrollbar">
                      <div className="project-tags">
                        {e.tags && e.tags.length > 0
                          ? e.tags.map((tag) => {
                              return (
                                <span className="custom-badge green tags">
                                  {tag}
                                </span>
                              );
                            })
                          : null}
                      </div>
                      <h6 style={{ borderBottom: "1px solid #000" }}>
                        Core members
                      </h6>
                      <div className="panel panel-default">
                        <div
                          id="core"
                          className="panel-body single box-container"
                        >
                          {e.coreMembers &&
                          e.coreMembers.People &&
                          e.coreMembers.People.length > 0
                            ? e.coreMembers.People.map((p, indexs) => {
                                if (specificData && (isEXT || isNO)) {
                                  if (
                                    specificData.data.people &&
                                    !specificData.data.people.includes(p._id)
                                  ) {
                                    return;
                                  }
                                } else {
                                  if (isTag || isEXT || isNO) {
                                    let Proceed = TagWiseSorting(
                                      p,
                                      isTag,
                                      isEXT,
                                      isNO,
                                      tagFilteredObj,
                                      extNoFilteredObj,
                                      "people"
                                    );
                                    if (!Proceed) return;
                                  }
                                }

                                let dLead = false;
                                let udLead = false;
                                if (
                                  cardData &&
                                  Object.keys(cardData).length > 0
                                ) {
                                  if (
                                    cardData.people &&
                                    cardData.people.length > 0 &&
                                    cardData.people === p._id
                                  ) {
                                    if (
                                      cardData.curType === "People" &&
                                      cardData.rAndS === null
                                    ) {
                                      dLead = true;
                                    } else if (
                                      (cardData.curType === "Roles" ||
                                        cardData.curType === "Skills") &&
                                      cardData.rAndS
                                    ) {
                                      udLead = true;
                                    }
                                  }
                                }
                                // console.log(21814, p)
                                return (
                                  <div key={indexs} className="card">
                                    <span className="custom-badge lightgray">
                                      Person
                                    </span>
                                    {p.workRole === "External" ? (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "EXT",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge blue ext-owner-pill"
                                      >
                                        EXT
                                      </span>
                                    ) : null}
                                    {dLead ? (
                                      <span className="custom-badge red-o direct-lead">
                                        LEAD
                                      </span>
                                    ) : null}
                                    {udLead ? (
                                      <span className="custom-badge gray-o">
                                        LEAD
                                      </span>
                                    ) : null}
                                    {p.tags && p.tags.length > 0
                                      ? p.tags.map((r, indext) => (
                                          <span
                                            key={indext}
                                            onClick={() => toggleTagCards(r)}
                                            className="custom-badge green tags"
                                          >
                                            {r}
                                          </span>
                                        ))
                                      : null}
                                    <a
                                      onClick={() => setPepole(p)}
                                      className="card-edit"
                                    >{`${p.fname} ${p.lname}`}</a>
                                  </div>
                                );
                              })
                            : null}

                          {allPeopleFD && allPeopleFD.length > 0
                            ? allPeopleFD.map((value, indexp) => {
                                if (isTag || isEXT || isNO) {
                                  let Proceed = TagWiseSorting(
                                    value,
                                    isTag,
                                    isEXT,
                                    isNO,
                                    tagFilteredObj,
                                    extNoFilteredObj,
                                    "people"
                                  );
                                  if (!Proceed) return;
                                }
                                return (
                                  <div key={indexp} className="card">
                                    {value.tags && value.tags.length > 0
                                      ? value.tags.map((r, indext) => (
                                          <span
                                            key={indext}
                                            onClick={() => toggleTagCards(r)}
                                            className="custom-badge green tags"
                                          >
                                            {r}
                                          </span>
                                        ))
                                      : null}
                                    <a
                                      onClick={() => setPepole(value)}
                                      className="card-edit"
                                    >{`${value.fname} ${value.lname}`}</a>
                                  </div>
                                );
                              })
                            : null}

                          {e.coreMembers &&
                          e.coreMembers.Skills &&
                          e.coreMembers.Skills.length > 0
                            ? e.coreMembers.Skills.map((s, indexs) => {
                                if (specificData && (isEXT || isNO)) {
                                  if (
                                    specificData.data.skill &&
                                    !specificData.data.skill.includes(s._id)
                                  ) {
                                    return;
                                  }
                                } else {
                                  if (isTag || isEXT || isNO) {
                                    let Proceed = TagWiseSorting(
                                      s,
                                      isTag,
                                      isEXT,
                                      isNO,
                                      tagFilteredObj,
                                      extNoFilteredObj,
                                      "skill"
                                    );
                                    if (!Proceed) return;
                                  }
                                }
                                let isExternal = IsOwnerExternal(s, "Skill");
                                let dLead = false;
                                if (
                                  cardData &&
                                  Object.keys(cardData).length > 0
                                ) {
                                  if (
                                    cardData.rAndS &&
                                    cardData.rAndS.length > 0 &&
                                    cardData.rAndS === s._id
                                  ) {
                                    if (cardData.curType === "Skills") {
                                      dLead = true;
                                    }
                                  }
                                }
                                let allOwner = getAllOwnerFCM(s, e._id, "core");
                                return (
                                  <div key={indexs} className="card">
                                    <span className="custom-badge lightgray">
                                      Skill
                                    </span>
                                    {isExternal ? (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "EXT",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge blue ext-owner-pill"
                                      >
                                        EXT
                                      </span>
                                    ) : null}
                                    {s.owners && s.owners.length > 0 ? null : (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "NO",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge no-owner-pill red"
                                      >
                                        NO
                                      </span>
                                    )}
                                    {s.ownerType &&
                                    s.ownerType.length > 0 &&
                                    s.ownerType === "Single owner" ? (
                                      <span className="custom-badge blue-o single-owner-pill">
                                        1
                                      </span>
                                    ) : null}
                                    {dLead ? (
                                      <span className="custom-badge red-o direct-lead">
                                        LEAD
                                      </span>
                                    ) : null}
                                    {s.tags && s.tags.length > 0
                                      ? s.tags.map((r, index) => (
                                          <span
                                            key={index}
                                            onClick={() => toggleTagCards(r)}
                                            className="custom-badge green tags"
                                          >
                                            {r}
                                          </span>
                                        ))
                                      : null}
                                    <a
                                      className="card-edit"
                                      onClick={() => setSkill(s)}
                                    >
                                      {s.skillName}
                                    </a>
                                    <br />
                                    {allOwner && allOwner.length > 0
                                      ? allOwner.map((value, pdindex) => {
                                          return (
                                            <span
                                              key={pdindex}
                                              onClick={() =>
                                                OpenCFPeople(value._id)
                                              }
                                              className="custom-badge tags blue-text"
                                            >{`${value.fname} ${value.lname}`}</span>
                                          );
                                        })
                                      : null}
                                  </div>
                                );
                              })
                            : null}
                          {e.coreMembers &&
                          e.coreMembers.Roles &&
                          e.coreMembers.Roles.length > 0
                            ? e.coreMembers.Roles.map((r, indexs) => {
                                if (specificData && (isEXT || isNO)) {
                                  if (
                                    specificData.data.role &&
                                    !specificData.data.role.includes(r._id)
                                  ) {
                                    return;
                                  }
                                } else {
                                  if (isTag || isEXT || isNO) {
                                    let Proceed = TagWiseSorting(
                                      r,
                                      isTag,
                                      isEXT,
                                      isNO,
                                      tagFilteredObj,
                                      extNoFilteredObj,
                                      "role"
                                    );
                                    if (!Proceed) return;
                                  }
                                }

                                let isExternal = IsOwnerExternal(r, "Role");
                                let dLead = false;
                                if (
                                  cardData &&
                                  Object.keys(cardData).length > 0
                                ) {
                                  if (
                                    cardData.rAndS &&
                                    cardData.rAndS.length > 0 &&
                                    cardData.rAndS === r._id
                                  ) {
                                    if (cardData.curType === "Roles") {
                                      dLead = true;
                                    }
                                  }
                                }

                                let allOwner = getAllOwnerFCM(r, e._id, "core");
                                return (
                                  <div key={indexs} className="card">
                                    <span className="custom-badge lightgray">
                                      Role
                                    </span>
                                    {isExternal ? (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "EXT",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge blue ext-owner-pill"
                                      >
                                        EXT
                                      </span>
                                    ) : null}
                                    {r.owners && r.owners.length === 0 ? (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "NO",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge no-owner-pill red"
                                      >
                                        NO
                                      </span>
                                    ) : null}
                                    {r.ownerRole &&
                                    r.ownerRole.length > 0 &&
                                    r.ownerRole === "Single owner" ? (
                                      <span className="custom-badge blue-o single-owner-pill">
                                        1
                                      </span>
                                    ) : null}
                                    {dLead ? (
                                      <span className="custom-badge red-o direct-lead">
                                        LEAD
                                      </span>
                                    ) : null}
                                    {r.tags && r.tags.length > 0
                                      ? r.tags.map((t, indext) => (
                                          <span
                                            key={indext}
                                            onClick={() => toggleTagCards(t)}
                                            className="custom-badge green tags"
                                          >
                                            {t}
                                          </span>
                                        ))
                                      : null}
                                    <a
                                      className="card-edit"
                                      onClick={() => setRole(r)}
                                    >
                                      {r.roleName}
                                    </a>
                                    <br />
                                    {allOwner && allOwner.length > 0
                                      ? allOwner.map((value, pdindex) => {
                                          return (
                                            <span
                                              key={pdindex}
                                              onClick={() =>
                                                OpenCFPeople(value._id)
                                              }
                                              className="custom-badge tags blue-text"
                                            >{`${value.fname} ${value.lname}`}</span>
                                          );
                                        })
                                      : null}
                                  </div>
                                );
                              })
                            : null}
                          {e.coreMembers &&
                          e.coreMembers.Domains &&
                          e.coreMembers.Domains.length > 0
                            ? e.coreMembers.Domains.map((d, indexs) => {
                                if (specificData && (isEXT || isNO)) {
                                  if (
                                    specificData.data.domain &&
                                    !specificData.data.domain.includes(d._id)
                                  ) {
                                    return;
                                  }
                                } else {
                                  if (isTag || isEXT || isNO) {
                                    let Proceed = TagWiseSorting(
                                      d,
                                      isTag,
                                      isEXT,
                                      isNO,
                                      tagFilteredObj,
                                      extNoFilteredObj,
                                      "domain"
                                    );
                                    if (!Proceed) return;
                                  }
                                }

                                let curOwner = {};
                                if (
                                  d &&
                                  Object.keys(d).length > 0 &&
                                  d.owners &&
                                  d.owners.owner &&
                                  d.owners.owner.length > 0
                                ) {
                                  if (peopleSorted && peopleSorted.length > 0) {
                                    for (
                                      let i = 0;
                                      i < peopleSorted.length;
                                      i++
                                    ) {
                                      if (
                                        peopleSorted[i]._id === d.owners.owner
                                      ) {
                                        curOwner = peopleSorted[i];
                                        break;
                                      }
                                    }
                                  }
                                }
                                let isExternal = IsOwnerExternal(d, "Domain");
                                let ownerType = getOwnerTypeFCM(d);
                                return (
                                  <div key={indexs} className="card">
                                    <span className="custom-badge lightgray">
                                      Domain
                                    </span>
                                    {isExternal ? (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "EXT",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge blue ext-owner-pill"
                                      >
                                        EXT
                                      </span>
                                    ) : null}
                                    {d.owners &&
                                    d.owners.owner &&
                                    d.owners.owner.length > 0 ? null : (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "NO",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge no-owner-pill red"
                                      >
                                        NO
                                      </span>
                                    )}
                                    {d.tags && d.tags.length > 0
                                      ? d.tags.map((t, indext) => (
                                          <span
                                            key={indext}
                                            onClick={() => toggleTagCards(t)}
                                            className="custom-badge green tags"
                                          >
                                            {t}
                                          </span>
                                        ))
                                      : null}
                                    <a
                                      className="card-edit"
                                      onClick={() => setDomain(d)}
                                    >
                                      {d.domainName}
                                    </a>
                                    {ownerType && ownerType.name ? (
                                      <p
                                        onClick={() =>
                                          cardOpensCfDomain(ownerType)
                                        }
                                        className="owner-type"
                                      >{`${ownerType.name} (${ownerType.type})`}</p>
                                    ) : null}
                                    {curOwner &&
                                    Object.keys(curOwner).length > 0 ? (
                                      <span
                                        onClick={() =>
                                          OpenCFPeople(curOwner._id)
                                        }
                                        className="custom-badge tags blue-text"
                                      >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                    ) : null}
                                  </div>
                                );
                              })
                            : null}

                          {e.coreMembers &&
                          e.coreMembers.Links &&
                          e.coreMembers.Links.length > 0
                            ? e.coreMembers.Links.map((l, indexs) => {
                                if (specificData && (isEXT || isNO)) {
                                  if (
                                    specificData.data.link &&
                                    !specificData.data.link.includes(l._id)
                                  ) {
                                    return;
                                  }
                                } else {
                                  if (isTag || isEXT || isNO) {
                                    let Proceed = TagWiseSorting(
                                      l,
                                      isTag,
                                      isEXT,
                                      isNO,
                                      tagFilteredObj,
                                      extNoFilteredObj,
                                      "link"
                                    );
                                    if (!Proceed) return;
                                  }
                                }
                                let isExternal = IsOwnerExternal(l, "Link");
                                let curOwner = {};
                                if (
                                  l &&
                                  Object.keys(l).length > 0 &&
                                  l.owner &&
                                  l.owner.length > 0
                                ) {
                                  if (peopleSorted && peopleSorted.length > 0) {
                                    for (
                                      let i = 0;
                                      i < peopleSorted.length;
                                      i++
                                    ) {
                                      if (peopleSorted[i]._id === l.owner) {
                                        curOwner = peopleSorted[i];
                                        break;
                                      }
                                    }
                                  }
                                }

                                return (
                                  <div key={indexs} className="card">
                                    <span className="custom-badge lightgray">
                                      Link
                                    </span>
                                    {isExternal ? (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "EXT",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge blue ext-owner-pill"
                                      >
                                        EXT
                                      </span>
                                    ) : null}
                                    {l.owner && l.owner.length > 0 ? null : (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "NO",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge no-owner-pill red"
                                      >
                                        NO
                                      </span>
                                    )}
                                    {l.tags && l.tags.length > 0
                                      ? l.tags.map((t, indext) => (
                                          <span
                                            key={indext}
                                            onClick={() => toggleTagCards(t)}
                                            className="custom-badge green tags"
                                          >
                                            {t}
                                          </span>
                                        ))
                                      : null}
                                    <a
                                      className="card-edit"
                                      onClick={() => setLink(l)}
                                    >
                                      {l.linkName}
                                    </a>
                                    {curOwner &&
                                    Object.keys(curOwner).length > 0 ? (
                                      <span
                                        onClick={() =>
                                          OpenCFPeople(curOwner._id)
                                        }
                                        className="custom-badge tags blue-text"
                                      >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                    ) : null}
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                      <h6 style={{ borderBottom: "1px solid #000" }}>
                        Extended members
                      </h6>
                      <div className="panel panel-default">
                        <div
                          id="extended"
                          className="panel-body single box-container"
                        >
                          {e.extendedMembers &&
                          e.extendedMembers.People &&
                          e.extendedMembers.People.length > 0
                            ? e.extendedMembers.People.map((p, indexs) => {
                                if (specificData && (isEXT || isNO)) {
                                  if (
                                    specificData.data.people &&
                                    !specificData.data.people.includes(p._id)
                                  ) {
                                    return;
                                  }
                                } else {
                                  if (isTag || isEXT || isNO) {
                                    let Proceed = TagWiseSorting(
                                      e,
                                      isTag,
                                      isEXT,
                                      isNO,
                                      tagFilteredObj,
                                      extNoFilteredObj,
                                      "people"
                                    );
                                    if (!Proceed) return;
                                  }
                                }

                                let dLead = false;
                                let udLead = false;
                                if (
                                  cardData &&
                                  Object.keys(cardData).length > 0
                                ) {
                                  if (
                                    cardData.people &&
                                    cardData.people.length > 0 &&
                                    cardData.people === p._id
                                  ) {
                                    if (
                                      cardData.curType === "People" &&
                                      cardData.rAndS === null
                                    ) {
                                      dLead = true;
                                    } else if (
                                      (cardData.curType === "Roles" ||
                                        cardData.curType === "Skills") &&
                                      cardData.rAndS
                                    ) {
                                      udLead = true;
                                    }
                                  }
                                }
                                // console.log(21814, p)
                                return (
                                  <div key={indexs} className="card">
                                    <span className="custom-badge lightgray">
                                      Person
                                    </span>
                                    {p.workRole === "External" ? (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "EXT",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge blue ext-owner-pill"
                                      >
                                        EXT
                                      </span>
                                    ) : null}
                                    {dLead ? (
                                      <span className="custom-badge red-o direct-lead">
                                        LEAD
                                      </span>
                                    ) : null}
                                    {udLead ? (
                                      <span className="custom-badge gray-o">
                                        LEAD
                                      </span>
                                    ) : null}
                                    {p.tags && p.tags.length > 0
                                      ? p.tags.map((r, indext) => (
                                          <span
                                            key={indext}
                                            onClick={() => toggleTagCards(r)}
                                            className="custom-badge green tags"
                                          >
                                            {r}
                                          </span>
                                        ))
                                      : null}
                                    <a
                                      onClick={() => setPepole(p)}
                                      className="card-edit"
                                    >{`${p.fname} ${p.lname}`}</a>
                                  </div>
                                );
                              })
                            : null}
                          {e.extendedMembers &&
                          e.extendedMembers.Skills &&
                          e.extendedMembers.Skills.length > 0
                            ? e.extendedMembers.Skills.map((s, indexs) => {
                                if (specificData && (isEXT || isNO)) {
                                  if (
                                    specificData.data.skill &&
                                    !specificData.data.skill.includes(s._id)
                                  ) {
                                    return;
                                  }
                                } else {
                                  if (isTag || isEXT || isNO) {
                                    let Proceed = TagWiseSorting(
                                      s,
                                      isTag,
                                      isEXT,
                                      isNO,
                                      tagFilteredObj,
                                      extNoFilteredObj,
                                      "skill"
                                    );
                                    if (!Proceed) return;
                                  }
                                }

                                let isExternal = IsOwnerExternal(s, "Skill");
                                let dLead = false;
                                if (
                                  cardData &&
                                  Object.keys(cardData).length > 0
                                ) {
                                  if (
                                    cardData.rAndS &&
                                    cardData.rAndS.length > 0 &&
                                    cardData.rAndS === s._id
                                  ) {
                                    if (cardData.curType === "Skills") {
                                      dLead = true;
                                    }
                                  }
                                }
                                let allOwner = getAllOwnerFCM(
                                  s,
                                  e._id,
                                  "extended"
                                );
                                return (
                                  <div key={indexs} className="card">
                                    <span className="custom-badge lightgray">
                                      Skill
                                    </span>
                                    {isExternal ? (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "EXT",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge blue ext-owner-pill"
                                      >
                                        EXT
                                      </span>
                                    ) : null}
                                    {s.owners && s.owners.length === 0 ? (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "NO",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge no-owner-pill red"
                                      >
                                        NO
                                      </span>
                                    ) : null}
                                    {s.ownerType &&
                                    s.ownerType.length > 0 &&
                                    e.ownerType === "Single owner" ? (
                                      <span className="custom-badge blue-o single-owner-pill">
                                        1
                                      </span>
                                    ) : null}
                                    {dLead ? (
                                      <span className="custom-badge red-o direct-lead">
                                        LEAD
                                      </span>
                                    ) : null}
                                    {s.tags && s.tags.length > 0
                                      ? s.tags.map((r, index) => (
                                          <span
                                            key={index}
                                            onClick={() => toggleTagCards(r)}
                                            className="custom-badge green tags"
                                          >
                                            {r}
                                          </span>
                                        ))
                                      : null}
                                    <a
                                      className="card-edit"
                                      onClick={() => setSkill(s)}
                                    >
                                      {s.skillName}
                                    </a>
                                    <br />
                                    {allOwner && allOwner.length > 0
                                      ? allOwner.map((value, pdindex) => {
                                          return (
                                            <span
                                              key={pdindex}
                                              onClick={() =>
                                                OpenCFPeople(value._id)
                                              }
                                              className="custom-badge tags blue-text"
                                            >{`${value.fname} ${value.lname}`}</span>
                                          );
                                        })
                                      : null}
                                  </div>
                                );
                              })
                            : null}
                          {e.extendedMembers &&
                          e.extendedMembers.Roles &&
                          e.extendedMembers.Roles.length > 0
                            ? e.extendedMembers.Roles.map((r, indexs) => {
                                if (specificData && (isEXT || isNO)) {
                                  if (
                                    specificData.data.role &&
                                    !specificData.data.role.includes(r._id)
                                  ) {
                                    return;
                                  }
                                } else {
                                  if (isTag || isEXT || isNO) {
                                    let Proceed = TagWiseSorting(
                                      r,
                                      isTag,
                                      isEXT,
                                      isNO,
                                      tagFilteredObj,
                                      extNoFilteredObj,
                                      "role"
                                    );
                                    if (!Proceed) return;
                                  }
                                }

                                let isExternal = IsOwnerExternal(r, "Role");
                                let dLead = false;
                                if (
                                  cardData &&
                                  Object.keys(cardData).length > 0
                                ) {
                                  if (
                                    cardData.rAndS &&
                                    cardData.rAndS.length > 0 &&
                                    cardData.rAndS === r._id
                                  ) {
                                    if (cardData.curType === "Roles") {
                                      dLead = true;
                                    }
                                  }
                                }

                                let allOwner = getAllOwnerFCM(
                                  r,
                                  e._id,
                                  "extended"
                                );
                                return (
                                  <div key={indexs} className="card">
                                    <span className="custom-badge lightgray">
                                      Role
                                    </span>
                                    {isExternal ? (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "EXT",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge blue ext-owner-pill"
                                      >
                                        EXT
                                      </span>
                                    ) : null}
                                    {r.owners && r.owners.length === 0 ? (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "NO",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge no-owner-pill red"
                                      >
                                        NO
                                      </span>
                                    ) : null}
                                    {r.ownerRole.length > 0 &&
                                    e.ownerRole === "Single owner" ? (
                                      <span className="custom-badge blue-o single-owner-pill">
                                        1
                                      </span>
                                    ) : null}
                                    {dLead ? (
                                      <span className="custom-badge red-o direct-lead">
                                        LEAD
                                      </span>
                                    ) : null}
                                    {r.tags && r.tags.length > 0
                                      ? r.tags.map((t, indext) => (
                                          <span
                                            key={indext}
                                            onClick={() => toggleTagCards(t)}
                                            className="custom-badge green tags"
                                          >
                                            {t}
                                          </span>
                                        ))
                                      : null}
                                    <a
                                      className="card-edit"
                                      onClick={() => setRole(r)}
                                    >
                                      {r.roleName}
                                    </a>
                                    <br />
                                    {allOwner && allOwner.length > 0
                                      ? allOwner.map((value, pdindex) => {
                                          return (
                                            <span
                                              key={pdindex}
                                              onClick={() =>
                                                OpenCFPeople(value._id)
                                              }
                                              className="custom-badge tags blue-text"
                                            >{`${value.fname} ${value.lname}`}</span>
                                          );
                                        })
                                      : null}
                                  </div>
                                );
                              })
                            : null}
                          {e.extendedMembers &&
                          e.extendedMembers.Domains &&
                          e.extendedMembers.Domains.length > 0
                            ? e.extendedMembers.Domains.map((d, indexs) => {
                                if (specificData && (isEXT || isNO)) {
                                  if (
                                    specificData.data.domain &&
                                    !specificData.data.domain.includes(d._id)
                                  ) {
                                    return;
                                  }
                                } else {
                                  if (isTag || isEXT || isNO) {
                                    let Proceed = TagWiseSorting(
                                      d,
                                      isTag,
                                      isEXT,
                                      isNO,
                                      tagFilteredObj,
                                      extNoFilteredObj,
                                      "domain"
                                    );
                                    if (!Proceed) return;
                                  }
                                }

                                let curOwner = {};
                                if (
                                  d &&
                                  Object.keys(d).length > 0 &&
                                  d.owners &&
                                  d.owners.owner &&
                                  d.owners.owner.length > 0
                                ) {
                                  if (peopleSorted && peopleSorted.length > 0) {
                                    for (
                                      let i = 0;
                                      i < peopleSorted.length;
                                      i++
                                    ) {
                                      if (
                                        peopleSorted[i]._id === d.owners.owner
                                      ) {
                                        curOwner = peopleSorted[i];
                                        break;
                                      }
                                    }
                                  }
                                }
                                let isExternal = IsOwnerExternal(d, "Domain");
                                let ownerType = getOwnerTypeFCM(d);
                                return (
                                  <div key={indexs} className="card">
                                    <span className="custom-badge lightgray">
                                      Domain
                                    </span>
                                    {isExternal ? (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "EXT",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge blue ext-owner-pill"
                                      >
                                        EXT
                                      </span>
                                    ) : null}
                                    {d.owners &&
                                    d.owners.owner &&
                                    d.owners.owner.length > 0 ? null : (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "NO",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge no-owner-pill red"
                                      >
                                        NO
                                      </span>
                                    )}
                                    {d.tags && d.tags.length > 0
                                      ? d.tags.map((t, indext) => (
                                          <span
                                            key={indext}
                                            onClick={() => toggleTagCards(t)}
                                            className="custom-badge green tags"
                                          >
                                            {t}
                                          </span>
                                        ))
                                      : null}
                                    <a
                                      className="card-edit"
                                      onClick={() => setDomain(d)}
                                    >
                                      {d.domainName}
                                    </a>
                                    {ownerType && ownerType.name ? (
                                      <p
                                        onClick={() =>
                                          cardOpensCfDomain(ownerType)
                                        }
                                        className="owner-type"
                                      >{`${ownerType.name} (${ownerType.type})`}</p>
                                    ) : null}
                                    {curOwner &&
                                    Object.keys(curOwner).length > 0 ? (
                                      <span
                                        onClick={() =>
                                          OpenCFPeople(curOwner._id)
                                        }
                                        className="custom-badge tags blue-text"
                                      >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                    ) : null}
                                  </div>
                                );
                              })
                            : null}
                          {e.extendedMembers &&
                          e.extendedMembers.Links &&
                          e.extendedMembers.Links.length > 0
                            ? e.extendedMembers.Links.map((l, indexs) => {
                                if (specificData && (isEXT || isNO)) {
                                  if (
                                    specificData.data.link &&
                                    !specificData.data.link.includes(l._id)
                                  ) {
                                    return;
                                  }
                                } else {
                                  if (isTag || isEXT || isNO) {
                                    let Proceed = TagWiseSorting(
                                      l,
                                      isTag,
                                      isEXT,
                                      isNO,
                                      tagFilteredObj,
                                      extNoFilteredObj,
                                      "link"
                                    );
                                    if (!Proceed) return;
                                  }
                                }

                                let isExternal = IsOwnerExternal(l, "Link");
                                let curOwner = {};
                                if (
                                  l &&
                                  Object.keys(l).length > 0 &&
                                  l.owner &&
                                  l.owner.length > 0
                                ) {
                                  if (peopleSorted && peopleSorted.length > 0) {
                                    for (
                                      let i = 0;
                                      i < peopleSorted.length;
                                      i++
                                    ) {
                                      if (peopleSorted[i]._id === l.owner) {
                                        curOwner = peopleSorted[i];
                                        break;
                                      }
                                    }
                                  }
                                }

                                return (
                                  <div key={indexs} className="card">
                                    <span className="custom-badge lightgray">
                                      Link
                                    </span>
                                    {isExternal ? (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "EXT",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge blue ext-owner-pill"
                                      >
                                        EXT
                                      </span>
                                    ) : null}
                                    {l.owner && l.owner.length > 0 ? null : (
                                      <span
                                        onClick={() => {
                                          toggleEXTnNOCards(
                                            e._id,
                                            "NO",
                                            false,
                                            "pill"
                                          );
                                        }}
                                        className="custom-badge no-owner-pill red"
                                      >
                                        NO
                                      </span>
                                    )}
                                    {l.tags && l.tags.length > 0
                                      ? l.tags.map((t, indext) => (
                                          <span
                                            key={indext}
                                            onClick={() => toggleTagCards(t)}
                                            className="custom-badge green tags"
                                          >
                                            {t}
                                          </span>
                                        ))
                                      : null}
                                    <a
                                      className="card-edit"
                                      onClick={() => setLink(l)}
                                    >
                                      {l.linkName}
                                    </a>
                                    {curOwner &&
                                    Object.keys(curOwner).length > 0 ? (
                                      <span
                                        onClick={() =>
                                          OpenCFPeople(curOwner._id)
                                        }
                                        className="custom-badge tags blue-text"
                                      >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                    ) : null}
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                      <h6 style={{ borderBottom: "1px solid #000" }}>
                        Projects
                      </h6>
                      <div className="panel panel-default">
                        <div
                          id="notmatched"
                          className="panel-body single box-container"
                        >
                          {e.coreMembers &&
                          e.coreMembers.Projects &&
                          e.coreMembers.Projects.length > 0
                            ? e.coreMembers.Projects.map((p, indexs) => {
                                if (isTag || isEXT || isNO) {
                                  let Proceed = TagWiseSorting(
                                    p,
                                    isTag,
                                    isEXT,
                                    isNO,
                                    tagFilteredObj,
                                    extNoFilteredObj,
                                    "project"
                                  );
                                  if (!Proceed) return;
                                }
                                return (
                                  <div key={indexs} className="card">
                                    {/* <span className="custom-badge lightgray">
                                Project
                              </span> */}
                                    {p.tags && p.tags.length > 0
                                      ? p.tags.map((t, indext) => (
                                          <span
                                            key={indext}
                                            onClick={() => toggleTagCards(t)}
                                            className="custom-badge green tags"
                                          >
                                            {t}
                                          </span>
                                        ))
                                      : null}
                                    <a
                                      className="card-edit"
                                      onClick={() => setProject(p)}
                                    >
                                      {p.projectName}
                                    </a>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                      <h6
                        style={{ borderBottom: "1px solid #000" }}
                        className="meetings-heading"
                      >
                        Meetings
                      </h6>
                      <div className="panel panel-default">
                        <div
                          id="meetings"
                          className="panel-body single box-container"
                        >
                          {meetingsData && meetingsData.length > 0
                            ? meetingsData.map((m, indexm) => {
                                return (
                                  <div className="card meeting-card ">
                                    <div className="meeting-content">
                                      <p>
                                        <span className="fas fa-users"></span>
                                        <b style={{ marginLeft: "5px" }}>
                                          {m.meetingsName}
                                        </b>
                                      </p>
                                      <p>{m.meetingsPurpose}</p>
                                      {m.recurrenceType === "As needed" ||
                                      m.durationType === "As needed" ? (
                                        <>
                                          <p>
                                            {m.recurrenceType} {m.durationType}
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          {m.recurrence && (
                                            <p>
                                              Every {m.recurrence}{" "}
                                              {m.recurrenceType}{" "}
                                              {m.duration && (
                                                <span>
                                                  for {m.duration}{" "}
                                                  {m.durationType}
                                                </span>
                                              )}
                                            </p>
                                          )}
                                        </>
                                      )}
                                      <p>{m.XTD ? "incl. XTD" : null}</p>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        : null}
    </div>
  );

  const ProjectMode = () => (
    <div className="wrap-lists" id="project-mode">
      {/*Project mode boards start 206 */}
      {curPModeDatas && curPModeDatas.length > 0
        ? curPModeDatas.map((e, index) => {
            // console.log(e.coreMembers.Skills)
            var meetingsData = [];
            if (e.meetings && e.meetings.length > 0) {
              meetingsData = cAndpADatas(e.meetings);
            }
            let cardData = {};
            if (e.lead && e.lead.length > 0) {
              cardData = cmLeadVerify(e);
            }

            let isExtNoF = false;
            let curCAndPrId = localStorage.getItem("circleOrProjectId");
            if (curCAndPrId === e._id) {
              isExtNoF = true;
            }

            let hideColumn = Get_ShowHideColumn_Project_Circle_Mode(
              e,
              tagFilteredMegedData
            );
            const specificData = specialModeDataObj.find(
              (item) => item.id === e._id
            );

            if (
              tagFilteredMegedData &&
              Object.keys(tagFilteredMegedData).length > 0 &&
              hideColumn
            )
              return null;

            return (
              <div
                key={index}
                className="board-col board-col-projects"
                data-list_id="206"
                data-board_id={e._id}
              >
                <div className="list list-bg-red" data-list_id={e._id}>
                  <div className="dropdown">
                    <h4 className="list-title">
                      <img alt="" src="images/icon-project.png" />
                      {e.projectName}

                      <a
                        className={
                          specialModeDataObj.some((item) => item.id === e._id)
                            ? "list-menu refresh-list"
                            : "list-menu refresh-list hide"
                        }
                        href="#"
                        onClick={() => {
                          toggleEXTnNOCards(
                            "",
                            "EXT",
                            true,
                            "list",
                            "CircleMode",
                            e._id
                          );
                        }}
                      >
                        <i className="fas fa-sync-alt"></i>
                      </a>

                      <a
                        className={
                          specialModeDataObj.some((item) => item.id === e._id)
                            ? "list-menu dropdown-toggle hide"
                            : "list-menu dropdown-toggle"
                        }
                        data-toggle="dropdown"
                        href="#"
                      >
                        {getSortIcon("Projects")}
                        <i className="fas fa-ellipsis-v"></i>
                      </a>

                      <ul
                        className="dropdown-menu dropdown-menu-right"
                        role="menu"
                      >
                        <li onClick={() => setProjects(e)} role="presentation">
                          <a role="menuitem" className="tool-from-mode">
                            Edit this project
                          </a>
                        </li>
                        <li
                          onClick={() => prmHandelFocused(index)}
                          role="presentation"
                        >
                          <a
                            role="menuitem"
                            className="focus-from-mode"
                            data-list_id="projects"
                          >
                            Enter focus mode
                          </a>
                        </li>
                        <li role="presentation" className="divider"></li>
                        <li
                          role="presentation"
                          onClick={() => {
                            // toggleEXTnNOCards(e._id, "EXT", false);
                            toggleEXTnNOCards(
                              "",
                              "EXT",
                              false,
                              "list",
                              "CircleMode",
                              e._id
                            );
                          }}
                        >
                          <a role="menuitem" className="show-external">
                            Show items owned by external
                          </a>
                        </li>
                        <li
                          role="presentation"
                          onClick={() => {
                            // toggleEXTnNOCards(e._id, "NO", false);
                            toggleEXTnNOCards(
                              "",
                              "NO",
                              false,
                              "list",
                              "CircleMode",
                              e._id
                            );
                          }}
                        >
                          <a role="menuitem" className="show-noowners">
                            Show items without owners
                          </a>
                        </li>
                      </ul>
                    </h4>
                  </div>

                  <div className="card-list scrollbar">
                    <div className="project-tags">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((tag) => {
                            return (
                              <span className="custom-badge green tags">
                                {tag}
                              </span>
                            );
                          })
                        : null}
                    </div>
                    <h6 style={{ borderBottom: "1px solid #000" }}>
                      Core members
                    </h6>
                    <div className="panel panel-default">
                      <div
                        id="core"
                        className="panel-body single box-container"
                      >
                        {e.coreMembers &&
                        e.coreMembers.People &&
                        e.coreMembers.People.length > 0
                          ? e.coreMembers.People.map((p, indexs) => {
                              if (specificData && (isEXT || isNO)) {
                                if (
                                  specificData.data.people &&
                                  !specificData.data.people.includes(p._id)
                                ) {
                                  return;
                                }
                              } else {
                                if (isTag || isEXT || isNO) {
                                  let Proceed = TagWiseSorting(
                                    p,
                                    isTag,
                                    isEXT,
                                    isNO,
                                    tagFilteredObj,
                                    extNoFilteredObj,
                                    "people"
                                  );
                                  if (!Proceed) return;
                                }
                              }

                              let dLead = false;
                              let udLead = false;
                              if (
                                cardData &&
                                Object.keys(cardData).length > 0
                              ) {
                                if (
                                  cardData.people &&
                                  cardData.people.length > 0 &&
                                  cardData.people === p._id
                                ) {
                                  if (
                                    cardData.curType === "People" &&
                                    cardData.rAndS === null
                                  ) {
                                    dLead = true;
                                  } else if (
                                    (cardData.curType === "Roles" ||
                                      cardData.curType === "Skills") &&
                                    cardData.rAndS
                                  ) {
                                    udLead = true;
                                  }
                                }
                              }
                              return (
                                <div key={indexs} className="card">
                                  <span className="custom-badge lightgray">
                                    Person
                                  </span>
                                  {p.workRole === "External" ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {dLead ? (
                                    <span className="custom-badge red-o direct-lead">
                                      LEAD
                                    </span>
                                  ) : null}
                                  {udLead ? (
                                    <span className="custom-badge gray-o">
                                      LEAD
                                    </span>
                                  ) : null}
                                  {p.tags && p.tags.length > 0
                                    ? p.tags.map((r, indext) => (
                                        <span
                                          key={indext}
                                          onClick={() => toggleTagCards(r)}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                  <a
                                    onClick={() => setPepole(p)}
                                    className="card-edit"
                                  >{`${p.fname} ${p.lname}`}</a>
                                </div>
                              );
                            })
                          : null}
                        {e.coreMembers &&
                        e.coreMembers.Skills &&
                        e.coreMembers.Skills.length > 0
                          ? e.coreMembers.Skills.map((s, indexs) => {
                              if (specificData && (isEXT || isNO)) {
                                if (
                                  specificData.data.skill &&
                                  !specificData.data.skill.includes(s._id)
                                ) {
                                  return;
                                }
                              } else {
                                if (isTag || isEXT || isNO) {
                                  let Proceed = TagWiseSorting(
                                    s,
                                    isTag,
                                    isEXT,
                                    isNO,
                                    tagFilteredObj,
                                    extNoFilteredObj,
                                    "skill"
                                  );
                                  if (!Proceed) return;
                                }
                              }
                              let isExternal = IsOwnerExternal(s, "Skill");
                              let dLead = false;
                              if (
                                cardData &&
                                Object.keys(cardData).length > 0
                              ) {
                                if (
                                  cardData.rAndS &&
                                  cardData.rAndS.length > 0 &&
                                  cardData.rAndS === s._id
                                ) {
                                  if (cardData.curType === "Skills") {
                                    dLead = true;
                                  }
                                }
                              }
                              let allOwner = getAllOwnerFCM(s, e._id, "core");
                              return (
                                <div key={indexs} className="card">
                                  <span className="custom-badge lightgray">
                                    Skill
                                  </span>
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {s.owners && s.owners.length === 0 ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  ) : null}
                                  {s.ownerType &&
                                  s.ownerType.length > 0 &&
                                  e.ownerType === "Single owner" ? (
                                    <span className="custom-badge blue-o single-owner-pill">
                                      1
                                    </span>
                                  ) : null}
                                  {dLead ? (
                                    <span className="custom-badge red-o direct-lead">
                                      LEAD
                                    </span>
                                  ) : null}
                                  {s.tags && s.tags.length > 0
                                    ? s.tags.map((r, index) => (
                                        <span
                                          key={index}
                                          onClick={() => toggleTagCards(r)}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                  <a
                                    className="card-edit"
                                    onClick={() => setSkill(s)}
                                  >
                                    {s.skillName}
                                  </a>
                                  <br />
                                  {allOwner && allOwner.length > 0
                                    ? allOwner.map((value, pdindex) => {
                                        return (
                                          <span
                                            key={pdindex}
                                            onClick={() =>
                                              OpenCFPeople(value._id)
                                            }
                                            className="custom-badge tags blue-text"
                                          >{`${value.fname} ${value.lname}`}</span>
                                        );
                                      })
                                    : null}
                                </div>
                              );
                            })
                          : null}
                        {e.coreMembers &&
                        e.coreMembers.Roles &&
                        e.coreMembers.Roles.length > 0
                          ? e.coreMembers.Roles.map((r, indexs) => {
                              if (specificData && (isEXT || isNO)) {
                                if (
                                  specificData.data.role &&
                                  !specificData.data.role.includes(r._id)
                                ) {
                                  return;
                                }
                              } else {
                                if (isTag || isEXT || isNO) {
                                  let Proceed = TagWiseSorting(
                                    r,
                                    isTag,
                                    isEXT,
                                    isNO,
                                    tagFilteredObj,
                                    extNoFilteredObj,
                                    "role"
                                  );
                                  if (!Proceed) return;
                                }
                              }

                              let isExternal = IsOwnerExternal(r, "Role");
                              let dLead = false;
                              if (
                                cardData &&
                                Object.keys(cardData).length > 0
                              ) {
                                if (
                                  cardData.rAndS &&
                                  cardData.rAndS.length > 0 &&
                                  cardData.rAndS === r._id
                                ) {
                                  if (cardData.curType === "Roles") {
                                    dLead = true;
                                  }
                                }
                              }

                              let allOwner = getAllOwnerFCM(r, e._id, "core");
                              return (
                                <div key={indexs} className="card">
                                  <span className="custom-badge lightgray">
                                    Role
                                  </span>
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {r.owners && r.owners.length === 0 ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  ) : null}
                                  {r.ownerRole.length > 0 &&
                                  e.ownerRole === "Single owner" ? (
                                    <span className="custom-badge blue-o single-owner-pill">
                                      1
                                    </span>
                                  ) : null}
                                  {dLead ? (
                                    <span className="custom-badge red-o direct-lead">
                                      LEAD
                                    </span>
                                  ) : null}
                                  {r.tags && r.tags.length > 0
                                    ? r.tags.map((t, indext) => (
                                        <span
                                          key={indext}
                                          onClick={() => toggleTagCards(t)}
                                          className="custom-badge green tags"
                                        >
                                          {t}
                                        </span>
                                      ))
                                    : null}
                                  <a
                                    className="card-edit"
                                    onClick={() => setRole(r)}
                                  >
                                    {r.roleName}
                                  </a>
                                  <br />
                                  {allOwner && allOwner.length > 0
                                    ? allOwner.map((value, pdindex) => {
                                        return (
                                          <span
                                            key={pdindex}
                                            onClick={() =>
                                              OpenCFPeople(value._id)
                                            }
                                            className="custom-badge tags blue-text"
                                          >{`${value.fname} ${value.lname}`}</span>
                                        );
                                      })
                                    : null}
                                </div>
                              );
                            })
                          : null}
                        {e.coreMembers &&
                        e.coreMembers.Domains &&
                        e.coreMembers.Domains.length > 0
                          ? e.coreMembers.Domains.map((d, indexs) => {
                              if (specificData && (isEXT || isNO)) {
                                if (
                                  specificData.data.domain &&
                                  !specificData.data.domain.includes(d._id)
                                ) {
                                  return;
                                }
                              } else {
                                if (isTag || isEXT || isNO) {
                                  let Proceed = TagWiseSorting(
                                    d,
                                    isTag,
                                    isEXT,
                                    isNO,
                                    tagFilteredObj,
                                    extNoFilteredObj,
                                    "domain"
                                  );
                                  if (!Proceed) return;
                                }
                              }

                              let curOwner = {};
                              if (
                                d &&
                                Object.keys(d).length > 0 &&
                                d.owners &&
                                d.owners.owner &&
                                d.owners.owner.length > 0
                              ) {
                                if (peopleSorted && peopleSorted.length > 0) {
                                  for (
                                    let i = 0;
                                    i < peopleSorted.length;
                                    i++
                                  ) {
                                    if (
                                      peopleSorted[i]._id === d.owners.owner
                                    ) {
                                      curOwner = peopleSorted[i];
                                      break;
                                    }
                                  }
                                }
                              }
                              let isExternal = IsOwnerExternal(d, "Domain");
                              let ownerType = getOwnerTypeFCM(d);
                              return (
                                <div key={indexs} className="card">
                                  <span className="custom-badge lightgray">
                                    Domain
                                  </span>
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {d.owners &&
                                  d.owners.owner &&
                                  d.owners.owner.length > 0 ? null : (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  )}
                                  {d.tags && d.tags.length > 0
                                    ? d.tags.map((t, indext) => (
                                        <span
                                          key={indext}
                                          onClick={() => toggleTagCards(t)}
                                          className="custom-badge green tags"
                                        >
                                          {t}
                                        </span>
                                      ))
                                    : null}
                                  <a
                                    className="card-edit"
                                    onClick={() => setDomain(d)}
                                  >
                                    {d.domainName}
                                  </a>
                                  {ownerType && ownerType.name ? (
                                    <p
                                      onClick={() =>
                                        cardOpensCfDomain(ownerType)
                                      }
                                      className="owner-type"
                                    >{`${ownerType.name} (${ownerType.type})`}</p>
                                  ) : null}
                                  {curOwner &&
                                  Object.keys(curOwner).length > 0 ? (
                                    <span
                                      onClick={() => OpenCFPeople(curOwner._id)}
                                      className="custom-badge tags blue-text"
                                    >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                  ) : null}
                                </div>
                              );
                            })
                          : null}
                        {e.coreMembers &&
                        e.coreMembers.Links &&
                        e.coreMembers.Links.length > 0
                          ? e.coreMembers.Links.map((l, indexs) => {
                              if (specificData && (isEXT || isNO)) {
                                if (
                                  specificData.data.link &&
                                  !specificData.data.link.includes(l._id)
                                ) {
                                  return;
                                }
                              } else {
                                if (isTag || isEXT || isNO) {
                                  let Proceed = TagWiseSorting(
                                    l,
                                    isTag,
                                    isEXT,
                                    isNO,
                                    tagFilteredObj,
                                    extNoFilteredObj,
                                    "link"
                                  );
                                  if (!Proceed) return;
                                }
                              }

                              let isExternal = IsOwnerExternal(l, "Link");
                              let curOwner = {};
                              if (
                                l &&
                                Object.keys(l).length > 0 &&
                                l.owner &&
                                l.owner.length > 0
                              ) {
                                if (peopleSorted && peopleSorted.length > 0) {
                                  for (
                                    let i = 0;
                                    i < peopleSorted.length;
                                    i++
                                  ) {
                                    if (peopleSorted[i]._id === l.owner) {
                                      curOwner = peopleSorted[i];
                                      break;
                                    }
                                  }
                                }
                              }

                              return (
                                <div key={indexs} className="card">
                                  <span className="custom-badge lightgray">
                                    Link
                                  </span>
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {l.owner && l.owner.length > 0 ? null : (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  )}
                                  {l.tags && l.tags.length > 0
                                    ? l.tags.map((t, indext) => (
                                        <span
                                          key={indext}
                                          onClick={() => toggleTagCards(t)}
                                          className="custom-badge green tags"
                                        >
                                          {t}
                                        </span>
                                      ))
                                    : null}
                                  <a
                                    className="card-edit"
                                    onClick={() => setLink(l)}
                                  >
                                    {l.linkName}
                                  </a>
                                  {curOwner &&
                                  Object.keys(curOwner).length > 0 ? (
                                    <span
                                      onClick={() => OpenCFPeople(curOwner._id)}
                                      className="custom-badge tags blue-text"
                                    >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                  ) : null}
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                    <h6 style={{ borderBottom: "1px solid #000" }}>
                      Extended members
                    </h6>
                    <div className="panel panel-default">
                      <div
                        id="extended"
                        className="panel-body single box-container"
                      >
                        {e.extendedMembers &&
                        e.extendedMembers.People &&
                        e.extendedMembers.People.length > 0
                          ? e.extendedMembers.People.map((p, indexs) => {
                              if (specificData && (isEXT || isNO)) {
                                if (
                                  specificData.data.people &&
                                  !specificData.data.people.includes(p._id)
                                ) {
                                  return;
                                }
                              } else {
                                if (isTag || isEXT || isNO) {
                                  let Proceed = TagWiseSorting(
                                    e,
                                    isTag,
                                    isEXT,
                                    isNO,
                                    tagFilteredObj,
                                    extNoFilteredObj,
                                    "people"
                                  );
                                  if (!Proceed) return;
                                }
                              }

                              let dLead = false;
                              let udLead = false;
                              if (
                                cardData &&
                                Object.keys(cardData).length > 0
                              ) {
                                if (
                                  cardData.people &&
                                  cardData.people.length > 0 &&
                                  cardData.people === p._id
                                ) {
                                  if (
                                    cardData.curType === "People" &&
                                    cardData.rAndS === null
                                  ) {
                                    dLead = true;
                                  } else if (
                                    (cardData.curType === "Roles" ||
                                      cardData.curType === "Skills") &&
                                    cardData.rAndS
                                  ) {
                                    udLead = true;
                                  }
                                }
                              }
                              // console.log(21814, p)
                              return (
                                <div key={indexs} className="card">
                                  <span className="custom-badge lightgray">
                                    Person
                                  </span>
                                  {p.workRole === "External" ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {dLead ? (
                                    <span className="custom-badge red-o direct-lead">
                                      LEAD
                                    </span>
                                  ) : null}
                                  {udLead ? (
                                    <span className="custom-badge gray-o">
                                      LEAD
                                    </span>
                                  ) : null}
                                  {p.tags && p.tags.length > 0
                                    ? p.tags.map((r, indext) => (
                                        <span
                                          key={indext}
                                          onClick={() => toggleTagCards(r)}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                  <a
                                    onClick={() => setPepole(p)}
                                    className="card-edit"
                                  >{`${p.fname} ${p.lname}`}</a>
                                </div>
                              );
                            })
                          : null}
                        {e.extendedMembers &&
                        e.extendedMembers.Skills &&
                        e.extendedMembers.Skills.length > 0
                          ? e.extendedMembers.Skills.map((s, indexs) => {
                              if (specificData && (isEXT || isNO)) {
                                if (
                                  specificData.data.skill &&
                                  !specificData.data.skill.includes(s._id)
                                ) {
                                  return;
                                }
                              } else {
                                if (isTag || isEXT || isNO) {
                                  let Proceed = TagWiseSorting(
                                    s,
                                    isTag,
                                    isEXT,
                                    isNO,
                                    tagFilteredObj,
                                    extNoFilteredObj,
                                    "skill"
                                  );
                                  if (!Proceed) return;
                                }
                              }

                              let isExternal = IsOwnerExternal(s, "Skill");
                              let dLead = false;
                              if (
                                cardData &&
                                Object.keys(cardData).length > 0
                              ) {
                                if (
                                  cardData.rAndS &&
                                  cardData.rAndS.length > 0 &&
                                  cardData.rAndS === s._id
                                ) {
                                  if (cardData.curType === "Skills") {
                                    dLead = true;
                                  }
                                }
                              }
                              let allOwner = getAllOwnerFCM(
                                s,
                                e._id,
                                "extended"
                              );
                              return (
                                <div key={indexs} className="card">
                                  <span className="custom-badge lightgray">
                                    Skill
                                  </span>
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {s.owners && s.owners.length === 0 ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  ) : null}
                                  {s.ownerType &&
                                  s.ownerType.length > 0 &&
                                  e.ownerType === "Single owner" ? (
                                    <span className="custom-badge blue-o single-owner-pill">
                                      1
                                    </span>
                                  ) : null}
                                  {dLead ? (
                                    <span className="custom-badge red-o direct-lead">
                                      LEAD
                                    </span>
                                  ) : null}
                                  {s.tags && s.tags.length > 0
                                    ? s.tags.map((r, index) => (
                                        <span
                                          key={index}
                                          onClick={() => toggleTagCards(r)}
                                          className="custom-badge green tags"
                                        >
                                          {r}
                                        </span>
                                      ))
                                    : null}
                                  <a
                                    className="card-edit"
                                    onClick={() => setSkill(s)}
                                  >
                                    {s.skillName}
                                  </a>
                                  <br />
                                  {allOwner && allOwner.length > 0
                                    ? allOwner.map((value, pdindex) => {
                                        return (
                                          <span
                                            key={pdindex}
                                            onClick={() =>
                                              OpenCFPeople(value._id)
                                            }
                                            className="custom-badge tags blue-text"
                                          >{`${value.fname} ${value.lname}`}</span>
                                        );
                                      })
                                    : null}
                                </div>
                              );
                            })
                          : null}
                        {e.extendedMembers &&
                        e.extendedMembers.Roles &&
                        e.extendedMembers.Roles.length > 0
                          ? e.extendedMembers.Roles.map((r, indexs) => {
                              if (specificData && (isEXT || isNO)) {
                                if (
                                  specificData.data.role &&
                                  !specificData.data.role.includes(r._id)
                                ) {
                                  return;
                                }
                              } else {
                                if (isTag || isEXT || isNO) {
                                  let Proceed = TagWiseSorting(
                                    r,
                                    isTag,
                                    isEXT,
                                    isNO,
                                    tagFilteredObj,
                                    extNoFilteredObj,
                                    "role"
                                  );
                                  if (!Proceed) return;
                                }
                              }

                              let isExternal = IsOwnerExternal(r, "Role");
                              let dLead = false;
                              if (
                                cardData &&
                                Object.keys(cardData).length > 0
                              ) {
                                if (
                                  cardData.rAndS &&
                                  cardData.rAndS.length > 0 &&
                                  cardData.rAndS === r._id
                                ) {
                                  if (cardData.curType === "Roles") {
                                    dLead = true;
                                  }
                                }
                              }

                              let allOwner = getAllOwnerFCM(
                                r,
                                e._id,
                                "extended"
                              );
                              return (
                                <div key={indexs} className="card">
                                  <span className="custom-badge lightgray">
                                    Role
                                  </span>
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {r.owners && r.owners.length === 0 ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  ) : null}
                                  {r.ownerRole.length > 0 &&
                                  e.ownerRole === "Single owner" ? (
                                    <span className="custom-badge blue-o single-owner-pill">
                                      1
                                    </span>
                                  ) : null}
                                  {dLead ? (
                                    <span className="custom-badge red-o direct-lead">
                                      LEAD
                                    </span>
                                  ) : null}
                                  {r.tags && r.tags.length > 0
                                    ? r.tags.map((t, indext) => (
                                        <span
                                          key={indext}
                                          onClick={() => toggleTagCards(t)}
                                          className="custom-badge green tags"
                                        >
                                          {t}
                                        </span>
                                      ))
                                    : null}
                                  <a
                                    className="card-edit"
                                    onClick={() => setRole(r)}
                                  >
                                    {r.roleName}
                                  </a>
                                  <br />
                                  {allOwner && allOwner.length > 0
                                    ? allOwner.map((value, pdindex) => {
                                        return (
                                          <span
                                            key={pdindex}
                                            onClick={() =>
                                              OpenCFPeople(value._id)
                                            }
                                            className="custom-badge tags blue-text"
                                          >{`${value.fname} ${value.lname}`}</span>
                                        );
                                      })
                                    : null}
                                </div>
                              );
                            })
                          : null}

                        {e.extendedMembers &&
                        e.extendedMembers.Domains &&
                        e.extendedMembers.Domains.length > 0
                          ? e.extendedMembers.Domains.map((d, indexs) => {
                              if (specificData && (isEXT || isNO)) {
                                if (
                                  specificData.data.domain &&
                                  !specificData.data.domain.includes(d._id)
                                ) {
                                  return;
                                }
                              } else {
                                if (isTag || isEXT || isNO) {
                                  let Proceed = TagWiseSorting(
                                    d,
                                    isTag,
                                    isEXT,
                                    isNO,
                                    tagFilteredObj,
                                    extNoFilteredObj,
                                    "domain"
                                  );
                                  if (!Proceed) return;
                                }
                              }

                              let curOwner = {};
                              if (
                                d &&
                                Object.keys(d).length > 0 &&
                                d.owners &&
                                d.owners.owner &&
                                d.owners.owner.length > 0
                              ) {
                                if (peopleSorted && peopleSorted.length > 0) {
                                  for (
                                    let i = 0;
                                    i < peopleSorted.length;
                                    i++
                                  ) {
                                    if (
                                      peopleSorted[i]._id === d.owners.owner
                                    ) {
                                      curOwner = peopleSorted[i];
                                      break;
                                    }
                                  }
                                }
                              }
                              let isExternal = IsOwnerExternal(d, "Domain");
                              let ownerType = getOwnerTypeFCM(d);
                              return (
                                <div key={indexs} className="card">
                                  <span className="custom-badge lightgray">
                                    Domain
                                  </span>
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {d.owners &&
                                  d.owners.owner &&
                                  d.owners.owner.length > 0 ? null : (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  )}
                                  {d.tags && d.tags.length > 0
                                    ? d.tags.map((t, indext) => (
                                        <span
                                          key={indext}
                                          onClick={() => toggleTagCards(t)}
                                          className="custom-badge green tags"
                                        >
                                          {t}
                                        </span>
                                      ))
                                    : null}
                                  <a
                                    className="card-edit"
                                    onClick={() => setDomain(d)}
                                  >
                                    {d.domainName}
                                  </a>
                                  {ownerType && ownerType.name ? (
                                    <p
                                      onClick={() =>
                                        cardOpensCfDomain(ownerType)
                                      }
                                      className="owner-type"
                                    >{`${ownerType.name} (${ownerType.type})`}</p>
                                  ) : null}
                                  {curOwner &&
                                  Object.keys(curOwner).length > 0 ? (
                                    <span
                                      onClick={() => OpenCFPeople(curOwner._id)}
                                      className="custom-badge tags blue-text"
                                    >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                  ) : null}
                                </div>
                              );
                            })
                          : null}
                        {e.extendedMembers &&
                        e.extendedMembers.Links &&
                        e.extendedMembers.Links.length > 0
                          ? e.extendedMembers.Links.map((l, indexs) => {
                              if (specificData && (isEXT || isNO)) {
                                if (
                                  specificData.data.link &&
                                  !specificData.data.link.includes(l._id)
                                ) {
                                  return;
                                }
                              } else {
                                if (isTag || isEXT || isNO) {
                                  let Proceed = TagWiseSorting(
                                    l,
                                    isTag,
                                    isEXT,
                                    isNO,
                                    tagFilteredObj,
                                    extNoFilteredObj,
                                    "link"
                                  );
                                  if (!Proceed) return;
                                }
                              }

                              let isExternal = IsOwnerExternal(l, "Link");
                              let curOwner = {};
                              if (
                                l &&
                                Object.keys(l).length > 0 &&
                                l.owner &&
                                l.owner.length > 0
                              ) {
                                if (peopleSorted && peopleSorted.length > 0) {
                                  for (
                                    let i = 0;
                                    i < peopleSorted.length;
                                    i++
                                  ) {
                                    if (peopleSorted[i]._id === l.owner) {
                                      curOwner = peopleSorted[i];
                                      break;
                                    }
                                  }
                                }
                              }

                              return (
                                <div key={indexs} className="card">
                                  <span className="custom-badge lightgray">
                                    Link
                                  </span>
                                  {isExternal ? (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "EXT",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge blue ext-owner-pill"
                                    >
                                      EXT
                                    </span>
                                  ) : null}
                                  {l.owner && l.owner.length > 0 ? null : (
                                    <span
                                      onClick={() => {
                                        toggleEXTnNOCards(
                                          e._id,
                                          "NO",
                                          false,
                                          "pill"
                                        );
                                      }}
                                      className="custom-badge no-owner-pill red"
                                    >
                                      NO
                                    </span>
                                  )}
                                  {l.tags && l.tags.length > 0
                                    ? l.tags.map((t, indext) => (
                                        <span
                                          key={indext}
                                          onClick={() => toggleTagCards(t)}
                                          className="custom-badge green tags"
                                        >
                                          {t}
                                        </span>
                                      ))
                                    : null}
                                  <a
                                    className="card-edit"
                                    onClick={() => setLink(l)}
                                  >
                                    {l.linkName}
                                  </a>
                                  {curOwner &&
                                  Object.keys(curOwner).length > 0 ? (
                                    <span
                                      onClick={() => OpenCFPeople(curOwner._id)}
                                      className="custom-badge tags blue-text"
                                    >{`${curOwner.fname} ${curOwner.lname}`}</span>
                                  ) : null}
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                    <h6 style={{ borderBottom: "1px solid #000" }}>Circle</h6>
                    <div className="panel panel-default">
                      <div
                        id="notmatched"
                        className="panel-body single box-container"
                      >
                        {e.coreMembers &&
                        e.coreMembers.Circles &&
                        e.coreMembers.Circles.length > 0
                          ? e.coreMembers.Circles.map((c, indexs) => {
                              if (isTag || isEXT || isNO) {
                                let Proceed = TagWiseSorting(
                                  c,
                                  isTag,
                                  isEXT,
                                  isNO,
                                  tagFilteredObj,
                                  extNoFilteredObj,
                                  "circle"
                                );
                                if (!Proceed) return;
                              }
                              return (
                                <div key={indexs} className="card">
                                  {/* <span className="custom-badge lightgray">
                                Circle
                              </span> */}
                                  {c.tags && c.tags.length > 0
                                    ? c.tags.map((t, indext) => (
                                        <span
                                          key={indext}
                                          onClick={() => toggleTagCards(t)}
                                          className="custom-badge green tags"
                                        >
                                          {t}
                                        </span>
                                      ))
                                    : null}
                                  <a
                                    className="card-edit"
                                    onClick={() => setCircle(c)}
                                  >
                                    {c.circleName}
                                  </a>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                    <h6
                      style={{ borderBottom: "1px solid #000" }}
                      className="meetings-heading"
                    >
                      Meetings
                    </h6>
                    <div className="panel panel-default">
                      <div
                        id="meetings"
                        className="panel-body single box-container"
                      >
                        {meetingsData && meetingsData.length > 0
                          ? meetingsData.map((m, indexp) => {
                              return (
                                <div className="card meeting-card ">
                                  <div className="meeting-content">
                                    <p>
                                      <span className="fas fa-users"></span>
                                      <b style={{ marginLeft: "5px" }}>
                                        {m.meetingsName}
                                      </b>
                                    </p>
                                    <p>{m.meetingsPurpose}</p>
                                    {m.recurrenceType === "As needed" ||
                                    m.durationType === "As needed" ? (
                                      <>
                                        <p>
                                          {m.recurrenceType} {m.durationType}
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        {m.recurrence && (
                                          <p>
                                            Every {m.recurrence}{" "}
                                            {m.recurrenceType}{" "}
                                            {m.duration && (
                                              <span>
                                                for {m.duration}{" "}
                                                {m.durationType}
                                              </span>
                                            )}
                                          </p>
                                        )}
                                      </>
                                    )}
                                    <p>{m.XTD ? "incl. XTD" : null}</p>
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );

  /* '''''''''''''''''''''''''''''''''''''''' Project Mode XLS And JPEG Downlod ''''''''''''''''''''''''''''''''''''''''''''''''' */
  let xlsPMData = [];
  let xlsPMList = [];

  if (focusMode.length > 0 && focusMode === "Project" && !isModeSwitched) {
    let obj = {};
    for (let i = 0; i < curPModeData.length; i++) {
      let curProjectData = [];

      if (curPModeData[i].coreMembers) {
        if (
          curPModeData[i].coreMembers.People &&
          curPModeData[i].coreMembers.People.length > 0
        ) {
          for (let j = 0; j < curPModeData[i].coreMembers.People.length; j++) {
            curProjectData.push(
              `${curPModeData[i].coreMembers.People[j].fname} ${curPModeData[i].coreMembers.People[j].lname}`
            );
          }
        }
        if (
          curPModeData[i].coreMembers.Skills &&
          curPModeData[i].coreMembers.Skills.length > 0
        ) {
          for (let j = 0; j < curPModeData[i].coreMembers.Skills.length; j++) {
            curProjectData.push(
              `${curPModeData[i].coreMembers.Skills[j].skillName}`
            );
          }
        }
        if (
          curPModeData[i].coreMembers.Roles &&
          curPModeData[i].coreMembers.Roles.length > 0
        ) {
          for (let j = 0; j < curPModeData[i].coreMembers.Roles.length; j++) {
            curProjectData.push(
              `${curPModeData[i].coreMembers.Roles[j].roleName}`
            );
          }
        }
        if (
          curPModeData[i].coreMembers.Links &&
          curPModeData[i].coreMembers.Links.length > 0
        ) {
          for (let j = 0; j < curPModeData[i].coreMembers.Links.length; j++) {
            curProjectData.push(
              `${curPModeData[i].coreMembers.Links[j].linkName}`
            );
          }
        }
        if (
          curPModeData[i].coreMembers.Domains &&
          curPModeData[i].coreMembers.Domains.length > 0
        ) {
          for (let j = 0; j < curPModeData[i].coreMembers.Domains.length; j++) {
            curProjectData.push(
              `${curPModeData[i].coreMembers.Domains[j].domainName}`
            );
          }
        }
        if (
          curPModeData[i].coreMembers.Projects &&
          curPModeData[i].coreMembers.Projects.length > 0
        ) {
          for (
            let j = 0;
            j < curPModeData[i].coreMembers.Projects.length;
            j++
          ) {
            curProjectData.push(
              `${curPModeData[i].coreMembers.Projects[j].projectName}`
            );
          }
        }
      }
      if (curPModeData[i].extendedMembers) {
        if (
          curPModeData[i].extendedMembers.People &&
          curPModeData[i].extendedMembers.People.length > 0
        ) {
          for (
            let j = 0;
            j < curPModeData[i].extendedMembers.People.length;
            j++
          ) {
            curProjectData.push(
              `${curPModeData[i].extendedMembers.People[j].fname} ${curPModeData[i].extendedMembers.People[j].lname}`
            );
          }
        }
        if (
          curPModeData[i].extendedMembers.Skills &&
          curPModeData[i].extendedMembers.Skills.length > 0
        ) {
          for (
            let j = 0;
            j < curPModeData[i].extendedMembers.Skills.length;
            j++
          ) {
            curProjectData.push(
              `${curPModeData[i].extendedMembers.Skills[j].skillName}`
            );
          }
        }
        if (
          curPModeData[i].extendedMembers.Roles &&
          curPModeData[i].extendedMembers.Roles.length > 0
        ) {
          for (
            let j = 0;
            j < curPModeData[i].extendedMembers.Roles.length;
            j++
          ) {
            curProjectData.push(
              `${curPModeData[i].extendedMembers.Roles[j].roleName}`
            );
          }
        }
        if (
          curPModeData[i].extendedMembers.Links &&
          curPModeData[i].extendedMembers.Links.length > 0
        ) {
          for (
            let j = 0;
            j < curPModeData[i].extendedMembers.Links.length;
            j++
          ) {
            curProjectData.push(
              `${curPModeData[i].extendedMembers.Links[j].linkName}`
            );
          }
        }
        if (
          curPModeData[i].extendedMembers.Domains &&
          curPModeData[i].extendedMembers.Domains.length > 0
        ) {
          for (
            let j = 0;
            j < curPModeData[i].extendedMembers.Domains.length;
            j++
          ) {
            curProjectData.push(
              `${curPModeData[i].extendedMembers.Domains[j].domainName}`
            );
          }
        }
      }
      if (
        curProjectData &&
        curProjectData.length > 0 &&
        curPModeData[i] &&
        curPModeData[i].projectName &&
        curPModeData[i].projectName.length > 0
      ) {
        xlsPMList.push(curPModeData[i].projectName);
        obj[i] = curProjectData;
      }
    }

    if (Object.keys(obj).length > 0) {
      let arr = [];
      for (let l = 0; l < Object.keys(obj).length; l++) {
        if (obj[l] && obj[l].length > -1) {
          arr.push(obj[l].length);
        }
      }
      if (arr && arr.length > 0) {
        const max = arr.reduce((a, b) => {
          return Math.max(a, b);
        });
        for (let k = 0; k < max; k++) {
          let xlsObj = {};
          for (let b = 0; b < Object.keys(obj).length; b++) {
            if (obj[b] && obj[b].length > -1) {
              xlsObj[b] = obj[b][k] ? obj[b][k] : "";
            }
          }
          xlsPMData.push(xlsObj);
        }
      }
    }
  }

  useEffect(() => {
    if (
      focusMode &&
      focusMode.length > 0 &&
      focusMode === "Project" &&
      !isModeSwitched
    ) {
      setXlsCM(xlsPMData);
      setXlsName(xlsPMList);
    }
  }, [focusMode, isModeSwitched]);

  /* ============================================== Circle As People Model ========================================== */

  let peopleAsCDatas = [...peopleAsCData];

  if (focusMode === "Circle" && isModeSwitched) {
    // console.log('Circle mode is switched to People');

    let curpeopleAsC = [];
    peopleAsCDatas = peopleAsCData.filter((e) => {
      return !fList[e.circleName];
    });
    // console.log('peopleAsCDatas', peopleAsCDatas)
    // console.log('focusList', focusList)
    if (focusList && focusList.length > 0) {
      for (let i = 0; i < focusList.length; i++) {
        for (let j = 0; j < peopleAsCDatas.length; j++) {
          if (focusList[i].id === peopleAsCDatas[j]._id) {
            curpeopleAsC.push(peopleAsCDatas[j]);
          }
        }
      }
    }
    if (curpeopleAsC.length > 0) {
      peopleAsCDatas = curpeopleAsC;
    }
  }

  // console.log(peopleAsCDatas)
  const CircleAsPeopleMode = () => (
    <div className="wrap-lists" id="circle-mode">
      {peopleAsCDatas && peopleAsCDatas.length > 0
        ? peopleAsCDatas.map((e, index) => {
            var meetingsData = [];
            if (e.meetings && e.meetings.length > 0) {
              meetingsData = cAndpADatas(e.meetings);
            }

            let hideColumn =
              Get_ShowHideColumn_Project_Circle_Mode_AddedAs_people(
                e,
                tagFilteredMegedData
              );
            if (
              tagFilteredMegedData &&
              Object.keys(tagFilteredMegedData).length > 0 &&
              hideColumn
            )
              return null;
            return (
              <div
                key={index}
                className="board-col board-col-circles"
                data-list_id="873"
                data-board_id="Circle two"
              >
                <div className="list list-bg-red" data-list_id="circles">
                  <div className="dropdown">
                    <h4 className="list-title">
                      <img alt="" src="images/icon-circle.png" />
                      {e.circleName}
                      <a
                        className="list-menu refresh-list hide"
                        href="#"
                        onClick={() => {
                          toggleEXTnNOCards(e._id, "", true);
                        }}
                      >
                        <i className="fas fa-sync-alt"></i>
                      </a>
                      <a
                        className="list-menu dropdown-toggle"
                        data-toggle="dropdown"
                        href="#"
                      >
                        {getSortIcon("Circles")}
                        <i className="fas fa-ellipsis-v"></i>
                      </a>
                      <ul
                        className="dropdown-menu dropdown-menu-right"
                        role="menu"
                      >
                        <li role="presentation">
                          <a
                            role="menuitem"
                            className="tool-from-mode"
                            data-list_id="circles"
                            data-card_name="Circle two"
                          >
                            Edit this circle
                          </a>
                        </li>
                        <li role="presentation">
                          <a
                            role="menuitem"
                            className="focus-from-mode"
                            data-list_id="circles"
                          >
                            Enter focus mode
                          </a>
                        </li>
                        <li role="presentation" className="divider"></li>
                        <li
                          role="presentation"
                          onClick={() => {
                            toggleEXTnNOCards(e._id, "EXT", false);
                          }}
                        >
                          <a role="menuitem" className="show-external">
                            Show items owned by external
                          </a>
                        </li>
                        <li
                          role="presentation"
                          onClick={() => {
                            toggleEXTnNOCards(e._id, "NO", false);
                          }}
                        >
                          <a role="menuitem" className="show-noowners">
                            Show items without owners
                          </a>
                        </li>
                      </ul>
                    </h4>
                  </div>

                  <div className="card-list only scrollbar">
                    <div className="circle-tags">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((tag, tindex) => {
                            return (
                              <span
                                key={tindex}
                                className="custom-badge green tags"
                              >
                                {tag}
                              </span>
                            );
                          })
                        : null}
                    </div>
                    {e.pepoleData && e.pepoleData.length > 0
                      ? e.pepoleData.map((p, indexs) => {
                          if (isTag || isEXT || isNO) {
                            let Proceed = TagWiseSorting(
                              p,
                              isTag,
                              isEXT,
                              isNO,
                              tagFilteredObj,
                              extNoFilteredObj,
                              "people"
                            );
                            if (!Proceed) return;
                          }
                          return (
                            <div key={indexs} className="card">
                              {p.workRole === "External" ? (
                                <span
                                  onClick={() => {
                                    toggleEXTnNOCards(
                                      e._id,
                                      "EXT",
                                      false,
                                      "pill"
                                    );
                                  }}
                                  className="custom-badge blue ext-owner-pill"
                                >
                                  EXT
                                </span>
                              ) : null}

                              {p.tags && p.tags.length > 0
                                ? p.tags.map((tag, tindex) => {
                                    return (
                                      <span
                                        key={tindex}
                                        onClick={() => toggleTagCards(tag)}
                                        className="custom-badge green tags"
                                      >
                                        {tag}
                                      </span>
                                    );
                                  })
                                : null}

                              {p.FildName && p.FildName.length > 0
                                ? p.FildName.map((f, indexf) => {
                                    return (
                                      <span
                                        key={indexf}
                                        className="custom-badge lightgray"
                                        onClick={() => cardOpens(f)}
                                      >
                                        {f.name}
                                      </span>
                                    );
                                  })
                                : null}

                              <a
                                className="card-edit"
                                onClick={() => setPepole(p)}
                              >{`${p.fname} ${p.lname}`}</a>
                            </div>
                          );
                        })
                      : null}

                    <h6
                      style={{ borderBottom: "1px solid #000" }}
                      className="meetings-heading"
                    >
                      Meetings
                    </h6>
                    <div className="panel panel-default">
                      <div
                        id="meetings"
                        className="panel-body single box-container"
                      >
                        {meetingsData && meetingsData.length > 0
                          ? meetingsData.map((m, indexm) => {
                              return (
                                <div className="card meeting-card ">
                                  <div className="meeting-content">
                                    <p>
                                      <span className="fas fa-users"></span>
                                      <b style={{ marginLeft: "5px" }}>
                                        {m.meetingsName}
                                      </b>
                                    </p>
                                    <p>{m.meetingsPurpose}</p>
                                    {m.recurrenceType === "As needed" ||
                                    m.durationType === "As needed" ? (
                                      <>
                                        <p>
                                          {m.recurrenceType} {m.durationType}
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        {m.recurrence && (
                                          <p>
                                            Every {m.recurrence}{" "}
                                            {m.recurrenceType}{" "}
                                            {m.duration && (
                                              <span>
                                                for {m.duration}{" "}
                                                {m.durationType}
                                              </span>
                                            )}
                                          </p>
                                        )}
                                      </>
                                    )}
                                    <p>{m.XTD ? "incl. XTD" : null}</p>
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );

  /* '''''''''''''''''''''''''''''''''''''''' XLS And JPEG Downlod ''''''''''''''''''''''''''''''''''''''''''''''''' */

  let xlsCMPASData = [];
  let xlsCMPAsList = [];

  if (focusMode.length > 0 && focusMode === "Circle" && isModeSwitched) {
    let obj = {};
    let count = 0;
    for (let i = 0; i < peopleAsCData.length; i++) {
      let xlsCMPAsName = [];
      if (
        peopleAsCData[i].pepoleData &&
        peopleAsCData[i].pepoleData.length > 0
      ) {
        for (let j = 0; j < peopleAsCData[i].pepoleData.length; j++) {
          xlsCMPAsName.push(
            `${peopleAsCData[i].pepoleData[j].fname} ${peopleAsCData[i].pepoleData[j].lname}`
          );
        }
        if (
          xlsCMPAsName.length > 0 &&
          peopleAsCData[i] &&
          peopleAsCData[i].circleName
        ) {
          xlsCMPAsList.push(peopleAsCData[i].circleName);
          obj[count] = xlsCMPAsName;
          count++;
        }
      }
    }
    if (Object.keys(obj).length > 0) {
      let arr = [];
      for (let l = 0; l < Object.keys(obj).length; l++) {
        arr.push(obj[l].length);
      }
      if (arr && arr.length > 0) {
        const max = arr.reduce((a, b) => {
          return Math.max(a, b);
        });
        for (let k = 0; k < max; k++) {
          let xlsObj = {};
          for (let b = 0; b < Object.keys(obj).length; b++) {
            xlsObj[b] = obj[b][k] ? obj[b][k] : "";
          }
          xlsCMPASData.push(xlsObj);
        }
      }
    }
  }

  useEffect(() => {
    if (
      focusMode &&
      focusMode.length > 0 &&
      focusMode === "Circle" &&
      isModeSwitched
    ) {
      setXlsCM(xlsCMPASData);
      setXlsName(xlsCMPAsList);
    }
  }, [focusMode, isModeSwitched]);

  let peopleAsPDatas = [...peopleAsPData];

  if (focusMode === "Project" && isModeSwitched) {
    let curpeopleAsP = [];
    peopleAsPDatas = peopleAsPData.filter((e) => {
      return !fList[e.projectName];
    });
    if (focusList && focusList.length > 0) {
      for (let i = 0; i < focusList.length; i++) {
        for (let j = 0; j < peopleAsPDatas.length; j++) {
          if (focusList[i].id === peopleAsPDatas[j]._id) {
            curpeopleAsP.push(peopleAsPDatas[j]);
          }
        }
      }
    }
    if (curpeopleAsP.length > 0) {
      peopleAsPDatas = curpeopleAsP;
    }
  }

  const ProjectAsPeopleMode = () => (
    <div className="wrap-lists" id="project-mode">
      {peopleAsPDatas && peopleAsPDatas.length > 0
        ? peopleAsPDatas.map((e, index) => {
            var meetingsData = [];
            if (e.meetings && e.meetings.length > 0) {
              meetingsData = cAndpADatas(e.meetings);
            }
            let hideColumn =
              Get_ShowHideColumn_Project_Circle_Mode_AddedAs_people(
                e,
                tagFilteredMegedData
              );
            if (
              tagFilteredMegedData &&
              Object.keys(tagFilteredMegedData).length > 0 &&
              hideColumn
            )
              return null;
            return (
              <div
                className="board-col board-col-circles"
                data-list_id={e._id}
                data-board_id={e.projectName}
                key={index}
              >
                <div className="list list-bg-red" data-list_id="projects">
                  <div className="dropdown">
                    <h4 className="list-title">
                      <img alt="" src="images/icon-project.png" />
                      {e.projectName}
                      <a
                        className="list-menu refresh-list hide"
                        href="#"
                        onClick={() => {
                          toggleEXTnNOCards(e._id, "", true);
                        }}
                      >
                        <i className="fas fa-sync-alt"></i>
                      </a>
                      <a
                        className="list-menu dropdown-toggle"
                        data-toggle="dropdown"
                        href="#"
                      >
                        {getSortIcon("Projects")}
                        <i className="fas fa-ellipsis-v"></i>
                      </a>
                      <ul
                        className="dropdown-menu dropdown-menu-right"
                        role="menu"
                      >
                        <li role="presentation">
                          <a role="menuitem" className="tool-from-mode"></a>
                        </li>
                        <li role="presentation">
                          <a
                            role="menuitem"
                            className="focus-from-mode"
                            data-list_id="circles"
                          >
                            Enter focus mode
                          </a>
                        </li>
                        <li role="presentation" className="divider"></li>
                        <li
                          role="presentation"
                          onClick={() => {
                            toggleEXTnNOCards(e._id, "EXT", false);
                          }}
                        >
                          <a role="menuitem" className="show-external">
                            Show items owned by external
                          </a>
                        </li>
                        <li
                          role="presentation"
                          onClick={() => {
                            toggleEXTnNOCards(e._id, "NO", false);
                          }}
                        >
                          <a role="menuitem" className="show-noowners">
                            Show items without owners
                          </a>
                        </li>
                      </ul>
                    </h4>
                  </div>

                  <div className="card-list only scrollbar">
                    <div className="circle-tags">
                      {e.tags && e.tags.length > 0
                        ? e.tags.map((tag) => {
                            return (
                              <span className="custom-badge green tags">
                                {tag}
                              </span>
                            );
                          })
                        : null}
                    </div>

                    {e.pepoleData && e.pepoleData.length > 0
                      ? e.pepoleData.map((p, indexs) => {
                          if (isTag || isEXT || isNO) {
                            let Proceed = TagWiseSorting(
                              p,
                              isTag,
                              isEXT,
                              isNO,
                              tagFilteredObj,
                              extNoFilteredObj,
                              "people"
                            );
                            if (!Proceed) return;
                          }
                          return (
                            <div key={indexs} className="card">
                              {p.workRole === "External" ? (
                                <span
                                  onClick={() => {
                                    toggleEXTnNOCards(
                                      e._id,
                                      "EXT",
                                      false,
                                      "pill"
                                    );
                                  }}
                                  className="custom-badge blue ext-owner-pill"
                                >
                                  EXT
                                </span>
                              ) : null}

                              {p.tags && p.tags.length > 0
                                ? p.tags.map((tag, tindex) => {
                                    return (
                                      <span
                                        key={tindex}
                                        onClick={() => toggleTagCards(tag)}
                                        className="custom-badge green tags"
                                      >
                                        {tag}
                                      </span>
                                    );
                                  })
                                : null}

                              {p.FildName && p.FildName.length > 0
                                ? p.FildName.map((f, indexf) => {
                                    return (
                                      <span
                                        className="custom-badge lightgray"
                                        onClick={() => cardOpens(f)}
                                      >
                                        {f.name}
                                      </span>
                                    );
                                  })
                                : null}

                              <a
                                className="card-edit"
                                onClick={() => setPepole(p)}
                              >{`${p.fname} ${p.lname}`}</a>
                            </div>
                          );
                        })
                      : null}

                    <h6
                      style={{ borderBottom: "1px solid #000" }}
                      className="meetings-heading"
                    >
                      Meetings
                    </h6>
                    <div className="panel panel-default">
                      <div
                        id="meetings"
                        className="panel-body single box-container"
                      >
                        {meetingsData && meetingsData.length > 0
                          ? meetingsData.map((m, indexm) => {
                              return (
                                <div className="card meeting-card ">
                                  <div className="meeting-content">
                                    <p>
                                      <span className="fas fa-users"></span>
                                      <b style={{ marginLeft: "5px" }}>
                                        {m.meetingsName}
                                      </b>
                                    </p>
                                    <p>{m.meetingsPurpose}</p>
                                    {m.recurrenceType === "As needed" ||
                                    m.durationType === "As needed" ? (
                                      <>
                                        <p>
                                          {m.recurrenceType} {m.durationType}
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        {m.recurrence && (
                                          <p>
                                            Every {m.recurrence}{" "}
                                            {m.recurrenceType}{" "}
                                            {m.duration && (
                                              <span>
                                                for {m.duration}{" "}
                                                {m.durationType}
                                              </span>
                                            )}
                                          </p>
                                        )}
                                      </>
                                    )}
                                    <p>{m.XTD ? "incl. XTD" : null}</p>
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                    {/* <!-- <a className="btn-list add-member" data-id="1" data-list_id="projects">+ Add new member</a> --> */}
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );

  /* '''''''''''''''''''''''''''''''''''''''' XLS And JPEG Downlod ''''''''''''''''''''''''''''''''''''''''''''''''' */

  let xlsPMPASData = [];
  let xlsPMPAsList = [];

  if (focusMode.length > 0 && focusMode === "Project" && isModeSwitched) {
    let obj = {};
    let count = 0;
    for (let i = 0; i < peopleAsPData.length; i++) {
      let xlsPMPAsName = [];
      if (
        peopleAsPData[i].pepoleData &&
        peopleAsPData[i].pepoleData.length > 0
      ) {
        for (let j = 0; j < peopleAsPData[i].pepoleData.length; j++) {
          xlsPMPAsName.push(
            `${peopleAsPData[i].pepoleData[j].fname} ${peopleAsPData[i].pepoleData[j].lname}`
          );
        }
        if (
          xlsPMPAsName.length > 0 &&
          peopleAsPData[i] &&
          peopleAsPData[i].projectName
        ) {
          xlsPMPAsList.push(peopleAsPData[i].projectName);
          obj[count] = xlsPMPAsName;
          count++;
        }
      }
    }
    if (Object.keys(obj) && Object.keys(obj).length > 0) {
      let arr = [];
      for (let l = 0; l < Object.keys(obj).length; l++) {
        arr.push(obj[l].length);
      }
      if (arr && arr.length > 0) {
        const max = arr.reduce((a, b) => {
          return Math.max(a, b);
        });
        for (let k = 0; k < max; k++) {
          let xlsObj = {};
          for (let b = 0; b < Object.keys(obj).length; b++) {
            xlsObj[b] = obj[b][k] ? obj[b][k] : "";
          }
          xlsPMPASData.push(xlsObj);
        }
      }
    }
  }

  useEffect(() => {
    if (
      focusMode &&
      focusMode.length > 0 &&
      focusMode === "Project" &&
      isModeSwitched
    ) {
      setXlsCM(xlsPMPASData);
      setXlsName(xlsPMPAsList);
    }
  }, [focusMode, isModeSwitched]);

  /* ................................................................................................................... */

  let ItemsData = localStorage.getItem("items");
  if (ItemsData) {
    ItemsData = ItemsData.split(",");
  }

  let listData = [];
  if (ItemsData && ItemsData.length > 0) {
    listData = ItemsData;
  } else {
    listData = list;
  }

  let cusDatas = [People, Skills, Roles, Domains, Links, Circles, Projects];
  let cuFPDatas = [
    FPPeople,
    FPSkills,
    FPRoles,
    FPDomains,
    FPLinks,
    FPCircles,
    FPProjects,
  ];
  let cuFCDatas = [
    FCPeople,
    FCSkills,
    FCRoles,
    FCDomains,
    FCLinks,
    FCCircles,
    FCProjects,
  ];
  let cuFPrDatas = [
    FPrPeople,
    FPrSkills,
    FPrRoles,
    FPrDomains,
    FPrLinks,
    FPrCircles,
    FPrProjects,
  ];
  let circleModes = [CircleMode, CircleAsPeopleMode];
  let projectModes = [ProjectMode, ProjectAsPeopleMode];

  // useEffect(() => {
  if (listHide && Object.keys(listHide).length > 0) {
    let listArray = [];
    if (listData && listData.length > 0) {
      for (let i = 0; i < listData.length; i++) {
        if (listHide[listData[i]] === false) {
          listArray.push(listData[i]);
        }
      }
    }
    if (listArray) {
      list = [...new Set(listArray)];
    }
  }
  // }, [listHide, setListHide])

  var curFocused = [];
  if (focusMode && focusMode.length > 0) {
    if (focusMode === "Circle") {
      if (!isModeSwitched) {
        curFocused.push(circleModes[0]); // Load added as
      } else if (isModeSwitched) {
        curFocused.push(circleModes[1]); // Load people
      }
    }

    if (focusMode === "Project") {
      if (!isModeSwitched) {
        curFocused.push(projectModes[0]); // Load added as
      } else if (isModeSwitched) {
        curFocused.push(projectModes[1]); // Load people
      }
    }
  }

  let cuDatas = [];
  if (focused && focused.card.length > 0) {
    if (focused.card === "People") {
      cuDatas = cuFPDatas;
      ListData.push("People");
    }
    if (focused.card === "Circles") {
      cuDatas = cuFCDatas;
      ListData.push("Circles");
    }
    if (focused.card === "Projects") {
      cuDatas = cuFPrDatas;
      ListData.push("Projects");
    }
  } else {
    cuDatas = cusDatas;
  }

  if (allSearch && allSearch.length > 0) {
    let set = [...new Set(ListData)];
    let SearchList = [];
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < set.length; j++) {
        let inc = set.includes(list[i]);
        if (inc) {
          SearchList.push(list[i]);
        }
      }
    }
    list = [...new Set(SearchList)];
  }

  let allData = [];
  if (list.length > 0) {
    for (let i = 0; i < 7; i++) {
      switch (list[i]) {
        case "People":
          allData.push(cuDatas[0]);
          break;
        case "Skills":
          allData.push(cuDatas[1]);
          break;
        case "Roles":
          allData.push(cuDatas[2]);
          break;
        case "Domains":
          allData.push(cuDatas[3]);
          break;
        case "Links":
          allData.push(cuDatas[4]);
          break;
        case "Circles":
          allData.push(cuDatas[5]);
          break;
        case "Projects":
          allData.push(cuDatas[6]);
          break;
        default:
          break;
      }
    }
  }

  const onDragEndSP = (result, provided) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    allData = reorder(allData, result.source.index, result.destination.index);
  };

  const onDragEndSPCM = (result, provided) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    curFocused = reorder(
      curFocused,
      result.source.index,
      result.destination.index
    );
  };

  /* Remove the .latest-created class from the element after 3 seconds if exists */
  useEffect(() => {
    let elem = document.querySelector(".latest-created");
    if (elem) {
      elem.scrollIntoView({
        behavior: "smooth",
      });

      setTimeout(() => {
        elem.classList.remove("latest-created");
        localStorage.setItem("latest-created", "");
      }, 3000);
    }
  }, [document.querySelector(".latest-created")]);

  useEffect(() => {
    const container = document.getElementById("st-peoples");
    if (container) {
      // Filter out items that should not be draggable
      const dragulaContainers = Array.from(container.children).filter(
        (child) => !child.classList.contains("no-drag")
      );

      const drake = dragula([container]);

      // Optional: Add event listeners to handle drag events
      drake.on("drag", (el, source) => {
        // console.log('Dragging...', el, source);
      });

      drake.on("drop", (el, target, source, sibling) => {
        // console.log('Dropped!', el.id, target, source);

        let curpeople = Array.from(container.children)
          .filter((child) => !child.classList.contains("no-drag"))
          .map((child) => child.getAttribute("id"));

        // console.log('New order:', curpeople);
        if (states && states.cListMode) {
          const copyListItems = [...peopleSorted];
          const sortedCopyListItems = copyListItems.sort((a, b) => {
            const indexA = curpeople.indexOf(a._id);
            const indexB = curpeople.indexOf(b._id);
            return indexA - indexB;
          });
          // console.log(sortedCopyListItems);

          setpeopleSorted(sortedCopyListItems);
          let updatedStates = { ...states, Person: curpeople };
          // console.log(updatedStates)
          dispatch({ type: STATES_MODIFICATIONS, payload: updatedStates });
          if (curpeople && curpeople.length > 0) {
            peopleStateUpdate(curpeople);
          }
        }
      });

      return () => {
        drake.destroy(); // Clean up to prevent memory leaks
      };
    }
  }, [document.getElementById("st-peoples")]);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    window.addEventListener("online", () => setIsOnline(true));
    window.addEventListener("offline", () => setIsOnline(false));

    return () => {
      window.removeEventListener("online", () => setIsOnline(true));
      window.removeEventListener("offline", () => setIsOnline(false));
    };
  }, []);

  return (
    <>
      {/* {isOnline ? (<Snackbar open={isOnline}>
        <Alert
          severity="danger"
          variant="filled"
          sx={{ width: '100%' }}
        >
          You are offline! Please check your internet connection.
        </Alert>
      </Snackbar>) : null} */}
      {/* <Permission_ModePopUp /> */}

      {allData && allData.length > 0 && curFocused.length === 0 ? (
        <div className="wrap-lists all-lists" id="standard-mode">
          {allData.map((Item, index) => (
            <Item key={index} />
          ))}
        </div>
      ) : null}

      {/* Circle and Project mode */}

      {curFocused && curFocused.length > 0
        ? curFocused.map((Items, index) => {
            return <Items key={index} />;
          })
        : null}
    </>
  );
}
export default Body;
