import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { RC_SITE_KEY, CLIENT_URL } from "../../../config/keys"

export default function Navbar() {
    const navigate = useNavigate();

    const { loading, successMessage, error, authenticate, myInfo } = useSelector(state => state.auth)

    // nav-link active
    return (
        <div>
            <nav className="mainNav navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbarSupportedContent">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <a className="navbar-brand order-1" /* onClick={() => navigate('/')} */ href='/'>
                    <img src={`${CLIENT_URL}/images/new-logo-sm-black.png`} style={{width: "100px"}} alt="teamdecoder"/>
                    </a>
                    <ul className="navbar-action-button navbar-right">
                        <li className="navbar-action-item"><a className="button  button-white-outline" href="/">{authenticate ? 'teamdecoder' : 'Login' }</a></li>
                    </ul>

                    <div className="collapse navbar-collapse order-4 order-lg-2" id="navbarSupportedContent">
                        <ul className="nav navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" target="_blank" rel="noopener nofollow" aria-current="page" href="http://teamdecoder.com/">Info</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" target="_blank" rel="noopener nofollow" href="http://help.teamdecoder.com/">Help</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className={window.location.pathname === '/free-trial' ? "nav-link active" :  "nav-link"} href="/free-trial">FREE</a>
                            </li> */}
                            <li className="nav-item">
                                <a className="nav-link" target="_blank" rel="noopener nofollow" href="http://demo.teamdecoder.com/">Book a Demo</a>
                            </li>
                            <li className="nav-item">
                                <a className={window.location.pathname === '/pricing' ? "nav-link active" :  "nav-link"} rel="noopener nofollow" href="/pricing">Pricing</a>
                            </li>
                            <li className="nav-item">
                                <a className={window.location.pathname === "/request-report" ? "nav-link active" :  "nav-link"} rel="noopener nofollow" href="/request-report">Report</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}
